import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import parse from 'html-react-parser';
const { Then, If } = require('react-if');

/**
 * @description 제목
 */
const Title = ({ isTop, children }) => {
    return (
        <div
            className={
                _.isBoolean(isTop) && isTop
                    ? 'wbTitleSub topTitle'
                    : 'wbTitleSub'
            }
        >
            {children}
        </div>
    );
};

/**
 * @description Button 영역
 */
const BothEndDiv = ({ left, right }) => {
    return (
        <div className="bothEndArea">
            <If condition={!_.isUndefined(left)}>
                <Then>
                    <div className="bothEndLeft">{left}</div>
                </Then>
            </If>
            <If condition={!_.isUndefined(right)}>
                <Then>
                    <div className="bothEndRight">{right}</div>
                </Then>
            </If>
        </div>
    );
};

/**
 * @description Table 기본 틀
 */
const TableBasicLayer = ({ tableClassName, children }) => {
    return <div className={tableClassName}>{children}</div>;
};

/**
 * @description Table 요소 수평
 */
const TableHorLayer = ({ children }) => {
    return (
        <TableBasicLayer tableClassName="wbTableH">{children}</TableBasicLayer>
    );
};

/**
 * @description Table 요소 수직
 */
const TableVerLayer = ({ children }) => {
    return (
        <TableBasicLayer tableClassName="wbTableV">{children}</TableBasicLayer>
    );
};

const NoDataTag = (props) => {
    const { style, resId } = props || {};
    const defaultStyle = {
        width: 600,
        height: 400,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    };
    const noDataStyle = _.merge(defaultStyle, style);
    return (
        <div style={noDataStyle}>
            <FormattedMessage id={resId || 'Message.No.Data'} />
        </div>
    );
};

/**
 * @description Table Td Left 정렬
 */
const TdLeft = ({ children }) => {
    return (
        <span className="tdLeft">
            {_.isString(children) ? parse(children) : children}
        </span>
    );
};

export {
    Title,
    BothEndDiv,
    TableBasicLayer,
    TableHorLayer,
    TableVerLayer,
    NoDataTag,
    TdLeft,
};
