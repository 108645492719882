import _ from 'lodash';
import { lazy, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

const DynamicRoute = ({ routes, onRanderHandle }) => {
    const [aimRoute, setAimRoute] = useState([]);

    useEffect(() => {
        const _routes = _.reduce(
            routes,
            (_aimRoutes, elem, index) => {
                if (_.isUndefined(elem)) {
                    return;
                }
                const { url, page, props } = elem;

                const dynamicRoute = (linkUrl, linkPage, linkProps, key) => {
                    const SubPageComponent = lazy(linkPage);
                    _aimRoutes.push(
                        <Route
                            key={key}
                            exact
                            path={linkUrl}
                            render={(rest) => {
                                return (
                                    <SubPageComponent
                                        {...linkProps}
                                        {...rest}
                                        onRanderHandle={onRanderHandle}
                                    />
                                );
                            }}
                        />,
                    );
                };

                if (_.isArray(url)) {
                    _.map(url, (elem, index) => {
                        dynamicRoute(elem, page, props, index);
                    });
                } else {
                    dynamicRoute(url, page, props, 'uni');
                }
                return _aimRoutes;
            },
            [],
        );

        setAimRoute(_routes);
    }, [routes]);

    return <Switch>{aimRoute}</Switch>;
};

export { DynamicRoute };
