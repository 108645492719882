import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Uploader as MuxUploader } from 'mux';

const Uploader = (props) => {
    const {
        placeholder,
        placeholderId,
        required,
        onDownloadFile,
        onImageClick,
        onDeleteFile,
        onThumbUrl,
        maxLength,
        label,
        ...rest
    } = props;

    const intl = useIntl();

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        return chunkRules;
    }, [intl, required]);

    return (
        <MuxUploader
            {...rest}
            {...rules}
            placeholder={
                placeholderId
                    ? intl.formatMessage({ id: placeholderId })
                    : placeholder
            }
            onDownload={onDownloadFile}
            onPreview={onImageClick}
            onRemove={onDeleteFile}
            onThumbUrl={onThumbUrl}
            maxCount={maxLength}
            buttonProps={{
                label: intl.formatMessage({
                    id: label || 'Btn.FileSearch',
                }),
            }}
            // appendMessage="※ 등록 사진 용량 10MB 이하"
            //appendMessage
        />
    );
};

Uploader.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    placeholderId: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    format: PropTypes.string,
    editable: PropTypes.bool,
    filter: PropTypes.array,
    multiple: PropTypes.bool,
    maxLength: PropTypes.number,
    onHasPermit: PropTypes.func,
    onDownloadFile: PropTypes.func,
    onImageClick: PropTypes.func,
    onDeleteFile: PropTypes.func,
    onThumbUrl: PropTypes.func,
    factory: PropTypes.string,
    buttonProps: PropTypes.object,
};

Uploader.defaultProps = {
    required: false,
    disabled: false,
    editable: true,
    multiple: false,
    maxLength: 6,
    buttonProps: {
        label: 'Upload',
        type: 'negative',
        at: 'component',
    },
};

export default Uploader;
