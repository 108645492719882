import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { AdvancedButton as MuxAdvancedButton, useAppRoot } from 'mux';
import { findMenuUrl } from 'config/routes';

const AdvancedButton = forwardRef((props, ref) => {
    const { children, resId, text, disable, permitId, permitUrl, ...rest } =
        props;
    const { hasPermit, menu } = useAppRoot();

    let permitShow = true;
    if (hasPermit) {
        if (permitUrl) {
            const menuUrl = findMenuUrl(permitUrl);

            const buttonAclYn = _.find(menu, { menuUrl });
            permitShow = buttonAclYn && buttonAclYn[`${permitId}Yn`] === 'Y'; // false 설정(지정 URL 권한 확인)
        } else if (permitId) {
            permitShow = hasPermit(permitId); // false 설정(내 메뉴 권한 확인)
        }
    }

    const label = useMemo(() => {
        if (children) {
            return children;
        } else if (resId) {
            return <FormattedMessage id={resId} />;
        } else {
            return text;
        }
    }, [children, resId, text]);

    return (
        <>
            {permitShow ? (
                <MuxAdvancedButton {...rest} disabled={disable} ref={ref}>
                    {label}
                </MuxAdvancedButton>
            ) : null}
        </>
    );
});

AdvancedButton.propTypes = {
    resId: PropTypes.string,
    text: PropTypes.string,
    disable: PropTypes.bool,
};

export default AdvancedButton;
