import TagManager from 'react-gtm-module';
const gtmId = process.env.REACT_APP_GTM;

export const initTagManager = () => {
  TagManager.initialize({ gtmId });
};

export const pushDataLayer = (dataLayer) => {
  TagManager.dataLayer({
    dataLayer,
  });
};

export const pushPageview = (path, ID, role, menunm) => {
  pushDataLayer({
    event: 'screen_view',
    user: {
      'userId' : ID,
      'user_type' : role,
      'company_code' : 'HAE',
      'company_nm' : '현대오토에버',
      'team_nm' : '서비스개발팀 MOP',
    },
    page: {
      'page_path' : path,
      'menu_nm' : menunm ? menunm : '0',
    },
  });
};