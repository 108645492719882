import antdLocale from 'antd/lib/locale/ja_JP';

export const jaJP = {
    ...antdLocale,
    Empty: {
        description: '検索条件と一致する結果が見つかりません。',
    },
};

const language = {
    /** common */
    'Common.All': '全体',
    'Common.All.ByName': '{name}全体',
    'Common.Login': 'ログイン',
    'Common.Logout': 'ログアウト',
    'Common.Language': 'Language',
    'Common.Unit.Percent': '%',
    'Common.Unit.Percent.Value': '{value}%',
    'Common.Unit.Distance.M': 'm',
    'Common.Unit.Distance.Km': 'km',
    'Common.Unit.Point': '点',
    'Common.Unit.Number': '個',
    'Common.Unit.Number.Value': '{value}個',
    'Common.Unit.Money': '円',
    'Common.Unit.Money.Value': '{value}円',
    'Common.Unit.Money.Personal': '円 (個人)',
    'Common.Unit.Money.Business': '円 (ビズ)',
    'Common.Unit.MoneyPerMinute': '円/分',
    'Common.Unit.MoneyPerMinute.Value': '{value}円/分',
    'Common.Unit.Money.10000': '万円',
    'Common.Unit.Vehicle.Count': '台',
    'Common.Unit.Vehicle.Count.Value': '{value}台',
    'Common.Unit.People.Count': '人',
    'Common.Unit.People.Count.Value': '{value}人',
    'Common.Unit.Case.Count': '件',
    'Common.Unit.Case.Count.Value': '{value}件',
    'Common.Unit.Seconds': '秒',
    'Common.Unit.Seconds.Value': '{value}秒',
    'Common.Unit.Minute': '分',
    'Common.Unit.Minute.Value': '{value}分',
    'Common.Unit.Hour': '時間',
    'Common.Unit.Hour.Value': '{value}時間',
    'Common.Unit.Hour.Short': '時',
    'Common.Unit.Days': '日',
    'Common.Unit.Count': '回',
    'Common.Unit.Months': 'ヶ月',
    'Common.Unit.Months.Value': '{value}ヶ月',
    'Common.Unit.Month': '月',
    'Common.Unit.Year': '年',
    'Common.Unit.Floor': '階',
    'Common.Unit.MoneyPerDay': '円/1泊', // JP
    'Intro.Title.Admin': 'Fleet Management System',
    'Intro.Title.Admin.2': 'FMS',
    'Intro.Title.Client.Admin': 'クライアント管理者システム',
    'Intro.Title.Client.Admin.2': 'クライアント',
    'Intro.Desc.Client.Admin':
        '業務用車両の管理に必要な経費と時間,<br />MOCEAN Car Sharing と共に効率的に管理しよう。',
    'Common.Main.GettingStart': 'スタート',
    // 'Common.Main.Title':
    //     '短期から?中長期まで{br}リアルタイムで?比較して?簡単に?予約',
    // 'Common.Main.SubTitle': '予約から売上の精算までよりお手軽にご利用ください。',
    'Common.Footer.Policy1': '利用約款',
    'Common.Footer.Policy2': '個人情報取り扱い方針',
    'Common.Footer.Policy1.JP': '会員規約',
    'Common.Footer.Policy2.JP': 'フラットフォームマネジメントシステム利用約款',
    'Common.Footer.Copyright': 'ⓒ Kia CORP. ALL RIGHTS RESERVED.',
    'Common.Footer.Address1':
        '<span className="title">事業者</span> キア（株）<span className="title">代表</span> ソンホソン、チェジュンヨン <span className="title">登録事業所番号</span> 119-81-02316',
    'Common.Footer.Address2':
        '<span className="title">住所</span>06797 ソウル特別市　ソチョ区ホンルン路12(ヤンジェ洞) <span className="title">代表電話番号</span> 1833-4964',
    'Common.Inquiry.Period': '照会期間',
    'Common.Active.Period': '使用期間',
    'Common.UseDate.Period': '適用期間',
    'Common.Msg.Key': 'メッセージキー',
    'Common.Gender.Male': '男性',
    'Common.Gender.female': '女性',
    'Common.YN.Y': 'Y',
    'Common.YN.N': 'N',
    'Common.YN.Yes': 'はい',
    'Common.YN.No': 'いいえ',
    'Common.YN.Use': '使用',
    'Common.YN.Unuse': '未使用',
    'Common.YN.Notuse': '使用しない',
    'Common.YN.Exist': '有',
    'Common.YN.NotExist': '無',
    'Common.Use.YN': '使用有無',
    'Common.Use.YN.All': '使用有無全体',
    'Common.YN.Show': '表示',
    'Common.YN.Hide': '非表示',
    'Common.Title.Placeholder': 'タイトルを入力',
    'Common.Title.Use': '使用有無',
    'Common.User.Create': '登録者',
    'Common.User.Update': '更新者',
    'Common.User.Update.Id': '更新者 ID',
    'Common.User.Business': '会員社',
    'Common.User.TelPhoneNumber': '電話番号',
    'Common.User.BusinessRegNum': '事業所番号',
    'Common.User.Adress': '住所',
    'Common.Customer.Name': '顧客名',
    'Common.Date.Create': '登録日時',
    'Common.Date.Update': '更新日時',
    'Common.Date.Occurrence': '発生日時',
    'Common.Date.Amount': '金額',
    'Common.Day.Create': '登録日',
    'Common.Day.Update': '更新日',
    'Common.Day.SendDay': '送信日',
    'Common.Day.SendDate': '送信日時',
    'Common.Day.SendPeriod': '送信期間',
    'Common.Date.Placeholder': 'YYYY-MM-DD',
    'Common.User.ID': '会員 ID',
    'Common.User.Name': '会員名',
    'Common.User.PhoneNumber': '連絡先',
    'Common.User.Email': '電子メール',
    'Common.StartDate': '開始日時',
    'Common.EndDate': '終了日時',
    'Common.BaseInfo': '基本情報',
    'Common.YN.Essential': '必須',
    'Common.YN.Select': '選択',
    'Common.Status': '状態',
    'Common.ActivationStatusCd': '承認状態',
    'Common.Day.Join': '入会日',
    'Common.Input.ByName': '{name}入力',
    'Common.Input.Placeholder.ByName': '{name}入力',
    'Common.Role': '権限(バーミッション)',
    'Common.KaKao.AddressSearch': '住所検索',
    'Common.MapView': '地図ビュー',
    'Common.User.Status': '会員状態',
    'Common.Date.LastUpdate': '最終更新日',
    'Common.MobilityManager': 'MOCEAN 管理者',
    'Common.BusinessManager': 'スーパー管理者',
    'Common.BusinessOperator': '部署担当者',
    'Common.Partner': '委託会社',
    'Common.Login.Agree.Title': '個人情報保護遵守の同意',
    'Common.Login.Agree.Content.1': 'MOCEAN Car Sharing に接続時',
    'Common.Login.Agree.Content.2':
        '企業/部署の担当者として、全ての役職員の個人情報及び利用内訳の情報を保護する義務があり、MOCEAN Car Sharing 管理以外の目的での外部への譲渡や使用をしないことに同意する必要があります。',
    'Common.Login.Agree.Content.3': '同意しない場合、接続できません。',
    'Common.Login.Agree.Content.4':
        '上記の内容を確認致し、個人情報保護遵守に同意致します。',
    'Common.StartAdmin.TermsUse.Title': '会員規約',
    'Common.StartAdmin.PrivacyPolicy.Title':
        'フラットフォームマネジメントシステム利用約款',
    'Common.Login.AdminCommon.Title': '管理者関連約款の同意',
    'Common.Week,Monday': '月',
    'Common.Week,Tuesday': '火',
    'Common.Week,Wednesday': '水',
    'Common.Week,Thursday': '木',
    'Common.Week,Friday': '金',
    'Common.Week,Saturday': '土',
    'Common.Week,Sunday': '日',
    'Common.Bnb.Alram': '通知',
    'Common.SelectMessage': '選択',
    'Common.Authed': '認証',
    'Common.UnAuthed': '未認証',
    'Common.Refresh': '更新',
    'Common.Success': '成功',
    'Common.Failure': '失敗',
    'Common.Dash': '-',
    'Common.Colon': ':',
    'Common.Image.Insert': '画像の挿入',
    'Common.StartDate.Placeholder': '開始日',
    'Common.EndDate.Placeholder': '終了日',
    'Common.StartDateTime.Placeholder': '開始日時',
    'Common.EndDateTime.Placeholder': '終了日時',
    'Common.StartTime.Placeholder': '開始時間',
    'Common.EndTime.Placeholder': '終了時間',
    'Common.Column.No': 'No.',
    'Common.Uploader.Placeholder': '画像またはPDFファイル',
    'Common.Placeholder.Suffix': '入力',
    'Common.Empty.Use.Department': '利用部署の未確認',
    'Common.Uploader.Limit.10': '※ 登録写真容量10MB以下',
    'Common.Stats.divider': '|',
    'Common.EmployeeRegNum': '社員番号', // JP
    'Common.Footer.JP.Address1': 'カスタマーセンター（☎）: +81-120-600-066',
    'Common.Footer.JP.Address2':
        'カスタマーセンター (＠) : mocean_customer@hyundai.com',
    'Common.Footer.JP.Address3': '事業者番号 : 9010401092271',
    'Common.Footer.JP.Address4':
        '所在地 : 神奈川県横浜市西区みなとみらい3丁目6番1号みなとみらいセンタービル16階',
    'Common.Footer.JP.Address5':
        'ヒョンデモビリティジャパン Ｈｙｕｎｄａｉ Ｍｏｂｉｌｉｔｙ Ｊａｐａｎ株式会社',

    /** Message */
    // 'Message.Login.Alert.System.Error':
    //     'ログインするシステム情報と一致しません。',
    // 'Message.Auth.Check.Alert.SystemError':
    //     '正しくない経路でアクセスしました。再度ログインしてください。',
    'Message.Auth.Confirm.User.Delete': '退会しますか?',
    'Message.Auth.Confirm.Pwd.Change': 'パスワードを変更しますか?',
    'Message.Auth.Logout.Success':
        '安全にログアウトしました。また、ご利用ください。',
    'Message.Auth.Alert.Expired':
        '指定時間超過により自動ログアウトされました。再ログインしてください。',
    'Message.Auth.Alert.Forbidden':
        '要請された機能を実行する権限がございません。',
    'Message.Alert.Timeout':
        '実行が遅延しています。　しばらくしてから再度ご確認ください。',
    'Message.Auth.Wait': 'しばらくお待ちください!',
    'Message.Join.Alert.SystemError':
        '一時的に接続障害が発生しました。しばらくしてから再度ご確認ください。',
    'Message.Join.Alert.PrivateAdmin':
        '入会の承認が完了しました。ログアウト後、再度ログインしてください。',
    'Message.Validator.Number': '数字のみ入力してください。',
    'Message.Validator.Email': '電子メール形式で入力してください。',
    'Message.Validator.MaxNumber': '最大{compareValue}まで入力してください。',
    'Message.Validator.MinNumber': '最小{compareValue}以上入力してください。',
    'Message.Validator.MinNumber.0': '最小0以上入力してください。',
    'Message.Validator.MinNumber.1': '最小1以上入力してください。',
    'Message.Validator.MaxLength': '最大{compareValue}文字まで入力してください',
    'Message.Validator.MinLength':
        '最小{compareValue}文字以上入力してください。',
    'Message.Validator.MinMoneyCardPayment1000':
        'クレジットカード決済は最小2円以上入力してください。',
    'Message.Validator.Required': '必須入力項目です。',
    'Message.Validator.Select': '必須選択項目です。',
    'Message.Validator.Hour': '0から23まで入力できます。',
    'Message.Validator.Day': '1から31まで入力できます。',
    'Message.Validator.Percent': '1から100まで入力できます。',
    'Message.Validator.Percent.Less.Then.100': '100％以下で入力してください。',
    'Message.Validator.Error': '必須項目はすべて入力してください。',
    'Message.Validator.Latitude': '緯度を正確に入力してください。',
    'Message.Validator.Longitude': '経度を正確に入力してください。',
    'Message.Validator.Telnum': '電話番号を正確に入力してください。',
    'Message.Validator.File.Size.Limit':
        'ファイルは{size}MBまでアップロードできます。',
    'Message.Validator.MM': '月を正確に入力してください。',
    'Message.Validator.YY': '年を正確に入力してください。',
    'Message.Validator.MaxDecimalPoint1': '小数点まで入力可能です。', // JP
    'Message.Send.Kakaotalk.Confirm': 'e-mailを送信しますか?',
    'Message.Send.Kakaotalk.Fail': 'e-mailを送信できません。',
    'Message.Send.Kakaotalk.Success': 'e-mailを送信しました。',
    'Message.Logout.Hello': 'ようこそ、{user}様',
    'Message.Action.Fail': '実行できません。',
    'Message.Get.Fail': '照会できません。',
    'Message.ACL.Fail': '現在の権限ではできません。',
    'Message.Confirm.Save': '保存しますか?',
    'Message.Success.Save': '保存しました。',
    'Message.Failure.Save': '保存できません。',
    'Message.Confirm.Create': '登録しますか?',
    'Message.Success.Create': '登録しました。',
    'Message.Failure.Create': '登録できません。',
    'Message.Confirm.Update': '更新しますか?',
    'Message.Success.Update': '更新しました。',
    'Message.Failure.Update': '更新できません。',
    'Message.Confirm.Delete': '削除しますか?',
    'Message.Success.Delete': '削除しました。',
    'Message.Failure.Delete': '削除できません。',
    'Message.Confirm.Deletes': '{count}件を削除しますか?',
    'Message.Failure.Deletes': '一部削除できません。データを確認してください。',
    'Message.Alert.ContractHistory.Selected': '履歴を選択してください。',
    'Message.Alert.Delete.No.Checked': '削除する項目を選択してください。',
    'Message.Alert.Update.No.Checked': '更新する項目を選択してください。',
    'Message.Alert.Create.No.Checked': '登録する項目を選択してください。',
    'Message.Alert.Send.No.Checked': '送信する項目を選択してください。',
    'Message.Alert.Duplicate.Checked': '既に登録された項目です。',
    'Message.Alert.Agree.Checked': '必須同意の項目が同意されておりません。',
    'Message.Alert.Business.Selected':
        '会員社を選択して検索後、次に進めてください。',
    'Message.Alert.Dialog.Business.Selected': '会員社を選択してください。',
    'Message.Alert.Dialog.Reservation.Selected': '貸出番号を選択してください。',
    'Message.Alert.Dialog.User.Selected': '会員を選択してください。',
    'Message.Alert.Dialog.Payment.Card.Selected':
        '決済用クレジットカードを選択してください。',
    'Message.Alert.Dialog.Charge.Card.Selected':
        '充電する提携カードを選択してください。',
    'Message.Alert.Dialog.Charge.Selected': '商品を選択してください。',
    'Message.Alert.Dialog.Hipass.Card.Selected':
        'ETC Card を選択してください。',
    'Message.Alert.Dialog.Vehicle.Model.Selected': '車種を選択してください。',
    'Message.Alert.Dialog.Vehicle.Selected': '車両を選択してください。',
    'Message.Alert.Dialog.WibleZone.Selected':
        'ステーションを選択してください。',
    'Message.Alert.Dialog.WibleZone.Guide.Selected':
        'ステーションの利用案内を選択してください。',
    'Message.Alert.Dialog.Safety.Option.Selected':
        'オプションを選択してください。',
    'Message.Alert.Dialog.Subscribe.Selected':
        'サブスクリプション番号を選択してください。',
    'Message.Alert.Dialog.Convenience.Option.Selected':
        'オプションを選択してください。',
    'Message.Confirm.Upload': 'アップロードしますか?',
    'Message.Success.Upload': 'アップロードしました。',
    'Message.Failure.Upload': 'アップロードできません。',
    'Message.Failure.Download': 'ダウンロードできません。',
    'Message.Confirm.Download': 'ダウンロードしますか?',
    'Message.Confirm.Send': '送信しますか?',
    'Message.Success.Send': '送信しました。',
    'Message.Failure.Send': '送信できません。',
    'Message.No.Data': 'データがございません。',
    'Message.Default.Error': '実行できません。',
    'Message.Confirm.SubscribeCancel':
        'サブスクリプションをキャンセルしますか?',
    'Message.Success.SubscribeCancel':
        'サブスクリプションのキャンセル申請しました。',
    'Message.Failure.SubscribeCancel':
        'サブスクリプションをキャンセルできません。',
    'Message.Confirm.Terms': '約款に同意が必要です。',
    'Message.Failure.Charge': '料金照会ができません。',
    'Message.URL.Empty': '情報を全て入力してください。',
    'Message.URL.Copy.Success': 'URLをコピーしました。',
    'Message.URL.Copy.Fail': 'URLをコピーできませんでした。 ',
    'Message.Coupon.UsePeriodDate.Empty':
        '発行日基準の適用日を入力してください。',
    'Message.Coupon.UsePeriodDate.Empty.Reverse':
        '使用期間を入力してください。',
    'Message.Coupon.issueEndDate.Error': 'クーポンの発行期限は、クーポンの使用期限以降に設定することはできません。',
    'Message.Notice.NoData': '新しいお知らせはございません。',
    'Message.Myinfo.PartnerId.Empty': '役割を選択してください。',
    'Message.Confirm.Command': '制御を実行しますか?',
    'Message.Confirm.Command.Value': '{value}に変更しますか?',
    'Message.Success.Command': '制御を実行しました。',
    'Message.Failure.Command': '制御を実行できません。',
    'Message.Upload.Empty.Image': '画像を選択してください。',
    'Message.IssuedNumber.Empty': '発行枚数を入力してください。',
    'Message.Confirm.Approve': '承認しますか?',
    'Message.Confirm.Deny': '却下しますか?',
    'Message.Success.Approve': '承認が完了しました。',
    'Message.Success.Deny': '却下が完了しました。',
    'Message.Failure.Approve': '承認できませんでした。',
    'Message.Failure.Deny': '却下できませんでした。',
    'Message.VocMgmt.UserNotSearch': '会員を検索してください。',
    'Message.VocMgmt.MessageEmpty': 'メッセージの送信内容を入力してください。',
    'Message.VocMgmt.RequestSend': 'メッセージを送信しますか?',
    'Message.VocMgmt.RequestSend.Success': 'メッセージを送信しました。',
    'Message.VocMgmt.RequestSend.Fail': 'メッセージを送信できませんでした。',
    'Message.Search.NoData': '検索条件と一致する結果がございません。',
    'Message.Confirm.Modify.Cancel':
        '登録をキャンセルしますか?この情報は保存されません。',
    'Message.Confirm.Regist.Cancel':
        '変更をキャンセルしますか?この情報は保存されません。',
    'Message.Confirm.Reservation.Cancel': '予約をキャンセルしますか?',

    /** 必須値のチェックメッセージ*/
    'Required.String.Empty': '入力されてない値があります。',

    /** btn */
    'Btn.Selected': '選択完了',
    'Btn.Select': '選択',
    'Btn.Select.All': '全て選択',
    'Btn.AllSelect': '全て選択', // 臨時で追加
    'Common.Btn.AllSelect': '全て選択', // 臨時で追加 - 既存マルチセレクトにある
    'Btn.Agree.No': 'いいえ',
    'Btn.Agree.Yes': 'はい',
    'Btn.Select.File': 'ファイル選択',
    'Btn.Edit.File': 'ファイル修正',
    'Btn.Create': '登録',
    'Btn.NewCreate': '新規登録',
    'Btn.Add': '追加',
    'Btn.Save': '保存',
    'Btn.NotSelect': '設定しない',
    'Btn.Modify': '修正',
    'Btn.Modify.Complete': '修正完了',
    'Btn.Edit': '編集',
    'Btn.Setup': '設定',
    'Btn.Setup.Complete': '設定完了',
    'Btn.Delete': '削除',
    'Btn.Cancel': 'キャンセル',
    'Btn.Confirm': '確認',
    'Btn.Reset': '初期化',
    'Btn.List': 'リスト',
    'Btn.View': '表示',
    'Btn.Search': '検索',
    'Btn.Close': '閉じる',
    'Btn.Send': '送信',
    'Btn.Duplicate.Check': '重複確認',
    'Btn.Leave.User': '退社',
    'Btn.Kakaotalk': 'LINE',
    'Btn.Fax': 'FAX',
    'Btn.Reservation.Regist': '貸出登録',
    'Btn.MMS': '送信',
    'Btn.MMS.Send': 'メッセージの送信',
    'Btn.VersionUp': 'バージョンのアップデート',
    'Btn.Terms.Download': '約款のダウンロード',
    'Btn.Deny': '却下',
    'Btn.Approve': '承認',
    'Btn.PendingApprove': '承認待ち',
    'Btn.Date.Today': '今日',
    'Btn.Date.Week.1': '1週間',
    'Btn.Date.Month.1': '1ヶ月',
    'Btn.Date.Month.3': '3ヶ月',
    'Btn.Date.Month.6': '6ヶ月',
    'Btn.Download': 'ダウンロード',
    'Btn.Upload': 'アップロード',
    'Btn.FileDownload': 'エクセルダウンロード',
    'Btn.FileUpload': 'ファイルアップロード',
    'Btn.ExcelDownload': 'エクセルダウンロード',
    'Btn.FileSearch': 'ファイルを探す',
    'Btn.DocumentDownload': '書式ダウンロード',
    'Btn.Modify.Business': '会員社情報修正',
    'Btn.Modify.SuperAdmin': 'スーパー管理者情報修正',
    'Btn.Modify.Contract': '契約情報の修正',
    'Btn.Coupon.Ledger': '発行台帳',
    'Btn.Coupon.Affiliate': 'クーポン',
    'Btn.OneToOne.Sms.Send': '1:1メッセージ送信',
    'Btn.Contract.Regist': '契約登録',
    'Btn.Contract.Add': '契約追加',
    'Btn.Process': 'プロセス',
    'Btn.Reset.Terminal': 'リセット',
    'Btn.SeveralCreate': '一括登録',
    'Btn.Lookup': '照会',
    'Btn.Cancel.Subscribe': 'サブスクリプションキャンセル',
    'Btn.Create.Complete': '登録完了',
    'Btn.Detail': '詳細確認',
    'Btn.Detail.Info': '詳細情報',
    'Btn.Produce': '作成',
    'Btn.Produce.URL.Copy': 'URL コピー',
    'Btn.Change': '変更',
    'Btn.Email.All': '全体送信',
    'Btn.Email.Selected': '選択送信',
    'Btn.Agree': '同意',
    'Btn.Agree.All': '全て同意',
    'Btn.X': 'X',
    'Btn.Today.Close': '24時間表示しない。',
    'Btn.Notice.More': 'お知らせの詳細確認',
    'Btn.Publish': '発行',
    'Btn.Preview': 'プレビュー',
    'Btn.Claim': '請求',
    'Btn.Pay': '決済',
    'Btn.PenaltyClear': '免除処理',

    /** code */
    'Code.Common': '共通コード',
    'Code.Content.Editor.Type.HTML': 'html',
    'Code.Content.Editor.Type.TXT': 'text',
    'Code.Faq.FaqCategoryId.SignUP': 'お問い合わせ',
    'Code.Faq.FaqCategoryId.Reservation': 'お問い合わせ',
    'Code.Faq.FaqCategoryId.Vehicle': 'お問い合わせ',
    'Code.Faq.FaqCategoryId.Pay': 'お問い合わせ',
    'Code.Faq.FaqCategoryId.Return': 'お問い合わせ',
    'Code.Menu.SystemType.System': 'システム',
    'Code.Menu.SystemType.SystemShort': 'システム短縮メニュー',
    'Code.Menu.SystemType.Provider': '供給社',
    'Code.Menu.SystemType.Business': '会員社',
    'Code.Menu.SystemType.Probiz': '供給社+会員社',
    'Code.Menu.UrlType.Internal': '内部',
    'Code.Menu.UrlType.External': '外部',
    'Code.Menu.UrlType.Group': 'Menu group',
    'Code.Menu.UrlType.Js': 'JS',
    'Code.Menu.SYSTEM_ALL': '全体',
    'Code.Menu.SYSTEM_WILBE_BUSINESS': '会員社',
    'Code.Menu.SYSTEM_WILBE_PROVIDER': '供給社',
    'Code.Menu.SHORT': 'システム短縮',
    'Code.Menu.BUSINESS': '会員社',
    'Code.Menu.PROVIDER': '供給社',
    'Code.Menu.MenuType.Screen': '画面',
    'Code.Menu.MenuType.Tab': 'タブ',
    'Code.Menu.MenuType.Button': 'ボタン',
    'Code.Terms.Group': 'グループ',
    'Code.Terms.Group.PrivateRental': '貸渡約款',
    'Code.Terms.Group.BusinessRental': '貸渡約款',
    'Code.Terms.Group.WibleBizAdmin': 'MOCEAN Car Sharing FMS 約款',
    'Code.Terms.Group.WibleBizApp': 'MOCEAN Car Sharing App 約款',
    'Code.Terms.Group.MemberAdmin': 'クライアントアドミン約款',
    'Code.Terms.Type': 'タイプ',
    'Code.Terms.Type.Join': '入会',
    'Code.Terms.Type.Join.ServiceUse': '利用約款(必須)',
    'Code.Terms.Type.Join.PrivacyConsigment': '個人情報収集及び利用同意',
    // 'Code.Terms.Type.Join.LocationBased': '位置情報基盤サービスの利用同意（必須)',
    'Code.Terms.Type.Join.3rdParty': '個人情報を第三者への提供同意(必須)',
    'Code.Terms.Type.Join.LocationBusiness': '位置情報利用約款（必須）',
    'Code.Terms.Type.Join.Marketing': 'マーケティング情報受信同意（必須）',
    // 'Code.Terms.Type.Join.CarRent': '自動車貸出の約款（必須）',
    // 'Code.Terms.Type.Join.PrivacyUse': '個人情報活用の同意（選択）',
    'Code.Terms.Type.BizRequest': '企業相談',
    'Code.Terms.Type.BizRequest.PrivacyConsigment':
        '個人情報収集及び利用同意(必須)',
    'Code.Terms.Type.BizJoin': 'ビジネスプロフィール作成',
    'Code.Terms.Type.BizJoin.Profile': 'ビジネスプロフィール利用約款(必須)',
    'Code.Terms.Type.BizJoin.PrivacyUse': '個人情報収集及び利用同意(必須)',
    'Code.Terms.Type.Payment': '決済情報登録',
    'Code.Terms.Type.Payment.ServiceUse':
        'ブルーウォルナッツ(Blue walnut)電子決済代行サービスの利用約款(必須)',
    'Code.Terms.Type.Payment.Privacy':
        'ブルーウォルナッツ(Blue walnut)個人情報収集及び利用同意（必須）',
    'Code.Terms.Type.Payment.3rdParty':
        'ブルーウォルナッツ(Blue walnut)個人情報収集及び利用同意（必須）',
    'Code.Terms.Type.Payment.Subscription':
        'ブルーウォルナッツ(Blue walnut)自動決済の約款（必須）',
    // 'Code.Terms.Type.Payment.Efinance': '電子金融取引の利用約款(必須)',
    // 'Code.Terms.Type.Payment.PrivacyUse': '個人情報活用の同意(必須)',
    'Code.Terms.Type.Driver': '運転免許証登録',
    'Code.Terms.Type.Driver.LicenseUse': '固有識別情報収集及び利用の同意(必須)',
    'Code.Terms.Type.Rent': '貸出',
    'Code.Terms.Type.Rent.CarRent':
        '自動車貸出の約款（標準約款基盤特約）(必須)',
    'Code.Terms.Type.Rent.PrivacyProcess': '個人情報収集及び利用同意(必須)',
    // 'Code.Terms.Type.Rent.Insurance': 車両障害免責制度の利用約款（必須）',
    'Code.Terms.Type.Rent.3rdParty': '個人情報第三者提供に同意(必須)',
    'Code.Terms.Type.Rent.LocationUse': '位置情報利用約款(必須)',
    'Code.Terms.Type.Rent.Kia.Use': 'Kia Connect 利用約款(必須)',
    'Code.Terms.Type.Rent.Location.Business':
        'Kia Connect 位置情報事業の利用約款(必須)',
    'Code.Terms.Type.Rent.Location.Based':
        'Kia Connect 位置情報基盤サービス利用約款の同意(必須)',
    'Code.Terms.Type.Rent.Kia.Privacy':
        'Kia Connect 個人情報取り扱い方針(必須)',
    'Code.Terms.Type.Withdrawal': '退会',
    // 'Code.Terms.Type.Withdrawal.Secession': '退会約款',
    'Code.Terms.Type.MoceanWithdrawal': '統合退会',
    // 'Code.Terms.Type.MoceanWithdrawal.Secession': '統合退会',
    'Code.Terms.Type.StartAdmin': '提示約款(共通)',
    // 'Code.Terms.Type.StartAdmin.AdminUse': '利用約款',
    'Code.Terms.Type.StartAdmin.AdminPrivacy': '個人情報取り扱い方針',
    'Code.Terms.Type.UseNoti': 'ログイン利用同意案内',
    'Code.Terms.Type.UseNoti.Noti': 'ログイン利用同意案内',
    'Code.CustomerService.StatusCd.Request': '待機',
    'Code.CustomerService.StatusCd.Processing': '処理中',
    'Code.CustomerService.StatusCd.Pending': '保留',
    'Code.CustomerService.StatusCd.Closed': '完了',
    'Code.CustomerService.StatusCd.Etc': 'その他',
    'Code.Operator.ActivationStatusCd.Guest': 'お客様',
    'Code.Operator.ActivationStatusCd.PendingApprove': '承認待ち',
    'Code.Operator.ActivationStatusCd.Deny': '却下',
    'Code.Operator.ActivationStatusCd.Normal': '承認',
    'Code.Operator.ActivationStatusCd.Inactive': '非活性',
    'Code.Operator.ActivationStatusCd.Blocked': 'ブロック',
    'Code.Operator.ActivationStatusCd.Leave': '退会',
    'Code.Operator.SystemType.B2BG': 'MOCEAN',
    'Code.Operator.SystemType.PARTNER': '委託社',
    'Code.CodeManagement.GroupType': 'コード項',
    'Code.CodeManagement.ValueType': 'コード項',
    'Code.MessageTemplate.SendProgram.Wiblebiz': 'MOCEAN',
    'Code.MessageTemplate.SendProgram.App': 'アプリ',
    'Code.MessageTemplate.SendProgram.All': '共通',
    'Code.MessageTemplate.MessageClassCd.0': 'LINE',
    'Code.MessageTemplate.MessageClassCd.2': 'SMS/LMS',
    'Code.MessageTemplate.MessageClassCd.5': 'プッシュ',
    'Code.MessageTemplate.MessageClassCd.6': '電子メール',
    'Code.MessageTemplate.MessageTypeCd.Reservation': '予約',
    'Code.MessageTemplate.MessageTypeCd.Rent': '貸出',
    'Code.MessageTemplate.MessageTypeCd.Return': '返却',
    'Code.MessageTemplate.MessageTypeCd.Claim': '請求',
    'Code.MessageTemplate.MessageTypeCd.Receivable': '未収',
    'Code.MessageTemplate.MessageTypeCd.Maintenance': '車両維持',
    'Code.MessageTemplate.MessageTypeCd.Etc': 'その他',
    'Code.MessageTemplate.UseYn.0': '使用中止',
    'Code.MessageTemplate.UseYn.1': '使用',
    'Code.MessageTemplate.AdmsgYn.N': '一般',
    'Code.MessageTemplate.AdmsgYn.Y': '広告',
    'Code.Trade.TradeType.Sale': '売却',
    'Code.Trade.TradeType.Scrap': '廃車',
    'Code.PassiveMessage.Manager': '担当者',
    'Code.PassiveMessage.CreateSendType.Realtime': 'リアルタイム',
    'Code.PassiveMessage.CreateSendType.Reservation': '予約',
    'Code.PassiveMessage.MsgTypeCd.Sms': 'SMS',
    'Code.PassiveMessage.MsgTypeCd.Lms': 'LMS',
    'Code.PassiveMessage.MsgTypeCd.Push': 'Push',
    'Code.PassiveMessage.MsgTypeCd.Email': 'Email',
    'Code.PassiveMessage.NoticeTypeCd.Normal': '一般',
    'Code.PassiveMessage.NoticeTypeCd.Advertising': '広告',
    'Code.PassiveMessage.NoticeTypeCd.Important': '重要',
    'Code.PassiveMessage.MsgStatus.1': '送信予約',
    'Code.PassiveMessage.MsgStatus.2': '送信キャンセル',
    'Code.PassiveMessage.MsgStatus.3': '送信完了',
    'Code.HistoryMsgTemplate.Type.0': 'LINE',
    // 'Code.HistoryMsgTemplate.Type.2': 'SMS/LMS',
    'Code.HistoryMsgTemplate.Type.2': 'SMS',
    'Code.HistoryMsgTemplate.Type.3': 'LMS',
    'Code.HistoryMsgTemplate.Type.4': 'MMS',
    'Code.HistoryMsgTemplate.Type.5': 'プッシュ',
    'Code.HistoryMsgTemplate.Type.6': '電子メール',
    'Code.HistoryMsgTemplate.MessageType.Reservation': '予約',
    'Code.HistoryMsgTemplate.MessageType.Subscribe': 'サブスクリプション',
    'Code.HistoryMsgTemplate.MessageType.Rent': '貸出',
    'Code.HistoryMsgTemplate.MessageType.Return': '返却',
    'Code.HistoryMsgTemplate.MessageType.Claim': '請求',
    'Code.HistoryMsgTemplate.MessageType.Receivable': '未払い',
    'Code.HistoryMsgTemplate.MessageType.Maintenance': '維持補修',
    'Code.HistoryMsgTemplate.MessageType.User': '使用者',
    'Code.HistoryMsgTemplate.MessageType.Noticontrol': '受動送信',
    'Code.HistoryMsgTemplate.MessageType.Etc': 'その他',
    'Code.Receivable.ProfileType.Businiss': 'ビジネス',
    'Code.Receivable.ProfileType.Person': '個人',
    'Code.Receivable.ReceivableType.ReserVation': '貸出料金',
    'Code.Receivable.ReceivableType.Driving': '走行料金',
    'Code.Receivable.ReceivableType.Toll': '通行料金',
    'Code.Receivable.ReceivableType.Penalty': 'ペナルティ',
    'Code.Program.Action.Inquiry': '読み',
    'Code.Program.Action.Save': '書き',
    'Code.Program.Action.Del': '削除',
    'Code.Program.Action.Down': 'ダウンロード',
    'Code.Coupon.CouponTypeCd': 'クーポンタイプ',
    'Code.Coupon.CouponTypeCd.DOWN': 'ダウンロード',
    'Code.Coupon.CouponTypeCd.RANDOM': 'ランダム',
    'Code.Coupon.CouponTypeCd.CODE': 'コード',
    'Code.Coupon.CouponTypeCd.REFERRAL': '紹介者',
    'Code.Coupon.CouponTypeCd.SYSTEM': 'システム',
    'Code.Coupon.DiscountTypeCd': '割引タイプ',
    'Code.Coupon.DiscountTypeCd.TIME': '時間',
    'Code.Coupon.DiscountTypeCd.AMOUNT': '金額',
    'Code.Coupon.DiscountTypeCd.DISCOUNTRATE': '割引率',
    'Code.Coupon.DiscountTypeCd.ONEDAY': '割引率(1day+)',
    'Code.Coupon.DiscountTypeCd.FIXEDAMOUNT': '定額券',
    'Code.Coupon.Status': '状態',
    'Code.Coupon.Status.Y': '適用',
    'Code.Coupon.Status.N': '解除',
    'Code.Coupon.ProfileTypeCd': 'タイプ',
    'Code.Coupon.ProfileTypeCd.BUSINESS': 'ビジネス',
    'Code.Coupon.ProfileTypeCd.PERSONAL': '個人',
    'Code.Coupon.ProfileTypeCd.ALL': '公用',
    'Code.Coupon.Weekdays.Holidays': '平日/休日',
    'Code.Coupon.Weekdays.WEEKDAY': '平日',
    'Code.Coupon.Weekdays.WEEKEND': '休日',
    'Code.Coupon.Weekdays.ALL': '公用',
    'Code.Coupon.CouponClassCd': '自動発行区分',
    'Code.Coupon.CouponClassCd.Select': '自動発行区分選択',
    'Code.Coupon.CouponClassCd.RETURN_REWARD': '返却時、リワードクーポン',
    'Code.Coupon.CouponClassCd.SIGN_UP': '入会クーポン',
    'Code.Coupon.CouponClassCd.REFERRAL': '友達招待クーポン',
    'Code.Coupon.CouponClassCd.LEVEL2_UP': 'レベルアップクーポン(Lv.2)',
    'Code.Coupon.CouponClassCd.LEVEL3_UP': 'レベルアップクーポン(Lv.3)',
    'Code.Coupon.CouponClassCd.SUPER': 'レベルアップクーポン(Super)',
    'Code.Coupon.CouponClassCd.LEVEL2_UP_MONTHLY':
        'レベルアップ月間クーポン(Lv.2)',
    'Code.Coupon.CouponClassCd.LEVEL3_UP_MONTHLY':
        'レベルアップ月間クーポン(Lv.3)',
    'Code.Coupon.CouponClassCd.SUPER _MONTHLY':
        'レベルアップ月間クーポン(Super)',
    'Code.Coupon.CouponClassCd.ALLIANCE': '提携クーポン',
    'Code.Coupon.CouponClassCd.VOC': '相談用リワードクーポン',
    'Code.Coupon.LedgerType.Random': 'ランダム発行',
    'Code.Coupon.LedgerType.User': '会員発行',
    'Code.CustomerUser.ActivationStatusCd.REGULAR': '会員',
    'Code.CustomerUser.ActivationStatusCd.ASSOCIATE': '準会員',
    'Code.CustomerUser.ActivationStatusCd.LEAVE': '退会済み',
    'Code.CustomerUser.ActivationStatusCd.GUEST': 'お客様',
    'Code.CustomerUser.ActivationStatusCd.G1': 'Greener Lv.1',
    'Code.CustomerUser.ActivationStatusCd.G2': 'Greener Lv.2',
    'Code.CustomerUser.ActivationStatusCd.G3': 'Greener Lv.3',
    'Code.CustomerUser.ActivationStatusCd.G0': 'Super Greener',
    'Code.Charge.ProfileTypeCd': 'プロフィールタイプ',
    'Code.Charge.ProfileTypeCd.BUSINESS': 'ビジネス',
    'Code.Charge.ProfileTypeCd.PERSONAL': '個人',
    'Code.Charge.ChargeTypeCd': '料金のタイプ',
    'Code.Charge.ChargeTypeCd.TIMEBASE': 'Time-based',
    'Code.Charge.ChargeTypeCd.SPECIALONE': '特価(1回券)',
    'Code.Charge.ChargeTypeCd.SPECIALSUBSCRIPTION':
        '特価(サブスクリプション券)',
    'Code.Charge.ChargeTypeCd.ONEDAYPLUS': '特価(1Day+)',
    'Code.Charge.ChargeTypeCd.Space': '空間料金制', // JP
    'Code.Charge.ChargeTypeCd.Camping': 'キャンピング料金制', // JP
    'Code.Charge.ChargeGroupCd': '料金グループ',
    'Code.Charge.ChargeGroupCd.WEEKDAY': '夜間パッケージ',
    'Code.Charge.ChargeGroupCd.WEEKEND': '週末パッケージ',
    'Code.Charge.ChargeGroupCd.SUPER': 'プレミアム',
    'Code.Charge.ChargeSpecialType': '特価料金タイプ',
    'Code.Location.PersonalCd.OPEN': 'オープン型',
    'Code.Location.PersonalCd.CLOSED': 'クローズ型',
    'Code.Location.UseYn.Y': '運営',
    'Code.Location.UseYn.N': '未運営',
    'Code.Location.UndergroundYn.N': '地上',
    'Code.Location.UndergroundYn.Y': '地下',
    'Code.Location.IndoorYn.0': '未選択',
    'Code.Location.IndoorYn.1': '室外',
    'Code.Location.IndoorYn.2': '室内',
    'Code.Location.Radius.50': '50m',
    'Code.Location.Radius.100': '100m',
    'Code.Location.Radius.300': '300m',
    'Code.Location.Radius.500': '500m',
    'Code.AppVersion.Group.WibleBizApp': 'MOCEAN Car Sharing App',
    'Code.AppVersion.OS.iOS': 'iOS',
    'Code.AppVersion.OS.Android': 'アンドロイド',
    'Code.Penalty.PenaltyCategoryCd.Reservation': '予約及び利用',
    'Code.Penalty.PenaltyCategoryCd.Return': '返却',
    'Code.Penalty.PenaltyCategoryCd.Zone': 'ステーション管理',
    'Code.Penalty.PenaltyCategoryCd.Cleaness': '車両清潔状態保持',
    'Code.Penalty.PenaltyCategoryCd.Damage': '自動車紛失、盗難、破損',
    'Code.Penalty.PenaltyCategoryCd.Compensation': '休車補償料',
    'Code.Penalty.PenaltyId.A-RV01':
        '(予約者未乗車状態で)同乗運転者が単独で運転した場合',
    'Code.Penalty.PenaltyId.A-RV02': '第三者自動車運転',
    'Code.Penalty.PenaltyId.A-RV03': '飲酒及び薬物運転した場合',
    'Code.Penalty.PenaltyId.B-RT01': '利用終了時刻を超過',
    'Code.Penalty.PenaltyId.B-RT02': '充電残量警告灯が点灯した状態で返却',
    'Code.Penalty.PenaltyId.B-RT03':
        '約定充電量未満で返却（車両別別途ポリシー）',
    'Code.Penalty.PenaltyId.B-RT04':
        '返却時、充電器の未接続(車両別別途ポリシー)',
    'Code.Penalty.PenaltyId.B-RT05': '車両内・外部の未消灯などによる放電',
    'Code.Penalty.PenaltyId.B-RT06':
        '車両のドア、窓、トランクなどの開放返却による車内棄損',
    'Code.Penalty.PenaltyId.B-RT07': '指定ステーションへ未返却',
    'Code.Penalty.PenaltyId.C-ZN01': 'ステーション内へゴミ無断投棄',
    'Code.Penalty.PenaltyId.C-ZN02':
        'ご本人の移動手段（車、オートバイ、自転車など）をステーションに駐車',
    'Code.Penalty.PenaltyId.D-CL01':
        '車内喫煙行為の発覚（タバコ、電子タバコなど）',
    'Code.Penalty.PenaltyId.D-CL02':
        '車内へのゴミ及び汚物の投棄や放置による汚れや悪臭の誘発',
    'Code.Penalty.PenaltyId.D-CL03': 'ペット同乗による車内汚染',
    'Code.Penalty.PenaltyId.E-DM01': '貸出期間中、自動車事故及び破損の未申告',
    'Code.Penalty.PenaltyId.E-DM02': '車両の任意修理及び分解',
    'Code.Penalty.PenaltyId.E-DM03': '充填/充電のカード紛失',
    'Code.Penalty.PenaltyId.E-DM04': '私用車で充填/充電カードを利用',
    'Code.Penalty.PenaltyId.E-DM05': '自動車キー紛失及び破損',
    'Code.Penalty.PenaltyId.E-DM06': '室内部品を破損及び盗難',
    'Code.Penalty.PenaltyId.F-CM01': '車両を破損、盗難',
    'Code.Violation.Category.0': '信号違反',
    'Code.Violation.Category.1': '速度違反',
    'Code.Violation.Category.3': '駐車違反',
    'Code.VocMgmt.Category.SIGN_UP': '入会/免許/退会',
    'Code.VocMgmt.Category.RESERVATION': '予約/決済/キャンセル/払い戻し',
    'Code.VocMgmt.Category.RETURN': '運行/返却',
    'Code.VocMgmt.Category.INSURANCE': '保険/事故',
    'Code.VocMgmt.Category.WASH': '洗車/充電/充填',
    'Code.VocMgmt.Category.COUPON': 'クーポン',
    'Code.VocMgmt.Category.DRIVING': '運行/返却',
    'Code.VocMgmt.Category.EVENT': 'イベント',
    'Code.VocMgmt.Category.BIZ_CONTRACT': 'ビジネス契約',
    'Code.VocMgmt.Category.ERROR': 'エラー',
    'Code.VocMgmt.Category.COMPLAINTS': 'クレーム受付',
    'Code.VocMgmt.Category.ETC': 'その他',
    'Code.VocMgmt.Result.WAIT': '待機中',
    'Code.VocMgmt.Result.ING': '処理中',
    'Code.VocMgmt.Result.HOLD': '保留',
    'Code.VocMgmt.Result.ETC': 'その他',
    'Code.VocMgmt.Result.COMPLETED': '完了',
    'Code.VocMgmt.UserSearch.Input': '直接入力',
    'Code.VocMgmt.UserSearch.Search': '会員検索',
    'Code.Common.Y': 'Y',
    'Code.Common.N': 'N',
    'Code.Common.Possible': '可',
    'Code.Common.Impossible': '不可',
    'Code.CSCenterNotice.NoticeTypeCd.POLICY': 'サービスポリシー',
    'Code.CSCenterNotice.NoticeTypeCd.MARKETING': 'マーケティング',
    'Code.CSCenterNotice.NoticeTypeCd.Etc': 'その他',
    'Code.CSCenterNotice.MarketingTypeCd.Event': 'イベント',
    'Code.CSCenterNotice.MarketingTypeCd.Promo': '割引プロモーション',
    'Code.CSCenterNotice.MarketingTypeCd.Etc': 'その他',
    'Code.CSCenterNotice.MarketingPurposeCd.New': '新規会員の保持',
    'Code.CSCenterNotice.MarketingPurposeCd.Sales': '売上増大',
    'Code.CSCenterNotice.MarketingPurposeCd.Etc': 'その他',
    'Code.CSCenterNotice.ChannelCd.App': 'App内チャンネル',
    'Code.CSCenterNotice.ChannelCd.Push': 'PUSH',
    'Code.CSCenterNotice.ChannelCd.Lms': 'LMS',
    'Code.CSCenterNotice.ChannelCd.Email': 'E-MAIL',
    'Code.CSCenterNotice.ChannelCd.Advertising': '広告',
    'Code.CSCenterNotice.ChannelCd.Etc': 'その他',
    'Code.CommandType': 'タイプ',
    'Code.CommandType.0': 'UNKNOWN_COMMAND_TYPE',
    'Code.CommandType.1': '解錠',
    'Code.CommandType.2': '施錠',
    'Code.CommandType.3': '警笛',
    'Code.CommandType.4': 'トランクオープン',
    'Code.CommandType.5': '予約情報送信',
    'Code.CommandType.6': '返却要請',
    'Code.CommandType.7': '状態確認',
    'Code.CommandType.8': '端末機アップデート',
    'Code.CommandType.9': '端末機リセット',
    'Code.CommandType.10': 'fobコントロール',
    'Code.CommandType.11': '始動ブロック',
    'Code.CommandType.12': 'ハザードランプ',
    'Code.CommandType.13': 'PubKey発行',
    'Code.CommandType.14': 'SMS送信',
    'Code.CommandType.15': 'ハブURL変更',
    'Code.CommandType.16': 'GET_GASCARD',
    'Code.CommandType.41': 'AUDIO_LAN_DEFAULT',
    'Code.CommandType.43': 'PAUSE_PAUSE_STATE',
    'Code.CommandType.44': 'AUDIO_REQ_PLAY',
    'Code.CommandType.45': 'VEHICLE_FUELTYPE',
    'Code.CommandType.48': 'SET_INFO_STARTUSE',
    'Code.CommandType.52': 'SET_OVERALL',
    'Code.CommandType.53': 'PAUSE_RESUME_STATE',
    'Code.CommandState': '状態',
    'Code.CommandState.1': '待機',
    'Code.CommandState.2': '要請',
    'Code.CommandState.3': '要請失敗',
    'Code.CommandState.4': '成功',
    'Code.CommandState.5': '失敗',
    // 'Code.Bank.KB': 'KB国民',
    // 'Code.Bank.IBK': 'IBK企業銀行',
    // 'Code.Bank.NH': 'NH農協現行',
    // 'Code.Bank.SH': '新韓銀行',
    // 'Code.Bank.KEB': 'KEBハナ銀行',
    // 'Code.Bank.WR': 'ウリ銀行',
    // 'Code.Bank.KDB': 'KDB産業銀行',
    // 'Code.Bank.BS': 'プサン銀行',
    // 'Code.Bank.DG': 'テグ銀行',
    // 'Code.Bank.CITY': '韓国シティ銀行',
    // 'Code.Bank.SC': 'SC第一銀行',
    // 'Code.Bank.KN': '慶南銀行',
    // 'Code.Bank.GJ': '光州銀行',
    // 'Code.Bank.SEA': '水協銀行',
    // 'Code.Bank.JB': '全北銀行',
    // 'Code.Bank.JJ': '済州銀行',
    // 'Code.Bank.KAKAO': 'カカオバンク',
    // 'Code.Bank.K': 'Kバンク',

    'Code.SALES_CLASS_CD': '売上区分',
    'Code.SALES_CLASS_CD.RESERVATION': '貸出料金',
    'Code.SALES_CLASS_CD.ADDITION': '延長料金',
    'Code.SALES_CLASS_CD.SUBSCRIBE': 'サブスクリプション料金',
    'Code.SALES_CLASS_CD.DRIVING': '走行料金',
    'Code.SALES_CLASS_CD.TOLL': '通行料金',
    'Code.SALES_CLASS_CD.DELAY': '遅延料金',
    'Code.SALES_CLASS_CD.PENALTY': 'ペナルティ',
    'Code.SALES_CLASS_CD.MEMBERSHIP': 'メンバーシップ料金',
    'Code.SALES_CLASS_CD.ACCIDENT': '事故処理',
    'Code.SALES_CLASS_CD.COMMISSION': 'キャンセル料',
    'Code.SALES_CLASS_CD.INSURANCE': 'NOC補償入会料金',
    'Code.SALES_CLASS_CD.ETC': 'その他',
    'Code.SALES_CLASS_CD.REFUND': 'キャンセル後、再決済',

    'Code.PAYMENT_CLASS_CD': '決済区分',
    'Code.PAYMENT_CLASS_CD.RESERVATION': '貸出料金',
    'Code.PAYMENT_CLASS_CD.ADDITION': '延長料金',
    'Code.PAYMENT_CLASS_CD.SUBSCRIBE': 'サブスクリプション料金',
    'Code.PAYMENT_CLASS_CD.DRIVING': '走行料金',
    'Code.PAYMENT_CLASS_CD.TOLL': '通行料金',
    'Code.PAYMENT_CLASS_CD.DELAY': '遅延料金',
    'Code.PAYMENT_CLASS_CD.PENALTY': 'ペナルティ料金',
    'Code.PAYMENT_CLASS_CD.MEMBERSHIP': 'メンバーシップ料金',
    'Code.PAYMENT_CLASS_CD.ACCIDENT': '事故処理',
    'Code.PAYMENT_CLASS_CD.COMMISSION': 'キャンセル料',
    'Code.PAYMENT_CLASS_CD.INSURANCE': 'NOC補償入会料金',
    'Code.PAYMENT_CLASS_CD.ETC': 'その他',

    'Code.Subscribe.Status.Complete': 'サブスクリプション完了',
    'Code.Subscribe.Status.Start': 'サブスクリプション開始',
    'Code.Subscribe.Status.End': 'サブスクリプション終了',
    'Code.Subscribe.Status.Cancel': 'サブスクリプションキャンセル',
    'Code.Subscribe.Status.Wait': 'サブスクリプション待機',
    'Code.Subscribe.Status.Fail': '失敗',

    'Code.Subscribe.ClaimStaus': '請求状態',
    'Code.Subscribe.ClaimStaus.CLAIM': '決済予定',
    'Code.Subscribe.ClaimStaus.CANCEL': 'キャンセル',
    'Code.Subscribe.ClaimStaus.COMPLETE': '決済完了',

    'Code.Subscribe.PaymentStaus': '決済状態',
    'Code.Subscribe.PaymentStaus.PAYMENT': '決済',
    'Code.Subscribe.PaymentStaus.CANCEL': '決済キャンセル',
    'Code.Subscribe.PaymentStaus.CLAIM': '請求',

    'Code.Subscribe.ExtendChargeTypeCd.OneDay': '1Day+',
    'Code.Subscribe.ExtendChargeTypeCd.Timebased': 'すぐ退勤', // JP

    'Code.Banner.BannerType.Banner': 'バナー',
    'Code.Banner.BannerType.Splash': 'サービス紹介',
    'Code.Banner.BannerType.Popup.App': 'ポップアップ（アプリ）',
    'Code.Banner.BannerType.Popup.Admin': 'ポップアップ（アドミン）',

    'Code.Batch.Categories.R': '起動',
    'Code.Batch.Categories.S': '中断',
    'Code.Batch.Categories.F': '失敗',

    'Code.Customer.ActivationStatusCd.Associate': '準会員',
    'Code.Customer.ActivationStatusCd.Regular': '会員',

    'Code.Business.Partner.TypeCd.AllSelect': '分類全体',
    'Code.Business.Partner.TypeCd.Select': '分類選択',
    'Code.Business.Partner.TypeCd.VOC': 'カスタマーセンター',
    // 'Code.Business.Partner.TypeCd.REFUEL': '充電/充填',
    // 'Code.Business.Partner.TypeCd.MAINTENANCE': '整備士',
    'Code.Business.Partner.TypeCd.WASH': '洗車',
    // 'Code.Business.Partner.TypeCd.DEVICE': '装着エンジニア',
    // 'Code.Business.Partner.TypeCd.INSURANCE': '保険会社',
    // 'Code.Business.Partner.TypeCd.SHIPPING': '託送会社',
    'Code.Business.Partner.TypeCd.GM': '委託総括',
    'Code.Business.Partner.TypeCd.FIELD_MANAGER': 'フィールドマネージャー',
    'Code.Business.Partner.TypeCd.MAINTENANCE.PAID':
        'メンテナンス(保証/有償修理)',
    'Code.Business.Partner.TypeCd.MAINTENANCE.INSURANCE':
        'メンテナンス（事故/保険修理)',

    'Code.BannerMgmt.Scene.Drawer': 'ドロワー',
    'Code.BannerMgmt.Scene.Wible': 'ステーション',
    'Code.BannerMgmt.Scene.Map': 'マップ',
    // 'Code.BannerMgmt.Scene.Banner': 'バナー',
    'Code.BannerMgmt.Scene.Coupon': 'クーポンブック',

    'Code.Business.Invite.AuthYn.Y': 'ビジネスプロフィール作成',
    'Code.Business.Invite.AuthYn.N': 'ビジネスプロフィール未作成',

    'Code.BusinessContract.LicenseTypeCd.Basic': 'BIZ ベーシック',
    'Code.BusinessContract.LicenseTypeCd.Etc': 'その他',
    'Code.BusinessContract.LicenseTypeCd.PremiumDiscount':
        'BIZ プレミアム（割引型）',
    'Code.BusinessContract.LicenseTypeCd.PremiumEngagement':
        'BIZ プレミアム(約定型)',
    'Code.BusinessContract.LicenseTypeCd.Unlimited': 'BIZ 無制限',

    'Code.Charge.SpecialPlanType.OFFWORK': '夜間パッケージ',
    'Code.Charge.SpecialPlanType.LUNCH': 'ランチ券',

    // 'Code.License.Type.Large': '一種大型',
    // 'Code.License.Type.MotorizedBicycle': '二種原付',
    // 'Code.License.Type.Normal': '一種普通',
    // 'Code.License.Type.SecondNormal': '二種普通',
    // 'Code.License.Type.SecondSmall': '二種小型',
    // 'Code.License.Type.Small': '一種小型',
    // 'Code.License.Type.SmallTrailer': '小型レッカー（牽引）',
    // 'Code.License.Type.Trailer': '大型レッカー（牽引）',
    // 'Code.License.Type.Wrecker': '救助車',

    'Code.VocMgmt.CategoryCd.Complain': 'クレーム受付',
    'Code.VocMgmt.CategoryCd.Contact': 'ビジネス契約',
    'Code.VocMgmt.CategoryCd.Error': 'エラー',
    'Code.VocMgmt.CategoryCd.Etc': 'その他',
    'Code.VocMgmt.CategoryCd.Event': 'イベント',
    'Code.VocMgmt.CategoryCd.Fuel': '洗車/充電/充填',
    'Code.VocMgmt.CategoryCd.Insurance': '保険/事故',
    'Code.VocMgmt.CategoryCd.Level': 'クーポン',
    'Code.VocMgmt.CategoryCd.Reservation': '予約/決済/キャンセル/払い戻し',
    'Code.VocMgmt.CategoryCd.SignUp': '入会/運転免許/退会',

    'Code.VocMgmt.StatusCd.Closed': '完了',
    'Code.VocMgmt.StatusCd.Etc': 'その他',
    'Code.VocMgmt.StatusCd.Pending': '保留',
    'Code.VocMgmt.StatusCd.Processing': '処理中',
    'Code.VocMgmt.StatusCd.Request': '待機中',

    'Code.BusinessNotification.Profile': 'プロフィール',
    'Code.BusinessNotification.Payment': '決済',
    'Code.BusinessNotification.Accident': '事故',
    'Code.BusinessNotification.Penalty': 'ペナルティ',

    'Code.VehicleRepair.SelfVehicle.Type.1': '自車保険',
    'Code.VehicleRepair.SelfVehicle.Type.2': '一般有償',
    'Code.VehicleRepair.SelfVehicle.Type.3': '未修理',
    'Code.VehicleRepair.SelfVehicle.Type.4': '後日修理',

    'Code.Charge.PassPlanType': 'サブスクリプション券特価料金のタイプ',

    'Code.Maintenance.ManageType.Maintenance': '整備',
    'Code.Maintenance.ManageType.Refuel': '充電/充填',
    'Code.Maintenance.ManageType.Washing': '洗車',
    'Code.Maintenance.Status.Complete': '作業完了',
    'Code.Maintenance.Status.Wait': '作業待機',
    'Code.Maintenance.Status.Work': '作業中',

    'Code.Vehicle.Transmission.Auto': '自動',
    'Code.Vehicle.Transmission.Manual': '手動',

    // 'Code.Vehicle.Mid.wible0001m': 'wible0001m',
    // 'Code.Vehicle.Mid.wible0002m': 'wible0002m',
    // 'Code.Vehicle.Mid.wible0003m': 'wible0003m',
    // 'Code.Vehicle.Mid.wible0004m': 'wible0004m',
    // 'Code.Vehicle.Mid.jecar0002m': 'jecar0002m',

    'Code.Vehicle.SafetyOptions': '安全オプション',
    'Code.Vehicle.ConvenienceOptions': '利便性オプション',
    // バックカメラ, エアーバック, AVシステム, Bluetooth, ドライブレコーダー, ドライブワイズ
    'Code.Vehicle.Options.Rearsensor': 'バックカメラ',
    'Code.Vehicle.Options.Airbag': 'エアーバック',
    'Code.Vehicle.Options.AV': 'AVシステム',
    'Code.Vehicle.Options.Bluetooth': 'Bluetooth',
    'Code.Vehicle.Options.Dashcam': 'ドライブレコーダー',
    'Code.Vehicle.Options.IntelligentDrivingAssit': 'ドライブワイズ',
    // ナビゲーション, ETC Card, GPS, USB, エアコン, シートヒーター, AUX, 携帯電話　無線充電システム, 220V インバーター
    'Code.Vehicle.Options.Navigation': 'ナビゲーション',
    'Code.Vehicle.Options.Hipass': 'ETC Card',
    'Code.Vehicle.Options.GPS': 'GPS',
    'Code.Vehicle.Options.USB': 'USB',
    'Code.Vehicle.Options.Airconditioner': 'エアコン',
    'Code.Vehicle.Options.Heatedseat': 'シートヒーター',
    'Code.Vehicle.Options.AUX': 'AUX',
    'Code.Vehicle.Options.Wirelesscharging': '携帯無線充電システム',
    'Code.Vehicle.Options.220V': '220Vインバーター',

    'Code.Notice.Target.App': '個人',
    'Code.Notice.Target.AppBusiness': 'ビジネス',
    'Code.Notice.Target.Business': '会員社',
    'Code.Notice.Target.Voc': 'カスタマーセンター',

    'Code.Coupon.Target.BUSINESS': 'ビジネス',
    'Code.Coupon.Target.': '個人',

    'Code.Role.System.Group.B2BG': 'MOCEAN',
    'Code.Role.System.Group.BUSINESS': 'クライアントアドミン',
    'Code.Role.System.Group.PARTNER': '委託社',
    'Code.Role.System.Group.MIP_OPERATOR': '装着エンジニア',

    'Code.Reservation.PaymentCardCd.Personal': '個人クレジットカード',
    'Code.ChargeRefuelCard.Company': '充電/充填カード社',
    // 'Code.ChargeRefuelCard.Company.ELECTRICITY': '電気車の充電事業者',

    'Code.Extra.ChargeType.FIXED': '定額タイプ', // JP
    'Code.Extra.ChargeType.PROPORTION': '比例タイプ', // JP

    'Code.Location.contentType.STATION': 'ステーション案内', // JP
    'Code.Location.contentType.WIFI': 'WIFIテザリング ', // JP
    'Code.Location.contentType.CAMPING': 'キャンピング料金案内', // JP

    /** exception - common*/
    910001: '登録できませんでした。',
    910002: '修正できませんでした。',
    910003: '削除できませんでした。',
    910004: 'データ処理ができませんでした。',
    910005: 'データが見つかりません。',
    920001: 'ログインセッション照会できませんでした。',
    930001: 'データの変換ができませんでした。',
    930002: 'データの変換が見つかりません。',
    940001: 'エクセルのダウンロードができませんでした。',
    940002: 'エクセルをアップロードできませんでした。',
    940005: '10,000件を超えました。{br}照会条件を変更してからもう一度お試しください。',
    950001: 'ファイルが見つかりません。',
    950002: 'ファイルを作成できません。',
    950003: 'ファイルを削除できません。',
    950004: 'ファイルタイプが見つかりません。',
    950005: 'ファイルグループタイプが見つかりません。',
    950006: 'ファイルグループアカウントが見つかりません。',
    950007: '間違ったファイル形式です。',
    950008: 'ファイルを開けません。',
    950009: 'ファイルを保存できません。',

    /** exception - handle(前2桁) + prossecer(後ろ4桁) (100001はhandle 自体エラー)*/
    // 車両管理 >車両登録/照会/
    330002: '予約履歴のある車両は削除できません。',
    330003: '端末機の取り付けられた車両は削除できません。',
    330004: '車が予約中です。 キャンセル/返却後、強制削除してください。',
    330005: '車が予約中です。 キャンセル/返品後、サプライヤーを変更してください。',

    // 車両管理>車種(車両モデル)
    340003: 'マッピングされた車両があります。',
    340004: 'マッピングされた下位車種があります。',
    340005: 'マッピングされた車種別の利用情報があります。',

    // 車両管理　> 端末機
    470001: '存在しないVINコードです。',

    // 車両管理 > 維持管理/整備履歴
    550002: '現在利用中の車両は作業を始められません。',
    550003: '既に作業予約中の車両です。',
    550005: '重複した日程があります。',

    // ステーション管理 > ステーション　登録/照会
    310001: '利用できないステーション',
    310002: '利用できない車両です。',
    310003: '利用できない料金タイプです。',
    310004: '契約情報が見つかりません。',
    310005: '料金の保険情報が見つかりません。',
    310006: '車両のスケジュール情報が見つかりません。',
    310008: '予約情報が見つかりません。',
    310009: 'マッピングされた車両のあるステーションは削除できません。',
    310010: '会員社契約されたステーションは削除できません。',
    310012: '有効ではない時間です。',
    310013: '予約/貸出中の車両です。',
    310014: '返却されていません。',
    313101: '会員社商品情報が見つかりません。',
    313102: '貸出情報が見つかりません。',
    313103: '保険情報が見つかりません。',

    // システム管理 > メニュー
    100001: '下位メニューが存在するので削除できません。',
    101001: '下位プログラムが存在するので削除できません。',

    // システム管理 > コード
    122001: '既に登録されたグループキーがあります。',
    120001: '下位コードが存在するので削除できません。',

    // コンテンツ管理 > お知らせテンプレート
    151501: 'テンプレートコードが重複して登録できません。',

    // 運営管理 > バージョン
    161901: 'バージョンが重複して登録できません。',
    512305: '会社または部署内の担当者が2人以上の場合のみ、ビジネスプロフィールを削除できます。 ビジネス プロファイルを削除する場合は、他の役職員に権限を移管してください。',

    // その他 > マイページ
    513101: '会社または部署内の担当者が2人以上の場合のみ、ビジネスプロフィールを削除できます。ビジネスプロフィールを削除したい場合、他の役職員に 権限（バーミッション）を移管してください。',
    513102: '予約/貸出中の車両がない場合のみ、ビジネスプロフィールを削除できます。ビジネスプロフィールを削除したい場合、貸出中の車両を返却するか、予約内訳をキャンセルしてください。',

    // 入会 > スーパー管理者/部署担当者
    515102: '利用権限（バーミッション）のない状態です。社内のMOCEAN Car Sharing担当者またはカスタマーセンターにお問い合わせください。',
    515103: '管理者にお問い合わせください。[コード: {errorCode}]',
    // マイページ >権限（バーミッション）の移管
    515106: '管理者を最低一人以上登録しなければなりません。',

    // 会員/部署管理 >会員
    480001: '登録されたクレジットカードが一枚以上ある場合、会員に変換できます。',
    // 740001: '貸出中の情報が存在するため免許証情報の削除ができません。',
    // 740001: '貸出中の車両がない場合のみ、免許証情報の削除ができます。免許書情報を削除したい場合、貸出中の車両を返却してください。',
    740001: '貸出を利用中の場合、免許証情報の削除ができません。', // JP

    // 会員/部署管理> 役職員の照会
    523101: '会社または部署内の担当者が二人以上の場合のみ、ビジネスプロフィールを削除できます。ビジネスプロフィールを削除する場合、他の役職員に権限（バーミッション）を移管してください。',
    523102: '予約/貸出中の車両がない場合のみ、ビジネスプロフィールを削除できます。ビジネスプロフィールを削除する場合、貸出中の車両を返却するか予約内訳をキャンセルしてください。',

    // 会員/部署管理> 役職員の登録
    523105: '一致しない電子メールの様式・書式です。',
    523111: '重複する電子メールです。',

    //会員/部署管理 > 部署の管理
    382901: '重複する部署名です。',
    382902: '会社または部署内の担当者が二人以上の場合のみ、ビジネスプロフィールを削除できます。ビジネスプロフィールを削除する場合、他の役職員に権限（バーミッション）を移管してください。',
    382903: '予約/貸出中の車両がない場合のみ、ビジネスプロフィールを削除できます。ビジネスプロフィールを削除したい場合、貸出中の車両を返却するか予約内訳をキャンセルしてください。',

    // 会員/部署管理 > ペナルティ
    233502: '該当ペナルティ項目と違反事項として、既に登録されたポリシーが存在します。',

    // 売上/精算管理 > クーポン
    350001: '最大発行可能なクーポンの数量を超えました。',
    350002: '既に発行されたため修正できません。',
    350003: '既に発行されたクーポンは削除できません。',
    350004: '重複発行可能なクーポンの数量を超えました。',
    354106: 'クーポンコードが重複するため登録できません。',
    350021: 'クーポン情報が合わないため発行できません。',
    410003: '提携クーポン番号が重複するため登録できません。',
    410004: '提携クーポンは既に発行されました。',

    // 貸出/決済管理 > 貸出管理
    403000: '既に作業進行中の要請です。',
    403001: '車両情報が見つかりません。',
    403002: '車両モデルが見つかりません。',
    403003: 'ステーション情報が見つかりません。',
    403004: 'ステーション情報が見つかりません。',
    403005: '会員社の情報が見つかりません。',
    403006: '会員社の契約情報が見つかりません。',
    403007: '貸出会員情報が見つかりません。',
    403008: '乗車者情報が見つかりません。',
    403009: '貸出会員の運転免許証情報が見つかりません。',
    403010: '乗車者の運転免許情報が見つかりません。',
    403011: '貸出会員の運転免許証の有効性検査に失敗しました。',
    403012: '乗車者の運転免許証の有効性検査に失敗しました。',
    403013: '料金制の情報が見つかりません。',
    403014: '貸出キーを作成できませんでした。',
    403015: '予約できません。(403015)',
    403016: '決済用クレジットカードの情報が見つかりません。',
    403017: '決済できませんでした。',
    403018: 'キャンセルできる状態ではございません。',
    403019: '延長できる状態ではございません。',
    403020: '既に予約された貸出情報があります。',
    403021: '制御履歴が存在するため、キャンセルできません。',
    403022: '車両位置と返却位置が一致しません。',
    403023: '車両状態の情報が見つかりません。',
    403024: '車両状態の条件が見つかりません。',
    403025: '車両の走行距離情報が見つかりません。',
    403026: '車両の燃料の残量情報が見つかりません。',
    403027: '車両の位置が見つかりません。',
    403028: '車両の位置コードが0です。',
    403029: '車両の充電残余量が見つかりません。',
    403030: '会員のペナルティ情報が作成されませんでした。',
    403031: '会員のリワードクーポンが作成されませんでした。',
    403032: '車両の端末機情報が見つかりません。',
    403033: '返却を送信できませんでした。',
    403034: '車両のMID情報が見つかりません。',
    403035: '請求情報を登録できませんでした。',
    403036: '未払い金があります。',
    403038: '部署情報が見つかりません。',
    403039: '返却可能な状態ではありません。',
    403040: '利用可能な状態ではありません。',
    403041: '車両が未返却状態です。',
    403042: '支払いのキャンセルに失敗しました。',
    403044: '予約キャンセル可能な時間が経過しているため、予約キャンセルが出来ません。',
    403045: 'セット商品キーが作成できませんでした。',
    403046: '車両の要請時間に別の予約が存在します。',
    403047: '同乗者はペナルティのため、利用停止状態です。',
    403048: '車両の充電器が接続されていません。',
    403049: '貸出会員はペナルティのため、利用停止状態です。',
    403050: '貸出料金が一致しません。',
    403051: '保険料が一致しません。',
    403052: 'クーポン割引額が一致しません。',
    403053: 'メンバーシップ割引額が一致しません。',
    403054: '料金の割引額が一致しません。',
    403055: '決済料金が一致しません。',
    403056: '使用者に割り当てられたクーポンではございません。',
    403057: '会員社のカード情報が存在しません。',
    403078: '開始日時が既存の予約の開始日時より遅いです。',
    403079: '返却日時が既存の予約の返却日時より早いです。',
    403080: '約定の残り時間が足りません。',
    403081: '予約要請時間が約定の残り時間より長いです。',
    403082: '予約状態が変更されたため、処理できません。',
    403083: '処理できる時間ではありません。', // [예약] 노쇼(시간검증)
    403084: '制御履歴が存在するため、処理できません。',
    403092: '貸出会員のパスポートの有効性検査に失敗しました。',
    403093: '乗車者のパスポート情報が見つかりません。',
    403094: '乗車者のパスポートの有効性検査に失敗しました。',
    403095: '貸出会員のパスポート情報が見つかりません。',

    503046: '予約情報のある車両です。',
    503050: '貸出料金が一致しません。',
    503051: '保険料が一致しません。',
    503052: 'クーポンの割引額が一致しません。',
    503053: 'メンバーシップの割引額が一致しません。',
    503054: '料金の割引額が一致しません。',
    503055: '決済料金が一致しません。',
    503056: 'クーポンの対象者ではございません。',
    503059: '既に貸出が開始しました。',
    503337: '車両のスケジュール情報が登録されていません。',

    //貸出/サブスクリプション管理> サブスクリプションキャンセル
    503018: 'キャンセルできる状態ではございません。',
    503316: 'カードの情報が見つかりません。',
    503060: '既にキャンセルされたサブスクリプションです。',

    // 貸出/決済管理> 決済クレジットカード
    454002: '電子決済代行サービス (PG) 連動エラーのため登録できません。',
    454003: '既に登録されたカードが存在するため、登録できません。',
    454004: 'カードBIN情報が有効でないため、登録できません。',
    454005: '当該カードは利用できない加盟店(他クレジットカードの利用要望)のため、登録できません。',
    454006: '電子決済代行サービス (PG) 連動エラー(カード情報を確認してください)のため、登録できません。',
    454007: '電子決済代行サービス (PG) 連動エラー(カード情報を確認してください)のため、登録できません。', // 臨時フレーズ(BID 生成エラー)
    454008: '既に登録されたカードが存在するため、登録できません。', // 臨時フレーズ(重複カード)

    // 貸出/決済管理 > 決済用クレジットカード
    413412: '削除できません。進行中の貸出内訳が存在します。',
    413413: '削除できません。アクティブサブスクリプション内訳が存在します。',

    // コンテンツ管理 > 約款
    270002: '削除できません。利用中の約款が存在します。',

    //会員社の契約登録
    292901: 'スーパー管理者は5人まで登録できます。', // JP
    224201: '契約期間が重複した契約があります。',

    //事故修理履歴の登録
    490001: '既に事故修理が登録された状態です。',

    // コンテンツ管理 > お知らせメッセージ > 手動メッセージ
    690001: 'エクセル内の受信対象ではございません。',

    // 車両管理 > 供給社登録/照会
    180001: 'マッピングされた車両のある供給社は削除できません。',

    // 運営管理 > 委託社
    610001: 'マッピングされた維持管理/整備履歴のある委託社は削除できません。',

    // 精算
    757501: '決済された、月別精算については取り消すことが出来ません。',
    729999: '決済にエラーが発生しました。詳細はマイページからご確認ください。',

    /** etc */
    undefined: ' ', // 消さないでください。

    /** title */
    'Title.Dashboard': 'ダッシュボード',
    'Title.VehicleMgmt': '車両管理',
    'Title.VehicleMgmt.Vehicle': '車両登録/照会',
    'Title.Business.VehicleMgmt.Vehicle': '車両照会',
    'Title.VehicleMgmt.Vehicle.Create': '車両情報の登録',
    'Title.VehicleMgmt.Vehicle.Edit': '車両情報の修正',
    'Title.VehicleMgmt.Vehicle.View': '車両詳細の情報',
    'Title.VehicleMgmt.VehicleMonitoring': '車両のモニタリング',
    'Title.VehicleMgmt.VehicleDailyOperation': '車両の運行日誌',
    'Title.VehicleMgmt.VehicleModel': '車種（車両モデル）',
    'Title.VehicleMgmt.VehicleModel.Create': '車種（車両モデル）登録',
    'Title.VehicleMgmt.VehicleModel.Edit': '車種（車両モデル）修正',
    'Title.VehicleMgmt.VehicleModel.View': '車種(車両モデル)詳細',
    'Title.VehicleMgmt.Terminal': '端末機',
    'Title.VehicleMgmt.Terminal.List': '端末機管理',
    'Title.VehicleMgmt.Terminal.Create': '端末機登録',
    'Title.VehicleMgmt.Terminal.Edit': '端末機修正',
    'Title.VehicleMgmt.Terminal.View': '端末機詳細情報',
    'Title.VehicleMgmt.Terminal.Verification': '端末装着検証',
    'Title.VehicleMgmt.Terminal.Verification.History': '端末搭載検証履歴',
    'Title.VehicleMgmt.Refuel': '充電/充填履歴',
    'Title.VehicleMgmt.VehicleRepair': '事故修理履歴',
    'Title.VehicleMgmt.VehicleRepair.Regist': '登録',
    'Title.VehicleMgmt.VehicleRepair.Info': '事故情報',
    'Title.VehicleMgmt.VehicleRepair.Rate': '相手車両及び過失情報',
    'Title.VehicleMgmt.VehicleRepair.Coverage': '保険情報',
    'Title.VehicleMgmt.VehicleRepair.Work': '作業内訳',
    'Title.VehicleMgmt.VehicleRepair.Detail': '詳細情報',
    'Title.VehicleMgmt.VehicleRepair.Edit': '修正情報', // JP
    'Title.VehicleMgmt.VehicleRepair.Rental': '貸出情報',
    'Title.VehicleMgmt.VehicleRepair.Photo': '詳細写真情報',
    'Title.VehicleMgmt.VehicleMaintenance': '維持管理/整備履歴',
    'Title.VehicleMgmt.VehicleMaintenance.Regist': '登録',
    'Title.VehicleMgmt.VehicleMaintenance.View': '詳細情報',
    'Title.VehicleMgmt.ControlHistory': '車両制御履歴',
    'Title.VehicleMgmt.ControlHistory.Server': '車両制御履歴(サーバー)',
    'Title.VehicleMgmt.ControlHistory.Server.Detail':
        '車両制御履歴(サーバー) 詳細',
    'Title.VehicleMgmt.ControlHistory.Ble': '車両制御履歴(BLE)',
    'Title.VehicleMgmt.ControlHistory.Ble.Detail': '車両制御履歴(BLE) 詳細',
    'Title.VehicleMgmt.ControlHistory.SubTitle.ReqDtl': '要請内訳',
    'Title.VehicleMgmt.Trade': '売却',
    'Title.VehicleMgmt.Trade.Create': '処理項目登録',
    'Title.VehicleMgmt.Trade.Edit.Sale': '売却情報',
    'Title.VehicleMgmt.Trade.Edit.Scrap': '廃車情報',
    'Title.VehicleMgmt.Trade.Edit.SubTitle': '登録情報',
    'Title.VehicleMgmt.Maintenance': '維持管理/整備履歴',
    'Title.VehicleMgmt.Provider': '供給社 登録/照会', // JP
    'Title.VehicleMgmt.Provider.Create': '供給社 登録', // JP
    'Title.VehicleMgmt.Provider.View': '供給社 詳細', // JP
    'Title.VehicleMgmt.Provider.Edit': '供給社 修正', // JP
    'Title.VehicleMgmt.Provider.Info': '供給社 登録情報', // JP
    'Title.VehicleMgmt.Provider.VehicleInfo': '供給社 車両情報', // JP

    'Title.WibleZoneMgmt': 'ステーション管理',
    'Title.WibleZoneMgmt.Location': 'ステーション 登録/照会',
    'Title.WibleZoneMgmt.Location.Create': 'ステーション 登録',
    'Title.WibleZoneMgmt.Location.Edit': 'ステーション修正',
    'Title.WibleZoneMgmt.Location.View': 'ステーション詳細',
    'Title.WibleZoneMgmt.Vehicle.Search': 'ステーション車両リスト',
    'Title.WibleZoneMgmt.Garage': 'ガレージ',

    'Title.ReservationMgmt': '貸出/決済の管理',
    'Title.ReservationMgmt.ReservationSchedule': '貸出状況',
    'Title.ReservationMgmt.Reservation.Mgmt': '貸出管理',
    'Title.ReservationMgmt.Reservation.Mgmt.View': '貸出管理詳細',
    'Title.ReservationMgmt.Reservation.Mgmt.Create': '貸出登録',
    'Title.ReservationMgmt.Reservation': '貸出内訳',
    'Title.ReservationMgmt.Reservation.View': '貸出内訳詳細',
    'Title.ReservationMgmt.ReservationHistory': '貸出内訳',
    'Title.ReservationMgmt.ReservationHistory.View': '貸出内訳詳細',
    'Title.ReservationMgmt.ReservationPayment': '決済内訳',
    'Title.ReservationMgmt.ReservationPayment.Detail': '決済内訳詳細',
    'Title.ReservationMgmt.Card': '決済用クレジットカード',
    'Title.ReservationMgmt.Card.Create': '決済用クレジットカード登録',
    'Title.ReservationMgmt.Card.Edit': '決済用クレジットカード修正',
    'Title.ReservationMgmt.Card.View': '決済用クレジットカード詳細',
    'Title.ReservationMgmt.Card.Modify': '決済用クレジットカード変更',
    'Title.ReservationMgmt.Transit': '通行履歴',
    'Title.ReservationMgmt.Subscribe': 'サブスクリプション内訳',
    'Title.ReservationMgmt.Subscribe.Card.Modify': 'カード変更',
    'Title.ReservationMgmt.Subscribe.Detail': 'サブスクリプション内訳詳細',
    'Title.ReservationMgmt.Hipass': 'ETC Card',
    'Title.ReservationMgmt.Hipass.Create': 'ETC Card 登録',
    'Title.ReservationMgmt.Hipass.Update': 'ETC Card 修正',
    'Title.ReservationMgmt.Hipass.View': 'ETC Card 詳細',
    'Title.ReservationMgmt.AssociateCard': '充電/充填カード',
    'Title.ReservationMgmt.AssociateCard.Create': '充電/充填カード登録',
    'Title.ReservationMgmt.AssociateCard.Update': '充電/充填カード修正',
    'Title.ReservationMgmt.AssociateCard.View': '充電/充填カード詳細',

    'Title.BusinessMgmt': '会員社の管理',
    'Title.BusinessMgmt.Business': '会員社/契約管理',
    'Title.BusinessMgmt.Contract': '会員社/契約',
    'Title.BusinessMgmt.Regist': '会員社/契約登録',
    'Title.BusinessMgmt.Regist.Info': '会員社の登録情報',
    'Title.BusinessMgmt.Regist.SuperAdmin': 'スーパー管理者登録',
    'Title.BusinessMgmt.Regist.Contract': '契約登録の情報',
    'Title.BusinessMgmt.ContractHist': '契約情報の履歴',
    'Title.BusinessMgmt.Detail': '会員社/契約の詳細',
    'Title.BusinessMgmt.Detail.SuperAdmin': 'スーパー管理者の情報',
    'Title.BusinessMgmt.Detail.Contract': '契約情報の履歴',
    'Title.BusinessMgmt.Modify': '会員社/契約の修正',
    'Title.BusinessMgmt.CustomerService': '企業相談履歴',
    'Title.BusinessMgmt.CustomerService.Edit': '企業相談履歴修正',
    'Title.BusinessMgmt.CustomerService.Create': '企業相談登録',
    'Title.ContractMgmt': '契約管理',
    'Title.BusinessContract': '契約',
    'Title.BusinessContract.Product': '契約商品',
    'Title.BusinessContract.Detail': '契約商品の照会',
    'Title.BusinessContract.BusinessInfo': '会員社情報',
    'Title.BusinessContract.ContractInfo': '契約情報',

    'Title.UserMgmt': '会員/部署管理',
    'Title.UserMgmt.Business': '役職員/部署管理',
    'Title.UserMgmt.Customer': '会員',
    'Title.UserMgmt.Customer.User': '会員',
    'Title.UserMgmt.Customer.WithdrawalUser': '退会済み',
    'Title.UserMgmt.Customer.Detail': '会員詳細',
    'Title.UserMgmt.Customer.Modify': '会員修正',
    'Title.UserMgmt.Customer.SubTitle.PersonalInfo': '個人情報',
    'Title.UserMgmt.Customer.SubTitle.MemberShipInfo': '運転免許証情報',
    'Title.UserMgmt.Customer.SubTitle.PassportInfo': 'パスポート情報',
    'Title.UserMgmt.Customer.SubTitle.BusinessProfileInfo':
        'ビジネスプロフィール情報',
    'Title.UserMgmt.Customer.SubTitle.PaymentUseDtl': '決済及び利用内訳',
    'Title.UserMgmt.Customer.SubTitle.CouponVocMgmtDtl': 'クーポン及び商談内訳',
    'Title.UserMgmt.Customer.SubTitle.Etc': 'その他',
    'Title.UserMgmt.BusinessMember': '役職員照会',
    'Title.UserMgmt.BusinessInvite': '役職員登録',
    'Title.UserMgmt.BusinessInvite.Create': '登録会員',
    'Title.UserMgmt.BusinessInvite.List': '登録状況',
    'Title.UserMgmt.Department': '部署',
    'Title.UserMgmt.Department.Create': '部署登録',
    'Title.UserMgmt.Department.View': '部署詳細',
    'Title.UserMgmt.Department.Edit': '部署修正',
    'Title.UserMgmt.BusinessOperator': '会員社権限（バーミッション）',
    'Title.UserMgmt.BusinessOperator.View': '会員社権限（バーミッション）詳細',
    'Title.UserMgmt.BusinessOperator.Edit': '会員社権限（バーミッション）修正',
    'Title.UserMgmt.BisinessManager': 'アドミン権限（バーミッション）',
    'Title.UserMgmt.BisinessManager.View': 'アドミン権限（バーミッション）詳細',
    'Title.UserMgmt.BisinessManager.Edit': 'アドミン権限（バーミッション）修正',
    'Title.UserMgmt.Penalty': 'ペナルティ',
    'Title.UserMgmt.Penalty.List': 'ペナルティ内訳',
    'Title.UserMgmt.Penalty.View': 'ペナルティ詳細',
    'Title.UserMgmt.Penalty.Create': 'ペナルティ登録',
    'Title.UserMgmt.Penalty.Update': 'ペナルティ修正',
    'Title.UserMgmt.PenaltyPolicy': 'ペナルティポリシー',
    'Title.UserMgmt.PenaltyPolicy.View': 'ペナルティポリシー詳細',
    'Title.UserMgmt.PenaltyPolicy.Policy1': 'ペナルティポリシー1',
    'Title.UserMgmt.PenaltyPolicy.Create': 'ペナルティポリシー登録',
    'Title.UserMgmt.PenaltyPolicy.Update': 'ペナルティポリシー修正',
    'Title.UserMgmt.LicenseCheck': '運転免許証の確認履歴',
    'Title.UserMgmt.LicenseCheck.Detail': '運転免許証の確認履歴の詳細',
    'Title.UserMgmt.LicenseInfo': '運転免許証の照会',
    'Title.UserMgmt.LicenseInfo.View': '運転免許証の詳細',

    'Title.SalesMgmt': '売上/精算管理',
    'Title.SalesMgmt.Sales': '売上',
    'Title.SalesMgmt.Sales.Detail': '詳細情報',
    'Title.SalesMgmt.Sales.Coupon': 'クーポン売上',
    'Title.SalesMgmt.CouponSales': 'クーポン売上',
    'Title.SalesMgmt.CouponSales.View': 'クーポン売上の詳細',
    'Title.SalesMgmt.Calculation': '精算',
    'Title.SalesMgmt.Calculation.Create': '精算登録',
    'Title.SalesMgmt.Calculation.Edit': '精算修正',
    'Title.SalesMgmt.Calculation.View': '精算詳細',
    'Title.SalesMgmt.Violation': '反則金',
    'Title.SalesMgmt.Violation.List': '反則金管理',
    'Title.SalesMgmt.Violation.Regist': '反則金登録',
    'Title.SalesMgmt.Violation.Modify': '反則金修正',
    'Title.SalesMgmt.Coupon': 'クーポン',
    'Title.SalesMgmt.Coupon.Detail.Create': 'クーポン登録',
    'Title.SalesMgmt.Coupon.Detail.Edit': 'クーポン修正',
    'Title.SalesMgmt.Coupon.Detail.View': 'クーポン詳細',
    'Title.SalesMgmt.Receivable': '未払い金',
    'Title.SalesMgmt.Receivable.View': '未払い金管理詳細',

    'Title.ProductMgmt': '商品管理',
    'Title.ProductMgmt.Charge': '料金制',
    'Title.ProductMgmt.Charge.Create': '料金制登録',
    'Title.ProductMgmt.Charge.View': '料金制詳細',
    'Title.ProductMgmt.Insurance': 'NOC補償',
    'Title.ProductMgmt.Insurance.Create': 'NOC補償登録',
    'Title.ProductMgmt.Insurance.View': 'NOC補償詳細',
    'Title.ProductMgmt.Extra': 'オプション', // JP
    'Title.ProductMgmt.Extra.View': 'オプションの詳細', // JP
    'Title.ProductMgmt.Extra.Create': 'オプションの登録', // JP
    'Title.ProductMgmt.Extra.Edit': 'オプションの修正', // JP
    'Title.ProductMgmt.Extra.Info': '基本情報', // JP
    'Title.ProductMgmt.Extra.CargeInfo': '料金情報', // JP

    'Title.ContentMgmt': 'コンテンツ管理',
    'Title.ContentMgmt.Notice': 'お知らせ/イベント',
    'Title.ContentMgmt.Notice.SubName': 'お知らせ事項',
    'Title.ContentMgmt.Notice.Create': 'お知らせ/イベント登録情報',
    'Title.ContentMgmt.Terms': '約款',
    'Title.ContentMgmt.Terms.Create': '約款登録',
    'Title.ContentMgmt.Terms.Edit': '約款修正',
    'Title.ContentMgmt.Terms.View': '約款詳細',
    'Title.ContentMgmt.MsgTemplate': 'お知らせテンプレート',
    'Title.ContentMgmt.MsgTemplate.Create': 'お知らせテンプレート登録',
    'Title.ContentMgmt.MsgTemplate.View': 'お知らせテンプレート詳細',
    'Title.ContentMgmt.MsgTemplate.Edit': 'お知らせテンプレート修正',
    'Title.ContentMgmt.NotiOrHistory': 'お知らせ/メッセージ送信',
    'Title.ContentMgmt.NotiOrHistory.History': 'お知らせ/メッセージ送信',
    'Title.ContentMgmt.NotiOrHistory.History.View':
        'お知らせ/メッセージ送信詳細',
    'Title.ContentMgmt.NotiOrHistory.PassiveMessage': '手動メッセージ',
    'Title.ContentMgmt.NotiOrHistory.PassiveMessage.Create': '手動メッセージ',
    'Title.ContentMgmt.NotiOrHistory.PassiveMessage.Edit': '手動メッセージ修正',
    'Title.ContentMgmt.NotiOrHistory.PassiveMessage.View': '手動メッセージ詳細',
    'Title.ContentMgmt.Banner': 'サービス紹介/バナー/ポップアップ',
    'Title.ContentMgmt.Banner.Regist': 'サービス紹介/バナー/ポップアップ登録',
    'Title.ContentMgmt.Banner.Modify': 'サービス紹介/バナー/ポップアップ修正',
    'Title.ContentMgmt.ModelInfo': '車種別利用案内',
    'Title.ContentMgmt.ModelInfo.Create': '車種別利用案内登録',
    'Title.ContentMgmt.ModelInfo.Modify': '車種別利用案内修正',
    'Title.ContentMgmt.GuideLocation': 'アプリのコンテンツ管理', // JP
    'Title.ContentMgmt.GuideLocation.Create': 'アプリのコンテンツ管理登録', // JP
    'Title.ContentMgmt.GuideLocation.Modify': 'アプリのコンテンツ管理修正', // JP

    'Title.WorkMgmt': '運営管理',
    'Title.WorkMgmt.Operator': '運営者',
    'Title.WorkMgmt.BusinessPartner': '委託社',
    'Title.WorkMgmt.BusinessPartner.Create': '委託社登録',
    'Title.WorkMgmt.BusinessPartner.Edit': '委託社修正',
    'Title.WorkMgmt.BusinessPartner.View': '委託社詳細',
    'Title.WorkMgmt.Admin': 'アドミン',
    'Title.WorkMgmt.AppVersion': 'バージョン',
    'Title.WorkMgmt.AppVersion.Create': 'バージョン登録',
    'Title.WorkMgmt.AppVersion.Edit': 'バージョン修正',
    'Title.WorkMgmt.AppVersion.View': 'バージョン詳細',
    'Title.WorkMgmt.GreenerPolicy': 'Greener ポリシー',
    'Title.WorkMgmt.Provider': '供給社',

    'Title.CSCenter': 'カスタマーセンター',
    'Title.CSCenter.FaqMgmt': 'FAQ',
    'Title.CSCenter.FaqMgmt.Regist': 'FAQ 登録',
    'Title.CSCenter.FaqMgmt.Modify': 'FAQ 修正',
    'Title.CSCenter.VocMgmt': '1:1相談',
    'Title.CSCenter.VocMgmt.Regist': '相談内訳登録',
    'Title.CSCenter.VocMgmt.Modify': '相談内訳修正',
    'Title.CSCenter.ContactManager': '担当者に問い合わせる',
    'Title.CSCenter.Contact': '担当者への問い合わせ',
    'Title.CSCenter.Notice': 'カスタマーセンターのお知らせ',
    'Title.CSCenter.Notice.Regist': 'カスタマーセンターのお知らせ登録',
    'Title.CSCenter.Notice.Modify': 'カスタマーセンターのお知らせ修正',
    'Title.CSCenter.Notice.SubName': 'お知らせ事項',
    'Title.CSCenter.Notice.Create': 'お知らせ/イベント登録',

    'Title.SystemMgmt': 'システム管理',
    'Title.SystemMgmt.Role': '権限（バーミッション）',
    'Title.SystemMgmt.Role.Access': 'アクセス権限（バーミッション）',
    'Title.SystemMgmt.Code': 'コード',
    'Title.SystemMgmt.Menu': 'メニュー',
    'Title.SystemMgmt.Menu.Tree': 'メニュー管理',
    'Title.SystemMgmt.Program': 'プログラム',
    'Title.SystemMgmt.Batch': '配置',
    'Title.SystemMgmt.Batch.Schedule': '配置スケジュール',
    'Title.SystemMgmt.Batch.Job.List': 'スケジューラーリスト照会',
    'Title.SystemMgmt.Batch.Job': 'スケジューラーリスト照会',
    'Title.SystemMgmt.Batch.Job.History': '配置履歴照会',

    'Title.MyInfo': 'マイページ',
    'Title.UserPolicy': '約款',

    'Title.Popup.Business.Search': '会員社検索',
    'Title.Popup.User.Search': '会員検索',
    'Title.Popup.VehicleModel.Search': '車種検索',
    'Title.Popup.Vehicle.Search': '車両検索',
    'Title.Popup.WibleZone.List': 'ステーション',
    'Title.Popup.WibleZone.Search': 'ステーション検索',
    'Title.Popup.WibleZone.Selected': '選択したステーション',
    'Title.Popup.WibleZone.Guide.Search': '利用詳細案内検索',
    'Title.Popup.Station.Guide.Search': 'コンテンツ検索', // JP
    'Title.Popup.Station.Guide': 'アプリのコンテンツ', // JP
    'Title.WebEditor.InsertLink': 'Custom insert hyperlink',
    'Title.WebEditor.ViewHtml': 'View html',
    'Title.WebEditor.InsertImage': 'Insert image',
    'Title.ImagePreview.Dialog': '詳しい写真情報',

    // ウェブエディター
    'WebEditor.InsertLink.Title': 'Title',
    'WebEditor.InsertLink.Href': 'Web address',
    'WebEditor.InsertLink.Target': 'New Window',
    'WebEditor.InsertLink.Target.Label': 'Open link in new window.',
    'WebEditor.InsertImage.Tab.File': 'Upload',
    'WebEditor.InsertImage.Tab.URL': 'By URL',
    'WebEditor.InsertImage.ImageFile': 'Image',
    'WebEditor.InsertImage.Src': 'Web Address',
    'WebEditor.InsertImage.AltText': 'Alternate Text',
    'WebEditor.InsertImage.Title': 'Title',
    'WebEditor.InsertImage.Width': 'Width',
    'WebEditor.InsertImage.Height': 'Height',

    //Report Designer
    'RD.Button.OperationHistory': '運行日誌レポート',
    'RD.Button.Transfer': '移管申請書',
    'RD.Button.Contract': '賃貸借車契約書',
    'RD.Button.Contract.Business': '賃貸借車契約書（ビジネス）',
    'RD.Button.Contract.Personal': '賃貸借車契約書(個人)',
    'RD.Button.ReservationPayment': '決済内訳出力',
    'RD.Button.Usagebill.Business': '利用内訳(ビジネス)',
    'RD.Button.Usagebill.Personal': '利用内訳書(個人)',
    'RD.Button.Customerbill': '顧客請求書出力',
    'RD.Title.OperationHistory': '業務用乗用車運行記録部',
    'RD.Title.Transfer': '移管申請書',
    'RD.Title.Contract': '賃貸借契約書',
    'RD.Title.Usagebill': '利用内訳書',
    'RD.Title.Customerbill': '顧客請求',
    'RD.Usagebill.DateTimeDiff.Format': '総 {hour}時間{minute}分',
    'RD.Usagebill.Payment.String': '{authDate}決済({businessCardName})',
    'RD.Usagebill.Payment.Default': '未決済',
    'RD.Message.Alert.No.Data': '出力するデータが存在しません。',
    'RD.StartTime': '貸出開始',
    'RD.EndTime': '貸出終了',

    // 車両管理 > 車両登録/照会
    'Btn.Force.Delete': '強制削除',
    'Message.Confirm.Force.Delete': '強制削除しますか?',
    'Btn.Chnage.Provider': 'フランチャイズ変更',
    'Vehicle.Stats': '貸出可能 {isAvailableCount}台 | 総 {totalRecord}台',
    'Vehicle.LicensePlateNumber': '車両番号',
    'Vehicle.Business.Placeholder': '会員社入力',
    'Vehicle.Model.Name': '車種',
    'Vehicle.Model.Name.Select': '車種選択',
    'Vehicle.Model.FuelType': '燃料タイプ',
    'Vehicle.RefuelLiter': '充電/充填量',
    'Vehicle.InsuranceAge': '保険年齢',
    'Vehicle.VehicleStatus.Wait': '待機',
    'Vehicle.VehicleStatus.Inuse': '利用中',
    'Vehicle.VehicleStatus.Refuel': '充電中',
    'Vehicle.VehicleStatus.Washing': '洗車中',
    'Vehicle.VehicleStatus.Maintenance': '修理中',
    'Vehicle.VehicleStatus.Sale': '売却',
    'Vehicle.VehicleStatus.Scrap': '廃車',
    'Vehicle.VehicleStatus.LowVoltage': '低電圧',
    'Vehicle.VehicleStatus.NotReturned': '返却遅延',
    'Vehicle.VehicleStatus': '車両状態',
    'Vehicle.OperationStatus': '運営状態',
    'Vehicle.Used': '貸出可能状態',
    'Vehicle.Available': '貸出可',
    'Vehicle.NotAvailable': '貸出不可',
    'Vehicle.WibleZone': 'ステーション',
    'Vehicle.LicensePlateNumber.Placeholder': '車両番号入力',
    'Vehicle.LicensePlateNumber.Alert': '車両番号を入力してください。',
    'Vehicle.LicensePlateNumber.Alert.Duplicated': '重複車両です。',
    'Vehicle.LicensePlateNumber.Alert.Success': '登録できる車両です。',
    'Vehicle.Model.Name.Placeholder': '車種入力',
    'Vehicle.Model.FuelType.AllSelect': '燃料タイプ全体',
    'Vehicle.InsuranceAge.AllSelect': '保険年齢全体',
    'Vehicle.InsuranceAge.26': '保険年齢26歳以上',
    'Vehicle.InsuranceAge.21': '保険年齢21歳以上',
    'Vehicle.InsuranceAge.26.Label': '26歳以上',
    'Vehicle.InsuranceAge.21.Label': '21歳以上',
    'Vehicle.VehicleStatus.AllSelect': '車両状態全体',
    'Vehicle.OperationStatus.AllSelect': '運営状態全体',
    'Vehicle.Used.AllSelect': '貸出可能状態全体',
    'Vehicle.WibleZone.AllSelect': 'ステーション 全体',
    'Vehicle.Provider.AllSelect': '供給社全体',
    'Vehicle.MID': 'MID',
    'Vehicle.MID.Select': 'MID 選択',
    'Vehicle.Account': '口座番号', // JP
    'Vehicle.SampleDownload.ConfirmMessage':
        '様式・書式をダウンロードしますか?',
    'Vehicle.Upload.ConfirmMessage': '車両一括登録を実行しますか?',
    'Vehicle.Upload.Success':
        '車両一括登録が完了しました。{br}車両詳細情報は、確認及び修正してください。',
    'Vehicle.Upload.Fail':
        '車両一括登録に失敗しました。{br}詳しい情報は失敗件数をクリックして確認してください。',
    'Vehicle.BasicInfo': '基本情報',
    'Vehicle.VIN': '車台番号',
    'Vehicle.VIN.Placeholder': '英文字、数字17桁入力',
    'Vehicle.VIN.Alert': '車台番号を入力してください。',
    'Vehicle.VIN.Alert.Duplicated': '重複するVINです。',
    'Vehicle.VIN.Error.Message': '英文字、数字17桁を入力してください。',
    'Vehicle.Alert.Success': '登録できます。',
    'Vehicle.Year': '車両年式',
    'Vehicle.Year.Alert': '車両年式は1970～2100年の値のみ入力できます。',
    'Vehicle.Year.Placeholder': '年式入力',
    'Vehicle.Model.FuelType.Placeholder': '燃料タイプの選択',
    'Vehicle.Trim': 'トリム',
    'Vehicle.Trim.Placeholder': 'トリム入力',
    'Vehicle.Color.Outside': '色-外装',
    'Vehicle.Color.Inside': '色ー内装',
    'Vehicle.Color.Placeholder': '色入力',
    'Vehicle.HiPassCard': 'ETC Card',
    'Vehicle.HiPassCard.Label': 'ETC Card',
    'Vehicle.SearchHiPassCard.Placeholder': 'ETC Card 選択',
    'Vehicle.HiPassCard.Number': 'カード番号',
    'Vehicle.HiPassCard.Placeholder': 'ETC Card 選択',
    'Vehicle.ChargeCard': '充電/充填カード情報',
    'Vehicle.ChargeCard.Label': '充電/充填カード',
    'Vehicle.SearchChargeCard.Placeholder': '充電/充填カード情報入力',
    'Vehicle.ChargeCard.Placeholder': '充電/充填カード選択',
    'Vehicle.WibleZone.Select': 'ステーション設定',
    'Vehicle.WibleZone.Placeholder': 'ステーション選択',
    'Vehicle.Used.Label': '貸出可能状態設定',
    'Vehicle.Registration': '車両登録証',
    'Vehicle.Registration.Delete.Success': '車両登録証削除に成功しました。',
    'Vehicle.Registration.Upload.Success':
        '車両登録証アップロードに成功しました。',
    'Vehicle.Registration.Upload.Failure':
        '車両登録証アップロードに失敗しました。',
    'Vehicle.Registration.Placeholder': '画像またはPDFファイル登録', // JP
    'Vehicle.RegistrationDate.Label': '車両登録日',
    'Vehicle.RegistrationDate.Placeholder': '車両登録日 選択',
    'Vehicle.InspectionDate': '点検日',
    'Vehicle.InspectionDate.Placeholder': '点検日 選択',
    'Vehicle.CarInspectionDate': '車検日',
    'Vehicle.CarInspectionDate.Placeholder': '車検日 選択',
    'Vehicle.Remark' : '備考',
    'Vehicle.Remark.Placeholder' : '備考の入力',
    'Vehicle.Inspection.Complete.Btn': '点検完了',
    'Vehicle.CarInspection.Complete.Btn': '車検完了',
    'Vehicle.InspectionDate.ConfirmMessage': '点検日を更新しますか？',
    'Vehicle.CarInspectionDate.ConfirmMessage': '車検日を更新しますか？',
    'Vehicle.InspectionDate.Confirm.Success': '点検日更新に成功しました。',
    'Vehicle.CarInspectionDate.Confirm.Success': '車検日更新に成功しました。',
    'Vehicle.InspectionDate.Confirm.Failure': '点検日更新に失敗しました。',
    'Vehicle.CarInspectionDate.Confirm.Failure': '車検日更新に失敗しました。',
    // 'Vehicle.InspectionDate': '点検期限',
    // 'Vehicle.CarInspectionDate': '車検期限',
    'Vehicle.InspectionDate.All': '点検期限 全体',
    'Vehicle.CarInspectionDate.All': '車検期限 全体',
    'Vehicle.InsuranceAge.Label': '保険年齢の制限設定',
    'Vehicle.Option': 'オプション情報',
    'Vehicle.Option.Label': 'オプション',
    'Vehicle.Option.ColumnLabel': 'オプション項目',
    'Vehicle.Option.Select': 'オプション選択',
    'Vehicle.Option.Safety': 'セーフティ',
    'Vehicle.Option.Safety.Select': 'セーフティオプション選択',
    'Vehicle.Option.Compose': '便利',
    'Vehicle.Option.Compose.Select': '利便性オプション選択',
    'Vehicle.Option.Placeholder': '直接入力',
    'Vehicle.Return.Charger': '車両返却条件（充電ポリシー）',
    'Vehicle.ChargerConnection.Yn': '充電器接続有無',
    'Vehicle.ChargerConnection.Yes': '充電中',
    'Vehicle.ChargerConnection.No': '充電中ではございません。',
    'Vehicle.ChargerConnection.Duty': '接続後、返却必須',
    'Vehicle.ChargerConnection.NotDuty': '接続せずに返却可能',
    'Vehicle.ReturnCondition.Yn': '車両返却条件の有無',
    'Vehicle.RewardPayments.Yn': '報酬支給の有無',
    'Vehicle.RewardPayments.Yes': '支給',
    'Vehicle.RewardPayments.No': '未支給',
    'Vehicle.ReturnOption': '返却時、付加条件設定',
    'Vehicle.ReturnOption.Label': '返却時、付加条件',
    'Vehicle.ReturnOption.Condition': '充電残余量基準の返却条件',
    'Vehicle.ReturnOption.Penalty': '充電残余量によるペナルティ',
    'Vehicle.Charge.Rest': '残余量基準',
    'Vehicle.Charge.Rest.View': '残余量基準 : ',
    'Vehicle.Charge.Warning': '※ 基準未満の場合返却不可',
    'Vehicle.Charge.Penalty.Warning': '※ 基準未満の場合ペナルティ賦課',
    'Vehicle.Charge.Warning.View': '(未満の場合返却不可)',
    'Vehicle.Charge.Penalty.Warning.View': '(未満の場合ペナルティ賦課)',
    'Vehicle.ServiceTime': 'サービス時間',
    'Vehicle.ServiceTime.Duplicate.Alert':
        '重複した時間帯が存在します。{br}時間設定を再度、確認してください。',
    'Vehicle.ServiceTime.Select.ChargeAndInsurance': '商品及び保険の選択',
    'Vehicle.ServiceTime.Select.Day': '販売区間',
    'Vehicle.ServiceTime.Pickup': '受け渡し',
    'Vehicle.ServiceTime.Return': '返却',
    'Vehicle.ServiceTime.HH': '時間',
    'Vehicle.ServiceTime.HH.Placeholder': '時間入力',
    'Vehicle.ServiceTime.mm': '分',
    'Vehicle.ServiceTime.mm.Placeholder': '分入力',
    'Vehicle.ServiceTime.mm.Alert': '0~59 間の値だけ入力できます。',
    'Vehicle.ServiceTime.Select.Time': '利用時間',
    'Vehicle.ServiceTime.Pickup.Day': '受け渡し可能曜日', // JP
    'Vehicle.ServiceTime.Pickup.Time': '受け渡し可能時間', // JP
    'Vehicle.RentalTime': '貸出時間',
    'Vehicle.LicenseType': '商品(料金制、保険)',
    'Vehicle.ServiceTime.Setting': 'サービス時間設定',
    'Vehicle.Charge.Select': '商品選択',
    'Vehicle.Insurance.Select': '保険選択',
    'Vehicle.ServiceTime.Wave': '~',
    'Vehicle.ServiceTime.Plus': '+',
    'Vehicle.ServiceTime.Minus': '-',
    'Vehicle.ServiceTime.Type': 'タイプ',
    'Vehicle.ServiceTime.Business': '業務用',
    'Vehicle.ServiceTime.Personal': '個人用',
    'Vehicle.ServiceTime.Weekday.Type': '貸出時間タイプ',
    'Vehicle.ServiceTime.Weekday': '運営日',
    'Vehicle.ServiceTime.Weekend': '非運営日',
    'Vehicle.ServiceTime.Day': '曜日',
    'Vehicle.ServiceTime.Day.Mon': '月',
    'Vehicle.ServiceTime.Day.Tue': '火',
    'Vehicle.ServiceTime.Day.Wed': '水',
    'Vehicle.ServiceTime.Day.Thu': '木',
    'Vehicle.ServiceTime.Day.Fri': '金',
    'Vehicle.ServiceTime.Day.Sat': '土',
    'Vehicle.ServiceTime.Day.Sun': '日',
    'Vehicle.ServiceTime.Weekday.SalesTime': '運営日販売区間',
    'Vehicle.ServiceTime.Weekend.SalesTime': '非運営日販売区間',
    'Vehicle.SalesTime.Y': '販売',
    'Vehicle.SalesTime.N': '販売しない',
    'Vehicle.Terminal': '端末機',
    'Vehicle.Terminal.Connect': '装着',
    'Vehicle.Terminal.DisConnect': '未装着',
    'Vehicle.WibleAndBusiness': 'ステーション/会員社連動情報',
    'Vehicle.Business': '会員社/部署',
    'Vehicle.Business.Content': '{businessName} / {departmentName}',
    'Vehicle.CheckDuplication': '重複チェック',
    'Vehicle.CheckDuplication.VIN.Alert': 'VIN 重複チェックが必要です。',
    'Vehicle.CheckDuplication.LicensePlateNumber.Alert':
        '車両番号の重複チェックが必要です。',
    'Vehicle.Gearbox': '変速機',
    'Vehicle.FuelLevelPercentage': '充電/充填量',
    'Vehicle.Provider': '供給社',
    'Vehicle.Provider.Placeholder': '供給社選択',
    'Vehicle.Insurance.Section': '保険情報',
    'Vehicle.Insurance.Company': '保険会社',
    'Vehicle.Insurance.Company.Input': '保険会社入力',
    'Vehicle.Insurance.Company.Krm': '控除会',
    'Vehicle.Insurance.Age': '保険年齢',
    'Vehicle.Insurance.Age.Select': '保険年齢選択',
    'Vehicle.VehicleDamage.Registration': '自車入会',
    'Vehicle.VehicleDamageInsurance': '本人車両損害',
    'Vehicle.VehicleDamageInsurance.Registration': '本人車両損害入会',
    'Vehicle.Emergency.Registration': '緊急出動特約の有無',
    'Vehicle.PhysicalAccident': '本人身体事故',
    'Vehicle.PhysicalAccident.Placeholder': '本人身体事故入力',
    'Vehicle.InterpersonalCompensation1': '対人賠償11',
    'Vehicle.InterpersonalCompensation1.Placeholder': '対人賠償1入力',
    'Vehicle.InterpersonalCompensation2': '対人賠償22',
    'Vehicle.InterpersonalCompensation2.Placeholder': '対人賠償2入力',
    'Vehicle.Reparation': '対物賠償',
    'Vehicle.Reparation.Placeholder': '対物賠償入力',
    'Vehicle.UninsuredCarInjury': '無保険車傷害',
    'Vehicle.UninsuredCarInjury.Placeholder': '無保険車傷害入力',
    'Vehicle.Interpersonal': '対人',
    'Vehicle.InterVehicle': '対物',
    'Vehicle.SelfDamage': '自損',
    'Vehicle.VehicleDamage': '本人車両損害',
    'Vehicle.VehicleDamage.Placeholder': '本人車両損害入力',
    'Vehicle.Insurance.Limit': '保険限度',
    'Vehicle.Insurance.SelfExposure': '自己負担金',
    'Vehicle.MultipleCreate': '一括登録',
    'Vehicle.ModelSelect': '車両モデル選択',
    'Vehicle.SearchModel.Placeholder': '車両モデル検索',
    'Vehicle.HiPassSelect': 'ETC Card 選択',
    'Vehicle.ChargeCardSelect': '充電/充填カード選択',
    'Vehicle.Registration.Yn': '入会有無',
    'Vehicle.Registration.Yes': '入会',
    'Vehicle.Registration.No': '未入会',
    'Vehicle.VehicleLocation': '車両位置/制御',
    'Vehicle.Accident': '事故修理内訳',
    'Vehicle.VehicleDailyOperation': '車両運行日誌',
    'Vehicle.CheckVehicleRegistration': '車両登録証確認',
    'Vehicle.TerminalDetail': '端末機詳細情報',
    'Vehicle.HiPassDetail': 'ETC Card 履歴',
    'Vehicle.ChargeCardDetail': '充電/充填履歴',
    'Vehicle.CardSearch': 'カード照会',
    'Vehicle.Contract': '契約期間<br />(延長を含む)',
    'Vehicle.BleWifi': 'BLE/WIFI',
    'Vehicle.BleWifi.MacAddress': 'BLE Mac Address',
    'Vehicle.BleWifi.MacAddress.Manual': 'BLE Mac Address (手書き)',
    'Vehicle.BleWifi.MacAddress.Placeholder': 'BLE MAC 住所を入力してください。',
    'Vehicle.BleWifi.AccessPoint': 'WIFI Access Point',
    'Vehicle.BusinessName': '会員社',
    'Vehicle.Insurance': '保険情報',
    'Vehicle.CurrentLocation': '県位置',
    'Vehicle.ExtraProduct.Info': 'オプションの情報', // JP
    'Vehicle.ExtraProduct': 'オプション', // JP
    'Vehicle.ExtraProduct.Select': 'オプションの選択', // JP
    'Vehicle.BLEMac': '- BLE MAC address', // JP
    'Vehicle.Wifiname': '- WIFI Access name', // JP

    // 車両管理 > 車両制御
    'VehicleMonitoring.SearchValue.Placeholder': '車両番号またはモデル名を入力',
    'VehicleMonitoring.Status': '状態',
    'VehicleMonitoring.LicensePlateNumber': '車両番号',
    'VehicleMonitoring.VehicleModelName': 'モデル名',
    'VehicleMonitoring.LocationName': 'ステーション',
    'VehicleMonitoring.Time': '日時',
    'VehicleMonitoring.UserName': '名前',
    'VehicleMonitoring.ControlCommand': '制御',
    'VehicleMonitoring.Result': '結果',
    'VehicleMonitoring.ENG_ON': '運行中',
    'VehicleMonitoring.ENG_OFF': '停車中',
    'VehicleMonitoring.INCIDENT': '車両の異常',
    'VehicleMonitoring.MAINTENANCE': '維持補修',
    'VehicleMonitoring.REFUEL': '充電',
    'VehicleMonitoring.NOT_RETURN': '返却遅延',
    'VehicleMonitoring.VehicleDetail': '車両状態',
    'VehicleMonitoring.CurrentLocation': '現在位置',
    'VehicleMonitoring.VehicleControl': '車両制御',
    'VehicleMonitoring.ControlHistory': '制御履歴(サーバー)',
    'VehicleMonitoring.ControlHistory.Ble': '制御履歴(BLE)',
    'VehicleMonitoring.Command.OpenDoor': '解錠',
    'VehicleMonitoring.Command.CloseDoor': '施錠',
    'VehicleMonitoring.Command.Horn': '警笛/点滅燈',
    'VehicleMonitoring.Command.IGNProhibit': '始動ブロック',
    'VehicleMonitoring.Command.Immobilizer': 'FOB ON/OFF',
    'VehicleMonitoring.Command.Preventreign': '始動ブロックON/OFF',
    'VehicleMonitoring.CommandResultAddToast':
        '10秒後に再度、確認してください。',
    'VehicleMonitoring.CommandResultAddToast2':
        'しばらくして再度、認してください。',
    'VehicleMonitoring.VehicleStatus': '車両状態',
    'VehicleMonitoring.ReConfirm': '再確認',
    'VehicleMonitoring.VehicleOperation': '車両運行',
    'VehicleMonitoring.VehicleDailyOperation': '車両運行日誌',
    'VehicleMonitoring.FailCollectInformation': '情報収集失敗',
    'VehicleMonitoring.IGNStatus': '始動状態',
    'VehicleMonitoring.FuelLevelPercentage': '充電/充填量',
    'VehicleMonitoring.Odometer': '総走行距離',
    'VehicleMonitoring.OdoKilometer': '最近移動距離',
    'VehicleMonitoring.TPMS': 'タイヤ空気圧(psi)',
    'VehicleMonitoring.FrontLeft': '左前',
    'VehicleMonitoring.FrontRight': '右前',
    'VehicleMonitoring.RearLeft': '左後',
    'VehicleMonitoring.RearRight': '右後',
    'VehicleMonitoring.BusinessName': '貸出会員社',
    'VehicleMonitoring.BusinessName.Exclusive': '専用会員社',
    'VehicleMonitoring.DepartmentName': '部署',
    'VehicleMonitoring.DepartmentName.Exclusive': '専用部署',
    'VehicleMonitoring.BatteryVoltage': '12Vバッテリー(Voltage)',
    'VehicleMonitoring.TPMSFrontLeft': '前タイヤ/左',
    'VehicleMonitoring.TPMSFrontRight': '前タイヤ/右',
    'VehicleMonitoring.TPMSRearLeft': '後タイヤ/左',
    'VehicleMonitoring.TPMSRearRight': '後タイヤ/右',
    'VehicleMonitoring.EngineStarted': '始動状態',
    'VehicleMonitoring.DoorLocked': 'ドアロック',
    'VehicleMonitoring.DoorLockFrontLeft': '運転席ドアロック',
    'VehicleMonitoring.DoorLockFrontRight': '助手席ドアロック',
    'VehicleMonitoring.DoorLockRearLeft': '後部座席/左ドアロック',
    'VehicleMonitoring.DoorLockRearRight': '後部座席/右ドアロック',
    'VehicleMonitoring.DoorOpened': '解錠',
    'VehicleMonitoring.DoorOpenFrontLeft': '運転席ドア状態開',
    'VehicleMonitoring.DoorOpenFrontRight': '助手席ドア状態開',
    'VehicleMonitoring.DoorOpenRearLeft': '後部座席/左ドア状態開',
    'VehicleMonitoring.DoorOpenRearRight': '後部座席/右ドア状態開',
    'VehicleMonitoring.HoodOpened': 'ボンネット',
    'VehicleMonitoring.TrunkOpened': 'トランク',
    'VehicleMonitoring.HoodTrunkOpen': 'ボンネット/トランクドア状態開',
    'VehicleMonitoring.FuelCapOpened': '充電/充填キャップ',
    'VehicleMonitoring.HeadlightStatus': 'ヘッドランプ',
    'VehicleMonitoring.GearPosition': 'ギア',
    'VehicleMonitoring.Charge.Connected': '充電/充填器接続',
    'VehicleMonitoring.Charging.Remain.Time': '充電/充填残時間',
    'VehicleMonitoring.DTE': '走行可能距離',
    'VehicleMonitoring.BAT_SOC': '12Vバッテリー(SoC)',
    'VehicleMonitoring.ChargingYn': '充電/充填',
    'Vehicle.State.Charging.Y': '充電/充填中',
    'Vehicle.State.Charging.N': '-',
    'Vehicle.State.Charging.Connection.Y': '接続',
    'Vehicle.State.Charging.Connection.N': '-',
    'Vehicle.State.Charging.Remain.Time': '{hours, select, 0 {{minutes} 分} other {{hours} 時間 {minutes}分}}',
    'Vehicle.State.Update.Scheduled': 'アップデート予定',
    'Vehicle.State.RequestIng': '収集中',
    'Vehicle.State.NA': 'N/A',
    'Vehicle.State.On': 'ON',
    'Vehicle.State.Off': 'OFF',
    'Vehicle.State.Lock': 'ロック',
    'Vehicle.State.Unlock': '解除',
    'Vehicle.State.Open': '開く',
    'Vehicle.State.Close': '閉じる',
    'Vehicle.State.Gear.Drive': 'D',
    'Vehicle.State.Gear.EV.B': 'B',
    'Vehicle.State.Gear.0': 'P',
    'Vehicle.State.Gear.1': 'L',
    'Vehicle.State.Gear.2': '2',
    'Vehicle.State.Gear.3': '3',
    'Vehicle.State.Gear.4': 'DS Mode',
    'Vehicle.State.Gear.5': 'D',
    'Vehicle.State.Gear.6': 'N',
    'Vehicle.State.Gear.7': 'R',
    'Vehicle.State.Gear.8': 'Sports Mode',
    'Vehicle.State.Gear.9': 'Not Display at Cluster',
    'Vehicle.State.Gear.10': 'Sub-Rom Communication',
    'Vehicle.State.Gear.11': 'Sub-Rom Communication Error',
    'Vehicle.State.Gear.12': 'Paddle Shift',
    'Vehicle.State.Gear.13': 'Reserved',
    'Vehicle.State.Gear.14': 'Intermediate Position',
    'Vehicle.State.Gear.15': 'Fault',
    'Vehicle.State.cLightSwState.0': 'OFF',
    'Vehicle.State.cLightSwState.1': 'テールランプ ON',
    'Vehicle.State.cLightSwState.2': 'ロービーム ON',
    'Vehicle.State.cLightSwState.3': 'Auto',
    'VehicleMonitoring.RequestedTime': '要請日時',
    'VehicleMonitoring.FinishedTime': '応答日時',
    'VehicleMonitoring.CallbackCode': 'コールバックコード',
    'VehicleMonitoring.ResponseCode': '応答コード',

    // 車両管理 > 車両運行日誌
    'VehicleDailyOperation.BusinessName': '会員社',
    'VehicleDailyOperation.BusinessName.Placeholder': '会員社入力',
    'VehicleDailyOperation.DepartmentName': '部署',
    'VehicleDailyOperation.RenterName': '使用者',
    'VehicleDailyOperation.RenterName.Placeholder': '使用者入力',
    'VehicleDailyOperation.ReservationTime': '使用日',
    'VehicleDailyOperation.ReservationTime.Label': '期間',
    'VehicleDailyOperation.LicensePlateNumber': '車両番号',
    'VehicleDailyOperation.LicensePlateNumber.Placeholder': '車両番号入力',
    'VehicleDailyOperation.Provider': '供給社',
    'VehicleDailyOperation.ModelName': '車種',
    'VehicleDailyOperation.ModelName.Placeholder': '車種入力',
    'VehicleDailyOperation.StartOdoKilometer': '走行前距離',
    'VehicleDailyOperation.EndOdoKilometer': '走行後距離',
    'VehicleDailyOperation.ChangeOdoKilometer': '走行距離',
    'VehicleDailyOperation.RenterOdoKilometer': '使用距離',
    'VehicleDailyOperation.ReservationType': '予約タイプ',
    'VehicleDailyOperation.ReservationType.BUSINESS': '業務用',
    'VehicleDailyOperation.ReservationType.PRIVATE': '個人用',
    'VehicleDailyOperation.TotalDistance': '走行距離',
    'VehicleDailyOperation.RenterDistance': '業務用使用距離',
    'VehicleDailyOperation.PersonalDistance': '出退勤用',
    'VehicleDailyOperation.BusinessDistance': '一般業務用',
    // 'VehicleDailyOperation.RdHistoryCreate': 'ダウンロードの理由',
    // 'VehicleDailyOperation.RdHistoryCreate.Text':
    //     '車両運行日誌のダウンロードの理由を入力してください。',
    // 'VehicleDailyOperation.Description.Input': '理由入力',
    // 'VehicleDailyOperation.Description.Placeholder': '内容を入力してください。',
    // 'VehicleDailyOperation.RdHistoryView': 'レポートダウンロード内訳',
    // 'VehicleDailyOperation.RoleName': '管理者アカウント',
    // 'VehicleDailyOperation.CreatorName': '管理者',
    // 'VehicleDailyOperation.DownloadTime': 'ダウンロード日時',
    // 'VehicleDailyOperation.Period': '期間',
    // 'VehicleDailyOperation.UserName': '使用者',
    // 'VehicleDailyOperation.Description': '理由',

    // 車両管理 > 車種(車両モデル)
    'VehicleModel.Standard': '代表車種',
    'VehicleModel.Standard.Setup': '代表車種設定',
    'VehicleModel.StandardTag': '代表',
    'VehicleModel.Name': '車種',
    'VehicleModel.Name.Placeholder': '車種入力',
    'VehicleModel.Name.Alert': '車種を入力してください。',
    'VehicleModel.CheckDuplication': '重複チェック',
    'VehicleModel.Brand': '製造社',
    'VehicleModel.Brand.Placeholder': '製造社選択',
    'Brand.Type.Hyundai': '現代自動車',
    'Brand.Type.Kia': 'キア自動車',
    'VehicleModel.Grade': 'グレード',
    'VehicleModel.GradeTypeNum': 'グレード(数字タイプ)',
    'VehicleModel.Grade.All': 'グレード全体',
    'VehicleModel.Grade.Placeholder': 'グレード選択',
    'Grade.Type.10': '軽自動車',
    'Grade.Type.20': '小型乗用車',
    'Grade.Type.30': '準中型乗用車',
    'Grade.Type.40': '中型乗用車',
    'Grade.Type.50': '大型乗用車',
    'Grade.Type.60': 'SUV',
    'Grade.Type.70': 'VAN(バン)',
    'Grade.Type.80': '電気自動車',
    'Grade.Type.90': '輸入車（外車）',
    'Grade.Type.0': '不明',
    'VehicleModel.FuelType': '燃料タイプ',
    'VehicleModel.FuelTypeNum': '燃料タイプ(数字タイプ)',
    'VehicleModel.FuelType.All': '燃料タイプ全体',
    'VehicleModel.FuelType.Placeholder': '燃料タイプ選択',
    'Fuel.Type.Gasoline': 'ガソリン',
    'Fuel.Type.Diesel': '軽油',
    'Fuel.Type.Ev': '電気',
    'Fuel.Type.Hybrid': 'ハイブリッド',
    'Fuel.Type.PlugInHybrid': 'プラグインハイブリッド',
    'Fuel.Type.HYDROGEN': '水素',
    'Fuel.Type.LPG': 'LPG',
    'Fuel.Type.LNG': 'LNG',
    'Fuel.Type.ALCOHOL': 'アルコール',
    'Fuel.Type.UNKNOWN': '不明',
    'VehicleModel.SeatingCapacity': '乗車人数(人乗り)',
    'VehicleModel.SeatingCapacity.Placeholder': '乗車人数入力',
    'VehicleModel.DisplacementOrMaxPower': '排気量/出力',
    'VehicleModel.Displacement': '排気量(cc)',
    'VehicleModel.Displacement.Placeholder': 'ex) 1800',
    'VehicleModel.MaxPower': '出力(kW)',
    'VehicleModel.FuelTankCapacityOrBatteryCapacity':
        '燃料タンク /バッテリー容量',
    'VehicleModel.FuelTankCapacity': '燃料タンク容量',
    'VehicleModel.FuelTankCapacity.Placeholder': 'ex) 70',
    'VehicleModel.BatteryCapacity': 'バッテリー容量',
    'VehicleModel.FuelEfficiency': '燃費(km/l)',
    'VehicleModel.FuelEfficiency.Placeholder': 'ex) 12.5',
    'VehicleModel.EvMileageComposite': '電費(km/kWh)',
    'VehicleModel.DeviceCode': 'モデルコード',
    'VehicleModel.DeviceCode.Placeholder': 'モデルコード入力',
    'VehicleModel.Image': '車両イメージ',
    'VehicleModel.Image.Helper': '車両イメージは必ず登録しなければなりません。',
    'VehicleModel.Grade.Label': 'グレード',
    'VehicleModel.Name.Input': '車種入力',
    'VehicleModel.Name.Duplicate.Helper': '車種重複チェックをしてください。',
    'VehicleModel.Name.Duplicate.Y': '重複した車種です。',
    'VehicleModel.Name.Duplicate.N': '登録可能な車種です。',
    'VehicleModel.Image.File.extension': 'png, jpg 登録',
    'VehicleModel.Image.File.extension2': 'png, jpg, pdf ファイル登録', // JP
    'VehicleModel.Image.File.Select': 'ファイル選択',
    'VehicleModel.Image.Preview': '画像のプレビュー',
    'VehicleModel.Image.Desc': '車両画像は、代表車種に設定すると登録できます。',
    'VehicleModel.Provider': '供給社',
    'VehicleModel.Provider.All': '供給社全体',
    'VehicleModel.Provider.Placeholder': '供給社選択',
    'VehicleModel.BaseFee': '1日基本料金',
    'VehicleModel.BaseFee.Placeholder': '1日基本料金入力',
    'VehicleModel.DelayFee': '遅延料金',
    'VehicleModel.DelayFee.Placeholder': '遅延料金入力',
    'VehicleModel.FotaVer': 'FotaVer',
    'VehicleModel.FotaVer.Placeholder': 'FotaVer 入力',

    // 車両管理 > 端末機
    'Terminal.LicensePlateNumber': '車両番号',
    'Terminal.VinNumber': 'VIN',
    'Terminal.ControlType': '端末機のタイプ',
    'Terminal.ControlType.MaasDevice': 'MAAS_DEVICE',
    'Terminal.NadID': 'USIM番号',
    'Terminal.Communication.State': '通信状態',
    'Terminal.Communication.State.Normal': '正常',
    'Terminal.Communication.State.Conflict': '衝突',
    'Terminal.Communication.State.NoData': 'ServiceHub 情報がございません。',
    'Terminal.Communication.State.1': '待機',
    'Terminal.Communication.State.2': '装着',
    'Terminal.Status': '端末機の状態',
    'Terminal.Status.Normal': '正常',
    'Terminal.Status.Breakdown': '固定',
    'Terminal.Status.Repair': '修理',
    'Terminal.Status.Disposal': '廃棄',
    'Terminal.DeviceState.5': '装着状態',
    'Terminal.HealthCheck': '端末機状態チェック',
    'Terminal.InitialSetting': '端末機の初期設定',
    'Terminal.LicensePlateNumber.Placeholder': '車両番号入力',
    'Terminal.VinNumber.Placeholder': 'VIN 入力',
    'Terminal.NadID.Placeholder': '電話番号入力',
    'Terminal.controlType.AllSelect': '端末機のタイプ全体',
    'Terminal.Communication.Status.AllSelect': '通信状態全体',
    'Terminal.Status.AllSelect': '端末機の状態全体',
    'Terminal.SearchWarning':
        '* 実物端末情報を検索するので、一致しない場合があります。',
    'Terminal.VinNumber.Input.Placeholder': '英文字、数字17桁入力',
    'Terminal.LicensePlateNumber.Input.Placeholder':
        'VIN 入力後に確認ボタンをクリック',
    'Terminal.TerminalType.Input.Placeholder': '端末機タイプ選択',
    'Terminal.SoftwareVersion': 'F/W Ver.',
    'Terminal.SoftwareVersion.Input.Placeholder': 'ソフトウェアバージョン入力',
    'Terminal.SerialNumber': 'Serial NO.',
    'Terminal.SerialNumber.Input.Placeholder': 'ex. CASD12434052DS888',
    'Terminal.Manufacturer': '製造社',
    'Terminal.Manufacturer.Input.Placeholder': '製造社入力',
    'Terminal.DeliveryDate': '納品日',
    'Terminal.DeliveryDate.Placeholder': '納品日選択',
    'Terminal.OrderDate': '発注要請日',
    'Terminal.OrderDate.Placeholder': '発注要請日選択',
    'Terminal.WireService': '通信社',
    'Terminal.WireService.KT': 'KT',
    'Terminal.WireService.SKT': 'SKT',
    'Terminal.WireService.U+': 'U+',
    'Terminal.WireService.Input.Placeholder': '通信社選択',
    'Terminal.ActivationDate': '通信開通日',
    'Terminal.ActivationDate.Placeholder': '通信開通日選択',
    'Terminal.USIMNumber': 'USIM 番号',
    'Terminal.USIMNumber.Input.Placeholder':
        'USIM 番号入力("-"ハイフンなしで入力)',
    'Terminal.BasicInfo': '基本情報',
    'Terminal.WireServiceInfo': '通信社',
    'Terminal.Title.Status': '状態',
    'Terminal.ActivationDate.Label': '設置日',
    'Terminal.DeviceState': '端末機状態',
    'Terminal.DeviceState.Input.Placeholder': '端末機状態選択',
    'Terminal.ConnectionState': '通信状態',
    'Terminal.Title.Vehicle': '車両情報',
    'Terminal.Vehicle.Company': '供給社',
    'Terminal.FobYn': 'FOB 装着',
    'Terminal.HealthCheck.Dialog': '端末機状態チェック',
    'Terminal.InitialSetting.Dialog': '端末機装着の初期設定の順序', // JP
    'Terminal.ResponseTime': '応答時間',
    'Terminal.Status.Unavailable': '状態確認不可',
    'Terminal.BleAddress': 'BLE アドレス確認',
    'Terminal.BleAddress.DB': 'BLE アドレス確認(DB)',
    'Terminal.BleAddress.Unavailable': 'アドレス確認不可',
    'Terminal.Fob.Status': 'FOB キー状態',
    'Terminal.Fob.Status.Off': 'キー状態 OFF',
    'Terminal.GPS.Check': 'GPS チェック',
    'Terminal.GPS.Check.Unavailable': 'GPS 受信感度が良くございません。',
    'Terminal.Slave.Version': 'Slave バージョン',
    'Terminal.Version.Unavailable': 'バージョン情報確認不可',
    'Terminal.Control.Version': 'Control ver.',
    'Terminal.Software.Version': 'F/W ver.',
    'Terminal.ControlStatus.Check': '車両制御状態チェック',
    'Terminal.FOTA': 'FOTA サーバー',
    'Terminal.FOTA.Dev': '開発系',
    'Terminal.FOTA.Stg': '検証系',
    'Terminal.FOTA.Prd': '運営系',
    'Terminal.Update': 'FOTA 実施', // JP
    'Terminal.Update.FirmWare': 'ファームウェア',
    'Terminal.Update.DB': 'DB',
    'Terminal.Update.FirmWareAndDB': 'ファームウェア+DB',
    'Terminal.HerbURLChange': 'ハブ URL 変更',
    'Terminal.HerbURLChange.Prd': '運営系 URL',
    'Terminal.HerbURLChange.Stg': '検証系 URL',
    'Terminal.Reset': '端末機リセット',
    'Terminal.SMS': '端末機 SMS 送信',
    'Terminal.Ble.Update': '端末機 BLE 設定',
    'Terminal.Ble.Btn.Sync': 'SYNC',
    'Status.Match': '一致',
    'Status.NotMatch': '不一致',
    'Terminal.Ble.PubK.Get': '公開キー確認',
    'Terminal.Ble.PubK.Create': '公開キー登録',
    'Terminal.Ble.PubK.Update': '公開キー更新',
    'Terminal.Ble.PubK.Delete': '公開キー削除',
    'Terminal.Ble.Msg.Success': 'BLE処理に成功しました。.',
    'Terminal.Ble.Msg.Fail': 'BLEに失敗しました。.',
    'Terminal.Ble.Msg.Fail.AlreadyExists':
        'BLE登録された公開キーが既に存在します。.',
    'Terminal.Ble.Msg.Fail.NotFound':
        '公開キーがございません。{br}公開キーの登録を行ってください。',
    'Terminal.Ble.Sync.Confirm': '同期化しますか?',
    'Terminal.Ble.Status.Fail': 'BLE状態を確認できません。',
    'Message.Confirm.Terminal.InitialSetting.Process':
        '端末機の初期設定を行いますか?',
    'Message.Success.Terminal.InitialSetting.Process':
        '端末機の初期設定を行いました。',
    'Message.Failure.Terminal.InitialSetting.Process':
        '端末機の初期設定ができませんでした。',
    'Message.Confirm.Terminal.InitialSetting.Delete':
        '端末機の初期設定を削除しますか?',
    'Message.Success.Terminal.InitialSetting.Delete':
        '端末機の初期設定を削除しました。',
    'Message.Failure.Terminal.InitialSetting.Delete':
        '端末機の初期設定を削除できませんでした。',
    'Message.Confirm.Terminal.Update.FirmWare':
        '端末機のファームウェアをアップデートしますか?',
    'Message.Success.Terminal.Update.FirmWare':
        '端末機のファームウェアをアップデートしました。',
    'Message.Failure.Terminal.Update.FirmWare':
        '端末機のファームウェアをアップデートできませんでした。',
    'Message.Confirm.Terminal.Update.DB': '端末機のDBをアップデートしますか?',
    'Message.Success.Terminal.Update.DB': '端末機のDBをアップデートしました。',
    'Message.Failure.Terminal.Update.DB':
        '端末機のDBをアップデートできません。',
    'Message.Confirm.Terminal.Update.FirmWareAndDB':
        '端末機ファームウェア+DBをアップデートしますか?',
    'Message.Success.Terminal.Update.FirmWareAndDB':
        '端末機ファームウェア+DBをアップデートしました。',
    'Message.Failure.Terminal.Update.FirmWareAndDB':
        '端末機ファームウェア+DBをアップデートできません。',
    'Message.Confirm.Terminal.HerbURLChange.Prd': '運営系URLを変更しますか?',
    'Message.Success.Terminal.HerbURLChange.Prd': '運営系URLを変更しました。',
    'Message.Failure.Terminal.HerbURLChange.Prd': '運営系URLを変更できません。',
    'Message.Confirm.Terminal.HerbURLChange.Stg': '検証系URLを変更しますか?',
    'Message.Success.Terminal.HerbURLChange.Stg': '検証系URLを変更しますか?',
    'Message.Failure.Terminal.HerbURLChange.Stg': '検証系URLを変更できません。',
    'Message.Confirm.Terminal.Reset': '端末機をリセットしますか?',
    'Message.Success.Terminal.Reset': '端末機をリセットしました。',
    'Message.Failure.Terminal.Reset': '端末機をリセットできません。',
    'Terminal.SMS.SendContents.Placeholder': '内容を入力',
    'Message.Confirm.Terminal.SMS.Send': '端末機SMSを送信しますか?',
    'Message.Success.Terminal.SMS.Send': '端末機SMSを送信しました。',
    'Message.Failure.Terminal.SMS.Send': '端末機SMSを送信できません。',
    'Message.Confirm.Terminal.Ble.Sync': 'SYNCを行いますか?',
    'Message.Success.Terminal.Ble.Sync': 'SYNCを行いました。',
    'Message.Failure.Terminal.Ble.Sync': 'SYNCができません。',
    'Message.Confirm.Terminal.Ble.Create': '公開キーを登録しますか?',
    'Message.Success.Terminal.Ble.Create': '公開キーを登録しました。',
    'Message.Failure.Terminal.Ble.Create': '公開キーを登録できません。',
    'Message.Confirm.Terminal.Ble.Update': '公開キーを更新しますか?',
    'Message.Success.Terminal.Ble.Update': '公開キーを更新しました。',
    'Message.Failure.Terminal.Ble.Update': '公開キーを更新できません。',
    'Message.Confirm.Terminal.Ble.Delete': '公開キーを削除しますか?',
    'Message.Success.Terminal.Ble.Delete': '公開キーを削除しました。',
    'Message.Failure.Terminal.Ble.Delete': '公開キーを削除できません。',
    'Terminal.Provider': '供給社',
    'Terminal.Provider.All': '供給社全体',
    'Terminal.Provider.Placeholder': '供給社選択',
    'Terminal.BleMacAddress': 'BLE MAC Address',
    'Terminal.WIFIName': 'WIFI Access Name',

    // 車両管理 > 端末装着検証
    'Terminal.Verification.Period': '期間',
    'Terminal.Verification.Date': '検証日',
    'Terminal.Verification.Status': '取り付け状態',
    'Terminal.Verification.Status.AllSelect': '取り付け状態全体',
    'Terminal.Verification.Status.Success': '検証完了',
    'Terminal.Verification.Status.Failure': '検証失敗',
    'Terminal.Verification.Status.InProgress' : '検証進行中',
    'Terminal.Verification.Verifier': '検証者',
    'Terminal.Verification.Sub.Info': '検証情報',
    'Terminal.Verification.CreateDate': '検証日',
    'Terminal.Verification.UpdateDate': '修正日',
    'Terminal.Verification.Normal': '正常',
    'Terminal.Verification.Abnormal': '異常',
    'Terminal.Verification.NotYet': '未進行',
    'Terminal.Verification.Sub.Device': 'デバイスファームウェアチェック',
    'Terminal.Verification.DB.Check': 'DBチェック',
    'Terminal.Verification.FW.Check': 'ファームウェアチェック',
    'Terminal.Verification.IGN.Check': 'IGNチェック',
    'Terminal.Verification.VIN.Check': 'VINチェック',
    'Terminal.Verification.Remark': '特異事項',
    'Terminal.Verification.Sub.Vehicle': '車両状態チェック',
    'Terminal.Verification.Response.Time': '応答受信所要時間',
    'Terminal.Verification.View.Time': '端末情報照会時刻',
    'Terminal.Verification.Accumulate.Distanse': '累積移動距離',
    'Terminal.Verification.Sub.Location': '車両位置チェック',
    'Terminal.Verification.Device.Locaiton': '端末基準車両位置',
    'Terminal.Verification.Server.Location': 'サーバーベースの車両位置',
    'Terminal.Verification.HDOP': 'HDOP',
    'Terminal.Verification.Sub.Door': 'ドア制御チェック',
    'Terminal.Verification.Lock.Ble': 'ロック(BLE)',
    'Terminal.Verification.UnLock.Ble': 'ロック解除(BLE)',
    'Terminal.Verification.Lock.Server': 'ロック(サーバー)',
    'Terminal.Verification.UnLock.Server': 'ロック解除(サーバー)',
    'Terminal.Verification.Sub.SmartKey': 'スマートキー (FOB ON, OFF)',
    'Terminal.Verification.Smart.On.Ble': 'スマートキーON始動ジャム（BLE）',
    'Terminal.Verification.Smart.Off.Ble': 'スマートキーOFF始動しない（BLE）',
    'Terminal.Verification.Smart.On.Server': 'スマートキーON始動ジャム（サーバー）',
    'Terminal.Verification.Smart.Off.Server': 'スマートキーOFF始動しない（サーバー）',
    'Terminal.Verification.Sub.Ign': '始動ブロックチェック',
    'Terminal.Verification.Ign.On': '始動ブロックON始動しない',
    'Terminal.Verification.Ign.Off': '始動ブロックOFF始動ジャム',

    // 車両管理 > 充電/充填履歴
    'Refuel.Stats': '総 {totalCost}円',
    'Refuel.LicensePlateNumber': '車両番号',
    'Refuel.LicensePlateNumber.Placeholder': '車両番号入力',
    'Refuel.BusinessName': '会員社',
    'Refuel.BusinessName.Placeholder': '会員社入力',
    'Refuel.DepartmentName': '部署',
    'Refuel.DepartmentName.Placeholder': '部署入力',
    'Refuel.ReservationKey': '貸出番号',
    'Refuel.ReservationKey.Placeholder': '貸出番号入力',
    'Refuel.CardNum': 'カード番号',
    'Refuel.CardNum.Placeholder': 'カード番号入力',
    'Refuel.PaidAt': '決済期間',
    'Refuel.UserName': '名前',
    'Refuel.ReservationTime': '貸出期間',
    'Refuel.FuelType': '燃料タイプ',
    'Refuel.EventTime': '決済日',
    'Refuel.OilStation': '充電所/充填所名',
    'Refuel.RefuelCost': '充電/充填額',
    'Refuel.RefuelLiter': '充電/充填量',
    'Refuel.FtlAmountChange': '充電/充填変化量',
    'Refuel.ReservationType': 'タイプ',
    'Refuel.ReservationType.All': 'Profile タイプ全体',

    // 車両管理 > ETC Card 履歴
    'Transit.Stats': '総 {totalCost}円',
    'Transit.LicensePlateNumber.Placeholder': '車両番号入力',
    'Transit.Business.Placeholder': '会員社入力',
    'Transit.Department.Placeholder': '部署入力',
    'Transit.RegistrationNumber.Placeholder': '予約番号入力',
    'Transit.CardNumber.Placeholder': 'カード番号入力',
    'Transit.BusinessName': '会員社',
    'Transit.UserName': '貸出会員',
    'Transit.ReservationKey': '貸出番号',
    'Transit.ReservationDate': '貸出期間',
    'Transit.LicensePlateNumber': '車両番号',
    'Transit.CardNum': 'カード番号',
    'Transit.PassedAt': '位置確認日時',
    'Transit.TotalCost': '決済金額',
    'Transit.Location': '位置',
    'Transit.TollgateExit': '出口',
    'Transit.TollgateEntrance': '入り口',
    'Transit.TollgateEntrance.Unspecified': '未指定(入り口)',
    'Transit.TollgateExit.Info1': '{tollgateExit}',
    'Transit.TollgateExit.Info2': '({passedAt})',
    'Transit.TollgateEntrance.Info1': '{tollgateEntrance}',
    'Transit.TollgateEntrance.Info2': '({entrancePassedAt})',
    'Transit.ReservationType': 'タイプ',
    'Transit.ReservationType.All': 'Profileタイプ全体',

    // 車両管理 > 事故処理履歴
    'VehicleRepair.ManageType': '管理項目',
    'VehicleRepair.State': '進行状態',
    'VehicleRepair.RepairStation': '担当営業店',
    'VehicleRepair.Period': '作業期間',
    'VehicleRepair.AccidentTime': '事故日時',
    'VehicleRepair.AccidentTime.Placeholder': '事故日時入力',
    'VehicleRepair.AccidentType': '事故分類',
    'VehicleRepair.AccidentType.AllSelect': '事故分類の全体',
    'VehicleRepair.AccidentType.Placeholder': '事故分類の選択',
    'VehicleRepair.AccidentInfo': '事故情報',
    'VehicleRepair.Repair.Info': '作業内訳',
    'VehicleRepair.Repair.Detail': '詳細内訳',
    'VehicleRepair.Repair.Before': '作業前',
    'VehicleRepair.Repair.After': '作業後',
    'VehicleRepair.Repair.Before.Placeholder': '作業前事項を入力',
    'VehicleRepair.Repair.After.Placeholder': '作業後事項を入力',
    'VehicleRepair.Repair.Regist': '情報登録',
    'VehicleRepair.Repair.Select': '詳細情報',
    'VehicleRepair.Repair.RegistTime': '登録日',
    'VehicleRepair.Repair.RegistNum': '登録番号',
    'VehicleRepair.Rental.Interwork': '登録日',
    'VehicleRepair.Vehicle.Num': '車両番号',
    'VehicleRepair.Vehicle.Num.Placeholder': '車両番号入力',
    'VehicleRepair.Vehicle.Num.Placeholder2': '車両番号検索',
    'VehicleRepair.Repair.Cost': '請求費用(円)',
    'VehicleRepair.Repair.State': '進行状態',
    'VehicleRepair.Repair.State.Placeholder': '進行状態選択',
    'VehicleRepair.Rental.Num': '貸出番号',
    'VehicleRepair.Rental.Num.Placeholder': '貸出番号選択',
    'VehicleRepair.Accident.Time': '事故日時',
    'VehicleRepair.Accident.Time.Placeholder': '事故日時入力',
    'VehicleRepair.Accident.Type': '事故分類',
    'VehicleRepair.Accident.Location': '事故位置',
    'VehicleRepair.Accident.Memo': '事故経緯',
    'VehicleRepair.Accident.Part': '事故部位',
    'VehicleRepair.Accident.Part.Placeholder': '事故部位入力',
    'VehicleRepair.Accident.Photo': '事故写真',
    'VehicleRepair.Accident.Latitude': '事故経緯度',
    'VehicleRepair.Accident.Longitude': '経度',
    'VehicleRepair.Accident.Latitude.Placeholder': '緯度入力',
    'VehicleRepair.Accident.Longitude.Placeholder': '経度入力',
    'VehicleRepair.Accident.File.Comment': '※ 登録写真容量10MB 以下',
    'VehicleRepair.Accident.UserFaultRate': '顧客過失比率(%)',
    'VehicleRepair.Accident.InsureReceptNum': '運行車両保険受付<br/>番号',
    'VehicleRepair.Accident.OpntVehicleNumber': '相手車両番号',
    'VehicleRepair.Accident.UserFaultRate.Placeholder': '顧客過失比率(%) 入力',
    'VehicleRepair.Accident.InsureReceptNum.Placeholder':
        '運行車両保険受付番号入力',
    'VehicleRepair.Accident.OpntVehicleNumber.Placeholder': '相手車両番号入力',
    'VehicleRepair.Accident.OpntInsureCompanyName': '相手車両保険会社',
    'VehicleRepair.Accident.OpntReceiptNum': '相手車両保険受付<br/>番号',
    'VehicleRepair.Accident.OpntReceiptNum.Placeholder':
        '相手車両保険受付番号入力',
    'VehicleRepair.Accident.OpntInsureCompanyName.Placeholder':
        '相手車両保険会社入力',
    'VehicleRepair.Accident.Type.1': '単独',
    'VehicleRepair.Accident.Type.2': '車対車(双方)',
    'VehicleRepair.Accident.Type.3': '車対車(被害)',
    'VehicleRepair.Accident.Status.1': '受付完了',
    'VehicleRepair.Accident.Status.2': '処理中',
    'VehicleRepair.Accident.Status.4': '請求',
    'VehicleRepair.Accident.Status.8': '処理完了',
    'VehicleRepair.Accident.Status.Claim': '未払い',
    'VehicleRepair.VehicleCoverageYn': '対物発生有無',
    'VehicleRepair.PersonCoverageYn': '対人発生有無',
    'VehicleRepair.SelfPhysicalYn': '自損発生有無',
    'VehicleRepair.DrivingYn': '車両正常運行可能有無',
    'VehicleRepair.AgentName': '担当営業店',
    'VehicleRepair.AgentName.Placeholder': '担当営業店入力',
    'VehicleRepair.Repair.Status': '進行状態',
    'VehicleRepair.Repair.Status.AllSelect': '進行状態全体',
    'VehicleRepair.Repair.Status.Placeholder': '進行状態選択',
    'VehicleRepair.Repair.Time': '作業期間',
    'VehicleRepair.Repair.Description': '詳細内訳',
    'VehicleRepair.Repair.Description.Placeholder': '詳細内訳入力',
    'VehicleRepair.Repair.Fee': '作業費用',
    'VehicleRepair.Repair.Fee.Placeholder': '作業費用入力',
    'VehicleRepair.Repair.SelfVehicleCd': '自車処理',
    'VehicleRepair.Repair.Estimate': '修理見積書',
    'VehicleRepair.Repair.Self': '自己負担金請求書',
    'VehicleRepair.Repair.Insurance': '点検整備明細書',
    'VehicleRepair.Repair.Customer': '顧客請求書',
    'VehicleRepair.Repair.Content': 'メモ',
    'VehicleRepair.Repair.Content.Placeholder': 'メモ入力',
    'VehicleRepair.Cost': '費用',
    'VehicleRepair.Cost.Placeholder': '費用入力',
    'VehicleRepair.Rest.Cost': '休車料',
    'VehicleRepair.Rest.Cost.Placeholder': '休車料入力',
    'VehicleRepair.Etc.Cost': 'その他費用',
    'VehicleRepair.Etc.Cost.Placeholder': 'その他費用入力',
    'VehicleRepair.Already.Repair.Message':
        '該当貸出番号で既に受付された事故申告があります。移動しますか？',
    'VehicleRepair.NoBeforeImg.Message': '登録された画像がございません。',
    'VehicleRepair.RangeValidate.Message': '貸出時間をオーバーしました。',
    'VehicleRepair.NoIncidentTime.Message':
        '事故日時は必須項目です。貸出開始時間で設定してください。',
    'VehicleRepair.SelfFee.Max': '自己負担金 (最大)',
    'VehicleRepair.SelfFee.Min': '自己負担金 (最小)',
    'VehicleRepair.SelfFee.Min.Placeholder': '自己負担金 (最小値) 入力',
    'VehicleRepair.SelfFee.Percent': '自己負担金比率',
    'VehicleRepair.SelfFee.Percent.Placeholder': '自己負担金比率入力',
    'VehicleRepair.CompensationFee': 'NOC補償入会有無', // JP
    'VehicleRepair.Exemption': '免責商品', // JP
    'VehicleRepair.incidentDriver.RegNum': '事故運転者の社員番号', // JP

    // 車両管理 > 維持管理/整備履歴
    'Maintenance.BusinessName': '委託業者',
    'Maintenance.BusinessName.Select': '委託業者選択',
    'Maintenance.BusinessName.AllSelect': '委託業者全体',
    'Maintenance.LicensePlateNumber.Placeholder': '車両番号入力',
    'Maintenance.Status.Select': '管理状態選択',
    'Maintenance.Status.AllSelect': '管理状態全体',
    'Maintenance.CreatedAt': '登録日時',
    'Maintenance.Wiblezone.Select': 'ステーション選択',
    'Maintenance.Wiblezone.Placeholder': 'ステーション入力',
    'Maintenance.Wiblezone': 'ステーション',
    'Maintenance.Provider': '供給社',
    'Maintenance.LicensePlateNumber': '車両番号',
    'Maintenance.Status': '管理状態',
    'Maintenance.Period': '作業期間',
    'Maintenance.Period2': '作業期間照会',
    'Maintenance.ManageType': '管理項目',
    'Maintenance.Reservation': '車両予約',
    'Maintenance.RentalInfo': '車両貸出情報',
    'Maintenance.Vehicle.Select': '車両選択',
    'Maintenance.Search': '車両検索',
    'Maintenance.RepairTime': '作業日時\n(貸出期間)',
    'Maintenance.RepairTime1': '作業日時',
    'Maintenance.RepairTime2': '\n(貸出期間)',
    'Maintenance.Description': '詳細内訳',
    'Maintenance.RepairInfo': '作業内訳',
    'Maintenance.StatusCheck': '状態点検',
    'Maintenance.Vehicle': '車両',
    'Maintenance.ReservationPosibleTime': '予約可能な時間帯',
    'Maintenance.StartTime': '開始時間',
    'Maintenance.EndTime': '終了時間',
    'Maintenance.VehicleControl': '車両制御',
    'Maintenance.CreateNo': '登録番号',
    'Maintenance.CreateManagerName': '登録担当者/ID',
    'Maintenance.Description.Placeholder': '詳細内訳入力',
    'Maintenance.WorkingDate.Duration': '総作業時間:',
    'Maintenance.WorkingDate.ErrorMessage':
        '終了時間は開始時間以降でなければなりません。',
    'Maintenance.Command.OpenDoor': '解錠',
    'Maintenance.Command.CloseDoor': '施錠',
    'Maintenance.Command.Horn': '警笛/点滅燈',
    'Maintenance.Command.Immobilizer': 'FOB ON/OFF',
    'Maintenance.Command.Preventreign': '始動ブロックON/OFF',
    'Maintenance.Msg.Success': '制御を実行しました。',
    'Maintenance.Msg.Fail': '制御実行を失敗しました。',
    'Maintenance.Msg.Vehicle.Select':
        'ステーションを選択してから車両を検索してください。',
    'Maintenance.Msg.Wible.Select1': 'ステーション選択 > 作業日時選択後',
    'Maintenance.Msg.Wible.Select2':
        '該当日付の作業可能な車両を検索してみてください。',
    'Maintenance.Remaing.Gas': '充電/充填量{value}%',
    'Maintenance.Status.Complete': '作業完了',

    // 車両管理 > 売却
    'Trade.LicensePlateNumber': '車両番号',
    'Trade.LicensePlateNumber.Placeholder': '車両番号入力',
    'Trade.item': '処理項目',
    'Trade.TradeType': '処理項目',
    'Trade.TradeType.Select': '処理項目選択',
    'Trade.TradeType.AllSelect': '処理項目全体',
    'Trade.Type.Sale': '売却',
    'Trade.Type.Scrap': '廃車',
    'Trade.Type': '売却管理項目',
    'Trade.VehicleModel': '車種',
    'Trade.VehicleModel.Placeholder': '車種番号選択時自動入力',
    'Trade.TradeDate': '処理日',
    'Trade.TradeDate.Sale': '売却日付',
    'Trade.TradeDate.Scrap': '廃車日',
    'Trade.TradeAmount.Sale': '売却金額',
    'Trade.TradeAmount.Scrap': '廃車金額',
    'Trade.SaleBuyer': '購入者',
    'Trade.ScrapBuyer': '廃車場',
    'Trade.File': '譲渡契約書',
    'Trade.File.Placeholder': 'png, jpg, pdf ファイル登録',
    'Trade.Note': '備考',
    'Trade.SaleBuyer.Placeholder': '購入者を入力',
    'Trade.ScrapBuyer.Placeholder': '廃車場を入力',
    'Trade.TradeDate.Sale.Placeholder': '売却日入力',
    'Trade.TradeDate.Scrap.Placeholder': '廃車日入力',

    // 車両管理 > 車両制御履歴
    'ControlHistory.UserName': '名前',
    'ControlHistory.UserName.Input': '名前入力',
    'ControlHistory.LicensePlateNumber': '車両番号',
    'ControlHistory.LicensePlateNumber.Input': '車両番号入力',
    'ControlHistory.CommandDate': '制御日時',
    'ControlHistory.RequestedTime': '要請時間',
    'ControlHistory.FinishedTime': '応答時間',
    'ControlHistory.Action': 'アクション',
    'ControlHistory.ControlCommand': '制御命令',
    'ControlHistory.ResponseMessage': 'Req 結果',
    'ControlHistory.Result': '結果',
    'ControlHistory.CallbackCode': 'コールバックコード',
    'ControlHistory.ResponseCode': '応答コード',
    'ControlHistory.RequestData.Label': '要請内訳',
    'ControlHistory.ServiceHUBTID': 'TID',
    'ControlHistory.ClientID': 'Client ID',
    'ControlHistory.RequestData': '要請データ',
    'ControlHistory.ResponseData': '応答内訳',
    'ControlHistory.CallbackData': '応答データ',

    // ステーション管理
    'Location.Name': 'ステーション名',
    'Location.Name.Placeholder': 'ステーション名称入力',
    'Location.Address': '住所',
    'Location.Address.Placeholder': '住所入力',
    'Location.Address.Placeholder2': 'ステーション住所入力',
    'Location.BusinessName': '会員社',
    'Location.BusinessName.Placeholder': '会員社入力',
    'Location.BusinessName.Button': '会員社登録',
    'Location.BusinessName.Exclusive': '専用会員社',
    'Location.PersonalCd': 'B2C 利用形態',
    'Location.PersonalCd.AllSelect': 'B2C 利用形態全体',
    'Location.UseYn': '運営状態',
    'Location.UseYn.AllSelect': '運営状態全体',
    'Location.CountVehicle': 'ステーション内の車両',
    'Location.Info': '基本情報',
    'Location.WibleZoneLocation': 'ステーション位置',
    'Location.UndergroundYn': '地上/地下',
    'Location.Floor': '階数',
    'Location.Floor.Placeholder': '階数入力',
    'Location.IndoorYn': '室内/室外',
    'Location.LatitudeLongitude': 'ステーション経緯度',
    'Location.Latitude.Placeholder': '緯度入力',
    'Location.Longitude.Placeholder': '経度入力',
    'Location.OperationTime': '運営時間',
    'Location.OperationTime.Placeholder': '運営時間入力',
    'Location.OperationTimeJP': '運営時間',
    'Location.RegularHoliday': '定期休業',
    'Location.JPlatform': '(J-Platform)',
    'Location.RegularHoliday.Placeholder': '定期休業入力',
    'Location.Radius': '返却地点 (半径)',
    'Location.Radius.Placeholder': '半径選択',
    'Location.ChargeTypeCd': '個人用販売商品',
    'Location.ChargeTypeCd.TIMEBASE': 'Time-based',
    'Location.ChargeTypeCd.SPECIAL': '1回券',
    'Location.ChargeTypeCd.PASS': 'サブスクリプション券',
    'Location.ChargeTypeCd.ONEDAY': '1Day+',
    'Location.Contract.Period': '契約期間',
    'Location.Contract.Period.StartDate': '契約開始日時',
    'Location.Contract.Period.EndDate': '契約終了日時',
    'Location.Desc': 'ステーション説明',
    'Location.Desc.Placeholder': '説明入力',
    'Location.ParkingCount': '駐車スペース数',
    'Location.ParkingCount.Placeholder': '駐車スペース数入力',
    'Location.VehicleCount': '配車台数',
    'Location.VehicleCount.Placeholder': '配車台数入力',
    'Location.RentalFee': '駐車両賃貸料',
    'Location.RentalFee.Placeholder': '価格入力',
    'Location.ManagerName': '現場担当者',
    'Location.ManagerName.Placeholder': '名前入力',
    'Location.EntryExitMethod': '出入車方法',
    'Location.EntryExitMethod.Placeholder': '出入車方法入力',
    'Location.Memo': '注意事項',
    'Location.Memo.Placeholder': '注意事項入力',
    'Location.HolidayInfo': '休業日情報',
    'Location.Holiday': '休業日',
    'Location.Holiday.Placeholder': '休業日選択',
    'Location.Business': '会員社',
    'Location.BusinessInfo': '会員社情報',
    'Location.BusinessRegNum': '登録事業所番号',
    'Location.BusinessManagerName': '担当者名',
    'Location.Vehicle': '車両',
    'Location.VehicleInfo': '車両情報',
    'Location.VehicleInfo.Alert': `<span className="titleInfo">*車両別貸出時間は<span className="textColorBlack">車両管理 > 車両照会</span> から設定できます。</span>`,
    'Location.Vehicle.Search': '車両検索',
    'Location.LicensePlateNumber': '車両番号',
    'Location.VehicleModelName': '車種',
    'Location.DepartmentName': '専用部署',
    'Location.DepartmentName2': '部署',
    'Location.DepartmentName.Button': '部署登録',
    'Location.DepartmentName.Select': '部署選択',
    'Location.X': 'X',
    'Location.Guide': 'ステーション利用案内',
    'Location.Guide.Placeholder': 'ステーション利用案内選択',
    'Location.UsageGuide': '利用詳細案内',
    'Location.UsageGuide.Tab': '利用案内詳細',
    'Location.UsageGuide.Placeholder': '利用詳細案内選択',
    'Location.UsageGuide.Language': '利用詳細案内({value})',
    'Location.ReservationType': 'タイプ',
    'Location.ReservationType.BUSINESS': '業務用',
    'Location.ReservationType.PERSONAL': '個人用',
    'Location.RentalTime': '貸出時間',
    'Location.Product': '商品 (料金制/保険名)',
    'Location.Provider': '供給社', // JP
    'Location.AddressDetail': '詳しい住所', // JP
    'Location.AddressDetail.Placeholder': '詳しい住所を入力', // JP

    // 貸出/決済管理 > 貸出状況
    'ReservationSchedule.Daily': '日別',
    'ReservationSchedule.DailyDateFormat': 'YYYY年 MM月 DD日',
    'ReservationSchedule.Monthly': '月別',
    'ReservationSchedule.MonthlyDateFormat': 'YYYY年 MM月',
    'ReservationSchedule.RenterName.Placeholder': '貸出会員名入力',
    'ReservationSchedule.ReservationKey.Placeholder': '貸出番号入力',
    'ReservationSchedule.SubscribeID.Placeholder': 'サブスクリプション番号入力',
    'ReservationSchedule.VehiclePickupStationName.Placeholder':
        'ステーション名称入力',
    'ReservationSchedule.LicensePlateNumber.Placeholder': '車両番号入力',
    'ReservationSchedule.ModelName.Placeholder': '車種入力',
    'ReservationSchedule.ModelGrade.All': 'グレード全体',
    'ReservationSchedule.ProviderID.All': '供給社全体',
    'ReservationSchedule.ReservationType.All': 'プロフィールタイプ全体',
    'ReservationSchedule.VehiclePickupStationName': 'ステーション',
    'ReservationSchedule.ModelName': '車種',
    'ReservationSchedule.LicensePlateNumber': '車両番号',
    'ReservationSchedule.ReservationCount': '予約件数',
    'ReservationSchedule.ConfirmCount': '予約完了{value}件',
    'ReservationSchedule.UseCount': '利用中{value}件',
    'ReservationSchedule.ReturnCount': '返却完了{value}件',
    'ReservationSchedule.ReturnDelayCount': '返却遅延{value}件',
    'ReservationSchedule.BusinessCount': 'ビジネス{value}件',
    'ReservationSchedule.BasicCount': 'Time-based{value}件',
    'ReservationSchedule.PassCount': 'サブスクリプション券{value}件',
    'ReservationSchedule.SpecialCount': '1回券{value}件',
    'ReservationSchedule.MaintenanceCount': '車両管理 {value} 件', // JP
    'ReservationSchedule.PersonalCount': '個人 {value} 件', // JP
    'ReservationSchedule.MaintenanceTypeCdName': '車両管理 - {value}', // JP

    // 貸出/決済管理 > 貸出内訳
    'Reservation.Stats':
        '昨日 {prevReservation}件 | 今日 {todayReservation}件 | 計 {totalRecord}件',
    'Reservation.Rental.Id': '貸出番号',
    'Reservation.Rental.Id.Search': '貸出番号検索',
    'Reservation.Rental.Customer': '顧客名', //事故修理管理で顧客名を使用
    'Reservation.Customer.Name': '会員名',
    'Reservation.Customer.Name.Label': '貸出会員',
    'Reservation.Customer.Email': '電子メール',
    'Reservation.Customer.CompanyEmail': '会社電子メール',
    'Reservation.Customer.Cellphone': '連絡先',
    'Reservation.Customer.Cellphone.Masked': '{firstNumber}-****-{lastNumber}',
    'Reservation.Customer.Id': '会員 ID',
    'Reservation.BusinessName': '会員社',
    'Reservation.BusinessName.Search': '会員社検索',
    'Reservation.BusinessName.Placeholder': '会員社入力',
    'Reservation.DepartmentName': '貸出会員部署名',
    'Reservation.DepartmentName.Label': '部署名',
    'Reservation.DepartmentName.Placeholder': '部署名入力',
    'Reservation.RentalName.Placeholder': '会員名入力',
    'Reservation.RentalName': '貸出会員',
    'Reservation.RentalName.Search': '貸出会員検索',
    'Reservation.ReservationKey': '貸出番号',
    'Reservation.ReservationKey.Placeholder': '貸出番号入力',
    'Reservation.SubscribeID': 'サブスクリプション番号',
    'Reservation.SubscribeID.Placeholder': 'サブスクリプション番号入力',
    'Reservation.SubscribeID.Search': 'サブスクリプション番号検索',
    'Reservation.Subscribe.DiscountPay': 'サブスクリプション割引料金',
    'Reservation.Subscribe.SubscribeRound': 'NO',
    'Reservation.Membership.DiscountPay.Short': 'メンバーシップ割引',
    'Reservation.Membership.DiscountPay': 'メンバーシップ割引料金',
    'Reservation.Membership.DiscountRate': 'メンバーシップ割引率',
    'Reservation.Extend.DiscountPay': '貸出料金割引',
    'Reservation.LicensePlateNumber': '車両番号',
    'Reservation.LicensePlateNumber.Placeholder': '車両番号入力',
    'Reservation.ModelName.Placeholder': '車種入力',
    'Reservation.ModelName': '車種',
    'Reservation.VehiclePickupStationName.Placeholder': 'ステーション名称入力',
    'Reservation.ReservationType.Placeholder': 'プロフィールタイプ全体',
    'Reservation.ReservationType.Label': '貸出タイプ',
    'Reservation.ReservationType': 'タイプ',
    'Reservation.ReservationType.Private': '個人',
    'Reservation.ReservationType.Business': 'ビジネス',
    'Reservation.ReservationStatus': '状態',
    'Reservation.VehicleAvailableTime': '貸出可能時間',
    'Reservation.PickUpToReturnTime':
        '受け渡し {pickupHour}:{pickupMin}, 返却 {returnHour}:{returnMin}',
    'Reservation.ReservationStatus.Label': '貸出状態',
    'Reservation.ReservationStatus.Placeholder': '状態全体',
    'Reservation.ReservationStatus.Request': '予約要請',
    'Reservation.ReservationStatus.Confirmed': '予約完了',
    'Reservation.ReservationStatus.Inuse': '利用中',
    'Reservation.ReservationStatus.Canceled': '予約キャンセル',
    'Reservation.ReservationStatus.Returned': '返却完了',
    'Reservation.ReservationStatus.NotReturned': '返却遅延',
    'Reservation.ChargeName': '料金制',
    'Reservation.ChargeGroup': '料金グループ',
    'Reservation.ChargeGroup.Placeholder': '料金グループ全体',
    'Reservation.ChargeGroup.TimeBase': 'Time-based',
    'Reservation.ChargeGroup.Single': '特価 (1回券)',
    'Reservation.ChargeGroup.Subscribe': '特価 (サブスクリプション券)',
    'Reservation.ChargeGroup.OneDayPlus': '特価 (1Day+)',
    'Reservation.ChargeType': '個人商品',
    'Reservation.ChargeType.Placeholder': '個人商品全体',
    'Reservation.LicenseType': 'ビジネス商品',
    'Reservation.LicenseType.Placeholder': 'ビジネス商品全体',
    'Reservation.ProviderName.Placeholder': '供給社入力',
    'Reservation.ProviderName': '供給社',
    'Reservation.ReservationDate': '予約日',
    'Reservation.Search.ReservationDate': '貸出日',
    'Reservation.ReservationTime': '決済日時',
    'Reservation.RentalDate': '貸出期間',
    'Reservation.RegistDate': '貸出登録日',
    'Reservation.StartDate': '貸出開始日',
    'Reservation.EndDate': '貸出終了日',
    'Reservation.EndDate.WibleContract.Alert':
        '貸出終了日がステーション契約期間以後です。貸出期間を変更してください。',
    'Reservation.Date.StartToEnd': '{startDate} ~ {endDate}',
    'Reservation.Date.Duration': '({days}日{hours}時間{minutes}分)',
    'Reservation.RentalDate.Duration':
        '総貸出期間: {days}日{hours}時間{minutes}分',
    'Reservation.Duration': '({days}日{hours}時間{minutes}分)',
    'Reservation.ReservationTime.Label': '貸出時間',
    'Reservation.RemainEngagementTime.Label': '今月約定の残り時間',
    'Reservation.RemainEngagementTime':
        '{hours}時間{minutes}分 / {totalHours}時間',
    'Reservation.MaxExtend': '{hours}時間{minutes}分',
    'Reservation.SubscribeDate': 'サブスクリプション期間選択',
    'Reservation.SubscribeDate.Months.Placeholder': '月選択',
    'Reservation.SubscribeDate.Months.Label': '{value}回数',
    'Reservation.SubscribeDate.StartDate': 'サブスクリプション開始日時',
    'Reservation.SubscribeDate.EndDate': 'サブスクリプション終了日時',
    'Reservation.SubscribeDate.~': ' ~ ',
    'Reservation.VehiclePickupStationName': 'ステーション',
    'Reservation.Passenger': '乗車者',
    'Reservation.Passenger.Alert':
        '既に選択した会員と同じ会員を選択できません。', // JP
    'Reservation.Passenger.Count': '{passenger}人',
    'Reservation.LicenseType.Label': '商品名',
    'Reservation.LicenseType.Name': '商品',
    'Reservation.LicenseType.PlaceHolder': '商品選択',
    'Reservation.ReservationCancelTime': '予約キャンセル日時',
    'Reservation.UsageTime': '実際の使用期間',
    'Reservation.Penalty': 'ペナルティ発生',
    'Reservation.Destination': '目的地',
    'Reservation.ReservationBasicInfo': '貸出情報',
    'Reservation.WorkDetail': '業務内容',
    'Reservation.VehicleCheck.Pictures': '駐車写真',
    'Reservation.BeforeReservation.Pictures': '貸出前写真',
    'Reservation.ReturnLocation.Pictures': '返却位置写真',
    'Reservation.Vehicle.External': '車両外部点検',
    'Reservation.Vehicle.External.CheckList': '車両外部チェックリスト',
    'Reservation.Vehicle.Internal': '車両内部点検',
    'Reservation.Vehicle.NotAbnormal': '異常なしチェック',
    'Reservation.Vehicle.CheckList.Scratch': 'スクラッチ',
    'Reservation.Vehicle.CheckList.Broken': '割れ',
    'Reservation.Vehicle.CheckList.Crush': 'つぶれてゆがむ',
    'Reservation.Vehicle.CheckList.Separation': 'ひずみ',
    'Reservation.Vehicle.CheckList.Smoke': 'タバコ臭',
    'Reservation.Vehicle.CheckList.Trash': 'ゴミ',
    'Reservation.Vehicle.CheckList.Dust': '土、砂',
    'Reservation.Vehicle.CheckList.Animal': '動物の毛',
    'Reservation.Vehicle.CheckList.Food': '飲食物',
    'Reservation.Vehicle.CheckList.Stink': '悪臭',
    'Reservation.Vehicle.CheckList.Pollution': 'べたべたする、汚染',
    'Reservation.Vehicle.CheckList.Goods': '他人の物品',
    'Reservation.Vehicle.CheckList.Window': '窓の汚れ、シミ',
    'Reservation.Vehicle.CheckList': 'チェックリスト',
    'Reservation.Vehicle.Pictures': '点検写真',
    'Reservation.Vehicle.Pictures.NotExist': '登録された写真はございません。',
    'Reservation.Vehicle.VehicleStatus.Text': '車両状態記載',
    'Reservation.CustomerInfo': '貸出会員情報',
    'Reservation.Passenger.Driving.Yes': '同乗運転者',
    'Reservation.Passenger.Driving.No': '同乗者',
    'Reservation.Passenger.Info': '乗車者情報',
    'Reservation.CombinationPassenger.Info': '同乗運転者(すぐに退勤する)',
    'Reservation.Passenger.Create': '+ 乗車者の追加',
    'Reservation.CombinationPassenger.Create': '+ 同乗運転者の追加',
    'Reservation.Passenger.Edit': '同乗者変更',
    'Reservation.CombinationPassenger.Edit': '同乗運転者変更',
    'Reservation.Passenger.EditComplete': '同乗者変更完了',
    'Reservation.CombinationPassenger.EditComplete': '同乗運転者変更完了',
    'Reservation.Passenger.Driving': '運転有無',
    'Reservation.Memo': 'メモ',
    'Reservation.VehicleInfo': '車両情報',
    'Reservation.VehicleInfo.Return': '車両点検及び返却情報',
    'Reservation.VehicleInfo.Create': '貸出車両情報',
    'Reservation.VehicleType': 'グレード',
    'Reservation.FuelType': '車種',
    'Reservation.Vehicle.Insurance': '保険情報',
    'Reservation.Vehicle.Select': '貸出情報選択',
    'Reservation.Insurance': '保険情報',
    'Reservation.Insurance.Label': '保険',
    'Reservation.Insurance.Placeholder': '保険選択',
    'Reservation.Insurance.NotExist': '選択できる保険がございません。',
    'Reservation.ChargeInfo': '利用情報',
    'Reservation.ChargeInfo.Main': '貸出料金',
    'Reservation.ChargeInfo.Additional': '延長情報',
    'Reservation.ChargeInfo.Extend': '延長料金',
    'Reservation.ChargeInfo.RentalFee': '貸出料金',
    'Reservation.ChargeInfo.CouponDiscountPay': 'クーポン割引',
    'Reservation.ChargeInfo.CouponName': '使用クーポン名',
    'Reservation.ChargeInfo.InsuranceFee': 'NOC補償入会料金',
    'Reservation.ChargeInfo.InsuranceFee.Value': '{insuranceFee}円',
    'Reservation.ChargeInfo.OdoKilometer': '走行距離',
    'Reservation.ChargeInfo.OdoKilometer.Km': '{distance}km',
    'Reservation.ChargeInfo.OdoKilometer.Combination.Km':
        '{totalDistance}km (個人用: {personalDistance}km / 業務用: {businessDistance}km)',
    'Reservation.ChargeInfo.DrivingFee.SubTitle': '走行料金',
    'Reservation.ChargeInfo.DrivingFee.Button': '走行料金',
    'Reservation.ChargeInfo.DrivingFee': '走行料金',
    'Reservation.ChargeInfo.DrivingFee.Km': '走行料金 (/km)',
    'Reservation.ChargeInfo.DrivingFee.Combination':
        '{totalDrivingFee}円 (個人用: {personalDrivingFee}円 / 業務用: {businessDrivingFee}円)',
    'Reservation.ChargeInfo.TollFee.Subtitle': '通行料金',
    'Reservation.ChargeInfo.TollFee': 'ETC Card 決済料金',
    'Reservation.ChargeInfo.TollFee.Date': '発生日時',
    'Reservation.ChargeInfo.TollFee.Combination':
        '{totalTollFee}円 (個人用: {personalTollFee}円 / 業務用: {businessTollFee}円)',
    'Reservation.ChargeInfo.RentalDelayFee': '返却遅延料金',
    'Reservation.ChargeInfo.TotalCharge': '総利用額',
    'Reservation.ChargeInfo.Notice':
        '予約を延長して利用した場合、走行料金はプロフィール別最終利用商品の貸出内訳に合算して表記されます。. <br /> 同一の貸出番号及びプロフィールで発生した貸出内訳を確認してください。',
    'Reservation.ChargeCombination.Info': 'セット商品情報',
    'Reservation.ChargeCombination.ChargeMasterYn': '区分',
    'Reservation.ChargeCombination.ChargeMaster.Master': 'メイン商品',
    'Reservation.ChargeCombination.ChargeMaster.Combined': '追加商品',
    'Reservation.ChargeCombination.ChargeType': 'タイプ',
    'Reservation.ChargeCombination.ChargeInfo': '料金グループ',
    'Reservation.ChargeCombination.RentalDate': '貸出期間',
    'Reservation.ChargeCombination.RentalCharge': '貸出量',
    'Reservation.ChargeCombination.InsuranceCharge': 'NOC補償',
    'Reservation.ChargeCombination.CouponDiscount': 'クーポン割引',
    'Reservation.ChargeCombination.DiscountPay': '割引額',
    'Reservation.ChargeCombination.Addgoods': 'オプション',
    'Reservation.ChargeCombination.Total': '合計',
    'Reservation.ChargeCombination.ReturnStatus': '返却状態',
    'Reservation.PaymentInfo': '決済情報',
    'Reservation.PaymentInfo.Coupon': '割引クーポン適用',
    'Reservation.PaymentInfo.Coupon.PlaceHolder.NotExist': '割引クーポン選択',
    'Reservation.PaymentInfo.Coupon.PlaceHolder': '割引クーポン選択',
    'Reservation.PaymentInfo.DrivingFee': '走行料金及び通行料金支払い方法',
    'Reservation.Payment.Button': '決済料金照会',
    'Reservation.FinalCharge': '最終決済料金',
    'Reservation.ReservClaimMethod.Month':
        '契約時、協議した会社決済情報に合算して請求されます。',
    'Reservation.ReservMonthClaimMethod.ExactCalculation':
        '契約時協議した方法に従って会社に別途請求されます。',
    'Reservation.ChargeCard.Select': '決済カード',
    'Reservation.ChargeCard.Placeholder': '決済カード選択',
    'Reservation.ChargeCard.NotExist': '決済カード選択',
    'Reservation.Extend.Title': '貸出期間延長',
    'Reservation.Extend.Success.Title': '貸出期間延長完了案内',
    'Reservation.Extend.Confirm': '貸出期間を延長しますか?',
    'Reservation.Extend.Success': '貸出期間の延長が完了しました。',
    'Reservation.Extend.Success.ChangedDate':
        '変更した貸出期間: {startDate}~{endDate}',
    'Reservation.Extend.ReservationInfo': '貸出情報',
    'Reservation.Extend.LicenseType': '延長方法',
    'Reservation.Extend.LicenseType.PersonalRelation':
        '(会員の既存予約があるので該当の予約に接続します。)',
    'Reservation.Extend.PersonalRelation': '既存予約',
    'Reservation.Extend.LicenseType.ReturnTime': '返却延長',
    'Reservation.Extend.LicenseType.Relation': 'すぐに退勤する',
    'Reservation.Extend.Time': '延長時間',
    'Reservation.Extend.Coupon': 'クーポン情報',
    'Reservation.Extend.Time.Alert':
        '延長時間は延長可能時間以上に設定できません。',
    'Reservation.Extend.Type': '延長タイプ',
    'Reservation.Extend.PossibleTime': '延長可能時間',
    'Reservation.Extend.PossibleTime.NotExist':
        '延長可能な時間がございません。',
    'Reservation.Extend.NotPossible': '延長できません。',
    'Reservation.Extend.NotPossibleDate':
        '延長できない日付です。ほかの日付を選んでください。',
    'Reservation.Extend.PossibleDate': '延長可能日時',
    'Reservation.Extend.ReturnTime': '返却時間',
    'Reservation.Extend.PickupTime.Constraint':
        '受け渡し時間は返却時間以降でなければなりません。',
    'Reservation.Extend.AdditionalAmount': '決済金額の追加',
    'Reservation.Extend.Personal.AdditionalAmount': '個人決済金額の追加',
    'Reservation.Extend.PersonalRelation.AdditionalTime':
        'ビジネス貸出時間の追加',
    'Reservation.Extend.PersonalRelation.AdditionalAmount':
        'ビジネス決済金額の追加',
    'Reservation.Extend.Card': '決済カード',
    'Reservation.Extend.ReservationType': 'プロフィールタイプ',
    'Reservation.PayAndCreate': '決済及び貸出登録',
    'Reservation.Extend.Button': '貸出期間延長',
    'Reservation.PaymentHistory.Button': '決済内訳', // JP
    'Reservation.Extend.RelationPassenger': 'すぐに退勤、同乗運転者設定',
    'Reservation.Extend.RelationPassenger.Info':
        'すぐに退勤、同乗運転者がいる場合、設定してください。{br}貸出管理詳細ページで後から追加できます。',
    'Reservation.Inuse.Button': '利用',
    'Reservation.Inuse.Alert': '利用中の状態に変換しますか?',
    'Reservation.Inuse.Success': '利用中の状態に変換しました。',
    'Reservation.Inuse.Failure': '利用中の状態に変換できませんでした。',
    'Reservation.ForceReturn.Button': '強制返却',
    'Reservation.ForceReturn.Alert':
        '強制返却処理しますか?{br}強制返却処理が完了すると決済料金の追加の有無により後日使用者に未払いが発生します。',
    'Reservation.ForceReturn.Complete': '強制返却完了',
    'Reservation.ForceReturn.Failure': '強制返却に失敗しました。',
    'Reservation.ForceReturn.Success':
        '強制返却が完了しました。{br}車両の端末機から使用者が決済するべき料金が確認されると{br}未払い金として登録されます。',
    'Reservation.Cancel.Button': 'キャンセル処理',
    'Reservation.Cancel': '貸出キャンセル',
    'Reservation.Cancel.Subtitle': '予約キャンセル情報',
    'Reservation.Cancel.Amount': '決済キャンセル額',
    'Reservation.Cancel.Amount.Business': '決済キャンセル額(ビズ)',
    'Reservation.Cancel.Amount.Personal': '決済キャンセル額(個人)',
    'Reservation.Cancel.Commission': 'キャンセル料',
    'Reservation.Cancel.Commission.Business': 'キャンセル料(ビズ)',
    'Reservation.Cancel.Commission.Personal': 'キャンセル料(個人)',
    'Reservation.Cancel.Amount.Commission.Business': '決済キャンセル料(ビズ)',
    'Reservation.Cancel.Amount.Commission.Personal': '決済キャンセル料(個人)',
    'Reservation.Cancel.Complete': '貸出キャンセル完了案内',
    'Reservation.Cancel.Failure.Title': '貸出キャンセル不可案内',
    'Reservation.Cancel.Alert': '貸出キャンセルを行いますか?',
    'Reservation.Cancel.Success':
        'お支払いのキャンセルが正常に成功しました。{br}レンタルキャンセルが完了しました。',
    'Reservation.Cancel.Msg': '決済キャンセル額{value}円',
    'Reservation.Cancel.Failure':
        'レンタルキャンセルはできません。{br}既に貸出を開始した場合キャンセルできません。{br}レンタル管理の状態を確認してください。',
    'Reservation.Return.Button': '返却処理',
    'Reservation.Return.Alert': '返却処理しますか?',
    'Reservation.Return.Success.Title': '貸出返却完了案内',
    'Reservation.Return.Success': '貸出返却が完了しました。',
    'Reservation.Success.Payment':
        '決済が完了しました。{br}貸出返却が完了しました。',
    'Reservation.Success.Payment.Amount': '決済金額: {amount}円',
    'Reservation.Success.Payment.Amount.Business': '決済金額 (ビズ)',
    'Reservation.Success.Payment.Amount.Personal': '決済金額 (個人)',
    'Reservation.Success.Payment.Amount.Commission.Business':
        '決済キャンセル金額 (ビズ)',
    'Reservation.Success.Payment.Amount.Commission.Personal':
        '決済キャンセル金額 (個人)',
    'Reservation.Return.Impossible.Title': '貸出返却不可案内',
    'Reservation.Return.Impossible':
        '現在、車両は充電器に接続されていません。{br}車両を返却するために充電器に車両を接続してください。',
    'Reservation.Return.Failure.Title': '返却失敗',
    'Reservation.Return.Failure': '返却処理に失敗しました。',
    'Reservation.Return.Failure.Location.Title': '車両位置エラー',
    'Reservation.Return.Failure.Location':
        'ステーション半径内に車両が駐車されていません。駐車位置を確認してください。',
    'Reservation.Return.Failure.Vehicle': '車両位置照会不可',
    'Reservation.Return.Failure.Location.Wible':
        'ステーション位置: {location}{br}車両位置: {vehicle}',
    'Reservation.Return.Failure.VehicleStatus.Title': '車両状態エラー',
    'Reservation.Return.Failure.VehicleStatus.ErrorCode':
        'エラーコード: {errorCode}',
    'Reservation.Return.Failure.VehicleStatus.501':
        '車両始動がかかっています。{br}始動を止めてください。',
    'Reservation.Return.Failure.VehicleStatus.503':
        '車両ドアが閉まっていません。{br}車両ドアを確認してください。',
    'Reservation.Return.Failure.VehicleStatus.504':
        'トランクが開いています。{br}トランクドアを確認してください。.',
    'Reservation.Return.Failure.VehicleStatus.505':
        'フードが開いています。{br}フードを確認してください。.',
    'Reservation.Return.Failure.VehicleStatus.506':
        '返却する予約情報がございません。{br}システム管理者にお問い合わせください。',
    'Reservation.Return.Failure.VehicleStatus.507':
        '返却する予約情報がございません。{br}システム管理者にお問い合わせください。',
    'Reservation.Failure.NotPayed.Title': '未払い金案内',
    'Reservation.Failure.NotPayed':
        '貸出会員が決済してない未払い金があります。',
    'Reservation.NotPayed.Push': '決済要請 Push 送信',
    'Reservation.Return.Failure.Payment.Title': '決済失敗',
    'Reservation.Failure.Payment':
        'カード決済に失敗しました。{br}エラーコードを確認してください。',
    'Reservation.Success.Title': '貸出登録完了案内',
    'Reservation.Success':
        'お支払いが正常に成功しました。{br}レンタル登録が完了しました。',
    'Reservation.Failure.Title': '貸出不可案内',
    'Reservation.Failure.Extend.Title': '貸出不可案内',
    'Reservation.Failure.Duplication':
        '選択した車両と貸出期間に既に貸出を申請した使用者がいます。{br}',
    'Reservation.Failure.ContractDate':
        '会員社契約商品の約定時間が足りないため貸出できません。{br}残り時間: {hours}時間{minutes}分{br}{br}貸出時間を変更して再度行ってください。{br}*残り時間が30分未満の場合新規貸出はできません。',
    'Reservation.Failure,ContractDate.Lite': '約定の残り時間が足りません。',
    'Reservation.Failure.ContractDate.Extend':
        '会員社契約商品の残り時間が足りないため返却延長できません。{br}残り時間: {hours}時間{minutes}分{br}{br}返却延長時間を変更して再度行ってください。{br}*残り時間が10分未満の場合、返却延長はできません。',
    'Reservation.Passenger.Failure.Title': '同乗運転者案内',
    'Reservation.Passenger.Failure.License':
        '入力された同乗運転者({passenger})運転免許の有効期限が切れました。',
    'Reservation.Passenger.Failure.Penalty':
        '入力した同乗運転者({passenger})ペナルティスコアが20点を超えました。',
    'Reservation.Contract.View': '契約書照会',
    'Reservation.Claim': '未払い金',
    'Reservation.Claim.Msg': '決済してない未払い金があります。',
    'Reservation.Send.PaymentTalk': '決済要請e-mail送信',
    'Reservation.Send.PaymentTalk.Confirm': '決済要請e-mailを送信しますか?',
    'Reservation.Send.PaymentTalk.Success': '決済要請e-mailを送信しました。',
    'Reservation.ClaimMethod.CaseBy': '件別請求 (車両返却する毎に請求)',
    'Reservation.ClaimMethod.Monthly': '月別請求 (4週単位で合計料金請求)',
    'Reservation.ServiceDelay': 'サービス遅延',
    'Reservation.ServiceDelayFee.PerMinute': '分毎遅延料金',
    'Reservation.ServiceDelay.DelayMinute': '遅延料金',
    'Reservation.Coupon.PriceZero.Alert':
        '貸出額が0円の場合クーポンを使用できません。',

    'Reservation.Info': '予約情報',
    'Reservation.Change.Vehicle': '車両変更',
    'Reservation.Change.Vehicle.Button': '利用可能な車両検索',
    'Reservation.Change.Vehicle.Title': '予約車両変更',
    'Reservation.Change.Vehicle.Confirm': '車を変更しますか？',
    'Reservation.Change.Vehicle.Success': '車を変更しました。',
    'Reservation.Change.Vehicle.Failure': '車両を変更することはできません。',
    'Reservation.Change.Vehicle.Reason.Placeholder': 'MOCEAN担当者の場合は、変更理由を入力してください。',
    'Reservation.Change.Requester': 'リクエスト者を選択してください。',
    'Reservation.Change.Requester.Renter': '顧客',
    'Reservation.Change.Requester.MOCEAN': 'MOCEAN担当者',
    'Reservation.Change.Reason': '理由',
    'Reservation.Change.Cancel.Renter.Placeholder': 'キャンセル理由を入力してください',
    'Reservation.Change.Cancel.MOCEAN.Placeholder': 'お客様に通知するキャンセル理由を入力してください',

    // 貸出/決済管理 > サブスクリプション内訳
    'Subscribe.Stats': '今月{thisMonth}件 | 来月{nextMonth}件 | 総{total}件',
    'Subscribe.SubscribeId': 'サブスクリプション番号',
    'Subscribe.ChargeGroup': '商品',
    'Subscribe.SubscribeMonth': 'サブスクリプションの月数',
    'Subscribe.ReservationTime': '予約日時',
    'Subscribe.ReservationStartTime': 'サブスクリプション開始日時',
    'Subscribe.ReservationEndTime': 'サブスクリプション終了日時',
    'Subscribe.StationName': 'ステーション',
    //'Vehicle.LicensePlateNumber': '車両番号',
    'Subscribe.ModelName': '車種',
    'Subscribe.ProviderName': '供給社',
    'Subscribe.RenterName': '貸出会員',
    'Subscribe.Status': '状態',
    'Subscribe.RenterId': '会員ID',
    'Subscribe.Card': '決済カード',
    'Subscribe.Payment': '請求日',
    'Subscribe.PaymentType': '利用料金請求周期',
    'Subscribe.VehicleType': 'グレード',
    'Subscribe.SubscribeId.Placeholder': 'サブスクリプション番号入力',
    //'Common.User.Name': '会員名',
    'Subscribe.User.Name.Placeholder': '会員名入力',
    'Subscribe.StationName.Placeholder': 'ステーション名称入力',
    'Subscribe.Vehicle.Placeholder': '車両番号入力',
    'Subscribe.ProviderName.Placeholder': '供給社全体',
    'Subscribe.ChargeGroup.Placeholder': '商品全体',
    'Subscribe.Period': 'サブスクリプション期間',
    'Subscribe.SubTitle.Detail': 'サブスクリプション内訳詳細',
    'Subscribe.SubTitle.RentalInfo': '貸出情報',
    'Subscribe.SubTitle.Info': 'サブスクリプション情報',
    'Subscribe.SubTitle.PayInfo': 'サブスクリプション決済情報',
    'Subscribe.SubTitle.VehicleInfo': 'サブスクリプション車両情報',
    'Subscribe.PayDaySelect': '請求日照会',
    'Subscribe.PayCount': '請求日照会',
    'Subscribe.PaymentTypeDesc': '{count}件{cycle}別決済',
    'Subscribe.PayDay.Info': '請求案内',
    'Subscribe.PayDay.comment': '回別、請求日は次の通りです。',
    'Subscribe.Rental.Detail': '貸出内訳詳細',
    'Subscribe.Rental.Detail.Info': '詳細照会',
    'Subscribe.Subscribe': 'サブスクリプション内訳',
    'Subscribe.Status.Wait': '待機',
    'Subscribe.Status.Complete': '完了',
    'Subscribe.Status.Fail': '失敗',

    'Subscribe.ChargeGroupCd': 'サブスクリプション料金グループ',
    'Subscribe.ChargeGroupCd.WEEKDAY': '出退勤サブスクリプション',
    'Subscribe.ChargeGroupCd.WEEKEND': '週末サブスクリプション',
    'Subscribe.ChargeGroupCd.SUPER': 'プレミアムサブスクリプション',

    'Subscribe.RentalFee': 'サブスクリプション料金',
    'Subscribe.CouponDiscountPay': 'クーポン割引',
    'Subscribe.CouponName': '使用クーポン名',
    'Subscribe.Fee': '総決済金額',

    'Subscribe.ClaimMethod.CaseBy': '件別決済',
    'Subscribe.ClaimMethod.Monthly': '月別決済',
    'Subscribe.Cancel.Title': 'サブスクリプションキャンセル案内',
    'Subscribe.Cancel.Check':
        'キャンセル期間: {cancelStartTime} ~ {cancelEndTime}{br}キャンセル料: {cancelCommission}円{br}割引返金返還金: {discountRefundPay}円',

    'Subscribe.Cancel.Check2':
        'キャンセル期間: {cancelStartTime} ~ {cancelEndTime}',
    'Subscribe.Cancel.CancelCommission': 'キャンセル料: ',
    'Subscribe.Cancel.DiscountRefundPay': '割引返還金: ',

    'Subscribe.Insurance.Name': '保険名',
    'Subscribe.Insurance.SelfCost': '自己負担金',
    'Subscribe.SubscribeMonth.Head': 'サブスク決済回数',
    'Subscribe.SubscribeMonth.Count':
        '{round}/{totalMonth}回数 ({subscribeStartTime} ~ {subscribeEndTime})',
    'Subscribe.ChargeDiscountPay': '定期サブスクリプション割引額',
    'Subscribe.InsuranceCost': '保険料',
    'Subscribe.TotalCost': '総決済金額',
    'Subscribe.CancelMsg': '(キャンセル)',

    'Subscribe.Cancel.Date': '決済キャンセル',
    'Subscribe.Cancel.DateTime': '決済キャンセル日時',
    'Subscribe.Cancel.Charge': 'キャンセル料',
    'Subscribe.Discount.Return.Charge': '割引返還金',
    'Subscribe.Cancel.Fee': 'サブスクリプションキャンセル料金',

    // 貸出/決済管理 > 決済内訳
    'ReservationPayment.Stats': '総 {total}円',
    'ReservationPayment.BusinessName': '会員社',
    'ReservationPayment.BusinessName.Placeholder': '会員社入力',
    'ReservationPayment.DepartmentName': '部署名',
    'ReservationPayment.DepartmentName.Placeholder': '部署名入力',
    'ReservationPayment.UserName': '貸出会員',
    'ReservationPayment.UserName.Placeholder': '会員名入力',
    'ReservationPayment.ReservationKey': '貸出番号',
    'ReservationPayment.ReservationKey.Placeholder': '貸出番号入力',
    'ReservationPayment.SubscribeID': 'サブスクリプション番号',
    'ReservationPayment.SubscribeID.Placeholder': 'サブスクリプション番号入力',
    'ReservationPayment.PaymentID': '決済番号',
    'ReservationPayment.PaymentID.Placeholder': '決済番号入力',
    'ReservationPayment.LicensePlateNumber': '車両番号',
    'ReservationPayment.LicensePlateNumber.Placeholder': '車両番号入力',
    'ReservationPayment.LocationName': 'ステーション',
    'ReservationPayment.LocationName.Placeholder': 'ステーション入力',
    'ReservationPayment.ReservationType': 'タイプ',
    'ReservationPayment.ReservationType.All': 'プロフィールタイプ全体',
    'ReservationPayment.ReservationType.BUSINESS': '業務用',
    'ReservationPayment.ReservationType.PERSONAL': '個人用',
    'ReservationPayment.SalesClassCd': '売上区分',
    'ReservationPayment.SalesClassCd.All': '売上区分全体',
    'ReservationPayment.PaymentClassCd': '決済区分',
    'ReservationPayment.PaymentClassCd.All': '決済区分全体',
    'ReservationPayment.ChargeGroup': '料金グループ',
    'ReservationPayment.ChargeGroup.All': '料金グループ全体',
    'ReservationPayment.ChargeType': '個人商品',
    'ReservationPayment.ChargeType.All': '個人商品全体',
    'ReservationPayment.LicenseTypeCd': 'ビジネス商品',
    'ReservationPayment.LicenseTypeCd.All': 'ビジネス商品全体',
    'ReservationPayment.AuthDate': '決済日時',
    'ReservationPayment.AuthDate.Label': '決済日',
    'ReservationPayment.ModelName': '車種',
    'ReservationPayment.UserDepartmentName': '貸出会員部署名',
    'ReservationPayment.RealPay': '決済金額',
    'ReservationPayment.PaymentInfo': '決済情報',
    'ReservationPayment.PaymentInfo.Text1':
        '※ 正確な決済内訳は各カード社ホームページ/アプリ及び領収書を参考にしてください。',
    'ReservationPayment.PaymentInfo.Text2':
        '※ ETC Card 決済料金は使用してから約3営業日後、確認できます。',
    'ReservationPayment.PaymentMethod': '決済手段',
    'ReservationPayment.ApproveNumber': '承認番号',
    'ReservationPayment.ApproveStatus': '承認状態',
    'ReservationPayment.ApproveStatus.All': '承認状態全体', // JP
    'ReservationPayment.Output': '決済内訳出力',
    'ReservationPayment.PaymentPartCancel': 'キャンセル後、再決済',
    'ReservationPayment.PaymentPartCancel.Title': 'キャンセル後、再決済案内',
    'ReservationPayment.PaymentPartCancel.Text': `<span className="textColorRed">キャンセル後、再決済</span>を実行しますか?`,
    'ReservationPayment.PaymentPartCancel.Text2':
        '部分キャンセルしたい場合、決済全体をキャンセルしてから再決済を実行してください。',
    'ReservationPayment.PaymentPartCancel.Confirm':
        'キャンセル後、再決済しますか?',
    'ReservationPayment.PaymentPartCancel.Success':
        'キャンセル後、再決済しました。',
    'ReservationPayment.PaymentPartCancel.Failure':
        'キャンセル後、再決済できませんでした。',
    'ReservationPayment.RePaymentAmount': '再決済金額',
    'ReservationPayment.RePaymentAmount.Alert':
        '再決済金額を確認してください。',
    'ReservationPayment.PaymentCancel': '決済キャンセル',
    'ReservationPayment.PaymentCancel.Title': '決済キャンセル案内',
    'ReservationPayment.PaymentCancel.Text': `<span className="textColorRed">決済キャンセル</span>を実行しますか?`,
    'ReservationPayment.PaymentCancel.Confirm': '決済キャンセルしますか？',
    'ReservationPayment.PaymentCancel.Success': '決済キャンセルしました。',
    'ReservationPayment.PaymentCancel.Failure': '決済キャンセルできません。',
    'ReservationPayment.PaymentHistory': '決済履歴',
    'ReservationPayment.UsageInformation': '利用情報',
    'ReservationPayment.UserMobilePhone': '連絡先',
    'ReservationPayment.ReservationTime.Period': '実際の使用期間',
    'ReservationPayment.ResultCode.0': '-',
    'ReservationPayment.ResultCode.0000': '成功',
    'ReservationPayment.ResultCode.2001': 'キャンセル',
    'ReservationPayment.ResultCode.9999': '失敗',
    'ReservationPayment.ResultCode.930302': '失敗',
    'ReservationPayment.ResultCode2.0': '-',
    'ReservationPayment.ResultCode2.0000': '決済完了',
    'ReservationPayment.ResultCode2.2001': 'キャンセル完了',
    'ReservationPayment.ResultCode2.9999': '決済失敗',
    'ReservationPayment.ResultCode2.930302': 'キャンセル失敗',

    // 貸出/決済管理 > 決済用クレジットカード
    'Card.Business.Placeholder': '会員社入力',
    'Card.Department.Placeholder': '部署名入力',
    'Card.Business.Owner.Placeholder': '名義人/法人名入力',
    'Card.CardRegister.Placeholder': 'クレジットカード登録者入力',
    'Card.CardCompany.AllSelect': 'クレジットカード社全体',
    'Card.CardCorporation.AllSelect': 'クレジットカード区分全体',
    'Card.CardStatus.AllSelect': '状態全体',
    'Card.CardNumber': 'クレジットカード番号',
    'Card.CardNumber.Masked': '****-****-****-{cardNumber}',
    'Card.CardNumber.Placeholder': 'クレジットカード番号 (後4桁) 入力',
    'Card.Change.CardStatus': '変更する状態を選択してください。',
    'Card.Change.CardStatus.NotUse': '未使用処理',
    'Card.Change.CardStatus.Use': '使用中処理',
    'Card.BusinessCard.Personal': '法人 (個人名義)',
    'Card.BusinessCard.Business': '法人 (法人名義)',
    'Card.Information.Input': 'クレジットカード情報入力',
    'Card.Category': 'クレジットカード区分',
    'Card.Register': '登録者',
    'Card.Business': '会員社',
    'Card.Business.Select': '会員社選択',
    'Card.Department': '部署名',
    'Card.Department.Select': '部署選択',
    'Card.OwnerOrBusiness': '名義人/法人名',
    'Card.Owner': 'クレジットカード名義人',
    'Card.Owner.Placeholder': 'クレジットカード名義人入力',
    'Card.SocialNumber': '住民登録番号',
    'Card.SocialNumber.Placeholder': '前6桁入力',
    'Card.CardCompany': 'クレジットカード社',
    'Card.CardCompany.Placeholder': 'クレジットカード社選択',
    'Card.Password': 'クレジットカードのパスワード',
    'Card.Password.Placeholder': '前2桁入力',
    'Card.Validity': '有効期間',
    'Card.Validity.Mid': '/',
    'Card.Validity.Month.Placeholder': '月入力',
    'Card.Validity.Year.Placeholder': '年入力',
    'Card.NickName': 'クレジットカードニックネーム',
    'Card.NickName.Placeholder': 'クレジットカードニックネーム入力',
    'Card.Belonging': 'クレジットカード所属',
    'Card.Belonging.Placeholder': 'クレジットカード所属選択',
    'Card.RegistrationDate': 'クレジットカード登録日',
    'Card.Representation.Label': 'メインクレジットカードの有無',
    'Card.Representation': 'メインクレジットカード設定',
    'Card.Representation.Yn': 'メインクレジットカード有無',
    'Card.Representation.Yes': 'メインクレジットカード',
    'Card.Representation.Set': 'メインクレジットカードを設定する。',
    'Card.Representation.Warning1':
        '※ 既に設定されたメインクレジットカードがある場合、本クレジットカードがメインクレジットカードに交換されます。',
    'Card.Representation.Warning2':
        '※ 会社のメインクレジットカードと部署のメインクレジットカード情報を全て所有している会員の場合、部署メインクレジットカードが優先設定されます。',
    'Card.Terms.Agree': '[決済サービス利用約款]に同意します。',
    'Card.Terms.Detail': '約款詳細',
    'Card.Status.Label': '状態',
    'Card.Status': 'クレジットカード状態',
    'Card.Status.Change': 'クレジットカード状態の変更',
    'Card.LastUpdateDate': '最終更新日',
    'Card.NoData': '-',
    'Card.Use.Yes': '使用中',
    'Card.Use.No': '未使用',
    'Card.Business.Card.Type': '会員社クレジットカード所属',
    'Card.Buseinss.Card.Type.Buseinss.Label': '会社共通クレジットカード',
    'Card.Buseinss.Card.Type.Department.Label': '部署専用クレジットカード',
    'Card.Buseinss.Card.Type.Buseinss':
        '会社共通クレジットカード(登録時、会社の役職員全て利用可能)',
    'Card.Buseinss.Card.Type.Department':
        '部署専用クレジットカード(登録時、部署所属役職員のみ利用可能)',
    'Card.Company': 'クレジットカード社',
    // 'Card.Company.BC': 'BC',
    // 'Card.Company.KB': 'KB国民',
    // 'Card.Company.Hana': 'ハナ(外換)',
    // 'Card.Company.Samsung': 'サムスン',
    // 'Card.Company.ShinHan': '新韓',
    // 'Card.Company.HD': '現代',
    // 'Card.Company.LT': 'ロッテ',
    // 'Card.Company.CT': 'シティ',
    // 'Card.Company.NH': 'NH農協',
    // 'Card.Company.SuHyup': '水協',
    // 'Card.Company.ShinHyup': '信協',
    // 'Card.Company.WooRi': 'ウリ',
    // 'Card.Company.GwangJu': '光州',
    // 'Card.Company.Jeonbuk': '全北',
    // 'Card.Company.Jeju': '済州',
    // 'Card.Company.SanEun': '産銀キャピタル',
    // 'Card.Company.Visa': '海外Visa',
    // 'Card.Company.Master': '海外Master',
    // 'Card.Company.Dynasty': '海外ダイナスティ',
    // 'Card.Company.AMX': '海外AMX',
    // 'Card.Company.JCB': '海外JCB',
    // 'Card.Company.PostOffice': '郵便局',
    // 'Card.Company.FSB': '貯蓄銀行',
    // 'Card.Company.UnionPay': '中国銀連',
    // 'Card.Company.kfcc': 'セマウル金庫',
    // 'Card.Company.KDB': 'KDB産業',
    'Card.BusinessRegNum.Message':
        '会員社/契約メニューから登録事業所番号を登録できます。',

    // 貸出/決済管理 > ETC Card
    'Hipass.Management.HiPassCard': 'ETC Card 管理',
    'Hipass.CardRegistrationDate': 'カード登録日',
    'Hipass.CardCompany.Placeholder': 'カード社選択',
    'Hipass.CardCorporation.AllSelect': 'カード区分全体',
    'Hipass.ModelGrade.AllSelect': 'グレード全体',
    'Hipass.CardStatus.AllSelect': '状態全体',
    'Hipass.CardNumber': 'カード番号',
    'Hipass.LicensePlateNumber.Placeholder': '車両番号入力',
    'Hipass.ModelName.Placeholder': '車種入力',
    'Hipass.LicensePlateNumber': '車両番号',
    'Hipass.ModelName': '車種',
    'Hipass.VehicleType': 'グレード',
    'Hipass.CardCompany': 'カード社',
    'Hipass.Validity': '有効期間',
    'Hipass.Status': '状態',
    'Hipass.Create.HiPassCard': 'ETC Card 登録',
    'Hipass.Update.HiPassCard': 'ETC Card 更新',
    'Hipass.Validity.Month.Placeholder': '月入力',
    'Hipass.Validity.Year.Placeholder': '年入力',
    'Hipass.Information.Input': 'ETC Card 情報入力',
    'Hipass.ModelGrade.Large': '大型',
    'Hipass.ModelGrade.Small': '小型',
    'Hipass.ModelGrade.Msg.PossibleRegist': '登録できます。',
    'Hipass.ModelGrade.Msg.AlreadyRegist': '登録済カード番号です。',
    'Hipass.ModelGrade.Msg.DupCheck': 'カード番号を重複チェックしてください。',
    'Hipass.ModelGrade.Msg.InputCardNum': 'カード番号を入力してください。',

    // 貸出/決済管理 > 充電提携カード
    'AssociateCard.CardRegistrationDate': 'カード登録日',
    'AssociateCard.CardCompany.Placeholder': 'カード社選択',
    'AssociateCard.CardCorporation.AllSelect': 'カード区分全体',
    'AssociateCard.CardStatus.AllSelect': '状態全体',
    'AssociateCard.CardType': 'カード種類',
    'AssociateCard.CardNumber': 'カード番号',
    'AssociateCard.LicensePlateNumber.Placeholder': '車両番号入力',
    'AssociateCard.ModelName.Placeholder': '車種入力',
    'AssociateCard.LicensePlateNumber': '車両番号',
    'AssociateCard.ModelName': '車種',
    'AssociateCard.VehicleType': 'グレード',
    'AssociateCard.VehicleType.AllSelect': 'グレード全体',
    'AssociateCard.CardCompany': 'カード社',
    'AssociateCard.Validity': '有効期間',
    'AssociateCard.Status': '状態',
    'AssociateCard.Create.ChargeCard': '充電/充填カード登録',
    'AssociateCard.Update.ChargeCard': '充電/充填カード更新',
    'AssociateCard.Validity.Month.Placeholder': '月入力',
    'AssociateCard.Validity.Year.Placeholder': '年入力',
    'AssociateCard.Information.Input': '充電/充填カード情報入力',
    'AssociateCard.Hipass': 'ETC Card',
    'AssociateCard.Oil': '充電',
    'AssociateCard.Memo': 'メモ',

    // 会員社管理 > 会員社/契約
    'Business.Select': '会員社選択',
    'Business.Placeholder': '会員社入力',
    'Business.Name.Placeholder': '会員社名入力',
    'Business.RegNum.Placeholder': '登録事業所番号入力',
    'Business.LicenseType.Placeholder': '入会商品全体',
    'Business.LicenseType.Insert.Placeholder': '入会商品入力',
    'Business.Contract.Yn.Placeholder': '契約登録有無全体',
    'Business.ManagerMobileNumber.Placeholder': '電話番号入力',
    'Business.Condition.Placeholder': '業態/業種入力',
    'Business.Address.Placeholder': '住所入力',
    'Business.Address.Detail.Placeholder': '詳しい住所を入力', // JP
    'Business.BankName.Placeholder': '銀行選択',
    'Business.AccountNumber.Placeholder': '口座番号入力',
    'Business.AccountHolder.Placeholder': '名義人の入力',
    'Business.MainProfile.Placeholder':
        '代表プロフィール名入力(ハングル2文字/英文字3文字)',
    'Business.Remark1.Placeholder':
        '※ 代表イメージがない場合、代表プロフィール名が写真と代替表示されます。',
    'Business.Remark2.Placeholder':
        '※ 代表プロフィール名を入力時、ハングル/英文字混用はできません。',
    'Business.Remark3.Placeholder':
        '※ スーパー管理者は最大5人まで登録できます。',
    // 'Business.Remark3.Placeholder': '※ スーパー管理者は最大5人まで登録できます。 ステーション管理者が最終承認するとスーパー管理者として登録できます。',
    'Business.Name': '会員社名',
    'Business.RegNum': '登録事業所番号',
    'Business.licenseType': '入会商品',
    'Business.Contract.Period': '契約期間',
    'Business.Contract.StartDate': '契約開始日',
    'Business.Contract.EndDate': '契約満了日',
    'Business.Contract.Yn': '契約登録有無',
    'Business.WibleZoneCnt': 'ステーション',
    'Business.Registration': '登録事業所証明書',
    'Business.ManagerMobileNumber': '電話番号',
    'Business.Condition': '業態/業種',
    'Business.Address': '住所',
    'Business.Address.Detail': '詳しい住所', // JP
    'Business.BankName': '銀行',
    'Business.AccountNumber': '口座番号',
    'Business.AccountHolder': '名義人',
    'Business.BankBookCopy': '通帳コピー',
    'Business.MainImage': '代表イメージ',
    'Business.MainProfile': '代表プロフィール',
    'Business.Email': '電子メールアドレス',
    'Business.Email1': '電子メールアドレス1',
    'Business.Email2': '電子メールアドレス2',
    'Business.Email3': '電子メールアドレス3',
    'Business.Email4': '電子メールアドレス4',
    'Business.Email5': '電子メールアドレス5',
    'Business.Contract.WibleZone.Placeholder': '契約ステーション数表示',
    'Business.Contract.MainWibleZone.Placeholder': '代表ステーション選択',
    'Business.MemberShip.Charge.Placeholder': '料金入力',
    'Business.MemberShip.DiscountRate.Placeholder': '割引率入力',
    'Business.MemberShip.Time.Placeholder': '時間入力',
    'Business.AutoClaimDate.Placeholder': '日付入力',
    'Business.BasicCard.Placeholder': 'カード社名/カード番号',
    'Business.Product.Guide.Placeholder': '商品案内メッセージ入力',
    'Business.Contract.Product': '契約商品',
    'Business.Contract.WibleZone': '契約ステーション',
    'Business.Contract.MainWibleZone': '代表ステーション',
    'Business.Contract.PaperReg': '契約書登録',
    'Business.MemberShip.Charge': 'メンバーシップ料金',
    'Business.MemberShip.DiscountRate': 'メンバーシップ割引率',
    'Business.MemberShip.Time': 'メンバーシップ約定時間',
    'Business.Reserv.Charge': '貸出料金',
    'Business.Reserv.DiscountRate': '% 割引',
    'Business.Reserv.MonthCycle': '月別請求 :',
    'Business.Charge.Month': '月別精算 :',
    'Business.Charge.Card': 'クレジットカード自動請求',
    'Business.Charge.Case': '件別精算クレジットカード)',
    'Business.Charge.Separate': '別途精算',
    'Business.Charge.Monthly': '毎月',
    'Business.Charge.Day': '日',
    'Business.Charge.BasicCard': '基本クレジットカード',
    'Business.AutoClaimDate': '自動請求日',
    'Business.Charge.SelectCard': 'クレジットカード選択',
    'Business.Product.Guide': '商品案内メッセージ',
    'Business.SelectedWibleZone': '選択したステーション',
    'Business.SearchWibleZone': 'ステーション検索',
    'Business.WibleZone.Name': 'ステーション名',
    'Business.WibleZone.Addr': 'ステーション住所',
    'Business.WibleZone.WibleZoneCnt': '車両台数',
    'Business.Email.Placeholder': '電子メールアドレス入力',
    'Business.WibleZone.Contract.WibleZoneCnt': '契約車両台数',
    'Business.Contract.WibleZone.Cnt': 'ステーション 数',
    'Business.ContractYn': '契約登録有無',
    'Business.ContractYn.Y': '登録',
    'Business.ContractYn.N': '未登録',
    'Business.Contract.PaymentInfo': '決済情報',
    'Business.EveryCheck': '全体選択',
    'Business.Penalty': 'ペナルティ',

    // 会員社管理 > 企業相談履歴
    'CustomerService.Business.Placeholder': '企業名入力',
    'CustomerService.Resister.Placeholder': '担当者名入力',
    'CustomerService.User.Placeholder': '相談者名入力',
    'CustomerService.Status.AllSelect': '処理結果全体',
    'CustomerService.LisenceType.AllSelect': '商品タイプ全体',
    'CustomerService.SubTitle': '申請内訳',
    'CustomerService.Business': '企業名',
    'CustomerService.Resister': '担当者名',
    'CustomerService.LisenceType': '商品タイプ',
    'CustomerService.Question': '質問等',
    'CustomerService.Response': '相談内訳',
    'CustomerService.Telnum': '部署名',
    'CustomerService.Email': '部署名',
    'CustomerService.RequestDate': '申請日時',
    'CustomerService.Response.Placeholder': `    ∙ 業種
    ∙ 事業所住所
    ∙ 現在業務用車両運営台数
    ∙ 希望契約車両台数
    ∙ その他相談内訳`,
    'CustomerUser.Coupon.DiscountAmount': '割引額',

    // 会員/部署管理 > 会員
    'CustomerUser.Stats':
        '昨日: (正) {yesterdayRegularCnt}人 (準) {yesterdayAssociateCnt}人 | 今月: (正) {monthRegularCnt}人 (準) {monthAssociateCnt}人 | 総: (正) {totalRegularCnt}人 (準) {totalAssociateCnt}人',
    'CustomerUser.Title': '会員照会',
    'CustomerUser.UserID': '会員 ID',
    'CustomerUser.UserID.Placeholder': '会員 ID 入力',
    'CustomerUser.Name': '会員名',
    'CustomerUser.Name.Placeholder': '会員名入力',
    'CustomerUser.ReferralCode': '会員固有コード',
    'CustomerUser.ReferralCode.Placeholder': '会員共有コード入力',
    'CustomerUser.ActivationStatusCd': '会員状態',
    'CustomerUser.MobileNumber': '連絡先',
    'CustomerUser.JoinDate': '入会日',
    'CustomerUser.Marketing': 'マーケティング受信同意有無',
    'CustomerUser.ReservationAlim': 'PUSH 情報受信',
    'CustomerUser.Address': '住所',
    'CustomerUser.DriverLicense': '運転免許証情報',
    'CustomerUser.MembershipCd': 'グレード',
    'CustomerUser.MembershipCd.Lv': '会員グレード',
    'CustomerUser.ExpectMembershipCd': '来月予想グレード',
    'CustomerUser.BusinessProfile.YN': 'ビジネスプロフィール作成有無',
    'CustomerUser.Business_Profile': 'ビジネスプロフィール有無',
    'CustomerUser.AuthCode': 'ビジネスプロフィール認証コード',
    'CustomerUser.BusinessName': '会員社情報',
    'CustomerUser.MyBusinessName': '所属会員社',
    'CustomerUser.DepartmentName': '部署情報',
    'CustomerUser.Auth': '権限（バーミッション）',
    'CustomerUser.CompanyEmail': '会社電子メール',
    'CustomerUser.PersonalCardInfo': '個人決済クレジットカード情報',
    'CustomerUser.CardCompanyName': 'クレジットカード会社名',
    'CustomerUser.CardNumber': 'クレジットカード番号',
    'CustomerUser.CouponCnt': '保有クーポン',
    'CustomerUser.RecommendCnt': '紹介実績',
    'CustomerUser.ConsultationCnt': '相談内訳',
    'CustomerUser.PenaltyPoint': '相談内訳',
    'CustomerUser.PenaltyFee': '累積反則金',
    'CustomerUser.Penalty': 'ペナルティ料金',
    'CustomerUser.ReceivablesPay': '累積未払い金',
    'CustomerUser.Personal': '個人',
    'CustomerUser.Business': 'ビジネス',
    'CustomerUser.PurchaseDtl': '購入/貸出内訳',
    'CustomerUser.TotalUseCnt': '総利用回数',
    'CustomerUser.Subject': 'タイトル',
    'CustomerUser.BirthDate': '生年月日',
    'CustomerUser.SendContents': '送信内容',
    'CustomerUser.CouponList': 'クーポンリスト',
    'CustomerUser.Recommend.UserID': '会員ID',
    'CustomerUser.Recommend.Name': '会員名',
    'CustomerUser.Recommend.UserStatus': '会員状態',
    'CustomerUser.Recommend.JoinDate': '入会日',
    'CustomerUser.Subject.Placeholder': 'タイトルを入力',
    'CustomerUser.SendContents.Placeholder': '内容を入力',
    'CustomerUser.Serach': '会員検索',
    'CustomerUser.ActivationStatusCd.Placeholder': '会員状態全体',
    'CustomerUser.MembershipCd.Placeholder': '会員レベル全体',
    'CustomerUser.ServiceInformationAlim': 'PUSH 情報受信',
    'CustomerUser.Recommend': '紹介実績',
    'CustomerUser.DriverReissueYn.Message':
        '<span>(*再交付日から1年未満の運転免許証情報の登録)</span>',
    'CustomerUser.Nationality': '内/外国人',
    'CustomerUser.PassportStatusCd': 'パスポート承認状態',
    'CustomerUser.Lang': '使用言語',
    'CustomerUser.Nationality.All': '内/外国人全体',
    'CustomerUser.PassportStatusCd.All': 'パスポート承認状態全体',
    'CustomerUser.Lang.All': '使用言語全体',

    // 会員/部署管理 > 役職員照会
    'BusinessMember.Stats': '総: (正) {regularCount}人 (準) {associateCount}人',
    'BusinessMember.CompanyEmail.Placeholder': '会社電子メール入力',
    'BusinessMember.SubTitle.View': '役職員詳細',
    'BusinessMember.SubTitle.Detail': '役職員修正',
    'BusinessMember.SubTitle.BusinessMemberInfo': '役職員情報',
    'BusinessMember.SubTitle.UserInfo': '会員情報',
    'BusinessMember.SubTitle.IndividualBusinessMemberInfo': '役職員個人情報',
    'BusinessMember.SubTitle.BDInfo': '会社及び部署情報',
    'BusinessMember.LicenseCertificate': '運転免許証の認証有無',
    'BusinessMember.LicenseStatusCd': '運転免許証承認状態',
    'BusinessMember.Delete.Message1': `{departmentName}{name}様のビジネスプロフィールを削除しますか?`,
    'BusinessMember.Delete.Message2': `削除処理した後には該当役職員が業務用に MOCEAN Car Sharing 車両を利用できなくなります。`,
    'BusinessMember.Delete.Message3': `*今後、当該役職員を業務用に再利用をしたい場合、役職員登録及び認証の手続きを再度行わなくてはなりません。`,
    'BusinessMember.Customer.Cellphone.Masked':
        '{firstNumber}-****-{lastNumber}',
    'BusinessMember.LicenseYN': '免許証の承認', // JP
    'BusinessMember.Role.Delete': '利用権限の削除', // JP

    // 会員/部署管理 > 役職員登録
    'BusinessInvite.Title.Group': '団体会員登録',
    'BusinessInvite.Title.Single': '個別会員登録',
    // 'BusinessInvite.Create.Tip.1': `- MOCEAN Car Sharing アカウントに貴社所属の<span>ビジネスプロフィール登録できる権限（バーミッション）を付与</span>できます。`,
    // 'BusinessInvite.Create.Tip.2': `- 役職員の会社電子メールを登録する時、 <span>該当電子メールでビジネスプロフィール認証及び作成</span>できます。`,
    // 'BusinessInvite.Create.Tip.3': `- 役職員に MOCEAN Car Sharing ビジネスプロフィール作成の案内/推奨したい場合<span>登録した後、招待電子メール </span>を送信してください。  ビジネスプロフィール作成のための認証コードと利用ガイドが含まれた招待電子メールが送信されます。`,
    // 'BusinessInvite.Create.Tip.4': `- 2人以上の役職員を同時に登録したい場合、<span>様式・書式をダウンロードして作成後、ファイルダウンロード> [確認] ボタン</span>を押してください。`,
    'BusinessInvite.Create.Tip.1': `- MOCEAN Car Sharingに入会した会員に<span>貴社のビジネスプロフィールを作成できる権限（バーミッション）を付与</span>できます。`,
    'BusinessInvite.Create.Tip.2': `- 本ページから<span>登録された役職員はMOCEAN Car Sharingアプリから会社の電子メールにビジネスプロフィールの認証と作成ができます。</span>`,
    'BusinessInvite.Create.Tip.3': `- 役職員にビジネスプロフィール作成を案内/推奨する場合、<span>登録した後、' 登録状況'タブから招待メールを送信</span>してください。`,
    'BusinessInvite.Create.Tip.4': `- 2人以上の役職員を同時に登録したい場合、<span>団体会員登録</span>の様式・書式をダウンロードして作成後、ファイルをアップロードしてください。`,
    'BusinessInvite.List.Tip.1': `- 役職員にMOCEAN Car Sharingビジネスプロフィール作成の案内/推奨する場合、<span>登録後、招待メールを送信</span>してください。`,
    'BusinessInvite.List.Tip.2': ` ビジネスプロフィールを作成するための <span>利用ガイドが含まれた招待メール</span>が送信されました。`,
    'BusinessInvite.Email.Confirm': '電子メールを送信しますか？',
    'BusinessInvite.Email.Success': '電子メールを送信しました。',
    'BusinessInvite.Department.Select': '部署名選択',
    'BusinessInvite.CreatorName': '登録者',
    'BusinessInvite.Status': '状態',
    'BusinessInvite.UploadCnt': '要請件数',
    'BusinessInvite.SuccessCnt': '成功件数',
    'BusinessInvite.FailCnt': '失敗件数',
    'BusinessInvite.Link': 'ファイルリンク',
    'BusinessInvite.BusinessID': '会員社 ID',
    'BusinessInvite.CreatedAt': '登録日',
    'BusinessInvite.List.CreatedAt': '登録日時',
    'BusinessInvite.UpdatedAt': '修正日',
    'BusinessInvite.CreatedBy': '作成者',
    'BusinessInvite.BusinessName': '会員社名',
    'BusinessInvite.List.BusinessName': '会社名',
    'BusinessInvite.DepartmentName': '部署名',
    'BusinessInvite.DepartmentID': '部署 ID',
    'BusinessInvite.DepartmentManager': '部署担当者',
    'BusinessInvite.CompanyEmail': '会社電子メール',
    'BusinessInvite.List.CompanyEmail': '会社電子メール',
    'BusinessInvite.BusinessProfileStatus': 'プロフィール進行状態',
    'BusinessInvite.FailMsg':
        '一部データが保存できませんでした。再度、確認してください。',
    'BusinessInvite.AllFailMsg': '保存するデータを再度、確認してください。',
    'BusinessInvite.NoDataMsg': '保存するデータがございません。',

    // 会員/部署管理 > 部署
    'Department.DepartmentName': '部署名',
    'Department.DepartmentManager': '部署担当者',
    'Department.RepresentativeDepartmentManager': '代表部署担当者',
    'Department.RepresentativeDepartmentManager.Placeholder':
        '代表部署担当者選択',
    'Department.Code': '部署コード',
    'Department.DepartmentCnt': '入会部署員',
    'Department.isTeamVehicle': '部署専用車両の有無',
    'Department.paymentMethod': '決済手段',
    'Department.License': '入会商品',
    'Department.BusinessName': '会員社名',
    'Department.BusinessName.Placeholder': '会員社名入力',
    'Department.BusinessName.Search': '会員社名検索',
    'Department.DepartmentName.Placeholder': '部署名入力',
    'Department.Select': '部署選択',
    'Department.All': '部署全体',
    'Department.Placeholder': '部署名入力',
    'Department.DepartmentManager.Placeholder': '代表部署担当者入力',
    'Department.DepartmentManager.Search': '部署担当者検索',
    'Department.CreateMethod': '部署登録方法',
    'Department.CreateMethod.Single': '個別追加',
    'Department.CreateMethod.Single.Subtitle': '部署個別追加',
    'Department.CreateMethod.Multiple': '一括追加',
    'Department.CreateMethod.Multiple.Subtitle': '部署一括追加',
    'Department.Edit': '部署情報修正',
    'Department.DepartmentManager.Create': '部署担当者登録',
    'Department.DepartmentManager.Create.Message':
        '※ 検索後、保存時、すぐに部署担当者は権限（バーミッション）を付与できます。',
    'Department.CreateResult':
        '団体会員登録結果<span className="titleInfo02">(最近7日に対する内訳のみ照会)</span>',
    'Department.Btn.Create': '部署登録',

    // 会員/部署管理 > 会員社権限（バーミッション）
    'BusinessOperator.List': '会員社権限（バーミッション）情報',

    // 会員/部署管理 > 運転免許証の確認履歴
    'License.Check.RentalTime': '貸出時間',
    'License.Check.CreatedAt': '要請時間',
    'License.Check.PhoneNumber': '電話番号',
    'License.Check.ResidentName': '運転者名前',
    'License.Check.ResidentDate': '運転者生年月日',
    'License.Check.ResponseCode': '応答結果',
    'License.Check.ResponseMessage': '応答メッセージ',
    'License.Check.LicenseKind': '運転免許証の種類',
    'License.Check.Number': '運転免許証番号',
    'License.Check.Type': '免許証の種類',
    'LicenseCheck.RentalTime': '貸出時間',
    'LicenseCheck.CreatedAt': '登録日',
    'LicenseCheck.PhoneNumber': '電話番号',
    'LicenseCheck.PhoneNumber.Placeholder': '電話番号入力',
    'LicenseCheck.ResidentName': '運転者名',
    'LicenseCheck.ResidentName.Placeholder': '運転者名入力',
    'LicenseCheck.ResidentDate': '生年月日',
    'LicenseCheck.ResidentDate.Placeholder': '運転者生年月日(YYMMDD, 6桁) 入力',
    'LicenseCheck.ResidentEmail': '会員電子メール',
    'LicenseCheck.ResidentEmail.Placeholder': '会員電子メール入力',
    'LicenseCheck.ResponseCreateAt': '確認日時',
    'LicenseCheck.ResponseCode': '応答結果',
    'LicenseCheck.ResponseMessage': '検証値' /*{ 応答メッセージ }*/,
    'LicenseCheck.LicenseKind': '運転免許証の種類',
    'LicenseCheck.SubTitle': '運転免許証の確認履歴',

    // 会員/部署管理 > ペナルティ
    'Penalty.List': 'ペナルティ内訳',
    'Penalty.Policy': 'ペナルティポリシー',
    'Penalty.UserID': '会員 ID',
    'Penalty.UserID.Placeholder': '会員 ID 入力',
    'Penalty.UserName': '会員名',
    'Penalty.UserName.Placeholder': '会員名入力',
    'Penalty.ReservationKey': '貸出番号',
    'Penalty.ReservationKey.Placeholder': '貸出番号入力',
    'Penalty.ReservationType': '貸出タイプ',
    'Penalty.ReservationType.Placeholder': '貸出タイプ入力',
    'Penalty.BusinessName': '会員社名',
    'Penalty.DepartmentName': '部署名',
    'Penalty.PenaltyCategory': 'ペナルティ項目',
    'Penalty.PenaltyCategory.Select': 'ペナルティ項目選択',
    'Penalty.PenaltyCategory.All': 'ペナルティ項目全体',
    'Penalty.ProfileTypeCd.All': 'プロフィールタイプ全体',
    'Penalty.RegistrationDate': 'ペナルティ登録日',
    'Penalty.ViolationDesc': '違反事項',
    'Penalty.ViolationDesc.Select': '違反事項選択',
    'Penalty.ViolationDesc.All': '違反事項全体',
    'Penalty.ViolationDesc.Placeholder': '違反事項入力',
    'Penalty.Point': '点数',
    'Penalty.PenaltyFee': 'ペナルティ料金',
    'Penalty.PenaltyPoint': 'ペナルティ点数',
    'Penalty.AccumulatedPenaltyPoint': '累積ペナルティ点数',
    // 'Penalty.PenaltyPoint.Minus': 'ペナルティ点数差引',
    'Penalty.PenaltyUseStopYn': '利用停止有無',
    'Penalty.CreateDt': '登録日',
    'Penalty.Register': '登録者',
    'Penalty.Description': '追加内容',
    'Penalty.Complete.Alert.Message':
        'ペナルティ料金が免除され、決済なしで処理完了します。このまま続けますか?', // JP
    // 'Penalty.Description.Minus':
    //     'ペナルティ点数免除完了(既存ペナルティ点数: {penaltyPoint}点)',

    // 売上/精算管理 > 売上
    'Sales.MID.Select.Placeholder': 'MID 全体',
    'Sales.ProfileTypeCd.Select.Placeholder': 'プロフィールタイプ全体',
    'Sales.SalesTypeCd.Select.Placeholder': '売上区分全体',
    'Sales.Account': '口座番号',
    'Sales.MID': 'MID',
    'Sales.ProfileType': 'プロフィールタイプ',
    'Sales.ChargeGroup': '料金グループ',
    'Sales.Personal': '個人商品',
    'Sales.Business': 'ビジネス商品',
    'Sales.SalesTypeCd': '売上区分',
    'Sales.Sales.Amount': '売上額',
    'Sales.SalesTime': '日時',
    'Sales.Stat': '昨日 {yesterday}円 | 今月 {month}円 | 総 {total}円',
    'Sales.BusinessName': '会員社',
    'Sales.BusinessName.Placeholder': '会員社入力',
    'Sales.DepartmentName': '部署名',
    'Sales.DepartmentName.Placeholder': '部署名入力',
    'Sales.UserName': '貸出会員',
    'Sales.UserName.Placeholder': '会員名入力',
    'Sales.ReservationType': '貸出タイプ',
    'Sales.ReservationKey': '貸出番号',
    'Sales.ReservationKey.Placeholder': '貸出番号入力',
    'Sales.ReservationVehicle': '貸出車両',

    'Sales.SubscribeID': 'サブスクリプション番号',
    'Sales.SubscribeID.Placeholder': 'サブスクリプション番号入力',
    'Sales.Accounting.Download': '会計用エクセルダウンロード',
    'Sales.PaymentID': '決済番号',
    'Sales.PaymentID.Placeholder': '決済番号入力',
    'Sales.LicensePlateNumber': '車両番号',
    'Sales.LicensePlateNumber.Placeholder': '車両番号入力',
    'Sales.LocationName': 'ステーション',
    'Sales.LocationName.Placeholder': 'ステーション',
    'Sales.Reservation.Type': 'プロフィールタイプ',
    'Sales.ReservationType.All': 'プロフィールタイプ全体',
    'Sales.ReservationType.BUSINESS': '業務用',
    'Sales.ReservationType.PERSONAL': '個人用',
    'Sales.ReservationPeriod': '貸出期間',
    'Sales.RentalPeriod': '実際の使用時間',
    'Sales.SalesClassCd': '売上区分',
    'Sales.SalesClassCd.All': '売上区分全体',
    'Sales.PaymentClassCd': '決済区分',
    'Sales.PaymentClassCd.All': '決済区分全体',
    'Sales.ChargeGroup.All': '料金グループ全体',
    'Sales.ChargeType': '個人商品',
    'Sales.ChargeType.All': '個人商品全体',
    'Sales.LicenseTypeCd': 'ビジネス商品',
    'Sales.LicenseTypeCd.All': 'ビジネス商品全体',
    'Sales.AuthDate': '決済日時',
    'Sales.AuthDate.Label': '決済日',
    'Sales.ModelName': '車種',
    'Sales.UserDepartmentName': '貸出会員部署名',
    'Sales.RealPay': '決済金額',
    'Sales.PaymentType': '決済方法',
    'Sales.PaymentInfo': '決済情報',
    'Sales.PaymentMethod': '決済方法',
    'Sales.ChargeEvidence': '決済証憑書類',
    'Sales.ChargeCompleteTime': '精算完了日時',
    'Sales.Charge.Type': '請求手段',
    'Sales.ChargeTime': '請求日時',
    'Sales.ApproveNumber': '承認番号',
    'Sales.ApproveStatus': '承認状態',
    'Sales.LicenseType': '商品名',
    'Sales.LicenseType.PlaceHolder': 'ビジネス商品選択',
    'Sales.Provider': '供給社',
    'Sales.Provider.Placeholder': '供給社入力',
    'Sales.TollTime': '通行料金発生日時',
    'Sales.TollFee': '通行料金',
    'Sales.TotalTollFee': '通行料金',
    'Sales.RentalDelayFee': '遅延料金(円/分)',
    'Sales.RentalDelayTime': '遅延時間(分)',
    'Sales.PenaltyFee': 'ペナルティ料金',
    'Sales.PenaltyCategory': 'ペナルティ項目',
    'Sales.PenaltyPoint': 'ペナルティ点数',
    'Sales.ViolationDesc': '違反事項',
    'Sales.VehicleRepairFee': '事故処理料金',
    'Sales.CancelFee': 'キャンセル料',
    'Sales.InsuranceFee': 'NOC補償',
    'Sales.InsuranceName': 'NOC補償名',
    'Sales.Insurance.SelfCost': '自己負担金',
    'Sales.RentalFee': '貸出料金',
    'Sales.ExtendFee': '延長料金',
    'Sales.UserID': '会員ID',
    'Sales.User.Name': '会員名',
    'Sales.UserTelnum': '連絡先',
    'Sales.PaymentInfo.DrivingFee': '走行料金及び通行料金支払い方法',
    'Sales.SubscribeGroupName': 'サブスクリプション商品名',
    'Sales.SubscribePeriod': 'サブスクリプション契約期間',
    'Sales.DrivingFee': '走行料金',
    'Sales.DrivingDistance': '走行距離(km)',
    'Sales.DrivingDistanceFee': '走行料金(円/km)',

    // 売上/精算管理 > クーポン売上
    // 'CouponSales.Stats': '· 割引額　昨日{prevDayDiscountSum}円 | 総{totalDiscountSum}円   · 連携発生売上　昨日{prevDaySum}円 | 総{totalSum}円',
    'CouponSales.Stats.1': '· 割引額',
    'CouponSales.Stats.2': '昨日 {prevDayDiscountSum}円',
    'CouponSales.Stats.3': '総 {totalDiscountSum}円',
    'CouponSales.Stats.4': '· 連携発生売上',
    'CouponSales.Stats.5': '昨日　{prevDaySum}円',
    'CouponSales.Stats.6': '総 {totalSum}円',
    'CouponSales.SalesClassCd': '売上区分',
    'CouponSales.SalesClassCd.All': '売上区分全体',
    'CouponSales.CouponTypeCd': 'クーポンタイプ',
    'CouponSales.CouponTypeCd.All': 'クーポンタイプ全体',
    'CouponSales.CouponTypeCd.Select': 'クーポンタイプ選択',
    'CouponSales.DiscountTypeCd': '割引タイプ',
    'CouponSales.DiscountTypeCd.All': '割引タイプ全体',
    'CouponSales.DiscountTypeCd.Select': '割引タイプ選択',
    'CouponSales.CouponKey': 'クーポン管理番号',
    'CouponSales.CouponKey.Placeholder': 'クーポン管理番号入力',
    'CouponSales.CouponName': 'クーポン名',
    'CouponSales.CouponName.Placeholder': 'クーポン名入力',
    'CouponSales.UsePay': '利用額',
    'CouponSales.CouponDiscountPay': '割引額',
    'CouponSales.RealPay': '決済金額',
    'CouponSales.ReservationType': 'プロフィールタイプ',
    'CouponSales.ReservationType.All': 'プロフィールタイプ全体',
    'CouponSales.ReservationType.Label': '貸出タイプ',
    'CouponSales.UserName': '会員名',
    'CouponSales.UserName.Placeholder': '会員名入力',
    'CouponSales.LocationName': 'ステーション',
    'CouponSales.LocationName.Placeholder': 'ステーション入力',
    'CouponSales.ModelName': '車種',
    'CouponSales.ModelName.Placeholder': '車種入力',
    'CouponSales.Provider': '供給社',
    'CouponSales.Provider.All': '供給社全体',
    'CouponSales.ChargeTypeCd': '料金グループ',
    'CouponSales.ChargeTypeCd.All': '料金グループ全体',
    'CouponSales.ChargeName': '個人商品',
    'CouponSales.ChargeID.All': '個人商品全体',
    'CouponSales.LicenseTypeCd': 'ビジネス商品',
    'CouponSales.LicenseTypeCd.All': 'ビジネス商品全体',
    'CouponSales.PaymentTypeCd': '精算区分',
    'CouponSales.PaymentTypeCd.All': '精算区分全体',
    'CouponSales.PaymentTypeCd.CLAIM': '請求完了',
    'CouponSales.PaymentTypeCd.PAYMENT': '精算完了',
    'CouponSales.UseDate': 'クーポン使用日時',
    'CouponSales.SearchDate': 'クーポン使用期間',
    'CouponSales.ClaimDate': '請求日時',
    'CouponSales.SalesInfo': 'クーポン売上情報',
    'CouponSales.CouponInfo': 'クーポン情報',
    'CouponSales.UnitDiscountRate': '割引単位',
    'CouponSales.DiscountPay': '割引額',
    'CouponSales.UserInfo': '会員情報',
    'CouponSales.UserEmail': '会員 ID',
    'CouponSales.UserMobileNumber': '連絡先',
    'CouponSales.BusinessName': '会員社',
    'CouponSales.DepartmentName': '部署名',
    'CouponSales.BusinessEmail': '会社電子メール',
    'CouponSales.SubscribeInfo': 'サブスクリプション情報',
    'CouponSales.SubscribeID': 'サブスクリプション番号',
    'CouponSales.SubscribeChargeName': 'サブスクリプション商品名',
    'CouponSales.SubscribeReservationTime': 'サブスクリプション契約期間',
    'CouponSales.ReservationInfo': '貸出情報',
    'CouponSales.ReservationKey': '貸出情報',
    'CouponSales.LicensePlateNumber': '車両番号',
    'CouponSales.LicensePlateNumber.Label': '貸出車両',
    'CouponSales.ChargeName.Label': '商品名',
    'CouponSales.ReservationTime': '貸出期間',
    'CouponSales.ReservationTime.ADDITION': '個別契約期間',
    'CouponSales.RentalEndTime': '実際の使用期間',
    'CouponSales.CalculateInfo': '精算情報',
    'CouponSales.PaymentMethod': '請求手段',
    'CouponSales.PaymentMethodInfo': '決済情報',
    'CouponSales.CompleteDate': '精算完了日時',

    // 売上/精算管理 > 精算
    'Calculation.Stats':
        '精算 {calculationSum}円 | 請求完了 {claimSum}円 | 未精算 {unCalculationSum}円',
    'Calculation.Stats.1': '精算{calculationSum}円',
    'Calculation.Stats.2': '請求完了{claimSum}円',
    'Calculation.Stats.3': '未精算{unCalculationSum}円',
    'Calculation.CalculateType': '精算対象',
    'Calculation.CalculateType.All': '精算対象全体',
    'Calculation.CalculateType.BUSINESS': '会員社',
    'Calculation.CalculateType.PERSONAL': '個人',
    'Calculation.BusinessName': '会員社名',
    'Calculation.BusinessName.Label': '会員社',
    'Calculation.UserName': '会員名',
    'Calculation.UserName.Label': '会員',
    'Calculation.UserName.Placeholder': '会員名入力',
    'Calculation.CalculateMethod': '精算タイプ',
    'Calculation.CalculateMethod.All': '精算タイプ全体',
    'Calculation.CalculateMethod.EXACT_CALCULATION': '別途精算',
    'Calculation.CalculateMethod.MANUAL_CALCULATION': '手書き精算',
    'Calculation.SalesClassCd': '売上区分',
    'Calculation.SalesClassCd.All': '売上区分全体',
    'Calculation.SalesClassCd.Select': '売上区分選択',
    'Calculation.LicenseTypeCd': 'ビジネス商品',
    'Calculation.LicenseTypeCd.All': 'ビジネス商品全体',
    'Calculation.CalculateStartEndDate.Label': '精算期間(発生日)',
    'Calculation.CalculateStartEndDate': '精算期間',
    'Calculation.CalculateStartEndDate.StartDate': '精算開始日時',
    'Calculation.CalculateStartEndDate.EndDate': '精算終了日時',
    'Calculation.CalculateDate': '発生日',
    'Calculation.CalculateDate.Placeholder': '発生日選択',
    'Calculation.PaymentMethod': '決済手段',
    'Calculation.PaymentMethod.All': '決済手段全体',
    'Calculation.PaymentMethod.CARD': 'カード決済',
    'Calculation.PaymentMethod.CASH': '口座振替',
    'Calculation.CreatedAt': '精算登録日時',
    'Calculation.CalculateEndTime': '精算完了日時',
    'Calculation.SearchDate': '精算完了日',
    'Calculation.CalculatePay': '精算額',
    'Calculation.CalculatePay.Placeholder': '精算額入力',
    'Calculation.CalculatePay.Search': '精算額照会',
    'Calculation.CalculatePay.Search.Helper': '精算額を照会',
    'Calculation.CalculatePay.None': '精算額が0円です。',
    'Calculation.CalculatePay.None1':
        '設定した精算期間に精算できる対象がございません。',
    'Calculation.CalculatePay.None2': '精算対象がないため、登録できません。',
    'Calculation.CalculateStatus': '精算状態',
    'Calculation.CalculateStatus.All': '精算状態全体',
    'Calculation.CalculateStatus.Label': '状態',
    'Calculation.CalculateStatus.UN_CALCULATE': '未精算',
    'Calculation.CalculateStatus.CLAIM_COMPLETE': '請求完了',
    'Calculation.CalculateStatus.COMPLETE': '精算完了',
    'Calculation.Memo': 'メモ',
    'Calculation.Memo.Placeholder': 'メモ入力',
    'Calculation.BaseInfo': '基本情報',
    'Calculation.ClaimInfo': '請求情報',
    'Calculation.ClaimTime': '請求日時',
    'Calculation.PaymentMethodInfo': '決済情報',
    'Calculation.ClaimFileName.Label': '決済証憑書類の添付',
    'Calculation.PaymentCard': '決済用カード',
    'Calculation.Evidence': '証憑書類',
    'Calculation.CalculateClaim.List': '精算対象リスト',
    'Calculation.ReservationTime': '発生日',
    'Calculation.ReservationKey': '貸出番号',
    'Calculation.LocationName': 'ステーション',
    'Calculation.DepartmentName': '部署',
    'Calculation.OdoKilometer': '走行距離(km)',
    'Calculation.ActionClaim': '請求する',
    'Calculation.ActionClaim.Confirm': '請求しますか?',
    'Calculation.ActionClaim.Success': '請求しました。',
    'Calculation.ActionClaim.Failure': '請求できません。',
    'Calculation.ActionCalculation': '精算完了',
    'Calculation.ActionCalculation.Confirm': '精算完了しますか?',
    'Calculation.ActionCalculation.Success': '精算完了しました。',
    'Calculation.ActionCalculation.Failure': '精算完了できません。',
    'Calculation.ActionCalculation.Title': '精算完了する',
    'Calculation.ActionCalculationCard.Text':
        '下記の精算額をカードで決済する。',
    'Calculation.ActionCalculationCard.Text2':
        '決済カードを設定してから精算完了を選択してください。',
    'Calculation.ActionCalculationCash.Text':
        '下の精算額を口座振替で確認します。',
    'Calculation.ActionCalculationCash.Text2':
        '決済証憑書類を添付した後、精算完了を選択してください。',
    'Calculation.MID': 'MID',
    'Calculation.MID.Select': 'MID 選択',

    // 売上/精算管理 > クーポン
    'Coupon.Code.Issued': '発行',
    'Coupon.Code.NotIssued': '未発行',
    'Coupon.CouponKey': '管理番号',
    'Coupon.CouponKey.Placeholder': '管理番号入力',
    'Coupon.CouponTypeCd': 'クーポンタイプ',
    'Coupon.CouponTypeCd.All': 'クーポンタイプ全体',
    'Coupon.CouponTypeCd.Select': 'クーポンタイプ選択',
    'Coupon.DiscountTypeCd': '割引タイプ',
    'Coupon.DiscountTypeCd.All': '割引タイプ全体',
    'Coupon.DiscountTypeCd.Select': '割引タイプ選択',
    'Coupon.ProfileTypeCd': 'タイプ',
    'Coupon.ProfileTypeCd.All': 'タイプ全体',
    'Coupon.CouponName': 'クーポン名',
    'Coupon.CouponName.Placeholder': 'クーポン名入力',
    'Coupon.Status': '状態',
    'Coupon.Status.All': '状態全体',
    'Coupon.Date.Issued': 'ダウンロード期限',
    'Coupon.Date.Used': '使用期間',
    'Coupon.CouponCode': 'クーポンコード',
    'Coupon.CouponCode.Placeholder': 'クーポンコード入力',
    'Coupon.Reward.Desc': '※ 5キロ以上走行、利用前より充填量が多い状態で返却するとリワードクーポンが発行されます。',
    'Coupon.Weekdays.Holidays': '平日/休日',
    'Coupon.Weekdays.Holidays.All': '曜日全体',
    'Coupon.Day': '曜日',
    'Coupon.IssuedNumber': '発行枚数',
    'Coupon.IssuedNumber.Placeholder': '発行枚数入力',
    'Coupon.IssuedNumber.Validator.Unlimit': '無制限に選択してください。',
    'Coupon.IssuedNumber.Posible': '発行可能枚数',
    'Coupon.Detail.CouponBasicInformation': 'クーポン基本情報',
    'Coupon.Detail.PublicationInformation': '発行情報',
    'Coupon.Detail.CouponNo': '発行番号',
    'Coupon.Detail.CouponNo.Placeholder': 'クーポン番号入力',
    'Coupon.Detail.CouponStatus': 'クーポン状態',
    'Coupon.Deatil.CouponName.Placeholder': 'クーポン名入力',
    'Coupon.Detail.Description.App': 'クーポン説明 (アプリ)',
    'Coupon.Detail.Description.App.Placeholder': 'クーポン説明入力',
    'Coupon.Detail.Description.Admin': 'クーポン説明(アドミン)',
    'Coupon.Detail.Description.Admin.Placeholder': 'クーポン説明入力',
    'Coupon.Detail.AddWithAply': 'ダウンロード日基準',
    'Coupon.Detail.AddWithAply.Value': 'ダウンロード日基準 {value} 日',
    'Coupon.Detail.AddWithAply.Placeholder': 'アラビア数字',
    'Coupon.RegisterableNumber': '登録可能数量',
    'Coupon.RegisterableNumber.Placeholder': '登録可能数量入力',
    'Coupon.CouponClassCd.Placeholder': '自動発行区分選択',
    'Coupon.Detail.DiscountInformation': '割引情報',
    'Coupon.Detail.UnitFieldInfo': 'クーポン単位',
    'Coupon.Detail.UnitField.TIME': '時間(分)',
    'Coupon.Detail.UnitField.TIME.Placeholder': '分入力',
    'Coupon.Detail.UnitField.TIME.Tail': '分',
    'Coupon.Detail.UnitField.TIME.View': '時間 ',
    'Coupon.Detail.UnitField.AMOUNT': '金額(円)',
    'Coupon.Detail.UnitField.AMOUNT.Placeholder': '金額入力',
    'Coupon.Detail.UnitField.AMOUNT.Tail': '円',
    'Coupon.Detail.UnitField.AMOUNT.View': '金額 | ',
    'Coupon.Detail.UnitField.DISCOUNTRATE': '割引率(%)',
    'Coupon.Detail.UnitField.DISCOUNTRATE.Placeholder': '割引率入力',
    'Coupon.Detail.UnitField.DISCOUNTRATE.Tail': '率',
    'Coupon.Detail.UnitField.DISCOUNTRATE.View': '割引率| ',
    'Coupon.Detail.LeastFieldInfo': '適用条件',
    'Coupon.Detail.DiscountInfoLange': '割引適用区間',
    'Coupon.Detail.UnitField.TIME.Choice': '時間選択',
    'Coupon.DiscountStartHour': '開始時間',
    'Coupon.DiscountEndHour': '終了時間',
    'Coupon.Detail.DiscountTimeAs.1': '開始時間から',
    'Coupon.Detail.DiscountTimeAs.2': '分後まで',
    'Coupon.Detail.DescountMaxAmt': '最大割引額',
    'Coupon.Detail.DescountMaxAmt.Placeholder': '最大割引額入力',
    'Coupon.Detail.ApplicableInformation': '適用対象情報',
    'Coupon.Detail.Applicable.Title': '提携情報',
    'Coupon.Detail.Applicable.Business': '適用会員社',
    'Coupon.Detail.Applicable.WibleZone': '適用ステーション',
    'Coupon.Detail.Applicable.Product': '適用商品',
    'Coupon.Detail.Applicable.Pop.AffiliateName': '提携名',
    'Coupon.Detail.Applicable.Pop.AffiliateName.Placeholder': '提携名入力',
    'Coupon.Detail.Applicable.Pop.AffiliateBusinessName': '提携社',
    'Coupon.Detail.Applicable.Pop.AffiliateBusinessName.Placeholder':
        '提携社入力',
    'Coupon.Detail.Applicable.Pop.AffiliateDate': '提携期間',
    'Coupon.Detail.Applicable.Pop.CouponCode': '提携コード',
    'Coupon.Detail.Applicable.Pop.CouponCode.Placeholder': '提携コード入力',
    'Coupon.Detail.ActualIssuedNumber': '実際の発行枚数',
    'Coupon.Detail.ActualUsedNumber': '実際の使用枚数',
    'Coupon.Detail.Customer.List': '会員照会', // JP
    'Coupon.Detail.Customer.List.Name': '会員名', // JP
    'Coupon.Detail.Customer.List.MobileNumber': '電話番号', // JP
    'Coupon.Detail.Customer.List.Email': '会員ID', // JP
    'Coupon.Detail.Customer.List.ValidStartDate': '発行開始日時', // JP
    'Coupon.Detail.Customer.List.ValidEndDate': '発行終了日時', // JP
    'Coupon.Detail.Customer.List.useDate': '使用日', // JP
    'Coupon.Detail.Btn.Business': '会員社検索',
    'Coupon.Detail.Btn.Business.Choice': '会員社選択',
    'Coupon.Detail.Btn.WibleZone': 'ステーション 検索',
    'Coupon.Detail.Btn.WibleZone.Choice': 'ステーション選択',
    'Coupon.Detail.Btn.Product': '商品検索',
    'Coupon.Detail.Btn.Product.Choice': '商品選択',
    'Coupon.Detail.LedgerInfo.Title': 'クーポン発行台帳',
    'Coupon.Detail.LedgerInfo': 'クーポン発行台帳内訳',
    'Coupon.Detail.LedgerInfo.Random.Description':
        '発行枚数だけランダム作成します。',
    'Coupon.Detail.LedgerInfo.User.Description':
        '登録された対象にランダムに発行されたクーポンを発行する。',
    'Coupon.LedgerInfo.IssuedDate': '発行日時',
    'Coupon.LedgerInfo.IssuedTypeCd': 'クーポンの発行方法',
    'Coupon.LedgerInfo.IssuedCount': '発行枚数',
    'Coupon.LedgerInfo.IssuedTarget': '発行対象',
    'Coupon.LedgerInfo.IssuedTarget.Guide': '発行対象案内',
    'Coupon.LedgerInfo.RsultDown': '結果ダウンロード',
    'Coupon.LedgerInfo.CouponInfo': 'クーポン情報',
    'Coupon.LedgerInfo.LedgerType': '発行方法',
    'Coupon.LedgerInfo.LedgerTarget': '発行対象',
    'Coupon.Detail.DiscountInfoLange.Time':
        '開始時間: {startTime} | 終了時間: 開始時間から{endTime}分後まで',
    'Coupon.Detail.User.Leave': '退会済み', // JP
    'Coupon.NationalityCd': '内/外国人',
    'Coupon.NationalityCd.All': '内/外国人全体',

    // 売上/精算管理 > 未払い金
    'Receivable.ProfileType': 'プロフィールタイプ',
    'Receivable.ProfileType.All': 'プロフィールタイプ全体',
    'Receivable.ReceivableType': '未払い金タイプ',
    'Receivable.ReceivableType.All': '未払い金タイプ全体',
    'Receivable.ReceivableInfo': '未払い金情報',
    'Receivable.Business': '会員社',
    'Receivable.Name': '会員名',
    'Receivable.Name.Input': '会員名入力',
    'Receivable.UserID': '会員 ID',
    'Receivable.UserID.Input': '会員 ID 入力',
    'Receivable.SubscriptionNo': 'サブスクリプション番号',
    'Receivable.RentalInfo': '貸出情報',
    'Receivable.RentalNo': '貸出番号',
    'Receivable.CarNo': '車両番号',
    'Receivable.CarNo.Input': '車両番号入力',
    'Receivable.RentalCarNo': '貸出車両',
    'Receivable.ReceivableAmtOne': '未払い金(円)',
    'Receivable.ReceivableAmt': '未払い金',
    'Receivable.ReceivableDate': '未払い金発行日時',
    'Receivable.ReceivableCreateDate': '未払い金発行日時',
    'Receivable.CustomerInfo': '顧客情報',
    'Receivable.Send.Push': 'プッシュ送信',
    'Receivable.Send.Select.Push': 'プッシュ選択送信',
    'Receivable.Send.All.Push': 'プッシュ全体送信',
    'Receivable.Charge.Card': '決済カード',

    // 売上/精算管理 > 反則金 (下段枠は削除予定)
    'Violation.Stats': '総 {totalCost}円',
    'Violation.RentType.Placeholder': '業務タイプ',
    'Violation.CompanyName.Placeholder': '会員社入力',
    'Violation.RenterID.Placeholder': '会員ID 入力',
    //'Vehicle.LicensePlateNumber.Placeholder': '車両番号入力',
    'Violation.UnpaidType.Placeholder': '未払い金タイプ',
    'Violation.RentVehicle': '貸出車両',
    'Violation.ViolationType': '違反タイプ',
    'Violation.ViolationType.Placeholder': '違反タイプ全体',
    'Violation.ViolationType.Select.Placeholder': '違反タイプ選択',
    'Violation.ReservationType.Placeholder': 'プロフィールタイプ全体',
    'Violation.ViolationCharge': '反則金 (円)',
    'Violation.ViolationTime': '違反日時',
    'Violation.ViolationTime.Placeholder': '違反日時入力',
    //'Code.Menu.SystemType.Business': '会員社',
    //'Common.User.Name': '会員名',
    'Violation.Rental.Customer.Tel': '電話番号',
    'Violation.Vehicle.SubTitle': '車両情報',
    'Violation.Info.SubTitle': '違反内訳',
    //'Vehicle.LicensePlateNumber': '車両番号',
    'Violation.Vehicle.Placeholder': '車両番号検索',
    //'License.Check.Number':'運転免許証の番号',
    //'License.Check.Type':'免許証の種類',
    'Violation.Business.CompanyName': '会員社',
    'Violation.Business.CompanyNum': '事業所番号',
    'Violation.Business.Address': '会員住所',
    'Violation.ViolationLoc': '違反した場所',
    'Violation.Detail': '詳しい内容',
    'Violation.Order.Organization': '違反した部署',
    'Violation.Handling.Time': '処理日時',
    'Violation.Objection': '異議処理',
    'Violation.Objection.W': '送信前',
    'Violation.Objection.C': '送信完了',
    'Violation.ViolationLoc.Placeholder': '違反した場所入力',
    'Violation.Detail.Placeholder': '詳しい内容入力',
    'Violation.Order.Organization.Placeholder': '違反した部署入力',
    'Violation.Handling.Time.Placeholder': '日時入力',
    'Violation.Objection.Placeholder': '異議処理選択',
    'Violation.Objection.Comment.Placeholder': '内容入力',
    'Violation.User.Name.Placeholder': '会員名入力',
    'Violation.title': '違反情報入力',
    'Violation.getReservation.btn': '貸出情報照会',
    'Violation.Vehicle.Search': '車両検索',
    'Violation.PenaltyFee': '罰金',
    'Violation.Msg.NoReservation':
        '入力した情報に該当の貸出情報がございません。',
    'Violation.PenaltyPaper': '反則金請求書',
    'Violation.TransferTarget': '移管申請対象',

    // 商品管理 > 料金制
    'Charge.Plan': '料金制名',
    'Charge.PlanType': '料金グループ',
    'Charge.SpecialPlanType': '1回券特価料金タイプ',
    'Charge.SpecialPlanType.AllSelect': '1回券特価料金タイプ全体',
    'Charge.SpecialPlanType.Select': '1回券特価料金タイプ選択',
    'Charge.PassPlanType': 'サブスクリプション券特価料金タイプ',
    'Charge.PassPlanType.AllSelect': 'サブスクリプション券特価料金タイプ全体',
    // 'Code.Charge.PassPlanType.WEEKDAY': '平日',
    'Charge.PlanGroup': '料金グループ',
    'Charge.FreeBaseDistance': '走行料金無課金基準距離', // JP
    'Charge.FreeBaseDistance.Placeholder': '無課金基準距離入力', // JP
    'Charge.BaseDistance': '基準距離',
    'Charge.BaseDistance.Placeholder': '基準距離入力',
    'Charge.ChargeForDistance': '距離当たり走行料金',
    'Charge.ChargeForDistance.Placeholder': '距離当たり走行距離入力',
    'Charge.Fee': '料金',
    'Charge.Fee.Placeholder': '料金入力',
    'Charge.Memo': 'メモ',
    'Charge.Memo.Placeholder': 'メモ入力',
    'Charge.Plan.Placeholder': '料金制入力',
    'Charge.PlanGroup.AllSelect': '料金グループ全体',
    'Charge.PlanName': '料金制名',
    'Charge.PlanName.Placeholder': '料金制名入力',
    'Charge.ProfileType': 'プロフィールタイプ',
    'Charge.PlanGuide': '料金制案内メッセージ',
    'Charge.PlanGuide.Placeholder': '料金制案内メッセージ',
    'Charge.BaseInfo': '基本情報',
    'Charge.DiscountInfo': '割引情報',
    'Charge.FeeInfo': '料金情報',
    'Charge.StartMin': '開始時間',
    'Charge.EndMin': '終了時間',
    'Charge.IncreaseYn': 'ランプ・アップ（割増し）適用有無',
    'Charge.Min.Placeholder': '分単位で入力',
    'Charge.BaseTime': '基準時間',
    'Charge.BaseFee': '基準料金',
    'Charge.Month.3': '3ヶ月',
    'Charge.Month.6': '6ヶ月',
    'Charge.Month.12': '12カ月',
    'Charge.PlusHour': 'プラス時間', // JP
    'Charge.PlusHour.Placeholder': 'プラス時間入力', // JP
    'Charge.RentalSpot': '貸出場所', // JP
    'Charge.ReturnSpot': '返却場所', // JP
    'Charge.Spot.Placeholder': '住所入力', // JP
    'Charge.GuideInfo.Placeholder': 'キャンピング料金制案内の選択', // JP

    // 商品管理 > NOC補償
    'Insurance.InsuranceFee': 'NOC補償',
    'Insurance.InsuranceFee.Placeholder': '加入料入力',
    'Insurance.InsuranceName': 'NOC補償名',
    'Insurance.InsuranceName.Placeholder': 'NOC補償名入力',
    'Insurance.InsuranceGroup': 'NOC補償グループ',
    'Insurance.InsuranceGroup.Placeholder': 'NOC補償グループ入力',
    'Insurance.InsuranceGroupName': 'NOC補償グループ名',
    'Insurance.InsuranceGroupName.Placeholder': 'NOC補償グループ名入力',
    'Insurance.ChargePlanType': '料金グループ',
    'Insurance.ChargePlanType.AllSelect': '料金グループ全体',
    'Insurance.Product': '料金商品',
    'Insurance.Product.Placeholder': '料金商品入力',
    'Insurance.Product.Select': '料金商品選択',
    'Insurance.Insurance': '加入料(24時間基準)',
    'Insurance.Insurance.short': '加入料',
    'Insurance.Insurance1': '保険料1',
    'Insurance.Insurance2': '保険料2',
    'Insurance.Insurance3': '保険料3',
    'Insurance.BaseInfo': '基本情報',
    'Insurance.SelfCost': '自己負担金',
    'Insurance.SelfCost.Placeholder': '自己負担金入力',
    'Insurance.ExemptionFee': '免責料金',
    'Insurance.ExemptionFee.Placeholder': '免責料金入力',
    'Insurance.UseYn': '使用有無',
    'Insurance.StartMin': '開始時間',
    'Insurance.EndMin': '終了時間',
    'Insurance.BaseTime': '基準時間',
    'Insurance.BaseFee': '基準料金',
    'Insurance.Won': '円',
    'Insurance.SurchargeRate': '追加金比率',
    'Insurance.Rate.Placeholder': '比率(%) 入力',

    // 商品管理 >オプション
    'Extra.Name': 'オプション名', // JP
    'Extra.Name.Placeholder': 'オプション名の入力', // JP
    'Extra.ChargeType': '課金タイプ', // JP
    'Extra.Memo': 'メモ', // JP
    'Extra.Memo.PlaceHolder': 'メモ入力', // JP
    'Extra.ChargeType.Placeholder': '課金タイプ全部', // JP
    'Extra.Html.File.Placeholder': 'html ファイル登録', // JP
    'Extra.ProductInfo.Language': '利用案内({value})',
    'Extra.ProductInfo.Placeholder': 'オプションの案内を選択', // JP
    'Extra.BaseCharge': '基本区間の料金', // JP
    'Extra.DelayCharge': '遅延区間の料金', // JP
    'Extra.Charge.Placeholder': '料金の入力', // JP
    'Extra.Day.Placeholder': '1日(24時間)', // JP
    'Extra.Asta': '*',

    // コンテンツ管理 > 公示/イベント
    'Notice.Subject': '公示/イベント名',
    'Notice.Subject.Placeholder': '公示/イベント名入力',
    'Notice.Contents': '公示内容',
    'Notice.Target': '掲示対象',
    'Notice.StartDate': '表示期間',
    'Notice.EndDate': '表示期間',
    'Notice.NoticePeriod': '掲示期間',
    'Notice.CreatedAt': '作成日',
    'Notice.CreatedBy': '作成者',
    'Notice.NoticeMainYn': '表示有無',
    'Notice.NoticeTypeCd': 'カテゴリー',
    'Notice.NoticeTopYn': 'リスト上段固定',
    'Notice.CompanyName': '会員社名',
    'Notice.BusinessRegNum': '事業所番号',
    'Notice.License': '入会商品',
    'Notice.Title.Company': '会員社情報',
    'Notice.Category.Placeholder': 'カテゴリー全体',
    'Notice.Target.Placeholder': '掲示対象全体',
    'Notice.NoticeMainYn.Placeholder': '表示有無全体',
    'Notice.TargetBusiness': '表示対象会員社',
    'Notice.FileInfo': '告示/イベントファイル情報',
    'Notice.NoBusinessMgs': '会員社未指定時、会員社全体に登録されます。',
    'Notice.Nationality': '入会者',
    'Notice.Nationality.All': '内/外国人全体',

    // コンテンツ管理 > ステーション利用案内
    'GuideLocation.Subject': 'タイトル',
    'GuideLocation.Subject.Placeholder': 'タイトル入力',
    'GuideLocation.UpdatedAt': '最終修正日',
    'GuideLocation.OpenYn': '表示有無',
    'GuideLocation.CreatedBy': '作成者',
    'GuideLocation.CreateInfo': 'アプリのコンテンツ管理登録情報', // JP
    'GuideLocation.FileInfo': 'アプリのコンテンツ管理ファイル情報', // JP
    'GuideLocation.Contents': '内容',
    'GuideLocation.Contents.Placeholder': '内容入力',
    'GuideLocation.Contents.creatorName': '作成者',
    'GuideLocation.Contents.Type': 'コンテンツのタイプ', // JP
    'GuideLocation.Contents.Type.PlaceHolder': 'コンテンツのタイプを選択', // JP

    // コンテンツ > 車種別利用案内
    'ModelInfo.Grade': 'グレード',
    'ModelInfo.ModelName': '車種',
    'ModelInfo.ModelID': 'モデル ID',
    'ModelInfo.OpenYn': '表示有無',
    'ModelInfo.Contents': '内容',
    'ModelInfo.UpdatedAt': '最終更新日',
    'ModelInfo.CreatorName': '作成者',
    'ModelInfo.Create.SubTitle.Info': '車種別利用案内登録情報',
    'ModelInfo.Create.SubTitle.File': '車種別利用案内ファイル情報',

    // コンテンツ管理 > サービス紹介/バナー/ポップアップ
    'BannerMgmt.Scene': 'バナー領域(シーン)',
    'BannerMgmt.Scene.Placeholder': 'バナー領域(シーン) 選択',
    'BannerMgmt.Scene.All': 'バナー領域(シーン) 全体',
    'BannerMgmt.Type': 'カテゴリー',
    'BannerMgmt.Type.All': 'カテゴリー全体',
    'BannerMgmt.OrderNum': '表示順序',
    'BannerMgmt.OrderNum.Placeholder': '表示順序入力',
    'BannerMgmt.UseYn': '使用有無',
    'BannerMgmt.Active.Period': '表示期間',
    'BannerMgmt.Title': 'サービス紹介/バナー/ポップアップ名',
    'BannerMgmt.Title.Placeholder': 'サービス紹介/バナー/ポップアップ名入力',
    'BannerMgmt.Url': '移動メニュー',
    'BannerMgmt.Url.Placeholder': '外部リンク入力(http://, https:// 含む)',
    'BannerMgmt.Image': 'サービス紹介/バナー/ポップアップ画像',
    'BannerMgmt.Image.Placeholder': '選択されたファイルがない',
    'BannerMgmt.LinkYn': 'リンク移動',
    'BannerMgmt.LinkY': '外部リンク',
    'BannerMgmt.LinkN': '使用しない',
    'BannerMgmt.InAppLink': '内部リンク',
    // 'BannerMgmt.Banner.Drawer.Desc':
    //     'バナー (ドロワー) 画像ファイル条件説明(840 px * 222 px / 1M 以下/ png, jpg, jpeg, gif / 最大1枚)',
    // 'BannerMgmt.Banner.Wiblezone.Desc':
    //     'バナー(ステーション) 画像ファイル条件説明(1005 px * 258 px / 1M 以下 / png, jpg, jpeg, gif / 最大1枚)',
    // 'BannerMgmt.Banner.CouponBook.Desc':
    //     'バナー(クーポンブック) 画像ファイル条件説明 (1005 px * 258 px / 1M 以下 / png, jpg, jpeg, gif / 最大1枚)',
    'BannerMgmt.Banner.Drawer.Desc':
        'バナー(ドロワー) 画像ファイル条件説明(714 px * 222 px / 1M 以下 / png, jpg, jpeg, gif / 最大1枚)',
    'BannerMgmt.Banner.Wiblezone.Desc':
        'バナー(ステーション) 画像ファイル条件説明(840 px * 222 px / 1M 以下 / png, jpg, jpeg, gif / 最大1枚)',
    'BannerMgmt.Banner.CouponBook.Desc':
        'バナー(クーポンブック) 画像ファイル条件説明 (840 px * 222 px / 1M 以下 / png, jpg, jpeg, gif / 最大1枚)',
    'BannerMgmt.Splash.Desc':
        'サービス紹介画像ファイル条件説明 (1236 px * 2334 px / 1M 以下/ png, jpg, jpeg, gif / 最大1枚)',
    'BannerMgmt.PopAdmin.Desc':
        'ポップアップ(アドミン) 画像ファイル条件説明(540 px * 540 px / 1M 以下 / png, jpg, jpeg, gif / 最大1枚)',
    'BannerMgmt.PopApp.Desc':
        'ポップアップ(アプリ) 画像ファイル条件説明(960 px * 1260 px / 1M 以下 / png, jpg, jpeg, gif / 最大1枚)',
    'BannerMgmt.Desc':
        '画像ファイル条件説明(1M 以下 / png, jpg, jpeg, gif / 最大1枚)',

    // コンテンツ管理 > お知らせテンプレート
    'MsgTemplate.MessageName': 'メッセージ名',
    'MsgTemplate.MessageName.Placeholder': 'メッセージ名を入力',
    'MsgTemplate.SendProgram': '送信プログラム',
    'MsgTemplate.MessagePurpose': 'メッセージ目的',
    'MsgTemplate.MessagePurpose.Placeholder': 'メッセージ目的入力',
    'MsgTemplate.MessageTemplate': 'メッセージテンプレート',
    'MsgTemplate.TargetReceiver': '受信対象',
    'MsgTemplate.TargetReceiver.Placeholder': '受信対象を入力',
    'MsgTemplate.UpdateHistory': '修正履歴',
    'MsgTemplate.MessageTypeCd': 'メッセージタイプ',
    'MsgTemplate.MessageTypeCd.All': 'メッセージタイプ全体',
    'MsgTemplate.MessageTypeCd.Select': 'メッセージタイプ選択',
    'MsgTemplate.MessageClassCd': 'メッセージ区分',
    'MsgTemplate.MessageClassCd.All': 'メッセージ区分全体',
    'MsgTemplate.AdmsgYn': '広告のお知らせ',
    'MsgTemplate.KakaoCode': 'LINEコード',
    'MsgTemplate.KakaoCode.Placeholder': 'LINEコードを入力する',
    'MsgTemplate.LinkButtonName': 'LINEボタン',
    'MsgTemplate.LinkButtonName.Placeholder': 'LINEボタン名入力',
    'MsgTemplate.LinkUrl': 'LINEリンクURL',
    'MsgTemplate.LinkUrl.Placeholder': 'リンク URLを入力',
    'MsgTemplate.SendPeriod': '伝達時刻',
    'MsgTemplate.SendPeriod.Placeholder': '伝達時刻を入力',
    'MsgTemplate.MessageContents': 'メッセージ内容 (例示)',
    'MsgTemplate.TemplateCode': 'テンプレートコード',
    'MsgTemplate.TemplateCode.Placeholder': 'テンプレートコードを入力',
    'MsgTemplate.MessageLang': '使用言語',
    'MsgTemplate.MessageLang.All': '使用言語全体',

    // コンテンツ管理 > お知らせ送信履歴
    'HistoryMsgTemplate.Type.AllSelect': 'メッセージ区分全体',
    'HistoryMsgTemplate.MessageType.AllSelect': 'メッセージタイプ全体',
    'HistoryMsgTemplate.MessageName.Placeholder': 'メッセージ名入力',
    'HistoryMsgTemplate.MessageName': 'メッセージ名',
    'HistoryMsgTemplate.Type': 'メッセージ区分',
    'HistoryMsgTemplate.MessageType': 'メッセージタイプ',
    'HistoryMsgTemplate.Status': '送信状態',
    'HistoryMsgTemplate.Purpose': 'メッセージ目的',
    'HistoryMsgTemplate.Content': 'メッセージ内容',
    'HistoryMsgTemplate.Content.Placeholder': 'メッセージ内容の入力', // JP
    'HistoryMsgTemplate.RetryCount': '再送信回数',
    'HistoryMsgTemplate.User': '貸出会員',
    'HistoryMsgTemplate.User.Placeholder': '貸出会員入力',

    // コンテンツ管理 > 手動メッセージ管理
    'PassiveMessage.CreatorName': '担当者',
    'PassiveMessage.MsgTitle': 'メッセージ名',
    'PassiveMessage.MsgTitle.Placeholder': 'メッセージ名を入力',
    'PassiveMessage.MsgTypeCd': 'メッセージ区分',
    'PassiveMessage.MsgTypeCd.All': 'メッセージ区分全体',
    // 'PassiveMessage.SendDate': '送信日時',
    'PassiveMessage.SendDay': '送信日',
    'PassiveMessage.NoticeTypeCd': 'お知らせタイプ',
    'PassiveMessage.NoticeTypeCd.All': 'お知らせタイプ全体',
    'PassiveMessage.MsgStatus': '送信状態',
    'PassiveMessage.MsgStatus.1': '予約',
    'PassiveMessage.MsgStatus.2': 'キャンセル',
    'PassiveMessage.MsgStatus.3': '完了',
    'PassiveMessage.MsgStatus.Cancel': '送信キャンセル',
    'PassiveMessage.MsgStatus.All': '送信状態すべて',
    'PassiveMessage.ExcelUpload': 'エクセルダウンロード',
    // 'PassiveMessage.Search.KeywordType': '区分',
    // 'PassiveMessage.Search.TimeGbn': '時間検索項目',
    // 'PassiveMessage.Search.TypeGbn': '検索項目',
    'PassiveMessage.SampleDownBtn': 'サンプルファイルダウンロード',
    'PassiveMessage.MsgContents': 'メッセージ内容',
    'PassiveMessage.MsgContents.TailText1':
        'メッセージ内の可変因子はエクセルで定義された #{col1}~#{col5}まで指定可能です。',
    'PassiveMessage.MsgContents.TailText2':
        '例) ようこそ#{col1}様#{col2}から案内メッセージが到着しました。 お問い合わせ事項は#{col3}に連絡してください。',
    'PassiveMessage.TargetReceiver': '受信対象',
    'PassiveMessage.INAPP.LINK': 'In App Link',
    'PassiveMessage.INAPP.LINK.Placeholder': 'In App Link 選択',
    'PassiveMessage.INAPP.LINK.PARAM': 'In App Link パラメータ',

    // コンテンツ管理 > 約款管理
    'Title.Terms.Agree': '約款同意',
    'Terms.UseYn': '使用有無',
    'Terms.TermsGroupID': '約款グループ名',
    'Terms.View.TermsGroupID': 'グループ',
    'Terms.View.TermsGroupID.All': 'グループ全体',
    'Terms.View.TermsGroupID.Select': 'グループ選択',
    'Terms.TermsVersion': '約款バージョン',
    'Terms.TermsName': '約款名',
    'Terms.TermsName.Placeholder': '約款名入力',
    'Terms.TermsAgreeYn': '同意必須有無',
    'Terms.View.TermsAgreeYn': '同意必須有無',
    'Terms.Search.TermsAgreeYn': '必須有無',
    'Terms.Search.TermsAgreeYn.All': '必須有無全体',
    'Terms.TermsMainYn': 'メイン約款有無',
    'Terms.TermsUrl': '約款 URL',
    'Terms.Type': 'タイプ',
    'Terms.View.Type': '約款タイプ',
    'Terms.View.Type.All': 'タイプ全体',
    'Terms.View.Type.Select': '約款タイプ選択',
    'Terms.ProviderId': '車両公給社ID',
    'Terms.LanguageCd': '言語コード',
    'Terms.CreateDate': '約款作成日',
    'Terms.UseDate': '約款適用期間',
    'Terms.View.UseDate': '適用開始時刻',
    'Terms.View.TermsClassID': '約款分類',
    'Terms.View.TermsClassID.Name': '約款分類名',
    'Terms.View.TermsClassID.Placeholder': '約款分類入力',
    'Terms.View.TermsClassID.All': '約款分類全体',
    'Terms.View.TermsClassID.Select': '約款分類選択',
    'Terms.TermsTemplate': '約款内容',
    'Terms.TermsHistory': '変更履歴',
    'Terms.View.UpdaterName': '最終変更者',
    'Terms.View.UpdatedAt': '最終変更者',
    'Terms.View.FileInfoTitle': '約款ファイル情報',
    'Terms.View.TabTitle.File': '約款ファイル表示',
    'Terms.View.TabTitle.History': '約款変更履歴',
    'Terms.Nationality': '入会者',
    'Terms.Nationality.All': '内/外国人全体',

    // 運営管理 > バージョン
    'AppVersion.AppTypeCd': 'アプリ分類',
    'AppVersion.AppTypeCd.Placeholder': 'アプリ分類選択',
    'AppVersion.AppPlatFormCd': 'OS',
    'AppVersion.AppPlatFormCd.Placeholder': 'OS 選択',
    'AppVersion.UseYn': '使用有無',
    'AppVersion.UseYn.Placeholder': '使用有無選択',
    'AppVersion.AppVersion': 'アプリバージョン',
    'AppVersion.BuildVersion': 'ビルドバージョン',
    'AppVersion.Description': 'アプリバージョン説明',
    'AppVersion.AppLink': 'アップデートリンク',
    'AppVersion.UpdateYn': 'アップデート有無',
    'AppVersion.UpdateYn.Placeholder': 'アップデート有無選択',
    'AppVersion.CreatedBy': '最終修正者',
    'AppVersion.CreateAt': '最終修正日',
    'AppVersion.UpdateMessage': 'アップデートメッセージ',

    // 運営管理 > アドミン
    'Operator.UserName.Placeholder': '名前入力',
    'Operator.Email.Placeholder': '使用者ID 入力',
    'Operator.MobileNumber.Placeholder': '電話番号入力',
    'Operator.RoleName.AllSelect': '役割全体',
    'Operator.ActivationStatusCd.AllSelect': '状態全体',
    'Operator.UserName': '名前',
    'Operator.MobileNumber': '電話番号',
    'Operator.Email': '使用者ID',
    'Operator.SystemType': '所属',
    'Operator.SystemType.All': '所属全体',
    'Operator.SystemType.Wible': 'MOCEAN',
    'Operator.SystemType.Partner': '委託社',
    'Operator.SystemType.Partner.Select': '委託社選択',
    // 'Operator.SystemType.PartnerName': '委託社名',
    'Operator.Role': '役割',
    'Operator.RoleDetail': '役割システム',
    'Operator.DenyReason': '却下理由',
    'Operator.DenyReason.Placeholder': '却下理由を入力',
    'Operator.RoleSelect.Placeholder': '役割選択',
    'Operator.StatusSelect.Placeholder': '状態選択',

    // 運営管理 > 委託社
    'BusinessPartner.ID': '委託社ID',
    'BusinessPartner.Name': '委託者名',
    'BusinessPartner.BusinessRegNum': '事業所番号',
    'BusinessPartner.Address': '住所',
    'BusinessPartner.DetailAddress': '住所詳細',
    'BusinessPartner.DetailAddress.Placeholder': '詳しい住所を入力',
    'BusinessPartner.ManagerName': '代表者名',
    'BusinessPartner.ConsignmentScope': '委託範囲',
    'BusinessPartner.Provider': '供給社',
    'BusinessPartner.ManagerMobileNumber': '電話番号',
    'BusinessPartner.ManagerEmail': '電子メール',
    'BusinessPartner.BankName': '銀行名',
    'BusinessPartner.AccountNumber': '口座番号',
    'BusinessPartner.AccountHolder': '名義人',
    'BusinessPartner.AccountNumberFile': '通帳コピー',
    'BusinessPartner.BusinessRegNumFile': '登録事業所証明書',
    'BusinessPartner.BusinessRegNumFileName': '登録事業所証明書ファイル名',
    'BusinessPartner.createdAt': '作成日',
    'BusinessPartner.updatedAt': '修正日',
    'BusinessPartner.CreatedBy': '作成者',
    'BusinessPartner.TypeCd': '分類',
    'BusinessPartner.UseYn': '使用有無',
    'BusinessPartner.UseYn.All': '使用有無全体',
    'BusinessPartner.Description': 'メモ',
    'BusinessPartner.Name.Placeholder': '委託社名入力',
    'BusinessPartner.ManagerName.Placeholder': '代表者名入力',
    'BusinessPartner.ManagerMobileNumber.Placeholder': '電話番号入力',
    'BusinessPartner.BusinessRegNum.Placeholder': '事業所番号入力',
    'BusinessPartner.BankName.Placeholder': '銀行名入力',
    'BusinessPartner.AccountHolder.Placeholder': '預金名義入力',
    'BusinessPartner.AccountNumber.Placeholder': '口座番号入力',
    'BusinessPartner.Description.Placeholder': 'メモ入力',
    'BusinessPartner.Btn.Add.Provider': '供給社追加',
    'BusinessPartner.Btn.Delete.Provider': '供給社削除',

    // 運営管理 > Greener 政策
    'GreenerPolicy.GradeName': 'レベル区分',
    'GreenerPolicy.MonthlyMileage': '月平均マイレージ(km 以上)',
    'GreenerPolicy.GoodsUseAt': '商品利用有無',
    'GreenerPolicy.DiscountPrice': 'km当たり割引額 (円)',

    // カスタマーセンター > カスタマーセンター告示
    'CSCenterNotice.NoticeMainYn': '表示有無',
    'CSCenterNotice.PopupNoticeMainYn': 'ポップアップ表示有無',
    'CSCenterNotice.PopupNoticeMainYn.All': 'ポップアップ表示有無全体',
    'CSCenterNotice.NoticeTypeCd': '告示タイプ',
    'CSCenterNotice.NoticeTypeCd.Placeholder': '告示タイプ選択',
    'CSCenterNotice.NoticeTypeCd.All': '告示タイプ全体',
    'CSCenterNotice.CreatedAt': '作成日',
    'CSCenterNotice.ManagerName': '担当者',
    'CSCenterNotice.Name': '名前',
    'CSCenterNotice.ManagerName.Placeholder': '担当者入力',
    'CSCenterNotice.ManagerEmail': '電子メール',
    'CSCenterNotice.ManagerEmail.Placeholder': '電子メール入力',
    'CSCenterNotice.ManagerPhoneNumber': '電話番号',
    'CSCenterNotice.ManagerPhoneNumber.Placeholder': '電話番号入力',
    'CSCenterNotice.NoticePeriod': '掲示期間',
    'CSCenterNotice.PromotionFg': '区分',
    'CSCenterNotice.PromotionFgEtcDtl': 'その他内訳',
    'CSCenterNotice.PromotionFgEtcDtl.Placeholder': 'その他入力',
    'CSCenterNotice.MarketingTypeEtc.Placeholder': '区分入力',
    'CSCenterNotice.MarketingPurposeEtc.Placeholder': '目的入力',
    'CSCenterNotice.PurposeFg': '目的',
    'CSCenterNotice.PurposeFgEtcDtl': 'その他内訳',
    'CSCenterNotice.PurposeFgEtcDtl.Placeholder': 'その他入力',
    'CSCenterNotice.MarketingName': 'マーケティング名',
    'CSCenterNotice.MarketingName.Placeholder': 'マーケティング名入力',
    'CSCenterNotice.Synopsis': '概要',
    'CSCenterNotice.Synopsis.Placeholder': '概要入力',
    'CSCenterNotice.Schedule': '日程',
    'CSCenterNotice.Schedule.Placeholder': '日程入力',
    'CSCenterNotice.AttendCondition': '参加条件',
    'CSCenterNotice.AttendCondition.Placeholder': '参加条件入力',
    'CSCenterNotice.Benefit': '特典/支給方法',
    'CSCenterNotice.Benefit.Placeholder': '特典/支給方法入力',
    'CSCenterNotice.HonorarySchedule': '広報/チャネル日程',
    'CSCenterNotice.DetailDtl': '詳細',
    'CSCenterNotice.DetailDtl.Placeholder': '詳細入力',
    'CSCenterNotice.Contents': '告示内容',
    'CSCenterNotice.Contents.Placeholder': '告示内容入力',
    'CSCenterNotice.Subject': '告示事項タイトル',
    'CSCenterNotice.Subject.Placeholder': '告示事項タイトル入力',
    'CSCenterNotice.Empty.Type.Etc':
        '区分項目のその他の事項を入力してください。',
    'CSCenterNotice.Empty.Purpose.Etc':
        '目的項目のその他の事項を入力してください。',

    // カスタマーセンター > 1:1相談
    'VocMgmt.Title.History': '相談履歴',
    'VocMgmt.Category': '相談分類',
    'VocMgmt.Info': '相談情報',
    'VocMgmt.Response': '相談処理',
    'VocMgmt.History': '処理履歴',
    'VocMgmt.Category.All': '相談分類全体',
    'VocMgmt.Category.Placeholder': '相談分類選択',
    'VocMgmt.Result': '処理結果',
    'VocMgmt.Result.All': '処理結果　全体',
    'VocMgmt.Assignee.Name': '相談者名',
    'VocMgmt.UserID': '会員 ID',
    'VocMgmt.UserID.Placeholder': '会員 ID 入力',
    'VocMgmt.Comment': 'お問い合わせ内容',
    'VocMgmt.CommentTitle': '処理内容',
    'VocMgmt.CreateDate': '相談日時',
    'VocMgmt.UpdateDate': '処理日時',
    'VocMgmt.Coupon.Select': 'クーポン発行',
    'VocMgmt.Coupon.Select.Placeholder': 'クーポン選択',
    'VocMgmt.User.Name': '貸出番号',
    //'Vehicle.LicensePlateNumber': '車両番号',
    'VocMgmt.Message.CouponComment':
        '* 発行されたクーポンは回収できません。注意してください。',
    'VocMgmt.Message.NoVocHistory': '相談履歴がございません。',
    'VocMgmt.RenterPhoneNumber.Placeholder': '連絡先入力',
    'VocMgmt.Option.Title': '貸出(サブスクリプション)番号',
    'VocMgmt.Option.Reservation': '貸出番号検索',
    'VocMgmt.Reservation.Placeholder': '貸出番号検索',
    'VocMgmt.Option.Subscribe': 'サブスクリプション番号検索',
    'VocMgmt.RenterName.Prefix': '( 貸出者名前:',
    'VocMgmt.RenterName.Suffix': ')',
    'VocMgmt.VocID': '相談番号',
    'VocMgmt.IssuedCoupon': '発給されたクーポン',

    // VOC 処理結果コード
    'VocMgmt.Stats': '昨日 {yesterday}件 | 計 {total}件',
    'VocMgmt.Code.Status.Wait': '待機中',
    'VocMgmt.Code.Status.Work': '処理中',
    'VocMgmt.Code.Status.Hold': '保留',
    'VocMgmt.Code.Status.Etc': 'その他',
    'VocMgmt.Code.Status.Complete': '完了',

    // カスタマーセンター > FAQ
    'FaqMgmt.Category': 'カテゴリー',
    'FaqMgmt.Category.Main': '大分類カテゴリー',
    'FaqMgmt.Category.Main.All': '大分類カテゴリー全体',
    'FaqMgmt.Category.Main.Select': '大分類カテゴリー選択',
    'FaqMgmt.Category.Sub': '小分類カテゴリー',
    'FaqMgmt.Category.Sub.All': '小分類カテゴリー全体',
    'FaqMgmt.Category.Sub.Select': '小分類カテゴリー選択',
    'FaqMgmt.FaqTitle': 'タイトル',
    'FaqMgmt.FaqTitle.Placeholder': 'タイトル入力',
    'FaqMgmt.UseYn': '表示有無',
    'FaqMgmt.TextYn': '作成タイプ',
    'FaqMgmt.Writer': '作成者',
    'FaqMgmt.question': '質問',
    'FaqMgmt.Answer': '回答',
    'FaqMgmt.ViewCount': '照会数',
    'FaqMgmt.Text': 'TEXT',
    'FaqMgmt.Html': 'HTML',
    'FaqMgmt.Title.Placeholder': 'タイトル入力',
    'FaqMgmt.Create.Title.Placeholder': 'タイトル (質問) 入力',
    'FaqMgmt.FaqCode': 'レンディングキー',
    'FaqMgmt.FaqCode.Placeholder': 'レンディングキー',

    // カスタマーセンター > 担当者お問い合わせ
    'ContactManager.ChargerName.Placeholder': '担当者名入力',
    'ContactManager.ChargeRole.Placeholder': '担当役割入力',
    'ContactManager.ChargerName': '担当者名',
    'ContactManager.ChargeRole': '担当役割',
    'ContactManager.Email': '電子メール',

    // システム管理 > 権限（バーミッション）
    'Role.RuleInfo': '役割情報',
    'Role.SystemType': 'システム',
    'Role.SystemType.Placeholder': 'システムを入力',
    'Role.RoleKey': '役割キー',
    'Role.RoleKey.Placeholder': '役割キーを入力',
    'Role.Name': '役割名',
    'Role.Name.Placeholder': '役割名を入力',
    'Role.Note': '説明',
    'Role.Note.Placeholder': '説明を入力',
    'Role.Privacy.Officer': 'プライバシー担当者',
    'Role.SubMenu.Name': 'メニュー名',
    'Role.SubMenu.InquiryYn': '読む',
    'Role.SubMenu.SaveYn': '書く',
    'Role.SubMenu.DelYn': '削除',
    'Role.SubMenu.DownYn': 'ダウンロード',
    'Role.LandingUrl': 'Landing Url',
    'Role.LandingUrl.Placeholder': 'Landing Url 入力',

    // システム管理 > コード
    'CodeManage.CodeType': 'タイプ',
    'CodeManage.UseYn': '表示有無',
    'CodeManage.Name': 'コード名',
    'CodeManage.Name.Placeholder': 'コード名を入力',
    'CodeManage.MsgKey': 'メッセージキー',
    'CodeManage.MsgKey.Placeholder': 'コードメッセージキーを入力',
    'CodeManage.CodeValue': 'コード値',
    'CodeManage.CodeValue.Placeholder': 'コード値を入力',
    'CodeManage.CodeValue.Int': 'コード値(数字型)',
    'CodeManage.CodeValue.Int.Placeholder': 'コード値(数字型)を入力',
    'CodeManage.GroupKey': 'グループキー',
    'CodeManage.GroupKey.Placeholder': 'グループキー値を入力',
    'CodeManage.OrderNum': '優先順位',
    'CodeManage.OrderNum.Placeholder': '優先順位値を入力',
    'CodeManage.Description': 'コード説明',
    'CodeManage.Description.Placeholder': 'コード説明を入力',

    // システム管理 > メニュー
    'Title.MenuManage.View': 'メニュー情報',
    'MenuManage.MsgKey': 'メニューキー',
    'MenuManage.MsgKey.Placeholder': 'メニューキーを入力',
    'MenuManage.Name': 'メニュー名',
    'MenuManage.Name.Placeholder': 'メニュー名を入力',
    'MenuManage.UrlType': 'メニュータイプ',
    'MenuManage.UrlType.Placeholder': 'メニュータイプを選択',
    'MenuManage.SystemType': 'システム',
    'MenuManage.SystemType.Placeholder': 'システム選択',
    'MenuManage.UseYn': '表示有無',
    'MenuManage.MenuUrl': 'メニュー経路',
    'MenuManage.MenuUrl.Placeholder': 'メニュー経路を入力',
    'MenuManage.OrderNum': '優先順位',
    'MenuManage.OrderNum.Placeholder': '優先順位を入力',
    'MenuManage.AclYn': '権限（バーミッション）有無',
    'MenuManage.ProgramSearch': 'プログラム追加/削除',
    'MenuManage.MenuType': 'メニュータイプ',
    'MenuManage.BusinessMsgKey': '会員社メニューキー',
    'MenuManage.BusinessMsgKey.Placeholder': '会員社メニューキーを入力',
    'MenuManage.BusinessMenuName': '会員社メニュー名',
    'MenuManage.BusinessMenuName.Placeholder':
        '会員社メニュー名を入力してください。',
    'MenuManage.SearchTitle': '照会対象',
    'MenuManage.SearchKeyword.Placeholder': 'プログラム名or API URL 入力',

    // システム管理 > プログラム
    'Title.Program.Create': 'プログラム登録',
    'Program.Name': 'プログラム名',
    'Program.ApiUrl': 'API URL',
    'Program.Action': '機能',
    'Program.Name.Placeholder': 'プログラム名入力',
    'Program.URL.Placeholder': 'API URL 入力',
    'Program.SearchKeyword': 'プログラム名 or API URL 入力',
    'Program.SearchKeyword.Placeholder': 'プログラム名 or API URL 入力',
    'Program.Alert.Create.Action.No.Selected':
        '登録したプログラム機能を選択してください。',

    // システム管理 > 配置
    'Batch.Date.Last.Operation': '最終作業遂行日時',
    'Batch.Cron.Expr': 'スケジューリング (Cron Expr)',
    'Batch.Job.Back.Off.Count': '再始動回数',
    'Batch.Job.Back.Off.Time': '再始動 Interval(秒)',
    'Batch.Job.Name': '配置名',
    'Batch.Job.Name.Placeholder': '配置名入力',
    'Batch.Job.Desc': '配置作業詳細',
    'Batch.Manual.Processing': '手動処理',
    'Batch.Scheduling': 'スケジューリング',
    'Batch.Run.Alert.No.Checked': '手動処理する作業を選択してください。',
    'Batch.Run.Confirm': '手動実行しますか?',
    'Batch.Run.Success': '手動実行しました。',
    'Batch.Run.Failure':
        '手動実行できませんでした。再度確認してから行ってください。',
    'Batch.Search.Info.Help':
        '* スムーズな検索のために検索期間は最大6ヶ月を推奨します。',

    'Batch.Categories': '区分',
    'Batch.Date.Occurrence': '実行開始日時',

    // 共通 or 決済側(臨時)
    'Team.paymentMethod': '決済手段',
    'Team.License': '入会商品',

    // 供給社管理
    'Provider.Name': '会社名',
    'Provider.VehicleCnt': '車両台数', // JP
    'Provider.BusinessRegNum': '登録事業所番号',
    'Provider.Contract.Period': '契約期間', // JP
    'Provider.createdAt': '作成日', // JP
    'Provider.UseYn': '使用有無',
    'Provider.Name.Placeholder': '供給社名を入力', // JP
    'Provider.BusinessRegNum.Placeholder': '登録事業所番号を入力', // JP
    'Provider.Provider.Name': '供給社名', // JP
    'Provider.Provider.Name.Placeholder': '供給社名を入力', // JP
    'Provider.Registration': '事業者登録証', // JP
    'Provider.ManagerMobileNumber': '電話番号',
    'Provider.Address': '住所',
    'Provider.Address.Detail': '詳しい住所',
    'Provider.BankName': '銀行名',
    'Provider.BankName.Placeholder': '銀行選択', // JP
    'Provider.AccountNumber': '口座番号',
    'Provider.AccountNumber.Placeholder': '口座番号を入力', // JP
    'Provider.AccountHolder': '名義人',
    'Provider.AccountHolder.Placeholder': '名義人の入力', // JP
    'Provider.BankBookCopy': '通帳コピー', // JP
    'Provider.CalculateType': '供給社 精算方法', // JP
    'Provider.CalculateType.Placeholder': '精算方法を入力', // JP
    'Provider.Contract.PaperReg': '契約書を登録', // JP
    'Provider.ManagerName': '代表者名',
    'Provider.ManagerEmail': '電子メール',
    'Provider.Location': '代表ステーション',
    'Provider.Location.Placeholder': '代表ステーション選択',
    'Provider.AccountNumberFile': '口座番号ファイル',
    'Provider.BusinessRegNumFile': '登録事業所番号',
    'Provider.BusinessRegNumFileName': '登録事業所番号',
    'Provider.CarNo': '車両番号', // JP
    'Provider.VIN': '車台番号', // JP
    'Provider.Year': '車両様式', // JP
    'Provider.VehicleModel': '車種', // JP
    'Provider.FuelType': '燃料タイプ', // JP
    'Provider.Grade': 'グレード', // JP
    'Provider.Mid': 'MID', // JP
    'Provider.Brand': '製造社', // JP
    'Provider.VehicleInfo.List': '供給社　車両情報リスト', // JP
    'Provider.AddressDetail.Placeholder': '詳しい住所を入力', // JP
    'Provider.updatedAt': '修正日',
    'Provider.CreatedBy': '作成者',

    // マイページ
    'MyInfo.User': '個人情報',
    'MyInfo.Business': 'ビジネスプロフィール情報',
    'MyInfo.Business.YN': 'ビジネスプロフィール有無',
    'MyInfo.Business.Code': 'ビジネスプロフィール認証コード',
    'MyInfo.Business.Info': '会員社情報',
    'MyInfo.Business.Department': '部署情報',
    'MyInfo.Business.Email': '会社電子メール',
    'MyInfo.TransferRole': '権限（バーミッション）移管',
    'MyInfo.TransferRole.BusinessManager':
        'スーパー管理者 権限（バーミッション）移管',
    'MyInfo.TransferRole.BusinessOperator':
        '部署担当者 権限（バーミッション）移管',
    'MyInfo.Business.Delete': 'ビジネスプロフィール削除',
    'MyInfo.BusinessManager.Now': '現スーパー管理者',
    'MyInfo.BusinessManager.Change': '変更スーパー管理者',
    'MyInfo.BusinessOperator.Now': '現部署担当者',
    'MyInfo.BusinessOperator.Change': '変更部署担当者',
    'MyInfo.Transfer': '権限（バーミッション）付与',
    'MyInfo.Delete.Possible': 'プロフィール削除案内',
    'MyInfo.Delete.Impossible': 'プロフィール削除不可案内',
    'MyInfo.Biz.Delete': 'Biz Solution 退会',
    'MyInfo.Biz.Delete.Message': `MOCEAN FMSからの退会を行います。このまま実行しますか?`,
    'MyInfo.Business.Delete.Message': `削除時{businessName}の業務用車両利用はできません。
        継続して削除を実行しますか?`,
    'MyInfo.BusinessManager.Transfer.Message01': `<span>{businessName}</span>のスーパー管理者 権限（バーミッション）を企業内の他の役職員に移管します。`,
    // TODO 該当フレーズ削除要望
    'MyInfo.BusinessManager.Transfer.Message02': ` 権限（バーミッション）を引き継ぐ役職員がMOCEAN Car Sharingに接続して'承認'ボタンを押すと企業管理者 権限（バーミッション）が移管適用されます。`,
    'MyInfo.BusinessOperator.Transfer.Message01': `<span>{departmentName}</span>の部署担当者 権限（バーミッション）を部署内のほかの部署員に移管します。 `,
    // TODO 該当フレーズ削除要望
    'MyInfo.BusinessOperator.Transfer.Message02': `権限（バーミッション）を引き継ぐ役職員がMOCEAN Car Sharingに接続して '保存'ボタンを押すと部署担当者 権限（バーミッション）が移管適用されます。`,

    // 契約情報照会
    'BusinessContract.ContractDownload': '契約書照会',
    'BusinessContract.ContractDownload.Popup': '契約書',
    'BusinessContract.Contract.Name': '会員社名',
    'BusinessContract.Contract.SuperAdmin': 'スーパー管理者',
    'BusinessContract.Contract.Type': '契約商品',
    'BusinessContract.Contract.Vehicle': '契約車両',
    'BusinessContract.Contract.WibleZone': '契約ステーション',
    'BusinessContract.Contract.Period': '契約期間',
    'BusinessContract.Contract.EndTime': '満了予定日',
    'BusinessContract.Contract.Extension': '延長申請',
    'BusinessContract.Vehicle.Name': '契約車両リスト',
    'BusinessContract.State': '契約状態',
    'BusinessContract.State.R': '登録',
    'BusinessContract.State.Y': '予定',
    'BusinessContract.State.E': '満了',
    'BusinessContract.Contract.Date': `契約日 :`,
    'BusinessContract.Contract.End.Remain1': `(満了日まで `,
    'BusinessContract.Contract.End.Remain2': `日残りました。)`,
    'BusinessContract.SuperAdmin':
        '{userName}|{employeeRegNum}|{departmentName}|{companyEmail}',
    'BusinessContract.Default.Question': '契約延長の相談を要請します。',

    'Role.MOBILITY_MANAGER': 'MOCEAN',
    'Role.MOBILITY_MANAGER.Partner': '委託業者',
    'Role.BUSINESS_MANAGER': 'スーパー管理者',
    'Role.BUSINESS_OPERATOR': '部署担当者',

    'Dashboard.Contract.Desc.1': '契約満了',
    'Dashboard.Contract.Desc.2': '日前',
    'Dashboard.Contract.Desc.3': 'です。',
    'Dashboard.Contract.Help':
        '· 契約に関するお問い合わせ事項はカスタマーセンター(1234-1234)にお問い合わせください。',
    'Dashboard.Contract.ShortCut': 'ショートカット',
    'Dashboard.Contract.Product': '契約情報',
    'Dashboard.Contract.Product.Not': '契約情報なし',
    'Dashboard.UsageDepartmentTop5.Title': '利用部署 TOP5',
    'Dashboard.UseSum.Title': '利用額',
    'Dashboard.AccidentOccurring.Title': '事故発生',
    'Dashboard.UseMemberTop5.Title': '利用会員 TOP5',
    'Dashboard.RentalNumber.Count': '貸出件数',
    'Dashboard.Compare.Before.Month': '先月対比',
    'Dashboard.EcoReport.Title': 'エコレポート',
    'Dashboard.EcoReport.Desc.1': '貴社と共に減らした炭素は',
    'Dashboard.EcoReport.Desc.2': '約',
    'Dashboard.EcoReport.Desc.Ton': 'トン',
    'Dashboard.EcoReport.Desc.3': 'です。',
    'Dashboard.WeekUsing.Title': '曜日別利用状況 (件)',
    'Dashboard.TimeUsing.Title': '時間別利用状況 (平均)',
    'Dashboard.Noti.Alram.Title':
        '貴社の MOCEAN Car Sharing 利用状況を一目で確認できます! :)',
    'Dashboard.CreatedAt': '最新バージョン {value}',
    'Dashboard.EmptyMsg': '会員社を選択してください。.',
    'Dashboard.TabMenu.Monthly': '月別',
    'Dashboard.TabMenu.Period': '特定期間',
    'Dashboard.Common.Accumulate': '累積',
    'Dashboard.Common.RentalsNumber': '貸出件数',
    'Dashboard.Common.RentalsTime': '貸出時間 (hour)',
    'Dashboard.Common.RealUseTime': '実際の使用時間 (hour)',
    'Dashboard.Common.RentalsNumberRate': '貸出件数基準準稼働率',
    'Dashboard.Common.RentalsTimeRate': '貸出時間基準稼働率',
    'Dashboard.Common.ProductRentalsNumber': '商品別貸出件数',
    'Dashboard.SubTitle.Member': '会員状況',
    'Dashboard.SubTitle.Member.Join': '入会',
    'Dashboard.SubTitle.Member.RegularJoin': '正入会',
    'Dashboard.SubTitle.Member.BusinessProfileCreate':
        'ビジネスプロフィール作成',
    'Dashboard.SubTitle.Member.Secession': '退会',
    'Dashboard.SubTitle.AppInstall': 'アプリ設置',
    'Dashboard.SubTitle.AppInstall.AndroidInstall': 'AOS 設置',
    'Dashboard.SubTitle.AppInstall.IOSInstall': 'iOS 設置',
    'Dashboard.SubTitle.AppActivate': 'アプリ活性使用者',
    'Dashboard.SubTitle.AppActivate.Statistics':
        'DAU: {DAU} | MAU: {MAU} | Stickiness: {Stickiness}%',
    'Dashboard.SubTitle.AppActivate.AOS': 'AOS',
    'Dashboard.SubTitle.AppActivate.IOS': 'iOS',
    'Dashboard.SubTitle.AppActivate.Chart.Header':
        '全体: {total} | AOS: {AOS} | IOS: {IOS}',
    'Dashboard.SubTitle.BusinessProfileUsage': 'ビジネスプロフィール利用状況',
    'Dashboard.SubTitle.PersonalProfileUsage': '個人プロフィール利用状況',
    'Dashboard.SubTitle.CommonUsage': '共通利用状況',
    'Dashboard.SubTitle.CommonUsage.Title.Business': '業務用',
    'Dashboard.SubTitle.CommonUsage.Title.Personal': '個人用',
    'Dashboard.SubTitle.CommonUsage.Week.Title': '曜日別利用状況 (件)',
    'Dashboard.SubTitle.CommonUsage.Week.Chart.Header': '{value}件',
    'Dashboard.SubTitle.CommonUsage.TimeUsing.Title': '時間別利用状況',
    'Dashboard.SubTitle.CommonUsage.CompanyTop5.Title':
        '会員社別利用順位 Best5',
    'Dashboard.SubTitle.CommonUsage.CompanyTop5.Business': 'ビジネス',
    'Dashboard.SubTitle.CommonUsage.CompanyTop5.Personal': '個人',
    'Dashboard.SubTitle.CommonUsage.CompanyTop5.Profile': 'プロフィール',
    'Dashboard.SubTitle.SalesUsage': '売上利用状況',
    'Dashboard.SubTitle.SalesUsage.RentalsFee': '貸出料金 (万円)',
    'Dashboard.SubTitle.SalesUsage.DrivingFee': '走行料金 (万円)',
    'Dashboard.SubTitle.SalesUsage.OtherSales': 'その他売上',
    'Dashboard.SubTitle.CustomerService': '顧客応対状況',
    'Dashboard.SubTitle.CustomerService.VocReceiptNumber': '1:1 相談受付件数',
    'Dashboard.SubTitle.CustomerService.VehicleRepairNumber': '事故受付件数',

    // ----------------------- Phase 2 ---------------------------------------------

    'CustomerUser.NormalUser': '一般会員',
    'CustomerUser.WithdrawUser': '退会済み',
    'CustomerUser.WithdrawUser.Detail': '退会会員詳細',
    'CustomerUser.UseYN': '利用停止有無',
    'CustomerUser.UseYN.All': '利用停止有無全体',
    'CustomerUser.DriverCertificate': '運転経歴証明書',
    'CustomerUser.DriverCertificate.FileName': '運転経歴証明書-{name}',
    'CustomerUser.WithdrawDate': '退会日',
    'CustomerUser.WithdrawReason': '退会理由',
    'CustomerUser.Path': '入会経路',
    'CustomerUser.Alim': 'サービス利用情報お知らせ',
    'CustomerUser.RepairCnt': '事故履歴',
    'CustomerUser.DeleteDriverLicense.Confirm.Message':
        '免許情報を削除しますか?',
    'CustomerUser.DeleteDriverLicense.Success.Message':
        '免許情報が削除されました。再登録まで車両貸出及び利用できません。',
    'CustomerUser.DeleteDriverLicense.Failure.Message':
        '免許情報を削除できません。',
    'CustomerUser.DriverDeletedDate.Message.1': `免許情報削除日時: {driverDeletedDate}`,
    'CustomerUser.DriverDeletedDate.Message.2': `<span>免許情報の再登録が必要です。</span>`,

    'Btn.VehicleRepair.Accident.Status.Update.2': '処理中',
    'Btn.VehicleRepair.Accident.Status.Update.4': '請求する',
    'Btn.VehicleRepair.Accident.Status.Update.8': '処理完了',

    // モバイルページ用
    'MobilePage.Search.Title': '検索',

    //反則金の追加
    'Title.SalesMgmt.Violation.Detail': '反則金詳細',
    'Title.Violation.Transfer.Dialog': '移管申請書　送信',
    'Violation.Transfer.SubTitle.Trans': '送信設定',
    'Violation.Transfer.SubTitle.Docu': '添付書類',
    'Violation.Transfer.Type': '送信方法',
    'Violation.Transfer.TransTarget': '受信者情報',
    'Violation.Transfer.Email': 'E-Mail',
    'Violation.Transfer.Fax': 'Fax',
    'Violation.Transfer.Confirm': '移管申請書が送信されました。',

    //売上
    'Sales.CalculateStatus': '精算区分',
    'Sales.CalculateStatus.Placeholder': '精算区分全体',
    'Sales.ModelName.Placeholder': '車種入力',
    'Sales.SubTitle.Sales': '売上情報',
    'Sales.SubTitle.Calculate': '精算情報',
    'Sales.SubTitle.User': '会員情報',
    'Sales.SubTitle.Subscribe': 'サブスクリプション情報',
    'Sales.SubTitle.Reservation': '貸出情報',
    'Sales.SubTitle.BusinessContract': '会員社契約情報',
    'Sales.SubTitle.SalesDetail': '売上詳細',
    'Sales.BusinessEmail': '会社電子メール',
    'Sales.BusinessName.Title': '会員社名',

    'Sales.TollFee.Title': '通行料金',
    'Sales.delayFee.Title': '遅延料金',

    'Sales.EtcFee': 'その他',
    'Sales.Memo': 'メモ',
    'Sales.MemberShip.Charge': 'メンバーシップ料金',

    'Sales.CalculateStatus.Payment': '精算完了',
    'Sales.CalculateStatus.Cancel': '決済キャンセル',
    'Sales.CalculateStatus.Claim': '請求完了',
    'Sales.CalculateStatus.ExactCalculation': '別途精算',
    'Sales.CalculateStatus.Refund': '精算完了 (再決済)',

    'Business.Delay.IsTarget': '遅延料金賦課',
    'Business.IsTarget': '賦課の有無',
    'Business.Target': '賦課',
    'Business.NoTarget': '賦課しない',
    'Business.CliamMethod.ExactCalculation': '別途精算',
    'Business.CliamMethod.ManualCalculation': '手書き精算',
    'Business.CliamMethod.CaseByCase': '件別精算',
    'Business.CliamMethod.CardAuto': '月別精算(カード自動請求)',
    'Business.IsAllowCardRegist': '役職員カード直接登録',
    'Business.Mid.Placeholder': '会員社 MID 選択',
    'Business.Mid': 'MID',
    'Business.remainTime': '今月残りの約定期間',
    'Business.remainTimeInfo': '{hours}時間 {minutes}分 / {engagementTime}時間',

    // 車両管理 > 維持管理/整備履歴
    'Location.WibleZone.CountVehicle': 'ステーションマイカー : {count}',

    'Common.YN.Allow': '許可',
    'Common.YN.NotAllow': '許可しない',

    'Code.Maintenance.MaintenanceItemCd.IN': '内部洗車',
    'Code.Maintenance.MaintenanceItemCd.OUT': '外部洗車',
    'Code.Maintenance.MaintenanceItemCd.CHARGING': '充電',
    'Code.Maintenance.MaintenanceItemCd.OIL': '充填',
    'Code.Maintenance.MaintenanceItemCd.CHECK': '消耗品点検',
    'Code.Maintenance.MaintenanceItemCd.EXCHANGE': '消耗品交換',
    'Code.Maintenance.MaintenanceItemCd.VEHICLE': '車両移動',
    'Code.Maintenance.MaintenanceItemCd.TC': 'その他',

    // サブスクリプション内訳
    'Subscribe.InsuranceFee': '保険料(自己負担金)',
    'Subscribe.SubscribeRound': '回次',
    'Subscribe.SubscribeRound.Period': '回次期間',
    'Subscribe.SubscribeMonth.All': '月次全体',
    'Subscribe.Additional.Info': '延長商品情報',
    'Subscribe.Additional.Payment.Fee': '延長商品決済金額',
    'Subscribe.Additional.Payment.Status': '延長商品決済状態',
    'Subscribe.Cancel.Info': 'サブスクリプションキャンセル情報',
    'Subscribe.Cancel.Round': 'キャンセル適用回次',
    'Subscribe.CancelDate': 'サブスクリプションキャンセル日',
    'Subscribe.Cancel.Period': '{start}回次から ~ {end}回次まで',
    'Subscribe.Discount.Return.Charge.List': '割引返還金照会',
    'Subscribe.Discount.Return.Charge.Info': '割引返還金案内',
    'Subscribe.Discount.Return.Charge.Total': '総割引返還金: {value}円',
    'Subscribe.Discount.Return.Charge.Total.Green':
        '総割引返還金: <span>{value}</span>円',
    'Subscribe.Discount.Return.Charge.Null': '割引返還金はございません。',
    'Subscribe.DiscountRefundPay.comment': '回別割引返還金は以下の通りです。',
    'Subscribe.InsuranceFee.Value':
        '{insuranceFee}円 (自己負担金 {selfCost}円)',

    // 利用内訳書
    'RD.Usagebill.CARD_AUTO': '{authDate} 月別精算-カード自動請求', // 月別精算-カード自動請求
    'RD.Usagebill.EXACT_CALCULATION': '{authDate} 別途精算', // 別途精算
    'RD.Usagebill.CASE_BY_CARD': '{authDate} 決済完了 ({cardName})', // 1. 件別精算 – 請求状態 X, 決済状態のみ存在
    'RD.Usagebill.CASE_BY_CARD_NO_CARD': '{authDate} 決済完了', // 2. 件別精算 – 請求状態 X, 決済状態のみ存在(cardName X)
    'RD.Usagebill.MANUAL_CALCULATION': '{authDate} 手書き精算', // 手書き精算なし
    'RD.Usagebill.NON_PAYMENT': '{authDate} 未払い ({cardName})', // 1. 未払い
    'RD.Usagebill.NON_PAYMENT_NO_CARD': '{authDate} 未払い', // 2. 未払い(cardName X)
    'RD.Usagebill.CANCELED': '{authDate} キャンセル ({cardName})', // 1. キャンセル
    'RD.Usagebill.CANCELED_NO_CARD': '{authDate} キャンセル', // 2. キャンセル(cardName X)
    'RD.Usagebill.CANCELED.COMMISSION': '{authDate} キャンセル料({cardName})', // 1. キャンセル料
    'RD.Usagebill.CANCELED.COMMISSION_NO_CARD': '{authDate} キャンセル料', // 2. キャンセル料(cardName X)

    'Maintenance.ManageType.AllSelect': '管理対象全体',

    //ペナルティ
    'Penalty.Status': 'ペナルティ状態',
    'Penalty.StatusCode.Regist': '登録完了',
    'Penalty.StatusCode.Claim': '請求完了',
    'Penalty.StatusCode.Complete': '処理完了',
    'Penalty.StatusCode.Default': '未払い',
    'Penalty.StatusCode.Exemption': '免除処理',
    'Penalty.StatusCode.Select.All': 'ペナルティ状態全体',
    'Penalty.Payment.Target': 'ペナルティ料金賦課対象',
    'Penalty.Payment.Target.Placeholder': 'ペナルティ料金賦課対象選択',
    'Penalty.Payment.CardInfo': '請求/決済情報',
    'Penalty.User.Target.Business': '会員社',
    'Penalty.User.Target.Personal': '個人',
    'Penalty.Reservation.Placeholder': '貸出番号検索',
    'Penalty.Btn.ClearPayment': 'ペナルティ料金免除',
    'Penalty.Btn.ClearScore': 'ペナルティ点数免除',
    'Penalty.Btn.Clear': 'ペナルティ全体免除',
    'Penalty.NoPaymentInfo': '決済情報がございません。',
    'Penalty.ClearPenaltyFee.Complete.1':
        'ペナルティ料金免除完了(既存ペナルティ料金 :',
    'Penalty.ClearPenaltyFee.Complete.2': '円)',
    'Penalty.ClearPenaltyPoint.Complete':
        'ペナルティ点数免除完了 (既存ペナルティ点数: {prevPenaltyPoint}点)',
    'Penalty.PaymentResultCode': 'ペナルティ決済結果',
    'Penalty.PaymentResultCode.0000': '決済完了 :',
    'Penalty.PaymentResultCode.0000f': '精算完了 :',
    'Penalty.PaymentResultCode.2002': '決済キャンセル :',
    'Penalty.PaymentResultCode.9999': '決済失敗 :',
    'Penalty.PaymentResultCode.3003': 'カード未払い',
    'Penalty.Representation.Yes': '(会社メインカード)',

    'Penalty.ClaimDialog.Message':
        'ペナルティを請求状態に変更する。{br}請求された金額はお客様に直接請求されるか会員社のペナルティ契約設定によって自動決済、別途精算または手書き精算が必要です。{br}{br}このまま実行しますか?',
    'Penalty.Dialog.Contents.Fee': 'ペナルティ賦課料金:',
    'Penalty.Dialog.Contents.Score': 'ペナルティ点数:',
    'Penalty.PaymentDialog.Message':
        '会員社に賦課されたペナルティ料金を決済します。{br}このまま実行しますか?{br}',

    'Penalty.ClearPenaltyDialog.Message':
        'ペナルティ免除処理します。{br}免除する場合ペナルティ点数及びペナルティ料金は賦課対象者に賦課されません。{br}このまま実行しますか?',
    'Penalty.ClearPenaltyFee.Message.Confirm':
        'ペナルティ料金を免除処理します。',
    'Penalty.ClearPenaltyFee.Message.Failure':
        'ペナルティ料金を免除できません。',
    'Penalty.ClearPenaltyPoint.Message.Confirm':
        'ペナルティ点数を免除処理します。',
    'Penalty.ClearPenaltyPoint.Message.Failure':
        'ペナルティ点数を免除できません。',
    'Penalty.Clear.Message.Failure': 'ペナルティ免除できません。',
    'Penalty.Claim.Message.Failure': 'ペナルティ請求ができません。',
    'Penalty.Payment.Message.Failure': 'ペナルティ料金決済ができません。',
    'Penalty.Message.Success': '精算処理しました。',
    'Penalty.EndPaymentDuoDate': '納付期間終了',
    'Penalty.ExemptionDate': '(免除処理: {exemptionDate})',

    'Code.MessageTemplate.MessageClassCd.4': 'MMS',
    'Common.Image.Title': '画像',

    // 売上/精算管理 > 未払い金
    'Receivable.Stats': '総{totalSum}円',
    'Receivable.RentalNo.Placeholder': '貸出番号入力',
    'Receivable.SubscriptionNo.Placeholder': 'サブスクリプション番号入力',
    'Receivable.ReservationType': 'タイプ',
    'Btn.Send.Alim': 'e-mail送信',
    'Btn.Receivable.Complete': '支払い完了',
    'Receivable.Date.Occurrence': '未払い金発生日時',
    'Receivable.Charge.Target': '未払い金賦課対象',
    'Receivable.Payment.Error': '決済エラーコード',
    'Receivable.SubTitle.UserInfo': '会員情報',
    'Receivable.DepartmentName': '部署名',
    'Receivable.BusinessEmail': '会社電子メール',
    'Receivable.WibleZone': 'ステーション',
    'Receivable.Main.ProductName': 'メイン商品名',
    'Receivable.Extend.ProductName': '延長商品名',
    'Receivable.SubTitle.UnpaidDetail': '未払い金詳細',
    'Receivable.Dialog.SubTitle.Complate': '未払い金支払い',
    'Receivable.Dialog.Desc.1':
        '決済方法を設定してから未払い金を支払ってください。',
    'Receivable.Dialog.ReceivableAmt': '未払い金額',
    'Receivable.Dialog.ReceivableType': '決済方法',
    'Receivable.Dialog.ReceivableCard': '決済カード',
    270003: 'バージョンアップ時、適用スタート時刻は、未来時点の時刻に設定しなければなりません。',
    'PassiveMessage.Image.Upload.Desc':
        '画像ファイル条件説明(1000 px * 1000 px 以下 / jpeg)',
    'Terms.View.UseDate.Endless': '現在まで適用',

    // 顧客請求書
    'RD.Customerbill.claimDueDate': '{year}年 {month}月 {date}日',

    'PassiveMessage.Image.Upload.Desc1':
        '推奨: 176*144pixel, 20KB 未満の jpeg ファイル',
    'PassiveMessage.Image.Upload.Desc2':
        '最大: 1000*1000pixel, 1MB 未満の jpeg ファイル',
    'PassiveMessage.Image.Upload.Desc3':
        '携帯電話の端末によって送信失敗するかもしれません。',
    'Common.User.Personal': '個人',
    'Receivable.SubTitle.BusinessInfo': '会員社/契約情報',
    'Receivable.BusinessContract.BusinessName': '会員社名',
    'Receivable.BusinessContract.Condition': '業態/業種',
    'Receivable.BusinessContract.BusinessRegNum': '登録事業所番号',
    'Receivable.BusinessContract.MobileNumber': '電話番号',
    'Receivable.BusinessContract.Address': '住所',
    'Receivable.BusinessContract.LicenseTypeCd': '契約商品',
    'Receivable.BusinessContract.ContractTime': '契約期間',
    'Receivable.Subscribe.SubTitle': 'サブスクリプション情報',
    'Receivable.Subscribe.CaseBy': '件別請求',
    'Receivable.Subscribe.Monthly': '月別請求',
    'Receivable.Subscribe.Btn.Detail': 'サブスクリプション詳細',
    'Sales.RentalDelayTime.Won': '遅延時間(円/分)',
    'Sales.AccidentFree': '事故修理料金',
    'Sales.ExtendFree': '延長料金',
    'Sales.CancelFree': 'キャンセル料金',
    'Sales.ChargeFree': '貸出料金',
    'Sales.EtcFree': 'その他料金',
    'Sales.Stats': '総 {total}円',
    'Sales.MonthlyDetail.Subtitle': '貸出情報及び売上詳細',
    'Sales.MonthlyDetail.Sum': '· 総{feeType}: {sumDrivingFree}',
    'Receivable.MonthlyDetail.Subtitle': '貸出情報及び未払い金詳細',
    'Receivable.MonthlyDetail.DrivingFee.Sum':
        '· 総{sumDrivingName}: {sumDrivingFree} {sumAddgoodsName}',

    'Receivable.CaseBy.ReservationTime': '個別契約期間',
    'Receivable.Reservation.Btn.Detail': '予約詳細',
    'Receivable.VehicleRepair.Btn.Detail': '事故受付履歴照会',
    'Receivable.Penalty.Btn.Detail': 'ペナルティ照会',
    'Btn.Transform.Mobile': 'モバイルページで新しくウィンドウを開く',
    'Calculation.PaymentMethod.FMS': 'FMS 決済',
    'Message.Receivable.Confirm.Complate': '未払い金を支払いますか?',
    'Message.Receivable.Success.Complate': '正常処理されました。',
    'Message.Receivable.Empty.fileData': '証憑書類を添付してください。',
    'Message.Receivable.Empty.Card':
        '決済用クレジットカードを選択してください。',
    'Receivable.Success.Title': '決済登録完了',
    'Receivable.Success': '決済登録が完了しました。',
    //事故処理 phase2
    'VehicleRepair.CashYn': '決済方法',
    'VehicleRepair.ClaimType': '請求方法',
    'VehicleRepair.ClaimType.App': 'Appで請求',
    'VehicleRepair.ClaimType.Etc': '別途請求',
    'VehicleRepair.AvailableYn': '貸出可能有無',
    'VehicleRepair.AvailableYn.Placeholder': '貸出可能有無全体',
    'VehicleRepair.AvailableYn.Y': '貸出可能',
    'VehicleRepair.AvailableYn.N': '貸出不可能',
    'VehicleRepair.IncidentDriver': '事故運転者情報',
    'VehicleRepair.IncidentDriverEmail': '事故運転者ID',
    'VehicleRepair.IncidentDriverEmail.Placeholder': '事故運転者ID 入力',
    'VehicleRepair.incidentDriverName': '事故運転者名',
    'VehicleRepair.incidentDriverName.Placeholder': '事故運転者名入力',
    'VehicleRepair.IncidentDriverInfo':
        '{incidentDriverName} ({incidentDriverEmail})',
    'VehicleRepair.ReservationKey': '貸出番号',
    'VehicleRepair.ReservationKey.Placeholder': '貸出番号入力',
    'VehicleRepair.BusinessName': '会員社',
    'VehicleRepair.DepartmentName': '部署名',
    'VehicleRepair.IncidentJoinPathCd': '受付経路',
    'VehicleRepair.IncidentJoinPathDate': '受付日時',
    'VehicleRepair.IncidentJoinPathCd.App': 'App',
    'VehicleRepair.InsuranceName': '免責商品',
    'VehicleRepair.RepairDay.Prefix': '休車日の数を選択',
    'VehicleRepair.RepairDay.Placeholder': '数字入力',
    'VehicleRepair.ClaimInfo': '請求情報',
    'VehicleRepair.ClaimTarget': '請求対象',
    'VehicleRepair.ClaimPaymentType': '決済方法',
    'VehicleRepair.ClaimPaymentFile': '決済証憑書類添付',
    'VehicleRepair.PaymentCash': '決済証憑書類添付',
    'VehicleRepair.ClaimMsg':
        "*請求対象顧客に'顧客請求書' メールを送信する必要があります。",
    'VehicleRepair.ConfirmMsg': '状態を変更しますか?',
    'VehicleRepair.ClaimDueDate': '請求日時',
    'VehicleRepair.PaymentDueDate': '納付期限',
    'VehicleRepair.PaymentDate': '決済日時',
    'VehicleRepair.PaymentConfirmDate': '振替内訳確認日時',
    'VehicleRepair.NoPaymentStatus': '(納付期限超過 : 未払い処理)',
    'VehicleRepair.EmailClaim.Title': '請求電子メール送信',
    'VehicleRepair.EmailClaim.Btn': '電子メール送信',
    'VehicleRepair.EmailClaim.Contents':
        '顧客請求書を電子メールで送信しました。{br}お客様の電子メールを入力して添付書類をアップロードした後、送信するボタンを選択してください。{br}',
    'VehicleRepair.EmailClaim.SubTitle': '添付書類',
    'VehicleRepair.EmailClaim.UploaderTitle1': '点検整備明細書',
    'VehicleRepair.EmailClaim.UploaderTitle2': '顧客請求書',
    'VehicleRepair.EmailClaim.PopupMsg':
        '顧客請求書の受信者情報を確認してください。',
    'VehicleRepair.EmailClaim.PopupComfirm': '移管申請書が送信されました。',
    'VehicleRepair.EmailClaim.SendBtn': '送信する',
    'VehicleRepair.EmailClaim.DriverInfo': '運転者情報',
    'VehicleRepair.EmailSend.Limit':
        '※ 添付ファイルの総容量は9MBまで送信できます。',
    'VehicleRepair.SelfFee.Max.Message':
        '自己負担金(最大) 以下の数字のみ入力できます。',
    'VehicleRepair.Processing.Alert': '[受付完了] > [処理中] に変更しますか?',
    'VehicleRepair.Claim_Complete.Alert': '[処理中] > [請求] に変更しますか?',
    'VehicleRepair.Claim_Complete.Alert.Customerbill':
        '[処理中] > [請求] に変更しますか?{br}(変更後、顧客請求書をアップロードしなければなりません。)', // JP
    'VehicleRepair.Payment.Alert': '[請求] > [処理完了] に変更しますか?',
    'VehicleRepair.Payment.Alert2': '[未払い] > [処理完了] に変更しますか?',
    'VehicleRepair.Customerbill.Confirm': '顧客請求書を送信しますか?',
    'VehicleRepair.Customerbill.Complete': '顧客請求書を送信しました。',
    'VehicleRepair.SelfFee.Select': '自己負担金選択',
    'VehicleRepair.SelfFee.Percent.Message':
        '※ 自己負担金比率のない場合、0で入力してください。',
    'VehicleRepair.Customerbill.Message': '顧客請求書を添付してください。',
    'VehicleRepair.Processing.Alert.Success': '処理中に変更されました。',
    'VehicleRepair.Claim_Complete.Alert.Success': '請求完了しました。',
    'VehicleRepair.Claim_Complete.Alert.Success.Customerbill':
        '請求完了しました。顧客請求書をアップロードしてください。', // JP
    'VehicleRepair.Payment.Alert.Success': '処理完了しました。',
    'VehicleRepair.ReservationType': 'タイプ',
    'VehicleRepair.ReservationType.All': 'Profile タイプ全体',
    'VehicleRepair.EmployeeRegNum': '事故運転者従業員番号',
    'VehicleRepair.EmployeeRegNum.Placeholder': '事故運転者従業員番号入力',

    'Common.Right.Bracket': ')',
    'Common.Left.Bracket': '(',
    'Dashboard.SubTitle.SysWeekUsing.Chart.Header':
        '全体: {total} | 業務用: {business} | 個人用: {personal}',
    'Common.Stats.Unit.Total': '全体: ',
    'Common.Stats.Unit.Business': '業務用: ',
    'Common.Stats.Unit.Personal': '個人用: ',
    'Common.CardName': '({cardBrand}){cardNumber}',
    'Dashboard.SubTitle.AppActivate.Desc': '*App OSから提供される情報で ',
    'Dashboard.SubTitle.AppActivate.Chart.Header.DAU': 'DAU: ',
    'Dashboard.SubTitle.AppActivate.Chart.Header.MAU': 'MAU: ',
    'Dashboard.SubTitle.AppActivate.Chart.Header.Stickiness': 'Stickiness: ',
    'Receivables.UnpaidStatus.COMPLETE': '支払い完了',
    'Receivables.UnpaidStatus.UN_CALCULATE': '未払い',
    'Receivables.UnpaidStatus.ALL': '状態全体',
    'Receivable.Payment.PaymentType': '未払い金決済方法',
    'Receivable.Payment.CompleteTime': '未払い金支払い完了日時',

    490002: '請求情報を入力してください。',
    490003: '該当ページでの別途精算の場合のみ処理完了できます。',
    490004: '振替内訳が登録できないので手書きでの処理が完了できません。',
    490005: '以前の状態が一致しません。',
    'VehicleModel.Image.File.extension3': 'jpeg ファイル登録', // JP
    'Receivable.receivable.Btn.Detail': '会員社詳細',
    'Common.Login.Pin.Number.Title': 'PIN 入力',
    'Common.Login.Pin.Number.Desc':
        'キア統合アカウント入会時、設定したPIN 4桁を入力してください。',
    'Common.Login.Pin.Number.Desc2':
        '5回以上認証に失敗すると最初のページに戻り、10分後に認証を再試行できます。',
    'Common.Login.Pin.Number.Placeholder': '4桁入力',
    510002: 'PINが正しくございません。{br}5回の失敗で10分間認証試行不可。 (現在{failCnt}回)',
    'Common.Login.Pin.Reset.Retry.FailOver':
        'PIN 認証を5回失敗しました。10分間認証できません。{br}最初のページに戻ります。',
    'Common.Login.Pin.Reset.Btn': 'PIN 初期化',
    'Common.Login.Pin.Reset.Retry.Msg':
        'PINを初期化しました。再度ログインしてPINを設定してください。',
    'Common.Login.Pin.Reset.Retry.Comfirm': 'PINを初期化しますか?',
    'Common.Login.Pin.Reset.Complate':
        'PINを初期化しました。{br}再度ログインし、PINを再設定してください。',
    'Message.Validator.OverByte': '入力できる文字数を超過しました。',
    'PassiveMessage.Unsubscribe': '受信却下案内',
    690002: '送信できる文字数を超過しました。',
    'Coupon.Detail.discountEndHour.Empty.Error':
        'スタート時間を選択してください。',
    'Maintenance.WorkingDate.Between.ErrorMessage':
        'スタート時間から最大1日まで選択できます。',
    'Maintenance.BusinessName.Name': '委託業者名',
    'Maintenance.MaintenanceTypeCd': '管理項目',
    'VehicleRepair.Repair.Refer': '(事前見積)',
    'VehicleRepair.Repair.RealRepair': '(最終修理費用)',
    'Input.File.Max.Size': 'ファイルは最大10Mまでアップロードできます。',
    'Code.CustomerUser.PenaltyYn.Y': '利用停止',
    'Code.CustomerUser.PenaltyYn.N': '利用停止解除',
    'VehicleModel.HydrogenEfficiency': '燃費(km/kg)',
    'Coupon.Unlimited': '無制限',

    // ----------------------- JP ---------------------------------------------
    'Common.EmployeeRegNum.Input': '社員番号を入力',
    'Common.Btn.UnSelect': '選択しない',
    'Reservation.Stats.Total': '計 {totalRecord}件',
    'Extra.Name.Placeholder.All': 'オプション名全部',
    'Reservation.Addgoods.NoSearch': 'オプションなし',
    'Extra.Placeholder.ID.All': 'オプション全部',

    // 事故修理
    'VehicleRepair.Join.YN': 'NOC補償入会の有無',
    'VehicleRepair.Join.YN.Y': '入会',
    'VehicleRepair.Join.YN.N': '未入会',
    'VehicleRepair.Delete.Alert': '[削除] 状態に変更しますか?',
    'VehicleRepair.Delete.Alert.Success': '削除処理されました。',

    // 車両登録/照会
    // 'Vehicle.ServiceTime.Pickup.Day': '受け渡し可能な曜日',

    // 料金制
    'Charge.Camping.GuideInfo': 'キャンピング用品貸出案内',
    'Charge.GuideInfo.Language': '利用案内({value})',
    'Charge.EndTime.Alert': '終了時間を入力してください。',
    'Charge.EndTime.Space.Alert': '終了時間は最長180分です。',
    'Charge.Min.Select.Placeholder': '分選択',

    // ステーション管理
    'Location.Guide.Detail': '{value} 詳細を見る',

    // コンテンツ管理 > ステーション利用案内
    'GuideLocation.Language': '言語',

    // オプション
    'Title.ProductMgmt.Extra.GuideInfo': '利用案内',

    // 貸出/決済管理
    'Reservation.Btn.ReservationInfo': '貸出内訳の照会',
    'Reservation.Btn.OperationInfo': '車両管理の照会',
    'Reservation.BusinessRentalDate': '期間選択',
    'Reservation.BusinessRentalDate.Months.Label': '{night}泊 {days}日',
    'Reservation.Space.EndTime.Placeholder': '終了時間の選択',
    'Reservation.Addgoods.Table.Title': 'オプションの情報',
    'Reservation.Addgoods.Table.AddgoodsReservation': 'オプションの貸出',
    'Reservation.Addgoods.Table.AddgoodsName': 'オプション名',
    'Reservation.Addgoods.Table.ReservationProfile': '貸出プロフィール',
    'Reservation.Addgoods.Table.DefaultAmt': '基本金額',
    'Reservation.Addgoods.Table.DelayAmt': '遅延金額',
    'Reservation.Insurance.RewardDrivingLoss.Label': 'NOC補償',
    'Reservation.Insurance.RewardDrivingLoss.Sign.Placeholder':
        '入会/未入会選択',
    'Reservation.ChargeInfo.AddgoodsFree': '貸出料金(オプション)',
    'Reservation.ChargeInfo.DriveSumFree': '総貸出金額',
    'Reservation.ChargeInfo.AddgoodsFree.Subtitle': 'オプションの料金',
    'Reservation.ChargeInfo.BasicAmount': '基本金額',
    'Reservation.ChargeInfo.DelayAmount': '遅延金額',
    'Reservation.ChargeTypeCd.Space': '空間料金制',
    'Reservation.ChargeTypeCd.Camping': 'キャンピング料金制',
    'Reservation.Btn.ChargeInfo.AddgoodsFree.Info': 'オプション料金の照会',
    'Reservation.ChargeGroupCd.WEEKDAY': '夜間パッケージ',
    'Reservation.ChargeGroupCd.WEEKEND': '週末パッケージ',
    'Reservation.Extend.PickupTime.ReturnTime': '受け渡し/返却時間',
    'Reservation.PlusHour': 'プラス時間 : {hour} 時間',
    'Reservation.Memo.Input': 'メモの入力',
    'Reservation.WorkDetail.Input': '業務内容の入力',
    'Reservation.Destination.Input': '目的地の入力',
    'Reservation.ChargeGroup.Select': '料金グループの選択',
    'Reservation.BusinessWork.Subtitle': 'ビジネス業務情報',
    'Reservation.ConfirmationName': '確認者名',
    'Reservation.BreathalyzerYn': '検知器使用の有無',
    'Reservation.DrinkingYn': '飲酒の有無',
    'Reservation.ConfirmationCheck': 'その他の確認方法',
    'Reservation.Instruction': '確認者の指示事項',
    'Reservation.Remark': '備考',
    'Reservation.ConfirmationName.Input': '確認者名の入力',
    'Reservation.ConfirmationCheck.Input': 'その他の確認方法の入力',
    'Reservation.Instruction.Input': '確認者の指示事項の入力',
    'Reservation.Remark.Input': '備考の入力',
    'Reservation.DrinkingYn.Y.Check': '飲酒時にレンタルできません。',

    // 会員
    'CustomerUser.DriverLicense.Photo': '運転免許証の写真',
    'CustomerUser.DriverLicense.Photo.View': '写真ビュー',
    'CustomerUser.DriverLicense.Msg':
        '※ 運転免許名義者氏名、会員名、写真の表裏を確認後承認してください。',
    'CustomerUser.DriverLicense.Name': '名義人名前',
    'CustomerUser.DriverLicense.LicenseStatusCd': '運転免許証承認状態',
    'CustomerUser.DriverLicense.LicenseNo': '運転免許証番号',
    'CustomerUser.DriverLicense.LicnConCode': '免許証の種類',
    'CustomerUser.DriverLicense.ToDate': '満了日',
    'CustomerUser.DriverLicense.RegistDate': '最初発行日',
    'CustomerUser.DriverLicense.Type': '日本/国際運転免許区分',
    'CustomerUser.DriverLicense.Type.Local': '日本運転免許',
    'CustomerUser.DriverLicense.Type.National': '国際運転免許',
    'CustomerUser.DriverLicense.DenyReason.Placeholder': '却下理由を入力',
    'CustomerUser.LicenseStatusCd': '運転免許証承認状態',
    'CustomerUser.LicenseStatusCd.All': '運転免許証承認状態全部',
    'CustomerUser.Passport.Photo': 'パスポートの写真',
    'CustomerUser.Passport.Msg':
        '※ パスポート名前、会員名、写真をご確認の上ご承認ください。',
    'CustomerUser.Passport.Name': '名前',
    'CustomerUser.Passport.PassportStatusCd': 'パスポート承認状態',
    'CustomerUser.Passport.PassportNo': 'パスポート番号',

    // 決済カード
    'Card.CVC': 'CVC',
    'Card.CVC.Placeholder': '3桁入力',
    'Card.Token.Error': 'トークンの生成に失敗しました。', // 臨時フレーズ

    // 端末機
    'Terminal.Device.Provider': '端末機 供給社',
    'Terminal.WifiAccessPoint': 'WIFI Access Point(AP) Name',
    'Terminal.Audio.Update': 'オーディオファイルのアップデート',
    'Terminal.FOTA.Setting': '設定値',
    'Message.Confirm.Terminal.AudioUpdate':
        'オーディオファイルをアップデートしますか?',
    'Message.Success.Terminal.AudioUpdate':
        'オーディオファイルをアップデートしました。',
    'Message.Failure.Terminal.AudioUpdate':
        'オーディオファイルをアップデートできませんでした。',

    // 共通
    'Common.Jp': '日本語',
    'Common.Kr': '韓国語',
    'Common.Business.Placeholder': '会員社全部',
    'Common.FuelLevel.Percentage': '総残量',

    // コード
    'CodeManage.Name.Placeholder.Jp': '日本語コード名を入力',
    'CodeManage.Name.Placeholder.Kr': '韓国語コード名を入力',

    // 売上/精算管理　-　クーポン
    'Coupon.Detail.ActualIssuedNumber.Dialog.Title':
        'クーポン実発行枚数の会員照会',
    'Coupon.Detail.ActualUsedNumber.Dialog.Title':
        'クーポン実使用枚数の会員照会',
    'Coupon.CreateDate': '発行日時',

    'Message.Validator.BusinessRegNum': '事業者番号を正確に入力してください。',

    // コンテンツ管理 > サービス紹介/バナー/ポップアップ
    'BannerMgmt.Langs.All': '言語全部',

    // ダッシュボード
    'Dashboard.Maintenance.Status.Complete': '作業確認',
    'Dashboard.ChargeWarning': '残りの総残量不足のお知らせ',

    // エラーコード
    523113: '間違った社員番号です。',

    // 売上/精算管理＞未払い金
    'Receivable.RemainReceivableAmtOne': '残余未払い金(円)',
    'Btn.Receivable.Exemption': '納付免除',
    'Receivable.Dialog.Exemption.SubTitle.Complate': '未払い金納付の免除',
    'Receivable.Dialog.Exemption.Desc.1':
        '免除金額を入力した後、未払い金納付免除を完了してください。',
    'Receivable.Dialog.Exemption.Desc.2':
        '- 免除金額により未払い金状態が未払いのまま維持、または、納付免除に変更されます。',
    'Receivable.Dialog.Exemption.Desc.3': '- 納付免除は1回だけです。',
    'Receivable.Dialog.Exemption.Amt': '免除金額',
    'Receivable.Dialog.Exemption.Amt.Placeholder': '免除金額を入力',
    'Message.Receivable.Empty.Exemption.Amt': '免除金額を入力してください。',
    'Receivable.Dialog.Exemption.GuideInfo1':
        '* 未払い金額以下の金額を入力してください。',
    'Receivable.Dialog.Exemption.GuideInfo2':
        '* 例） 未払い金が2,000円の状態から部分免除金額の入力を500にすると未払い金が1,500円に変更されます。',
    'Message.Receivable.Confirm.Exemption':
        '当該未払い金を免除します。{br}免除処理は1回だけです。{br}このまま続行しますか?',
    'Receivable.RentalDelay.Day': '遅延時間(分)',
    'Receivable.RentalDelay.Extra.Fee': '遅延料金(円/24時間)',
    'Receivable.Occurrence.Period': '未払い金発生期間',
    'Receivable.Exemption.Title': '免除情報',
    'Receivable.Exemption.Date.Occurrence': '免除処理日時',
    'Receivable.Exemption.RemainFee': '残りの未払い金',
    'Receivable.Exemption.Success.Title': '未払い金納付免除の完了',
    'Receivable.Exemption.Success': '未払い金の納付免除が完了されました。',
    'Receivable.Return.Failure.Exemption.Title': '未払い金納付免除の失敗',
    'Receivable.Failure.Exemption':
        '未払い金納付免除に失敗しました。{br}エラーコードを確認してください。',

    // 売上/精算管理　>売上
    'Sales.NonPaymentTime': '未払い日時',
    'Sales.ReceivableClearTime': '未払い免除日時',
    'Sales.AddgoodsDelay': 'オプションの遅延料金',

    // 車両管理 > 車両登録/照会
    'Vehicle.Insurance.Interpersonal.Assurance': '対人保証',
    'Vehicle.Insurance.InterVehicle.Assurance': '対物保証',
    'Vehicle.Insurance.Vehicle.Assurance': '車両保証',
    'Vehicle.Insurance.Vehicle.GuideInfo': '(NOC補償入会時提供)',
    'Vehicle.Insurance.BodilyHarm.Assurance': '身体損害保証',
    'Vehicle.Insurance.Placeholder': '無制限',
    'Vehicle.Insurance.GuideInfo':
        '*車両保証は顧客がNOC補償に入会した場合保障され、入会しない場合、 NOC 料金が事故処理費用により請求される。',
    'Vehicle.ModelYear': '{year}年式',

    // 売上/精算管理　>　精算
    'Calculation.RegisterDate': '精算登録日',

    // アプリのコンテンツ管理
    'GuideLocation.Language.Type': '言語',
    'GuideLocation.Language.All': '言語全部',
    'GuideLocation.Language.Select': '言語設定',
    'GuideLocation.ContentType.Guide':
        '*WIFI テザリングコンテンツは端末機別に接続せずに言語別に一番最近、登録されたコンテンツに接続され、提供されます。',

    // コンテンツ管理>車種別の利用案内
    'ModelInfo.Grade.Placeholder': 'グレード入力',

    // GoogleAddress Search
    'Message.Alert.Google.Address.Fail': '検索された住所を選択してください。',

    // カスタマーセンター>1:1相談
    'VocMgmt.VocID.Placeholder': '相談番号の入力',
    'VocMgmt.UserName.Placeholder': '会員名を入力または検索',
    'VocMgmt.TextArea.Placeholder': '3,000文字入力可能',

    // 会員社管理>会員社/契約
    'Business.Registration.Placeholder': '事業者登録証の選択',
    'Business.BankBookCopy.Placeholder': '通帳コピーの選択',
    'Business.MainImage.Placeholder': 'メインイメージの選択',
    'Business.Contract.PaperReg.Placeholder': '契約書の選択',
    'Business.Name.Placeholder.CutOff': '会員社名',
    'Business.Registration.Upload': '事業者登録証の登録',
    'Business.BankBookCopy.Upload': '通帳コピーの登録',
    'Business.MainImage.Upload': 'メインイメージの登録',

    // コンテンツ管理>お知らせテンプレート
    'MsgTemplate.MessageTemplate.Placeholder': 'メッセージテンプレート入力',
    'MsgTemplate.UpdateHistory.Placeholder': '修正履歴の入力',
    'MsgTemplate.MessageContents.Placeholder': 'メッセージ内容(例示）を入力',

    'Common.UseDate.Period.Placeholder': '適用期間の選択',

    // 運営管理>バージョン
    'AppVersion.AppVersion.Placeholder': 'アプリバージョンを入力',
    'AppVersion.BuildVersion.Placeholder': 'ビルドバージョンを入力',
    'AppVersion.Description.Placeholder': 'アプリバージョン説明を入力',
    'AppVersion.AppLink.Placeholder': 'アップデートリンクを入力',
    'AppVersion.UpdateMessage.Placeholder': 'アップデートメッセージを入力',
    'AppVersion.AppTypeCd.All': 'アプリ分類全部',
    'AppVersion.AppPlatFormCd.All': 'OS 全部',
    'AppVersion.UseYn.All': '使用有無全部',

    // 車両管理>売却
    'Trade.TradeAmount.Sale.Placeholder': '売却金額を入力',
    'Trade.TradeAmount.Scrap.Placeholder': '廃車金額を入力',
    'Trade.Note.Placeholder': '備考入力',

    // 売上/精算管理>反則金
    'Violation.PenaltyFee.Input': '罰金入力',

    // 運営管理>アドミン
    'Operator.RoleSelect.All': '役割全体',
    'Operator.StatusSelect.All': '状態全体',
    // ----------------------- JP 07.18 번역 요청 ---------------------------------------------

    // 고객센터 > 고객센터 공지
    'Title.CSCenter.Notice.View': 'カスタマーセンターのお知らせ状態',

    //Report Designer > 차량운행일지
    'RD.Date.Duration': '{days}日 {hours}時間 {minutes}分',

    // 고객센터 > 고객센터 공지
    'CSCenterNotice.Subject.Name': 'お知らせ名',
    'CSCenterNotice.Contents.Etc': '備考',
    'CSCenterNotice.Contents.Etc.Placeholder': '備考入力',

    'Title.ContentMgmt.Notice.Edit': 'お知らせ修正',

    // 콘텐츠 관리 > サービス紹介/バナー/ポップアップ
    'BannerMgmt.SubTitle.Info': 'サービス紹介/バナー/ポップアップ登録情報',

    // 매출/정산 관리 > 쿠폰
    'Coupon.Publish.StartDate.Placeholder': '開始日時',
    'Coupon.Publish.EndDate.Placeholder': '終了日時',
    'Coupon.Use.StartDate.Placeholder': '開始日時',
    'Coupon.Use.EndDate.Placeholder': '終了日時',
    'Coupon.Detail.Affiliate.Title': '提携クーポン発行',
    'Coupon.Detail.Affiliate.StartDate.Placeholder': '提携開始日時',
    'Coupon.Detail.Affiliate.EndDate.Placeholder': '提携終了日時',
    'Coupon.Validator.UseDate':
        '使用期間は発行開始日から将来の日付を選択してください。',

    // 공통
    'Message.Validator.NumUpEn': '英語と数字のみを入力してください。',
    'Message.Validator.File.Extention':
        'ファイル形式と拡張子名が間違っており、添付できません。',
    'Common.Login.State.Error.Title': 'ログイン障害ガイド',
    'Message.Login.State.Error':
        'サービスに一時的な問題があります。しばらくしてから再度お試しください。',

    // 대여/결제 관리 > 대여 관리
    'Reservation.ChargeInfo.RentalFee.Car': '貸出料金(車両)',
    // ----------------------- JP 07.26 구글 번역 ---------------------------------------------

    // 사고수리
    'VehicleModel.Image.File.extension4': 'png、jpgファイルのアップロード',

    // 매출/정산 관리 > 미수금
    'Receivable.ExemptionAmount': '未収金の免除',

    // 대여/결제관리 > 대여관리
    'Reservation.ChargeInfo.FreeDistanceCharge.Km': '無課金走行距離',
    'Reservation.ImageTypeCdGroup.Front': 'フロント',
    'Reservation.ImageTypeCdGroup.Back': '背面',
    'Reservation.ImageTypeCdGroup.Left': '左側面',
    'Reservation.ImageTypeCdGroup.Right': '右側面',
    'Reservation.ImageTypeCdGroup.More': '追加',
    'Reservation.ImageTypeCdGroup.Internal1': '内部1',
    'Reservation.ImageTypeCdGroup.Internal2': '内部2',
    // ----------------------- JP 08.03 구글 번역 ---------------------------------------------

    // 사고수리
    'VehicleRepair.IncidentDriver.Search': '事故運転者検索',

    // 회원/부서관리 > 회원사 권한
    'Title.UserMgmt.BusinessOperator.List': '会員社権限（バーミッション）照会',
    // ----------------------- JP 08.05 구글 번역 ---------------------------------------------
    'BusinessOperator.Delete.Message': '登録情報が削除されました。',

    // 차량 관리 > 단말기
    'Terminal.Audio.Version': 'Audio ver.',

    // 차량 관리 > 유지보수
    'Maintenance.Vehicle.Dialog.Title': '[ステーション : {locationName}]',

    // 차량 관리 > 단말기
    'Terminal.InitialSetting.Dialog.Title': '端末機装着の初期設定',

    // 회원/부서 관리 > 회원
    'CustomerUser.Button.TargetReceiver': 'メッセージ受信対象',

    'Vehicle.Keybox.PWD': '鍵保管ボックスパスワード', // 키박스 비밀번호
    'Operator.SystemType.Company': '所属社', // 'Operator.SystemType.PartnerName': '委託社名',
    'Alert.Request.Timeout':
        '応答が遅れています。しばらくしてからもう一度お試しください。',
    'Alert.Business.Profile.Not.Found':
        'ビジネスプロフィールのない会員なので、会員会社に罰金を科せません。',
};

export default language;
