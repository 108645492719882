const numberWithCommas = (x) => {
    // return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (x) {
        let parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    } else {
        return x;
    }
};

const convertBizNumber = (text) => {
    return text?.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3') || '-';
};

const phoneFomatter = ({text, isAsterisk = false, defaultMessage = ''}) => {
    if (!text) return defaultMessage;
    let formatNum = '';
    if (text.length === 11) {
        if (isAsterisk) {
            formatNum = text.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
        } else {
            formatNum = text.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }
    } else if (text.length === 8) {
        formatNum = text.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else {
        if (text.indexOf('02') === 0) {
            if (isAsterisk) {
                formatNum = text.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
            } else {
                formatNum = text.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
            }
        } else {
            if (isAsterisk) {
                formatNum = text.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
            } else {
                formatNum = text.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            }
        }
    }
    return formatNum;
}

export { numberWithCommas, convertBizNumber, phoneFomatter };
