import { ContentsPageMobile } from './ContentsPageMobile';

const AuthedAppMobile = ({ onRanderHandle }) => {
    return (
        <>
            <div id="wrap">
                <ContentsPageMobile
                    onRanderHandle={onRanderHandle}
                ></ContentsPageMobile>
            </div>
        </>
    );
};

export { AuthedAppMobile };
