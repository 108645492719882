import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Button as MuxButton } from 'mux';

const Button = forwardRef((props, ref) => {
    const { children, resId, text, disable, ...rest } = props;
    const label = useMemo(() => {
        if (children) {
            return children;
        } else if (resId) {
            return <FormattedMessage id={resId} />;
        } else {
            return text;
        }
    }, [children, resId, text]);

    return (
        <MuxButton {...rest} disabled={disable} ref={ref}>
            {label}
        </MuxButton>
    );
});

Button.propTypes = {
    resId: PropTypes.string,
    text: PropTypes.string,
    disable: PropTypes.bool,
};

export default Button;
