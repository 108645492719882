import * as _ from 'lodash';

export function getCodeByIntValue({ codeList, value, defaultValue }) {
    if (!codeList?.length) return null;
    if ((value !== '' || value !== 0) && !value) {
        if ((defaultValue !== '' || defaultValue !== 0) && !defaultValue)
            return null;
    }

    let codes = codeList.filter((code) =>
        _.isEqual((code.meta?.codeIntValue || '').toString(), value.toString()),
    );

    if (!codes?.length && (defaultValue || defaultValue === 0)) {
        codes = codeList.filter((code) =>
            _.isEqual(
                (code.meta?.codeIntValue || '').toString(),
                defaultValue.toString(),
            ),
        );
    }
    if (!codes?.length) return null;
    if (typeof codes[0].label !== 'string') {
        codes[0].label = codes[0].label.props && codes[0].label.props.id;
    }

    return codes[0];
}

export function getCode({ codeList, value, defaultValue }) {
    if (
        !codeList ||
        codeList.length < 1 ||
        !codeList[0].label ||
        codeList[0].value === null ||
        typeof codeList[0].value === 'undefined'
    ) {
        return null;
    }
    let codes = codeList.filter((code) =>
        _.isEqual(code.value.toString(), value.toString()),
    );
    if (!codes || codes.length < 1) {
        if (typeof defaultValue !== 'undefined') {
            codes = codeList.filter((code) => code.value === defaultValue);
        }
    }
    if (!codes || codes.length < 1) {
        return null;
    }
    if (typeof codes[0].label !== 'string') {
        codes[0].label = codes[0].label.props && codes[0].label.props.id;
    }

    return codes[0];
}

export const getMsgByCode = ({ codeList, value, defaultMessage }) => {
    const tgValue = value === 0 ? '0' : value;
    if (tgValue) {
        const findCode = getCode({ codeList, value: tgValue });
        return findCode ? findCode.label : defaultMessage;
    } else {
        return defaultMessage;
    }
};

export function getCodeByChildGroupKey({
    codeList,
    childGroupKey,
    defaultValue,
}) {
    if (!codeList.length) {
        return defaultValue;
    }

    let codes = codeList.filter((code) => {
        if (code?.meta?.groupKey) {
            if (childGroupKey)
                return _.isEqual(
                    code.meta.groupKey.toString(),
                    childGroupKey.toString(),
                );
            else if (defaultValue || defaultValue === '') {
                return _.isEqual(
                    code.meta.groupKey.toString(),
                    defaultValue.toString(),
                );
            }
        }
        return false;
    });
    if (!codes?.length) {
        return defaultValue;
    }

    return codes[0];
}

export const getMsgByCodeChildGroupKey = ({
    codeList,
    childGroupKey,
    defaultValue,
    defaultMessage,
}) => {
    if (codeList?.length > 0 || childGroupKey || childGroupKey === '') {
        const findCode = getCodeByChildGroupKey({
            codeList,
            childGroupKey,
            defaultValue,
        });
        return findCode ? findCode.label : defaultMessage;
    } else {
        return defaultMessage;
    }
};
