import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useForm, FormProvider } from 'react-hook-form';

import { Dialog } from 'component/mux';

const CustomDialog = ({ visible, children, onSubmit, ...rest }) => {
    const methods = useForm();

    useEffect(() => {
        if (!visible) {
            methods.reset({});
        }
    }, [visible]);

    return (
        <Dialog
            visible={visible}
            type="default"
            actions={[
                {
                    resId: 'Btn.Cancel',
                    type: 'cancel',
                },
                {
                    resId: 'Btn.Confirm',
                    onClick: () => {
                        onSubmit(methods.getValues());
                    },
                },
            ]}
            {...rest}
        >
            <FormProvider {...methods}>
                <form>{children}</form>
            </FormProvider>
        </Dialog>
    );
};

CustomDialog.propTypes = {
    visible: PropTypes.bool,
    resId: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
};

export default CustomDialog;
