import { Fragment, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { If, Then, Else } from 'react-if';
import { useAppRoot, Button } from 'component/mux';
import { Link } from 'react-router-dom';

/**
 *
 * permitId [ 'acl', 'inquiry', 'save', 'del', 'down' ]
 * @param {permitId} param0
 * @returns
 */

const ActionButton = ({
    permitId,
    permitUrl, // 특수 버튼 URL
    label,
    type,
    onClick,
    targetForm,
    className,
    hidden,
    ...rest
}) => {
    // type = [ 'blank', 'normal', 'filled' ]
    const [buttonType, setButtonType] = useState('default');
    const [htmlType, setHtmlType] = useState('button');
    const { hasPermit, menu } = useAppRoot();

    let permitShow = true;
    if (permitUrl) {
        permitShow = hasPermitUrl(menu, permitUrl, permitId);
    } else if (permitId) {
        if (hasPermit) {
            permitShow = hasPermit(permitId); // false 설정(내 메뉴 권한 확인)
        }
    }

    useEffect(() => {
        setButtonType('default');
        setHtmlType('button');
        switch (type) {
            case 'blank':
                setButtonType('negative');
                break;
            case 'filled':
                setButtonType('primary');
                break;
            case 'create':
                setButtonType('primary');
                setHtmlType('submit');
                break;
            case 'submit':
                // setButtonType('primary');
                setHtmlType('submit');
                break;
            case 'primary':
                setButtonType('primary');
                break;
            case 'cancel':
                setButtonType('cancel');
                break;
            case 'positive':
                setButtonType('positive');
                break;
            case 'text':
                setButtonType('text');
                break;
            case 'normal':
            default:
                setButtonType('default');
                break;
        }
    }, [type]);

    return !permitShow || hidden ? null : (
        <If condition={!_.isUndefined(targetForm)}>
            <Then>
                <Button
                    resId={label?.resId}
                    htmlType={htmlType}
                    type={buttonType}
                    onClick={onClick}
                    targetForm={targetForm}
                    className={className}
                    {...rest}
                />
            </Then>
            <Else>
                <Button
                    resId={label?.resId}
                    htmlType={htmlType}
                    type={buttonType}
                    onClick={onClick}
                    className={className}
                    {...rest}
                />
            </Else>
        </If>
    );
};

const LinkButton = ({
    permitId,
    permitUrl, // 특수 버튼 URL
    linkUrl, // 링크 detail page
    state,
    resId,
    onClick,
    ...rest
}) => {
    const OnClick = useCallback(
        (props) => {
            if (onClick) onClick(props);
            if (permitUrl) {
                sessionStorage.setItem('pathname', linkUrl || permitUrl);
                sessionStorage.setItem('state', JSON.stringify(state || {}));

                window.open(`${process.env.PUBLIC_URL}/openWindow`, '_blank');
            }
        },
        [permitId, permitUrl, state, onClick],
    );
    return (
        <ActionButton
            permitId={permitId || (permitUrl ? 'inquiry' : undefined)}
            permitUrl={permitUrl}
            label={{ resId }}
            onClick={OnClick}
            {...rest}
        />
    );
};

const ActionButtonByList = ({ onClick4New, addition, permitUrl, ...rest }) => {
    const [additionElements, setAdditionElements] = useState(<></>);

    useEffect(() => {
        if (null === addition || _.isUndefined(addition)) {
            return;
        }

        const elems = addition.map((element, index) => {
            return (
                <Fragment key={`ActionButtonByList${index}`}>
                    {element}
                </Fragment>
            );
        });
        setAdditionElements(elems);
    }, [addition]);

    const OnClick4New = useCallback(() => {
        if (onClick4New) onClick4New({ ...rest });
    }, [onClick4New, rest]);

    return (
        <>
            {additionElements}
            <ActionButton
                permitId="save"
                permitUrl={permitUrl}
                type="create"
                label={{ resId: 'Btn.Create' }}
                onClick={OnClick4New}
            />
        </>
    );
};

const ActionButtonOnlyDetailView = ({
    editable,
    mode,
    onClick4Cancel,
    // onClick4Submit,
    onClick4Delete,
    onClick4Edit,
    onClick4List,
    addition,
    targetForm,
}) => {
    const [elements, setElements] = useState(<></>);
    const [additionElements, setAdditionElements] = useState(<></>);

    useEffect(() => {
        if (!addition) {
            return;
        }

        const elems = addition.map((element, index) => {
            return (
                <Fragment key={`ActionButtonOnlyDetailView${index}`}>
                    {element}
                </Fragment>
            );
        });
        setAdditionElements(elems);
    }, [addition]);

    useEffect(() => {
        if ('create' === mode) {
            setElements(
                <>
                    <ActionButton
                        type="cancel"
                        label={{ resId: 'Btn.Cancel' }}
                        onClick={onClick4List}
                    />
                    <ActionButton
                        permitId="save"
                        type="submit"
                        label={{ resId: 'Btn.Save' }}
                        targetForm={targetForm}
                    />
                </>,
            );
            return;
        }

        if (!editable) {
            setElements(
                <>
                    <ActionButton
                        permitId="del"
                        type="blank"
                        label={{ resId: 'Btn.Delete' }}
                        onClick={onClick4Delete}
                    />
                    <ActionButton
                        type="cancel"
                        label={{ resId: 'Btn.List' }}
                        onClick={onClick4Cancel}
                    />
                </>,
            );
            return;
        }
    }, [
        mode,
        editable,
        onClick4Cancel,
        // onClick4Submit,
        onClick4Delete,
        onClick4Edit,
        onClick4List,
    ]);

    return (
        <>
            {additionElements}
            {elements}
        </>
    );
};

const ActionButtonNoDetailView = ({
    editable,
    mode,
    onClick4Cancel,
    // onClick4Submit,
    onClick4Delete,
    onClick4Edit,
    onClick4List,
    addition,
    targetForm,
}) => {
    const [elements, setElements] = useState(<></>);
    const [additionElements, setAdditionElements] = useState(<></>);

    useEffect(() => {
        if (!addition) {
            return;
        }

        const elems = addition.map((element, index) => {
            return (
                <Fragment key={`ActionButtonNoDetailView${index}`}>
                    {element}
                </Fragment>
            );
        });
        setAdditionElements(elems);
    }, [addition]);

    useEffect(() => {
        if ('create' === mode) {
            setElements(
                <>
                    <ActionButton
                        type="cancel"
                        label={{ resId: 'Btn.Cancel' }}
                        onClick={onClick4List}
                    />
                    <ActionButton
                        permitId="save"
                        type="submit"
                        label={{ resId: 'Btn.Save' }}
                        targetForm={targetForm}
                    />
                </>,
            );
            return;
        }

        if (editable) {
            setElements(
                <>
                    <ActionButton
                        permitId="del"
                        type="blank"
                        label={{ resId: 'Btn.Delete' }}
                        onClick={onClick4Delete}
                    />
                    <ActionButton
                        type="cancel"
                        label={{ resId: 'Btn.Cancel' }}
                        onClick={onClick4Cancel}
                    />
                    <ActionButton
                        permitId="save"
                        type="submit"
                        label={{ resId: 'Btn.Modify.Complete' }}
                        // onClick={onClick4Submit}
                        targetForm={targetForm}
                    />
                </>,
            );
            return;
        }
    }, [
        mode,
        editable,
        onClick4Cancel,
        // onClick4Submit,
        onClick4Delete,
        onClick4Edit,
        onClick4List,
    ]);

    return (
        <>
            {additionElements}
            {elements}
        </>
    );
};

// 취소(onClick4Cancel), 등록(onClick4Submit), 삭제(onClick4Delete), 수정(onClick4Edit), 수정완료(onClick4Submit), addtion
const ActionButtonByDetail = ({
    editable,
    mode,
    onClick4Cancel,
    // onClick4Submit,
    onClick4Delete,
    onClick4Edit,
    onClick4List,
    addition,
    additionBack,
    targetForm,
}) => {
    const [elements, setElements] = useState(<></>);
    const [additionElements, setAdditionElements] = useState(<></>);
    const [additionBackElements, setAdditionBackElements] = useState(<></>);

    useEffect(() => {
        if (!addition) {
            return;
        }

        const elems = addition.map((element, index) => {
            return (
                <Fragment key={`ActionButtonByDetailAddition${index}`}>
                    {element}
                </Fragment>
            );
        });
        setAdditionElements(elems);
    }, [addition]);

    useEffect(() => {
        if (!additionBack) {
            return;
        }

        const elems = additionBack.map((element, index) => {
            return (
                <Fragment key={`ActionButtonByDetailAdditionBack${index}`}>
                    {element}
                </Fragment>
            );
        });
        setAdditionBackElements(elems);
    }, [additionBack]);

    useEffect(() => {
        if ('create' === mode) {
            setElements(
                <>
                    <ActionButton
                        type="cancel"
                        label={{ resId: 'Btn.Cancel' }}
                        onClick={onClick4List}
                    />
                    <ActionButton
                        permitId="save"
                        type="submit"
                        label={{ resId: 'Btn.Save' }}
                        targetForm={targetForm}
                    />
                </>,
            );
            return;
        }

        if (editable) {
            setElements(
                <>
                    <ActionButton
                        type="cancel"
                        label={{ resId: 'Btn.Cancel' }}
                        onClick={onClick4Cancel}
                    />
                    <ActionButton
                        permitId="save"
                        type="submit"
                        label={{ resId: 'Btn.Modify.Complete' }}
                        // onClick={onClick4Submit}
                        targetForm={targetForm}
                    />
                </>,
            );
            return;
        }
        setElements(
            <>
                {onClick4Delete && (
                    <ActionButton
                        permitId="del"
                        type="blank"
                        label={{ resId: 'Btn.Delete' }}
                        onClick={onClick4Delete}
                    />
                )}
                <ActionButton
                    type="cancel"
                    label={{ resId: 'Btn.List' }}
                    onClick={onClick4List}
                />
                {onClick4Edit && (
                    <ActionButton
                        permitId="save"
                        label={{ resId: 'Btn.Modify' }}
                        onClick={onClick4Edit}
                    />
                )}
            </>,
        );
    }, [
        mode,
        editable,
        onClick4Cancel,
        // onClick4Submit,
        onClick4Delete,
        onClick4Edit,
        onClick4List,
    ]);

    return (
        <>
            {additionElements}
            {elements}
            {additionBackElements}
        </>
    );
};

// 등록(onClick4Submit), 삭제(onClick4Delete), 수정완료(onClick4Submit), addtion
const ActionButtonByEdit = ({
    mode,
    // onClick4Submit,
    onClick4Delete,
    onClick4List,
    addition,
    targetForm,
}) => {
    const [elements, setElements] = useState(<></>);
    const [additionElements, setAdditionElements] = useState(<></>);

    useEffect(() => {
        if (!addition) {
            return;
        }

        const elems = addition.map((element, index) => {
            return (
                <Fragment key={`ActionButtonByEdit${index}`}>
                    {element}
                </Fragment>
            );
        });
        setAdditionElements(elems);
    }, [addition]);

    useEffect(() => {
        if ('create' === mode) {
            setElements(
                <>
                    <ActionButton
                        type="cancel"
                        label={{ resId: 'Btn.List' }}
                        onClick={onClick4List}
                    />
                    <ActionButton
                        permitId="save"
                        type="submit"
                        label={{ resId: 'Btn.Save' }}
                        // onClick={onClick4Submit}
                        targetForm={targetForm}
                    />
                </>,
            );
            return;
        }

        setElements(
            <>
                <ActionButton
                    permitId="del"
                    type="blank"
                    label={{ resId: 'Btn.Delete' }}
                    onClick={onClick4Delete}
                />
                <ActionButton
                    type="cancel"
                    label={{ resId: 'Btn.List' }}
                    onClick={onClick4List}
                />
                <ActionButton
                    permitId="save"
                    type="submit"
                    label={{ resId: 'Btn.Modify.Complete' }}
                    // onClick={onClick4Submit}
                />
            </>,
        );
    }, [mode, /* onClick4Submit, */ onClick4Delete, onClick4List]);

    return (
        <>
            {additionElements}
            {elements}
        </>
    );
};

// 권한 체크
const hasPermitUrl = (menu, permitUrl, permitId) => {
    if (!menu || !permitUrl || !permitId) {
        return false;
    }
    const permitKey = `${permitId}Yn`;
    return !_.isEmpty(
        _.find(menu, {
            menuUrl: permitUrl,
            [permitKey]: 'Y',
        }),
    );
};

// 현재창 이동 링크 Text
const LinkText = ({
    permitId,
    permitUrl, // 특수 버튼 URL
    linkProps,
    className,
    children,
}) => {
    const { menu } = useAppRoot();
    return (
        <span className={className}>
            <If
                condition={linkProps && hasPermitUrl(menu, permitUrl, permitId)}
            >
                <Then>
                    <Link to={linkProps || '/'}>{children}</Link>
                </Then>
                <Else>{children}</Else>
            </If>
        </span>
    );
};

export {
    LinkButton,
    ActionButton,
    ActionButtonByList,
    ActionButtonByDetail, // view 모드와 edit 모드 모두 사용하는 메뉴
    ActionButtonByEdit, // view 모드 없이 edit 모드만 사용하는 메뉴
    ActionButtonNoDetailView, // view 모드 없이 edit 모드 (삭제 제공)
    ActionButtonOnlyDetailView, // view 모드만 제공 (edit 없음)
    hasPermitUrl, // 권한 체크용으로 fn 외부 제공
    LinkText, // 현재창 이동 링크 Text
};
