import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import './index.css';
import 'antd/dist/antd.css';
import 'mux/dist/default.css';
import '@progress/kendo-theme-default/dist/all.css';
import './App.css';

import { AppRoot } from 'component/mux';
import { Initialize } from 'util/initialize';
import * as pvLangConfig from 'config/locale';
import * as adLangConfig from 'config/locale';
import { ErrorCode } from 'config/locale/errors';

import moment from 'moment';
import 'moment/locale/ko';
import 'moment/locale/ja';

import { GateKeeperPageMobile } from 'layoutMobile/GateKeeperPageMobile';

import dotenv from 'dotenv';
import GoogleAnalyticsHOC from './service/GoogleAnalyticsHOC';
import React from 'react';
import { STORAGE_KEY } from 'util/pageUtil';
dotenv.config();

const DEFAULT = 'ko';
const ANT_CONFIG = [{ ko: 'koKR' }, { ja: 'jaJP' }];

const getLocale = (langKey) => {
    const language = langKey || DEFAULT;
    const pvName = `${language}`;
    const adName = ANT_CONFIG[language];
    return {
        language: language,
        pvLocale: pvLangConfig[pvName] || pvLangConfig[DEFAULT],
        adLocale: adLangConfig[adName] || adLangConfig[adName],
    };
};

function AppMobile() {
    const sLanguage = localStorage.getItem(STORAGE_KEY.LANGUAGE);
    const curLanguage =
        !_.isEmpty(sLanguage) && ['ko', 'ja'].includes(sLanguage)
            ? sLanguage
            : process.env.REACT_APP_LANGUAGE;
    const [locale, setLocale] = useState(getLocale(curLanguage));

    const changeLocale = (language) => {
        if (!language) {
            return;
        }
        setLocale(getLocale(language));
        moment.locale(language);
        localStorage.setItem(STORAGE_KEY.LANGUAGE, language);
    };

    // moment 기본 언어 설정
    useEffect(() => {
        moment.locale(curLanguage);
    }, []);

    return (
        <>
            <IntlProvider
                locale={locale?.language}
                defaultLocale={DEFAULT}
                messages={{
                    ...pvLangConfig[DEFAULT],
                    ...locale?.pvLocale,
                }}
                onError={(e) => {
                    // console.log(e);
                }}
            >
                <AppRoot
                    locale={locale?.adLocale}
                    language={locale?.language}
                    errorCode={ErrorCode}
                    changeLocale={changeLocale}
                >
                    <BrowserRouter>
                        <GoogleAnalyticsHOC>
                            <Initialize />
                            <GateKeeperPageMobile />
                        </GoogleAnalyticsHOC>
                    </BrowserRouter>
                </AppRoot>
            </IntlProvider>
        </>
    );
}

export default AppMobile;
