import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { numberWithCommas } from 'util/NumberUtil';

const DATETIME_FORMAT = [
    'YYYY-MM-DD', // 0
    'YYYY-MM-DD HH:mm', // 1
    'YYYY-MM-DD HH:mm:ss', // 2
    'YYYY-MM', // 3
];

const parseFormatStr = ({
    text,
    formatStr = DATETIME_FORMAT[1],
    defaultMessage = '',
}) => {
    if (!text) return defaultMessage;
    const longTime = parseInt(text, 10);
    if (!longTime || isNaN(longTime)) return defaultMessage;
    const momentTime = moment(longTime);
    if (!momentTime.isValid()) return defaultMessage;

    return momentTime.format(formatStr);
};

const parseFormatStartToEnd = (
    startDate,
    endDate,
    formatStr = DATETIME_FORMAT[0],
    defaultMessage = '',
) => {
    //if (!startDate || !endDate) return defaultMessage;
    return (
        parseFormatStr({ text: startDate, formatStr: formatStr }) +
        ' ~ ' +
        parseFormatStr({ text: endDate, formatStr: formatStr })
    );
};

const parseFormatStrForStrDate = ({
    text,
    formatStr = DATETIME_FORMAT[0],
    defaultMessage = '',
}) => {
    if (!text) return defaultMessage;
    const weight = text.length < 7 ? 2 : 4;
    const y = text.substr(0, weight);
    const m = text.substr(weight, 2);
    const d = text.substr(weight + 2, 2);
    const joinDate = new Date(Number(y), Number(m) - 1, Number(d));
    return moment(joinDate).format(formatStr);
};

const priceFormatStr = ({ text, defaultMessage = '' }) => {
    if (!text) return defaultMessage;

    return (
        <FormattedMessage
            id="Common.Unit.Money.Value"
            values={{
                value: numberWithCommas(text),
            }}
        />
    );
    // return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' 원';
};

const priceFormatStrNoneUnit = ({ text, defaultMessage = '' }) => {
    if (!text) return defaultMessage;

    return numberWithCommas(text);
    // return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const parseDateToLong = ({
    text,
    formatStr = DATETIME_FORMAT[0],
    defaultMessage = '',
}) => {
    if (!text) return defaultMessage;
    return moment(text, formatStr).valueOf();
};

const parseTimeStringToMoment = (timeString, seperator = ':') => {
    if (!timeString || !_.isString(timeString)) return null;
    return moment(
        _.reduce(
            timeString.split(':'),
            (result, time, index) => {
                if (index === 0) result.hour = time;
                if (index === 1) result.minute = time;
                if (index === 2) result.second = time;
                return result;
            },
            {},
        ),
    );
};
const getDateTimeDiff = (date1, date2, format = 'm') => {
    return Math.round(
        moment.duration(moment(date2).diff(moment(date1))).as(format),
    );
};

const parseFormatStrNextDay = ({
    text,
    formatStr = DATETIME_FORMAT[1],
    days = 1,
    defaultMessage = '',
}) => {
    if (!text) return defaultMessage;
    const longTime = parseInt(text, 10);
    if (!longTime || isNaN(longTime)) return defaultMessage;
    const momentTime = moment(longTime).add(days, 'days').utcOffset(0).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
    });
    if (!momentTime.isValid()) return defaultMessage;

    return momentTime.format(formatStr);
};

const parseFormatStartToEndLess = (
    startDate,
    formatStr = DATETIME_FORMAT[0],
    intl,
    defaultMessage = '',
) => {
    if (!startDate) return defaultMessage;
    return `${parseFormatStr({
        text: startDate,
        formatStr: formatStr,
    })} ~ ${intl.formatMessage({ id: 'Terms.View.UseDate.Endless' })}`;
};

const getByte = (str, korSize) => {
    if (!_.isString(str) || _.isEmpty(str)) {
        return;
    }

    const korByteSize = korSize || 3;

    return str
        .split('')
        .map((s) => s.charCodeAt(0))
        .reduce(
            (prev, c) => prev + (c === 10 ? 2 : c >> 7 ? korByteSize : 1),
            0,
        );
};

export {
    DATETIME_FORMAT,
    parseFormatStr,
    priceFormatStr,
    parseFormatStrForStrDate,
    parseDateToLong,
    parseTimeStringToMoment,
    getDateTimeDiff,
    parseFormatStartToEnd,
    priceFormatStrNoneUnit,
    parseFormatStrNextDay,
    parseFormatStartToEndLess,
    getByte,
};
