import { Suspense } from 'react';

import { AuthedRoutes } from 'config/routes';
import { DynamicRoute } from 'util/DynamicRoute';

const ContentsPage = ({ onRanderHandle }) => {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <DynamicRoute
                    routes={AuthedRoutes}
                    onRanderHandle={onRanderHandle}
                ></DynamicRoute>
            </Suspense>
        </>
    );
};

export { ContentsPage };
