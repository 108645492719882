import React, { useEffect } from 'react';
import { useGA4React } from 'ga-4-react';
import { withRouter, useHistory } from 'react-router'
// import { AuthedRoutes, UnauthedRoutes } from '../config/routes';
import { useAppRoot } from 'mux';
import { findMenuUrl } from 'config/routes';
import { excludePath } from 'util/authentication';

const GoogleAnalyticsHOC = withRouter(({ children }) => {
    const history = useHistory();
    const { menu } = useAppRoot();
    const ga = useGA4React();

    const path = history?.location?.pathname; // page_path
    useEffect(() => {
        if (!ga) return;
        if (process.env.NODE_ENV !== 'production') return; // npm build 한 결과물로 실행될때만 적용.
        if (excludePath.includes(path)) return;
        const location = window.location.href; // page_location
        const routeMenuUrl = findMenuUrl(path);
        const currentMenu = _.find(menu, {
            menuUrl: routeMenuUrl,
        });

        const title = currentMenu?.name || path; // page_title
        // console.log('>> env, path, location, title', process.env.NODE_ENV, path, location, title, currentMenu, ga, process.env.REACT_APP_GOOGLE_ANALYTICS_CODE)
        // console.log('>> env, path, ', process.env.NODE_ENV, path, ga, process.env.REACT_APP_GOOGLE_ANALYTICS_CODE)
        if (process.env.REACT_APP_MODE === 'prd') {
            ga.pageview(path, location, title);
            ga.gtag('event', 'pageview', 'path');
        // } else { // 검증계 테스트용
            // ga.pageview(path, location, `[${process.env.REACT_APP_MODE}] - ${title}`);
            // ga.gtag('event', 'pageview', 'path');
        }
    }, [path]);
    return (
        <>
            {children}
        </>
    )
});

export default GoogleAnalyticsHOC;