import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useIntl } from 'react-intl';

import { TextOrDateRangePicker as MuxTextOrDateRangePicker } from 'mux';

const TextOrDateRangePicker = forwardRef((props, ref) => {
    const {
        disable,
        placeholders,
        placeholderIds,
        required,
        validate,
        ...rest
    } = props;

    const intl = useIntl();

    // 기간 버튼
    const periodOptions = useMemo(() => {
        return [
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Today',
                }),
                value: 'TODAY',
            },
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Week.1',
                }),
                value: '1W',
            },
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Month.1',
                }),
                value: '1M',
            },
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Month.3',
                }),
                value: '3M',
            },
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Month.6',
                }),
                value: '6M',
            },
        ];
    }, [intl]);

    // placeholder
    const placeholderMessage = useMemo(() => {
        if (!_.isEmpty(placeholderIds)) {
            return _.map(placeholderIds, (placeholderId) =>
                intl.formatMessage({ id: placeholderId }),
            );
        }
        return placeholders;
    }, [intl, placeholderIds, placeholders]);

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [intl, required, validate]);

    // 현재 위치에 해당하는 modal 을 popupContainer 로 지정 (다중 popup 문제로 추가)
    const getPopupContainer = (trigger) => {
        let curBody = trigger.parentNode;
        const modalBodys = document.getElementsByClassName('ant-modal-body');
        _.map(modalBodys, (element) => {
            if (element.hasChildNodes()) {
                _.map(element.childNodes, (element) => {
                    if (element.contains(trigger)) {
                        curBody = element;
                    }
                });
            }
        });
        return curBody;
    };

    return (
        <MuxTextOrDateRangePicker
            {...rest}
            {...rules}
            periodOptions={periodOptions}
            disabled={disable}
            placeholder={placeholderMessage}
            getPopupContainer={getPopupContainer}
            ref={ref}
        />
    );
});

TextOrDateRangePicker.propTypes = {
    names: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultValues: PropTypes.arrayOf(PropTypes.any),
    values: PropTypes.arrayOf(PropTypes.any),
    disable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool),
    ]),
    placeholders: PropTypes.arrayOf(PropTypes.string),
    placeholderIds: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    format: PropTypes.string,
    btnShow: PropTypes.bool,
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    exportFormat: PropTypes.string, // longtime | moment | format
};

TextOrDateRangePicker.defaultProps = {
    format: 'YYYY-MM-DD',
    separator: '~',
    disable: false,
    required: false,
    editable: true,
    btnShow: true,
    exportFormat: 'longtime',
    placeholderIds: [
        'Common.StartDate.Placeholder',
        'Common.EndDate.Placeholder',
    ],
};

export default TextOrDateRangePicker;
