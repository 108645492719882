import React, { forwardRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import {
    TextOrSelect as MuxTextOrSelect,
    withBaseOption,
    withIncreseNumber,
} from 'mux';

const TextOrSelect = forwardRef((props, ref) => {
    const {
        name,
        value,
        option,
        disable,
        placeholderId,
        placeholder,
        required,
        validate,
        onChangeValue,
        onValueChange,
        orderBy,
        ...rest
    } = props;

    const intl = useIntl();
    const {
        setValue,
        formState: { isDirty, dirtyFields },
    } = useFormContext();

    // placeholder: placeholderId > placeholder
    const placeholderMessage = useMemo(
        () =>
            placeholderId
                ? intl.formatMessage({ id: placeholderId })
                : placeholder,
        [placeholder, placeholderId, intl],
    );

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Select',
            });
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [required, validate]);

    // change 이벤트
    const handleChangeValue = (selectedValue) => {
        if (_.isFunction(onChangeValue)) {
            onChangeValue(selectedValue);
        }

        if (_.isFunction(onValueChange)) {
            onValueChange({ data: { [name]: selectedValue } });
        }
    };

    useEffect(() => {
        if (!dirtyFields[name]) {
            setValue(name, value, { shouldDirty: !!value });
        }
    }, [isDirty, option]);

    // 현재 위치에 해당하는 modal 을 popupContainer 로 지정 (다중 popup 문제로 추가)
    const getPopupContainer = (trigger) => {
        let curBody = trigger.parentNode;
        const modalBodys = document.getElementsByClassName('ant-modal-body');
        _.map(modalBodys, (element) => {
            if (element.hasChildNodes()) {
                _.map(element.childNodes, (element) => {
                    if (element.contains(trigger)) {
                        curBody = element;
                    }
                });
            }
        });
        return curBody;
    };

    // option 정렬변경
    const orderByOptions = useMemo(() => {
        let resultOption = [];
        let orderKey = null;

        if (_.isBoolean(orderBy)) {
            orderKey = orderBy ? 'asc' : null;
        } else if (_.isString(orderBy)) {
            orderKey = orderBy;
        } else if (_.isFunction(orderBy)) {
            return orderBy(option);
        }

        if (!_.isEmpty(orderKey)) {
            const head = _.filter(option, ({ value }) => _.isEmpty(value))
            const body = _.filter(option, ({ value }) => !_.isEmpty(value))
            resultOption = [...head, ..._.orderBy(body, ['label'], orderBy)]
        } else {
            resultOption = option;
        }

        return resultOption;
    }, [option, orderBy])

    return (
        <MuxTextOrSelect
            {...rest}
            {...rules}
            name={name}
            value={value}
            options={orderByOptions}
            placeholder={placeholderMessage}
            disabled={disable}
            onChangeValue={handleChangeValue}
            notFoundContent={<FormattedMessage id="Message.Search.NoData" />}
            getPopupContainer={getPopupContainer}
            ref={ref}
            optionFilterProp="children" // label 검색 기본값.
        />
    );
});

TextOrSelect.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    allowClear: PropTypes.bool,
    defaultActiveFirstOption: PropTypes.bool,
    disable: PropTypes.bool,
    required: PropTypes.bool,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    option: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.any, value: PropTypes.any }),
    ),
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    watch: PropTypes.arrayOf(PropTypes.string),
    onDetected: PropTypes.func,
    onValueChange: PropTypes.func,
    orderBy: PropTypes.any,
};

TextOrSelect.defaultProps = {
    disable: false,
    allowClear: false,
    defaultActiveFirstOption: false,
    required: false,
    editable: true,
    orderBy: false
};

const TextOrSelectWithbaseOption = withBaseOption(TextOrSelect);

TextOrSelectWithbaseOption.propTypes = {
    baseOption: PropTypes.shape({
        resId: PropTypes.string,
        value: PropTypes.any,
    }),
};

const TextOrSelectWithIncreseNumber = withIncreseNumber(
    TextOrSelect,
    1,
    100,
    1,
);

export { TextOrSelectWithbaseOption, TextOrSelectWithIncreseNumber };
export default TextOrSelect;
