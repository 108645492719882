const language = {
    'Common.All': 'Todo',
    'Common.Login': 'Iniciar sesión',
    'Common.Logout': 'Cerrar sesión',
    'Common.Logout.Hello': 'Hola, {user}.',
    'Common.Input.Required': 'Es un valor obligatorio.',
    'Common.Input.EnterSearchKeyword': 'Escriba palabras clave',
    'Common.Btn.Selected': 'Selección',
    'Common.Btn.Select': 'Seleccionar',
    'Common.Btn.AllSearch': 'Búsqueda completa',
    'Common.Btn.Cancel': 'Cancelar',
    'Common.Btn.CancelEdit': 'Cancelar',
    'Common.Btn.CancelExtend': 'Cancelar',
    'Common.Btn.Filter': 'Opción',
    'Common.Btn.Create': 'Registrar',
    'Common.Btn.Edit': 'Modificar',
    'Common.Btn.EditInfo': 'Editar información',
    'Common.Btn.Confirm': 'Aceptar',
    'Common.Btn.EditBusiness': 'Modificar',
    'Common.Btn.EditInformation': 'Modificar',
    'Common.Btn.CompleteReservation': 'Reserva finalizada',
    'Common.Btn.CompleteEdit': 'Modificación finalizada',
    'Common.Btn.Add': 'Añadir',
    'Common.Btn.Delete': 'Eliminar',
    'Common.Btn.InUse': 'Usar',
    'Common.Btn.List': 'Lista',
    'Common.Btn.Return': 'Volver',
    'Common.Btn.ForceEdit': 'Modificación forzada',
    'Common.Btn.ExtendReturn': 'Ampliar fecha de devolución',
    'Common.Btn.CompleteExtend': 'Ampliar fecha de devolución',
    'Common.Btn.ForceReturn': 'Devolución forzada',
    'Common.Btn.CancelReservation': 'Cancelar reserva',
    'Common.Btn.Approve': 'Aprobar',
    'Common.Btn.Deny': 'Rechazar',
    'Common.Btn.Map': 'Seleccionar en el mapa',
    'Common.Btn.SearchWeek': '1 semana',
    'Common.Btn.Search1Month': '1 mes',
    'Common.Btn.Search3Month': '1 mes',
    'Common.Btn.Show.Edit': 'Editar',
    'Common.Btn.AddVehicle': 'Añadir vehículo',
    'Common.Btn.AllSelect': 'Seleccionar todo',
    'Common.Btn.Apply': 'Aplicar',
    'Common.Btn.MultiSelected': 'Se pueden seleccionar varios elementos',
    'Common.Confirm.Create': '¿Desea registrarlo?',
    'Common.Confirm.Update': '¿Desea editarlo?',
    'Common.Confirm.Delete': '¿Desea eliminarlo?',
    'Common.Confirm.Deletes': '¿Desea eliminar {count} casos?',
    'Common.Confirm.Approve': '¿Desea aprobarlo?',
    'Common.Confirm.Deny': '¿Desea rechazarlo?',
    'Common.Confirm.Action': '¿Desea ejecutar la función?',
    'Common.Confirm.UserDelete': '¿Desea cancelar su suscripción?',
    'Common.Confirm.PwdChange': '¿Desea cambiar la contraseña?',
    'Common.Confirm.InUse': '¿Desea cambiar el vehículo al estado En uso?',
    'Common.Alert': 'No se puede ejecutar la función.',
    'Common.Created.Success': 'Registrado.',
    'Common.Updated.Success': 'Modificado.',
    'Common.Deleted.Success': 'Eliminado.',
    'Common.Create.Fail': 'No se puede registrar.',
    'Common.Update.Fail': 'No se puede modificar.',
    'Common.Delete.Fail': 'No se puede eliminar.',
    'Common.Search.Fail': 'La búsqueda ha fallado.',
    'Common.Print': 'Imprimir',
    'Common.Alert.Delete.No.Checked': 'Seleccione un elemento para eliminar.',
    'Common.Alert.Approve.No.Checked': 'Seleccione un elemento para aprobar.',
    'Common.Alert.Deny.No.Checked': 'Seleccione un elemento para rechazar.',
    'Common.Alert.Pay.No.Checked': 'Seleccione un elemento que pagar.',
    'Common.Msg.NoData': 'No se han recuperado datos.',
    'Common.Msg.NoSearchData': 'La búsqueda no ha devuelto resultados.',
    'Common.Msg.NoVehicle': 'El vehículo no existe.',
    'Common.Unit.Distance.meter': 'm',

    'Common.Unit.Money.0': 'EUR',
    'Common.Unit.Money.10000': '0000EUR',
    'Common.Unit.Money.0.Value': '{value} EUR',
    'Common.Unit.Vehicle.Count': 'vehículos',
    'Common.Unit.Vehicle.Count.Value': '{value} vehículos',
    'Common.Unit.Hour': 'horas',
    'Common.Unit.Hour.Value': '{hours} horas',
    'Common.Unit.Minute': 'minutos',
    'Common.Unit.Minute.Value': '{value} minutos',
    'Common.Unit.Age.Over': 'años de antigüedad o más',
    'Common.Unit.DiffDays.DaysHours.Valule': '{days} días {hours} horas',
    'Common.Unit.DiffDays.Days.Valule': '{days} días',
    'Common.Unit.DiffDays.Hours.Valule': '{hours} horas',
    'Common.Today': 'Hoy',
    'Common.Back': 'Anterior',
    'Common.BirthDate.Year': 'Año',
    'Common.BirthDate.Month': 'Mes',
    'Common.BirthDate.Day': '{days} día',
    'Common.Role.Warn': 'No tiene permisos.',
    'Common.Validator.Help.Number': 'Introduzca solo números.',
    'Common.Validator.Help.Email':
        'Introduzca la dirección en un formato correcto.',
    'Common.Validator.Help.MaxNumber':
        'Puede introducir {compareValue} como máximo.',
    'Common.Validator.Help.MinNumber':
        'Puede introducir {compareValue} como mínimo.',

    'Main.Title':
        'Compare las condiciones de alquiler de corto plazo hasta medio a largo,{br}y reserve un vehículo cómodamente.',
    // 'Main.SubTitle': ' ',
    'Main.GettingStart': 'Primeros pasos',

    'Footer.Policy1': 'Términos del servicio',
    'Footer.Policy2': 'Declaración de privacidad',
    'Footer.Mocean': 'MOCEAN',
    'Footer.Address':
        '907-ho, A-dong, Bundang-Suji U-TOWER, 767, Sinsu-ro, Suji-gu, Yongin-si, Gyeonggi-do, Republic of Korea',

    'DayTimePicker.Help.From.Selected': 'Seleccione una fecha de inicio.',
    'DayTimePicker.Help.From.After.Now':
        'La fecha de inicio debe ser anterior a la actual.',
    'DayTimePicker.Help.To.Selected': 'Seleccione una fecha de fin.',
    'DayTimePicker.Help.To.Before.From':
        'La fecha de fin debe ser posterior a la fecha de inicio.',

    'Login.Alert.SystemError': 'Fallo temporal del sistema',
    'Auth.Check.Alert.SystemError':
        'Ha accedido desde una ruta de acceso incorrecta. Inicie sesión de nuevo.',
    'Join.Alert.SystemError': 'Inicie sesión más tarde.',

    'Auth.Alert.Expired':
        'La sesión ha caducado y se ha cerrado su sesión. Inicie sesión de nuevo.',
    'Auth.Alert.Forbidden': 'No tiene permisos.',
    'Auth.Wait': 'Espere unos instantes.',

    'Menu.VehicleManager': 'Gestión de flota',
    'Menu.ReservationManager': 'Gestión de reservas',
    'Menu.VehicleUseManager': 'Finalización del alquiler',
    'Menu.PaymentManager': 'Gestión de ventas/liquidaciones',
    'Menu.Location': 'Gestión de bases',
    'Menu.Terminal': 'Gestión de terminales',
    'Menu.User': 'Gestión de usuarios',
    'Menu.MyPage': 'Mi página',

    'Title.Main': 'Principal',
    'Title.VehicleMonitoring': 'Control del vehículo',
    'Title.VehicleManager': 'Gestión de flota',
    'Title.Vehicle': 'Ver/registrar vehículo',
    'Title.VehicleModel': 'Gestión del modelo de vehículo',
    'Title.Terminal': 'Gestión de terminales',
    'Title.ReservationManager': 'Gestión de reservas',
    'Title.ReservationList': 'Ver detalles de la reserva',
    'Title.ReservationCreate': 'Nueva reserva',
    'Title.MakeReservation.BUSINESS_CORP': 'Nueva reserva (afiliado)',
    'Title.UseList': 'Estado de uso',
    'Title.EndUseList': 'Finalización del alquiler',
    'Title.PaymentList': 'Detalles de pago',
    'Title.Charge': 'Plan de tarifa',
    'Title.ChargeVehicle': 'Gestión del plan de tarifa por vehículo',
    'Title.UseStateList': 'Estado de alquiler',
    'Title.Insurance': 'Prima',
    'Title.InsuranceVehicle': 'Gestión de primas por vehículo',
    'Title.PenaltyAndToll': 'Penalización/cargo',
    'Title.Location': 'Base',
    'Title.LocationCreate': 'Registro de base',
    'Title.LocationEdit': 'Modificación de base',
    'Title.Member': 'Responsable',
    'Title.Business': 'Gestión de operadores',
    'Title.BusinessCreate': 'Registro de operador',
    'Title.Customer': 'Gestión de suscripciones',

    'Monitoring.LatestUpdate': 'Última actualización',
    'Monitoring.ViewUnavailableVehicle': 'Ver vehículo con problema',
    'Monitoring.ViewAllVehicle': 'Ver todos los vehículos',
    'Monitoring.CurrentLocation': 'Ubicación actual',
    'Monitoring.Location': 'Ubicación de la base',
    'Monitoring.SeeMore': 'Ver más',
    'Monitoring.NoUnavailableVehicle': 'No hay ningún vehículo con problemas.',
    'Monitoring.Vehicle.DTE': 'Nivel de combustible',
    'Monitoring.Vehicle.AUXbattery': 'Voltaje de la batería',
    'Monitoring.Vehicle.Connectivity': 'Estado de comunicación',
    'Monitoring.Unknown': 'Desconocido',
    'Monitoring.Normal': 'Normal',

    'Reservation.Btn.Create': 'Nueva reserva',
    'Reservation.Btn.Filter': 'Opción',
    'Reservation.Btn.Selected': 'Reserva finalizada',
    'Reservation.Code': 'N.º de reserva',
    'Reservation.Birth': 'Fecha de nacimiento',
    'Reservation.Birth.Placeholder': 'YYYY.MM.DD',
    'Reservation.BirthYear.Placeholder': '2000',
    'Reservation.BirthMonth.Placeholder': '0',
    'Reservation.BirthDate.Placeholder': '0',
    'Reservation.Address': 'Dirección',
    'Reservation.Address.Zip.Placeholder': 'Código postal',
    'Reservation.Address.Placeholder': 'Introduzca la dirección.',
    'Reservation.DetailAddress.Placeholder': 'Introduzca la dirección postal.',
    'Reservation.Address.Search': 'Buscar código postal',
    'Reservation.LicenseInfo': 'Información del permiso de conducir',
    'Reservation.License': 'N.º del permiso de conducir',
    'Reservation.License.Placeholder': 'N.º del permiso de conducir',
    'Reservation.License.Confirm': 'Autenticación del permiso',
    'Reservation.License.ConfirmFinish': 'Autenticado',
    'Reservation.License.DueDate': 'Fecha de caducidad del permiso',
    'Reservation.License.DueDate.DayPicker':
        'Introduzca la fecha de caducidad del permiso.',
    'Reservation.License.Check': 'Complete la autenticación del permiso.',
    'Reservation.License.AdditionalCheck':
        'Complete la autenticación del permiso del segundo conductor.',
    'Reservation.License.ValidCheck':
        'Complete todos los campos para autenticar el permiso.',
    'Reservation.Edit.Warn':
        'No se puede modificar la reserva una vez superada la hora de inicio de la reserva.',
    'Reservation.GetProfile.NoData': 'Los datos no coinciden con ningún socio',
    'Reservation.GetEmployeeProfile.NoData':
        'No se encuentra la información del empleado',
    'Reservation.GetProfile.fail':
        'No se pudo encontrar la información del socio.',
    'Reservation.Location.NotMatch':
        'No se puede devolver, ya que la ubicación de devolución no coincide.',
    'Reservation.RemoteControl.Fail':
        'El control del vehículo ha fallado. No se puede devolver el vehículo.',
    'Reservation.PaymentDetail.IsNull':
        'No tiene ningún plan de tarifa que editar.',
    'Reservation.Date.IsEqual':
        'La fecha de fin de la reserva debe ser posterior a la fecha de inicio.',
    'Reservation.Date.Invalid':
        'La fecha de inicio de la reserva no puede ser anterior a hoy.',
    'Reservation.AlreadyUseCarExist.Fail':
        'Ya hay un coche en uso. No es posible realizar la reserva.',
    'Reservation.VehicleUsePay.IsZero':
        'Su plan no existe o está ajustado en 0 EUR.',
    'Reservation.Extend.CheckEndDate':
        'La fecha de devolución ampliada debe ser posterior a la fecha de fin de la reserva.',
    'Reservation.Deny.DefaultMessage': 'Introduzca los detalles.',
    'Reservation.TotalPay.Changed': 'La tarifa ha cambiado.',
    'Reservation.Insurance.Invalid':
        'La reserva no es posible porque la fecha de caducidad del seguro ha pasado.',
    'Reservation.InsuranceEdit.Invalid':
        'La reserva no se puede modificar porque la fecha de caducidad del seguro ha pasado.',
    'Reservation.UserNameInfo':
        'Información de la persona que realiza la reserva',
    'Reservation.UserName': 'Persona que realiza la reserva',
    'Reservation.Name': 'Nombre',
    'Reservation.CustName': 'Nombre del cliente',
    'Reservation.Status': 'Estado',
    'Reservation.ReservationStatus': 'Estado de la reserva',
    'Reservation.UserName.Placeholder':
        'Introduzca el nombre de la persona que realiza la reserva.',
    'Reservation.UserName.Confirm': 'Buscar socios existentes',
    'Reservation.UserName.ReConfirm': 'Cambiar persona que realiza la reserva',
    'Reservation.UserInfo.Search': 'Buscar información del socio',
    'Reservation.UserInfo.Confirm': 'Comprobar información del socio',
    'Reservation.Employee.Confirm': 'Buscar empleado',
    'Reservation.EmployeeInfo.Search': 'Buscar información del empleado',
    'Reservation.EmployeeInfo.Confirm': 'Comprobar información del empleado',
    'Reservation.Share.UserName': 'Segundo conductor',
    'Reservation.Share.UserName.Placeholder':
        'Introduzca el nombre del segundo conductor.',
    'Reservation.Mobile': 'N.º de teléfono',
    'Reservation.Mobile.Placeholder': 'N.º de teléfono',
    'Reservation.Team': 'Equipo',
    'Reservation.Business': 'Organización',
    'Reservation.Team.Placeholder': 'Equipo',
    'Reservation.CampanyNumber': 'N.º de empleado',
    'Reservation.CampanyNumber.Placeholder': 'N.º de empleado',
    'Reservation.EmailAddress': 'Dirección de correo electrónico',
    'Reservation.EmailAddress.Placeholder':
        'Introduzca la dirección de correo electrónico.',
    'Reservation.Insurance.Fee': 'Prima',
    'Reservation.Rent.Fee': 'Tarifa de alquiler',
    'Reservation.BusinessInfo': 'Seleccionar operador',
    'Reservation.Business.Rent': 'General',
    'Reservation.Business.Corp': 'Empresa',
    'Reservation.VehicleInfo': 'Información del vehículo',
    'Reservation.VehicleUseFeeInfo': 'Tarifa de uso del vehículo',
    'Reservation.UseFeeInfo': 'Tarifa de uso',
    'Reservation.VehicleModel': 'Tipo de vehículo',
    'Reservation.BusinessName': 'Nombre de la empresa',
    'Reservation.DateRange': 'Periodo de reserva',
    'Reservation.RentalPeriod': 'Periodo de alquiler',
    'Reservation.MobileReservation': 'Reserva móvil',
    'Reservation.Date': 'Fecha y hora de la reserva',
    'Reservation.DateRange.Placeholder.Start': 'Fecha de inicio',
    'Reservation.DateRange.Placeholder.End': 'Fecha de fin',
    'Reservation.InsuranceAge': 'Antigüedad del seguro',
    'Reservation.InsuranceAge.Placeholder':
        'Seguro de conductor para 21 años o más',
    'Reservation.UseFee': 'Tarifa de uso del vehículo',
    'Reservation.ExtendFee': 'Tarifa de ampliación',
    'Reservation.UseFee.Result': 'Tarifa por kilometraje {fee} EUR/km',
    'Reservation.StartLocation': 'Lugar de entrega',
    'Reservation.StartLocation.Placeholder':
        'Seleccione el lugar de entrega del vehículo',
    'Reservation.StartLocation.Button': 'Seleccionar en el mapa',
    'Reservation.VehicleNumber': 'N.º de vehículo',
    'Reservation.DenyReson': 'Motivo del rechazo',
    'Reservation.LicenseType': 'Tipo de licencia',
    'Reservation.VehicleNumber.Placeholder': 'Seleccione un vehículo.',
    'Reservation.UseFeeInfo.Placeholder': '0',
    'Reservation.UseFee.Placeholder': '0',
    'Reservation.ExtendFee.Placeholder': '0',
    'Reservation.Confirm.ExtendFee': 'Introduzca una tarifa de ampliación.',
    'Reservation.BusinessType.Placeholder': 'Seleccione un operador.',
    'Reservation.Confirm.Return': '¿Desea devolver el vehículo?',
    'Reservation.Confirm.ForceReturn':
        '¿Desea devolver el vehículo por la fuerza?',
    'Reservation.Confirm.Extend': '¿Desea ampliar la fecha de devolución?',
    'Reservation.Confirm.Cancel': '¿Desea cancelar su reserva?',
    'Reservation.Confirm.Confirm': '¿Desea aprobarlo?',
    'Reservation.Confirm.Deny': '¿Desea rechazarlo?',
    'Reservation.DenyReason.Confirm': 'Introduzca el motivo de la devolución.',
    'Reservation.Return.Complete': 'Devuelto.',
    'Reservation.ForceReturn.Complete': 'Devuelto por la fuerza.',
    'Reservation.Extend.Complete': 'Fecha de devolución ampliada',
    'Reservation.Confirm.Complete': 'Reserva aprobada.',
    'Reservation.Cancel.Complete': 'Reserva cancelada.',
    'Reservation.Deny.Complete': 'Reserva rechazada.',
    'Reservation.Delete.Complete': 'Reserva eliminada.',
    'Reservation.Update.Complete': 'Reserva modificada.',
    'Reservation.Create.Complete': 'Reserva registrada.',
    'Reservation.InUse.Complete': 'La reserva se cambió al estado En uso.',
    'Reservation.InsruanceAge.ConfirmFail':
        'El vehículo seleccionado no puede reservarse debido a la edad de la persona que realiza la reserva.',
    'Reservation.AdditionalInsruanceAge.ConfirmFail':
        'El vehículo seleccionado no puede reservarse debido a la edad del segundo conductor.',
    'Reservation.LicenseType.Placeholder': 'Tipo de licencia',
    'Reservation.InsuranceList': 'Tipo de seguro',
    'Reservation.InsuranceList.Placeholder': 'Seleccione un tipo de seguro.',
    'Reservation.Complete.NameMobileIsNull':
        'Introduzca tanto la persona que realiza la reserva como el número de teléfono.',
    'Reservation.Extend.AlreadyReserved':
        'El vehículo que reservó está disponible a esa hora.',
    'Reservation.InsuranceAge.IsOver': '{insuranceAge} años o más',
    'Reservation.List.Fail':
        'No se pueden recuperar los detalles de la reserva.',
    'Reservation.Approve.Fail': 'La autorización ha fallado.',
    'Reservation.Deny.Fail': 'La devolución ha fallado.',
    'Reservation.GetRentCar.Fail':
        'No se puede recuperar la lista de empresas de alquiler.',
    'Reservation.EndList.Fail':
        'No se pueden recuperar los detalles de finalización del alquiler.',
    'Reservation.GetAvailable.Fail':
        'No se puede recuperar la lista de vehículos disponibles.',
    'Reservation.GetLocation.Fail': 'No se puede recuperar la lista de bases.',
    'Reservation.MustHave.Fail': 'Cumplimente todos los campos obligatorios.',
    'Reservation.AddReservation.Fail': 'No se pudo registrar la reserva.',
    'Reservation.AlreadyReserved.Fail': 'El vehículo ya está reservado.',
    'Reservation.GetUseList.Fail': 'No se puede recuperar la lista de uso.',
    'Reservation.GetTimeList.Fail': 'No se puede recuperar la lista de uso.',
    'Reservation.Return.Fail': 'No se puede devolver el vehículo.',
    'Reservation.Return.StartFail':
        'No puede devolver el vehículo antes de la hora de inicio de la reserva.',
    'Reservation.ForceReturn.Fail':
        'No se pudo devolver el vehículo por la fuerza.',
    'Reservation.ForceReturn.StartFail':
        'No puede devolver el vehículo antes de la hora de inicio de la reserva.',
    'Reservation.Cancel.Fail': 'No se puede cancelar la reserva.',
    'Reservation.Inuse.Fail':
        'No se puede cambiar el vehículo al estado En uso.',
    'Reservation.InUse.TimeIsInvalid':
        'Puede cambiar el vehículo al estado En uso 15 minutos antes de la hora de inicio.',
    'Reservation.Delete.Fail': 'No se puede eliminar la reserva.',
    'Reservation.Extend.Fail': 'No se puede ampliar la fecha de devolución.',
    'Reservation.Update.Fail': 'No se puede modificar la reserva.',
    'Reservation.LicenseCheck.Fail': 'La autenticación del permiso ha fallado.',
    'Reservation.LicenseCheck.Invalid':
        'La información del permiso no es válida. Compruebe la información de nuevo.',
    'Reservation.LicenseExpiryDate.InValid':
        'La fecha de inicio del alquiler supera la fecha de caducidad de renovación del permiso.',
    'Reservation.LicenseExpiryDate.AddTimeIsInvalid':
        'La fecha de inicio del alquiler supera la fecha de caducidad de renovación del permiso del segundo conductor.',
    'Reservation.GetPaymentDetail.Fail':
        'No se pudo recuperar la información de tarifa.',
    'Reservation.GetInsuranceList.Fail':
        'No se pudo recuperar la lista de seguros.',
    'Reservation.View.Fail':
        'No se pudo recuperar la información de la reserva.',
    'Reservation.CurrentVehicle.AlreadyReserved':
        'El vehículo ya está reservado.',
    'Reservation.Edit.NotAvailable': 'No se puede modificar la reserva.',
    'Reservation.OfflinePayment.Notify':
        'Solo puede pagar la tarifa de uso sin conexión.',
    'Reservation.DenyReason.Placeholder':
        'Introduzca el motivo de la devolución.',
    'Reservation.Payment.NotMinTime':
        'El periodo de reserva es menor que el periodo de uso mínimo.',
    'Reservation.Payment.NotMinUseTime':
        'El periodo ampliado es menor que el periodo de uso mínimo.',
    'Reservation.Payment.NoCharge':
        'No hay ningún plan de tarifa establecido para el vehículo.',
    'Reservation.Payment.NoInsurance':
        'No hay ninguna prima establecida para el vehículo.',

    'Reservation.Filter.LicensePlateNumber': 'N.º de vehículo',
    'Reservation.Filter.Code': 'N.º de reserva',
    'Reservation.Filter.User.Name':
        'Nombre de la persona que realiza la reserva',
    'Reservation.Filter.User.MobileNumber':
        'N.º de teléfono de la persona que realiza la reserva',
    'Reservation.Filter.Location.Name': 'Nombre de la base',
    'Reservation.Filter.Business.Name': 'Nombre de la empresa',

    'Reservation.DayTimePicker.Title': 'Seleccione una fecha de alquiler.',
    'Rerservation.DayPicker.Title':
        'Introduzca una fecha de alquiler y una fecha de devolución.',
    'Rerservation.DayPicker.StartTime': 'Fecha de alquiler',
    'Rerservation.DayPicker.EndTime': 'Fecha de devolución',
    'Rerservation.Btn.All.Confirm': 'Aprobación completa',
    'Rerservation.Btn.Confirm': 'Aprobar',
    'Rerservation.Btn.Deny': 'Rechazar',
    'Reservation.DateFormat.Placeholder': 'YYYY.MM.DD',

    'UseStateCalendar.No': 'NO.',
    'UseStateCalendar.LicensePlateNumber': 'N.º de vehículo',

    'ReservationCalendar.Calendar.Item.Label.requested':
        'A la espera de aprobación :  {count}',
    'ReservationCalendar.Calendar.Item.Label.confirmed': 'Reservas : {count}',
    'ReservationCalendar.Calendar.Item.Label.canceled':
        'Cancelar reserva : {count}',
    'ReservationCalendar.Calendar.Item.Label.returned':
        'Pendiente de devolución : {count}',
    'ReservationCalendar.Admin.Title': 'Estado de la reserva',
    'ReservationCalendar.Admin.Item': '{businessName}{br}{reservationCount}',
    'ReservationCalendar.Private.Desc':
        'Las reservas desautorizadas durante el periodo de reserva se cancelarán automáticamente.',
    'ReservationCalendar.Private.Requested.Title': 'A la espera de aprobación',
    'ReservationCalendar.Private.Requested.Item':
        'A la espera de aprobación : {requestedCount}',
    'ReservationCalendar.Private.Confirmed.Title': 'Estado de la reserva',
    'ReservationCalendar.Private.Confirmed.Item': '{groupName} {length}',
    'ReservationCalendar.Private.Group.Confirmed.Item':
        '{licensePlateNumber}{br}Persona que realiza la reserva {user}',
    'ReservationCalendar.Private.Group.Requested.Item':
        '{licensePlateNumber}{br}{teamName} / {user}',
    'ReservationCalendar.Private.Confirm.Approve':
        '¿Desea aprobar los elementos marcados?',
    'ReservationCalendar.Private.Confirm.Approve.All':
        '¿Desea conceder una aprobación total?',
    'ReservationCalendar.Public.Confirmed.Title': 'Estado de la reserva',
    'ReservationCalendar.Public.Confirmed.Item':
        '[{code}] {reservationTime}{br}{name} / {totalPay} EUR //[{code}]{reservationTime}{br}{name}/{totalPay} EUR',
    'ReservationCalendar.Public.Retruned.Item':
        '[{code}] {reservationTime}{br}{name}',
    'ReservationCalendar.Public.Returned.Title': 'Pendiente de devolución',
    'ReservationCalendar.Public.Returned.Item':
        '{reservationTime}{br}{code} / {name}',
    'ReservationCalendar.Month.Get.Fail':
        'No se puede recuperar el estado de la reserva mensual.',
    'ReservationCalendar.Business.Get.Fail':
        'No se puede recuperar el estado de la reserva por empresa.',
    'ReservationCalendar.Public.Get.Fail':
        'No se puede recuperar el estado de alquiler.',
    'ReservationCalendar.Private.Get.Fail':
        'No se puede recuperar el estado de la reserva de la empresa.',

    'Vehicle.Information': 'Información del vehículo',
    'Vehicle.State.Sub.Title': 'Información del vehículo',
    'Vehicle.Reservation.Sub.Title': 'Estado de la reserva',
    'Vehicle.Model': 'Modelo de vehículo',
    'Vehicle.Color': 'Color del vehículo',
    'Vehicle.Transmission': 'Tipo de caja de cambios',
    'Vehicle.LicensePlateNumber': 'N.º de registro del vehículo',
    'Vehicle.VIN': 'VIN No.',
    'Vehicle.Status': 'Estado',
    'Vehicle.ControllerID': 'N.º de teléfono del terminal',
    'Vehicle.RegistrationDate': 'Fecha de registro del vehículo',
    'Vehicle.ProductionDate': 'Fecha de fabricación',
    'Vehicle.Options': 'Opción',
    'Vehicle.Business': 'Empresa poseedora',
    'Vehicle.Location': 'Base',
    'Vehicle.Insurance.Company': 'Empresa aseguradora',
    'Vehicle.Insurance.Age': 'Antigüedad del seguro',
    'Vehicle.Insurance.JoinDate': 'Fecha de suscripción del seguro',
    'Vehicle.Insurance.ExpireDate': 'Fecha de caducidad',
    'Vehicle.Insurance.PD': 'Límite de responsabilidad por objetos',
    'Vehicle.Insurance.BI': 'Límite de responsabilidad por personas',
    'Vehicle.Insurance.DB': 'Límite de seguro de ocupantes',
    'Vehicle.RegistrationDate.Placeholder': 'ex) 20190101',
    'Vehicle.Model.Placeholder': 'Seleccione un modelo.',
    'Vehicle.Transmission.Placeholder':
        'Seleccione un tipo de caja de cambios.',

    'Vehicle.VIN.Placeholder':
        'El VIN está compuesto por 17 caracteres, incluidas letras del alfabeto inglés y números',
    'Vehicle.VIN.Help':
        'El VIN está compuesto por 17 caracteres, incluidas letras del alfabeto inglés y números',

    'Vehicle.ControllerID.Help':
        'El número de teléfono solo puede estar compuesto por números.',
    'Vehicle.Business.Placeholder': 'Seleccione una empresa.',
    'Vehicle.Location.Placeholder': 'Seleccione una base.',
    'Vehicle.Options.Placeholder': 'Seleccione una opción.',
    'Vehicle.Options.No.Data': 'Seleccione una opción.',
    'Vehicle.Insurance.Company.Placeholder':
        'Seleccione una compañía aseguradora.',
    'Vehicle.Insurance.Age.Placeholder': 'Seleccione la antigüedad del seguro.',
    'Vehicle.Insurance.JoinDate.Placeholder':
        'Introduzca la fecha de suscripción del seguro.',
    'Vehicle.Insurance.PD.Placeholder':
        'Introduzca el límite de responsabilidad por objetos.',
    'Vehicle.Insurance.BI.Placeholder':
        'Introduzca el límite de responsabilidad por personas.',
    'Vehicle.Insurance.DB.Placeholder':
        'Introduzca el límite del seguro de ocupantes.',
    'Vehicle.ControllerID.Help.Already.Use':
        'El número de teléfono del terminal introducido ya está en uso.',
    'Vehicle.VIN.Help.Already.Use': 'El VIN introducido ya está en uso.',
    'Vehicle.Btn.Controller': 'Comprobar terminal',
    'Vehicle.View.Fail': 'No se puede recuperar la información del vehículo.',
    'Vehicle.Delete.Fail': 'Los vehículos en uso no pueden eliminarse.',
    'Vehicle.Confirm.Delete': '¿Desea eliminar la información del vehículo?',
    'Vehicle.Confirm.Deletes':
        '¿Desea eliminar los elementos marcados?{br}Los vehículos en uso no pueden eliminarse.',

    'Vehicle.State.TpmsFl': 'Rueda delantera izquierda',
    'Vehicle.State.TpmsFr': 'Rueda delantera derecha',
    'Vehicle.State.TpmsRl': 'Rueda trasera izquierda',
    'Vehicle.State.TpmsRr': 'Rueda trasera derecha',
    'Vehicle.State.Tpms.Unit': 'PSI',
    'Vehicle.State.Battery': 'Voltaje de la batería',
    'Vehicle.State.FuelLevel': 'Nivel de combustible',

    'Vehicle.Confirm.Control.Open': '¿Desea abrir la puerta?',
    'Vehicle.Confirm.Control.Close': '¿Desea cerrar la puerta?',
    'Vehicle.Confirm.Control.Siren':
        '¿Desea activar las luces de emergencia o el claxon?',
    'Vehicle.Control': 'Control del vehículo',
    'Vehicle.Control.Open': 'Abrir puerta',
    'Vehicle.Control.Close': 'Cerrar puerta',
    'Vehicle.Control.Siren': 'Luces de emergencia/claxon',
    'Vehicle.Control.Open.Action': 'Activar',
    'Vehicle.Control.Close.Action': 'Activar',
    'Vehicle.Control.Siren.Action': 'Activar',
    'Vehicle.Control.Requested.Success': 'Se ha enviado la señal al vehículo.',
    'Vehicle.Control.Request.Fail': 'No se puede enviar la señal al vehículo.',
    'Vehicle.Control.Opened.Success': 'La puerta está abierta.',
    'Vehicle.Control.Closed.Success': 'La puerta está cerrada.',
    'Vehicle.Control.Siren.Success':
        'Se han activado las luces de emergencia/el claxon.',
    'Vehicle.Control.Fail':
        'Se ha producido un error durante el control del vehículo.',
    'Vehicle.Control.Fail.Code':
        'Póngase en contacto con el administrador. [{code}]',
    'Vehicle.Control.Timeout':
        'Se ha retrasado la recepción del resultado de control.',

    'VehicleModel.Information': 'Información del vehículo',
    'VehicleModel.Name': 'Nombre del modelo',
    'VehicleModel.Name.Placeholder': 'Introduzca un nombre de modelo.',
    'VehicleModel.Image.Helper': 'Se debe registrar una imagen del vehículo.',
    'VehicleModel.Brand': 'Fabricante',
    'VehicleModel.Brand.Placeholder': 'Seleccione un fabricante.',
    'VehicleModel.FuelType': 'Tipo de combustible',
    'VehicleModel.FuelType.Placeholder': 'Seleccione un tipo de combustible.',
    'VehicleModel.SeatingCapacity': 'Número de pasajeros',
    'VehicleModel.SeatingCapacity.Placeholder':
        'Introduzca el número de pasajeros.',
    'VehicleModel.SeatingCapacityP': 'Número de pasajeros',
    'VehicleModel.Displacement': 'Cilindrada (cc)',
    'VehicleModel.Displacement.Placeholder': 'ex) 1800',
    'VehicleModel.FuelTankCapacity': 'Capacidad del depósito de combustible',
    'VehicleModel.FuelTankCapacity.Placeholder': 'ex) 70',
    'VehicleModel.FuelTankCapacityE': 'Capacidad de la batería (kWh)',
    'VehicleModel.FuelEfficiency': 'Consumo de combustible (km/l)',
    'VehicleModel.FuelEfficiency.Helper':
        'Para el consumo de combustible solo puede introducir un número entero o un número decimal; p. ej., 12,5',
    'VehicleModel.FuelEfficiency.Placeholder': 'ex) 12.5',
    'VehicleModel.Grade': 'Tipo de vehículo',
    'VehicleModel.Grade.Placeholder': 'Tipo de vehículo',
    'VehicleModel.Standard': 'Modelo principal',
    'VehicleModel.StandardTag': 'Principal',
    'VehicleModel.Confirm.Deletes':
        '¿Desea eliminar {count} modelos?{br}No puede eliminar modelos registrados.',
    'VehicleModel.GetData.Fail': 'No se pudo recuperar el modelo de vehículo.',
    'VehicleModel.Create.Success': 'Se ha registrado el modelo de vehículo.',
    'VehicleModel.Create.Fail': 'No se pudo registrar el modelo de vehículo.',
    'VehicleModel.Update.Success': 'Se ha modificado el modelo de vehículo.',
    'VehicleModel.Update.Fail': 'No se pudo modificar el modelo de vehículo.',
    'VehicleModel.UpdateImage.Fail':
        'No se pudo registrar la imagen del modelo de vehículo.',
    'VehicleModel.Delete.Success': 'Se ha eliminado el modelo de vehículo.',
    'VehicleModel.Delete.Fail': 'No se pudo eliminar el modelo de vehículo.',
    'VehicleModel.Fail.DuplicatedModelName':
        '모델명 중복으로 기능을 수행할 수 없습니다.',

    'Terminal.Title.Create': 'Registrar terminal',
    'Terminal.Title.Info': 'Detalles del terminal',
    'Terminal.Vehicle.LicensePlateNumber': 'N.º de vehículo',
    'Terminal.VIN': 'VIN No.',
    'Terminal.NadID': 'N.º de teléfono',
    'Terminal.Serial': 'Serial No.',
    'Terminal.Manufacturer': 'Fabricante',
    'Terminal.Version': 'S/W Ver.',
    'Terminal.OrderDate': 'Fecha del pedido',
    'Terminal.DeliveryDate': 'Fecha de entrega',
    'Terminal.MobileOperator': 'Operador de comunicaciones',
    'Terminal.ActivationDate': 'Fecha de activación del terminal',
    'Terminal.ICCID': 'N.º de USIM',
    'Terminal.State': 'Estado del terminal',
    'Terminal.Status': 'Gestión de terminales',
    'Terminal.State.Status': 'Estado del terminal',
    'Terminal.VIN.Placeholder':
        'El VIN está compuesto por 17 caracteres, incluidas letras del alfabeto inglés y números',
    'Terminal.NadID.Placeholder': 'Introduzca el número de teléfono.',
    'Terminal.Manufacturer.Placeholder': 'Introduzca el fabricante.',
    'Terminal.Serial.Placeholder': 'ex) CASD12434052DS888',
    'Terminal.Version.Placeholder': 'Introduzca la versión de software.',
    'Terminal.OrderDate.Placeholder': 'ex) 20191201',
    'Terminal.DeliveryDate.Placeholder': 'ex) 20191201',
    'Terminal.MobileOperator.Placeholder': 'Introduzca un operador.',
    'Terminal.ActivationDate.Placeholder': 'ex) 20191201',
    'Terminal.ICCID.Placeholder': 'Introduzca el número',
    'Terminal.State.Placeholder': 'Mostrar automáticamente tras la conexión',
    'Terminal.Status.Placeholder':
        'Seleccione el estado de gestión del terminal.',
    'Terminal.Get.Fail': 'No se puede recuperar la información del terminal.',

    'Charge.Btn.Business.List': 'Lista de empresas',
    'Charge.Title': 'Plan de tarifa',
    'Charge.Name': 'Nombre del plan de tarifa',
    'Charge.Reservation': 'Tiempo de reserva mínimo',
    'Charge.PriceTime': 'Unidad de tiempo para cargos',
    'Charge.Price.Helper':
        'La tarifa debe ser un número entero mayor que cero.',
    'Charge.Week_day_charge': 'Tarifa de lunes a viernes',
    'Charge.Week_end_charge': 'Tarifa de fin de semana',
    'Charge.Insurance': 'Prima todo riesgo',
    'Charge.Peak_season_week_day_charge':
        'Tarifa de lunes a viernes en temporada alta',
    'Charge.Peak_season_week_end_charge':
        'Tarifa de fin de semana en temporada alta',
    'Charge.Peak_season': 'Establecer periodo',
    'Charge.TimePrice': 'Tabla de tarifas por tiempo de alquiler',
    'Charge.Vehicle.Apply': 'Vehículo aplicable',
    'Charge.WeekDay': 'De lunes a viernes',
    'Charge.WeekEnd': 'Fin de semana',
    'Charge.PeakWeekDay': 'Lunes a viernes en temporada alta',
    'Charge.PeakWeekEnd': 'Fin de semana en temporada alta',
    'Charge.VehicleNoData':
        'Añada un vehículo al que se aplicará el plan de tarifa.',
    'Charge.PriceTimeUnit': 'Unidad',
    'Charge.MinReservation': 'Tiempo mínimo',
    'Charge.Vehiclemodel.Name': 'Tipo de vehículo',
    'Charge.Vehicle.licenseplatenumber': 'N.º de vehículo',
    'Charge.Vehicle.Charge': 'Plan de tarifa',
    'Charge.PickerInfoMsg':
        '* Si no introduce la tarifa para temporada alta, se aplicará la tarifa normal.',
    'Charge.Vehicle': 'Vehículo',
    'Charge.Vehicle.Msg': 'Vehículo',
    'Charge.Name.Placeholder': 'Introduzca un plan de tarifa.',
    'Charge.Insurance.Placeholder': 'Seleccione una prima todo riesgo.',
    'Charge.Peak_season_start.Placeholder': 'Fecha de inicio',
    'Charge.Peak_season_end.Placeholder': 'Fecha de fin',
    'Charge.Week_day_charge.Placeholder': '0',
    'Charge.Week_end_charge.Placeholder': '0',
    'Charge.Peak_season_week_day_charge.Placeholder': '0',
    'Charge.Peak_season_week_end_charge.Placeholder': '0',
    'Charge.PriceTime_hour.Placeholder': '0 horas',
    'Charge.PriceTime_minute.Placeholder': '0 minutos',
    'Charge.Reservation_hour.Placeholder': '0 horas',
    'Charge.Reservation_minute.Placeholder': '0 minutos',
    'Charge.WarningMsg':
        'Se incluyen los vehículos con otros planes de tarifa.{br}Si no desea cambiar su plan, desmarque la casilla.',
    'Charge.NotCreate': 'No registrado',
    'Charge.Vehicle.NoData': 'No hay vehículo',
    'Charge.Confirm.Deletes':
        '¿Desea eliminar los elementos marcados?{br}A los vehículos reservados con este plan de tarifa se les cobrará la tarifa anterior a la eliminación.',
    'Charge.Vehicle.Confirm.Deletes': '¿Desea eliminar los elementos marcados?',
    'Charge.First_discount': 'Descuento por alquiler de 3 días o más (%)',
    'Charge.Second_discount': 'Descuento por alquiler de 7 días o más (%)',
    'Charge.Toast.Business.Fail':
        'No se pudo recuperar la lista de operadores.',
    'Charge.Toast.ChargeList.Fail':
        'No se pudo recuperar la lista de planes de tarifa.',
    'Charge.Toast.ChargeDetail.Fail':
        'No se pudieron recuperar los detalles del plan de tarifa.',
    'Charge.Toast.ChargeCreate.Success': 'Se ha registrado el plan de tarifa.',
    'Charge.Toast.ChargeCreate.Fail': 'No se pudo registrar el plan de tarifa.',
    'Charge.Toast.ChargeUpdate.Success': 'Se ha modificado el plan de tarifa.',
    'Charge.Toast.ChargeUpdate.Fail': 'No se pudo modificar el plan de tarifa.',
    'Charge.Toast.DetailVehicleList.Fail':
        'No se pudo recuperar la lista de vehículos que tienen el plan de tarifa.',
    'Charge.Toast.VehicleList.Fail':
        'No se pudo recuperar la lista de vehículos.',
    'Charge.Toast.Delete.Success': 'Se ha eliminado el plan de tarifa.',
    'Charge.Toast.Delete.Fail':
        'Se produjo un fallo debido al plan de tarifa reservado.',
    'Charge.Toast.ChargeDelete.Fail': 'No se pudo eliminar el plan de tarifa.',
    'Charge.Toast.VehicleChargeDelete.Success':
        'Se ha eliminado el plan de tarifa.',
    'Charge.Toast.VehicleChargeDelete.Fail':
        'No se pudo eliminar el plan de tarifa.',
    'Charge.Toast.VehicleChargeList.Fail':
        'No se pudo recuperar la lista de modelos del vehículo.',

    'Insurance.Vehicle': 'Vehículo',
    'Insurance.GroupName': 'Nombre del grupo de primas',
    'Insurance.Info': 'Información de la prima',
    'Insurance.Name': 'Nombre del seguro',
    'Insurance.SelfCost': 'Seguro deducible (EUR)',
    'Insurance.Time': 'Unidad de tiempo para cargos',
    'Insurance.Premium': 'Prima (EUR)',
    'Insurance.TimePrice': 'Prima por tiempo de alquiler',
    'Insurance.Charge1': 'Prima 1',
    'Insurance.Charge2': 'Prima 2',
    'Insurance.Charge3': 'Prima 3',
    'Insurance.WarningMsg':
        'Hay vehículos con otras primas.{br}Si no desea cambiar la prima, desmarque la casilla.',
    'Insurance.Confirm.Deletes':
        '¿Desea eliminar los elementos marcados?{br}A los vehículos reservados con esta prima se les cobrará la tarifa anterior a la eliminación.',
    'Insurance.Toast.InsuranceList.Fail':
        'No se pudo recuperar la lista de primas.',
    'Insurance.Toast.InsuranceDetail.Fail':
        'No se pudieron recuperar los detalles de la prima.',
    'Insurance.Toast.InsuranceUpdate.Success': 'La prima se ha modificado.',
    'Insurance.Toast.InsuranceUpdate.Fail': 'No se pudo modificar la prima.',
    'Insurance.Toast.InsuranceDelete.Success': 'La prima se ha eliminado.',
    'Insurance.Toast.InsuranceDelete.Fail': 'No se pudo eliminar la prima.',
    'Insurance.Toast.InsuranceCreate.Success': 'La prima se ha registrado.',
    'Insurance.Toast.InsuranceCreate.Fail': 'No se pudo registrar la prima.',
    'Insurance.Toast.DetailVehicleList.Fail':
        'No se pudo recuperar la lista de vehículos que tienen la prima.',
    'Insurance.Toast.VehicleChargeList.Fail':
        'No se pudo recuperar la lista de modelos del vehículo.',
    'Insurance.Toast.VehicleChargeDelete.Success':
        'La prima por vehículo se ha eliminado.',
    'Insurance.Toast.VehicleChargeDelete.Fail':
        'No se pudo eliminar la prima por vehículo.',
    'Insurance.Toast.Delete.Fail':
        'Se produjo un fallo debido a la prima reservada.',
    'Insurance.Toast.Business.Fail':
        'No se pudo recuperar la lista de operadores.',

    'SalesManager.AppReservation': 'Reserva desde la aplicación',
    'SalesManager.FMSReservation': 'Reserva desde la web',
    'SalesManager.Use_pay': 'Alquiler/ampliación',
    'SalesManager.Premium': 'Prima',
    'SalesManager.Receivables_pay': 'Importes por cobrar',
    'SalesManager.Receivables_pay_total': '{month} por cobrar{br}(acumulado)',
    'SalesManager.Sales_total': 'Total',
    'SalesManager.Month_total': '{month} ventas totales{br}(acumuladas)',
    'SalesManager.Won': '{amount} EUR',
    'SalesManager.Toast.PaymentMonthList.Fail':
        'No se pudo recuperar la lista mensual.',
    'SalesManager.Toast.PaymentDayList.Fail':
        'No se pudo recuperar la lista diaria.',
    'SalesManager.Toast.PaymentBusinessList.Fail':
        'No se pudo recuperar la lista de operadores.',

    'UseState.State.InUse': 'En uso',
    'UseState.State.Wait': 'Esperando',
    'UseState.State.Unavailable': 'Anómalo',
    'UseState.State.LowVoltage': 'Voltaje bajo',
    'UseState.State.NotReturned': 'No devuelto',
    'UseState.All': 'Todo',
    'UseState.Use': 'En uso',
    'UseState.Toast.UseStatusList.Fail':
        'No se pudo recuperar la lista de estados de uso.',
    'UseState.Toast.UseStateCount.Fail':
        'No se pudo recuperar la lista de estados de uso.',

    'Payment.Processing': 'Procesar pago',
    'Payment.Complete': 'Pago finalizado',
    'Payment.Status': 'Estado/importe',
    'Payment.PaymentStatus': 'Estado del pago',
    'Payment.UnpaidPay': 'Tarifas no pagadas',
    'Payment.ActualPay': 'Pago real',
    'Payment.Status.Complete': 'Pago finalizado',
    'Payment.Status.Unpaid': 'No pagado',
    'Payment.Status.Wait': 'A la espera de pago',
    'Payment.Status.Fail': 'Pago fallido',
    'Payment.Alert.NoPaymentData': 'La información de pago no existe.',
    'Payment.Alert.Delete.No.Checked':
        'Seleccione la reserva para la que desea procesar el pago.',
    'Payment.Confirm.Pay': '¿Desea procesar {count} pagos?',
    'Payment.Pay.Fail': 'No se pudo procesar el pago.',
    'Payment.Get.Fail': 'No se pudieron recuperar los detalles de pago.',
    'Payment.Pay.No.Checked': 'No hay elementos para los que procesar el pago.',
    'Payment.Pay.MultiFail': 'No se pudieron procesar {count} pagos.',
    'Payment.Pay.Success': 'El pago se ha completado.',

    'Local.Address.Get.Fail': 'No se pudo convertir la dirección.',
    'Local.Geocode.Get.Fail': 'No se pudo convertir la dirección.',
    'Local.Search.Fail': 'La búsqueda ha fallado.',

    Location: 'Base',
    'Location.Placeholder':
        'Mueva el marcador por el mapa para introducir la dirección automáticamente.',
    'Location.Name': 'Nombre de la base',
    'Location.Name.Placeholder': 'Introduzca el nombre de la base.',
    'Location.Address': 'Dirección',
    'Location.Address.ModalTitle': 'Búsqueda de dirección',
    'Location.Address.Placeholder':
        'Este campo se completa automáticamente con la dirección.',
    'Location.DetailedAddress': 'Dirección detallada.',
    'Location.DetailedAddress.Placeholder': 'Introduzca la dirección postal.',
    'Location.VehicleCount': 'Vehículo en posesión',
    'Location.Business': 'Empresa poseedora',
    'Location.Business.Name': 'Nombre de la empresa',
    'Location.Business.Opened': 'Base pública',
    'Location.Business.Name.Placeholder': 'Seleccione una empresa.',
    'Location.Confirm.MultiDelete': '¿Desea eliminar {count} bases?',
    'Location.Create.Success': 'La base se ha registrado.',
    'Location.Create.Fail': 'No se pudo registrar la base.',
    'Location.Update.Success': 'Se ha modificado la información de la base.',
    'Location.Update.Fail': 'No se pudo modificar la información de la base.',
    'Location.Delete.Success': 'La base se ha eliminado.',
    'Location.Delete.Fail': 'No se pudo eliminar la base.',
    'Location.Delete.Fail.VehicleExists':
        'La eliminación ha fallado, ya que hay vehículos en la base.',
    'Location.View.Fail': 'No se puede recuperar la información de la base.',
    'Location.Monitoring.Fail':
        'No se pudo recuperar la información del vehículo en la base.',
    'Location.Unit.Vehicle.Count': '{value} vehículos',
    'Location.Radius': 'Lugar de devolución (radio)',
    'Location.Radius.Placeholder': 'Seleccione un radio.',
    'Location.Note': 'Detalles',
    'Location.Note.Placeholder': 'Introduzca los detalles.',
    'Location.Note.Empty': 'No hay detalles.',

    'Member.Title.Info': 'Información básica',
    'Member.Title.Business': 'Información del operador',
    'Member.Name': 'Nombre',
    'Member.Email': 'ID de usuario',
    'Member.MobileNumber': 'N.º de teléfono',
    'Member.Role': 'Tipo',
    'Member.ActivationStatus': 'Estado',
    'Member.JoinDate': 'Fecha de inscripción',
    'Member.BirthDay': 'Fecha de nacimiento',
    'Member.Business.Name': 'Operador',
    'Member.TeamName': 'Equipo',
    'Member.EmployeeRegNum': 'N.º de empleado',
    'Member.OfficePhoneNumber': 'Oficina',
    'Member.DenyReason': 'Motivo del rechazo',
    'Member.Filter.Email': 'ID de usuario',
    'Member.Filter.Name': 'Nombre',
    'Member.Filter.MobileNumber': 'N.º de teléfono',
    'Member.Filter.Business': 'Nombre del operador',
    'Member.Modal.Title.Deny': 'Motivo del rechazo',
    'Member.Denyreason.Placeholder': 'Introduzca el motivo de la devolución.',
    'Member.View.Fail': 'No se puede recuperar el administrador.',
    'Member.Approved.Success': 'Se ha aprobado la solicitud.',
    'Member.Approve.Fail': 'No se pudo aprobar la solicitud.',
    'Member.Denied.Success': 'Se ha rechazado la solicitud.',
    'Member.Deny.Fail': 'No se pudo rechazar la solicitud.',
    'Member.Help.Approve.Ready':
        '※ Su solicitud de aprobación no se ha completado.',

    'Customer.Title.Info': 'Información básica',
    'Customer.Title.Business': 'Información del empleado',
    'Customer.Name': 'Nombre del socio',
    'Customer.Email': 'Dirección de correo electrónico',
    'Customer.MobileNumber': 'N.º de teléfono',
    'Customer.JoinDate': 'Fecha de inscripción',
    'Customer.ActivationStatus': 'Estado',
    'Customer.BirthDay': 'Fecha de nacimiento',
    'Customer.Address': 'Dirección',
    'Customer.DriverLicense': 'Permiso de conducir',
    'Customer.CreditCard': 'N.º de tarjeta',
    'Customer.Business.Name': 'Nombre de la empresa',
    'Customer.TeamName': 'Equipo',
    'Customer.EmployeeRegNum': 'N.º de empleado',
    'Customer.DenyReason': 'Motivo del rechazo',
    'Customer.Filter.Email': 'ID de usuario',
    'Customer.Filter.Name': 'Nombre',
    'Customer.Filter.MobileNumber': 'N.º de teléfono',
    'Customer.Filter.Business': 'Nombre del operador',
    'Customer.Filter.Team': 'Equipo',
    'Customer.Modal.Title.Deny': 'Motivo del rechazo',
    'Customer.Denyreason.Placeholder': 'Introduzca el motivo de la devolución.',
    'Customer.View.Fail': 'No se pueden recuperar los socios.',
    'Customer.Approved.Success': 'Se ha aprobado la solicitud.',
    'Customer.Approve.Fail': 'No se pudo aprobar la solicitud.',
    'Customer.Denied.Success': 'Se ha rechazado la solicitud.',
    'Customer.Deny.Fail': 'No se pudo rechazar la solicitud.',

    'Business.Title.Type': 'Categoría del operador',
    'Business.Title.Business': 'Información del operador',
    'Business.Title.Manager': 'Administrador principal',
    'Business.BusinessCode': 'Código',
    'Business.BusinessRegNum': 'N.º de operador',
    'Business.Name': 'Nombre de la empresa',
    'Business.MobileNumber': 'Teléfono principal',
    'Business.Address': 'Dirección',
    'Business.KoroadClientID': 'Permiso de conducir',
    'Business.PGMemberID': 'PG Member ID',
    'Business.Manager.Name': 'Nombre',
    'Business.Manager.MobileNumber': 'N.º de teléfono',
    'Business.Manager.Email': 'Dirección de correo electrónico',
    'Business.Type.Private': 'Empresa',
    'Business.Type.Public': 'General',
    'Business.Modal.Address.Search': 'Buscar dirección del operador',
    'Business.BusinessRegNum.Placeholder': 'Introduzca el número del operador.',
    'Business.Name.Placeholder': 'Introduzca el nombre de la empresa.',

    'Business.Address.Address.Placeholder': 'Busque un código postal.',
    'Business.Address.DetailedAddress.Placeholder':
        'Introduzca la dirección postal.',
    'Business.KoroadClientID.Placeholder': 'CLIENT ID',
    'Business.KoroadSecret.Placeholder': 'CLIENT SECRET',
    'Business.KoroadPIN.Placeholder': 'PIN',
    'Business.KoroadPWD.Placeholder': 'Contraseña',
    'Business.PGMemberID.Placeholder': 'Introduzca el ID.',
    'Business.Manager.Name.Placeholder': 'Introduzca el nombre.',

    'Business.Manager.Email.Placeholder':
        'Introduzca una dirección de correo electrónico.',
    'Business.View.Fail': 'No se puede recuperar la información del operador.',
    'Business.BusinessRegNum.Help': 'Introduzca el número del operador.',
    'Business.MobileNumber.Help':
        'Introduzca un número de teléfono principal válido.',
    'Business.Manager.MobileNumber.Help': 'Introduzca un número de teléfono.',

    'Alert.Image.SizeLimitExceeded': 'Puede cargar una imagen hasta de 500 KB.',

    'Vehicle.Status.Wait': 'Esperando',
    'Vehicle.Status.InUse': 'En uso',
    'Vehicle.Status.Incident': 'Anómalo',
    'Vehicle.Status.LowVoltage': 'Voltaje bajo',
    'Vehicle.Status.NotReturned': 'No devuelto',
    'Vehicle.Status.Unknown': 'Desconocido',

    'Brand.Type.Hyundai': 'Hyundai Motor Company',
    'Brand.Type.Kia': 'Kia Motors',

    'Fuel.Type.Gasoline': 'Gasolina',
    'Fuel.Type.Diesel': 'Diésel',
    'Fuel.Type.Ev': 'Eléctrico',
    'Fuel.Type.Hybrid': 'Híbrido',

    'Grade.Type.City': 'Minicompacto',
    'Grade.Type.SubCompact': 'Subcompacto',
    'Grade.Type.Compact': 'Tamaño compacto',
    'Grade.Type.Mid': 'Tamaño medio',
    'Grade.Type.Large': 'Grande',
    'Grade.Type.SUV': 'SUV',
    'Grade.Type.VAN': 'Furgoneta',
    'Grade.Type.Electric': 'Eléctrico',
    'Grade.Type.Oversea': 'De importación',

    'Accident.Type.Washing': 'Lavado',
    'Accident.Type.Maintenance': 'Mantenimiento',
    'Accident.Type.Repair': 'Reparación',
    'Accident.Type.Etc': 'Otros',

    'Terminal.StatusType.Normal': 'Uso (estado de conexión)',
    'Terminal.StatusType.Outoforder': 'Resolución de problemas',
    'Terminal.StatusType.Discard': 'Eliminación',
    'Terminal.StatusType.Unknown': 'Desconocido',

    'Terminal.State.Type.Unknown': 'Desconocido',
    'Terminal.State.Type.Connect': 'Montado en el vehículo',
    'Terminal.State.Type.Conflict': 'Incoherencia',
    'Terminal.State.Type.Wait': 'Esperando',

    'Terms.StatusType.Valid': 'Válido',
    'Terms.StatusType.Invalid': 'No válido',

    'Notice.StatusType.Draft': 'DRAFT',
    'Notice.StatusType.Published': 'PUBLISHED',
    'Notice.StatusType.Notified': 'NOTIFIED',

    'Member.ActivationStatusType.Pending': 'Esperando',
    'Member.ActivationStatusType.Normal': 'Aprobar',
    'Member.ActivationStatusType.Deny': 'Rechazar',

    'Member.RoleType.System.Admin': 'Responsable',
    'Member.RoleType.System.Operator': 'Administrador',
    'Member.RoleType.Public.Admin': 'Responsable',
    'Member.RoleType.Public.Operator': 'Administrador',
    'Member.RoleType.Private.Admin': 'Responsable',
    'Member.RoleType.Private.Operator': 'Administrador',

    'Member.RoleGroupType.Admin': 'Responsable',
    'Member.RoleGroupType.Operator': 'Administrador',

    'User.StatusType.Pending': 'Esperando',
    'User.StatusType.Normal': 'Aprobar',
    'User.StatusType.Reject': 'Rechazar',
    'User.StatusType.Deny': 'Rechazar',

    'User.LevelType.Public': 'Socio general',
    'User.LevelType.Private': 'Socio corporativo',

    'Customer.BizStatusType.Requested': 'Esperando',
    'Customer.BizStatusType.Inactive': 'Aprobar',
    'Customer.BizStatusType.Deny': 'Rechazar',

    'Reservation.Status.Unknown': 'Desconocido',
    'Reservation.Status.Requested': 'A la espera de aprobación',
    'Reservation.Status.Approve': 'Reserva finalizada',
    'Reservation.Status.Confirm': 'Aprobación completada',
    'Reservation.Status.Denied': 'Reserva rechazada',
    'Reservation.Status.Canceled': 'Cancelar reserva',

    'Reservation.Status.InUse': 'En uso',
    'Reservation.Status.Returned': 'Devolución completada',

    'Reservation.ConfirmType.Requested': 'A la espera de despacho del vehículo',
    'Reservation.ConfirmType.Confirmed': 'Aprobación de despacho del vehículo',
    'Reservation.ConfirmType.Denied': 'Rechazo de despacho del vehículo',

    'Vehicle.ControlType.Open': 'Abrir puerta (desbloquear)',
    'Vehicle.ControlType.Close': 'Cerrar puerta (bloquear)',
    'Vehicle.ControlType.Horn': 'Claxon',
    'Vehcile.ControlType.Light': 'Luz de emergencia',

    'Vehicle.Colors.White': 'Blanco',
    'Vehicle.Colors.LightGrey': 'Plata',
    'Vehicle.Colors.Grey': 'Gris',
    'Vehicle.Colors.Black': 'Negro',
    'Vehicle.Colors.Red': 'Rojo',
    'Vehicle.Colors.Blue': 'Azul',

    'Vehicle.Transmission.Auto': 'Automático',
    'Vehicle.Transmission.Manual': 'Manual',

    'Vehicle.Options.Nosmoking': 'No fumador',
    'Vehicle.Options.Dashcam': 'Caja negra',
    'Vehicle.Options.Rearview': 'Cámara trasera',
    'Vehicle.Options.Navigation': 'Navegador',

    'Vehicle.InsuranceAge.21': '21 años o más',
    'Vehicle.InsuranceAge.26': '26 años o más',
    'Vehicle.InsuranceDriverAge.21': 'Seguro de conductor para 21 años o más',
    'Vehicle.InsuranceDriverAge.26': 'Seguro de conductor para 26 años o más',

    // LICENSE_LOCATION_TYPE
    'License.Location.Seoul': 'Seúl',
    'License.Location.Busan': 'Busan',
    'License.Location.Gyeonggi': 'Gyeonggi(South)',
    'License.Location.Kangwon': 'Kangwon',
    'License.Location.Chungbuk': 'Chungbuk',
    'License.Location.Chungnam': 'Chungnam',
    'License.Location.Jeonbuk': 'Jeonbuk',
    'License.Location.Jeonnam': 'Jeonnam',
    'License.Location.Gyeongbuk': 'Gyeongbuk',
    'License.Location.Gyeongnam': 'Gyeongnam',
    'License.Location.Jeju': 'Jeju',
    'License.Location.Daegu': 'Daegu',
    'License.Location.Incheon': 'Incheon',
    'License.Location.Kwangju': 'Kwangju',
    'License.Location.Daejeon': 'Daejeon',
    'License.Location.Ulsan': 'Ulsan',
    'License.Location.NorthGyeonggi': 'Gyeonggi(North)',

    // LICENSE_TYPE
    'License.Type.Large': 'primera clase (grande)',
    'License.Type.Normal': 'primera clase (ordinaria)',
    'License.Type.Small': 'primera clase (pequeña)',
    'License.Type.Trailer': 'remolque grande',
    'License.Type.Wrecker': 'Camión de remolque',
    'License.Type.SmallTrailer': 'Camión de remolque (pequeña)',
    'License.Type.SecondNormal': '2da clase (ordinaria)',
    'License.Type.SecondSmall': '2da clase (pequeña)',
    'License.Type.MotorizedBicycle': '2da clase (Bicicleta motorizada)',

    'PenaltyAndTollPage.Objection': 'Recurso',
    'PenaltyAndTollPage.LicensePlateNumber': 'N.º de vehículo',
    'PenaltyAndTollPage.Violation': 'Fecha y hora de la infracción',
    'PenaltyAndTollPage.UserName': 'Nombre del cliente',
    'PenaltyAndTollPage.MobileNumber': 'N.º de teléfono',
    'PenaltyAndTollPage.LicenseType': 'N.º de permiso',
    'PenaltyAndTollPage.LicnConCode': 'N.º de permiso',

    'PenaltyAndTollPage.ExpirationOfLicence': 'Periodo de permiso efectivo',
    'PenaltyAndTollPage.RegistrationDate.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.AppealTime.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.Violation.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.ExpirationOfLicence.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.LicensePlateNumber.Placeholder':
        'Introduzca el n.º de vehículo',
    'PenaltyAndTollPage.UserName.Placeholder':
        'Introduzca el nombre del cliente',
    'PenaltyAndTollPage.MobileNumber.Placeholder':
        'Introduzca el n.º de teléfono',

    'PenaltyAndTollPage.ZipCode': 'Dirección',
    'PenaltyAndTollPage.DetailedAddress': 'Dirección detallada. ',
    'PenaltyAndTollPage.LicenceNumberSecond.Placeholder':
        'Introduzca el n.º de permiso',
    'PenaltyAndTollPage.RentDivision': 'Categoría de alquiler',
    'PenaltyAndTollPage.RentDivision.Placeholder': 'General',
    'PenaltyAndTollPage.RentDuration': 'Periodo de alquiler',
    'PenaltyAndTollPage.RentDuration.Placeholder': '0000.00.00',
    'PenaltyAndTollPage.BusinessName': 'Nombre de la empresa',
    'PenaltyAndTollPage.BusinessName.Placeholder':
        'Introduzca el nombre de la empresa',
    'PenaltyAndTollPage.NumberOfBusiness': 'N.º de operador',
    'PenaltyAndTollPage.NumberOfBusiness.Placeholder':
        'Introduzca el n.º de operador',
    'PenaltyAndTollPage.Category': 'Tipo de infracción',
    'PenaltyAndTollPage.Category.Placeholder': 'Todo',
    'PenaltyAndTollPage.PenaltyFee.Placeholder': 'Introduzca el importe',
    'PenaltyAndTollPage.PenaltyFee': 'Penalización',
    'PenaltyAndTollPage.Location.Placeholder':
        'Introduzca la ubicación de la infracción',
    'PenaltyAndTollPage.Organization.Placeholder':
        'Introduzca el departamento emisor',
    'PenaltyAndTollPage.Desc.Placeholder': 'Introduzca el contenido',
    'PenaltyAndTollPage.Desc': 'Detalles',
    'PenaltyAndTollPage.ProcessObjection.Placeholder': 'Sí',
    'PenaltyAndTollPage.ProcessObjection': 'Procesar objeción',
    'PenaltyAndTollPage.Appeal': 'Procesar objeción',
    'PenaltyAndTollPage.Appeal.Placeholder': 'Sí',
    'PenaltyAndTollPage.Remark.Placeholder': 'Introduzca el contenido',
    'PenaltyAndTollPage.Remark': 'Contenido',
    'PenaltyAndTollPage.ViolationPlace.Placeholder':
        'Introduzca la ubicación de la infracción',
    'PenaltyAndTollPage.ViolationPlace': 'Ubicación de la infracción',
    'PenaltyAndTollPage.Location': 'Ubicación de la infracción',
    'PenaltyAndTollPage.Organization': 'Departamento emisor',
    'PenaltyAndTollPage.ProcessingDate': 'Fecha y hora de procesamiento',
    'PenaltyAndTollPage.ConnectRentInformation':
        'Vincular a información de alquiler',
    'PenaltyAndTollPage.Check.RentInformation':
        'Introduzca el número del vehículo y la fecha de infracción',
    'PenaltyAndTollPage.Fail.ConnectRentInformation':
        'La información de alquiler no existe.',
    'PenaltyAndTollPage.Fail.Create':
        'No se pudo registrar la penalización o el cargo.',
    'PenaltyAndTollPage.Success.Create': 'Penalización/cargo registrado.',
    'PenaltyAndTollPage.Success.Delete': 'Penalización/cargo eliminado.',
    'PenaltyAndTollPage.Fail.Delete':
        'No se pudo eliminar la penalización o el cargo.',
    'PenaltyAndTollPage.Fail.ViolationList':
        'No se pudo recuperar la lista de penalizaciones/cargos.',
    'PenaltyAndTollPage.Fail.Detail':
        'No se pudieron recuperar los detalles de la penalización o el cargo.',
    'PenaltyAndTollPage.Fail.Download': 'No se pudo imprimir.',
    'PenaltyAndTollPage.Success.Update': 'Penalización/cargo modificado.',
    'PenaltyAndTollPage.Fail.Update':
        'No se pudo modificar la penalización o el cargo.',
    'PenaltyAndToll.NumberOfPenalty': 'N.º de penalización',
    'PenaltyAndToll.ViolationCategory': 'Tipo de infracción',
    'PenaltyAndToll.Fee': 'Tarifa',
    'PenaltyAndTollPage.RegistrationDate': 'Fecha y hora de la infracción',
    'PenaltyAndToll.ViolationDate': 'Fecha y hora de la infracción',
    'PenaltyAndToll.Vehicle': 'Vehículo',
    'PenaltyAndToll.UserName': 'Nombre del cliente',
    'PenaltyAndToll.MobileNumber': 'N.º de teléfono',
    'PenaltyAndToll.Business': 'Operador',
    'PenaltyAndToll.NumberOfPenalty.Placeholder':
        'Introduzca el n.º de penalización',
    'PenaltyAndToll.Download.PdfName': 'Recurso.pdf',
    'PenaltyAndTollPage.Type.Park': 'Infracción de aparcamiento',
    'PenaltyAndTollPage.Type.Sign': 'Infracción de señal',
    'PenaltyAndTollPage.Type.Speed': 'Infracción de velocidad',
    'PenaltyAndTollPage.Yes': 'Sí',
    'PenaltyAndTollPage.No': 'No',
    'PenaltyAndTollPage.Required': 'Introduzca todos los campos.',
    'AppealDetail.DocID': 'N.º de documento',
    'AppealDetail.AppealTime': 'Fecha de ejecución',
    'AppealDetail.Organization': 'Destinatario',
    'AppealDetail.Temp': 'CC',
    'AppealDetail.Remark': 'Contenido',
    'AppealDetail.LicensePlateNumber': 'N.º de vehículo infractor',
    'AppealDetail.UserName': 'Nombre del cliente',
    'AppealDetail.LicnCon': 'N.º de permiso',
    'AppealDetail.ViolationTime': 'Fecha y hora de la infracción',
    'AppealDetail.ReservationTime': 'Periodo de alquiler',
    'AppealDetail.Representative': 'Director general',
    'AppealDetail.Paragraph': 'Debido a la infracción ',
    'AppealDetail.Paragraph2':
        'que se produjo durante el periodo de alquiler, presento el recurso anterior; les ruego que lo revisen y lo transfieran al conductor.',
    'MyPage.JoinDate': 'Fecha de inscripción',
    'MyPage.Name': 'Nombre del cliente',
    'MyPage.Email': 'ID de usuario',
    'MyPage.MobileNumber': 'N.º de teléfono',
    'MyPage.BirthDate': 'Fecha de nacimiento',
    'MyPage.ApproveMessage': 'Motivo del rechazo',
    'MyPage.Info': 'Información básica',
    'MyPage.BusinessInfo': 'Información de la empresa',
    'MyPage.Business.BusinessCode': 'Código de la empresa',
    'MyPage.Business.Name': 'Nombre de la empresa',
    'MyPage.Business.Required': 'Introduzca un código de la empresa válido.',
    'MyPage.EmployeeRegNum': 'N.º de empleado',
    'MyPage.TeamName': 'Equipo',
    'MyPage.CompanyEmail': 'Dirección de correo electrónico',
    'MyPage.ActivationStatus': 'Estado',
    'MyPage.PasswordChange': 'Cambiar contraseña',
    'MyPage.OldPassword': 'Contraseña antigua',
    'MyPage.NewPassword': 'Contraseña nueva',
    'MyPage.CheckNewPassword': 'Confirmar nueva contraseña',
    'MyPage.PasswordInfo':
        'Para la contraseña, utilice de 8 a 20 caracteres con una combinación de letras en mayúscula/minúscula del alfabeto inglés, números y caracteres especiales.',
    'MyPage.Change': 'Cambiar',
    'MyPage.UserDelete': 'Dar de baja',
    'MyPage.UserType': 'Tipo',
    'MyPage.Update.Fail': 'No se pudo modificar la información.',
    'MyPage.Update.Success': 'Se ha modificado la información.',
    'MyPage.UpdatePwdCheck.Fail': 'La contraseña antigua es incorrecta.',
    'MyPage.UpdatePwd.Fail': 'No se pudo cambiar la contraseña.',
    'MyPage.UpdatePwd.Success': 'La contraseña ha cambiado.',

    // 번역의뢰 WEB 0330    - es.js
    'Common.Select.Required': 'Se debe seleccionar un valor',
    'Common.Btn.Save': 'Guardar',
    'Common.Btn.NewCreate': 'Nuevo registro',
    'Common.Btn.CreateComplete': 'Registro completado',
    'Common.Btn.EditComplete': 'Modificar',
    'Common.Btn.PrivateDeny': 'Retirada de la sociedad',
    'Common.Btn.CarLog': 'Registro de conducción',
    'Common.Confirm.Pay': '¿Quiere realizar el pago?',
    'Common.Unit.Short.Hour': 'Hora',
    'Common.Unit.Days': 'Día',
    'Common.Unit.Count': 'Recuento',
    'Common.Unit.Month': 'Mes',
    'Main.SubTitle': 'Las reservas y liquidaciones se hacen más fáciles.',
    // 'Menu.SalesAndCalculation': 'Gestión de ventas/liquidación',
    // 'Menu.SalesManager': 'Gestión de ventas/liquidación',
    // 'Menu.LocationManager': 'Gestión de la ubicación',
    // 'Menu.UserManager': 'Gestión de afiliación',
    // 'Menu.BusinessManager': 'Gestión de la sociedad de gestión',
    // 'Menu.BusinessCustomrList': 'Lista de los clientes de la sociedad',
    // 'Menu.BusinessCustomerEditList': 'Editar la lista de clientes de la sociedad',
    // 'Menu.User': 'Gestión de usuarios',
    'Title.VehicleInfo': 'Ver vehículo',
    'Title.VehicleCreate': 'Matrícula de vehículos',
    'Title.ReservationTestCarCreate': 'Reserva del coche de prueba',
    'Title.TestCarConfigList': 'Establecer límite para coches de prueba',
    'Title.License': 'Gestión de licencias',
    'Title.Code': 'Gestión de códigos',
    'Title.Menu': 'Gestión de menú',
    'Title.Role': 'Gestión de acceso a roles',
    'Title.Program': 'Gestión de programas',
    'Title.Message': 'Gestión de mensajes',
    'Title.AccidentCreate': 'Registro del accidente/reparación',
    // 'Title.TestCarConfigList': 'Configuración del coche de prueba',
    'Menu.Holiday': 'Gestión de coches de prueba durante días festivos',
    'Reservation.Btn.Download': 'Descargar CSV',
    // 'Reservation.Status.InUse': 'No se puede devolver el vehículo porque no está en uso.',
    'Reservation.VehicleType': 'Tipo de vehículo',
    // 'Reservation.Insurance.Invalid':  //   'No se puede reservar un coche porque el seguro de coche ha expirado'.,
    //   'La reserva no se puede modificar porque el seguro de coche ha expirado'.,
    'Reservation.StartOdometer': 'Medición inicial del cuentakilómetros (km)',
    'Reservation.EndOdometer': 'Medición final del cuentakilómetros (km) ',
    'Reservation.TotalOdometer': 'Distancia total del viaje (km)',
    'Reservation.BusinessType': 'Seleccionar operador',
    'Reservation.Approve.Success':
        'Se han aprobado {success} casos de los {total} casos con aprobación global.',
    'Reservation.Deny.Success':
        'Los pagos de {success} han sido rechazados a partir de la suma global de {total} pagos.',
    'Reservation.Pay.Success':
        'Los pagos de {success} se han llevado a cabo con éxito a partir de la suma global de {total} pagos.',
    'Reservation.Business.Name': 'Nombre de la sociedad',
    'Reservation.User.Team': 'Equipo',
    'Reservation.User.Name': 'Nombre de la persona que hace la reserva',
    'Reservation.Use.ImgList': 'Imágenes antes y después del uso del vehículo',
    'Reservation.Use.ImgCheck':
        'Compruebe las imágenes del vehículo antes y después del uso.',
    'Reservation.Count.AllVehicle': 'Todos los vehículos en funcionamiento',
    'Reservation.Count.AvailableVehicle': 'Vehículos disponibles para reserva',
    'Reservation.Count.CompletedVehicle': 'Vehículo reservado',
    'Reservation.Count.InUseVehicle': 'El vehículo está actualmente en uso.',
    'Reservation.Count.WaitVehicle': 'Vehículo pendiente de aprobación',
    'Rerservation.Btn.Pay': 'Pago',
    // 'Vehicle.Used': 'Estado de uso',
    // 'Vehicle.Used.Yes': 'Usado',
    // 'Vehicle.Used.No': 'Nuevo',
    'Vehicle.LicensePlateNumber.Placeholder': "Por ejemplo') 00 Ha 0000",
    'Vehicle.ControllerID.Placeholder': 'ejemplo) 01012345678',
    'Vehicle.Type.Normal': 'Vehículo normal',
    'Vehicle.Type.TestCar': 'Coche de prueba',
    'Vehicle.VehicleType': 'Tipo de vehículo',
    'BusinessMember.Title': 'Información de suscripción del empleado',
    'Member.Branch.Name': 'Sucursal',
    // 'Business.TestCar': 'Coche de prueba',
    'Accident.Type.Acc1': 'Accidente causado por el cliente',
    'Accident.Type.Acc2': 'Accidente que ha causado daños al cliente',
    'Terminal.Telecom.Type.SKTMVNO': 'SKT MVNO',
    'Terminal.Telecom.Type.KTMVNO': 'KT MVNO',
    'Terminal.Telecom.Type.LGUMVNO': 'LGU+ MVNO',
    'Reservation.Status.Return': 'Devolución',
    'Vehicle.Colors.Green': 'Verde',
    'Vehicle.Colors.Yellow': 'Amarillo',
    'PenaltyAndTollPage.LicenseType.3': 'Incheon(3)',
    'PenaltyAndTollPage.LicenseType.12': 'Seúl(11)',
    'PenaltyAndTollPage.LicenseType.Placeholder': 'Seúl(11)',
    'PenaltyAndTollPage.LicnConCode.Placeholder': 'Seúl(11)',
    'TestCarConfig.Business': 'Seleccionar operador',
    'TestCarConfig.ReservationTime':
        'Período de reserva (el n.º máximo de días)',
    'TestCarConfig.MaxMonth':
        'Período de prueba del coche (núm. máx. de meses)',
    'TestCarConfig.ReservationCount': 'Recuento de reservas (por trimestre)',
    'TestCarConfig.TokURL': 'URL para revisiones de coches de prueba',
    'TestCarConfig.Info': 'Establecer límite para coches de prueba',
    'TestCarConfig.ReservationHour':
        'Hora de inicio/finalización de la reserva',
    'TestCarConfig.ReservationInfo': 'Información de la reserva',
    'TestCarConfig.Toast.getTestCarConfig.Fail':
        'No se ha podido recuperar la lista de configuración.',
    'TestCarConfig.Toast.createTestCarConfig.Fail':
        'No se ha podido registrar la configuración.',
    'TestCarConfig.Toast.updateTestCarConfig.Success':
        'Se ha actualizado la configuración.',
    'TestCarConfig.Toast.updateTestCarConfig.Fail':
        'No se ha podido actualizar la configuración.',
    'Program.Name': 'Nombre del programa',
    // 'Program.Method': 'Acción',
    'Program.Action': 'Acción',
    'Program.Confirm.MultiDelete': '¿Desea eliminar {count} programas?',
    'Program.View.Fail': 'No se puede recuperar la información del programa.',
    'Program.Delete.Success': 'Se ha eliminado el programa',
    'Program.Delete.Fail': 'No se ha podido eliminar el programa.',
    'Program.Create.Success': 'Se ha registrado el programa.',
    'Program.Create.Fail': 'No se ha podido registrar el programa.',
    'Program.Action.Inquiry': 'Leer',
    'Program.Action.Save': 'Escribir',
    'Program.Action.Delete': 'Eliminar',
    'Program.Action.Download': 'Descargar',
    'Program.Name.Placeholder': 'Introduzca el nombre del programa.',
    'Program.Apiurl.Placeholder': 'Introduzca la URL de la API',
    'Message.ResourceKey': 'Clave',
    'Message.Message': 'Mensaje',
    'Message.Confirm.MultiDelete': '¿Desea eliminar {count} mensajes?',
    'Message.View.Fail': 'No se puede recuperar la información del mensaje.',
    'Message.Delete.Success': 'Se ha eliminado el mensaje.',
    'Message.Delete.Fail': 'No se ha podido eliminar el mensaje.',
    'Message.Create.Success': 'Se ha creado el mensaje.',
    'Message.Create.Fail': 'No se ha podido crear el mensaje.',
    'Message.Update.Success': 'Se ha actualizado el mensaje.',
    'Message.Update.Fail': 'No se ha podido actualizar el mensaje.',
    'Message.Key.Placeholder': 'Introduzca el valor de la clave.',
    'Message.Message.Placeholder': 'Introduzca un mensaje.',
    'Message.Confirm.Delete': '¿Desea eliminar el mensaje?',
    'Code.RootName': 'Gestión de códigos',
    'Code.SelectCode': 'Seleccione el código',
    'Code.Type': 'Tipo',
    'Code.Type.Group': 'Grupo de códigos',
    'Code.Type.Value': 'Elemento de código',
    'Code.Name': 'Nombre del código',
    'Code.GroupKey': 'Grupo clave',
    'Code.CodeValue': 'Valor de código',
    'Code.OrderNum': 'Prioridad',
    'Code.Use': 'Estado de visualización',
    'Code.Use.false': 'Ocultar',
    'Code.Use.true': 'Visualizar',
    'Code.Name.Placeholder': 'Introduzca el nombre del código.',
    'Code.MsgKey.Placeholder': 'Introduzca el código de clave del mensaje.',
    'Code.GroupKey.Placeholder': 'Introduzca un valor clave de grupo.',
    'Code.CodeValue.Placeholder': 'Introduzca el valor del código.',
    'Code.OrderNum.Placeholder': 'Introduzca un valor de prioridad.',
    'Code.View.Fail': 'No se puede recuperar la información del código.',
    'Code.Confirm.Delete': '¿Desea eliminar el código?',
    'Code.Create.Success': 'Se ha registrado el código.',
    'Code.Create.Fail': 'No se ha podido registrar el código.',
    'Code.Update.Success': 'Se ha actualizado el código.',
    'Code.Update.Fail': 'No se ha podido actualizar el código.',
    'Code.Delete.Success': 'Código eliminado.',
    'Code.Delete.Fail': 'No se ha podido eliminar el código.',
    'Menu.SelectCode': 'Seleccione un menú',
    'Menu.Name': 'Nombre del menú',
    'Menu.MenuUrl': 'Acceso vía menú',
    'Menu.OrderNum': 'Prioridad',
    'Menu.Use': 'Estado de visualización',
    'Menu.Use.false': 'Ocultar',
    'Menu.Use.true': 'Visualizar',
    'Menu.Acl': 'Estado de la autoridad',
    'Menu.Acl.true': 'Usado',
    'Menu.Acl.false': 'No está en uso',
    'Menu.Confirm.Delete': '¿Desea eliminar el menú?',
    'Menu.Menu.Placeholder': 'Introduzca el nombre del menú.',
    'Menu.Path.Placeholder': 'Indique el acceso vía menú.',
    'Menu.Order.Placeholder': 'Establecer la prioridad.',
    'Menu.View.Fail': 'No se puede recuperar la información del menú.',
    'Menu.Delete.Success': 'Se ha eliminado el menú.',
    'Menu.Delete.Fail': 'No se ha podido eliminar el menú.',
    'Menu.Create.Success': 'Se ha registrado el menú.',
    'Menu.Create.Fail': 'No se ha podido registrar el menú.',
    'Menu.Update.Success': 'Se ha actualizado el menú.',
    'Menu.Update.Fail': 'No se ha podido actualizar el menú.',
    'Menu.Program.View.Fail':
        'No se puede recuperar la información del programa.',
    'Menu.Program.Delete.Success': 'Se ha eliminado el programa',
    'Menu.Program.Delete.Fail': 'No se ha podido eliminar el programa.',
    'Menu.Program.Create.Success': 'Se ha registrado el programa.',
    'Menu.Program.Create.Fail': 'No se ha podido registrar el programa.',
    'Role.Select.Placeholder': 'Seleccione un rol.',
    'Role.Name': 'Nombre de rol',
    'Role.RoleKey': 'Clave del rol',
    'Role.Note': 'Nota sobre el rol',
    'Role.Name.Placeholder': 'Introduzca un nombre de rol',
    'Role.RoleKey.Placeholder': 'Introduzca una clave del rol.',
    'Role.Note.Placeholder': 'Introduzca una nota sobre el rol.',
    'Role.Acl.Title': 'Autoridad de acceso',
    'Role.Admin.Title': 'Gerente',
    'Role.View.Fail': 'No se puede recuperar la información del rol.',
    'Role.Delete.Success': 'Se ha eliminado el rol.',
    'Role.Delete.Fail': 'No se ha podido eliminar el rol.',
    'Role.Create.Success': 'El rol ha sido registrado.',
    'Role.Create.Fail': 'No se puede usar una clave de rol duplicada.',
    'Role.Update.Success': 'Se ha actualizado el rol.',
    'Role.Update.Fail': 'No se puede usar una clave de rol duplicada.',
    'Role.Acl.View.Fail':
        'No se puede recuperar la información de la autoridad de acceso.',
    'Role.Acl.Update.Success': 'Se ha actualizado la autoridad de acceso.',
    'Role.Acl.Update.Fail':
        'No se ha podido actualizar la autorización de acceso.',
    'Role.Admin.View.Fail': 'No se puede ver la información del administrador.',
    'Role.Confirm.Delete': '¿Desea eliminar el rol?',
    'Role.Menu': 'Nombre del menú',
    'Role.Read': 'Leer',
    'Role.Write': 'Escribir',
    'Role.Delete': 'Eliminar',
    'Role.Download': 'Descargar',
    'Role.Admin.Name': 'Nombre',
    'Role.Admin.UserID': 'ID de usuario',
    'Role.Admin.MobileNumber': 'N.º de teléfono.',
    'License.Confirm.MultiDelete':
        '¿Desea eliminar {count} gestiones de licencias?',
    'License.View.Fail':
        'No se puede ver la información de gestión de licencias.',
    'License.Confirm.Delete': '¿Desea eliminar la gestión de licencias?',
    'License.Delete.Success': 'Se ha eliminado la gestión de licencias.',
    'License.Delete.Fail': 'No se ha podido eliminar la gestión de licencias.',
    'License.Create.Success': 'Se ha registrado la gestión de licencias.',
    'License.Create.Fail': 'No se ha podido registrar la gestión de licencias.',
    'License.Update.Success': 'Se ha actualizado la gestión de licencias.',
    'License.Update.Fail':
        'No se ha podido actualizar la gestión de licencias.',
    'License.View.Menu.Fail':
        'No se puede ver la información de la autoridad de acceso de gestión de licencias.',
    'License.Update.Menu.Fail':
        'No se ha podido actualizar la autoridad de acceso a la gestión de licencias.',
    'License.View.Business.Fail':
        'No se puede ver la licencia que gestiona la información empresarial.',
    'License.Name': 'Nombre de la licencia',
    'License.Use': 'Estado de uso',
    'License.Note': 'Nota',
    'License.BusienssCnt': 'Recuento de suscripciones',
    'License.UsageFee': 'Tarifa de uso/mes',
    'License.Use.false': 'No está en uso',
    'License.Use.true': 'Usado',
    'License.Menu.Title': 'Autoridad de acceso',
    'License.Business.Title': 'Operador',
    'License.Business.Name': 'Nombre del operador',
    'License.Business.Date': 'Fecha de la licencia',
    'License.Name.Placeholder': 'Introducir el nombre de la licencia.',
    'License.UseFee.Placeholder': 'Introducir la tarifa de uso.',
    'License.Note.Placeholder': 'Introducir una nota',
    'CompanyUser.CompanyId': 'Nombre de la empresa.',
    'CompanyUser.ManagerName': 'Nombre del gerente',
    'CompanyUser.PhoneNumber': 'N.º de teléfono.',
    'CompanyUser.FaxNumber': 'N.º de fax',
    'CompanyUser.ManagerMobileNumber': 'N.º del teléfono móvil del gerente',
    'CompanyUser.ManagerEmail': 'Dirección de correo electrónico del gerente',
    'CompanyUser.Get.Fail':
        'La información del gerente de la empresa no está disponible.',
    'Title.CompanyUser': 'Gerente de la empresa',
    'CompanyUser.Title.Create': 'Registrar el gerente de la empresa',
    'CompanyUser.CompanyID.Placeholder': 'Seleccione un ID de empresa',
    'CompanyUser.ManagerName.Placeholder':
        'Introduzca el nombre del gerente de la empresa.',
    'CompanyUser.PhoneNumber.Placeholder':
        'Introduzca el número de teléfono del gerente de la empresa.',
    'CompanyUser.FaxNumber.Placeholder':
        'Introduzca el número de fax del gerente de la empresa.',
    'CompanyUser.ManagerMobileNumber.Placeholder':
        'Introduzca el número de teléfono del gerente de la empresa.',
    'CompanyUser.ManagerEmail.Placeholder':
        'Introduzca la dirección de correo electrónico del gerente de la empresa.',
    'CompanyUser.Filter.CompanyID': 'ID de la empresa',
    'CompanyUser.Filter.ManagerName': 'Nombre del gerente',
    'CompanyUser.Filter.ManagerMobileNumber':
        'N.º del teléfono móvil del gerente',
    'Company.Name': 'Nombre de la empresa.',
    'Company.CompanyBusiness': 'Estado comercial de la empresa',
    'Company.CompanyType': 'Tipo de empresa',
    'Company.PhoneNumber': 'N.º de teléfono.',
    'Company.FaxNumber': 'N.º de fax',
    'Company.Address': 'Dirección',
    'Company.BusinessRegNum': 'Operador n.º',
    'Company.CeoName': 'Nombre',
    'Company.Homepage': 'Página web',
    'Company.RepBusinessType': 'Tipo de negocio principal',
    'Company.BusinessType': 'Tipo de negocio',
    // 'Company.BusinessId': 'Identificación de la sociedad',
    // 'Company.BranchId': 'ID de la sucursal',
    'Company.BusinessId': 'Nombre de la sociedad',
    'Company.BranchId': 'Sucursal',
    'COMPANY.Type.Mechanic': 'Tienda de mantenimiento',
    'COMPANY.Type.Insurance': 'Compañía de seguros',
    'Company.Title.Create': 'Registrar una empresa',
    'Title.Company': 'Empresa',
    'Company.Name.Placeholder': 'Introduzca el nombre de la empresa',
    'Company.CompanyType.Placeholder': 'Seleccione el tipo de empresa.',
    'Company.PhoneNumber.Placeholder': 'ejemplo) 0101234567',
    'Company.FaxNumber.Placeholder': 'ejemplo) 0101234567',
    'Company.BusinessRegNum.Placeholder': 'Introduzca el n.º de operador',
    'Company.CeoName.Placeholder':
        'Introduzca el nombre del director ejecutivo o del gerente de la sucursal.',
    'Company.Homepage.Placeholder': 'Entrar en la página web.',
    'Company.CompanyBusiness.Placeholder':
        'Introduzca el estado comercial de su empresa.',
    'Company.RepBusinessType.Placeholder': 'Introducir el tipo de negocio.',
    'Company.BusinessType.Placeholder': 'Introduzca un tipo de negocio.',
    // 'Company.BusinessId.Placeholder': 'Introduzca el ID de la sociedad',
    // 'Company.BranchId.Placeholder': 'Introduzca el ID de la sucursal.',
    'Company.BusinessId.Placeholder': 'Seleccione una sociedad.',
    'Company.BranchId.Placeholder': 'Seleccione una sucursal.',
    'Company.Address.Address.Placeholder':
        'Por favor, busque un código postal.',
    'Company.Address.DetailedAddress.Placeholder':
        'Por favor, introduzca la dirección postal.',
    'TestCarConfig.Toast.createTestCarConfig.Success':
        'La configuración se ha registrado.',
    'TestCarConfig.Toast.ReservationHour.Fail':
        'La hora de inicio de la reserva es igual o superior a la hora de finalización.',
    'Holiday.Create.Info': 'Registro de unas vacaciones',
    'Holiday.HolidayName': 'Nombre de las vacaciones',
    'Holiday.Date': 'Fecha',
    'Holiday.Toast.getHolidayList.Fail':
        'No se ha podido recuperar la lista de vacaciones.',
    'Holiday.Toast.getHolidayDetail.Fail':
        'No se han podido recuperar los detalles de las vacaciones.',
    'Holiday.Toast.createHoliday.Success':
        'Las vacaciones han sido registradas.',
    'Holiday.Toast.createHoliday.Fail':
        'No se han podido registrar unas vacaciones.',
    'Holiday.Toast.updateHoliday.Success':
        'Las vacaciones han sido actualizadas.',
    'Holiday.Toast.updateHoliday.Fail':
        'No se ha podido actualizar las vacaciones.',
    'Holiday.Toast.deleteHoliday.Success':
        'Las vacaciones han sido eliminadas.',
    'Holiday.Toast.deleteHoliday.Fail':
        'No se han podido eliminar las vacaciones.',
    'Menu.Accident': 'Información del accidente/reparación',
    // 'Title.AccidentCreate': 'Registro del accidente/reparación',
    'Title.AccidentDetail': 'Información del accidente/reparación',
    'Accident.Btn.Create': 'Registro del accidente',
    'Accident.Date': 'Fecha del accidente',
    'Accident.Location': 'Sucursal',
    'Accident.Vehicle.Information': 'Información del vehículo',
    'Accident.Vehicle.LicensePlateNum': 'Vehículo n.º.',
    'Accident.RepairType': 'Tipo de accidente',
    'Accident.Status': 'Estado',
    'Accident.Id': 'Expediente n.º',
    'Accident.Company': 'Empresa',
    'Accident.Status.Occur': 'Incidencia de accidente',
    'Accident.Status.Requested': 'Reparación solicitada',
    'Accident.Status.Repairing': 'Reparación',
    'Accident.Status.Repaired': 'Reparado',
    'Accident.Status.Unknown': 'Desconocido',
    'Accident.Filter.LicensePlateNumber': 'Vehículo n.º.',
    'Accident.Filter.RepairType': 'Tipo de accidente',
    'Accident.Filter.VehicleModel': 'Modelo de vehículo',
    'Accident.Filter.Location': 'Sucursal',
    'Accident.Filter.Id': 'Expediente n.º',
    'Accident.Filter.Company': 'Empresa',
    'Accident.VehicleInfo': 'Información del vehículo',
    'Accident.ReservationInfo': 'Información del alquiler',
    'Accident.RepairInfo': 'Detalles de reparación',
    'Accident.CostInfo': 'Procesamiento de costes',
    'Accident.InsuranceInfo': 'Compañía de seguros de la otra parte',
    'Accident.Get.Fail':
        'No se puede recuperar la información del accidente/reparación.',
    'Accident.Create.Succes': 'Accidente registrado con éxito.',
    'Accident.Create.Fail': 'Error en el registro del accidente.',
    'AccidentVehicle.LicensePlateNum': 'Vehículo n.º.',
    'AccidentVehicle.Company': 'Empresa',
    'AccidentVehicle.AccidentDate': 'Fecha del accidente',
    'AccidentVehicle.Location': 'Sucursal',
    'AccidentVehicle.AccidentStatus': 'Estado',
    'AccidentVehicle.AccidentType': 'Tipo de accidente',
    'AccidentVehicle.Search.Vehicle': 'Buscar vehículo',
    'AccidentVehicle.Search.Company': 'Buscar una empresa',
    'AccidentVehicle.AccidentDate.DayPicker': 'Fecha del accidente',
    'AccidentVehicle.Repair': 'Fecha de reparación',
    'AccidentVehicle.Repair.Start': 'Fecha de inicio de reparación',
    'AccidentVehicle.Repair.End': 'Fecha de finalización de la reparación',
    'AccidentReservation.ReservationCode': 'Código de reserva',
    'AccidentReservation.ReservationDate': 'Período de alquiler',
    'AccidentReservation.CustomerName': 'Nombre del cliente',
    'AccidentReservation.LicenseType': 'Tipo de carnet de conducir',
    'AccidentReservation.LicenseNumber': 'Carnet de conducir n.º',
    'AccidentReservation.Tel': 'N.º de teléfono.',
    'AccidentReservation.Search.ReservationInfo':
        'Enlace de información del alquiler',
    'AccidentRepair.RepairDetail': 'Detalles de reparación',
    'AccidentRepair.RepairBefore': 'Antes de la reparación',
    'AccidentRepair.RepairAfter': 'Después de la reparación',
    'Accident.RepairBefore.Placeholder':
        'Introduzca los artículos antes de la reparación.',
    'Accident.RepairAfter.Placeholder':
        'Introduzca los artículos después de la reparación.',
    'AccidentInsurance.InsuranceCompany':
        'Compañía de seguros de la otra parte',
    'AccidentInsurance.InsuranceCode':
        'N.º del expediente del seguro de la otra parte',
    'AccidentInsurance.InsuranceManager': 'Gerente',
    'AccidentInsurance.InsuranceManagerTel': 'N.º de teléfono del gerente',
    'AccidentCost.InsuranceTreatement': 'Tratamiento del seguro',
    'AccidentCost.Company': 'Compañía de seguros',
    'AccidentCost.InsuranceCode': 'Expediente n.º',
    'AccidentCost.OpponentInjury': 'Por persona',
    'AccidentCost.OpponentVehicle': 'Para objetos',
    'AccidentCost.OwnVehicle': 'Cobertura de colisión',
    'AccidentCost.OwnInjury': 'Daño autoinfligido',
    'AccidentCost.Other': 'Otros',
    'AccidentCost.TotalInsuranceCost': 'Coste total del seguro',
    'AccidentCost.CompanyTreatment': 'Tratamiento de la empresa',
    'AccidentCost.InsuranceCompanyCharge':
        'Deducible de la compañía de seguros',
    'AccidentCost.TotalCompanyCost': 'Coste total de la empresa',
    'AccidentCost.AmountOfAccident': 'Importe recaudado',
    'AccidentCost.IndemnityCost': 'Coste de indemnización',
    'AccidentCost.CompensationFee': 'Tasa de compensación',
    'AccidentCost.Unrepaired': 'No reparado',
    'AccidentCost.TotalCollectCost': 'Importe total cobrado',
    'AccidentCost.TotalCost': 'Total',
    'AccidentCost.Status': 'Estado del pago',
    'AccidentCost.PaymentMethod': 'Método de pago',
    'AccidentBusiness.BusinessType.Placeholder': 'Seleccionar operador',
    'Accident.GetVehicle.Fail':
        'No se ha podido recuperar la lista de vehículos.',
    'Accident.GetBusiness.Fail':
        'No se ha podido recuperar la lista de operadores.',
    'Accident.ReservationCheckParam.Fail':
        'Introducir el n.º del vehículo y la fecha del accidente.',
    'Accident.GetReservationCheck.Fail':
        'No se ha podido vincular la información del alquiler.',
    'Accident.Fail.ConnectRentInformation':
        'La información del alquiler no existe.',
    'Accident.Delete.Success': 'Se han eliminado los detalles del accidente.',
    'Accident.Edit.Success': 'Los detalles del accidente han sido editados.',
    'Accident.Create.Success':
        'Los detalles del accidente han sido registrados.',
    'Accident.Delete.Fail':
        'No se han podido eliminar los detalles del accidente.',
    'Accident.Edit.Fail':
        'No se han podido modificar los detalles del accidente.',
    'Accident.Confirm': 'Eliminar detalles del accidente',
    'Accident.Confirm.Delete': '¿Desea eliminar los detalles del accidente?',
    'Accident.Confirm.Edit': '¿Desea editar los detalles del accidente?',
    'Accident.Confirm.Create': '¿Quieres registrar los detalles del accidente?',
    'Menu.Branch': 'Sucursal',
    'Title.Branch': 'Sucursal',
    'Branch.Name': 'Nombre de la sucursal',
    'Branch.BusinessRegNum': 'Operador n.º',
    'Branch.PhoneNumber': 'N.º de teléfono.',
    'Branch.FaxNumber': 'N.º de fax',
    'Branch.Address': 'Dirección',
    'Branch.ManagerName': 'Nombre',
    'Branch.ManagerPhoneNumber': 'N.º de teléfono.',
    'Branch.ManagerEmail': 'Dirección de correo electrónico',
    'Branch.Title.Create': 'Registro de la sucursal',
    'Branch.Company': 'Sucursal',
    'Branch.BusinessID': 'Nombre de la sociedad',
    'Branch.BusinessID.Placeholder': 'Introduzca el nombre de la sociedad',
    'Branch.Name.Placeholder': 'Introduzca el nombre de la sucursal.',
    'Branch.BusinessRegNum.Placeholder': 'Introduzca el n.º de operador',
    'Branch.PhoneNumber.Placeholder': 'Introduzca un n.º de teléfono.',
    'Branch.FaxNumber.Placeholder': 'Introduzca el n.º de fax',
    'Branch.ManagerName.Placeholder':
        'Introduzca el nombre del director ejecutivo.',
    'Branch.ManagerPhoneNumber.Placeholder':
        'Introduzca el número de teléfono del director ejecutivo o del gerente de la sucursal.',
    'Branch.ManagerEmail.Placeholder':
        'Introduzca la dirección de correo electrónico del director ejecutivo o del gerente de la sucursal.',
    'Branch.Company.Placeholder': 'Introduzca una sucursal.',
    'Branch.Address.Address.Placeholder': 'Por favor, busque un código postal.',
    'Branch.Address.DetailedAddress.Placeholder':
        'Por favor, introduzca la dirección postal.',
    'Accident.PaymentStatus.Unpaid': 'No pagado',
    'Accident.PaymentStatus.Paid': 'Pagado',
    'Accident.PaymentType.Card': 'Tarjeta',
    'Accident.PaymentType.Cash': 'Efectivo',
    'Accident.GetBranch.Fail':
        'No se ha podido recuperar la lista de sucursales.',
    'Accident.GetCompany.Fail': 'No se ha podido cargar la lista de empresas',
    'Code.MsgKey': 'Clave de código',
    'Menu.VehicleInsurance': 'Gestión de seguros',
    'VehicleInsurance.Get.Fail':
        'No se ha podido recuperar la administración de seguros.',
    'VehicleInsurance.LicensePlateNumber': 'Vehículo n.º.',
    'VehicleInsurance.InsureYear': 'Enésimo año',
    'VehicleInsurance.InsureCondition': 'Situación del asegurado',
    'VehicleInsurance.VehicleGrade': 'Grado de vehículo',
    'VehicleInsurance.VehicleModelName': 'Nombre del modelo de vehículo',
    'VehicleInsurance.CompanyName': 'Compañía de seguros',
    'VehicleInsurance.StartDate': 'Fecha de inicio del seguro',
    'VehicleInsurance.EndDate': 'Fecha de vencimiento del seguro',
    'VehicleInsurance.Filter.LicensePlateNumber': 'Vehículo n.º.',
    'VehicleInsurance.Filter.CompanyName': 'Compañía de seguros',
    'Title.VehicleInsurance': 'Gestión de seguros de vehículos',
    'VehicleInsurance.VehicleInformation': 'Información del vehículo',
    'VehicleInsurance.InsuranceInformation': 'Información del seguro',
    'VehicleInsurance.insureSelfVehicle': 'Cobertura por colisión asegurada',
    'VehicleInsurance.PaymentDueDay': 'Fecha de pago',
    'VehicleInsurance.InsurePayment': 'Seguro pagado',
    'VehicleInsurance.paymentMethod': 'Método de pago',
    'VehicleInsurance.insureSelfVehicle.true': 'Sí',
    'VehicleInsurance.insureSelfVehicle.false': 'No',
    'VehicleInsurance.paymentMethod.true': 'Pagado en su totalidad',
    'VehicleInsurance.paymentMethod.false': 'Cuota mensual',
    'VehicleInsurance.MonthPer': 'Número de cuotas',
    'VehicleInsurance.MonthPerPayment': 'Pago mensual',
    'VehicleInsurance.VehiclePremium': 'Información sobre la prima del seguro',
    'VehicleInsurance.insureOrder': 'Secuencia',
    'VehicleInsurance.paymentDate': 'Fecha de pago',
    'VehicleInsurance.monthPerPayment': 'Pago mensual',
    'VehicleInsurance.delete': 'Eliminar',
    'Menu.MsgKey': 'Tecla Menú',
    'Menu.MsgKey.Placeholder': 'Introduzca una tecla Menú',
    'Title.ShortRental': 'Alquiler corto',
    'ShortRental.Type': 'Categoría del alquiler',
    'ShortRental.Type.Public': 'Alquiler de coches',
    'ShortRental.Type.Private': 'Sociedad',
    'ShortRental.Type.Rent': 'Registrar un alquiler a corto plazo',
    'ShortRental.Type.Reservation': 'Reserva de alquiler corta',
    'ShortRental.RentalInfo':
        'Información sobre el alquiler de coches y el vehículo',
    'ShortRental.Duration': 'Período de alquiler',
    'ShortRental.Duration.Value': '{days} días {hours} horas',
    'ShortRental.Insurance.Type': 'Tipo de seguro',
    'ShortRental.Insurance.Age.Placeholder':
        'Seleccione la antigüedad del seguro.',
    'ShortRental.Insurance.Type.Placeholder': 'Seleccione un tipo de seguro.',
    'Reservation.EndLocation': 'Ubicación de devolución',
    'Reservation.EndLocation.Placeholder':
        'Seleccione una ubicación de devolución.',
    'Common.Deletes.Fail':
        'Algunos elementos no se pueden eliminar. Compruebe los datos.',
    'Reservation.OwnVehicle': 'Situación del seguro de cobertura por colisión',
    'Reservation.CarInfo': 'Información del vehículo',
    'Reservation.SelectCar': 'Selección de vehículos',
    'Reservation.CarNumber': 'Vehículo n.º.',
    'Reservation.CarModel': 'Modelo de vehículo',
    'Reservation.CarColor': 'Color del vehículo',
    'Reservation.CarFuel': 'Tipo de combustible',
    'Reservation.CarRate': 'Clase de vehículo',
    'Reservation.Agent': 'Responsable',
    'Reservation.RentAgent': 'Agente de alquiler',
    'Reservation.ReturnAgent': 'Agente de la devolución',
    'Reservation.Agent.Placeholder': 'Seleccione un empleado.',
    'Reservation.Join': 'Unirse',
    'Reservation.NotJoin': 'Sin seguro',
    'Reservation.Indemnity.title':
        'Información sobre el coste de indemnización',
    'Reservation.Run.title': 'Combustible y distancia de viaje',
    'Reservation.Run.Fuel': 'Nivel de combustible',
    'Reservation.Run.Run': 'Distancia del viaje',
    'Reservation.Run.Rental': 'Alquiler',
    'Reservation.Run.Return': 'Devolución',
    'ShortRental.OwnVehicle.Type.Placeholder':
        'Seleccione el tipo de suscripción',
    'ShortRental.User.Public': 'Sociedad',
    'ShortRental.User.Private': 'Usuario privado',
    'ShortRental.User.SelectBusiness': 'Buscar una sociedad existente',
    'ShortRental.User.Name.Error': 'Introduzca un nombre',
    'ShortRental.User.ZipCode.Error': 'Introduzca un código postal.',
    'ShortRental.User.SelectUser': 'Buscar un socio existente',
    'ShortRental.User.SelectEmployee': 'Encontrar empleado',
    'ShortRental.User.Contact': 'Datos de contacto del usuario',
    'ShortRental.User.Contact.Placeholder': 'Introduzca sus datos de contacto.',
    'ShortRental.User.Contact.Error': 'Introduzca sus datos de contacto.',
    'ShortRental.User.Address.Error': 'Introduzca la dirección.',
    'ShortRental.User.Email': 'Dirección de correo electrónico',
    'ShortRental.User.Email.Placeholder':
        'Por favor, introduzca la dirección de correo electrónico.',
    'ShortRental.User.Fax': 'Fax',
    'ShortRental.User.Fax.Placeholder': 'Introduzca el n.º de fax',
    'ShortRental.User.BusinessRegNum.Error': 'Introduzca el n.º de operador',
    'ShortRental.User.DetailAddress.Error':
        'Introducir la dirección detallada.',
    'ShortRental.User.FirstDriver': 'Primer conductor',
    'ShortRental.User.SecondDriver': 'Segundo conductor',
    'ShortRental.Charge.Title': 'Información de tarifas',
    'ShortRental.Charge.Discount.title': 'Información del descuento',
    'ShortRental.Charge.Default': 'Tarifa por defecto',
    'ShortRental.Charge.Discount': 'Tarifa con descuento',
    'ShortRental.Charge.Rental': 'Tarifa de alquiler',
    'ShortRental.Charge.Real': 'Tarifa de alquiler',
    'ShortRental.Charge.Additional': 'Cargo adicional',
    'ShortRental.Charge.Etc': 'Distinta de la prima del seguro',
    'ShortRental.Charge.Sum': 'Tarifa total del alquiler',
    'ShortRental.Etc.Special': 'Números especiales',
    'ShortRental.Etc.Memo': 'Memo',
    'ShortRental.Car.Select': 'No hay vehículos disponibles para seleccionar.',
    'ShortRental.DueDate.Empty': 'Introduzca la fecha y hora del alquiler.',
    'Title.LongRental': 'Alquiler a largo plazo de un coche nuevo',
    'LongRental.Type': 'Categoría del alquiler',
    'LongRental.Type.Estimate':
        'Estimación de tarifas de alquiler a largo plazo para un coche nuevo',
    'LongRental.Type.Create':
        'Registrar alquiler a largo plazo para un coche nuevo',
    'LongRental.ChargeInfo': 'Información de tarifas',
    'LongRental.RentalDeposit': 'Depósito del alquiler',
    'LongRental.ExtraCharge': 'Tarifa adicional',
    'LongRental.ExtraItem': 'Artículo adicional',
    'LongRental.MonthlyCharge.Title': 'Tarifa mensual',
    'LongRental.MonthlyCharge': 'Tarifa de alquiler',
    'LongRental.MonthlyTotal': 'Total',
    'LongRental.ContractNumber': 'Contrato n.º',
    'LongRental.ContractStatus': 'Estado de la cotización',
    'LongRental.Info.Repair': 'Incluir mantenimiento',
    'LongRental.Repair.Include': 'Incluir',
    'LongRental.Repair.NonInclude': 'No incluido',
    'LongRental.Payment.first': 'Primer pago',
    'LongRental.Payment.Final': 'Pago final',
    'LongRental.Payment.Day': 'Fecha de pago',
    'LongRental.Payment.Charge': 'Importe del pago',
    'LongRental.Payment.Month': 'Fecha del pago mensual',
    'LongRental.Payment.Beginning': 'Fecha del primer pago',
    'LongRental.CommonDriverInfo': 'Información del conductor habitual',
    'LongRental.User.DriverName': 'Copiloto',
    'LongRental.User.DriverName.Placeholder':
        'Introduzca el nombre del conductor habitual',
    'LongRental.User.Contact': 'Datos de contacto del usuario',
    'LongRental.User.Contact.Placeholder': 'Introduzca sus datos de contacto.',
    'LongRental.User.Birth': 'Fecha de nacimiento',
    'LongRental.User.Birth.Placeholder':
        'Los primeros seis dígitos del n.º de registro del titular, por ejemplo, 990126',
    'LongRental.User.DriverLicense': 'Carnet de conducir',
    'LongRental.Kind.DriverLicense.Placeholder': 'Seleccione un tipo de carnet',
    'Title.MonthRental': 'Alquiler mensual a largo plazo',
    'MonthRental.Type': 'Categoría del alquiler',
    'MonthRental.Type.Reservation':
        'Reservar un alquiler mensual a largo plazo',
    'MonthRental.Type.Create': 'Registrar un alquiler mensual a largo plazo',
    'MonthRental.ReservationNumber': 'Reserva N.º',
    'MonthRental.ReservationStatus': 'Estado de la reserva',
    'Rent.RentalDueDate': 'Fecha estimada del alquiler',
    'MonthRental.ExtraCharge': 'Cargo adicional',
    'Menu.Vehicle': 'Gestión de vehículos',
    'Menu.Maintenance': 'Gestión del mantenimiento del vehículo',
    'Menu.Operation': 'Funcionamiento del servicio',
    'Menu.Vehicle.Insurance': 'Gestión de pagos del seguro',
    'Menu.Rent': 'Gestión del alquiler',
    'Menu.Payment': 'Gestión de ventas/liquidación',
    'Menu.Work': 'Gestión del trabajo MOCEAN',
    'Menu.System': 'Gestión del sistema',
    'Common.Get.Fail': 'Error al recuperar los datos.',
    'Menu.History': 'Historial',
    'Title.ControlHistory': 'Historial de control del vehículo',
    'Title.ControlHistory.Request': 'Detalles de la solicitud',
    'Title.ControlHistory.Response': 'Detalles de la respuesta',
    'ControlHistory.Filter.LicensePlateNumber': 'Vehículo n.º.',
    'ControlHistory.Filter.UserName': 'Nombre',
    'ControlHistory.HistoryTime': 'Fecha y hora de la incidencia',
    'ControlHistory.UserName': 'Nombre',
    'ControlHistory.LicensePlateNumber': 'Vehículo n.º.',
    'ControlHistory.ReqTime': 'Hora de la solicitud',
    'ControlHistory.ResTime': 'Tiempo de respuesta',
    'ControlHistory.Action': 'Acción',
    'ControlHistory.ReqResultMessage': 'Resultado de la solicitud',
    'ControlHistory.ResResultCode': 'Código de respuesta',
    'ControlHistory.ResData': 'Datos de la respuesta',
    'ControlHistory.ReqData': 'Datos solicitados',
    Vehicle: 'Vehículo',
    'Vehicle.Tab.Detail': 'Detalle del vehículo',
    'Vehicle.Tab.Purchase': 'Información de compra',
    'Vehicle.Tab.Accident': 'Accidente/reparación',
    'Vehicle.PurchaseMethod': 'Método de compra',
    'Vehicle.PurchaseCost': 'Coste de compra',
    'Vehicle.Purchase.BasicCost': 'Coste básico del vehículo',
    'Vehicle.Purchase.BasicCost.Placeholder': 'Coste básico del vehículo',
    'Vehicle.Purchase.SelectCost': 'Opciones',
    'Vehicle.Purchase.SelectCost.Placeholder': 'Opciones',
    'Vehicle.Purchase.Consignment': 'Tasa de envío',
    'Vehicle.Purchase.Consignment.Placeholder': 'Tasa de envío',
    'Vehicle.Purchase.PureVehiclePrice': 'Precio real del vehículo',
    'Vehicle.Purchase.PureVehiclePrice.Placeholder': 'Precio real del vehículo',
    'Vehicle.Purchase.TotalCost': 'Coste total de compra',
    'Vehicle.Pay.DownPayment': 'Pago inicial',
    'Vehicle.Pay.DownPayment.Placeholder': 'Pago inicial',
    'Vehicle.Pay.DeliveryPayment': 'Pago de entrega',
    'Vehicle.Pay.DeliveryPayment.Placeholder': 'Pago de entrega',
    'Vehicle.Pay.TotalLeasePayment': 'Importe total de la cuota',
    'Vehicle.Pay.TotalLeasePayment.Placeholder': 'Importe total de la cuota',
    'Vehicle.PayInfo': 'Condiciones de pago',
    'Vehicle.AdditionalFee': 'Coste adicional',
    'Vehicle.Additional.MandatoryPremium': 'Prima obligatoria',
    'Vehicle.Additional.MandatoryPremium.Placeholder': 'Prima obligatoria',
    'Vehicle.Additional.StampPrice': 'Precio del sello',
    'Vehicle.Additional.StampPrice.Placeholder': 'Precio del sello',
    'Vehicle.Additional.EtcPrice': 'Otros costes',
    'Vehicle.Additional.EtcPrice.Placeholder': 'Otros costes',
    'Vehicle.Additional.TotalCost': 'Total coste adicional',
    'Vehicle.RegistrationFee': 'Tasa de matriculación de vehículos',
    'Vehicle.Registration.TotalCost': 'Tasa total de matriculación',
    'Vehicle.Registration.AcquisitionTax': 'Impuesto de adquisición',
    'Vehicle.Registration.BoundDiscount': 'Descuento deducido',
    'Vehicle.Registration.RegistrationStampPrice': 'Precio del sello',
    'Vehicle.Registration.LicensePlateNumberPrice': 'Precio de la matrícula',
    'Vehicle.Registration.RegistrationFee':
        'Comisión de gestión por el registro',
    'Vehicle.Registration.AcquisitionTax.Placeholder':
        'Impuesto de adquisición',
    'Vehicle.Registration.BoundDiscount.Placeholder': 'Descuento deducido',
    'Vehicle.Registration.RegistrationStampPrice.Placeholder':
        'Precio del sello',
    'Vehicle.Registration.LicensePlateNumberPrice.Placeholder':
        'Precio de la matrícula',
    'Vehicle.Registration.RegistrationFee.Placeholder':
        'Comisión de gestión por el registro',
    'Vehicle.TotalFee': 'Coste total de compra',
    'Vehicle.Available': 'Verificar los vehículos disponibles',
    'Title.LicenseCheckHistory':
        'Historial de verificación del carnet de conducir',
    'LicenseCheckHistory.Filter.UserID': 'ID de usuario',
    'LicenseCheckHistory.Filter.PhoneNumber': 'N.º de teléfono.',
    'LicenseCheckHistory.Filter.ResidentName': 'Nombre del conductor',
    'LicenseCheckHistory.Filter.ResidentDate':
        'Fecha de nacimiento del conductor',
    'LicenseCheckHistory.Filter.ResponseCode': 'Código de respuesta',
    'LicenseCheckHistory.LicenseNo': 'Carnet de conducir no.',
    'LicenseCheckHistory.LicnConCode': 'Tipo de carnet de conducir',
    'LicenseCheckHistory.UserID': 'ID de usuario',
    'LicenseCheckHistory.PhoneNumber': 'N.º de teléfono.',
    'LicenseCheckHistory.ResidentName': 'Nombre del conductor',
    'LicenseCheckHistory.ResidentDate': 'Fecha de nacimiento del conductor',
    'LicenseCheckHistory.ResponseCode': 'Resultado de la respuesta',
    'LicenseCheckHistory.ResponseMessage': 'Mensaje de respuesta',
    'LicenseCheckHistory.Date': 'Horas de alquiler',
    'LicenseCheckHistory.StartDate': 'Hora de inicio del alquiler',
    'LicenseCheckHistory.EndDate': 'Hora de finalización del alquiler',
    'LicenseCheckHistory.CreatedAt': 'Hora de la solicitud',
    'Title.RentalSearch': 'Buscar vehículos de alquiler disponibles',
    'RentalSearch.Rental.Day': 'Fecha de alquiler',
    'RentalSearch.Rental.UseCarYear': 'Año del modelo',
    'RentalSearch.Rental.UseCarFuel': 'Combustible en uso',
    'RentalSearch.CarFuel': 'Combustible',
    'RentalSearch.CarLocation': 'Ubicación del coche',
    'RentalSearch.Title.RentalDetail': 'Detalles del vehículo',
    'RentalSearch.CarAnsic': 'Año del modelo',
    'RentalSearch.FixedPeople': 'N.º de pasajeros',
    'RentalSearch.CarInsurance': 'Seguro de alquiler de coches',
    'Business.Extra.Service': 'Servicio extra',
    'Business.TestCar': 'Coche de prueba',
    'Common.Btn.Update': 'Modificar',
    'Terminal.Title.State': 'Estado',
    'Common.Btn.DetailAndUpdate': 'Detalles/actualizaciones',
    'Customer.Title.Private': 'Información personal',
    'Title.Branch.Info': 'Información básica de la sucursal',
    'Vehicle.BasicInfo.CarNumber': 'Coche n.º',
    'Vehicle.BasicInfo.TerminalNumber': 'Terminal n.º',
    'Vehicle.BasicInfo.UseCarYear': 'Año del modelo',
    'Vehicle.BasicInfo.Transmission': 'Transmisión',
    'Vehicle.BasicInfo.CreatedDate': 'Fecha de matriculación del vehículo',
    'Vehicle.BasicInfo.InsuranceAge': 'Antigüedad del seguro',
    'Vehicle.BasicInfo.Option': 'Opción',
    'Vehicle.BasicInfo.BusinessName': 'Nombre de la sociedad',
    'Vehicle.BasicInfo.Location': 'Básico',
    'Vehicle.BasicInfo.Color': 'Color',
    'Vehicle.BasicInfo.FuelType': 'Tipo de combustible',
    'Vehicle.BasicInfo.CarType': 'Clase de vehículo',
    'Vehicle.BasicInfo': 'Información Básica',
    'Vehicle.BasicInfo.AvailableDistance': 'Distancia disponible',
    'Vehicle.BasicInfo.TotalDistance': 'Distancia total del viaje',
    'Vehicle.BasicInfo.AuxBattery': 'Batería AUX',
    'Vehicle.BasicInfo.TempFuelUsage': 'Uso temporal de combustible',
    'Vehicle.BasicInfo.FrontLeft': 'Rueda delantera/izquierda',
    'Vehicle.BasicInfo.FrontRight': 'Rueda delantera/derecha',
    'Vehicle.BasicInfo.BackLeft': 'Rueda trasera/izquierda',
    'Vehicle.BasicInfo.BackRight': 'Rueda trasera/derecha',
    'Vehicle.BasicInfo.DoorOpen': 'Desbloquear puerta',
    'Vehicle.BasicInfo.DoorClose': 'Cerrar puerta',
    'Vehicle.BasicInfo.Siren': 'Luz de emergencia/bocina',
    'Common.Btn.UpdateVehicle': 'Modificación de la información del vehículo',
    'Vehicle.BasicInfo.Type': 'Tipo',
    'Title.Company.Info': 'Información de la empresa',
    'Common.Btn.Setup': 'Configuración',
    'Common.Btn.Setup.Complete': 'Configuración completada',
    'Common.Btn.DuplicateCheck': 'Comprobación de duplicados',
    'Vehicle.BasicInfo.CreateDate': 'Fecha de la primera inscripción',
    'Vehicle.BasicInfo.ExpireDate': 'Fecha de caducidad del vehículo',
    'MyPage.Business.Code.Placeholder': 'Introducir el código de la sociedad',
    'MyPage.Branch': 'Sucursal',
    'MyPage.Branch.Placeholder': 'Seleccione la sociedad y la sucursal.',
    'Vehicle.BasicInfo.VehicleImage': 'Imagen del vehículo',
    'Vehicle.BasicInfo.Terminal': 'Terminal',
    'Vehicle.InterpersonalCompensation1': 'Obligación de pago por persona 1',
    'Vehicle.InterpersonalCompensation2': 'Obligación de pago por persona 2',
    'Vehicle.Reparation1': 'Obligación de pago por objetos 1',
    'Vehicle.UninsuredCarInjury': 'Lesión por accidente de coche sin seguro',
    'Vehicle.PhysicalAccident': 'Accidente con autolesión',
    'Vehicle.Interpersonal': 'Por persona',
    'Vehicle.InterVehicle': 'Para objetos',
    'Vehicle.SelfDamage': 'Daño autoinfligido',
    'Vehicle.VehicleDamage': 'Cobertura de colisión',
    'Vehicle.VehicleDamage.Registration': 'Cobertura por colisión asegurada',
    'Vehicle.VehicleDamage.Join': 'Unirse',
    'Vehicle.VehicleDamage.NotJoin': 'No registrado',
    'Title.VehicleEdit': 'Actualización del vehículo',
    'Menu.Password': 'Cambiar contraseña',
    'Menu.MyBusiness': 'Mi operador',
    'Menu.MyBranch': 'Mi sucursal',
    UNKNOWN: 'Desconocido',
    'BusinessMember.RoleDetail.Business': 'Administrador representante',
    'BusinessMember.RoleDetail.Branch': 'Administrador de la sucursal',
    'BusinessMember.RoleDetail.Staff': 'Empleado',
    'Business.Title.License': 'Licencia suscrita',
    'Business.License.Name': 'Nombre de la licencia',
    'Vehicle.Duplicate.Fail': 'El vehículo n.º que ya existe.',
    'Vehicle.Duplicate.Required':
        'Comprobar si el n.º del vehículo se duplica.',
    'Vehicle.Duplicate.Success':
        'El vehículo n.º está disponible para el registro.',
    'InsuranceRental.Title': 'Seguro de alquiler de coches',
    'InsuranceRental.Type.Reservation':
        'Reserva del seguro de alquiler de coches',
    'InsuranceRental.Type.Creation':
        'Registro del seguro de alquiler de coches',
    'InsuranceRental.Charge.Amount': 'Importe facturado',
    'InsuranceRental.Charge.Daily': 'Tarifa diaria',
    'InsuranceRental.Charge.Time': 'Tarifas por hora',
    'InsuranceRental.Charge.Rate': 'Otras tasas',
    'InsuranceRental.Charge.Rate.Charge': 'Tasa de pago',
    'InsuranceRental.Charge.Rate.Mistake': 'Proporción de averías',
    'InsuranceRental.Charge.TotalAmount': 'Total tarifas',
    'InsuranceRental.Charge.TotalAmount.Example':
        '((tarifas diarias * n.º de días) + (tarifas por hora * n.º de horas)) * tasa de carga * (100 % - Proporción de averías)',
    'InsuranceRental.Charge.Real': 'Importe real reclamado',
    'InsuranceRental.Accident.Title': 'Información del accidente',
    'InsuranceRental.Accident.Damage': 'Información de daños',
    'InsuranceRental.Accident.Assault': 'Información de ataque',
    'InsuranceRental.Accident.Name': 'Nombre',
    'InsuranceRental.Accident.CarNum': 'Vehículo n.º.',
    'InsuranceRental.Accident.CarModel': 'Modelo de vehículo',
    'InsuranceRental.Accident.MaintenanceInfo': 'Información del mantenimiento',
    'InsuranceRental.Accident.MaintenanceShop': 'Tienda de mantenimiento',
    'InsuranceRental.Insurance.Title': 'Información del seguro',
    'InsuranceRental.Insurance.CompanyInfo':
        'Información de la compañía de seguros',
    'InsuranceRental.Insurance.CompanyCode': 'Compañía de seguros',
    'InsuranceRental.Insurance.InsuranceCode':
        'Código del expediente del seguro',
    'InsuranceRental.Insurance.Manager': 'Gerente de seguros',
    'InsuranceRental.Insurance.Charge.Title': 'Información de facturación',
    'InsuranceRental.Insurance.Charge.Date': 'Fecha de facturación',
    'InsuranceRental.Insurance.Charge.Amount': 'Total tarifas',
    'InsuranceRental.Insurance.Deposit.Date': 'Fecha del depósito',
    'InsuranceRental.Insurance.Deposit.Amount': 'Importe del depósito',
    'InsuranceRental.Insurance.Mistake.Amount':
        'Honorarios de responsabilidad por daños al cliente',
    'InsuranceRental.Insurance.OwnVehicle.Amount':
        'Importe del seguro de cobertura por colisión',
    'InsuranceRental.Insurance.Payment.Type': 'Tipo de pago',
    'InsuranceRental.Insurance.Payment.Type.Card': 'Tarjeta',
    'InsuranceRental.Insurance.Payment.Type.Cash': 'Efectivo',
    'Common.Btn.Search': 'Buscar',
    'Reservation.Label.StartDate': 'Fecha de inicio del alquiler',
    'Reservation.Label.SearchType': 'Elemento de búsqueda',
    'Reservation.Label.Type': 'Categoría del alquiler',
    'Reservation.Label.Status': 'Estado de la reserva',
    'Reservation.Label.DueDate': 'Fecha y hora estimadas de devolución',
    'Title.RentList': 'Gestión de detalles del alquiler',
    'Vehicle.DuplicateCheck.FieldNull': 'Introducir el n.º de vehículo.',
    'Common.Btn.DeleteVehicle': 'Supresión de la información del vehículo',
    'Menu.Vehicle.Mgnt': 'Gestión de matriculación de vehículos',
    'Menu.Vehicle.Insurance.Payment.Mgnt':
        'Gestión de la información de seguros',
    'Menu.Insurance.Payment.Mgnt': 'Gestión de la información de seguros',
    'Menu.Vehicle.Purchase.Payment.Mgnt': 'Gestión de cuotas mensuales',
    'Menu.Vehicle.Accident.Mgnt': 'Gestión de reparación de accidentes',
    'Menu.Vehicle.Penalty.Pee.Mgnt': 'Gestión de multas',
    'Menu.Branch.Mgnt': 'Gestión de la sucursal',
    'Menu.Business.Member.Mgnt': 'Gestión del socio empresarial',
    'Menu.Business.Customer.Mgnt': 'Gestión de clientes empresariales',
    'Menu.Customer.Mgnt': 'Gestión de clientes',
    'Menu.Member.Mgnt': 'Gestión de empleados',
    'Menu.License': 'Gestión de licencias',
    'Menu.Code': 'Gestión de códigos',
    'Menu.Menu': 'Gestión de menú',
    'Menu.Role': 'Gestión de la autoridad',
    'Menu.Program': 'Gestión de programas',
    'Menu.Message': 'Gestión de mensajes',
    'Menu.Rent.Fare': 'Gestión de tarifas de alquiler',
    'Menu.Vehicle.Rent.Fare': 'Tarifa del alquiler de coches',
    'Menu.Rent.Insurance.Fare': 'Gestión de primas de seguro de alquiler',
    'Menu.Vehicle.Rent.Insurance.Fare': 'Prima de seguro de alquiler de coches',
    'Menu.Rent.Schedule': 'Comprobar el estado del alquiler',
    'Menu.Rent.Vehcile.Available': 'Buscar vehículos de alquiler disponibles',
    'Menu.Reservation.List': 'Ver detalles de la reserva',
    'Menu.Rent.List': 'Gestión de detalles del alquiler',
    'Menu.CompanyUser': 'Gestión del gerente de la empresa',
    'Menu.Company': 'Gestión de la empresa',
    'Reservation.Label.RentalRegister': 'Registro de alquiler',
    'Common.Confirm.Reservation.Cancle': '¿Desea cancelar su reserva?',
    'Common.Logout.Success': 'Ha cerrado sesión de forma segura. Adiós',
    'LongRental.Estimate.CarInfo': 'Información sobre el coche estimado',
    'LongRental.ReservationDate.Placeholder':
        'Seleccione un período de alquiler',
    'LongRental.Estimate.VehicleName': 'Nombre del vehículo',
    'LongRental.Purchase.Cost': 'Coste de compra del vehículo (EUR)',
    'LongRental.RentalDeposit.Cost': 'Depósito del alquiler (EUR)',
    'LongRental.MonthlyCharge.Cost': 'Tarifa mensual (EUR)',
    'LongRental.Period': 'Período (meses)',
    'LongRental.Options': 'Opciones',
    'LongRental.PenaltyAmount': 'Multa (%)',
    'LongRental.Note': 'Nota',
    'LongRental.VehiclePremium': 'Información del pago',
    'LongRental.Charge.Method': 'Método de pago',
    'LongRental.Charge.Bank': 'Banco para facturación',
    'LongRental.Charge.CountNumber': 'Cuenta n.º',
    'LongRental.Charge.CountOwner': 'Titular de la cuenta',
    'LongRental.Send.Method': 'Ubicación de facturación',
    'LongRental.Pay.Day': 'Fecha de pago',
    'LongRental.Charge.Method.Placeholder': 'Seleccionar método de pago',
    'LongRental.Charge.Bank.Placeholder': 'Seleccione un banco para facturar',
    'LongRental.Charge.CountNumber.Placeholder':
        'Introduzca el número de cuenta.',
    'LongRental.Charge.CountOwner.Placeholder':
        'Introduzca el titular de la cuenta',
    'LongRental.Send.Method.Placeholder':
        'Seleccionar ubicación de facturación',
    'LongRental.Pay.Day.Placeholder': 'Seleccione la fecha de pago',
    'Rent.UseState.Type.Day': 'Día',
    'Rent.UseState.Type.Month': 'Mes',
    'Code.GradeType.Placeholder': 'Clase de vehículo',
    'Code.VehicleStatus.Placeholder': 'Estado del alquiler',
    'UseState.State.Short': 'Corto',
    'UseState.State.Insurance': 'Seguro',
    'UseState.State.Month': 'Mensual',
    'UseState.State.Long': 'A largo plazo',
    'Code.Weekdays.0': 'Día',
    'Code.Weekdays.1': 'Mes',
    'Code.Weekdays.2': 'Martes',
    'Code.Weekdays.3': 'Miércoles',
    'Code.Weekdays.4': 'Jueves',
    'Code.Weekdays.5': 'Viernes',
    'Code.Weekdays.6': 'Sábado',
    'VehicleModel.Image': 'Imagen',
    'Branch.ManagerName.Detail':
        'Nombre del director ejecutivo o del gerente de la sucursal',
    'Branch.ManagerPhoneNumber.Detail':
        'N.º de teléfono del director ejecutivo o del gerente de la sucursal',
    'Branch.ManagerEmail.Detail':
        'Dirección de correo electrónico del director ejecutivo o del gerente de la sucursal',
    'Rent.Filter.Label.Status': 'Estado del alquiler',
    'Rent.Filter.Label.IncludeMaintenance': 'Mantenimiento incluido',
    'Rent.Filter.Label.PaymentDate': 'Fecha de pago',
    'Rent.Filter.Code': 'Código de alquiler',
    'Rent.Filter.User.Name': 'Nombre del cliente',
    'Rent.Filter.User.MobileNumber': 'N.º de teléfono del cliente',
    'Rent.Filter.User.Location': 'Alquiler básico',
    'Rent.Filter.Status.Inuse': 'Actualmente alquilado',
    'Rent.Filter.Status.Returned': 'Fin del alquiler',
    'Rent.Filter.Status.Canceled': 'Cancelación',
    'Rent.Filter.Status.LowPower': 'Baja tensión',
    'Rent.Filter.Status.NoReturned': 'No devuelto',
    'Rent.Filter.Maintenance.Include': 'Incluir',
    'Rent.Filter.Maintenance.NotInclude': 'No incluido',
    'Rent.Filter.Payment.5': '5 días',
    'Rent.Filter.Payment.7': '7 días',
    'Rent.Filter.Payment.10': '10 días',
    'Rent.Filter.Payment.15': '15 días',
    'Rent.Filter.Payment.20': '20 días',
    'Rent.Filter.Payment.25': '25 días',
    'Rent.Filter.Payment.EndOfMonth': 'El último día del mes',
    'Rent.Code': 'Contrato n.º',
    'Rent.Date': 'Fecha de alquiler',
    'Rent.Status.INUSE': 'Actualmente alquilado',
    'Rent.Status.RETURNED': 'Fin del alquiler',
    'Rent.Status.BE_CANCELED': 'Cancelación',
    'Rent.Status.LOW_POWER': 'Baja tensión',
    'Rent.Status.NO_RETURNED': 'No devuelto',
    'Rent.IncludeMaintenance': 'Estado del mantenimiento',
    'Rent.Extend': 'Extensión del alquiler',
    'Rent.Contract': 'Contrato',
    'Rent.status.Requested': 'Solicitud de reserva',
    'Rent.status.Canceled': 'Cancelar la reserva',
    'Rent.status.NoShow': 'El vehículo no se ha adquirido',
    'Rent.status.Deleted': 'Eliminación de reservas',
    'Rent.status.Denied': 'Reserva de rechazo',
    'Rent.status.CommError': 'Error de comunicación',
    'Rent.status.Estimate': 'Cotización completada',
    'Rent.status.EstimateCanceled': 'Cancelar la cotización',
    'Rent.status.ChangeToInsurance': 'Cambiar al seguro de alquiler de coches',
    'Rent.status.ChangeToShort': 'Cambiar a alquiler corto',
    'Rent.status.Insurance.Billing': 'Factura del seguro',
    'Rent.status.Billing': 'Contrato',
    'Rent.modal.Return.message': '¿Quieres devolver el coche?',
    'Rent.modal.Return.Date': 'Fecha y hora de devolución',
    'Rent.modal.Return.Worker': 'Trabajador',
    'Rent.modal.Return.WorkDate': 'Fecha de trabajo',
    'Rent.modal.Return.Fail': 'No se ha podido procesar la devolución.',
    'Rent.modal.Extend.message': '¿Desea extender el período de alquiler?',
    'Rent.modal.Extend.DueDate': 'Hora de finalización anterior',
    'Rent.modal.Extend.Date': 'Fecha de finalización de la prórroga',
    'Rent.modal.Extend.Fail': 'Error de extensión del período.',
    'Rent.modal.Force.message': '¿Quieres devolver el coche por la fuerza?',
    'Rent.modal.Force.Date': 'Fecha y hora de la devolución forzada',
    'Rent.modal.Force.Fail': 'No se ha podido procesar la devolución forzada',
    'VehicleBasic.VIN.Error':
        'El VIN ya existe o no es válido. Compruebe la información de nuevo.',
    'LongRental.Charge.DirectDebit': 'Domiciliación bancaria',
    'LongRental.Charge.VirtualAccount': 'Cuenta virtual',
    'LongRental.Charge.Card': 'Pago con tarjeta',
    'LongRental.Charge.Billing': 'Factura',
    'LongRental.Charge.Home': 'Inicio',
    'LongRental.Charge.Company': 'Empresa',
    'LongRental.UserNameInfo': 'Información del cliente',
    'LongRental.VehicleNumber': 'Vehículo',
    'LongRental.Payment.Month.Placeholder': 'Seleccione una fecha de pago',
    'LongRental.Title.Estimate.Create': 'Registrar un coche estimado',
    'LongRental.Estimate.VehicleName.Placeholder':
        'Introduzca el nombre del vehículo.',
    'LongRental.Purchase.Cost.Placeholder': 'Especifique el coste de compra.',
    'LongRental.RentalDeposit.Cost.Placeholder':
        'Introducir el depósito del alquiler.',
    'LongRental.MonthlyCharge.Cost.Placeholder': 'Introduzca la tarifa mensual',
    'LongRental.Period.Placeholder': 'Introduzca un período',
    'LongRental.Options.Placeholder': 'Introducir opciones',
    'LongRental.PenaltyAmount.Placeholder': 'Introduzca el importe de la multa',
    'LongRental.Note.Placeholder': 'Introducir una nota',
    'Title.Charge.Peak.Fare': 'Tasa máxima',
    'Title.Charge.Discount': 'Descuento en el alquiler (%)',
    'Vehicle.Tab.Log': 'Registro de conducción',
    'Common.Fail.User': 'Reserva duplicada existente.',
    'Common.Btn.ContractCancle': 'Cancelación',
    'Rent.modal.ContractCancle.message':
        '¿Desea cancelar el contrato de alquiler?',
    'LongRental.ContractCancle.StartDate': 'Fecha de inicio del alquiler',
    'LongRental.ContractCancle.EndDate': 'Fecha y hora estimadas de devolución',
    'LongRental.ContractCancle.ContractCancle':
        'Fecha y hora de la cancelación',
    'LongRental.ContractCancle.ReturnPay.Title':
        'Reembolso de la tarifa de alquiler',
    'LongRental.ContractCancle.Refund': 'Reembolso de tarifas de alquiler',
    'LongRental.ContractCancle.Total': 'Total',
    'LongRental.ContractCancle.PenaltyAmount': 'Multa',
    'LongRental.Charge.Cash': 'Efectivo',
    'LongRental.Estimate.message':
        'Introduzca la información del coche y del alquiler para una cotización',
    'Common.BeContractCancle.Fail': 'No se permite la cancelación.',
    'Common.Btn.Report': 'Estimación',
    'Code.Create.ErrorCode.910004':
        'El código de clave de grupo está duplicado.',
    'Code.Update.ErrorCode.910004':
        'El código de clave de grupo está duplicado.',
    'Code.Delete.ErrorCode.910004':
        'No se puede eliminar debido a un subcódigo existente.',
    'VehicleInsurance.branchName': 'Nombre de la sucursal',
    'VehicleInsurance.insureYear': 'N.º de años',
    'VehicleInsurance.paymentDueDate': 'Fecha de vencimiento',
    'VehicleInsurance.totalInsurance': 'Prima total del seguro',
    'VehicleInsurance.paymentStatus': 'Estado del pago',
    'VehicleInsurance.ManagerName': 'Gerente de seguros',
    'VehicleInsurance.ManagerMobileNumber':
        'N.º de teléfono del gerente de seguros',
    'LongRental.values':
        'Total = Depósito de alquiler - importe de la multa + coste de indemnización - tarifas adicionales + reembolso',
    'Vehicle.Tab.Insurance': 'Información del seguro',
    'VehicleInsurance.yearSelect': 'Seleccione el n.º de años',
    'VehicleInsurance.1year': '1.er año',
    'VehicleInsurance.2year': '2.º año',
    'VehicleInsurance.3year': '3.er año',
    'Menu.Business': 'Gestión del operador',
    'VehicleInsurance.12Month': '12 meses',
    'VehicleInsurance.24Month': '24 meses',
    'VehicleInsurance.36Month': '36 meses',
    'VehicleInsurance.2Month': '2 meses',
    'VehicleInsurance.3Month': '3 meses',
    'VehicleInsurance.4Month': '4 meses',
    'VehicleInsurance.5Month': '5 meses',
    'VehicleInsurance.6Month': '6 meses',
    'VehicleInsurance.7Month': '7 meses',
    'VehicleInsurance.8Month': '8 meses',
    'VehicleInsurance.9Month': '9 meses',
    'VehicleInsurance.10Month': '10 meses',
    'VehicleInsurance.11Month': '11 meses',
    ERROR_CODE_SUCCESS: 'Se ha realizado con éxito.',
    ERROR_CODE_NO_CONTENT: 'No hay datos disponibles',
    ERROR_CODE_CREATED: 'Se ha creado',
    ERROR_CODE_NOT_CREATED: 'No se han podido introducir los datos.',
    ERROR_CODE_NOT_UPDATED: 'No se han podido modificar los datos.',
    ERROR_CODE_NOT_DELETED: 'No se han podido eliminar los datos.',
    ERROR_CODE_BAD_REQUEST: 'Factor de entrada no válido',
    ERROR_CODE_FORBIDDEN_ROLE: 'Permiso de administrador no disponible.',
    ERROR_CODE_FORBIDDEN_ACL: 'El permiso del usuario no está disponible.',
    ERROR_CODE_INTERNAL_SERVER_ERROR: 'Se ha producido un error interno',
    ERROR_CODE_NEED_LOGON: 'Es obligatorio iniciar sesión.',
    ERROR_CODE_VEHICLE_NOT_DELETE_RESERVATION:
        'No se puede eliminar el vehículo debido a la información de reserva existente.',
    ERROR_CODE_VEHICLE_NOT_DELETE_ACCIDENT:
        'No se ha podido borrar el vehículo debido al historial de accidentes existente.',
    ERROR_CODE_VEHICLE_VIN_DUPLICATION: 'No se puede usar el código VIN.',
    ERROR_CODE_VEHICLE_CONTROL_ID_DUPLICATION:
        'No se puede utilizar el ID de control del vehículo.',
    ERROR_CODE_USER_NOT_AUTHORIZATION: 'Error en la autorización del usuario.',
    ERROR_CODE_BUSINESS_NOT_DELETE_CAR:
        'No se puede suprimir porque existe un vehículo relacionado.',
    ERROR_CODE_PRIVATE_UNAUTH_BUSINESS:
        'El usuario activado no puede cambiar su empresa.',
    ERROR_CODE_CHARGE_NOT_DELETE_RESERVATION:
        'Se ha producido un fallo debido a la prima reservada.',
    ERROR_CODE_VEHICLEMODEL_NOT_DELETE:
        'No se puede suprimir porque existe un vehículo relacionado.',
    ERROR_CODE_RESERVATION_ALEADY_VEHICLE: 'El vehículo ya está reservado.',
    ERROR_CODE_RESERVATION_CONFLICT: 'Conflicto en la reserva del usuario',
    ERROR_CODE_RESERVATION_UNAUTH_USER:
        'La reserva está deshabilitada para usuarios no autorizados',
    ERROR_CODE_RESERVATION_FIRST_USER_BUSINESS:
        'Registrar primero una sociedad.',
    ERROR_CODE_RESERVATION_NOT_INSUE:
        "El estado de la reserva es 'no está en uso'.",
    ERROR_CODE_RESERVATION_RETURN_NOT_LOCATION:
        'La ubicación actual del vehículo no coincide con la ubicación de devolución.',
    ERROR_CODE_RESERVATION_BAD_REQUEST: 'No se puede interpretar la solicitud.',
    ERROR_CODE_RESERVATION_VEHICLE_ERROR:
        'No se puede encontrar la información del vehículo.',
    ERROR_CODE_RESERVATION_TIME_ERROR:
        'Tiempos inusuales de inicio y finalización',
    ERROR_CODE_RESERVATION_RENTAL_COMPANY:
        'No se ha podido registrar o modificar la empresa de alquiler.',
    ERROR_CODE_RESERVATION_DRIVER_LICENSE:
        'No se ha podido registrar o actualizar el carnet de conducir.',
    ERROR_CODE_RESERVATION_ADDITIONAL_DRIVER_LICENSE:
        'Información adicional del conductor no válida.',
    ERROR_CODE_RESERVATION_NO_CONTENT: 'No hay datos disponibles',
    ERROR_CODE_RESERVATION_GRPC_ERROR: 'Hay un error en el módulo GRPC.',
    ERROR_CODE_RESERVATION_STATUS_ERROR: 'Estado de error',
    ERROR_CODE_RESERVATION_KOROAD_ERROR:
        'No se ha podido verificar el carnet de conducir.',
    ERROR_CODE_RESERVATION_INUSE:
        'Puede realizar una reserva hasta 15 minutos antes de la hora de inicio',
    ERROR_CODE_RESERVATION_OVERLAP: 'Incidencia de solapamiento de reservas',
    ERROR_CODE_CHARGE: 'Información del pago no disponible.',
    ERROR_CODE_TESTCAR_CONFIG_NOT_EXIST:
        'La información de configuración del coche de prueba no está disponible.',
    ERROR_CODE_TESTCAR_RESERVATION_PERIOD_EXCESS:
        'Se ha superado el período de reserva de un coche de prueba.',
    ERROR_CODE_TESTCAR_STARTTIME_WEEKEND:
        'Los alquileres no están disponibles los fines de semana.',
    ERROR_CODE_TESTCAR_ENDTIME_WEEKEND:
        'Los alquileres no se pueden devolver durante los fines de semana.',
    ERROR_CODE_TESTCAR_STARTTIME_HOLIDAY: 'No se puede alquilar en vacaciones.',
    ERROR_CODE_TESTCAR_ENDTIME_HOLIDAY:
        'No se puede devolver en un día festivo.',
    ERROR_CODE_TESTCAR_COUNT_EXCESS:
        'Se ha superado el límite de reserva para coches de prueba.',
    ERROR_CODE_MONITORING_NOT_FIND_VIN:
        'La ubicación actual del vehículo no coincide con la ubicación de devolución.',
    ERROR_CODE_CONTROL_FAIL:
        'No se ha podido controlar de forma remota el vehículo.',
    ERROR_CODE_CONTROL_VIN_DUPLICATION: 'El código VIN no está disponible',
    ERROR_CODE_CONTROL_NADID_DUPLICATION:
        'La información de NADID no está disponible',
    ERROR_CODE_RESOURCE_NOT_FOUND: 'No se puede encontrar el recurso.',
    ERROR_CODE_RESOURCE_KEY_DUPLICATION:
        'No se puede usar la clave de recurso.',
    ERROR_CODE_RESOURCE_KEY_EMPTY: 'La clave de recurso está vacía.',
    ERROR_CODE_COMPANY_BUSINESS_REG_NUM_EXIST:
        'El n.º de registro del operador ya existe.',
    ERROR_CODE_EXIST_BRANCH_USER: 'Existe un usuario de sucursal.',
    'Vehicle.BasicInfo.ModelYear.Placeholder':
        'Introduzca el año del modelo del vehículo',
    'Vehicle.BasicInfo.ModelYear': 'Año del modelo',
    'Vehicle.BasicInfo.ExtendCnt':
        'Recuento de la extensión de la edad del vehículo',
    'Vehicle.BasicInfo.RegularInspectionDate': 'Inspección periódica',
    'Vehicle.ExtendCnt.0': '0 veces',
    'Vehicle.ExtendCnt.1': 'Una vez',
    'Vehicle.ExtendCnt.2': 'Dos veces',
    'Branch.Manager.Title': 'Gerente de la sucursal',
    ERROR_CODE_ALREADY_RESERVED: 'La fecha ya ha sido reservada.',
    'LongRental.Charge.Bank_CARD': 'Compañía de la tarjeta',
    'LongRental.Charge.CountNumber_CARD': 'Tarjeta n.º',
    'LongRental.Charge.CountOwner_CARD': 'Titular de la tarjeta',
    'VehicleInsurance.Payment.1': '1.º',
    'VehicleInsurance.Payment.2': '2.º',
    'VehicleInsurance.Payment.3': '3.º',
    'VehicleInsurance.Payment.4': '4.º',
    'VehicleInsurance.Payment.5': '5.º',
    'VehicleInsurance.Payment.6': '6.º',
    'VehicleInsurance.Payment.7': '7.º',
    'VehicleInsurance.Payment.8': '8.º',
    'VehicleInsurance.Payment.9': '9.º',
    'VehicleInsurance.Payment.10': '10.º',
    'VehicleInsurance.Payment.11': '11.º',
    'VehicleInsurance.Payment.12': '12.º',
    'Vehicle.Model.Info': 'Información sobre el modelo de vehículo',
    'VehicleInsurance.Select.Paid': 'Pagado',
    'VehicleInsurance.Day.type': 'Seleccione una fecha',
    'VehicleInsurance.Day.Duration': 'Duración del pago',
    'VehicleInsurance.Modal.Year': 'N.º de años/secuencia',
    'Common.Buuton.Close': 'Cerrar',
    'Vehicle.Used': 'Estado del alquiler disponible',
    'Vehicle.Used.Yes': 'Alquiler disponible',
    'Vehicle.Used.No': 'Alquiler detenido',
    'Common.Btn.Terminal': 'Transmisión del terminal',
    'Reservation.Request.Terminal.Fail':
        'No se puede enviar la información de la reserva a su terminal.',
    'Reservation.Request.Terminal.Success':
        'Se ha solicitado el envío de la información de la reserva a su terminal.',
    'Reservation.Request.Terminal.Confirm':
        '¿Desea enviar la información de la reserva a su terminal?',
    'TestCarConfig.Reservation.StartTime':
        'Establecer la fecha/hora de inicio de la reserva',
    'Common.Input.Required.Hour': 'Introduzca un valor de 0 a 23.',
    'Common.Input.Required.Day': 'Introduzca un valor de 1 a 31',
    'Location.Radius.50m': '50 m',
    'Location.Radius.100m': '100 m',
    'Location.Radius.200m': '200 m',
    'Location.Radius.300m': '300 m',
    'Menu.Reservation.TestCar': 'Gestión de alquiler de coches de prueba',
    'Charge.Minute.Zero': '0 m',
    'Charge.Minute.Ten': '10 minutos',
    'Charge.Minute.Twenty': '20 minutos',
    'Charge.Minute.Thirty': '30 minutos',
    'Charge.Minute.Forty': '40 minutos',
    'Charge.Minute.Fifty': '50 minutos',
    'LongRental.Payment.Day.One': '1 día',
    'LongRental.Payment.Day.Five': '5 días',
    'LongRental.Payment.Day.Ten': '10 días',
    'LongRental.Payment.Day.Fifteen': '15 días',
    'LongRental.Payment.Day.Twenty': '20 días',
    'LongRental.Payment.Day.TwentyFifth': '25 días',
    'LongRental.Payment.Day.Last': 'El último día del mes',
    'LongRental.RentalInfo.Year.Two': '24 meses',
    'LongRental.RentalInfo.Year.Three': '36 meses',
    'LongRental.RentalInfo.Year.Four': '48 meses',
    'LongRental.RentalInfo.Year.Five': '60 meses',
    'LongRental.Charge.Bank_CARD.Placeholder':
        'Seleccione la compañía de la tarjeta.',
    'LongRental.Charge.CountNumber_CARD.Placeholder':
        'Introduzca el n.º de tarjeta',
    'LongRental.Charge.CountOwner_CARD.Placeholder':
        'Introduzca el titular de la tarjeta.',
    ERROR_CODE_LICENSE_USE_BUSINESS_EXIST:
        'No se puede eliminar porque un coche de negocios o de alquiler está utilizando el plan de tarifas.',
    ERROR_CODE_MENU_CHILDREN_EXIST:
        'No se puede eliminar debido a un submenú existente',
    ERROR_CODE_ROLE_CHILDREN_EXIST:
        'No se puede eliminar debido a los datos de subautoridad existentes',
    'TestCar.Reservation.View': 'Vista',
    'Common.Input.Required.Percent': 'Introduzca un valor de 1 a 100',
    'Terminal.message':
        '*Puede que no coincida con la información del terminal físico recuperado*',
    'Reservation.Request.BeContractCancle.Success':
        'Se cancela el alquiler a largo plazo de un coche nuevo.',
    'VehicleInsurance.4year': '4.º año',
    'VehicleInsurance.5year': '5.º año',
    'VehicleInsurance.6year': '6.º año',
    'VehicleInsurance.7year': '7.º año',
    'VehicleInsurance.8year': '8.º año',
    'VehicleInsurance.9year': '9.º año',
    'VehicleInsurance.10year': '10.º año',
    'Reservation.Error.SelectPeriod':
        'Seleccione la fecha y hora estimadas del alquiler.',
    'Reservation.Error.StartLocation': 'Seleccione una ubicación de entrega.',
    'Common.Error.message': 'Acción deshabilitada.',
    'Title.PaymentManager': 'Estado de las ventas/liquidación',
    'Menu.Payment.Violation.Mgnt': 'Gestión de multas/peaje',
    ERROR_CODE_NOT_FOUND_RESERVATION_DATA:
        'No existe ninguna reserva relacionada con la infracción.',
    ERROR_CODE_FORCERETURN_CAN_BE_MADE_AFTER_THE_START_TIME:
        'La devolución forzada se puede realizar después del inicio del alquiler',
    'Password.Infomation1':
        '- Debe introducir la contraseña antes de ver su contrato de alquiler para proteger su información personal',
    'Password.Infomation2':
        '- La contraseña es su fecha de nacimiento de seis dígitos o los primeros seis dígitos del número del registro mercantil de la empresa. Introduzca la contraseña y haga clic en el botón Aceptar',
    'Password.CompanyInfo1': 'Operador',
    'Vehicle.Registration.Card': 'Tarjeta de matriculación del vehículo',
    'Vehicle.Registration.Card.Btn.Create':
        'Registrar la tarjeta de matriculación del vehículo',
    'Vehicle.Registration.Card.Create.Help':
        'Seleccione una tarjeta de matriculación del vehículo (jpeg, jpg, png).',
    'Vehicle.Registration.Card.Create.ExtName.Help':
        'Solo se pueden usar extensiones jpeg, jpg y png.',
    'Vehicle.Registration.Card.Btn.Upload':
        'Cargar la tarjeta de matriculación del vehículo',
    'Vehicle.Registration.Card.Upload.Fail':
        'No se ha podido analizar la tarjeta de matriculación del vehículo.',
    'Common.Buuton.Kakaotalk': 'Kakao talk',
    'Common.fail.changeMonth': 'Seleccione un período de alquiler',
    'Send.Kakaotalk.Fail':
        'No se ha podido enviar la notificación de KakaoTalk.',
    'Send.Kakaotalk.Success':
        'La notificación de KakaoTalk se ha enviado correctamente.',
    'Send.Kakaotalk.Password.Fail': 'La contraseña introducida es incorrecta',
    'Send.Kakaotalk.Password.Success': 'Contraseña introducida correctamente.',
    ERROR_CODE_TODAY_IS_NOT_SAME_DATE:
        'El alquiler solo se puede registrar con la fecha de hoy.',
    'Common.Error.Validation':
        'Por favor, rellene todos los campos obligatorios.',
    ERROR_CODE_NOT_MATCH_PASSWORD: 'La contraseña no coincide.',
    'Vehicle.BasicInfo.Insurance.Limit': 'Límite del seguro',

    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */
    'Vehicle.BasicInfo.Equip': '장착',
    'Vehicle.BasicInfo.Not.Equip': '미장착',
    'Vehicle.Title.RealTimeInfo': '실시간차량정보',
    'Vehicle.Title.AdditionalInfo': '부가정보',
    'Vehicle.AdditionalInfo.iviID': 'IVI ID',
    'Vehicle.AdditionalInfo.iviID.Call': 'IVI ID 불러오기',
    'Vehicle.AdditionalInfo.asPeriod': 'A/S 기간',
    'Vehicle.AdditionalInfo.taxiDrivingGroup': '택시운행조',
    'Vehicle.AdditionalInfo.VIN.FieldNull': '차대번호를 입력해주세요.',
    'Vehicle.AdditionalInfo.iviID.Fail': 'IVI ID 조회 실패',
    'Vehicle.AdditionalInfo.iviID.Empty':
        '차대번호에 해당되는 IVI ID가 존재하지 않습니다.',

    'Vehicle.Tab.Sales': '차량별매출',
    'Vehicle.Sales.HistoryTime': '발생일시',
    'Vehicle.Sales.Business.Time': '영업시간(거리)',

    'Menu.Vehicle.OperationStatistics': '차량운행일지',
    'Vehicle.OperationStatistics.Btn.Download': '엑셀다운로드',
    'Terms.Search.Period': '조회기간',
    'Vehicle.OperationStatistics.Vehicle.Number': '차량번호',
    'Vehicle.OperationStatistics.TaxiDriver.Name': '기사명',
    'Vehicle.OperationStatistics.Date': '날짜',
    'Vehicle.OperationStatistics.Login.Time': '출근시간',
    'Vehicle.OperationStatistics.Logout.Time': '퇴근시간',
    'Vehicle.OperationStatistics.Driving.Time': '주행시간(거리)',
    'Vehicle.OperationStatistics.Business.Time': '영업시간(거리)',
    'Vehicle.OperationStatistics.Empty.Time': '공차시간(거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount': '총영업금액(호출/배회)',

    'Vehicle.OperationStatistics.Title.Info': '차량운행 상세 정보',
    'Vehicle.OperationStatistics.Work.Time': '출퇴근시간',
    'Vehicle.OperationStatistics.TotalDriving.Time': '총주행시간(거리)',
    'Vehicle.OperationStatistics.TotalBusiness.Time': '총영업시간(거리)',
    'Vehicle.OperationStatistics.TotalEmpty.Time': '총공차시간(거리)',
    'Vehicle.OperationStatistics.Business.Rate': '영업률(영업거리/총주행거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount2': '총매출금액',
    'Vehicle.OperationStatistics.Business.Count': '영업건수/객단가',
    'Vehicle.OperationStatistics.CallType.Count': '호출/배회건수',
    'Vehicle.OperationStatistics.CallType.SalesAmount': '호출/배회 매출금액',

    'Vehicle.OperationStatistics.Departure.Time': '승차시간',
    'Vehicle.OperationStatistics.Arrive.Time': '하차시간',
    'Vehicle.OperationStatistics.Departure.PlaceName': '승차위치',
    'Vehicle.OperationStatistics.Arrive.PlaceName': '하차위치',
    'Vehicle.OperationStatistics.Call.YN': '호출여부',
    'Vehicle.OperationStatistics.Call.App': '호출APP',
    'Vehicle.OperationStatistics.Payment.Method': '결제방식',
    'Vehicle.OperationStatistics.SalesAmount': '매출금액',
    'Vehicle.OperationStatistics.Create.Time': '운행정보입력시간',
    'Vehicle.OperationStatistics.PaymentType.Cash': '현금',
    'Vehicle.OperationStatistics.PaymentType.Card': '카드',
    'Vehicle.OperationStatistics.AppType.Macaron': '마카롱배차앱',
    'Vehicle.OperationStatistics.AppType.TMoney': '티머니미터기',
    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */

    // 차량만령관리
    'Vehicle.ExpiryHeader.Title': '차량만령관리',
    'Vehicle.ExpirySearch.Title': '차령만료일 기간',
    'Vehicle.ExpirySearch.all': '전체',
    'Vehicle.ExpirySearch.prevday': '1일전',
    'Vehicle.ExpirySearch.prevweek': '1주일전',
    'Vehicle.ExpirySearch.prevtwoweek': '2주일전',
    'Vehicle.ExpirySearch.prevmonth': '1달전',
    'Vehicle.ExpirySearch.prevyear': '1년 2주전',
    'Vehicle.ExpirySearch.today': '당일',
    'Vehicle.ExpiryHeader.vehicleAge': '차량연령',

    // 택시통합연동로그
    'Menu.ReceiveLog': '택시통합연동로그',
    'Title.ReceiveLog': '택시통합연동로그',
    'ReceiveLog.Btn.Download': '엑셀다운로드',
    'ReceiveLog.Filter.RegDate': '등록일',
    'ReceiveLog.Filter.InterfaceId': 'InterfaceID',
    'ReceiveLog.Filter.DriverName': '기사명',
    'ReceiveLog.Filter.Pincode': 'PINCODE',
    'ReceiveLog.Filter.ResultCodeKind': '응답코드종류',
    'ReceiveLog.Filter.ResultCode': '응답코드',
    'ReceiveLog.ResultCodeKind.All': '전체',
    'ReceiveLog.ResultCodeKind.Success': '성공',
    'ReceiveLog.ResultCodeKind.Failure': '실패',
    // 'ReceiveLog.Item.ID' : 'ID',
    'ReceiveLog.Item.CreatedDateTime': '생성일시',
    'ReceiveLog.Item.YdServiceName': '서비스명',
    'ReceiveLog.Item.InterfaceId': 'InterfaceID',
    'ReceiveLog.Item.InterfaceName': 'Interface명',
    'ReceiveLog.Item.AccessDateTime': '요청일시',
    // 'ReceiveLog.Item.SessionId' : '세션ID',
    'ReceiveLog.Item.UrlPath': 'API URL',
    // 'ReceiveLog.Item.HttpMethod' : '메소드명',
    // 'ReceiveLog.Item.ClientIp' : 'Client IP',
    'ReceiveLog.Item.TaxiDriverName': '기사명',
    'ReceiveLog.Item.Pincode': 'PINCODE',
    // 'ReceiveLog.Item.Vin' : 'VIN No.',
    'ReceiveLog.Item.Param': 'Parameter',
    'ReceiveLog.Item.ResultCode': '응답코드',
    'ReceiveLog.Item.ResultMsg': '응답메시지',
    'ReceiveLog.Item.InnerResultMsg': '응답메시지상세',
    'ReceiveLog.Item.ResultValue': '응답값',
    // 차량기록조회
    'Vehicle.Records.Btn.Download': '엑셀다운로드',
    'Terms.Search.Time': '조회시간*',
    'Vehicle.Records.Vehicle.Number': '차대번호*',
    'Menu.Vehicle.Records': '차량실시간정보',
    'Vehicle.Records.Minute.Placeholder': '(분)',
    'Vehicle.Records.Hour.Placeholder': '(시)',
    'Vehicle.Records.Comment':
        '* 선택한 시간부터 10분간의 데이터를 조회합니다.',
};

export default language;
