import { message } from 'antd';
import { TRANSE_MODE } from 'util/pageUtil';
import { Modal } from 'antd';
import parse from 'html-react-parser';
import { ErrorCode, getErrorMessage } from 'config/locale/errors';
import React from 'react';
import _ from 'lodash';

const MESSAGE_DURATION = 2;

const ConfirmMessagePage = async ({ intl, msgID, defaultMsg, values }) => {
    if (!intl) return;
    if (msgID || defaultMsg) {
        const result = await new Promise((resolve, reject) => {
            Modal.confirm({
                icon: <></>,
                content: intl.formatMessage(
                    { id: msgID, defaultMessage: defaultMsg || msgID },
                    { ...values, br: <br /> },
                ),
                okText: intl.formatMessage({ id: 'Btn.Confirm' }),
                cancelText: intl.formatMessage({ id: 'Btn.Cancel' }),
                onOk() {
                    resolve(true);
                },
                onCancel() {
                    reject(false);
                },
            });
        });

        return result;
    }
};

const SuccessMessagePage = ({ intl, msgID, defaultMsg, values }) => {
    if (!intl) return;
    if (msgID || defaultMsg) {
        message.success(
            intl.formatMessage(
                { id: msgID, defaultMessage: defaultMsg || msgID },
                { ...values, br: <br /> },
            ),
            MESSAGE_DURATION,
        );
    }
};

const FailureMessagePage = ({ intl, msgID, defaultMsg, values }) => {
    if (!intl) return;
    if (msgID || defaultMsg) {
        message.error(
            intl.formatMessage(
                { id: msgID, defaultMessage: defaultMsg || msgID },
                { ...values, br: <br /> },
            ),
            MESSAGE_DURATION,
        );
    }
};

const AlertMessagePage = ({ intl, msgID, defaultMsg, values }) => {
    if (!intl) return;
    if (msgID || defaultMsg) {
        message.warning(
            intl.formatMessage(
                { id: msgID, defaultMessage: defaultMsg || msgID },
                { ...values, br: <br /> },
            ),
            MESSAGE_DURATION,
        );
    }
};

const TransConfirm = ({ intl, mode = TRANSE_MODE.GET, message, values }) => {
    let msgID = message;
    if (!msgID) {
        switch (mode) {
            case TRANSE_MODE.CREATE:
                msgID = 'Message.Confirm.Save';
                break;
            case TRANSE_MODE.UPDATE:
                msgID = 'Message.Confirm.Update';
                break;
            case TRANSE_MODE.DELETE:
                msgID = 'Message.Confirm.Delete';
                break;
            default:
                break;
        }
    }

    return ConfirmMessagePage({ intl, msgID, values });
};

const TransSuccess = ({ intl, mode = 'view', message, values }) => {
    let msgID = message;
    if (!msgID) {
        switch (mode) {
            case TRANSE_MODE.CREATE:
                msgID = 'Message.Success.Save';
                break;
            case TRANSE_MODE.UPDATE:
                msgID = 'Message.Success.Update';
                break;
            case TRANSE_MODE.DELETE:
                msgID = 'Message.Success.Delete';
                break;
            default:
                break;
        }
    }
    SuccessMessagePage({ intl, msgID, values });
};

const TransFailure = ({ intl, error, mode = '', message, values }) => {
    console.error('[ERROR]', error);
    let msgID = message;

    const errorHandler = error?.errorHandler;
    const { errorCode, message: errCode } = error?.data || {};
    const tError = _.parseInt(errCode);
    const findItem = ErrorCode.find(
        (item) => tError === _.parseInt(item?.code),
    );
    if (errorCode && findItem) {
        msgID = getErrorMessage(tError);
    }
    if (!msgID) {
        switch (mode) {
            case TRANSE_MODE.CREATE:
                msgID = 'Message.Failure.Save';
                break;
            case TRANSE_MODE.UPDATE:
                msgID = 'Message.Failure.Update';
                break;
            case TRANSE_MODE.DELETE:
                msgID = 'Message.Failure.Delete';
                break;
            case TRANSE_MODE.GET:
                msgID = 'Message.Get.Fail';
                break;
            case 'action':
                msgID = 'Message.Action.Fail';
                break;
            default:
                break;
        }
    }

    if (error?.response?.status === 401 && errorHandler) {
        msgID = '';
    }

    // 사용자 설정 메세지 가 없을경우 initialize.js 의 초기 메세지 사용
    if (!msgID && errorHandler && errorHandler(error?.response)) {
        return;
    }

    // 사용자 설정 메세지
    FailureMessagePage({
        intl,
        msgID,
        values: { errorCode: errCode, ...values },
    });
};

const TransHtmlParse = (response) => {
    if (!_.isEmpty(response)) {
        // response 가 pageInfo, items 로 이루어져 있을 경우
        if (_.has(response, 'items') && _.isArray(response.items)) {
            return _.map(response.items, (item) => {
                const { msgKey, name, ...restItem } = item;
                return {
                    ...restItem,
                    msgKey,
                    name:
                        !_.isEmpty(msgKey) &&
                        msgKey !== 'undefined' &&
                        _.isString(name)
                            ? parse(name, { trim: true })
                            : name,
                };
            });
            // response 가 복수건 array 일 경우
        } else if (_.isArray(response)) {
            return _.map(response, (item) => {
                const { msgKey, name, ...restItem } = item;
                return {
                    ...restItem,
                    msgKey,
                    name:
                        !_.isEmpty(msgKey) &&
                        msgKey !== 'undefined' &&
                        _.isString(name)
                            ? parse(name, { trim: true })
                            : name,
                };
            });

            // response 가 단건 object 일 경우
        } else if (_.isObject(response)) {
            const { msgKey, name, ...restResponse } = response;
            return {
                ...restResponse,
                msgKey,
                name:
                    !_.isEmpty(msgKey) &&
                    msgKey !== 'undefined' &&
                    _.isString(name)
                        ? parse(name, { trim: true })
                        : name,
            };
        }
    }
    return response;
};

const CustomizedConfirmMessagePage = async ({
    intl,
    titleMsgID,
    msgID,
    defaultMsg,
    values,
    okTextId,
}) => {
    if (msgID || defaultMsg) {
        const result = await new Promise((resolve, reject) => {
            Modal.confirm({
                icon: <></>,
                width: 540,
                title: (
                    <div className="wbPopupHeader">
                        <div className="popupTitle">
                            {intl.formatMessage(
                                {
                                    id: titleMsgID,
                                    defaultMessage: defaultMsg || titleMsgID,
                                },
                                { ...values, br: <br /> },
                            )}
                        </div>
                    </div>
                ),
                content: (
                    <div className="wbPopupContent">
                        {intl.formatMessage(
                            { id: msgID, defaultMessage: defaultMsg || msgID },
                            { ...values, br: <br /> },
                        )}
                    </div>
                ),
                okText: intl.formatMessage({ id: okTextId || 'Btn.Confirm' }),
                cancelText: intl.formatMessage({ id: 'Btn.Cancel' }),
                onOk() {
                    resolve(true);
                },
                onCancel() {
                    reject(false);
                },
            });
        });

        return result;
    }
};

const InfoMessagePage = ({
    intl,
    titleMsgID,
    msgID,
    defaultMsg,
    values,
    additionalContents,
    onOk,
}) => {
    Modal.info({
        icon: <></>,
        width: 540,
        title: (
            <div className="wbPopupHeader">
                <div className="popupTitle">
                    {intl.formatMessage(
                        {
                            id: titleMsgID,
                            defaultMessage: defaultMsg || titleMsgID,
                        },
                        { ...values, br: <br /> },
                    )}
                </div>
            </div>
        ),
        content: (
            <div className="wbPopupContent">
                <div className="popupInfoText">
                    {intl.formatMessage(
                        { id: msgID, defaultMessage: defaultMsg || msgID },
                        { ...values, br: <br /> },
                    )}
                </div>
                {additionalContents && (
                    <div className="popupGrayBox">{additionalContents}</div>
                )}
            </div>
        ),
        okText: intl.formatMessage({ id: 'Btn.Confirm' }),
        onOk() {
            if (_.isFunction(onOk)) return onOk(true);
        },
    });
};

export {
    ConfirmMessagePage,
    AlertMessagePage,
    SuccessMessagePage,
    TransSuccess,
    FailureMessagePage,
    TransFailure,
    TransConfirm,
    TransHtmlParse,
    CustomizedConfirmMessagePage,
    InfoMessagePage,
};
