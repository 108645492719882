import React, { useState, useMemo } from 'react';

import { EditorToolsSettings, EditorUtils } from '@progress/kendo-react-editor';
import { Button } from '@progress/kendo-react-buttons';
import { trimWhitespace } from '@progress/kendo-editor-common';

import { TextArea } from 'component/mux';
import CustomDialog from './CustomDialog';

/**
 * /kor.mop.bo.web/node_modules/@progress/kendo-react-editor/dist/npm/tools/insertLink.js
 * 분석하여 작성
 */
const { commandName, props } = EditorToolsSettings.viewHtml;

const ViewHtml = ({ view }) => {
    const state = view && view.state;

    const htmlArea = useMemo(
        () =>
            state
                ? EditorUtils.indentHtml(EditorUtils.getHtml(state))
                : undefined,
        [state],
    );

    const [visible, setVisible] = useState(false);

    const handleCancel = () => setVisible(!visible);

    const handleSubmit = (data) => {
        const html = trimWhitespace(data.htmlArea ? data.htmlArea : '');
        EditorUtils.setHtml(view, html, commandName, {
            preserveWhitespace: 'full',
        });
        handleCancel();
    };

    return (
        <>
            <Button onClick={handleCancel} {...props} />
            <CustomDialog
                visible={visible}
                resId="Title.WebEditor.ViewHtml"
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            >
                <TextArea name="htmlArea" value={htmlArea} />
            </CustomDialog>
        </>
    );
};

export default ViewHtml;
