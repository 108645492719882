import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import { useIntl } from 'react-intl';
import { Section, Element as MuxElement } from 'mux';

const Element = ({
    label,
    resId,
    required,
    children,
    span,
    labelClassName,
    labelStyle,
    contentClassName,
    contentStyle,
}) => {
    const intl = useIntl();
    const text = useMemo(() => {
        const chunkText = resId ? intl.formatMessage({ id: resId }) : label;
        return _.isString(chunkText) ? parse(chunkText) : chunkText;
    }, [label, resId, intl]);

    return (
        <MuxElement
            text={text}
            required={required}
            span={span}
            labelClassName={labelClassName}
            labelStyle={labelStyle}
            contentClassName={contentClassName}
            contentStyle={contentStyle}
        >
            {children}
        </MuxElement>
    );
};

Element.propTypes = {
    span: PropTypes.number.isRequired,
    label: PropTypes.string,
    resId: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    labelClassName: PropTypes.string,
    labelStyle: PropTypes.object,
    contentClassName: PropTypes.string,
    contentStyle: PropTypes.object,
};

Element.defaultProps = {
    span: 1,
    required: false,
};

export { Section, Element };
