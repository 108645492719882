import { Suspense, useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import _ from 'lodash';
import { useAppRoot } from 'component/mux';
import { useAuthentication } from 'util/authentication';
import { AuthedAppMobile } from './AuthedAppMobile';
import { UnAuthedAppMobile } from './UnAuthedAppMobile';

const GateKeeperMobile = () => {
    // 기본 State 선언
    const { fetchSession } = useAuthentication();
    // const { customTransaction } = useTransaction(TRANSID_AUTHENTICATION);
    const [IsAuthed, setAuthed] = useState(false);
    const [randerHandle, setRanderHandle] = useState(false);
    const { session } = useAppRoot();

    // 로그인 감지
    useEffect(() => {
        if (_.isNull(session) || _.isUndefined(session) || _.isEmpty(session)) {
            fetchSession()
                .then((data) => {
                    // 인증 상태인경우 afeter처리
                    setAuthed(true);
                })
                .catch((error) => {
                    // 미인증 상태일경우 아무 처리 하지 않음
                    setAuthed(false);
                });
        } else {
            setAuthed(true);
        }
    }, [randerHandle]);

    // 로그아웃 감지
    useEffect(() => {
        if (_.isNull(session) || _.isUndefined(session) || _.isEmpty(session)) {
            setAuthed(false);
        }
    }, [session]);

    const onRanderHandle = () => {
        setRanderHandle((prev) => {
            return !prev;
        });
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <If condition={IsAuthed}>
                <Then>
                    <AuthedAppMobile onRanderHandle={onRanderHandle} />
                </Then>
                <Else>
                    <UnAuthedAppMobile onRanderHandle={onRanderHandle} />
                </Else>
            </If>
        </Suspense>
    );
};

export default GateKeeperMobile;

export { GateKeeperMobile };
