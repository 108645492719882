import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { If, Then, Else } from 'react-if';
import { Tabs } from 'antd';
import { useAppRoot } from 'component/mux';
const { TabPane } = Tabs;

const TabGroup = ({ children, tabGroup, activeKey, OnChangeTab }) => {
    const intl = useIntl();
    const { menu } = useAppRoot();
    const tapMenus = _.filter(menu, {
        menuType: 'TAB',
    });

    const tabs = useCallback(
        tabGroup?.length
            ? tabGroup.filter((item) => {
                  let permitShow = true;
                  const tabUrl = item?.key;

                  if (tapMenus && tabUrl) {
                      //   const tabAclYn = hasPermit(tabUrl);
                      const findedTabObj = _.find(tapMenus, {
                          menuUrl: tabUrl,
                      });

                      // 설정한 Tab 권한이 없으면 Tab 을 노출하고, 있을경우만 체크
                      if (!_.isEmpty(findedTabObj)) {
                          permitShow = findedTabObj?.inquiryYn === 'Y'; // false 설정(지정 URL 권한 확인)
                      }
                  }
                  return permitShow;
              })
            : [],
        [tabGroup],
    );

    return (
        <If condition={tabs?.length > 1}>
            <Then>
                <Tabs
                    activeKey={activeKey}
                    onChange={OnChangeTab}
                    className="wbTabWrap"
                    type="card"
                >
                    {tabs.map((item) => {
                        return (
                            <TabPane
                                tab={intl.formatMessage({
                                    id: item?.resId,
                                    defaultMessage: item?.title || item?.resId,
                                })}
                                key={item?.key || item?.resId}
                            >
                                {item?.panel || <></>}
                            </TabPane>
                        );
                    })}
                </Tabs>
            </Then>
            <Else>{children}</Else>
        </If>
    );
};

export default TabGroup;
