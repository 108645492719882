import { useState } from 'react';
import { Link } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { FormattedMessage } from 'react-intl';

import { useAppRoot, Text } from 'component/mux';
import NoticeGnb from 'component/dialogComponent/NoticeDialog/NoticeGnb';

const GlobalNavigationBar = () => {
    const { session } = useAppRoot();
    const [alramVisible, setAlramVisible] = useState(false);
    const [alramCnt, setAlramCnt] = useState(0);

    const fnVisible = () => {
        setAlramVisible((prev) => {
            return !prev;
        });
    };

    const quickNotiCnt = (cnt) => {
        setAlramCnt(cnt);
    };

    return (
        <>
            <div id="header">
                <div className="headerIn">
                    <div className="noticeName">
                        <div className="quickNotice">
                            <If
                                condition={
                                    session?.activationStatusCd === 'NORMAL' &&
                                    [
                                        'BUSINESS_MANAGER',
                                        'BUSINESS_OPERATOR',
                                    ].includes(session?.role)
                                }
                            >
                                <Then>
                                    <button
                                        type="button"
                                        className="quickNoticeBtn"
                                        onClick={fnVisible}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/image/common/icon_bell.png`}
                                            alt={
                                                <Text resId="Common.Bnb.Alram" />
                                            }
                                        />
                                        <span>{alramCnt}</span>
                                    </button>
                                </Then>
                            </If>
                        </div>
                        <div className="userName">
                            <FormattedMessage
                                id="Message.Logout.Hello"
                                values={{ user: <span>{session?.name}</span> }}
                            />
                        </div>
                    </div>
                    <div className="setupLogin">
                        {session?.activationStatusCd === 'NORMAL' && (
                            <If
                                condition={[
                                    'BUSINESS_MANAGER',
                                    'BUSINESS_OPERATOR',
                                ].includes(session?.role)}
                            >
                                <Then>
                                    <div className="label">
                                        <Text
                                            resId={`Role.${session?.role}`}
                                            className="cate"
                                        />
                                    </div>
                                    <span className="company">
                                        {session?.business?.name}
                                    </span>
                                </Then>
                                <Else>
                                    {session?.roleInfo?.systemType ===
                                    'B2BG' ? (
                                        <>
                                            <div className="label">
                                                <Text
                                                    resId={`Role.MOBILITY_MANAGER`}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="label">
                                                <Text
                                                    resId={`Role.MOBILITY_MANAGER.Partner`}
                                                    className="cate"
                                                />
                                            </div>
                                            <span className="company">
                                                {session?.business?.name}
                                            </span>
                                        </>
                                    )}
                                </Else>
                            </If>
                        )}
                        <Link to="/myInfo">
                            <Text resId="Btn.Setup" className="setup" />
                        </Link>
                        <Link to="/signOut">
                            <Text resId="Common.Logout" className="Login" />
                        </Link>
                    </div>
                </div>
            </div>
            <If condition={session?.activationStatusCd === 'NORMAL'}>
                <Then>
                    <NoticeGnb
                        visible={alramVisible}
                        onClose={fnVisible}
                        lengCallBack={quickNotiCnt}
                    />
                </Then>
            </If>
        </>
    );
};

export default GlobalNavigationBar;
