import { matchPath } from 'react-router';
import { PAGE_MODE } from 'util/pageUtil';

const AuthedRoutes = [
    /* 차량 등록/조회 */
    {
        url: '/vehicle',
        page: () => import('page/vehicle/VehicleListPage'),
    },
    {
        url: '/vehicle/new',
        page: () => import('page/vehicle/VehicleDetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/vehicle' },
    },
    {
        url: '/vehicle/:ID/form',
        page: () => import('page/vehicle/VehicleDetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/vehicle' },
    },
    {
        url: '/vehicle/:ID',
        page: () => import('page/vehicle/VehicleDetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/vehicle' },
    },
    /* 차량 관제 */
    {
        url: '/monitoring',
        page: () => import('page/monitoring/v2'),
    },
    {
        url: '/monitoring/:vehicleID',
        page: () => import('page/monitoring'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/monitoring' },
    },
    /* 차량 운행일지 */
    {
        url: '/vehicleDailyOperation',
        page: () =>
            import('page/vehicleDailyOperation/VehicleDailyOperationListPage'),
    },
    /* 차종(차량모델) */
    {
        url: '/vehicleModel',
        page: () => import('page/vehicleModel/VehicleModelListPage'),
    },
    {
        url: '/vehicleModel/new',
        page: () => import('page/vehicleModel/VehicleModelDetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/vehicleModel' },
    },
    {
        url: '/vehicleModel/:vehicleModelID/form',
        page: () => import('page/vehicleModel/VehicleModelDetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/vehicleModel' },
    },
    {
        url: '/vehicleModel/:vehicleModelID',
        page: () => import('page/vehicleModel/VehicleModelDetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/vehicleModel' },
    },
    /* 단말기 */
    {
        url: '/terminal',
        page: () => import('page/terminal/TerminalListPage'),
    },
    {
        url: '/terminal/new',
        page: () => import('page/terminal/TerminalDetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/terminal' },
    },
    {
        url: '/terminal/:ID/form',
        page: () => import('page/terminal/TerminalDetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/terminal' },
    },
    {
        url: '/terminal/:ID',
        page: () => import('page/terminal/TerminalDetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/terminal' },
    },
    /* 단말기 장착 검증 */
    {
        url: '/terminalVerification',
        page: () => import('page/terminalVerification/ListPage'),
    },
    {
        url: '/terminalVerification/:ID',
        page: () => import('page/terminalVerification/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/terminalVerification' },
    },
    /* 충전/주유 이력 */
    {
        url: '/refuel',
        page: () => import('page/refuel/RefuelListPage'),
    },
    /* 하이패스 이력 */
    {
        url: '/transit',
        page: () => import('page/transit/TransitListPage'),
    },
    /* 사고수리 이력(일본) */
    {
        url: '/vehicleRepair',
        page: () => import('page/vehicleRepair_JP/ListPage'),
    },
    {
        url: '/vehicleRepair/new',
        page: () => import('page/vehicleRepair_JP/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/vehicleRepair' },
    },
    {
        url: '/vehicleRepair/:ID',
        page: () => import('page/vehicleRepair_JP/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/vehicleRepair' },
    },
    {
        url: '/vehicleRepair/:ID/form',
        page: () => import('page/vehicleRepair_JP/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/vehicleRepair' },
    },
    /* 유지관리/정비 이력 */
    {
        url: '/vehicleOperation',
        page: () => import('page/vehicleMaintenance/ListPage'),
        props: { baseURL: '/vehicleOperation' },
    },
    {
        url: '/vehicleOperation/new',
        page: () => import('page/vehicleMaintenance/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/vehicleOperation' },
    },
    {
        url: '/vehicleOperation/:ID/form',
        page: () => import('page/vehicleMaintenance/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/vehicleOperation' },
    },
    {
        url: '/vehicleOperation/:ID',
        page: () => import('page/vehicleMaintenance/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/vehicleOperation' },
    },
    /* 매각 */
    {
        url: '/trade',
        page: () => import('page/trade/ListPage'),
    },
    {
        url: '/trade/new',
        page: () => import('page/trade/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/trade' },
    },
    {
        url: '/trade/:ID/form',
        page: () => import('page/trade/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/trade' },
    },
    /* 차량제어 이력 Tab */
    {
        url: '/controlHistory',
        page: () => import('page/TabGroupPage'),
        props: {
            baseURL: '/controlHistory/server',
            menuURL: '/controlHistory',
        },
    },
    /* 차량제어 이력(서버) */
    {
        url: '/controlHistory/server',
        page: () => import('page/controlHistoryServer/ListPage'),
        props: {
            baseURL: '/controlHistory/server',
            menuURL: '/controlHistory',
        },
    },
    {
        url: '/controlHistory/server/:ID',
        page: () => import('page/controlHistoryServer/DetailPage'),
        props: {
            mode: PAGE_MODE.VIEW,
            baseURL: '/controlHistory/server',
            menuURL: '/controlHistory',
        },
    },
    /* 차량제어 이력(BLE) */
    {
        url: '/controlHistory/ble',
        page: () => import('page/controlHistoryBle/ListPage'),
        props: {
            baseURL: '/controlHistory/ble',
            menuURL: '/controlHistory',
        },
    },
    {
        url: '/controlHistory/ble/:ID',
        page: () => import('page/controlHistoryBle/DetailPage'),
        props: {
            mode: PAGE_MODE.VIEW,
            baseURL: '/controlHistory/ble',
            menuURL: '/controlHistory',
        },
    },
    /* 스테이션 등록/조회 */
    {
        url: '/location',
        page: () => import('page/location/LocationListPage'),
    },
    {
        url: '/location/new',
        page: () => import('page/location/LocationDetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/location' },
    },
    {
        url: '/location/:locationID/form',
        page: () => import('page/location/LocationDetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/location' },
    },
    {
        url: '/location/:locationID',
        page: () => import('page/location/LocationDetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/location' },
    },
    // /* 대여 현황 */
    // {
    //     url: '/reservationSchedule',
    //     page: () =>
    //         import('page/reservationSchedule/ReservationScheduleBasePage'),
    // },
    /* 대여 현황 Tab */
    {
        url: '/reservationSchedule',
        page: () => import('page/TabGroupPage'),
        props: {
            baseURL: '/reservationSchedule/daily',
            menuURL: '/reservationSchedule',
        },
    },
    /* 대여 현황 > 일 별 */
    {
        url: '/reservationSchedule/daily',
        page: () =>
            import('page/reservationSchedule/ReservationScheduleDailyPage'),
        props: {
            baseURL: '/reservationSchedule/daily',
            menuURL: '/reservationSchedule',
        },
    },
    /* 대여 현황 > 월 별 */
    {
        url: '/reservationSchedule/monthly',
        page: () =>
            import('page/reservationSchedule/ReservationScheduleMonthlyPage'),
        props: {
            baseURL: '/reservationSchedule/monthly',
            menuURL: '/reservationSchedule',
        },
    },
    /* 대여 내역 */
    {
        url: '/reservation',
        page: () => import('page/reservation/ReservationListPage'),
    },
    {
        url: '/reservation/new',
        page: () => import('page/reservation/ReservationDetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/reservation' },
    },
    {
        url: '/reservation/:ID/form',
        page: () => import('page/reservation/ReservationDetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/reservation' },
    },
    {
        url: '/reservation/:ID',
        page: () => import('page/reservation/ReservationDetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/reservation' },
    },
    /* 대여 내역 */
    {
        url: '/reservationhistory',
        page: () =>
            import('page/reservationHistory/ReservationHistoryListPage'),
    },
    {
        url: '/reservationhistory/:ID',
        page: () =>
            import('page/reservationHistory/ReservationHistoryDetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/reservationhistory' },
    },
    /* 구독 내역 */
    {
        url: '/subscribe',
        page: () => import('page/subscribe/ListPage'),
    },
    {
        url: '/subscribe/:subscribeID',
        page: () => import('page/subscribe/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/subscribe' },
    },
    /* 결제 내역 */
    {
        url: '/reservationPayment',
        page: () =>
            import('page/reservationPayment/ReservationPaymentListPage'),
    },
    {
        url: '/reservationPayment/:ID',
        page: () =>
            import('page/reservationPayment/ReservationPaymentDetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/reservationPayment' },
    },
    /* 결제 카드 */
    {
        url: '/card',
        page: () => import('page/card/CardListPage'),
    },
    {
        url: '/card/new',
        page: () => import('page/card/CardDetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/card' },
    },
    {
        url: '/card/:ID/form',
        page: () => import('page/card/CardDetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/card' },
    },
    {
        url: '/card/:ID',
        page: () => import('page/card/CardDetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/card' },
    },
    // TODO: 결제카드 토큰 생성 샘플 페이지(추후 삭제)
    {
        url: '/sampleCard',
        page: () => import('page/card/SampleCard'),
    },
    /* 하이패스 카드 */
    {
        url: '/highpassCard',
        page: () => import('page/hipass/ListPage'),
        props: { baseURL: '/highpassCard' },
    },
    {
        url: '/highpassCard/new',
        page: () => import('page/hipass/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/highpassCard' },
    },
    {
        url: '/highpassCard/:ID',
        page: () => import('page/hipass/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/highpassCard' },
    },
    // {
    //     url: '/highpassCard/:ID/form',
    //     page: () => import('page/hipass/DetailPage'),
    //     props: { mode: PAGE_MODE.EDIT, baseURL: '/highpassCard' },
    // },
    /* 충전제휴 카드 */
    {
        url: '/associateCard',
        page: () => import('page/associateCard/ListPage'),
        props: { baseURL: '/associateCard' },
    },
    {
        url: '/associateCard/new',
        page: () => import('page/associateCard/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/associateCard' },
    },
    {
        url: '/associateCard/:ID',
        page: () => import('page/associateCard/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/associateCard' },
    },
    // {
    // url: '/associateCard/:ID/form',
    // page: () => import('page/associateCard/DetailPage'),
    // props: { mode: PAGE_MODE.EDIT, baseURL: '/associateCard' },
    // },
    /* 회원사/계약 */
    {
        url: '/business',
        page: () => import('page/business/ListPage'),
    },
    {
        url: '/business/new',
        page: () => import('page/business/CreatePage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/business' },
    },
    {
        url: '/business/:ID',
        page: () => import('page/business/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/business' },
    },
    {
        url: '/business/admin/:ID',
        page: () => import('page/business/AdminDetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/business' },
    },
    {
        url: '/business/info/:ID',
        page: () => import('page/business/InfoDetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/business' },
    },
    {
        url: '/business/contract/:ID',
        page: () => import('page/business/ContractDetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/business' },
    },
    /* 계약정보 */
    {
        url: '/businessContract',
        page: () => import('page/businessContract/DetailPage'),
        props: { mode: PAGE_MODE.VIEW },
    },
    /* 기업 상담이력 */
    {
        url: '/customerService',
        page: () => import('page/customerService/ListPage'),
    },
    {
        url: '/customerService/:ID/form',
        page: () => import('page/customerService/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/customerService' },
    },
    {
        url: '/customerService/new',
        page: () => import('page/customerService/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/customerService' },
    },

    /* 회원 Tab*/
    {
        url: '/customerUserMgnt',
        page: () => import('page/TabGroupPage'),
        props: { baseURL: '/customerUser', menuURL: '/customerUserMgnt' },
    },
    /* 일반 회원 */
    {
        url: '/customerUser',
        page: () => import('page/customerUser/ListPage'),
        props: { baseURL: '/customerUser', menuURL: '/customerUserMgnt' },
    },
    {
        url: '/customerUser/:ID/form',
        page: () => import('page/customerUser/DetailPage'),
        props: {
            mode: PAGE_MODE.EDIT,
            baseURL: '/customerUser',
            menuURL: '/customerUserMgnt',
        },
    },
    {
        url: '/customerUser/:ID',
        page: () => import('page/customerUser/DetailPage'),
        props: {
            mode: PAGE_MODE.VIEW,
            baseURL: '/customerUser',
            menuURL: '/customerUserMgnt',
        },
    },
    /* 탈퇴 회원 */
    {
        url: '/withdrawalUser',
        page: () => import('page/withdrawalUser/ListPage'),
        props: { baseURL: '/withdrawalUser', menuURL: '/customerUserMgnt' },
    },
    {
        url: '/withdrawalUser/:ID',
        page: () => import('page/withdrawalUser/DetailPage'),
        props: {
            mode: PAGE_MODE.VIEW,
            baseURL: '/withdrawalUser',
            menuURL: '/customerUserMgnt',
        },
    },
    /* 임직원 조회 */
    {
        url: '/businessMember',
        page: () => import('page/businessMember/ListPage'),
    },
    {
        url: '/businessMember/:ID/form',
        page: () => import('page/businessMember/DetailPage'),
        props: {
            mode: PAGE_MODE.EDIT,
            baseURL: '/businessMember',
        },
    },
    {
        url: '/businessMember/:ID',
        page: () => import('page/businessMember/DetailPage'),
        props: {
            mode: PAGE_MODE.VIEW,
            baseURL: '/businessMember',
        },
    },
    /* 임직원 등록 관리 Tab */
    {
        url: '/businessInviteMgnt',
        page: () => import('page/TabGroupPage'),
        props: {
            baseURL: '/businessInvite/new',
            menuURL: '/businessInviteMgnt',
        },
    },
    /* 임직원 등록 */
    {
        url: '/businessInvite/new',
        page: () => import('page/businessInvite/CreatePage'),
        props: {
            baseURL: '/businessInvite/new',
            menuURL: '/businessInviteMgnt',
        },
    },
    /* 임직원 등록현황 */
    {
        url: '/businessInvite',
        page: () => import('page/businessInvite/ListPage'),
        props: {
            baseURL: '/businessInvite',
            menuURL: '/businessInviteMgnt',
        },
    },
    /* 부서 */
    {
        url: '/department',
        page: () => import('page/department/ListPage'),
    },
    {
        url: '/department/new',
        page: () => import('page/department/CreatePage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/department' },
    },
    {
        url: '/department/:ID',
        page: () => import('page/department/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/department' },
    },
    /* 회원사 권한 */
    {
        url: '/businessOperator',
        page: () => import('page/businessOperator/ListPage'),
    },
    {
        url: '/businessOperator/:ID',
        page: () => import('page/businessOperator/DetailPage'),
        props: { baseURL: '/businessOperator' },
    },
    /* 운전면허 검증이력 */
    {
        url: '/license/check',
        page: () => import('page/licenseCheck/ListPage'),
    },
    {
        url: '/license/check/:ID',
        page: () => import('page/licenseCheck/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/license/check' },
    },
    /* 페널티 관리 Tab */
    {
        url: '/penaltyMgnt',
        page: () => import('page/TabGroupPage'),
        props: { baseURL: '/penaltyMgnt', menuURL: '/penaltyMgnt' },
    },
    /* 페널티 */
    {
        url: '/penalty/user',
        page: () => import('page/penalty/ListPage'),
        props: { baseURL: '/penalty/user', menuURL: '/penaltyMgnt' },
    },
    {
        url: '/penalty/user/new',
        page: () => import('page/penalty/components/penalty/PenaltyDetail'),
        props: {
            mode: PAGE_MODE.CREATE,
            baseURL: '/penalty/user',
            menuURL: '/penaltyMgnt',
        },
    },
    // {
    //     url: '/penalty/user/:ID/form',
    //     page: () => import('page/penalty/components/penalty/PenaltyDetail'),
    //     props: {
    //         mode: PAGE_MODE.EDIT,
    //         baseURL: '/penalty/user',
    //         menuURL: '/penaltyMgnt',
    //     },
    // },
    {
        url: '/penalty/user/:ID',
        page: () => import('page/penalty/components/penalty/PenaltyDetail'),
        props: {
            mode: PAGE_MODE.VIEW,
            baseURL: '/penalty/user',
            menuURL: '/penaltyMgnt',
        },
    },
    {
        url: '/penalty/user/:ID/form',
        page: () => import('page/penalty/components/penalty/PenaltyDetail'),
        props: {
            mode: PAGE_MODE.EDIT,
            baseURL: '/penalty/user',
            menuURL: '/penaltyMgnt',
        },
    },
    /* 페널티 정책 */
    {
        url: '/penalty',
        page: () => import('page/penalty/PolicyListPage'),
        props: { baseURL: '/penalty', menuURL: '/penaltyMgnt' },
    },
    {
        url: '/penalty/new',
        page: () =>
            import('page/penalty/components/penaltyPolicy/PenaltyPolicyDetail'),
        props: {
            mode: PAGE_MODE.CREATE,
            baseURL: '/penalty',
            menuURL: '/penaltyMgnt',
        },
    },
    {
        url: '/penalty/:ID/form',
        page: () =>
            import('page/penalty/components/penaltyPolicy/PenaltyPolicyDetail'),
        props: {
            mode: PAGE_MODE.EDIT,
            baseURL: '/penalty',
            menuURL: '/penaltyMgnt',
        },
    },
    {
        url: '/penalty/:ID',
        page: () =>
            import('page/penalty/components/penaltyPolicy/PenaltyPolicyDetail'),
        props: {
            mode: PAGE_MODE.VIEW,
            baseURL: '/penalty',
            menuURL: '/penaltyMgnt',
        },
    },
    /* 운전면허 조회 */
    {
        url: '/licenseInfo',
        page: () => import('page/licenseInfo/ListPage'),
    },
    {
        url: '/licenseInfo/:ID',
        page: () => import('page/licenseInfo/DetailPage'),
        props: { mode: PAGE_MODE.VIEW,  baseURL: '/licenseInfo' },
    },
    /* 매출 */
    {
        url: '/sales',
        page: () => import('page/salesMgmt/ListPage'),
    },
    {
        url: '/sales/new',
        page: () => import('page/salesMgmt/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/sales' },
    },
    {
        url: '/sales/:ID',
        page: () => import('page/salesMgmt/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/sales' },
    },
    /* 쿠폰 매출 */
    {
        url: '/couponSales',
        page: () => import('page/couponSales/ListPage'),
    },
    {
        url: '/couponSales/:ID',
        page: () => import('page/couponSales/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/couponSales' },
    },
    /* 정산 */
    {
        url: '/calculation',
        page: () => import('page/calculation/ListPage'),
    },
    {
        url: '/calculation/new',
        page: () => import('page/calculation/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/calculation' },
    },
    {
        url: '/calculation/:ID/form',
        page: () => import('page/calculation/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/calculation' },
    },
    {
        url: '/calculation/:ID',
        page: () => import('page/calculation/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/calculation' },
    },
    /* 쿠폰 */
    {
        url: '/coupon',
        page: () => import('page/coupon/ListPage'),
        props: { baseURL: '/coupon' },
    },
    {
        url: '/coupon/new',
        page: () => import('page/coupon/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/coupon' },
    },
    {
        url: '/coupon/:ID/form',
        page: () => import('page/coupon/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/coupon' },
    },
    {
        url: '/coupon/:ID',
        page: () => import('page/coupon/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/coupon' },
    },
    /* 미수금 */
    {
        url: '/receivable',
        page: () => import('page/receivable/ListPage'),
        props: { baseURL: '/receivable' },
    },
    {
        url: '/receivable/new',
        page: () => import('page/receivable/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/receivable' },
    },
    {
        url: '/receivable/:ID',
        page: () => import('page/receivable/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/receivable' },
    },
    /* 과태료 */
    {
        url: '/violation',
        page: () => import('page/violation/ListPage'),
        props: { baseURL: '/violation' },
    },
    {
        url: '/violation/new',
        page: () => import('page/violation/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/violation' },
    },
    {
        url: '/violation/:ID/form',
        page: () => import('page/violation/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/violation' },
    },
    {
        url: '/violation/:ID',
        page: () => import('page/violation/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/violation' },
    },
    /* 요금제 */
    {
        url: '/charge',
        page: () => import('page/charge/ListPage'),
        props: { baseURL: '/charge' },
    },
    {
        url: '/charge/new',
        page: () => import('page/charge/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/charge' },
    },
    {
        url: '/charge/:ID/form',
        page: () => import('page/charge/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/charge' },
    },
    {
        url: '/charge/:ID',
        page: () => import('page/charge/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/charge' },
    },
    /* 보험료 */
    {
        url: '/chargeInsurance',
        page: () => import('page/insurance/ListPage'),
        props: { baseURL: '/chargeInsurance' },
    },
    {
        url: '/chargeInsurance/new',
        page: () => import('page/insurance/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/chargeInsurance' },
    },
    // 대여가 시작된 보험료는 수정할 수 없습니다. 2022.01.14 by 김연혜
    // {
    //     url: '/chargeInsurance/:ID/form',
    //     page: () => import('page/insurance/DetailPage'),
    //     props: { mode: PAGE_MODE.EDIT, baseURL: '/chargeInsurance' },
    // },
    {
        url: '/chargeInsurance/:ID',
        page: () => import('page/insurance/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/chargeInsurance' },
    },
    /* 부가상품 */
    {
        url: '/extraProduct',
        page: () => import('page/extraProduct/ListPage'),
        props: { baseURL: '/extraProduct' },
    },
    {
        url: '/extraProduct/new',
        page: () => import('page/extraProduct/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/extraProduct' },
    },
    {
        url: '/extraProduct/:ID/form',
        page: () => import('page/extraProduct/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/extraProduct' },
    },
    {
        url: '/extraProduct/:ID',
        page: () => import('page/extraProduct/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/extraProduct' },
    },
    /* 공지/이벤트 */
    {
        url: '/notice',
        page: () => import('page/notice/ListPage'),
    },
    {
        url: '/notice/:ID/form',
        page: () => import('page/notice/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/notice' },
    },
    {
        url: '/notice/new',
        page: () => import('page/notice/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/notice' },
    },
    /* 위블존 이용안내 */
    {
        url: '/guide/location',
        page: () => import('page/guideLocation/ListPage'),
    },
    {
        url: '/guide/location/new',
        page: () => import('page/guideLocation/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/guide/location' },
    },
    {
        url: '/guide/location/:ID/form',
        page: () => import('page/guideLocation/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/guide/location' },
    },
    {
        url: '/guide/location/:ID',
        page: () => import('page/guideLocation/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/guide/location' },
    },
    /* 차종별 이용안내 */
    {
        url: '/modelInfo',
        page: () => import('page/modelInfo/ListPage'),
    },
    {
        url: '/modelInfo/new',
        page: () => import('page/modelInfo/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/modelInfo' },
    },
    {
        url: '/modelInfo/:ID/form',
        page: () => import('page/modelInfo/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/modelInfo' },
    },
    /* 배너/스플래시/팝업 */
    {
        url: '/bannerMgmt',
        page: () => import('page/bannerMgmt/ListPage'),
    },
    {
        url: '/bannerMgmt/new',
        page: () => import('page/bannerMgmt/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/bannerMgmt' },
    },
    {
        url: '/bannerMgmt/:ID/form',
        page: () => import('page/bannerMgmt/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/bannerMgmt' },
    },
    /* 알림 템플릿 */
    {
        url: '/msgTemplate',
        page: () => import('page/msgTemplate/ListPage'),
        props: { baseURL: '/msgTemplate' },
    },
    {
        url: '/msgTemplate/new',
        page: () => import('page/msgTemplate/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/msgTemplate' },
    },
    {
        url: '/msgTemplate/:ID/form',
        page: () => import('page/msgTemplate/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/msgTemplate' },
    },
    {
        url: '/msgTemplate/:ID',
        page: () => import('page/msgTemplate/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/msgTemplate' },
    },
    /** s -------------- 알림/메시지 발송 -------------- s */
    {
        url: '/messageMgnt',
        page: () => import('page/TabGroupPage'),
        props: { baseURL: '/message', menuURL: '/messageMgnt' },
    },
    /* 알림발송이력 */
    {
        url: '/message',
        page: () => import('page/historyMsgTemplate/ListPage'),
        props: { baseURL: '/message', menuURL: '/messageMgnt' },
    },
    {
        url: '/message/:ID',
        page: () => import('page/historyMsgTemplate/DetailPage'),
        props: { baseURL: '/message', menuURL: '/messageMgnt' },
    },
    /* 수동메시지 관리 */
    {
        url: '/passiveMessage',
        page: () => import('page/passiveMessage/ListPage'),
        props: { baseURL: '/passiveMessage', menuURL: '/messageMgnt' },
    },
    {
        url: '/passiveMessage/new',
        page: () => import('page/passiveMessage/DetailPage'),
        props: {
            mode: PAGE_MODE.CREATE,
            baseURL: '/passiveMessage',
            menuURL: '/messageMgnt',
        },
    },
    {
        url: '/passiveMessage/:ID',
        page: () => import('page/passiveMessage/DetailPage'),
        props: {
            mode: PAGE_MODE.VIEW,
            baseURL: '/passiveMessage',
            menuURL: '/messageMgnt',
        },
    },
    /** e -------------- 알림/메시지 발송 -------------- e */
    /* 약관관리 */
    {
        url: '/terms',
        page: () => import('page/terms/ListPage'),
        props: { baseURL: '/terms' },
    },
    {
        url: '/terms/new',
        page: () => import('page/terms/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/terms' },
    },
    {
        url: '/terms/:ID/form',
        page: () => import('page/terms/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/terms' },
    },
    {
        url: '/terms/:ID',
        page: () => import('page/terms/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/terms' },
    },
    /* 버전 */
    {
        url: '/appVersion',
        page: () => import('page/appVersion/ListPage'),
        props: { baseURL: '/appVersion' },
    },
    {
        url: '/appVersion/new',
        page: () => import('page/appVersion/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/appVersion' },
    },
    {
        url: '/appVersion/:ID/form',
        page: () => import('page/appVersion/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/appVersion' },
    },
    /* 어드민 */
    {
        url: '/operator',
        page: () => import('page/operator/ListPage'),
    },
    {
        url: '/operator/:ID',
        page: () => import('page/operator/DetailPage'),
        props: { baseURL: '/operator' },
    },
    /* 위탁사 */
    {
        url: '/businessPartner',
        page: () => import('page/businessPartner/ListPage'),
    },
    {
        url: '/businessPartner/new',
        page: () => import('page/businessPartner/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/businessPartner' },
    },
    {
        url: '/businessPartner/:ID',
        page: () => import('page/businessPartner/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/businessPartner' },
    },
    {
        url: '/businessPartner/:ID/form',
        page: () => import('page/businessPartner/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/businessPartner' },
    },
    /* Greener 정책 */
    {
        url: '/greenerPolicy',
        page: () => import('page/greenerPolicy/DetailPage'),
    },
    /* 고객센터 공지 */
    {
        url: '/csCenterNotice',
        page: () => import('page/csCenterNotice/ListPage'),
    },
    {
        url: '/csCenterNotice/new',
        page: () => import('page/csCenterNotice/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/csCenterNotice' },
    },
    {
        url: '/csCenterNotice/:ID/form',
        page: () => import('page/csCenterNotice/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/csCenterNotice' },
    },
    {
        url: '/csCenterNotice/:ID',
        page: () => import('page/csCenterNotice/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/csCenterNotice' },
    },
    // {
    //     url: '/csCenterNoticeMarketing/new',
    //     page: () => import('page/csCenterNotice/MarketingDetailPage'),
    //     props: { mode: PAGE_MODE.CREATE, baseURL: '/csCenterNoticeMarketing' },
    // },
    // {
    //     url: '/csCenterNoticeMarketing/:ID/form',
    //     page: () => import('page/csCenterNotice/MarketingDetailPage'),
    //     props: { mode: PAGE_MODE.EDIT, baseURL: '/csCenterNoticeMarketing' },
    // },
    // {
    //     url: '/csCenterNoticeMarketing/:ID',
    //     page: () => import('page/csCenterNotice/DetailPage'),
    //     props: { mode: PAGE_MODE.VIEW, baseURL: '/csCenterNotice' },
    // },
    /* 1:1상담 */
    {
        url: '/vocMgmt',
        page: () => import('page/vocMgmt/ListPage'),
    },
    {
        url: '/vocMgmt/new',
        page: () => import('page/vocMgmt/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/vocMgmt' },
    },
    {
        url: '/vocMgmt/:ID/form',
        page: () => import('page/vocMgmt/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/vocMgmt' },
    },
    /* FAQ */
    {
        url: '/faqMgmt',
        page: () => import('page/faqMgmt/ListPage'),
    },
    {
        url: '/faqMgmt/new',
        page: () => import('page/faqMgmt/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/faqMgmt' },
    },
    {
        url: '/faqMgmt/:ID/form',
        page: () => import('page/faqMgmt/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/faqMgmt' },
    },
    {
        url: '/faqMgmt/:ID',
        page: () => import('page/faqMgmt/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/faqMgmt' },
    },
    /* 담당자 문의 */
    {
        url: '/contactManager',
        page: () => import('page/contactManager/ListPage'),
    },
    /* 권한 */
    {
        url: '/role',
        page: () => import('page/role/RoleBasePage'),
    },
    /* 코드 */
    {
        url: '/code',
        page: () => import('page/code/CodeBasePage'),
    },
    /* 메뉴 */
    {
        url: '/menu',
        page: () => import('page/menu/MenuBasePage'),
    },
    /* 프로그램 */
    {
        url: '/program',
        page: () => import('page/program/ListPage'),
    },
    /* 배치 > 배치 실행 이력 */
    {
        url: '/jobHist',
        page: () => import('page/jobHist/ListPage'),
        props: { mode: PAGE_MODE.VIEW },
    },
    /* 배치 > 배치 */
    {
        url: '/job/:ID',
        page: () => import('page/job/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/job' },
    },
    {
        url: '/job',
        page: () => import('page/job/ListPage'),
        props: { mode: PAGE_MODE.VIEW },
    },
    /* 공급사등록/조회 */
    {
        url: '/provider',
        page: () => import('page/provider/ListPage'),
    },
    {
        url: '/provider/new',
        page: () => import('page/provider/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/provider' },
    },
    {
        url: '/provider/:ID',
        page: () => import('page/provider/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/provider' },
    },
    {
        url: '/provider/:ID/form',
        page: () => import('page/provider/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/provider' },
    },
    /* 내 정보 */
    {
        url: '/myInfo',
        page: () => import('page/myInfo/ListPage'),
    },
    /* 대시보드 */
    {
        url: '/dashboardBusiness',
        page: () => import('page/dashboardBusiness/DashboardBasePage'),
        // props: { mode: PAGE_MODE.VIEW },
    },
    {
        url: '/signOut',
        page: () => import('page/unauthed/SignOut'),
    },
    /* 공통 */
    {
        url: '/openWindow',
        page: () => import('page/OpenWindow'),
    },
    {
        url: '/reload',
        page: () => import('page/ReloadPage'),
    },
];

const UnauthedRoutes = [
    {
        url: '/redirect',
        page: () => import('page/unauthed/RedirectPage'),
    },
    {
        url: '/user/redirect',
        page: () => import('page/unauthed/RedirectPage'),
    },
    {
        url: '/loginAfter/agreementPage',
        page: () => import('page/unauthed/AgreementPage'),
    },
    {
        url: '/loginAfter/messageForwardPage',
        page: () => import('page/unauthed/MessageForwardPage'),
    },
    {
        url: '/signOut',
        page: () => import('page/unauthed/SignOut'),
    },
    // 일본
    {
        url: '/jp/redirect',
        page: () => import('country/jp/page/unauthed/RedirectPage'),
    },
    {
        url: '/jp/user/redirect',
        page: () => import('country/jp/page/unauthed/RedirectPage'),
    },
    {
        url: '/jp/jam/redirect',
        page: () => import('country/jp/page/unauthed/JamRedirectPage'),
    },
    {
        url: '/jp/loginAfter/agreementPage',
        page: () => import('page/unauthed/AgreementPage'),
    },
];

const findMenuUrl = (pathname) => {
    const selectedRoutes = AuthedRoutes.filter((item) => {
        const mPath = matchPath(pathname, { path: item?.url, exact: true });
        return !!mPath;
    });
    if (selectedRoutes?.length)
        return (
            selectedRoutes[0]?.props?.menuURL ||
            selectedRoutes[0]?.props?.baseURL ||
            selectedRoutes[0]?.url
        );
    return pathname;
};

export { AuthedRoutes, UnauthedRoutes, findMenuUrl };
