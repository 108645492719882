import { Suspense, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { If, Then } from 'react-if';

import { Text, Button, Form, TextOrSelect, useAppRoot } from 'component/mux';
import { UnauthedRoutes } from 'config/routes';
import { DynamicRoute } from 'util/DynamicRoute';
import { INPUT_WIDTH_CLASS, TERMS } from 'util/pageUtil';
import PrivacyPolicy from 'component/dialogComponent/TermsDialog/adminNoAuth/PrivacyPolicy';
import { UnAuthedContent } from 'country/jp/component/UnAuthedContent';
import { UnAuthedLocal } from 'country/jp/component/UnAuthedLocal';

const MaingContent = ({ location }) => {
    const [dispTermsUse, setDispTermsUse] = useState(false);
    const { changeLocale, language } = useAppRoot();
    const [dispPrivacyPolicy, setDispPrivacyPolicy] = useState(false);
    const isAdmin = location?.pathname === '/admin';

    const onTermsUse = () => {
        setDispTermsUse((prev) => {
            return !prev;
        });
    };

    const onPrivacyPolicy = () => {
        setDispPrivacyPolicy((prev) => {
            return !prev;
        });
    };
    return (
        <>
            <div id="wrap" className="wbLogin">
                <div className="wbLoginContent">
                    <div className="contentTop">
                        <img
                            src={`${process.env.PUBLIC_URL}/image/login/login_logo.png`}
                            alt="logo"
                        />
                        {'local' === process.env.REACT_APP_MODE ? (
                            <>
                                <UnAuthedLocal />
                            </>
                        ) : (
                            <>
                                <UnAuthedContent
                                    language={language}
                                    isAdmin={isAdmin}
                                />
                            </>
                        )}
                    </div>
                    <div className="contentFooter">
                        <ul>
                            <If condition={isAdmin}>
                                <Then>
                                    <li>
                                        <Button
                                            onClick={onTermsUse}
                                            className="menuItemBold"
                                            resId="Common.Footer.Policy1.JP"
                                        />
                                    </li>
                                </Then>
                            </If>
                            <li>
                                <Button
                                    onClick={onPrivacyPolicy}
                                    className="menuItemBold"
                                    resId="Common.Footer.Policy2.JP"
                                />
                            </li>
                            <li>
                                {/* [일본] 비로그인 페이지이기 때문에 언어변경 하드코딩 */}
                                <Form>
                                    <TextOrSelect
                                        name="changeLocale"
                                        option={[
                                            { label: '日本語', value: 'ja' },
                                            { label: '한국어', value: 'ko' },
                                        ]}
                                        value={language}
                                        onValueChange={(res) => {
                                            if (changeLocale)
                                                changeLocale(
                                                    res?.data?.changeLocale,
                                                );
                                        }}
                                        className={INPUT_WIDTH_CLASS.W80}
                                    />
                                </Form>
                            </li>
                        </ul>
                        <Text
                            resId="Common.Footer.JP.Address1"
                            className="address"
                        />
                        <Text
                            resId="Common.Footer.JP.Address2"
                            className="address"
                        />
                        <Text
                            resId="Common.Footer.JP.Address4"
                            className="address"
                        />
                        <Text
                            resId="Common.Footer.JP.Address5"
                            className="address"
                        />
                    </div>
                </div>
            </div>
            {/* 이용약관 */}
            <PrivacyPolicy
                key="TermsUse"
                visible={dispTermsUse}
                handleClose={onTermsUse}
                applyDomain={isAdmin ? TERMS.ADMIN : TERMS.BIZ}
                titleResId="Common.StartAdmin.TermsUse.Title"
                transData={{
                    termsGroupIDs: 'GROUP_WIBLE_BIZ_APP',
                    termsTypes: 'Terms.Type.Join',
                    termsClassIDs: 'JOIN_USER',
                    useYn: 'Y',
                    activeDateYn: 'Y',
                    nationality: language === 'ko' ? 'KOR' : 'JPN',
                }}
            />
            {/* 개인정보 처리 방침 ! */}
            <PrivacyPolicy
                visible={dispPrivacyPolicy}
                handleClose={onPrivacyPolicy}
                applyDomain={isAdmin ? TERMS.ADMIN : TERMS.BIZ}
                titleResId="Common.StartAdmin.PrivacyPolicy.Title"
                transData={{
                    termsGroupIDs: TERMS.ADMIN,
                    termsTypes: 'Terms.Type.StartAdmin',
                    termsClassIDs: 'STARTADMIN_ADMIN_PRIVACY',
                    useYn: 'Y',
                    activeDateYn: 'Y',
                    nationality: language === 'ko' ? 'KOR' : 'JPN',
                }}
            />
        </>
    );
};

const UnAuthedApp = ({ onRanderHandle }) => {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route exact path="/" component={MaingContent}></Route>
                    <Route exact path="/admin" component={MaingContent}></Route>
                    <DynamicRoute
                        routes={UnauthedRoutes}
                        onRanderHandle={onRanderHandle}
                    ></DynamicRoute>
                </Switch>
            </Suspense>
        </>
    );
};

export { UnAuthedApp };
