import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { Text, DefaultTable as MuxDefaultTable } from 'mux';
import _ from 'lodash';

const Count = ({ showCount, total }) => {
    return (
        <>
            {showCount && total > 0 ? (
                <Text>
                    <FormattedMessage
                        id="Common.Unit.Case.Count.Value"
                        values={{
                            value: total,
                        }}
                    />
                </Text>
            ) : null}
        </>
    );
};

const DefaultTable = (props) => {
    const {
        columns,
        dataSource,
        pagination,
        showIndex,
        showIndexIncrement = false,
        checkable,
        selectedRowsKeys,
        defaultSelectedRowKeys,
        selType,
        onSelect,
        getCheckboxProps,
        showCount,
        ...rest
    } = props;

    const intl = useIntl();
    const [selectedRowKeys, setSelectedRowKeys] = useState(selectedRowsKeys);

    const indexColumn = showIndex
        ? showIndexIncrement
            ? {
                  title: <FormattedMessage id="Common.Column.No" />,
                  width: 60,
                  render: (text, record, index) => {
                      const number = index + 1;
                      return <div>{number}</div>;
                  },
              }
            : {
                  title: <FormattedMessage id="Common.Column.No" />,
                  width: 60,
                  render: (text, record, index) => {
                      const number =
                          pagination.total -
                          (pagination.current - 1) * pagination.pageSize -
                          index;
                      if (_.isNaN(number)) {
                          return '';
                      }
                      return <div>{number}</div>;
                  },
              }
        : {};

    const mergedColumns = useMemo(() => {
        return _.map(columns, (column) => {
            if (!_.isEmpty(column.resId)) {
                column.title = <FormattedMessage id={column.resId} />;
            }

            const children = column.children;
            if (!_.isEmpty(children) && _.size(children) > 0) {
                const nChildren = _.reduce(
                    children,
                    (result, cur) => {
                        cur.title = intl.formatMessage({ id: cur.resId });
                        result.push(cur);
                        return result;
                    },
                    [],
                );
                column.children = nChildren;
            }

            return column;
        });
    }, [columns, intl]);

    useEffect(() => {
        if (_.isUndefined(selectedRowsKeys) || _.isEmpty(selectedRowsKeys)) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys(selectedRowsKeys);
        }
    }, [selectedRowsKeys]);

    const handleChangeRow = useCallback((selectedRowKeys, selectedRows) => {
        if (_.isFunction(onSelect)) {
            onSelect(selectedRowKeys, selectedRows);
        }

        setSelectedRowKeys(selectedRowKeys);
    }, []);

    const rowSelection = useMemo(() => {
        if (!checkable) return undefined;

        return {
            selectedRowKeys: selectedRowKeys,
            defaultSelectedRowKeys: defaultSelectedRowKeys,
            type: selType,
            onChange: handleChangeRow,
            getCheckboxProps,
        };
    }, [
        checkable,
        selectedRowKeys,
        selType,
        handleChangeRow,
        getCheckboxProps,
    ]);

    return (
        <MuxDefaultTable
            columns={
                _.isEmpty(indexColumn)
                    ? [...mergedColumns]
                    : [indexColumn, ...mergedColumns]
            }
            dataSource={dataSource}
            pagination={{ ...pagination, showSizeChanger: false }}
            rowSelection={rowSelection}
            countComponent={
                <Count showCount={showCount} total={pagination?.total || 0} />
            }
            {...rest}
        />
    );
};

DefaultTable.propTypes = {
    selType: PropTypes.oneOf(['checkbox', 'radio']),
};

DefaultTable.defaultProps = {
    rowKey: 'ID',
    showIndex: false,
    checkable: true,
    showCount: false,
    selType: 'checkbox',
    tableLayout: 'fixed',
    // scroll: {
    //     x: '100%',
    // },
    // scroll 속성 추가시 테이블에 가상의 tr이 생겨 사용하지않음(CSS처리함)
};

export default DefaultTable;
