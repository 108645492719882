import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useIntl } from 'react-intl';

import { TextOrDatePicker as MuxTextOrDatePicker } from 'mux';

const TextOrDateTimePicker = forwardRef((props, ref) => {
    const {
        timeFormat,
        disable,
        placeholder,
        placeholderId,
        required,
        validate,
        timeProps,
        ...rest
    } = props;

    const intl = useIntl();

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [intl, required, validate]);

    return (
        <MuxTextOrDatePicker
            {...rest}
            {...rules}
            disabled={disable}
            placeholder={
                placeholderId
                    ? intl.formatMessage({ id: placeholderId })
                    : placeholder
            }
            showTime={{ format: timeFormat, ...timeProps }}
            ref={ref}
        />
    );
});

TextOrDateTimePicker.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    disable: PropTypes.bool,
    placeholder: PropTypes.string,
    placeholderId: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    format: PropTypes.string,
    timeFormat: PropTypes.string,
    timeProps: PropTypes.object,
    naviShow: PropTypes.bool.isRequired,
    inputReadOnly: PropTypes.bool,
    showToday: PropTypes.bool,
    showNow: PropTypes.bool,
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    picker: PropTypes.oneOf(['date', 'week', 'month', 'quarter', 'year']),
    exportFormat: PropTypes.oneOf(['longtime', 'moment', PropTypes.string]),
};

TextOrDateTimePicker.defaultProps = {
    format: 'YYYY-MM-DD HH:mm:ss',
    timeFormat: 'HH:mm:ss',
    required: false,
    disable: false,
    inputReadOnly: true,
    showToday: false,
    showNow: false,
    editable: true,
    naviShow: false,
    picker: 'date',
    exportFormat: 'longtime',
};

export default TextOrDateTimePicker;
