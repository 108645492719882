import { useIntl } from 'react-intl';
import { withRouter, useHistory, useLocation } from 'react-router';
import _ from 'lodash';

import { useTransaction, useAppRoot } from 'component/mux';
import { default as mockups } from 'config/mockup';
import { MEMBER_ROLE, excludePath } from 'util/authentication';
import {useEffect} from "react";
import {pushPageview} from "./tagmanagerUtil";

const Initialize = withRouter(() => {
    const { messageBox, session, signOut } = useAppRoot();
    const { ID, role } = session || {};
    const { initialize } = useTransaction();
    const history = useHistory();
    const location  = useLocation();
    // GTM 페이지 트래킹 정보 수집
    useEffect(() => {
        const { pathname, search } = location;
        const currentPath = `${pathname}${search}`;
        if (currentPath) pushPageview(currentPath, ID, role);
    }, [location]);
    const intl = useIntl();

    const transmissionErrorHandler = (response) => {
        if (response?.code === 'ECONNABORTED') {
            messageBox(
                intl.formatMessage({
                    id: 'Message.Alert.Timeout',
                    defaultMessage: 'Message.Alert.Timeout',
                }),
            );
            return true;
        }
        const { status } = response || 0;
        switch (status) {
            case 500: {
                messageBox(
                    intl.formatMessage({
                        id: 'Message.Action.Fail',
                        defaultMessage: 'Message.Action.Fail',
                    }),
                );
                return true;
            }
            case 401: {
                // 세션 만료 알림 안내
                const sessionExpired = !!ID;
                if (!_.includes(excludePath, window.location.pathname)) {
                    if (sessionExpired) {
                        signOut();
                        if (MEMBER_ROLE.includes(role)) {
                            history.push({ pathname: '/' });
                        } else {
                            history.push({ pathname: '/admin' });
                        }
                        messageBox(
                            intl.formatMessage({
                                id: 'Message.Auth.Alert.Expired',
                                defaultMessage: 'Message.Auth.Alert.Expired',
                            }),
                        );
                        return true;
                    }
                }
                return;
            }
            case 403:
                messageBox(
                    intl.formatMessage({
                        id: 'Message.Auth.Alert.Forbidden',
                        defaultMessage: 'Message.Auth.Alert.Forbidden',
                    }),
                );
                return true;
            case 404:
                messageBox(
                    intl.formatMessage({
                        id: 'Message.Failure.Download',
                        defaultMessage: 'Message.Failure.Download',
                    }),
                );
                return true;
            default:
                break;
        }
    };

    let allowedServices = [];
    if (process.env.REACT_APP_MOCKUP_MATCH) {
        allowedServices = _.merge(
            [],
            JSON.parse(process.env.REACT_APP_MOCKUP_MATCH || '{}'),
        );
    }

    initialize({
        request: {
            baseURL: process.env.REACT_APP_API_ENDPOINT,
            timeout: 40000,
        },
        mockup: { store: mockups, allowedServices: allowedServices },
        transmission: { errorHandler: transmissionErrorHandler },
    });

    return <></>;
});

export { Initialize };
