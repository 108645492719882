/**
 * global.mop.user.lib/src/component/form/TextOrDatePicker.js
 * 복제된 사유: DEVOPS 개발자 환경에서 nexus를 통한 npm install 이 안되기 때문에 mux 업데이트 대신 소스 복제를 통해 수정함.
 */
import React, {
    forwardRef,
    useState,
    useMemo,
    useCallback,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { DatePicker } from 'antd';
import { withProperty } from 'mux'; // '../withBaseOption';
import {
    isDisabledDate,
    getExportValue,
    BE_DATE_UINT_MAX,
    BE_DATE_UINT_MIN,
} from './util/date-picker'; // '../../util/date-picker';

function parseDateProps(value, format) {
    const date = value ? moment(value) : '';
    const dateString = value ? moment(value).format(format) : '';

    return [date, dateString];
}

const BaseTextOrDatePicker = forwardRef((props, ref) => {
    const { editable, dateString, ...rest } = props;
    // Text 일 경우
    if (!editable) {
        return <div {...rest}>{dateString}</div>;
    }
    return <DatePicker {...rest} ref={ref} />;
});

const MuxBaseTextOrDatePicker = forwardRef((props, ref) => {
    const {
        className,
        name,
        defaultValue,
        value,
        required,
        validate,
        disabled,
        format,
        disabledDate,
        showTime,
        picker,
        onChangeValue,
        naviShow,
        exportFormat,
        ...rest
    } = props;

    const {
        register,
        setValue,
        getValues,
        trigger,
        watch,
        formState: { dirtyFields, isSubmitted, errors },
    } = useFormContext();

    // form 등록
    register(name, { required, validate });

    const [date, setDate] = useState();
    const [dateString, setDateString] = useState();

    // 날짜 선택
    const handleChange = useCallback((date, dateString) => {
        // 00 시 00 분 00초 로 설정
        if (!_.isEmpty(date) && !showTime) date?.startOf('day');

        setDate((prev) => {
            if (_.isEqual(prev, date)) return prev;
            return date;
        });

        setDateString((prev) => {
            if (_.isEqual(prev, dateString)) return prev;
            return dateString;
        });

        setValue(name, getExportValue(date, exportFormat), {
            shouldDirty: date,
        });

        if (_.isFunction(onChangeValue)) {
            onChangeValue(getValues(name));
        }
    }, []);

    // 초기값
    useEffect(() => {
        if (!dirtyFields[name]) {
            if (value) {
                handleChange(...parseDateProps(value, format));
            } else {
                handleChange(null, '');
            }
        }
    }, [dirtyFields[name], value]);

    // setValue 로 값 변경시
    const watchValue = watch(name);
    useEffect(() => {
        if (dirtyFields[name]) {
            handleChange(...parseDateProps(watchValue, format));
        }
    }, [watchValue]);

    // 유효성 검사
    useEffect(() => {
        if (
            (!_.isUndefined(required) || !_.isUndefined(validate)) &&
            isSubmitted
        ) {
            trigger(name);
        }
    }, [date]);

    const handleClickNavi = (step) => {
        if (_.isEmpty(date)) {
            return;
        }

        const chunkDate = date.clone();
        switch (picker) {
            case 'week':
                chunkDate.add(step, 'w');
                break;
            case 'month':
                chunkDate.add(step, 'M');
                break;
            case 'quarter':
                chunkDate.add(step, 'Q');
                break;
            case 'year':
                chunkDate.add(step, 'y');
                break;
            default:
                chunkDate.add(step, 'd');
        }

        // disable date일땐 navi를 멈춘다.
        if (!handleDisabledDate(chunkDate)) {
            handleChange(chunkDate, chunkDate.format(format));
        }
    };

    const handleDisabledDate = (current) => {
        if (isDisabledDate(current, BE_DATE_UINT_MAX, 'post')) return true;
        if (isDisabledDate(current, BE_DATE_UINT_MIN, 'before')) return true;

        if (_.isFunction(disabledDate)) {
            return disabledDate(current);
        }

        if (_.isObject(disabledDate)) {
            return isDisabledDate(current, ...Object.values(disabledDate));
        }

        return false;
    };

    // minute, second step 설정시 시간 표시
    const customShowTime = useMemo(() => {
        if (
            _.isObject(showTime) &&
            (_.has(showTime, 'minuteStep') || _.has(showTime, 'secondStep'))
        ) {
            const now = moment();
            const defaultValue = now.clone();

            const { minuteStep, secondStep } = showTime;

            if (minuteStep > 1) {
                const remainder = minuteStep - (now.minute() % minuteStep);
                if (remainder !== minuteStep) {
                    defaultValue.add(remainder, 'minutes');
                }
            }
            if (secondStep > 1) {
                const remainder = secondStep - (now.second() % secondStep);
                if (remainder !== secondStep) {
                    defaultValue.add(remainder, 'seconds');
                }
            }

            showTime.defaultValue = defaultValue;

            return showTime;
        }

        return showTime;
    }, [showTime]);

    if (naviShow && disabled) {
        rest.suffixIcon = null;
    }

    const baseClassName = classNames('dateArea', {
        [className]: !!className,
        wbForm_error: !!_.get(errors, name),
    });

    return (
        <div className={baseClassName}>
            <div className={naviShow ? 'dateBox' : ''}>
                {naviShow && (
                    <span
                        className={classNames('btnPrev', { disabled })}
                        onClick={() => {
                            !disabled && handleClickNavi(-1);
                        }}
                    />
                )}
                <BaseTextOrDatePicker
                    {...rest}
                    bordered={!naviShow}
                    format={format}
                    value={date}
                    disabled={disabled}
                    disabledDate={handleDisabledDate}
                    showTime={customShowTime}
                    picker={picker}
                    onChange={handleChange}
                    dateString={dateString}
                    ref={ref}
                />
                {naviShow && (
                    <span
                        className={classNames('btnNext', { disabled })}
                        onClick={() => {
                            !disabled && handleClickNavi(1);
                        }}
                    />
                )}
            </div>
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                    <div className="errorMessage">{message}</div>
                )}
            />
        </div>
    );
});

MuxBaseTextOrDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    format: PropTypes.string,
    showTime: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    disabled: PropTypes.bool,
    disabledDate: PropTypes.oneOfType([
        PropTypes.shape({
            baseDate: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.instanceOf(moment),
            ]),
            direction: PropTypes.oneOf(['before', 'after']),
        }),
        PropTypes.func,
    ]),
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool.isRequired,
    naviShow: PropTypes.bool.isRequired,
    inputReadOnly: PropTypes.bool,
    showToday: PropTypes.bool,
    showNow: PropTypes.bool,
    picker: PropTypes.oneOf(['date', 'week', 'month', 'quarter', 'year']),
    exportFormat: PropTypes.string, // longtime | moment | format
};

MuxBaseTextOrDatePicker.defaultProps = {
    format: 'YYYY-MM-DD',
    required: false,
    disabled: false,
    inputReadOnly: true,
    showToday: false,
    showNow: false,
    editable: true,
    naviShow: true,
    picker: 'date',
    exportFormat: 'moment',
};

export default withProperty(MuxBaseTextOrDatePicker);
export { MuxBaseTextOrDatePicker };
