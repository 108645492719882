const language = {
    'Common.All': 'All',
    'Common.Login': 'login',
    'Common.Logout': 'Logout',
    'Common.Logout.Hello': 'Hello, {user}.',
    'Common.Input.Required': 'It is a required value.',
    'Common.Input.EnterSearchKeyword': 'Enter Keywords',
    'Common.Btn.Selected': 'Selected',
    'Common.Btn.Select': 'Select',
    'Common.Btn.AllSearch': 'Full Search',
    'Common.Btn.Cancel': 'Cancel',
    'Common.Btn.CancelEdit': 'Cancel',
    'Common.Btn.CancelExtend': 'Cancel',
    'Common.Btn.Filter': 'Option',
    'Common.Btn.Create': 'Register',
    'Common.Btn.Edit': 'Modify',
    'Common.Btn.EditInfo': 'Edit Information',
    'Common.Btn.Confirm': 'OK',
    'Common.Btn.EditBusiness': 'Modify',
    'Common.Btn.EditInformation': 'Modify',
    'Common.Btn.CompleteReservation': 'Reservation completed',
    'Common.Btn.CompleteEdit': 'Modify',
    'Common.Btn.Add': 'Add',
    'Common.Btn.Delete': 'Delete',
    'Common.Btn.InUse': 'Use',
    'Common.Btn.List': 'List',
    'Common.Btn.Return': 'Return',
    'Common.Btn.ForceEdit': 'Forced Modification',
    'Common.Btn.ExtendReturn': 'Extend Return Date',
    'Common.Btn.CompleteExtend': 'Extend Return Date',
    'Common.Btn.ForceReturn': 'Forced Return',
    'Common.Btn.CancelReservation': 'Cancel Reservation',
    'Common.Btn.Approve': 'Approve',
    'Common.Btn.Deny': 'Reject',
    'Common.Btn.Map': 'Select from Map',
    'Common.Btn.SearchWeek': '1 week',
    'Common.Btn.Search1Month': '1 month',
    'Common.Btn.Search3Month': '3 months',
    'Common.Btn.Show.Edit': 'Edit',
    'Common.Btn.AddVehicle': 'Add Vehicle',
    'Common.Btn.AllSelect': 'Select All',
    'Common.Btn.Apply': 'Apply',
    'Common.Btn.MultiSelected': 'Multiple selection possible',
    'Common.Confirm.Create': 'Do you want to register it?',
    'Common.Confirm.Update': 'Do you want to edit it?',
    'Common.Confirm.Delete': 'do you want to delete it?',
    'Common.Confirm.Deletes': 'Do you want to delete {count} cases?',
    'Common.Confirm.Approve': 'Do you want to approve it?',
    'Common.Confirm.Deny': 'Do you want to reject it?',
    'Common.Confirm.Action': 'Do you want to perform the function?',
    'Common.Confirm.UserDelete': 'Do you want to withdraw your membership?',
    'Common.Confirm.PwdChange': 'Do you want to change the password?',
    'Common.Confirm.InUse':
        'Do you want to change the vehicle to the In Use state?',
    'Common.Alert': 'Cannot perform the function.',
    'Common.Created.Success': 'Registered.',
    'Common.Updated.Success': 'Modified.',
    'Common.Deleted.Success': 'Deleted.',
    'Common.Create.Fail': 'It cannot be registered.',
    'Common.Update.Fail': 'It cannot be modified.',
    'Common.Delete.Fail': 'It cannot be deleted.',
    'Common.Search.Fail': 'The search has failed.',
    'Common.Print': 'Print',
    'Common.Alert.Delete.No.Checked': 'Select an item to delete.',
    'Common.Alert.Approve.No.Checked': 'Select an item to approve',
    'Common.Alert.Deny.No.Checked': 'Select an item to reject.',
    'Common.Alert.Pay.No.Checked': 'Select an item to pay for.',
    'Common.Msg.NoData': 'There is no data retrieved.',
    'Common.Msg.NoSearchData': 'There is no search result.',
    'Common.Msg.NoVehicle': 'The vehicle does not exist.',
    'Common.Unit.Distance.meter': 'm',

    'Common.Unit.Money.0': 'USD',
    'Common.Unit.Money.10000': '0000USD',
    'Common.Unit.Money.0.Value': '{value} USD',
    'Common.Unit.Vehicle.Count': 'vehicles',
    'Common.Unit.Vehicle.Count.Value': '{value} vehicles',
    'Common.Unit.Hour': 'hours',
    'Common.Unit.Hour.Value': '{hours} hours',
    'Common.Unit.Minute': 'minutes',
    'Common.Unit.Minute.Value': '{value} minutes',
    'Common.Unit.Age.Over': 'years old or older',
    'Common.Unit.DiffDays.DaysHours.Valule': '{days} days {hours} hours',
    'Common.Unit.DiffDays.Days.Valule': '{days} days',
    'Common.Unit.DiffDays.Hours.Valule': '{hours} hours',
    'Common.Today': 'Today',
    'Common.Back': 'Previous',
    'Common.BirthDate.Year': 'Year',
    'Common.BirthDate.Month': 'Month',
    'Common.BirthDate.Day': 'Day',
    'Common.Role.Warn': "You don't have the permission.",
    'Common.Validator.Help.Number': 'Please enter numbers only.',
    'Common.Validator.Help.Email': 'Enter the address in a correct format.',
    'Common.Validator.Help.MaxNumber': 'You may enter {compareValue} or less.',
    'Common.Validator.Help.MinNumber': 'You may enter {compareValue} or more.',

    'Main.Title':
        'Compare the rental conditions from short term to mid-to-long term,{br}and reserve a vehicle conveniently.',
    // 'Main.SubTitle': ' ',
    'Main.GettingStart': 'Getting Started',

    'Footer.Policy1': 'Terms of Service',
    'Footer.Policy2': 'Privacy Statement',
    'Footer.Mocean': 'MOCEAN',
    'Footer.Address':
        '907-ho, A-dong, Bundang-Suji U-TOWER, 767, Sinsu-ro, Suji-gu, Yongin-si, Gyeonggi-do, Republic of Korea',

    'DayTimePicker.Help.From.Selected': 'Please select a start date.',
    'DayTimePicker.Help.From.After.Now':
        'The start date must be earlier than the present.',
    'DayTimePicker.Help.To.Selected': 'Please select an end date.',
    'DayTimePicker.Help.To.Before.From':
        'The end date must be later than the start date.',

    'Login.Alert.SystemError': 'Temporary system failure',
    'Auth.Check.Alert.SystemError':
        'You’ve accessed from a wrong path. Please log in again.',
    'Join.Alert.SystemError': 'Please log in again later.',

    'Auth.Alert.Expired':
        'You are logged out as the session has expired. Please re-login.',
    'Auth.Alert.Forbidden': "You don't have the permission.",
    'Auth.Wait': 'Please wait for a moment!',

    'Menu.VehicleManager': 'Fleet Management',
    'Menu.ReservationManager': 'Reservation Management',
    'Menu.VehicleUseManager': 'Rental Completion',
    'Menu.PaymentManager': 'Sales/Settlement Management',
    'Menu.Location': 'Base Management',
    'Menu.Terminal': 'Terminal Management',
    'Menu.User': 'User Management',
    'Menu.MyPage': 'My Page',

    'Title.Main': 'Main',
    'Title.VehicleMonitoring': 'Vehicle Control',
    'Title.VehicleManager': 'Fleet Management',
    'Title.Vehicle': 'View/Register Vehicle',
    'Title.VehicleModel': 'Vehicle Model Management',
    'Title.Terminal': 'Terminal Management',
    'Title.ReservationManager': 'Reservation Management',
    'Title.ReservationList': 'View Reservation Details',
    'Title.ReservationCreate': 'New Reservation',
    'Title.MakeReservation.BUSINESS_CORP': 'New Reservation (Affiliate)',
    'Title.UseList': 'Usage Status',
    'Title.EndUseList': 'Rental Completion',
    'Title.PaymentList': 'Payment Details',
    'Title.Charge': 'Rate Plan',
    'Title.ChargeVehicle': 'Rate Plan Management by Vehicle',
    'Title.UseStateList': 'Rental Status',
    'Title.Insurance': 'Premium',
    'Title.InsuranceVehicle': 'Premium Management by Vehicle',
    'Title.PenaltyAndToll': 'Penalty/Toll',
    'Title.Location': 'Base',
    'Title.LocationCreate': 'Base Registration',
    'Title.LocationEdit': 'Base Modification',
    'Title.Member': 'Manager',
    'Title.Business': 'Operator Management',
    'Title.BusinessCreate': 'Operator Registration',
    'Title.Customer': 'Membership Management',

    'Monitoring.LatestUpdate': 'Last Update',
    'Monitoring.ViewUnavailableVehicle': 'View Problem Vehicle',
    'Monitoring.ViewAllVehicle': 'View All Vehicles',
    'Monitoring.CurrentLocation': 'Current Location',
    'Monitoring.Location': 'Base Location',
    'Monitoring.SeeMore': 'View More',
    'Monitoring.NoUnavailableVehicle': 'There is no problem vehicle.',
    'Monitoring.Vehicle.DTE': 'Fuel Level',
    'Monitoring.Vehicle.AUXbattery': 'Battery Voltage',
    'Monitoring.Vehicle.Connectivity': 'Communication Status',
    'Monitoring.Unknown': 'Unknown',
    'Monitoring.Normal': 'Normal',

    'Reservation.Btn.Create': 'New Reservation',
    'Reservation.Btn.Filter': 'Option',
    'Reservation.Btn.Selected': 'Reservation completed',
    'Reservation.Code': 'Reservation No.',
    'Reservation.Birth': 'Date of Birth',
    'Reservation.Birth.Placeholder': 'YYYY.MM.DD',
    'Reservation.BirthYear.Placeholder': '2000',
    'Reservation.BirthMonth.Placeholder': '0',
    'Reservation.BirthDate.Placeholder': '0',
    'Reservation.Address': 'Address',
    'Reservation.Address.Zip.Placeholder': 'Zip Code',
    'Reservation.Address.Placeholder': 'Please enter the address.',
    'Reservation.DetailAddress.Placeholder': 'Please enter the street address.',
    'Reservation.Address.Search': 'Search Zip Code',
    'Reservation.LicenseInfo': "Driver's License Information",
    'Reservation.License': "Driver's License No.",
    'Reservation.License.Placeholder': "Driver's License No.",
    'Reservation.License.Confirm': 'License Authentication',
    'Reservation.License.ConfirmFinish': 'Authenticated',
    'Reservation.License.DueDate': 'License Expiration Date',
    'Reservation.License.DueDate.DayPicker':
        'Please enter the license expiration date.',
    'Reservation.License.Check': 'Please complete license authentication.',
    'Reservation.License.AdditionalCheck':
        "Please complete the authentication of co-driver's license.",
    'Reservation.License.ValidCheck':
        'Please enter all the fields for license authentication.',
    'Reservation.Edit.Warn':
        'Reservation cannot be modified once the start time of the reservation has passed.',
    'Reservation.GetProfile.NoData': 'No matching member data found',
    'Reservation.GetEmployeeProfile.NoData': 'Employee information not found',
    'Reservation.GetProfile.fail': 'Failed to find member information.',
    'Reservation.Location.NotMatch':
        'Cannot return it as the return location does not match.',
    'Reservation.RemoteControl.Fail':
        'Vehicle control has failed. Cannot return the vehicle.',
    'Reservation.PaymentDetail.IsNull': "You don't have a rate plan to edit.",
    'Reservation.Date.IsEqual':
        'Reservation end date should be later than the start date.',
    'Reservation.Date.Invalid':
        'Reservation start date cannot be earlier than today.',
    'Reservation.AlreadyUseCarExist.Fail':
        'There is a car already in use. Reservation not possible.',
    'Reservation.VehicleUsePay.IsZero':
        "Your plan doesn't exist or is set to $ 0.",
    'Reservation.Extend.CheckEndDate':
        'The extended return date should be later than the reservation end date.',
    'Reservation.Deny.DefaultMessage': 'Please enter the details.',
    'Reservation.TotalPay.Changed': 'The rate has changed.',
    'Reservation.Insurance.Invalid':
        'Reservation is not possible as the insurance expiration date has passed.',
    'Reservation.InsuranceEdit.Invalid':
        'Reservation cannot be modified as the insurance expiration date has passed.',
    'Reservation.UserNameInfo': 'Reserver Information',
    'Reservation.UserName': 'Reserver',
    'Reservation.Name': 'Name',
    'Reservation.CustName': 'Customer Name',
    'Reservation.Status': 'Status',
    'Reservation.ReservationStatus': 'Reservation Status',
    'Reservation.UserName.Placeholder': 'Please enter the reserver name.',
    'Reservation.UserName.Confirm': 'Find Existing Members',
    'Reservation.UserName.ReConfirm': 'Change Reserver',
    'Reservation.UserInfo.Search': 'Find Member Information',
    'Reservation.UserInfo.Confirm': 'Check Member Information',
    'Reservation.Employee.Confirm': 'Find Employee',
    'Reservation.EmployeeInfo.Search': 'Find Employee Information',
    'Reservation.EmployeeInfo.Confirm': 'Check Employee Information',
    'Reservation.Share.UserName': 'Co-driver',
    'Reservation.Share.UserName.Placeholder':
        "Please enter the co-driver's name.",
    'Reservation.Mobile': 'Phone No.',
    'Reservation.Mobile.Placeholder': 'Phone No.',
    'Reservation.Team': 'Team',
    'Reservation.Business': 'Organization',
    'Reservation.Team.Placeholder': 'Team',
    'Reservation.CampanyNumber': 'Employee No.',
    'Reservation.CampanyNumber.Placeholder': 'Employee No.',
    'Reservation.EmailAddress': 'Email Address',
    'Reservation.EmailAddress.Placeholder': 'Please enter the email address.',
    'Reservation.Insurance.Fee': 'Premium',
    'Reservation.Rent.Fee': 'Rental Fee',
    'Reservation.BusinessInfo': 'Select Operator',
    'Reservation.Business.Rent': 'General',
    'Reservation.Business.Corp': 'Corporation',
    'Reservation.VehicleInfo': 'Vehicle Information',
    'Reservation.VehicleUseFeeInfo': 'Vehicle Usage Fee',
    'Reservation.UseFeeInfo': 'Usage Fee',
    'Reservation.VehicleModel': 'Vehicle Type',
    'Reservation.BusinessName': 'Company Name',
    'Reservation.DateRange': 'Reservation Period',
    'Reservation.RentalPeriod': 'Rental Period',
    'Reservation.MobileReservation': 'Mobile Reservation',
    'Reservation.Date': 'Reservation Date/Time',
    'Reservation.DateRange.Placeholder.Start': 'Start Date',
    'Reservation.DateRange.Placeholder.End': 'End Date',
    'Reservation.InsuranceAge': 'Insurance Age',
    'Reservation.InsuranceAge.Placeholder':
        'Driver insurance for ages 21 and older',
    'Reservation.UseFee': 'Vehicle Usage Fee',
    'Reservation.ExtendFee': 'Extension Fee',
    'Reservation.UseFee.Result': 'Travel Fee {fee} USD/km',
    'Reservation.StartLocation': 'Delivery Place',
    'Reservation.StartLocation.Placeholder': 'Select Vehicle Delivery Place',
    'Reservation.StartLocation.Button': 'Select from Map',
    'Reservation.VehicleNumber': 'Vehicle No.',
    'Reservation.DenyReson': 'Reason for Rejection',
    'Reservation.LicenseType': 'License Type',
    'Reservation.VehicleNumber.Placeholder': 'Please select a vehicle.',
    'Reservation.UseFeeInfo.Placeholder': '0',
    'Reservation.UseFee.Placeholder': '0',
    'Reservation.ExtendFee.Placeholder': '0',
    'Reservation.Confirm.ExtendFee': 'Please enter an extension fee.',
    'Reservation.BusinessType.Placeholder': 'Please select an operator.',
    'Reservation.Confirm.Return': 'Do you want to return the vehicle?',
    'Reservation.Confirm.ForceReturn':
        'Do you want to return the vehicle forcibly?',
    'Reservation.Confirm.Extend': 'Do you want to extend the return date?',
    'Reservation.Confirm.Cancel': 'Do you want to cancel your reservation?',
    'Reservation.Confirm.Confirm': 'Do you want to approve it?',
    'Reservation.Confirm.Deny': 'Do you want to reject it?',
    'Reservation.DenyReason.Confirm': 'Please enter a reason for the return.',
    'Reservation.Return.Complete': 'Returned.',
    'Reservation.ForceReturn.Complete': 'Returned forcibly.',
    'Reservation.Extend.Complete': 'Return date extended.',
    'Reservation.Confirm.Complete': 'Reservation approved.',
    'Reservation.Cancel.Complete': 'Reservation canceled.',
    'Reservation.Deny.Complete': 'Reservation rejected.',
    'Reservation.Delete.Complete': 'Reservation deleted.',
    'Reservation.Update.Complete': 'Reservation modified.',
    'Reservation.Create.Complete': 'Reservation registered.',
    'Reservation.InUse.Complete':
        'The reservation was changed to the In Use state.',
    'Reservation.InsruanceAge.ConfirmFail':
        'The selected vehicle cannot be reserved due to the age of the reserver.',
    'Reservation.AdditionalInsruanceAge.ConfirmFail':
        'The selected vehicle cannot be reserved due to the age of the co-driver.',
    'Reservation.LicenseType.Placeholder': 'License Type',
    'Reservation.InsuranceList': 'Insurance Type',
    'Reservation.InsuranceList.Placeholder': 'Please select an insurance type.',
    'Reservation.Complete.NameMobileIsNull':
        'Please enter both the reserver and phone number.',
    'Reservation.Extend.AlreadyReserved':
        'The vehicle you reserved is available at that time.',
    'Reservation.InsuranceAge.IsOver': '{insuranceAge} years old or older',
    'Reservation.List.Fail': 'Cannot retrieve the reservation details.',
    'Reservation.Approve.Fail': 'The authorization has failed.',
    'Reservation.Deny.Fail': 'The return has failed.',
    'Reservation.GetRentCar.Fail': 'Cannot retrieve the rental company list.',
    'Reservation.EndList.Fail':
        'Cannot retrieve the rental completion details.',
    'Reservation.GetAvailable.Fail':
        'Cannot retrieve the list of available vehicles.',
    'Reservation.GetLocation.Fail': 'Cannot retrieve the list of bases.',
    'Reservation.MustHave.Fail': 'Please fill in all required fields.',
    'Reservation.AddReservation.Fail': 'Failed to register the reservation.',
    'Reservation.AlreadyReserved.Fail': 'The vehicle is already reserved.',
    'Reservation.GetUseList.Fail': 'Cannot retrieve the usage list.',
    'Reservation.GetTimeList.Fail': 'Cannot retrieve the usage list.',
    'Reservation.Return.Fail': 'Cannot return the vehicle.',
    'Reservation.Return.StartFail':
        'You cannot return the vehicle before the start time of the reservation.',
    'Reservation.ForceReturn.Fail': 'Failed to return the vehicle forcibly.',
    'Reservation.ForceReturn.StartFail':
        'You cannot return the vehicle before the start time of the reservation.',
    'Reservation.Cancel.Fail': 'Cannot cancel the reservation.',
    'Reservation.Inuse.Fail': 'Cannot change the vehicle to the In Use state.',
    'Reservation.InUse.TimeIsInvalid':
        'You can change the vehicle to the In Use state 15 minutes before the start time.',
    'Reservation.Delete.Fail': 'Cannot delete the reservation.',
    'Reservation.Extend.Fail': 'Cannot extend the return date.',
    'Reservation.Update.Fail': 'Cannot modify the reservation.',
    'Reservation.LicenseCheck.Fail': 'License authentication has failed.',
    'Reservation.LicenseCheck.Invalid':
        'Invalid license information. Please check the information again.',
    'Reservation.LicenseExpiryDate.InValid':
        'Rental start date exceeds the license renewal expiration date.',
    'Reservation.LicenseExpiryDate.AddTimeIsInvalid':
        'Rental start date exceeds the license renewal expiration date of the co-driver.',
    'Reservation.GetPaymentDetail.Fail':
        'Failed to retrieve the rate information.',
    'Reservation.GetInsuranceList.Fail':
        'Failed to retrieve the insurance list.',
    'Reservation.View.Fail': 'Failed to retrieve the reservation information.',
    'Reservation.CurrentVehicle.AlreadyReserved':
        'The vehicle is already reserved.',
    'Reservation.Edit.NotAvailable': 'The reservation cannot be modified.',
    'Reservation.OfflinePayment.Notify':
        'You can only pay the usage fee offline.',
    'Reservation.DenyReason.Placeholder':
        'Please enter a reason for the return.',
    'Reservation.Payment.NotMinTime':
        'The reservation period is shorter than the minimum usage period.',
    'Reservation.Payment.NotMinUseTime':
        'The extended period is shorter than the minimum usage period.',
    'Reservation.Payment.NoCharge': 'No rate plan is set for the vehicle.',
    'Reservation.Payment.NoInsurance': 'No premium is set for the vehicle.',

    'Reservation.Filter.LicensePlateNumber': 'Vehicle No.',
    'Reservation.Filter.Code': 'Reservation No.',
    'Reservation.Filter.User.Name': 'Reserver Name',
    'Reservation.Filter.User.MobileNumber': 'Reserver Phone No.',
    'Reservation.Filter.Location.Name': 'Base Name',
    'Reservation.Filter.Business.Name': 'Corporation Name',

    'Reservation.DayTimePicker.Title': 'Please select a rental date.',
    'Rerservation.DayPicker.Title': 'Enter a rental date and return date.',
    'Rerservation.DayPicker.StartTime': 'Rental Date',
    'Rerservation.DayPicker.EndTime': 'Return Date',
    'Rerservation.Btn.All.Confirm': 'Full Approval',
    'Rerservation.Btn.Confirm': 'Approve',
    'Rerservation.Btn.Deny': 'Reject',
    'Reservation.DateFormat.Placeholder': 'YYYY.MM.DD',

    'UseStateCalendar.No': 'NO.',
    'UseStateCalendar.LicensePlateNumber': 'Vehicle No.',

    'ReservationCalendar.Calendar.Item.Label.requested':
        'Waiting for Approval : {count} ',
    'ReservationCalendar.Calendar.Item.Label.confirmed':
        'Reservations : {count}',
    'ReservationCalendar.Calendar.Item.Label.canceled':
        'Cancel Reservation : {count}',
    'ReservationCalendar.Calendar.Item.Label.returned':
        'To be returned : {count}',
    'ReservationCalendar.Admin.Title': 'Reservation Status',
    'ReservationCalendar.Admin.Item': '{businessName}{br}{reservationCount}',
    'ReservationCalendar.Private.Desc':
        'Reservations unauthorized within the reservation period will be automatically canceled.',
    'ReservationCalendar.Private.Requested.Title': 'Waiting for Approval',
    'ReservationCalendar.Private.Requested.Item':
        'Waiting for Approval : {requestedCount}',
    'ReservationCalendar.Private.Confirmed.Title': 'Reservation Status',
    'ReservationCalendar.Private.Confirmed.Item': '{groupName} {length} 건',
    'ReservationCalendar.Private.Group.Confirmed.Item':
        '{licensePlateNumber}{br}Reserver {user}',
    'ReservationCalendar.Private.Group.Requested.Item':
        '{licensePlateNumber}{br}{teamName} / {user}',
    'ReservationCalendar.Private.Confirm.Approve':
        'Do you want to approve the checked item(s)?',
    'ReservationCalendar.Private.Confirm.Approve.All':
        'Do you want to grant full approval?',
    'ReservationCalendar.Public.Confirmed.Title': 'Reservation Status',
    'ReservationCalendar.Public.Confirmed.Item':
        '[{code}] {reservationTime}{br}{name} / {totalPay}USD //[{code}]{reservationTime}{br}{name}/{totalPay}USD',
    'ReservationCalendar.Public.Retruned.Item':
        '[{code}] {reservationTime}{br}{name}',
    'ReservationCalendar.Public.Returned.Title': 'To be returned',
    'ReservationCalendar.Public.Returned.Item':
        '{reservationTime}{br}{code} / {name}',
    'ReservationCalendar.Month.Get.Fail':
        'Cannot retrieve the monthly reservation status.',
    'ReservationCalendar.Business.Get.Fail':
        'Cannot retrieve the reservation status by corporation.',
    'ReservationCalendar.Public.Get.Fail': 'Cannot retrieve the rental status.',
    'ReservationCalendar.Private.Get.Fail':
        'Cannot retrieve the reservation status of the corporation.',

    'Vehicle.Information': 'Vehicle Information',
    'Vehicle.State.Sub.Title': 'Vehicle Information',
    'Vehicle.Reservation.Sub.Title': 'Reservation Status',
    'Vehicle.Model': 'Vehicle Model',
    'Vehicle.Color': 'Vehicle Color',
    'Vehicle.Transmission': 'Gearshift Type',
    'Vehicle.LicensePlateNumber': 'Vehicle Registration No.',
    'Vehicle.VIN': 'VIN No.',
    'Vehicle.Status': 'Status',
    'Vehicle.ControllerID': 'Terminal Phone No.',
    'Vehicle.RegistrationDate': 'Vehicle Registration Date',
    'Vehicle.ProductionDate': 'Production Date',
    'Vehicle.Options': 'Option',
    'Vehicle.Business': 'Possessor Corporation',
    'Vehicle.Location': 'Base',
    'Vehicle.Insurance.Company': 'Insurance Company',
    'Vehicle.Insurance.Age': 'Insurance Age',
    'Vehicle.Insurance.JoinDate': 'Insurance Subscription Date',
    'Vehicle.Insurance.ExpireDate': 'Expiration Date',
    'Vehicle.Insurance.PD': 'Liability Limit for Objects',
    'Vehicle.Insurance.BI': 'Liability Limit for Person',
    'Vehicle.Insurance.DB': 'PAI Limit',
    'Vehicle.RegistrationDate.Placeholder': 'ex) 20190101',
    'Vehicle.Model.Placeholder': 'Please select a model.',
    'Vehicle.Transmission.Placeholder': 'Please select a gearshift type.',

    'Vehicle.VIN.Placeholder':
        'VIN consists of 17 characters including English letters and numbers',
    'Vehicle.VIN.Help':
        'VIN consists of 17 characters including English letters and numbers',

    'Vehicle.ControllerID.Help': 'Phone number should be composed of numbers.',
    'Vehicle.Business.Placeholder': 'Please select a corporation.',
    'Vehicle.Location.Placeholder': 'Please select a base.',
    'Vehicle.Options.Placeholder': 'Please select an option.',
    'Vehicle.Options.No.Data': 'Please select an option.',
    'Vehicle.Insurance.Company.Placeholder':
        'Please enter an insurance company.',
    'Vehicle.Insurance.Age.Placeholder': 'Please select the insurance age.',
    'Vehicle.Insurance.JoinDate.Placeholder':
        'Please enter the insurance subscription date.',
    'Vehicle.Insurance.PD.Placeholder':
        'Please enter the liability limit for objects.',
    'Vehicle.Insurance.BI.Placeholder':
        'Please enter the liability limit for person.',
    'Vehicle.Insurance.DB.Placeholder': 'Please enter the PAI limit.',
    'Vehicle.ControllerID.Help.Already.Use':
        'The entered terminal phone number is already in use.',
    'Vehicle.VIN.Help.Already.Use': 'The entered VIN is already in use.',
    'Vehicle.Btn.Controller': 'Check Terminal',
    'Vehicle.View.Fail': 'Cannot retrieve the vehicle information.',
    'Vehicle.Delete.Fail': 'Vehicles in use cannot be deleted.',
    'Vehicle.Confirm.Delete': 'Do you want to delete the vehicle information?',
    'Vehicle.Confirm.Deletes':
        'Do you want to delete the checked item(s)?{br}Vehicles in use cannot be deleted.',

    'Vehicle.State.TpmsFl': 'Front Wheel/Left',
    'Vehicle.State.TpmsFr': 'Front Wheel/Right',
    'Vehicle.State.TpmsRl': 'Rear Wheel/Left',
    'Vehicle.State.TpmsRr': 'Rear Wheel/Right',
    'Vehicle.State.Tpms.Unit': 'PSI',
    'Vehicle.State.Battery': 'Battery Voltage',
    'Vehicle.State.FuelLevel': 'Fuel Level',

    'Vehicle.Confirm.Control.Open': 'Do you want to open the door?',
    'Vehicle.Confirm.Control.Close': 'Do you want to lock the door?',
    'Vehicle.Confirm.Control.Siren':
        'Do you want to activate the emergency light/horn?',
    'Vehicle.Control': 'Vehicle Control',
    'Vehicle.Control.Open': 'Open Door',
    'Vehicle.Control.Close': 'Close Door',
    'Vehicle.Control.Siren': 'Emergency Light/Horn',
    'Vehicle.Control.Open.Action': 'Activate',
    'Vehicle.Control.Close.Action': 'Activate',
    'Vehicle.Control.Siren.Action': 'Activate',
    'Vehicle.Control.Requested.Success':
        'A signal has been sent to the vehicle.',
    'Vehicle.Control.Request.Fail': 'Cannot send a signal to the vehicle.',
    'Vehicle.Control.Opened.Success': 'The door is open.',
    'Vehicle.Control.Closed.Success': 'The door is locked.',
    'Vehicle.Control.Siren.Success': 'Emergency light/horn was activated.',
    'Vehicle.Control.Fail': 'An error has occurred during vehicle control.',
    'Vehicle.Control.Fail.Code': 'Please contact your administrator. [{code}]',
    'Vehicle.Control.Timeout': 'Reception of control result is delayed.',

    'VehicleModel.Information': 'Vehicle Information',
    'VehicleModel.Name': 'Model Name',
    'VehicleModel.Name.Placeholder': 'Please enter a model name.',
    'VehicleModel.Image.Helper': 'A vehicle image must be registered.',
    'VehicleModel.Brand': 'Manufacturer',
    'VehicleModel.Brand.Placeholder': 'Please select a manufacturer.',
    'VehicleModel.FuelType': 'Fuel Type',
    'VehicleModel.FuelType.Placeholder': 'Please select a fuel type.',
    'VehicleModel.SeatingCapacity': 'Number of Passengers',
    'VehicleModel.SeatingCapacity.Placeholder':
        'Please enter the number of passengers.',
    'VehicleModel.SeatingCapacityP': 'Number of Passengers',
    'VehicleModel.Displacement': 'Displacement (cc)',
    'VehicleModel.Displacement.Placeholder': 'ex) 1800',
    'VehicleModel.FuelTankCapacity': 'Fuel Tank Capacity',
    'VehicleModel.FuelTankCapacity.Placeholder': 'ex) 70',
    'VehicleModel.FuelTankCapacityE': 'Battery Capacity (kWh)',
    'VehicleModel.FuelEfficiency': 'Fuel Consumption (km/l)',
    'VehicleModel.FuelEfficiency.Helper':
        'You can only enter an integer or a decimal number for fuel consumption. e.g.) 12.5',
    'VehicleModel.FuelEfficiency.Placeholder': 'ex) 12.5',
    'VehicleModel.Grade': 'Vehicle Class',
    'VehicleModel.Grade.Placeholder': 'Vehicle Class',
    'VehicleModel.Standard': 'Main Model',
    'VehicleModel.StandardTag': 'Main',
    'VehicleModel.Confirm.Deletes':
        'Do you want to delete {count} models?{br}You cannot delete models that are registered.',
    'VehicleModel.GetData.Fail': 'Failed to retrieve the vehicle model.',
    'VehicleModel.Create.Success': 'The vehicle model has been registered.',
    'VehicleModel.Create.Fail': 'Failed to register the vehicle model.',
    'VehicleModel.Update.Success': 'The vehicle model has been modified.',
    'VehicleModel.Update.Fail': 'Failed to modify the vehicle model.',
    'VehicleModel.UpdateImage.Fail':
        'Failed to register the vehicle model image.',
    'VehicleModel.Delete.Success': 'The vehicle model has been deleted.',
    'VehicleModel.Delete.Fail': 'Failed to delete the vehicle model.',
    'VehicleModel.Fail.DuplicatedModelName':
        '모델명 중복으로 기능을 수행할 수 없습니다.',

    'Terminal.Title.Create': 'Register Terminal',
    'Terminal.Title.Info': 'Terminal Details',
    'Terminal.Vehicle.LicensePlateNumber': 'Vehicle No.',
    'Terminal.VIN': 'VIN No.',
    'Terminal.NadID': 'Phone No.',
    'Terminal.Serial': 'Serial No.',
    'Terminal.Manufacturer': 'Manufacturer',
    'Terminal.Version': 'S/W Ver.',
    'Terminal.OrderDate': 'Order Date',
    'Terminal.DeliveryDate': 'Delivery Date',
    'Terminal.MobileOperator': 'Communications Carrier',
    'Terminal.ActivationDate': 'Terminal Activation Date',
    'Terminal.ICCID': 'USIM No.',
    'Terminal.State': 'Terminal Status',
    'Terminal.Status': 'Terminal Management',
    'Terminal.State.Status': 'Terminal Status',
    'Terminal.VIN.Placeholder':
        'VIN consists of 17 characters including English letters and numbers',
    'Terminal.NadID.Placeholder': 'Please enter the phone number.',
    'Terminal.Manufacturer.Placeholder': 'Please enter the manufacturer.',
    'Terminal.Serial.Placeholder': 'ex) CASD12434052DS888',
    'Terminal.Version.Placeholder': 'Enter Software Version',
    'Terminal.OrderDate.Placeholder': 'ex) 20191201',
    'Terminal.DeliveryDate.Placeholder': 'ex) 20191201',
    'Terminal.MobileOperator.Placeholder': 'Please select a carrier.',
    'Terminal.ActivationDate.Placeholder': 'ex) 20191201',
    'Terminal.ICCID.Placeholder': 'Enter Number',
    'Terminal.State.Placeholder': 'Auto Display after Connection',
    'Terminal.Status.Placeholder': 'Select the terminal management status.',
    'Terminal.Get.Fail': 'Cannot retrieve the terminal information.',

    'Charge.Btn.Business.List': 'Corporation List',
    'Charge.Title': 'Rate Plan',
    'Charge.Name': 'Rate Plan Name',
    'Charge.Reservation': 'Minimum Time for Reservation',
    'Charge.PriceTime': 'Time Unit for Charge',
    'Charge.Price.Helper': 'Rate must be an integer greater than zero.',
    'Charge.Week_day_charge': 'Weekday Rate',
    'Charge.Week_end_charge': 'Weekend Rate',
    'Charge.Insurance': 'Disclaimer Premium',
    'Charge.Peak_season_week_day_charge': 'Peak Season Weekday Rate',
    'Charge.Peak_season_week_end_charge': 'Peak Season Weekend Rate',
    'Charge.Peak_season': 'Set Period',
    'Charge.TimePrice': 'Rate Table by Rental Time',
    'Charge.Vehicle.Apply': 'Applicable Vehicle',
    'Charge.WeekDay': 'Weekday',
    'Charge.WeekEnd': 'Weekend',
    'Charge.PeakWeekDay': 'Peak Season Weekday',
    'Charge.PeakWeekEnd': 'Peak Season Weekend',
    'Charge.VehicleNoData':
        'Please add a vehicle to which the rate plan will be applied.',
    'Charge.PriceTimeUnit': 'Unit',
    'Charge.MinReservation': 'Minimum Time',
    'Charge.Vehiclemodel.Name': 'Vehicle Type',
    'Charge.Vehicle.licenseplatenumber': 'Vehicle No.',
    'Charge.Vehicle.Charge': 'Rate Plan',
    'Charge.PickerInfoMsg':
        '* If you do not enter the peak season rate, the regular rate will be applied.',
    'Charge.Vehicle': 'Vehicle',
    'Charge.Vehicle.Msg': 'Vehicle',
    'Charge.Name.Placeholder': 'Please enter a rate plan.',
    'Charge.Insurance.Placeholder': 'Please select a disclaimer premium.',
    'Charge.Peak_season_start.Placeholder': 'Start Date',
    'Charge.Peak_season_end.Placeholder': 'End Date',
    'Charge.Week_day_charge.Placeholder': '0',
    'Charge.Week_end_charge.Placeholder': '0',
    'Charge.Peak_season_week_day_charge.Placeholder': '0',
    'Charge.Peak_season_week_end_charge.Placeholder': '0',
    'Charge.PriceTime_hour.Placeholder': '0h',
    'Charge.PriceTime_minute.Placeholder': '0m',
    'Charge.Reservation_hour.Placeholder': '0h',
    'Charge.Reservation_minute.Placeholder': '0m',
    'Charge.WarningMsg':
        "Vehicles with other rate plans are included.{br}If you don't want to change your plan, please uncheck the box.",
    'Charge.NotCreate': 'Unregistered',
    'Charge.Vehicle.NoData': 'No Vehicle',
    'Charge.Confirm.Deletes':
        'Do you want to delete the checked item(s)?{br}Vehicles reserved under this rate plan will be charged with the rate before deletion.',
    'Charge.Vehicle.Confirm.Deletes':
        'Do you want to delete the checked item(s)?',
    'Charge.First_discount': 'Discount for Rent of 3 Days or More (%)',
    'Charge.Second_discount': 'Discount for Rent of 7 Days or More (%)',
    'Charge.Toast.Business.Fail': 'Failed to retrieve the operator list.',
    'Charge.Toast.ChargeList.Fail': 'Failed to retrieve the rate plan list.',
    'Charge.Toast.ChargeDetail.Fail':
        'Failed to retrieve the rate plan details.',
    'Charge.Toast.ChargeCreate.Success': 'The rate plan has been registered.',
    'Charge.Toast.ChargeCreate.Fail': 'Failed to register the rate plan.',
    'Charge.Toast.ChargeUpdate.Success': 'The rate plan has been modified.',
    'Charge.Toast.ChargeUpdate.Fail': 'Failed to modify the rate plan.',
    'Charge.Toast.DetailVehicleList.Fail':
        'Failed to retrieve the list of vehicles with the rate plan.',
    'Charge.Toast.VehicleList.Fail': 'Failed to retrieve the vehicle list.',
    'Charge.Toast.Delete.Success': 'The rate plan has been deleted.',
    'Charge.Toast.Delete.Fail':
        'A failure occurred due to the reserved rate plan.',
    'Charge.Toast.ChargeDelete.Fail': 'Failed to delete the rate plan.',
    'Charge.Toast.VehicleChargeDelete.Success':
        'The rate plan has been deleted.',
    'Charge.Toast.VehicleChargeDelete.Fail': 'Failed to delete the rate plan.',
    'Charge.Toast.VehicleChargeList.Fail':
        'Failed to retrieve the vehicle model list.',

    'Insurance.Vehicle': 'Vehicle',
    'Insurance.GroupName': 'Premium Group Name',
    'Insurance.Info': 'Premium Information',
    'Insurance.Name': 'Insurance Name',
    'Insurance.SelfCost': 'Insurance Deductible (USD)',
    'Insurance.Time': 'Time Unit for Charge',
    'Insurance.Premium': 'Premium (USD)',
    'Insurance.TimePrice': 'Premium by Rental Time',
    'Insurance.Charge1': 'Premium 1',
    'Insurance.Charge2': 'Premium 2',
    'Insurance.Charge3': 'Premium 3',
    'Insurance.WarningMsg':
        "There are vehicles with other premiums.{br}If you don't want to change the premium, please uncheck the box.",
    'Insurance.Confirm.Deletes':
        'Do you want to delete the checked item(s)?{br}Vehicles reserved under this premium will be charged with the premium before deletion.',
    'Insurance.Toast.InsuranceList.Fail':
        'Failed to retrieve the premium list.',
    'Insurance.Toast.InsuranceDetail.Fail':
        'Failed to retrieve the premium details.',
    'Insurance.Toast.InsuranceUpdate.Success': 'The premium has been modified.',
    'Insurance.Toast.InsuranceUpdate.Fail': 'Failed to modify the premium.',
    'Insurance.Toast.InsuranceDelete.Success': 'The premium has been deleted.',
    'Insurance.Toast.InsuranceDelete.Fail': 'Failed to delete the premium.',
    'Insurance.Toast.InsuranceCreate.Success':
        'The premium has been registered.',
    'Insurance.Toast.InsuranceCreate.Fail': 'Failed to register the premium.',
    'Insurance.Toast.DetailVehicleList.Fail':
        'Failed to retrieve the list of vehicles with the premium.',
    'Insurance.Toast.VehicleChargeList.Fail':
        'Failed to retrieve the vehicle model list.',
    'Insurance.Toast.VehicleChargeDelete.Success':
        'The premium by vehicle has been deleted.',
    'Insurance.Toast.VehicleChargeDelete.Fail':
        'Failed to delete the premium by vehicle.',
    'Insurance.Toast.Delete.Fail':
        'A failure occurred due to the reserved premium.',
    'Insurance.Toast.Business.Fail': 'Failed to retrieve the operator list.',

    'SalesManager.AppReservation': 'APP Reservation',
    'SalesManager.FMSReservation': 'WEB Reservation',
    'SalesManager.Use_pay': 'Rental/Extension',
    'SalesManager.Premium': 'Premium',
    'SalesManager.Receivables_pay': 'Receivables',
    'SalesManager.Receivables_pay_total': '{month} receivables{br}(cumulative)',
    'SalesManager.Sales_total': 'Total',
    'SalesManager.Month_total': '{month} total sales{br}(cumulative)',
    'SalesManager.Won': '{amount} USD',
    'SalesManager.Toast.PaymentMonthList.Fail':
        'Failed to retrieve the monthly list.',
    'SalesManager.Toast.PaymentDayList.Fail':
        'Failed to retrieve the daily list.',
    'SalesManager.Toast.PaymentBusinessList.Fail':
        'Failed to retrieve the operator list.',

    'UseState.State.InUse': 'In Use',
    'UseState.State.Wait': 'Waiting',
    'UseState.State.Unavailable': 'Abnormal',
    'UseState.State.LowVoltage': 'Low Voltage',
    'UseState.State.NotReturned': 'Not Returned',
    'UseState.All': 'All',
    'UseState.Use': 'In Use',
    'UseState.Toast.UseStatusList.Fail':
        'Failed to retrieve the usage status list.',
    'UseState.Toast.UseStateCount.Fail':
        'Failed to retrieve the usage status list.',

    'Payment.Processing': 'Process Payment',
    'Payment.Complete': 'Payment completed',
    'Payment.Status': 'Status/Amount',
    'Payment.PaymentStatus': 'Payment Status',
    'Payment.UnpaidPay': 'Unpaid Fees',
    'Payment.ActualPay': 'Actual Payment',
    'Payment.Status.Complete': 'Payment completed',
    'Payment.Status.Unpaid': 'Unpaid',
    'Payment.Status.Wait': 'Waiting for Payment',
    'Payment.Status.Fail': 'Payment Failure',
    'Payment.Alert.NoPaymentData': 'Payment information does not exist.',
    'Payment.Alert.Delete.No.Checked':
        'Select a reservation to process the payment for.',
    'Payment.Confirm.Pay': 'Do you want to process {count} payments?',
    'Payment.Pay.Fail': 'Failed to process the payment.',
    'Payment.Get.Fail': 'Failed to retrieve the payment details.',
    'Payment.Pay.No.Checked': 'There are no items to process the payment for.',
    'Payment.Pay.MultiFail': 'Failed to process {count} payments.',
    'Payment.Pay.Success': 'The payment has been completed.',

    'Local.Address.Get.Fail': 'Failed to convert the address.',
    'Local.Geocode.Get.Fail': 'Failed to convert the address.',
    'Local.Search.Fail': 'The search has failed.',

    Location: 'Base',
    'Location.Placeholder':
        'Move the marker on the map to automatically enter the address.',
    'Location.Name': 'Base Name',
    'Location.Name.Placeholder': 'Please enter the base name.',
    'Location.Address': 'Address',
    'Location.Address.ModalTitle': 'Address Search',
    'Location.Address.Placeholder':
        'This field is automatically filled with the address.',
    'Location.DetailedAddress': 'Detailed Address',
    'Location.DetailedAddress.Placeholder': 'Please enter the street address.',
    'Location.VehicleCount': 'Vehicle in Possession',
    'Location.Business': 'Possessor Corporation',
    'Location.Business.Name': 'Corporation Name',
    'Location.Business.Opened': 'Public Base',
    'Location.Business.Name.Placeholder': 'Please select a corporation.',
    'Location.Confirm.MultiDelete': 'Do you want to delete {count} base(s)?',
    'Location.Create.Success': 'The base has been registered.',
    'Location.Create.Fail': 'Failed to register the base.',
    'Location.Update.Success': 'Base information has been modified.',
    'Location.Update.Fail': 'Failed to modify the base information.',
    'Location.Delete.Success': 'The base has been deleted.',
    'Location.Delete.Fail': 'Failed to delete the base.',
    'Location.Delete.Fail.VehicleExists':
        'Deletion failed as vehicles exist in the base.',
    'Location.View.Fail': 'Cannot retrieve the base information.',
    'Location.Monitoring.Fail':
        'Cannot retrieve the vehicle information in the base.',
    'Location.Unit.Vehicle.Count': '{value} vehicles',
    'Location.Radius': 'Return Place (Radius)',
    'Location.Radius.Placeholder': 'Please select a radius.',
    'Location.Note': 'Details',
    'Location.Note.Placeholder': 'Please enter the details.',
    'Location.Note.Empty': 'There are no details.',

    'Member.Title.Info': 'Basic Information',
    'Member.Title.Business': 'Operator Information',
    'Member.Name': 'Name',
    'Member.Email': 'User ID',
    'Member.MobileNumber': 'Phone No.',
    'Member.Role': 'Type',
    'Member.ActivationStatus': 'Status',
    'Member.JoinDate': 'Enrollment Date',
    'Member.BirthDay': 'Date of Birth',
    'Member.Business.Name': 'Operator',
    'Member.TeamName': 'Team',
    'Member.EmployeeRegNum': 'Employee No.',
    'Member.OfficePhoneNumber': 'Office',
    'Member.DenyReason': 'Reason for Rejection',
    'Member.Filter.Email': 'User ID',
    'Member.Filter.Name': 'Name',
    'Member.Filter.MobileNumber': 'Phone No.',
    'Member.Filter.Business': 'Operator Name',
    'Member.Modal.Title.Deny': 'Reason for Rejection',
    'Member.Denyreason.Placeholder': 'Please enter a reason for the return.',
    'Member.View.Fail': 'Cannot retrieve the administrator.',
    'Member.Approved.Success': 'The request has been approved.',
    'Member.Approve.Fail': 'Failed to approve the request.',
    'Member.Denied.Success': 'The request has been rejected.',
    'Member.Deny.Fail': 'Failed to reject the request.',
    'Member.Help.Approve.Ready':
        '※ Your request for approval has not been completed.',

    'Customer.Title.Info': 'Basic Information',
    'Customer.Title.Business': 'Employee Information',
    'Customer.Name': 'Member Name',
    'Customer.Email': 'Email Address',
    'Customer.MobileNumber': 'Phone No.',
    'Customer.JoinDate': 'Enrollment Date',
    'Customer.ActivationStatus': 'Status',
    'Customer.BirthDay': 'Date of Birth',
    'Customer.Address': 'Address',
    'Customer.DriverLicense': "Driver's License",
    'Customer.CreditCard': 'Card No.',
    'Customer.Business.Name': 'Corporation Name',
    'Customer.TeamName': 'Team',
    'Customer.EmployeeRegNum': 'Employee No.',
    'Customer.DenyReason': 'Reason for Rejection',
    'Customer.Filter.Email': 'User ID',
    'Customer.Filter.Name': 'Name',
    'Customer.Filter.MobileNumber': 'Phone No.',
    'Customer.Filter.Business': 'Operator Name',
    'Customer.Filter.Team': 'Team',
    'Customer.Modal.Title.Deny': 'Reason for Rejection',
    'Customer.Denyreason.Placeholder': 'Please enter a reason for the return.',
    'Customer.View.Fail': 'Cannot retrieve members.',
    'Customer.Approved.Success': 'The request has been approved.',
    'Customer.Approve.Fail': 'Failed to approve the request.',
    'Customer.Denied.Success': 'The request has been rejected.',
    'Customer.Deny.Fail': 'Failed to reject the request.',

    'Business.Title.Type': 'Operator Category',
    'Business.Title.Business': 'Operator Information',
    'Business.Title.Manager': 'Main Administrator',
    'Business.BusinessCode': 'Code',
    'Business.BusinessRegNum': 'Operator No.',
    'Business.Name': 'Corporation Name',
    'Business.MobileNumber': 'Main Phone',
    'Business.Address': 'Address',
    'Business.KoroadClientID': "Driver's License",
    'Business.PGMemberID': 'PG Member ID',
    'Business.Manager.Name': 'Name',
    'Business.Manager.MobileNumber': 'Phone No.',
    'Business.Manager.Email': 'Email Address',
    'Business.Type.Private': 'Corporation',
    'Business.Type.Public': 'General',
    'Business.Modal.Address.Search': 'Find Operator’s Address',
    'Business.BusinessRegNum.Placeholder': 'Please enter the operator number.',
    'Business.Name.Placeholder': 'Please enter the name of the corporation.',

    'Business.Address.Address.Placeholder': 'Please search for a postal code.',
    'Business.Address.DetailedAddress.Placeholder':
        'Please enter the street address.',
    'Business.KoroadClientID.Placeholder': 'CLIENT ID',
    'Business.KoroadSecret.Placeholder': 'CLIENT SECRET',
    'Business.KoroadPIN.Placeholder': 'PIN',
    'Business.KoroadPWD.Placeholder': 'Password',
    'Business.PGMemberID.Placeholder': 'Please enter ID.',
    'Business.Manager.Name.Placeholder': 'Please enter the name.',

    'Business.Manager.Email.Placeholder': 'Please enter an email address.',
    'Business.View.Fail': 'Cannot retrieve the operator information.',
    'Business.BusinessRegNum.Help': 'Please enter the operator number.',
    'Business.MobileNumber.Help': 'Enter a valid main phone number.',
    'Business.Manager.MobileNumber.Help': 'Enter a valid phone number.',

    'Alert.Image.SizeLimitExceeded': 'You can upload an image up to 500KB.',

    'Vehicle.Status.Wait': 'Waiting',
    'Vehicle.Status.InUse': 'In Use',
    'Vehicle.Status.Incident': 'Abnormal',
    'Vehicle.Status.LowVoltage': 'Low Voltage',
    'Vehicle.Status.NotReturned': 'Not Returned',
    'Vehicle.Status.Unknown': 'Unknown',

    'Brand.Type.Hyundai': 'Hyundai Motor Company',
    'Brand.Type.Kia': 'Kia Motors',

    'Fuel.Type.Gasoline': 'Gasoline',
    'Fuel.Type.Diesel': 'Diesel',
    'Fuel.Type.Ev': 'Electricity',
    'Fuel.Type.Hybrid': 'Hybrid',

    'Grade.Type.City': 'Minicompact',
    'Grade.Type.SubCompact': 'Subcompact',
    'Grade.Type.Compact': 'Compact',
    'Grade.Type.Mid': 'Mid-Size',
    'Grade.Type.Large': 'Large',
    'Grade.Type.SUV': 'SUV',
    'Grade.Type.VAN': 'Van',
    'Grade.Type.Electric': 'Electricity',
    'Grade.Type.Oversea': 'Import',

    'Accident.Type.Washing': 'Washing',
    'Accident.Type.Maintenance': 'Maintenance',
    'Accident.Type.Repair': 'Repair',
    'Accident.Type.Etc': 'Other',

    'Terminal.StatusType.Normal': 'Use (Connection Status)',
    'Terminal.StatusType.Outoforder': 'Troubleshooting',
    'Terminal.StatusType.Discard': 'Disposal',
    'Terminal.StatusType.Unknown': 'Unknown',

    'Terminal.State.Type.Unknown': 'Unknown',
    'Terminal.State.Type.Connect': 'Mounted on the Vehicle',
    'Terminal.State.Type.Conflict': 'Inconsistency',
    'Terminal.State.Type.Wait': 'Waiting',

    'Terms.StatusType.Valid': 'Valid',
    'Terms.StatusType.Invalid': 'Invalid',

    'Notice.StatusType.Draft': 'DRAFT',
    'Notice.StatusType.Published': 'PUBLISHED',
    'Notice.StatusType.Notified': 'NOTIFIED',

    'Member.ActivationStatusType.Pending': 'Waiting',
    'Member.ActivationStatusType.Normal': 'Approve',
    'Member.ActivationStatusType.Deny': 'Reject',

    'Member.RoleType.System.Admin': 'Manager',
    'Member.RoleType.System.Operator': 'Administrator',
    'Member.RoleType.Public.Admin': 'Manager',
    'Member.RoleType.Public.Operator': 'Administrator',
    'Member.RoleType.Private.Admin': 'Manager',
    'Member.RoleType.Private.Operator': 'Administrator',

    'Member.RoleGroupType.Admin': 'Manager',
    'Member.RoleGroupType.Operator': 'Administrator',

    'User.StatusType.Pending': 'Waiting',
    'User.StatusType.Normal': 'Approve',
    'User.StatusType.Reject': 'Reject',
    'User.StatusType.Deny': 'Reject',

    'User.LevelType.Public': 'General Member',
    'User.LevelType.Private': 'Corporate Member',

    'Customer.BizStatusType.Requested': 'Waiting',
    'Customer.BizStatusType.Inactive': 'Approve',
    'Customer.BizStatusType.Deny': 'Reject',

    'Reservation.Status.Unknown': 'Unknown',
    'Reservation.Status.Requested': 'Waiting for Approval',
    'Reservation.Status.Approve': 'Reservation completed',
    'Reservation.Status.Confirm': 'Approval completed',
    'Reservation.Status.Denied': 'Reservation rejected',
    'Reservation.Status.Canceled': 'Cancel Reservation',

    'Reservation.Status.InUse': 'In Use',
    'Reservation.Status.Returned': 'Return completed',

    'Reservation.ConfirmType.Requested': 'Waiting for Vehicle Dispatch',
    'Reservation.ConfirmType.Confirmed': 'Vehicle Dispatch Approval',
    'Reservation.ConfirmType.Denied': 'Vehicle Dispatch Rejection',

    'Vehicle.ControlType.Open': 'Open Door (Unlock)',
    'Vehicle.ControlType.Close': 'Close Door (Lock)',
    'Vehicle.ControlType.Horn': 'Horn',
    'Vehcile.ControlType.Light': 'Emergency Light',

    'Vehicle.Colors.White': 'White',
    'Vehicle.Colors.LightGrey': 'silver',
    'Vehicle.Colors.Grey': 'grey',
    'Vehicle.Colors.Black': 'Black color',
    'Vehicle.Colors.Red': 'Red',
    'Vehicle.Colors.Blue': 'blue',

    'Vehicle.Transmission.Auto': 'Automatic',
    'Vehicle.Transmission.Manual': 'Manual',

    'Vehicle.Options.Nosmoking': 'Non-smoking',
    'Vehicle.Options.Dashcam': 'Black Box',
    'Vehicle.Options.Rearview': 'Rear Camera',
    'Vehicle.Options.Navigation': 'Navigation',

    'Vehicle.InsuranceAge.21': 'ages 21 and older',
    'Vehicle.InsuranceAge.26': 'ages 26 and older',
    'Vehicle.InsuranceDriverAge.21': 'Driver insurance for ages 21 and older',
    'Vehicle.InsuranceDriverAge.26': 'Driver insurance for ages 26 and older',

    // LICENSE_LOCATION_TYPE
    'License.Location.Seoul': 'Seoul',
    'License.Location.Busan': 'Busan',
    'License.Location.Gyeonggi': 'Gyeonggi(South)',
    'License.Location.Kangwon': 'Kangwon',
    'License.Location.Chungbuk': 'Chungbuk',
    'License.Location.Chungnam': 'Chungnam',
    'License.Location.Jeonbuk': 'Jeonbuk',
    'License.Location.Jeonnam': 'Jeonnam',
    'License.Location.Gyeongbuk': 'Gyeongbuk',
    'License.Location.Gyeongnam': 'Gyeongnam',
    'License.Location.Jeju': 'Jeju',
    'License.Location.Daegu': 'Daegu',
    'License.Location.Incheon': 'Incheon',
    'License.Location.Kwangju': 'Kwangju',
    'License.Location.Daejeon': 'Daejeon',
    'License.Location.Ulsan': 'Ulsan',
    'License.Location.NorthGyeonggi': 'Gyeonggi(North)',

    // LICENSE_TYPE
    'License.Type.Large': 'First-class (Large)',
    'License.Type.Normal': 'First-class (Ordinary)',
    'License.Type.Small': 'First-class (Small)',
    'License.Type.Trailer': 'Large trailer',
    'License.Type.Wrecker': 'Wrecker',
    'License.Type.SmallTrailer': 'Small trailer',
    'License.Type.SecondNormal': '2nd Class (Ordinary)',
    'License.Type.SecondSmall': '2nd Class (Small)',
    'License.Type.MotorizedBicycle': '2nd Class (Motorized bicycle)',

    'PenaltyAndTollPage.Objection': 'Statement of Objection',
    'PenaltyAndTollPage.LicensePlateNumber': 'Vehicle No.',
    'PenaltyAndTollPage.Violation': 'Violation Date/Time',
    'PenaltyAndTollPage.UserName': 'Customer Name',
    'PenaltyAndTollPage.MobileNumber': 'Phone No.',
    'PenaltyAndTollPage.LicenseType': 'License No.',
    'PenaltyAndTollPage.LicnConCode': 'License No.',

    'PenaltyAndTollPage.ExpirationOfLicence': 'Effective License Period',
    'PenaltyAndTollPage.RegistrationDate.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.AppealTime.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.Violation.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.ExpirationOfLicence.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.LicensePlateNumber.Placeholder': 'Enter Vehicle No.',
    'PenaltyAndTollPage.UserName.Placeholder': 'Enter Customer Name',
    'PenaltyAndTollPage.MobileNumber.Placeholder': 'Enter Phone No.',

    'PenaltyAndTollPage.ZipCode': 'Address',
    'PenaltyAndTollPage.DetailedAddress': 'Detailed Address ',
    'PenaltyAndTollPage.LicenceNumberSecond.Placeholder': 'Enter License No.',
    'PenaltyAndTollPage.RentDivision': 'Rental Category',
    'PenaltyAndTollPage.RentDivision.Placeholder': 'General',
    'PenaltyAndTollPage.RentDuration': 'Rental Period',
    'PenaltyAndTollPage.RentDuration.Placeholder': '0000.00.00',
    'PenaltyAndTollPage.BusinessName': 'Corporation Name',
    'PenaltyAndTollPage.BusinessName.Placeholder': 'Enter Corporation Name',
    'PenaltyAndTollPage.NumberOfBusiness': 'Operator No.',
    'PenaltyAndTollPage.NumberOfBusiness.Placeholder': 'Enter Operator No.',
    'PenaltyAndTollPage.Category': 'Violation Type',
    'PenaltyAndTollPage.Category.Placeholder': 'All',
    'PenaltyAndTollPage.PenaltyFee.Placeholder': 'Enter Amount',
    'PenaltyAndTollPage.PenaltyFee': 'Penalty',
    'PenaltyAndTollPage.Location.Placeholder': 'Enter Violation Location',
    'PenaltyAndTollPage.Organization.Placeholder': 'Enter Issuer Department',
    'PenaltyAndTollPage.Desc.Placeholder': 'Enter Content',
    'PenaltyAndTollPage.Desc': 'Details',
    'PenaltyAndTollPage.ProcessObjection.Placeholder': 'Yes',
    'PenaltyAndTollPage.ProcessObjection': 'Process Objection',
    'PenaltyAndTollPage.Appeal': 'Process Objection',
    'PenaltyAndTollPage.Appeal.Placeholder': 'Yes',
    'PenaltyAndTollPage.Remark.Placeholder': 'Enter Content',
    'PenaltyAndTollPage.Remark': 'Content',
    'PenaltyAndTollPage.ViolationPlace.Placeholder': 'Enter Violation Location',
    'PenaltyAndTollPage.ViolationPlace': 'Violation Location',
    'PenaltyAndTollPage.Location': 'Violation Location',
    'PenaltyAndTollPage.Organization': 'Issuer Department',
    'PenaltyAndTollPage.ProcessingDate': 'Processed Date/Time',
    'PenaltyAndTollPage.ConnectRentInformation': 'Link with Rental Information',
    'PenaltyAndTollPage.Check.RentInformation':
        'Please enter your vehicle number and the date of violation.',
    'PenaltyAndTollPage.Fail.ConnectRentInformation':
        'Rental information does not exist.',
    'PenaltyAndTollPage.Fail.Create': 'Failed to register the penalty/toll.',
    'PenaltyAndTollPage.Success.Create': 'Penalty/Toll registered.',
    'PenaltyAndTollPage.Success.Delete': 'Penalty/Toll deleted.',
    'PenaltyAndTollPage.Fail.Delete': 'Failed to delete the penalty/toll.',
    'PenaltyAndTollPage.Fail.ViolationList':
        'Failed to retrieve the penalty/toll list.',
    'PenaltyAndTollPage.Fail.Detail':
        'Failed to retrieve the details of the penalty/toll.',
    'PenaltyAndTollPage.Fail.Download': 'Failed to print.',
    'PenaltyAndTollPage.Success.Update': 'Penalty/Toll modified.',
    'PenaltyAndTollPage.Fail.Update': 'Failed to modify the penalty/toll.',
    'PenaltyAndToll.NumberOfPenalty': 'Penalty No.',
    'PenaltyAndToll.ViolationCategory': 'Violation Type',
    'PenaltyAndToll.Fee': 'Fee',
    'PenaltyAndTollPage.RegistrationDate': 'Violation Date/Time',
    'PenaltyAndToll.ViolationDate': 'Violation Date/Time',
    'PenaltyAndToll.Vehicle': 'Vehicle',
    'PenaltyAndToll.UserName': 'Customer Name',
    'PenaltyAndToll.MobileNumber': 'Phone No.',
    'PenaltyAndToll.Business': 'Operator',
    'PenaltyAndToll.NumberOfPenalty.Placeholder': 'Enter Penalty No.',
    'PenaltyAndToll.Download.PdfName': 'Statement of Objection.pdf',
    'PenaltyAndTollPage.Type.Park': 'Parking Violation',
    'PenaltyAndTollPage.Type.Sign': 'Signal Violation',
    'PenaltyAndTollPage.Type.Speed': 'Speed V​violation',
    'PenaltyAndTollPage.Yes': 'Yes',
    'PenaltyAndTollPage.No': 'No',
    'PenaltyAndTollPage.Required': 'Please enter all the fields.',
    'AppealDetail.DocID': 'Document No.',
    'AppealDetail.AppealTime': 'Execution Date',
    'AppealDetail.Organization': 'Recipient',
    'AppealDetail.Temp': 'CC',
    'AppealDetail.Remark': 'Content',
    'AppealDetail.LicensePlateNumber': 'Violation Vehicle No.',
    'AppealDetail.UserName': 'Customer Name',
    'AppealDetail.LicnCon': 'License No.',
    'AppealDetail.ViolationTime': 'Violation Date/Time',
    'AppealDetail.ReservationTime': 'Rental Period',
    'AppealDetail.Representative': 'CEO',
    'AppealDetail.Paragraph': 'Due to ',
    'AppealDetail.Paragraph2':
        'that occurred during the rental period, I submit a statement of objection as above, so please review it and transfer it to the driver.',
    'MyPage.JoinDate': 'Enrollment Date',
    'MyPage.Name': 'Customer Name',
    'MyPage.Email': 'User ID',
    'MyPage.MobileNumber': 'Phone No.',
    'MyPage.BirthDate': 'Date of Birth',
    'MyPage.ApproveMessage': 'Reason for Rejection',
    'MyPage.Info': 'Basic Information',
    'MyPage.BusinessInfo': 'Corporate Information',
    'MyPage.Business.BusinessCode': 'Company Code',
    'MyPage.Business.Name': 'Corporation Name',
    'MyPage.Business.Required': 'Please enter a valid company code.',
    'MyPage.EmployeeRegNum': 'Employee No.',
    'MyPage.TeamName': 'Team',
    'MyPage.CompanyEmail': 'Email Address',
    'MyPage.ActivationStatus': 'Status',
    'MyPage.PasswordChange': 'Change Password',
    'MyPage.OldPassword': 'Old Password',
    'MyPage.NewPassword': 'New Password',
    'MyPage.CheckNewPassword': 'Confirm New Password',
    'MyPage.PasswordInfo':
        'For a password, use 8 to 20 characters with a mixture of 3 different character types including English uppercase/lowercase letters, numbers and special characters.',
    'MyPage.Change': 'Change',
    'MyPage.UserDelete': 'Withdrawal',
    'MyPage.UserType': 'Type',
    'MyPage.Update.Fail': 'Failed to modify the information.',
    'MyPage.Update.Success': 'The information has been modified.',
    'MyPage.UpdatePwdCheck.Fail': 'Old password is wrong.',
    'MyPage.UpdatePwd.Fail': 'Failed to change the password.',
    'MyPage.UpdatePwd.Success': 'Password has been changed.',

    // 번역의뢰 WEB 0330    - en.js
    'Common.Select.Required': 'The value must be selected',
    'Common.Btn.Save': 'Save',
    'Common.Btn.NewCreate': 'New registration',
    'Common.Btn.CreateComplete': 'Registration completed',
    'Common.Btn.EditComplete': 'Modify',
    'Common.Btn.PrivateDeny': 'Corporation withdrawal',
    'Common.Btn.CarLog': 'Driving log',
    'Common.Confirm.Pay': 'Do you want to make the payment?',
    'Common.Unit.Short.Hour': 'Hour',
    'Common.Unit.Days': 'Day',
    'Common.Unit.Count': 'Count',
    'Common.Unit.Month': 'Month',
    'Main.SubTitle': 'Reservations and settlements are made easier.',
    // 'Menu.SalesAndCalculation': 'Sales/Settlement Management',
    // 'Menu.SalesManager': 'Sales/Settlement Management',
    // 'Menu.LocationManager': 'Location management',
    // 'Menu.UserManager': 'Membership Management',
    // 'Menu.BusinessManager': 'Corporation management',
    // 'Menu.BusinessCustomrList': 'Corporation customer list',
    // 'Menu.BusinessCustomerEditList': 'Edit corporation customer list',
    // 'Menu.User': 'User management',
    'Title.VehicleInfo': 'View vehicle',
    'Title.VehicleCreate': 'Vehicle registration',
    'Title.ReservationTestCarCreate': 'Test car reservation',
    'Title.TestCarConfigList': 'Set limit for test cars',
    'Title.License': 'License management',
    'Title.Code': 'Code management',
    'Title.Menu': 'Menu management',
    'Title.Role': 'Role access management',
    'Title.Program': 'Program management',
    'Title.Message': 'Message management',
    'Title.AccidentCreate': 'Registration of accident/repair',
    // 'Title.TestCarConfigList': 'Test car configuration',
    'Menu.Holiday': 'Test car management for holidays',
    'Reservation.Btn.Download': 'CSV download',
    // 'Reservation.Status.InUse': 'The vehicle cannot be returned because it is not in use.',
    'Reservation.VehicleType': 'Vehicle type',
    // 'Reservation.Insurance.Invalid':  //   'A car cannot be reserved because the car insurance has expired.',
    //   'The reservation cannot be modified because the car insurance has expired.',
    'Reservation.StartOdometer': 'Initial odometer measurement (km)',
    'Reservation.EndOdometer': 'Final odometer measurement (km) ',
    'Reservation.TotalOdometer': 'Total trip distance (km)',
    'Reservation.BusinessType': 'Select Operator',
    'Reservation.Approve.Success':
        '{success} cases have been approved out of the {total} cases with global approval.',
    'Reservation.Deny.Success':
        '{success} payments have been rejected from the lump sum of {total} payments.',
    'Reservation.Pay.Success':
        '{success} payments were successful from the lump sum of {total} payments.',
    'Reservation.Business.Name': 'Corporation Name',
    'Reservation.User.Team': 'Team',
    'Reservation.User.Name': 'Reserver Name',
    'Reservation.Use.ImgList': 'Images before and after vehicle use',
    'Reservation.Use.ImgCheck':
        'Check the images of the vehicle before and after use.',
    'Reservation.Count.AllVehicle': 'All vehicles in operation',
    'Reservation.Count.AvailableVehicle': 'Available vehicles for reservation',
    'Reservation.Count.CompletedVehicle': 'Reserved vehicle',
    'Reservation.Count.InUseVehicle': 'The vehicle is currently in use.',
    'Reservation.Count.WaitVehicle': 'Pending vehicle approval',
    'Rerservation.Btn.Pay': 'Payment',
    // 'Vehicle.Used': 'Usage status',
    // 'Vehicle.Used.Yes': 'Used',
    // 'Vehicle.Used.No': 'Unused',
    'Vehicle.LicensePlateNumber.Placeholder': 'e.g.) 00 Ha 0000.',
    'Vehicle.ControllerID.Placeholder': 'ex) 01012345678',
    'Vehicle.Type.Normal': 'Normal vehicle',
    'Vehicle.Type.TestCar': 'Test car',
    'Vehicle.VehicleType': 'Vehicle type',
    'BusinessMember.Title': 'Employee subscription information',
    'Member.Branch.Name': 'Branch',
    // 'Business.TestCar': 'Test car',
    'Accident.Type.Acc1': 'Accident caused by the customer',
    'Accident.Type.Acc2': 'Accident causing damages to customer',
    'Terminal.Telecom.Type.SKTMVNO': 'SKT MVNO ',
    'Terminal.Telecom.Type.KTMVNO': 'KT MVNO',
    'Terminal.Telecom.Type.LGUMVNO': 'LGU+ MVNO ',
    'Reservation.Status.Return': 'Return',
    'Vehicle.Colors.Green': 'Green',
    'Vehicle.Colors.Yellow': 'Yellow',
    'PenaltyAndTollPage.LicenseType.3': 'Incheon(3)',
    'PenaltyAndTollPage.LicenseType.12': 'Seoul(11)',
    'PenaltyAndTollPage.LicenseType.Placeholder': 'Seoul(11)',
    'PenaltyAndTollPage.LicnConCode.Placeholder': 'Seoul(11)',
    'TestCarConfig.Business': 'Select Operator',
    'TestCarConfig.ReservationTime':
        'Reservation period (the maximum no. of days)',
    'TestCarConfig.MaxMonth': 'Test car period (maximum no. of months)',
    'TestCarConfig.ReservationCount': 'Reservation count (per quarter)',
    'TestCarConfig.TokURL': 'URL for test car reviews',
    'TestCarConfig.Info': 'Set limit for test cars',
    'TestCarConfig.ReservationHour': 'Reservation start/end time',
    'TestCarConfig.ReservationInfo': 'Reservation information',
    'TestCarConfig.Toast.getTestCarConfig.Fail':
        'Failed to retrieve the configuration list.',
    'TestCarConfig.Toast.createTestCarConfig.Fail':
        'Failed to register the configuration.',
    'TestCarConfig.Toast.updateTestCarConfig.Success':
        'The configuration has been updated.',
    'TestCarConfig.Toast.updateTestCarConfig.Fail':
        'Failed to update the configuration.',
    'Program.Name': 'Program name',
    // 'Program.Method': 'Action',
    'Program.Action': 'Action',
    'Program.Confirm.MultiDelete': 'Do you want to delete {count} programs?',
    'Program.View.Fail': 'Cannot retrieve program information.',
    'Program.Delete.Success': 'The program has been deleted.',
    'Program.Delete.Fail': 'Failed to delete the program.',
    'Program.Create.Success': 'The program has been registered.',
    'Program.Create.Fail': 'Failed to register the program.',
    'Program.Action.Inquiry': 'Read',
    'Program.Action.Save': 'Write',
    'Program.Action.Delete': 'Delete',
    'Program.Action.Download': 'Download',
    'Program.Name.Placeholder': 'Enter the program name.',
    'Program.Apiurl.Placeholder': 'Enter the API URL.',
    'Message.ResourceKey': 'Key',
    'Message.Message': 'Message',
    'Message.Confirm.MultiDelete': 'Do you want to delete {count} messages?',
    'Message.View.Fail': 'Cannot retrieve message information.',
    'Message.Delete.Success': 'The message has been deleted.',
    'Message.Delete.Fail': 'Failed to delete the message.',
    'Message.Create.Success': 'The message has been created.',
    'Message.Create.Fail': 'Failed to create the message.',
    'Message.Update.Success': 'The message has been updated.',
    'Message.Update.Fail': 'Failed to update the message.',
    'Message.Key.Placeholder': 'Enter the key value.',
    'Message.Message.Placeholder': 'Enter a message.',
    'Message.Confirm.Delete': 'Do you want to delete the message?',
    'Code.RootName': 'Code management',
    'Code.SelectCode': 'Select the code',
    'Code.Type': 'Type',
    'Code.Type.Group': 'Code group',
    'Code.Type.Value': 'Code item',
    'Code.Name': 'Code name',
    'Code.GroupKey': 'Group key',
    'Code.CodeValue': 'Code value',
    'Code.OrderNum': 'Priority',
    'Code.Use': 'Display status',
    'Code.Use.false': 'Hide',
    'Code.Use.true': 'Display',
    'Code.Name.Placeholder': 'Enter the code name.',
    'Code.MsgKey.Placeholder': 'Enter the message key code.',
    'Code.GroupKey.Placeholder': 'Enter a group key value.',
    'Code.CodeValue.Placeholder': 'Enter the code value.',
    'Code.OrderNum.Placeholder': 'Enter a priority value.',
    'Code.View.Fail': 'Cannot retrieve the code information.',
    'Code.Confirm.Delete': 'Do you want to delete the code?',
    'Code.Create.Success': 'The code has been registered.',
    'Code.Create.Fail': 'Failed to register the code.',
    'Code.Update.Success': 'The code has been updated.',
    'Code.Update.Fail': 'Failed to update the code.',
    'Code.Delete.Success': 'Code deleted.',
    'Code.Delete.Fail': 'Failed to delete the code.',
    'Menu.SelectCode': 'Select a menu',
    'Menu.Name': 'Menu name',
    'Menu.MenuUrl': 'Menu path',
    'Menu.OrderNum': 'Priority',
    'Menu.Use': 'Display status',
    'Menu.Use.false': 'Hide',
    'Menu.Use.true': 'Display',
    'Menu.Acl': 'Authority status',
    'Menu.Acl.true': 'Used',
    'Menu.Acl.false': 'Not in use',
    'Menu.Confirm.Delete': 'Do you want to delete the menu?',
    'Menu.Menu.Placeholder': 'Enter the menu name.',
    'Menu.Path.Placeholder': 'Enter the menu path.',
    'Menu.Order.Placeholder': 'Set the priority.',
    'Menu.View.Fail': 'Cannot retrieve menu information.',
    'Menu.Delete.Success': 'The menu has been deleted.',
    'Menu.Delete.Fail': 'Failed to delete the menu.',
    'Menu.Create.Success': 'The menu has been registered.',
    'Menu.Create.Fail': 'Failed to register the menu.',
    'Menu.Update.Success': 'The menu has been updated.',
    'Menu.Update.Fail': 'Failed to update the menu.',
    'Menu.Program.View.Fail': 'Cannot retrieve program information.',
    'Menu.Program.Delete.Success': 'The program has been deleted.',
    'Menu.Program.Delete.Fail': 'Failed to delete the program.',
    'Menu.Program.Create.Success': 'The program has been registered.',
    'Menu.Program.Create.Fail': 'Failed to register the program.',
    'Role.Select.Placeholder': 'Select a role.',
    'Role.Name': 'Role name',
    'Role.RoleKey': 'Role key',
    'Role.Note': 'Note on the role',
    'Role.Name.Placeholder': 'Enter a role name',
    'Role.RoleKey.Placeholder': 'Enter a role key.',
    'Role.Note.Placeholder': 'Enter a note on the role.',
    'Role.Acl.Title': 'Access authority',
    'Role.Admin.Title': 'Manager',
    'Role.View.Fail': 'Cannot retrieve role information.',
    'Role.Delete.Success': 'The role was deleted.',
    'Role.Delete.Fail': 'Failed to delete the role.',
    'Role.Create.Success': 'The role has been registered.',
    'Role.Create.Fail': 'Cannot use a duplicated role key.',
    'Role.Update.Success': 'The role was updated.',
    'Role.Update.Fail': 'Cannot use a duplicated role key.',
    'Role.Acl.View.Fail': 'Cannot retrieve the access authority information.',
    'Role.Acl.Update.Success': 'The access authority has been updated.',
    'Role.Acl.Update.Fail': 'Failed to update the access authority.',
    'Role.Admin.View.Fail': 'Failed to view administrator information.',
    'Role.Confirm.Delete': 'Do you want to delete the role?',
    'Role.Menu': 'Menu name',
    'Role.Read': 'Read',
    'Role.Write': 'Write',
    'Role.Delete': 'Delete',
    'Role.Download': 'Download',
    'Role.Admin.Name': 'Name',
    'Role.Admin.UserID': 'User ID',
    'Role.Admin.MobileNumber': 'Phone No.',
    'License.Confirm.MultiDelete':
        'Do you want to delete {count} license managements?',
    'License.View.Fail': 'Cannot view the license management information.',
    'License.Confirm.Delete': 'Do you want to delete the license management?',
    'License.Delete.Success': 'The license manamgent has been deleted.',
    'License.Delete.Fail': 'Failed to delete the license management.',
    'License.Create.Success': 'The license management has been registered.',
    'License.Create.Fail': 'Failed to register the license management.',
    'License.Update.Success': 'The license management has been updated.',
    'License.Update.Fail': 'Failed to update the license management.',
    'License.View.Menu.Fail':
        'Cannot view the license management access authority information.',
    'License.Update.Menu.Fail':
        'Failed to update the license management access authority.',
    'License.View.Business.Fail':
        'Cannot view the license managing business information.',
    'License.Name': 'License name',
    'License.Use': 'Usage status',
    'License.Note': 'Note',
    'License.BusienssCnt': 'Subscription count',
    'License.UsageFee': 'Usage fee / month',
    'License.Use.false': 'Not in use',
    'License.Use.true': 'Used',
    'License.Menu.Title': 'Access authority',
    'License.Business.Title': 'Operator',
    'License.Business.Name': 'Operator Name',
    'License.Business.Date': 'License date',
    'License.Name.Placeholder': 'Enter the license name.',
    'License.UseFee.Placeholder': 'Enter the usage fee.',
    'License.Note.Placeholder': 'Enter a note.',
    'CompanyUser.CompanyId': 'Company name',
    'CompanyUser.ManagerName': "Manager's name",
    'CompanyUser.PhoneNumber': 'Phone No.',
    'CompanyUser.FaxNumber': 'Fax no.',
    'CompanyUser.ManagerMobileNumber': "Managers' mobile no.",
    'CompanyUser.ManagerEmail': "Manager's email address",
    'CompanyUser.Get.Fail': 'Company manager information is unavailable.',
    'Title.CompanyUser': 'Company manager',
    'CompanyUser.Title.Create': 'Register the company manager',
    'CompanyUser.CompanyID.Placeholder': 'Select a company ID.',
    'CompanyUser.ManagerName.Placeholder': "Enter the company manager's name.",
    'CompanyUser.PhoneNumber.Placeholder':
        "Enter the company manager's phone no.",
    'CompanyUser.FaxNumber.Placeholder':
        'Enter the fax no. of the company manager.',
    'CompanyUser.ManagerMobileNumber.Placeholder':
        "Enter the company manager's phone no.",
    'CompanyUser.ManagerEmail.Placeholder':
        "Enter the company manager's email address.",
    'CompanyUser.Filter.CompanyID': 'Company ID',
    'CompanyUser.Filter.ManagerName': "Manager's name",
    'CompanyUser.Filter.ManagerMobileNumber': "Managers' mobile no.",
    'Company.Name': 'Company name',
    'Company.CompanyBusiness': "Company's business status",
    'Company.CompanyType': 'Company type',
    'Company.PhoneNumber': 'Phone No.',
    'Company.FaxNumber': 'FAX no.',
    'Company.Address': 'Address',
    'Company.BusinessRegNum': 'Operator no.',
    'Company.CeoName': 'Name',
    'Company.Homepage': 'Website',
    'Company.RepBusinessType': 'Main business type',
    'Company.BusinessType': 'Business type',
    // 'Company.BusinessId': 'Corporation ID',
    // 'Company.BranchId': 'Branch ID',
    'Company.BusinessId': 'Corporation Name',
    'Company.BranchId': 'Branch',
    'COMPANY.Type.Mechanic': 'Maintenance shop',
    'COMPANY.Type.Insurance': 'Insurance Company',
    'Company.Title.Create': 'Register a company',
    'Title.Company': 'Company',
    'Company.Name.Placeholder': 'Enter the company name.',
    'Company.CompanyType.Placeholder': 'Select company type.',
    'Company.PhoneNumber.Placeholder': 'ex) 0101234567',
    'Company.FaxNumber.Placeholder': 'ex) 0101234567',
    'Company.BusinessRegNum.Placeholder': 'Enter the operator no.',
    'Company.CeoName.Placeholder': "Enter the CEO/Branch manager's name.",
    'Company.Homepage.Placeholder': 'Enter the website.',
    'Company.CompanyBusiness.Placeholder':
        "Enter your company's business status.",
    'Company.RepBusinessType.Placeholder': 'Enter the business type.',
    'Company.BusinessType.Placeholder': 'Enter a business type.',
    // 'Company.BusinessId.Placeholder': 'Enter the corporation ID.',
    // 'Company.BranchId.Placeholder': 'Enter the branch ID.',
    'Company.BusinessId.Placeholder': 'Select a corporation.',
    'Company.BranchId.Placeholder': 'Select a branch.',
    'Company.Address.Address.Placeholder': 'Please search for a postal code.',
    'Company.Address.DetailedAddress.Placeholder':
        'Please enter the street address.',
    'TestCarConfig.Toast.createTestCarConfig.Success':
        'The configuration has been registered.',
    'TestCarConfig.Toast.ReservationHour.Fail':
        "The reservation's start time is equal to or greater than the end time.",
    'Holiday.Create.Info': 'Registration of a holiday',
    'Holiday.HolidayName': 'Holiday name',
    'Holiday.Date': 'Date',
    'Holiday.Toast.getHolidayList.Fail': 'Failed to retrieve the holiday list.',
    'Holiday.Toast.getHolidayDetail.Fail':
        'Failed to retrieve the holiday details.',
    'Holiday.Toast.createHoliday.Success': 'The holiday has been registered.',
    'Holiday.Toast.createHoliday.Fail': 'Failed to register a holiday.',
    'Holiday.Toast.updateHoliday.Success': 'The holiday has been updated.',
    'Holiday.Toast.updateHoliday.Fail': 'Failed to update the holiday.',
    'Holiday.Toast.deleteHoliday.Success': 'The holiday has been deleted.',
    'Holiday.Toast.deleteHoliday.Fail': 'Failed to delete the holiday.',
    'Menu.Accident': 'Accident/repair information',
    // 'Title.AccidentCreate': 'Registration of accident/repair',
    'Title.AccidentDetail': 'Accident/repair information',
    'Accident.Btn.Create': 'Accident registration',
    'Accident.Date': 'Accident date',
    'Accident.Location': 'Branch',
    'Accident.Vehicle.Information': 'Vehicle Information',
    'Accident.Vehicle.LicensePlateNum': 'Vehicle No.',
    'Accident.RepairType': 'Accident type',
    'Accident.Status': 'Status',
    'Accident.Id': 'Filing no.',
    'Accident.Company': 'Company',
    'Accident.Status.Occur': 'Accident occurrence',
    'Accident.Status.Requested': 'Repair requested',
    'Accident.Status.Repairing': 'Repairing',
    'Accident.Status.Repaired': 'Repaired',
    'Accident.Status.Unknown': 'Unknown',
    'Accident.Filter.LicensePlateNumber': 'Vehicle No.',
    'Accident.Filter.RepairType': 'Accident type',
    'Accident.Filter.VehicleModel': 'Vehicle Model',
    'Accident.Filter.Location': 'Branch',
    'Accident.Filter.Id': 'Filing no.',
    'Accident.Filter.Company': 'Company',
    'Accident.VehicleInfo': 'Vehicle Information',
    'Accident.ReservationInfo': 'Rental information',
    'Accident.RepairInfo': 'Repair details',
    'Accident.CostInfo': 'Cost processing',
    'Accident.InsuranceInfo': 'Insurance company of the other party',
    'Accident.Get.Fail': 'Cannot retrieve the accident/repair information.',
    'Accident.Create.Succes': 'Accident registered successfully.',
    'Accident.Create.Fail': 'Accident registration failed.',
    'AccidentVehicle.LicensePlateNum': 'Vehicle No.',
    'AccidentVehicle.Company': 'Company',
    'AccidentVehicle.AccidentDate': 'Accident date',
    'AccidentVehicle.Location': 'Branch',
    'AccidentVehicle.AccidentStatus': 'Status',
    'AccidentVehicle.AccidentType': 'Accident type',
    'AccidentVehicle.Search.Vehicle': 'Search for Vehicle',
    'AccidentVehicle.Search.Company': 'Search a company',
    'AccidentVehicle.AccidentDate.DayPicker': 'Accident date',
    'AccidentVehicle.Repair': 'Date of repair',
    'AccidentVehicle.Repair.Start': 'Repair start date',
    'AccidentVehicle.Repair.End': 'Repair end date',
    'AccidentReservation.ReservationCode': 'Reservation code',
    'AccidentReservation.ReservationDate': 'Rental period',
    'AccidentReservation.CustomerName': 'Customer Name',
    'AccidentReservation.LicenseType': 'License Type',
    'AccidentReservation.LicenseNumber': "Driver's license no.",
    'AccidentReservation.Tel': 'Phone no.',
    'AccidentReservation.Search.ReservationInfo': 'Rental information link',
    'AccidentRepair.RepairDetail': 'Repair details',
    'AccidentRepair.RepairBefore': 'Before repair',
    'AccidentRepair.RepairAfter': 'After repair',
    'Accident.RepairBefore.Placeholder': 'Enter the items before repair.',
    'Accident.RepairAfter.Placeholder': 'Enter the items after repair.',
    'AccidentInsurance.InsuranceCompany':
        'Insurance company of the other party',
    'AccidentInsurance.InsuranceCode':
        "Filing no. of the other party's insurance",
    'AccidentInsurance.InsuranceManager': 'Manager',
    'AccidentInsurance.InsuranceManagerTel': "Manager's phone no.",
    'AccidentCost.InsuranceTreatement': 'Insurance treatment',
    'AccidentCost.Company': 'Insurance Company',
    'AccidentCost.InsuranceCode': 'Filing no.',
    'AccidentCost.OpponentInjury': 'For person',
    'AccidentCost.OpponentVehicle': 'For objects',
    'AccidentCost.OwnVehicle': 'Collision coverage',
    'AccidentCost.OwnInjury': 'Self-inflicted damage',
    'AccidentCost.Other': 'Other',
    'AccidentCost.TotalInsuranceCost': 'Total insurance cost',
    'AccidentCost.CompanyTreatment': 'Company treatment',
    'AccidentCost.InsuranceCompanyCharge': 'Insurance company deductible',
    'AccidentCost.TotalCompanyCost': 'Total company cost',
    'AccidentCost.AmountOfAccident': 'Amount collected',
    'AccidentCost.IndemnityCost': 'Indemnity cost',
    'AccidentCost.CompensationFee': 'Compensation fee',
    'AccidentCost.Unrepaired': 'Not repaired',
    'AccidentCost.TotalCollectCost': 'Total amount collected',
    'AccidentCost.TotalCost': 'Total',
    'AccidentCost.Status': 'Payment status',
    'AccidentCost.PaymentMethod': 'Payment method',
    'AccidentBusiness.BusinessType.Placeholder': 'Select Operator',
    'Accident.GetVehicle.Fail': 'Failed to retrieve the vehicle list.',
    'Accident.GetBusiness.Fail': 'Failed to retrieve the operator list.',
    'Accident.ReservationCheckParam.Fail':
        'Enter the vehicle no. and the date of the accident.',
    'Accident.GetReservationCheck.Fail':
        'Failed to link the rental information.',
    'Accident.Fail.ConnectRentInformation':
        'Rental information does not exist.',
    'Accident.Delete.Success': 'The accident details have been deleted.',
    'Accident.Edit.Success': 'The accident details have been edited.',
    'Accident.Create.Success': 'The accident details have been registered.',
    'Accident.Delete.Fail': 'Failed to delete the accident details.',
    'Accident.Edit.Fail': 'Failed to modify the accident details.',
    'Accident.Confirm': 'Delete accident details',
    'Accident.Confirm.Delete': 'Do you want to delete the accident details?',
    'Accident.Confirm.Edit': 'Do you want to edit the accident details?',
    'Accident.Confirm.Create': 'Do you want to register accident details?',
    'Menu.Branch': 'Branch',
    'Title.Branch': 'Branch',
    'Branch.Name': 'Branch name',
    'Branch.BusinessRegNum': 'Operator no.',
    'Branch.PhoneNumber': 'Phone No.',
    'Branch.FaxNumber': 'Fax no.',
    'Branch.Address': 'Address',
    'Branch.ManagerName': 'Name',
    'Branch.ManagerPhoneNumber': 'Phone No.',
    'Branch.ManagerEmail': 'Email Address',
    'Branch.Title.Create': 'Branch registration',
    'Branch.Company': 'Branch',
    'Branch.BusinessID': 'Corporation Name',
    'Branch.BusinessID.Placeholder': 'Enter corporation name',
    'Branch.Name.Placeholder': 'Enter the branch name.',
    'Branch.BusinessRegNum.Placeholder': 'Enter the operator no.',
    'Branch.PhoneNumber.Placeholder': 'Enter a phone no.',
    'Branch.FaxNumber.Placeholder': 'Enter a fax no.',
    'Branch.ManagerName.Placeholder': "Enter the CEO's name.",
    'Branch.ManagerPhoneNumber.Placeholder':
        "Enter the CEO/Branch manager's phone no.",
    'Branch.ManagerEmail.Placeholder':
        "Enter the CEO/branch manager's email address.",
    'Branch.Company.Placeholder': 'Enter a branch.',
    'Branch.Address.Address.Placeholder': 'Please search for a postal code.',
    'Branch.Address.DetailedAddress.Placeholder':
        'Please enter the street address.',
    'Accident.PaymentStatus.Unpaid': 'Unpaid',
    'Accident.PaymentStatus.Paid': 'Paid',
    'Accident.PaymentType.Card': 'Card',
    'Accident.PaymentType.Cash': 'Cash',
    'Accident.GetBranch.Fail': 'Failed to retrieve the branch list.',
    'Accident.GetCompany.Fail': 'Failed to load the list of companies.',
    'Code.MsgKey': 'Code key',
    'Menu.VehicleInsurance': 'Insurance management',
    'VehicleInsurance.Get.Fail': 'Failed to retrieve insurance management.',
    'VehicleInsurance.LicensePlateNumber': 'Vehicle No.',
    'VehicleInsurance.InsureYear': 'Nth year',
    'VehicleInsurance.InsureCondition': 'Insured condition',
    'VehicleInsurance.VehicleGrade': 'Vehicle grade',
    'VehicleInsurance.VehicleModelName': 'Vehicle model name',
    'VehicleInsurance.CompanyName': 'Insurance company',
    'VehicleInsurance.StartDate': 'Insurance start date',
    'VehicleInsurance.EndDate': 'Insurance expiration date',
    'VehicleInsurance.Filter.LicensePlateNumber': 'Vehicle No.',
    'VehicleInsurance.Filter.CompanyName': 'Insurance company',
    'Title.VehicleInsurance': 'Vehicle insurance management',
    'VehicleInsurance.VehicleInformation': 'Vehicle Information',
    'VehicleInsurance.InsuranceInformation': 'Insurance information',
    'VehicleInsurance.insureSelfVehicle': 'Collision coverage insured',
    'VehicleInsurance.PaymentDueDay': 'Payment date',
    'VehicleInsurance.InsurePayment': 'Insurance paid',
    'VehicleInsurance.paymentMethod': 'Payment method',
    'VehicleInsurance.insureSelfVehicle.true': 'Yes',
    'VehicleInsurance.insureSelfVehicle.false': 'No',
    'VehicleInsurance.paymentMethod.true': 'Paid in full',
    'VehicleInsurance.paymentMethod.false': 'Monthly installment',
    'VehicleInsurance.MonthPer': 'Number of installments',
    'VehicleInsurance.MonthPerPayment': 'Monthly payment',
    'VehicleInsurance.VehiclePremium': 'Insurance premium information',
    'VehicleInsurance.insureOrder': 'Sequence',
    'VehicleInsurance.paymentDate': 'Payment date',
    'VehicleInsurance.monthPerPayment': 'Monthly payment',
    'VehicleInsurance.delete': 'Delete',
    'Menu.MsgKey': 'Menu key',
    'Menu.MsgKey.Placeholder': 'Enter a menu key',
    'Title.ShortRental': 'Short rental',
    'ShortRental.Type': 'Rental Category',
    'ShortRental.Type.Public': 'Rental cars',
    'ShortRental.Type.Private': 'Corporation',
    'ShortRental.Type.Rent': 'Register a short-term rental',
    'ShortRental.Type.Reservation': 'Short rental reservation',
    'ShortRental.RentalInfo': 'Car rental and vehicle information',
    'ShortRental.Duration': 'Rental Period',
    'ShortRental.Duration.Value': '{days} days {hours} hours',
    'ShortRental.Insurance.Type': 'Insurance type',
    'ShortRental.Insurance.Age.Placeholder': 'Select the insurance age.',
    'ShortRental.Insurance.Type.Placeholder': 'Select an insurance type.',
    'Reservation.EndLocation': 'Return location',
    'Reservation.EndLocation.Placeholder': 'Select a return location.',
    'Common.Deletes.Fail': 'Some items cannot be deleted. Check the data.',
    'Reservation.OwnVehicle': 'Status of the collision coverage insurance',
    'Reservation.CarInfo': 'Vehicle Information',
    'Reservation.SelectCar': 'Vehicle selection',
    'Reservation.CarNumber': 'Vehicle No.',
    'Reservation.CarModel': 'Vehicle Model',
    'Reservation.CarColor': 'Vehicle Color',
    'Reservation.CarFuel': 'Fuel Type',
    'Reservation.CarRate': 'Vehicle Class',
    'Reservation.Agent': 'Person in charge',
    'Reservation.RentAgent': 'Rental agent',
    'Reservation.ReturnAgent': 'Return agent',
    'Reservation.Agent.Placeholder': 'Select an employee.',
    'Reservation.Join': 'Join',
    'Reservation.NotJoin': 'Uninsured',
    'Reservation.Indemnity.title': 'Indemnity cost information',
    'Reservation.Run.title': 'Fuel and trip distance',
    'Reservation.Run.Fuel': 'Fuel level',
    'Reservation.Run.Run': 'Trip distance',
    'Reservation.Run.Rental': 'Rental',
    'Reservation.Run.Return': 'Return',
    'ShortRental.OwnVehicle.Type.Placeholder': 'Select the subscription type.',
    'ShortRental.User.Public': 'Corporation',
    'ShortRental.User.Private': 'Private user',
    'ShortRental.User.SelectBusiness': 'Find an existing corporation',
    'ShortRental.User.Name.Error': 'Enter a name.',
    'ShortRental.User.ZipCode.Error': 'Enter a zip code.',
    'ShortRental.User.SelectUser': 'Find an existing member',
    'ShortRental.User.SelectEmployee': 'Find employee',
    'ShortRental.User.Contact': 'User contact details',
    'ShortRental.User.Contact.Placeholder': 'Enter your contact details.',
    'ShortRental.User.Contact.Error': 'Enter your contact details.',
    'ShortRental.User.Address.Error': 'Enter the address.',
    'ShortRental.User.Email': 'Email address',
    'ShortRental.User.Email.Placeholder': 'Please enter the email address.',
    'ShortRental.User.Fax': 'Fax',
    'ShortRental.User.Fax.Placeholder': 'Enter a fax no.',
    'ShortRental.User.BusinessRegNum.Error': 'Enter the operator no.',
    'ShortRental.User.DetailAddress.Error': 'Enter the detailed address.',
    'ShortRental.User.FirstDriver': 'First driver',
    'ShortRental.User.SecondDriver': 'Second driver',
    'ShortRental.Charge.Title': 'Rate information',
    'ShortRental.Charge.Discount.title': 'Discount information',
    'ShortRental.Charge.Default': 'Default fee',
    'ShortRental.Charge.Discount': 'Discounted rate',
    'ShortRental.Charge.Rental': 'Rental Fee',
    'ShortRental.Charge.Real': 'Rental fee',
    'ShortRental.Charge.Additional': 'Additional fee',
    'ShortRental.Charge.Etc': 'Other than insurance premium',
    'ShortRental.Charge.Sum': 'Total rental fee',
    'ShortRental.Etc.Special': 'Special issues',
    'ShortRental.Etc.Memo': 'Memo',
    'ShortRental.Car.Select': 'There are no available vehicles to select.',
    'ShortRental.DueDate.Empty': 'Enter the time and date of rental.',
    'Title.LongRental': 'Long-term rental of a new car',
    'LongRental.Type': 'Rental Category',
    'LongRental.Type.Estimate':
        'Estimate for long-term rental fees for a new car',
    'LongRental.Type.Create': 'Register long-term rental for a new car',
    'LongRental.ChargeInfo': 'Rate information',
    'LongRental.RentalDeposit': 'Rental deposit',
    'LongRental.ExtraCharge': 'Extra fee',
    'LongRental.ExtraItem': 'Extra item',
    'LongRental.MonthlyCharge.Title': 'Monthly fee',
    'LongRental.MonthlyCharge': 'Rental Fee',
    'LongRental.MonthlyTotal': 'Total',
    'LongRental.ContractNumber': 'Contract no.',
    'LongRental.ContractStatus': 'Quotation status',
    'LongRental.Info.Repair': 'Inclusion of maintenance',
    'LongRental.Repair.Include': 'Include',
    'LongRental.Repair.NonInclude': 'Not included',
    'LongRental.Payment.first': 'First payment',
    'LongRental.Payment.Final': 'Final payment',
    'LongRental.Payment.Day': 'Payment date',
    'LongRental.Payment.Charge': 'Payment amount',
    'LongRental.Payment.Month': 'Date of monthly payment',
    'LongRental.Payment.Beginning': 'First payment date',
    'LongRental.CommonDriverInfo': 'Common driver information',
    'LongRental.User.DriverName': 'Co-driver',
    'LongRental.User.DriverName.Placeholder': "Enter the common driver's name.",
    'LongRental.User.Contact': 'User contact details',
    'LongRental.User.Contact.Placeholder': 'Enter your contact details.',
    'LongRental.User.Birth': 'Date of Birth',
    'LongRental.User.Birth.Placeholder':
        'The first six digits of the resident registration no. e.g., 990126',
    'LongRental.User.DriverLicense': "Driver's License",
    'LongRental.Kind.DriverLicense.Placeholder': 'Select a license type.',
    'Title.MonthRental': 'Monthly long-term rental',
    'MonthRental.Type': 'Rental Category',
    'MonthRental.Type.Reservation': 'Reserve a monthly long-term rental',
    'MonthRental.Type.Create': 'Register a monthly long-term rental',
    'MonthRental.ReservationNumber': 'Reservation No.',
    'MonthRental.ReservationStatus': 'Reservation Status',
    'Rent.RentalDueDate': 'Estimated rental date',
    'MonthRental.ExtraCharge': 'Additional fee',
    'Menu.Vehicle': 'Vehicle management',
    'Menu.Maintenance': 'Vehicle maintenance management',
    'Menu.Operation': 'Service operation',
    'Menu.Vehicle.Insurance': 'Insurance payment management',
    'Menu.Rent': 'Rental management',
    'Menu.Payment': 'Sales/settlement management',
    'Menu.Work': 'MOCEAN work management',
    'Menu.System': 'System management',
    'Common.Get.Fail': 'Failed to retrieve the data.',
    'Menu.History': 'History',
    'Title.ControlHistory': 'Vehicle control history',
    'Title.ControlHistory.Request': 'Request details',
    'Title.ControlHistory.Response': 'Response details',
    'ControlHistory.Filter.LicensePlateNumber': 'Vehicle No.',
    'ControlHistory.Filter.UserName': 'Name',
    'ControlHistory.HistoryTime': 'Time and date of occurrence',
    'ControlHistory.UserName': 'Name',
    'ControlHistory.LicensePlateNumber': 'Vehicle No.',
    'ControlHistory.ReqTime': 'Time of request',
    'ControlHistory.ResTime': 'Response time',
    'ControlHistory.Action': 'Action',
    'ControlHistory.ReqResultMessage': 'Result of request',
    'ControlHistory.ResResultCode': 'Response code',
    'ControlHistory.ResData': 'Response data',
    'ControlHistory.ReqData': 'Requested data',
    Vehicle: 'Vehicle',
    'Vehicle.Tab.Detail': 'Vehicle detail',
    'Vehicle.Tab.Purchase': 'Purchase information',
    'Vehicle.Tab.Accident': 'Accident/repair',
    'Vehicle.PurchaseMethod': 'Purchase method',
    'Vehicle.PurchaseCost': 'Purchase cost',
    'Vehicle.Purchase.BasicCost': 'Basic vehicle cost',
    'Vehicle.Purchase.BasicCost.Placeholder': 'Basic vehicle cost',
    'Vehicle.Purchase.SelectCost': 'Options',
    'Vehicle.Purchase.SelectCost.Placeholder': 'Options',
    'Vehicle.Purchase.Consignment': 'Consignment fee',
    'Vehicle.Purchase.Consignment.Placeholder': 'Consignment fee',
    'Vehicle.Purchase.PureVehiclePrice': 'Actual vehicle price',
    'Vehicle.Purchase.PureVehiclePrice.Placeholder': 'Actual vehicle price',
    'Vehicle.Purchase.TotalCost': 'Total purchase cost',
    'Vehicle.Pay.DownPayment': 'Down payment',
    'Vehicle.Pay.DownPayment.Placeholder': 'Down payment',
    'Vehicle.Pay.DeliveryPayment': 'Delivery payment',
    'Vehicle.Pay.DeliveryPayment.Placeholder': 'Delivery payment',
    'Vehicle.Pay.TotalLeasePayment': 'Total amount of installment',
    'Vehicle.Pay.TotalLeasePayment.Placeholder': 'Total amount of installment',
    'Vehicle.PayInfo': 'Payment terms',
    'Vehicle.AdditionalFee': 'Additional cost',
    'Vehicle.Additional.MandatoryPremium': 'Mandatory premium',
    'Vehicle.Additional.MandatoryPremium.Placeholder': 'Mandatory premium',
    'Vehicle.Additional.StampPrice': 'Stamp price',
    'Vehicle.Additional.StampPrice.Placeholder': 'Stamp price',
    'Vehicle.Additional.EtcPrice': 'Other costs',
    'Vehicle.Additional.EtcPrice.Placeholder': 'Other costs',
    'Vehicle.Additional.TotalCost': 'Total additional cost',
    'Vehicle.RegistrationFee': 'Vehicle registration fee',
    'Vehicle.Registration.TotalCost': 'Total registration fee',
    'Vehicle.Registration.AcquisitionTax': 'Acquisition tax',
    'Vehicle.Registration.BoundDiscount': 'Deducted discount',
    'Vehicle.Registration.RegistrationStampPrice': 'Stamp price',
    'Vehicle.Registration.LicensePlateNumberPrice': 'License plate price',
    'Vehicle.Registration.RegistrationFee': 'Registration agency fees',
    'Vehicle.Registration.AcquisitionTax.Placeholder': 'Acquisition tax',
    'Vehicle.Registration.BoundDiscount.Placeholder': 'Deducted discount',
    'Vehicle.Registration.RegistrationStampPrice.Placeholder': 'Stamp price',
    'Vehicle.Registration.LicensePlateNumberPrice.Placeholder':
        'License plate price',
    'Vehicle.Registration.RegistrationFee.Placeholder':
        'Registration agency fees',
    'Vehicle.TotalFee': 'Total purchase cost',
    'Vehicle.Available': 'Check available vehicles',
    'Title.LicenseCheckHistory': "Driver's license verification history",
    'LicenseCheckHistory.Filter.UserID': 'User ID',
    'LicenseCheckHistory.Filter.PhoneNumber': 'Phone No.',
    'LicenseCheckHistory.Filter.ResidentName': "Driver's name",
    'LicenseCheckHistory.Filter.ResidentDate': "Driver's date of birth",
    'LicenseCheckHistory.Filter.ResponseCode': 'Response code',
    'LicenseCheckHistory.LicenseNo': 'Driver license no.',
    'LicenseCheckHistory.LicnConCode': 'Driver license type',
    'LicenseCheckHistory.UserID': 'User ID',
    'LicenseCheckHistory.PhoneNumber': 'Phone No.',
    'LicenseCheckHistory.ResidentName': "Driver's name",
    'LicenseCheckHistory.ResidentDate': "Driver's date of birth",
    'LicenseCheckHistory.ResponseCode': 'Response result',
    'LicenseCheckHistory.ResponseMessage': 'Response message',
    'LicenseCheckHistory.Date': 'Rental hours',
    'LicenseCheckHistory.StartDate': 'Rental start time',
    'LicenseCheckHistory.EndDate': 'Rental end time',
    'LicenseCheckHistory.CreatedAt': 'Time of request',
    'Title.RentalSearch': 'Search available rental vehicles',
    'RentalSearch.Rental.Day': 'Rental date',
    'RentalSearch.Rental.UseCarYear': 'Model year',
    'RentalSearch.Rental.UseCarFuel': 'Fuel in use',
    'RentalSearch.CarFuel': 'Fuel',
    'RentalSearch.CarLocation': 'Car location',
    'RentalSearch.Title.RentalDetail': 'Vehicle details',
    'RentalSearch.CarAnsic': 'Model year',
    'RentalSearch.FixedPeople': 'No. of passengers',
    'RentalSearch.CarInsurance': 'Car rental insurance',
    'Business.Extra.Service': 'Extra service',
    'Business.TestCar': 'Test car',
    'Common.Btn.Update': 'Modify',
    'Terminal.Title.State': 'Status',
    'Common.Btn.DetailAndUpdate': 'Details/updates',
    'Customer.Title.Private': 'Personal information',
    'Title.Branch.Info': 'Basic branch information',
    'Vehicle.BasicInfo.CarNumber': 'Car no.',
    'Vehicle.BasicInfo.TerminalNumber': 'Terminal no.',
    'Vehicle.BasicInfo.UseCarYear': 'Model year',
    'Vehicle.BasicInfo.Transmission': 'Transmission',
    'Vehicle.BasicInfo.CreatedDate': 'Vehicle Registration Date',
    'Vehicle.BasicInfo.InsuranceAge': 'Insurance Age',
    'Vehicle.BasicInfo.Option': ' Option',
    'Vehicle.BasicInfo.BusinessName': 'Corporation Name',
    'Vehicle.BasicInfo.Location': 'Base',
    'Vehicle.BasicInfo.Color': 'Color',
    'Vehicle.BasicInfo.FuelType': 'Fuel Type',
    'Vehicle.BasicInfo.CarType': 'Vehicle Class',
    'Vehicle.BasicInfo': 'Basic Information',
    'Vehicle.BasicInfo.AvailableDistance': 'Available distance',
    'Vehicle.BasicInfo.TotalDistance': 'Total trip distance',
    'Vehicle.BasicInfo.AuxBattery': 'AUX battery',
    'Vehicle.BasicInfo.TempFuelUsage': 'Temporary fuel usage',
    'Vehicle.BasicInfo.FrontLeft': 'Front Wheel/Left',
    'Vehicle.BasicInfo.FrontRight': 'Front Wheel/Right',
    'Vehicle.BasicInfo.BackLeft': 'Rear Wheel/Left',
    'Vehicle.BasicInfo.BackRight': 'Rear Wheel/Right',
    'Vehicle.BasicInfo.DoorOpen': 'Unlock Door',
    'Vehicle.BasicInfo.DoorClose': 'Close door',
    'Vehicle.BasicInfo.Siren': 'Emergency Light/Horn',
    'Common.Btn.UpdateVehicle': 'Modification of vehicle information',
    'Vehicle.BasicInfo.Type': 'Type',
    'Title.Company.Info': 'Company information',
    'Common.Btn.Setup': 'Settings',
    'Common.Btn.Setup.Complete': 'Setup completed',
    'Common.Btn.DuplicateCheck': 'Duplicate check',
    'Vehicle.BasicInfo.CreateDate': 'First registration date',
    'Vehicle.BasicInfo.ExpireDate': 'Vehicle expiration date',
    'MyPage.Business.Code.Placeholder': 'Enter corporation code',
    'MyPage.Branch': 'Branch',
    'MyPage.Branch.Placeholder': 'Select the corporation and branch.',
    'Vehicle.BasicInfo.VehicleImage': 'Vehicle image',
    'Vehicle.BasicInfo.Terminal': 'Terminal',
    'Vehicle.InterpersonalCompensation1': 'Liability payment for person 1',
    'Vehicle.InterpersonalCompensation2': 'Liability payment for person 2',
    'Vehicle.Reparation1': 'Liability payment for objects 1',
    'Vehicle.UninsuredCarInjury': 'Uninsured car injury',
    'Vehicle.PhysicalAccident': 'Accident with self-injury',
    'Vehicle.Interpersonal': 'For person',
    'Vehicle.InterVehicle': 'For objects',
    'Vehicle.SelfDamage': 'Self-inflicted damage',
    'Vehicle.VehicleDamage': 'Collision coverage',
    'Vehicle.VehicleDamage.Registration': 'Collision coverage insured',
    'Vehicle.VehicleDamage.Join': 'Join',
    'Vehicle.VehicleDamage.NotJoin': 'Unregistered',
    'Title.VehicleEdit': 'Vehicle update',
    'Menu.Password': 'Change Password',
    'Menu.MyBusiness': 'My operator',
    'Menu.MyBranch': 'My branch',
    UNKNOWN: 'Unknown',
    'BusinessMember.RoleDetail.Business': 'Representative administrator',
    'BusinessMember.RoleDetail.Branch': 'Branch administrator',
    'BusinessMember.RoleDetail.Staff': 'Employee',
    'Business.Title.License': 'Subscribed license',
    'Business.License.Name': 'License name',
    'Vehicle.Duplicate.Fail': 'The vehicle no. already exists.',
    'Vehicle.Duplicate.Required': 'Check if the vehicle no. is duplicated.',
    'Vehicle.Duplicate.Success':
        'The vehicle no. is available for registration.',
    'InsuranceRental.Title': 'Car rental insurance',
    'InsuranceRental.Type.Reservation': 'Reservation of car rental insurance',
    'InsuranceRental.Type.Creation': 'Registration of car rental insurance',
    'InsuranceRental.Charge.Amount': 'Billed amount',
    'InsuranceRental.Charge.Daily': 'Daily fee',
    'InsuranceRental.Charge.Time': 'Hourly fees',
    'InsuranceRental.Charge.Rate': 'Other rates',
    'InsuranceRental.Charge.Rate.Charge': 'Payment rate',
    'InsuranceRental.Charge.Rate.Mistake': 'Fault rate',
    'InsuranceRental.Charge.TotalAmount': 'Total fees',
    'InsuranceRental.Charge.TotalAmount.Example':
        '((daily fees * no. of days) + (hourly fees * no. of hours)) * charging rate * (100% - fault rate)',
    'InsuranceRental.Charge.Real': 'Actual claimed amount',
    'InsuranceRental.Accident.Title': 'Accident information',
    'InsuranceRental.Accident.Damage': 'Damage information',
    'InsuranceRental.Accident.Assault': 'Assault information',
    'InsuranceRental.Accident.Name': 'Name',
    'InsuranceRental.Accident.CarNum': 'Vehicle No.',
    'InsuranceRental.Accident.CarModel': 'Vehicle Model',
    'InsuranceRental.Accident.MaintenanceInfo': 'Maintenance information',
    'InsuranceRental.Accident.MaintenanceShop': 'Maintenance shop',
    'InsuranceRental.Insurance.Title': 'Insurance information',
    'InsuranceRental.Insurance.CompanyInfo': 'Insurance company information',
    'InsuranceRental.Insurance.CompanyCode': 'Insurance Company',
    'InsuranceRental.Insurance.InsuranceCode': 'Insurance filing code',
    'InsuranceRental.Insurance.Manager': 'Insurance manager',
    'InsuranceRental.Insurance.Charge.Title': 'Billing information',
    'InsuranceRental.Insurance.Charge.Date': 'Billing date',
    'InsuranceRental.Insurance.Charge.Amount': 'Total fees',
    'InsuranceRental.Insurance.Deposit.Date': 'Deposit date',
    'InsuranceRental.Insurance.Deposit.Amount': 'Deposit amount',
    'InsuranceRental.Insurance.Mistake.Amount':
        'Customer damage liability fees',
    'InsuranceRental.Insurance.OwnVehicle.Amount':
        'Collision coverage insurance amount',
    'InsuranceRental.Insurance.Payment.Type': 'Payment type',
    'InsuranceRental.Insurance.Payment.Type.Card': 'Card',
    'InsuranceRental.Insurance.Payment.Type.Cash': 'Cash',
    'Common.Btn.Search': 'Search',
    'Reservation.Label.StartDate': 'Rental start date',
    'Reservation.Label.SearchType': 'Search item',
    'Reservation.Label.Type': 'Rental Category',
    'Reservation.Label.Status': 'Reservation Status',
    'Reservation.Label.DueDate': 'Estimated time and date of return',
    'Title.RentList': 'Management of rental details',
    'Vehicle.DuplicateCheck.FieldNull': 'Enter the vehicle no.',
    'Common.Btn.DeleteVehicle': 'Deletion of vehicle information',
    'Menu.Vehicle.Mgnt': 'Vehicle registration management',
    'Menu.Vehicle.Insurance.Payment.Mgnt': 'Insurance information management',
    'Menu.Insurance.Payment.Mgnt': 'Insurance information management',
    'Menu.Vehicle.Purchase.Payment.Mgnt': 'Monthly installment management',
    'Menu.Vehicle.Accident.Mgnt': 'Accident repair management',
    'Menu.Vehicle.Penalty.Pee.Mgnt': 'Penalty management',
    'Menu.Branch.Mgnt': 'Branch management',
    'Menu.Business.Member.Mgnt': 'Business member management',
    'Menu.Business.Customer.Mgnt': 'Business customer management',
    'Menu.Customer.Mgnt': 'Customer management',
    'Menu.Member.Mgnt': 'Employee management',
    'Menu.License': 'License management',
    'Menu.Code': 'Code management',
    'Menu.Menu': 'Menu management',
    'Menu.Role': 'Authority management',
    'Menu.Program': 'Program management',
    'Menu.Message': 'Message management',
    'Menu.Rent.Fare': 'Management of rental fees',
    'Menu.Vehicle.Rent.Fare': 'Car rental fee',
    'Menu.Rent.Insurance.Fare': 'Rental insurance premium management',
    'Menu.Vehicle.Rent.Insurance.Fare': 'Car rental insurance premium',
    'Menu.Rent.Schedule': 'Check rental status',
    'Menu.Rent.Vehcile.Available': 'Search available rental vehicles',
    'Menu.Reservation.List': 'View Reservation Details',
    'Menu.Rent.List': 'Management of rental details',
    'Menu.CompanyUser': 'Company manager management',
    'Menu.Company': 'Company management',
    'Reservation.Label.RentalRegister': 'Rental registration',
    'Common.Confirm.Reservation.Cancle':
        'Do you want to cancel your reservation',
    'Common.Logout.Success': 'You have logged out safely. Goodbye',
    'LongRental.Estimate.CarInfo': 'Information on the estimated car',
    'LongRental.ReservationDate.Placeholder': 'Select a rental period',
    'LongRental.Estimate.VehicleName': 'Vehicle name',
    'LongRental.Purchase.Cost': 'Vehicle purchase cost (USD)',
    'LongRental.RentalDeposit.Cost': 'Rental deposit (USD)',
    'LongRental.MonthlyCharge.Cost': 'Monthly fee (USD)',
    'LongRental.Period': 'Period (months)',
    'LongRental.Options': 'Options',
    'LongRental.PenaltyAmount': 'Penalty fee (%)',
    'LongRental.Note': 'Note',
    'LongRental.VehiclePremium': 'Payment information',
    'LongRental.Charge.Method': 'Payment method',
    'LongRental.Charge.Bank': 'Bank for billing',
    'LongRental.Charge.CountNumber': 'Account no.',
    'LongRental.Charge.CountOwner': 'Account holder',
    'LongRental.Send.Method': 'Billing location',
    'LongRental.Pay.Day': 'Payment date',
    'LongRental.Charge.Method.Placeholder': 'Select payment method',
    'LongRental.Charge.Bank.Placeholder': 'Select a bank for billing',
    'LongRental.Charge.CountNumber.Placeholder': 'Enter the account no.',
    'LongRental.Charge.CountOwner.Placeholder': 'Enter the account holder',
    'LongRental.Send.Method.Placeholder': 'Select billing location',
    'LongRental.Pay.Day.Placeholder': 'Select date of payment',
    'Rent.UseState.Type.Day': 'Day',
    'Rent.UseState.Type.Month': 'Month',
    'Code.GradeType.Placeholder': 'Vehicle Class',
    'Code.VehicleStatus.Placeholder': 'Rental status',
    'UseState.State.Short': 'Short',
    'UseState.State.Insurance': 'Insurance',
    'UseState.State.Month': 'Monthly',
    'UseState.State.Long': 'Long-term',
    'Code.Weekdays.0': 'Day',
    'Code.Weekdays.1': 'Month',
    'Code.Weekdays.2': 'Tuesday',
    'Code.Weekdays.3': 'Wednesday',
    'Code.Weekdays.4': 'Thursday',
    'Code.Weekdays.5': 'Friday',
    'Code.Weekdays.6': 'Saturday',
    'VehicleModel.Image': 'Image',
    'Branch.ManagerName.Detail': "CEO/Branch manager's name",
    'Branch.ManagerPhoneNumber.Detail': "CEO/Branch manager's phone no.",
    'Branch.ManagerEmail.Detail': "CEO/Branch manager's email address",
    'Rent.Filter.Label.Status': 'Rental status',
    'Rent.Filter.Label.IncludeMaintenance': 'Maintenance included',
    'Rent.Filter.Label.PaymentDate': 'Payment date',
    'Rent.Filter.Code': 'Rental code',
    'Rent.Filter.User.Name': "Customer's name",
    'Rent.Filter.User.MobileNumber': "Customer's phone no.",
    'Rent.Filter.User.Location': 'Rental base',
    'Rent.Filter.Status.Inuse': 'Currently rented',
    'Rent.Filter.Status.Returned': 'End of the rental',
    'Rent.Filter.Status.Canceled': 'Cancellation',
    'Rent.Filter.Status.LowPower': 'Low Voltage',
    'Rent.Filter.Status.NoReturned': 'Not Returned',
    'Rent.Filter.Maintenance.Include': 'Include',
    'Rent.Filter.Maintenance.NotInclude': 'Not included',
    'Rent.Filter.Payment.5': '5 days',
    'Rent.Filter.Payment.7': '7 days',
    'Rent.Filter.Payment.10': '10 days',
    'Rent.Filter.Payment.15': '15 days',
    'Rent.Filter.Payment.20': '20 days',
    'Rent.Filter.Payment.25': '25 days',
    'Rent.Filter.Payment.EndOfMonth': 'The last day',
    'Rent.Code': 'Contract no.',
    'Rent.Date': 'Rental date',
    'Rent.Status.INUSE': 'Currently rented',
    'Rent.Status.RETURNED': 'End of the rental',
    'Rent.Status.BE_CANCELED': 'Cancellation',
    'Rent.Status.LOW_POWER': 'Low Voltage',
    'Rent.Status.NO_RETURNED': 'Not Returned',
    'Rent.IncludeMaintenance': 'Maintenance status',
    'Rent.Extend': 'Rental extension',
    'Rent.Contract': 'Contract',
    'Rent.status.Requested': 'Request for reservation',
    'Rent.status.Canceled': 'Cancel Reservation',
    'Rent.status.NoShow': 'The vehicle was not acquired',
    'Rent.status.Deleted': 'Reservation deletion',
    'Rent.status.Denied': 'Reservation rejection',
    'Rent.status.CommError': 'Communication error',
    'Rent.status.Estimate': 'Quotation completed',
    'Rent.status.EstimateCanceled': 'Cancel the quotation',
    'Rent.status.ChangeToInsurance': 'Change to car rental insurance',
    'Rent.status.ChangeToShort': 'Change to short rental',
    'Rent.status.Insurance.Billing': 'Insurance bill',
    'Rent.status.Billing': 'Contract',
    'Rent.modal.Return.message': 'Do you want to return the car?',
    'Rent.modal.Return.Date': 'Return time and date',
    'Rent.modal.Return.Worker': 'Worker',
    'Rent.modal.Return.WorkDate': 'Work date',
    'Rent.modal.Return.Fail': 'Failed to process the return.',
    'Rent.modal.Extend.message': 'Do you want to extend the rental period?',
    'Rent.modal.Extend.DueDate': 'Previous end time',
    'Rent.modal.Extend.Date': 'End date of extension',
    'Rent.modal.Extend.Fail': 'Period extension failed.',
    'Rent.modal.Force.message': 'Do you want to forcibly return the car?',
    'Rent.modal.Force.Date': 'Time and date of forced return',
    'Rent.modal.Force.Fail': 'Failed to process forced return.',
    'VehicleBasic.VIN.Error':
        'The VIN already exists or is invalid. Check the information again.',
    'LongRental.Charge.DirectDebit': 'Direct debit',
    'LongRental.Charge.VirtualAccount': 'Virtual account',
    'LongRental.Charge.Card': 'Card payment',
    'LongRental.Charge.Billing': 'Bill',
    'LongRental.Charge.Home': 'Home',
    'LongRental.Charge.Company': 'Company',
    'LongRental.UserNameInfo': 'Customer information',
    'LongRental.VehicleNumber': 'Vehicle',
    'LongRental.Payment.Month.Placeholder': 'Select a payment date',
    'LongRental.Title.Estimate.Create': 'Register an estimated car',
    'LongRental.Estimate.VehicleName.Placeholder': 'Enter the vehicle name.',
    'LongRental.Purchase.Cost.Placeholder': 'Enter the purchase cost.',
    'LongRental.RentalDeposit.Cost.Placeholder': 'Enter the rental deposit.',
    'LongRental.MonthlyCharge.Cost.Placeholder': 'Enter the monthly fee',
    'LongRental.Period.Placeholder': 'Enter a period',
    'LongRental.Options.Placeholder': 'Enter options',
    'LongRental.PenaltyAmount.Placeholder': 'Enter the penalty fee',
    'LongRental.Note.Placeholder': 'Enter a note',
    'Title.Charge.Peak.Fare': 'Peak rate',
    'Title.Charge.Discount': 'Rental discount (%)',
    'Vehicle.Tab.Log': 'Driving log',
    'Common.Fail.User': 'Existing duplicate reservation.',
    'Common.Btn.ContractCancle': 'Cancellation',
    'Rent.modal.ContractCancle.message':
        'Do you want to cancel the rental contract?',
    'LongRental.ContractCancle.StartDate': 'Rental start date',
    'LongRental.ContractCancle.EndDate': 'Estimated time and date of return',
    'LongRental.ContractCancle.ContractCancle': 'Date and time of cancellation',
    'LongRental.ContractCancle.ReturnPay.Title': 'Rental fee refund',
    'LongRental.ContractCancle.Refund': 'Refund of rental fees',
    'LongRental.ContractCancle.Total': 'Total',
    'LongRental.ContractCancle.PenaltyAmount': 'Penalty fee',
    'LongRental.Charge.Cash': 'Cash',
    'LongRental.Estimate.message':
        'Enter car and rental information for a quotation.',
    'Common.BeContractCancle.Fail': 'Cancellation is not allowed.',
    'Common.Btn.Report': 'Estimate',
    'Code.Create.ErrorCode.910004': 'The group key code is duplicated.',
    'Code.Update.ErrorCode.910004': 'The group key code is duplicated.',
    'Code.Delete.ErrorCode.910004':
        'Cannot delete because of an existing subcode.',
    'VehicleInsurance.branchName': 'Branch name',
    'VehicleInsurance.insureYear': 'No. of years',
    'VehicleInsurance.paymentDueDate': 'Due date',
    'VehicleInsurance.totalInsurance': 'Total insurance premium',
    'VehicleInsurance.paymentStatus': 'Payment status',
    'VehicleInsurance.ManagerName': 'Insurance manager',
    'VehicleInsurance.ManagerMobileNumber': "Insurance manager's phone no.",
    'LongRental.values':
        'Total = Rental deposit – penalty amount + indemnity cost – extra fees + refund',
    'Vehicle.Tab.Insurance': 'Insurance information',
    'VehicleInsurance.yearSelect': 'Select the no. of years',
    'VehicleInsurance.1year': '1st year',
    'VehicleInsurance.2year': '2nd year',
    'VehicleInsurance.3year': '3rd year',
    'Menu.Business': 'Operator management',
    'VehicleInsurance.12Month': '12 months',
    'VehicleInsurance.24Month': '24 months',
    'VehicleInsurance.36Month': '36 months',
    'VehicleInsurance.2Month': '2 months',
    'VehicleInsurance.3Month': '3 months',
    'VehicleInsurance.4Month': '4 months',
    'VehicleInsurance.5Month': '5 months',
    'VehicleInsurance.6Month': '6 months',
    'VehicleInsurance.7Month': '7 months',
    'VehicleInsurance.8Month': '8 months',
    'VehicleInsurance.9Month': '9 months',
    'VehicleInsurance.10Month': '10 months',
    'VehicleInsurance.11Month': '11 months',
    ERROR_CODE_SUCCESS: 'It has been successful.',
    ERROR_CODE_NO_CONTENT: 'No data available',
    ERROR_CODE_CREATED: 'It has been created.',
    ERROR_CODE_NOT_CREATED: 'Failed to enter the data.',
    ERROR_CODE_NOT_UPDATED: 'Failed to modify the data.',
    ERROR_CODE_NOT_DELETED: 'Failed to delete the data.',
    ERROR_CODE_BAD_REQUEST: 'Input factor invalid',
    ERROR_CODE_FORBIDDEN_ROLE: 'Administrator permission unavailable.',
    ERROR_CODE_FORBIDDEN_ACL: 'User permission is unavailable.',
    ERROR_CODE_INTERNAL_SERVER_ERROR: 'An internal error occurred.',
    ERROR_CODE_NEED_LOGON: 'Login is required.',
    ERROR_CODE_VEHICLE_NOT_DELETE_RESERVATION:
        'Cannot delete the vehicle because of existing reservation information.',
    ERROR_CODE_VEHICLE_NOT_DELETE_ACCIDENT:
        'Unable to delete the car because of existing accident history.',
    ERROR_CODE_VEHICLE_VIN_DUPLICATION: 'Cannot use the VIN code.',
    ERROR_CODE_VEHICLE_CONTROL_ID_DUPLICATION:
        'Cannot use the vehicle control ID.',
    ERROR_CODE_USER_NOT_AUTHORIZATION: 'User authorization failed.',
    ERROR_CODE_BUSINESS_NOT_DELETE_CAR:
        'It cannot be deleted as a related vehicle exists.',
    ERROR_CODE_PRIVATE_UNAUTH_BUSINESS:
        'The activated user cannot change his/her corporation.',
    ERROR_CODE_CHARGE_NOT_DELETE_RESERVATION:
        'A failure occurred due to the reserved premium.',
    ERROR_CODE_VEHICLEMODEL_NOT_DELETE:
        'It cannot be deleted as a related vehicle exists.',
    ERROR_CODE_RESERVATION_ALEADY_VEHICLE: 'The vehicle is already reserved.',
    ERROR_CODE_RESERVATION_CONFLICT: 'Conflict in user reservation',
    ERROR_CODE_RESERVATION_UNAUTH_USER:
        'Reservation is disabled for unauthorized users.',
    ERROR_CODE_RESERVATION_FIRST_USER_BUSINESS: 'Register a corporation first.',
    ERROR_CODE_RESERVATION_NOT_INSUE: "The reservation status is 'not in use'.",
    ERROR_CODE_RESERVATION_RETURN_NOT_LOCATION:
        'The current vehicle location does not match the return location.',
    ERROR_CODE_RESERVATION_BAD_REQUEST: 'Cannot interpret the request.',
    ERROR_CODE_RESERVATION_VEHICLE_ERROR: 'Cannot find vehicle information.',
    ERROR_CODE_RESERVATION_TIME_ERROR: 'Unusual start and end times',
    ERROR_CODE_RESERVATION_RENTAL_COMPANY:
        'Failed to register or modify the rental companpy.',
    ERROR_CODE_RESERVATION_DRIVER_LICENSE:
        "Failed to register or update the driver's license.",
    ERROR_CODE_RESERVATION_ADDITIONAL_DRIVER_LICENSE:
        'Invalid additional driver information.',
    ERROR_CODE_RESERVATION_NO_CONTENT: 'No data available',
    ERROR_CODE_RESERVATION_GRPC_ERROR: "There's an error in the GRPC module.",
    ERROR_CODE_RESERVATION_STATUS_ERROR: 'Status error',
    ERROR_CODE_RESERVATION_KOROAD_ERROR: 'Failed to verify the license.',
    ERROR_CODE_RESERVATION_INUSE:
        'You can set a reservation 15 min before the start time.',
    ERROR_CODE_RESERVATION_OVERLAP: 'Occurrence of reservation overlap.',
    ERROR_CODE_CHARGE: 'Payment information unavailable.',
    ERROR_CODE_TESTCAR_CONFIG_NOT_EXIST:
        'Setup information for the test car is unavailable.',
    ERROR_CODE_TESTCAR_RESERVATION_PERIOD_EXCESS:
        'Reservation period for a test car has been exceeded.',
    ERROR_CODE_TESTCAR_STARTTIME_WEEKEND:
        'Rentals are unavailable during weekends.',
    ERROR_CODE_TESTCAR_ENDTIME_WEEKEND:
        'Rentals cannot be returned during weekends.',
    ERROR_CODE_TESTCAR_STARTTIME_HOLIDAY: 'Cannot rent on a holiday.',
    ERROR_CODE_TESTCAR_ENDTIME_HOLIDAY: 'Cannot return on a holiday.',
    ERROR_CODE_TESTCAR_COUNT_EXCESS:
        'The reservation limit for test cars has been exceeded.',
    ERROR_CODE_MONITORING_NOT_FIND_VIN:
        'The current vehicle location does not match the return location.',
    ERROR_CODE_CONTROL_FAIL: 'Failed to remotely control the vehicle.',
    ERROR_CODE_CONTROL_VIN_DUPLICATION: 'VIN code is unavailable.',
    ERROR_CODE_CONTROL_NADID_DUPLICATION: 'NADID information is unavailable.',
    ERROR_CODE_RESOURCE_NOT_FOUND: 'Cannot find the resource.',
    ERROR_CODE_RESOURCE_KEY_DUPLICATION: 'Cannot use the resource key.',
    ERROR_CODE_RESOURCE_KEY_EMPTY: 'The resource key is empty.',
    ERROR_CODE_COMPANY_BUSINESS_REG_NUM_EXIST:
        'The operator registration no. already exists.',
    ERROR_CODE_EXIST_BRANCH_USER: 'A branch user exists.',
    'Vehicle.BasicInfo.ModelYear.Placeholder':
        'Enter the model year of the vehicle',
    'Vehicle.BasicInfo.ModelYear': 'Model year',
    'Vehicle.BasicInfo.ExtendCnt': 'Vehicle age extension count',
    'Vehicle.BasicInfo.RegularInspectionDate': 'Regular inspection',
    'Vehicle.ExtendCnt.0': '0 times',
    'Vehicle.ExtendCnt.1': 'One time',
    'Vehicle.ExtendCnt.2': 'Two times',
    'Branch.Manager.Title': 'Branch manager',
    ERROR_CODE_ALREADY_RESERVED: 'The date has already been reserved.',
    'LongRental.Charge.Bank_CARD': 'Card company',
    'LongRental.Charge.CountNumber_CARD': 'Card No.',
    'LongRental.Charge.CountOwner_CARD': 'Card owner',
    'VehicleInsurance.Payment.1': '1st',
    'VehicleInsurance.Payment.2': '2nd',
    'VehicleInsurance.Payment.3': '3rd',
    'VehicleInsurance.Payment.4': '4th',
    'VehicleInsurance.Payment.5': '5th',
    'VehicleInsurance.Payment.6': '6th',
    'VehicleInsurance.Payment.7': '7th',
    'VehicleInsurance.Payment.8': '8th',
    'VehicleInsurance.Payment.9': '9th',
    'VehicleInsurance.Payment.10': '10th',
    'VehicleInsurance.Payment.11': '11th',
    'VehicleInsurance.Payment.12': '12th',
    'Vehicle.Model.Info': 'Vehicle model information',
    'VehicleInsurance.Select.Paid': 'Paid',
    'VehicleInsurance.Day.type': 'Select a date',
    'VehicleInsurance.Day.Duration': 'Payment duration',
    'VehicleInsurance.Modal.Year': 'No. of years/sequence',
    'Common.Buuton.Close': 'Close',
    'Vehicle.Used': 'Rental available state',
    'Vehicle.Used.Yes': 'Rental available',
    'Vehicle.Used.No': 'Rental stopped',
    'Common.Btn.Terminal': 'Terminal transmission',
    'Reservation.Request.Terminal.Fail':
        'Cannot send reservation information to your terminal.',
    'Reservation.Request.Terminal.Success':
        'A request has been made to send the reservation information to your terminal.',
    'Reservation.Request.Terminal.Confirm':
        'Do you want to send the reservation information to your terminal?',
    'TestCarConfig.Reservation.StartTime': 'Set reservation start date/time',
    'Common.Input.Required.Hour': 'Enter a value from 0 to 23.',
    'Common.Input.Required.Day': 'Enter a value from 1 to 31.',
    'Location.Radius.50m': '50 m',
    'Location.Radius.100m': '100 m',
    'Location.Radius.200m': '200 m',
    'Location.Radius.300m': '300 m',
    'Menu.Reservation.TestCar': 'Test car rental management',
    'Charge.Minute.Zero': '0m',
    'Charge.Minute.Ten': '10 minutes',
    'Charge.Minute.Twenty': '20 minutes',
    'Charge.Minute.Thirty': '30 minutes',
    'Charge.Minute.Forty': '40 minutes',
    'Charge.Minute.Fifty': '50 minutes',
    'LongRental.Payment.Day.One': '1 day',
    'LongRental.Payment.Day.Five': '5 days',
    'LongRental.Payment.Day.Ten': '10 days',
    'LongRental.Payment.Day.Fifteen': '15 days',
    'LongRental.Payment.Day.Twenty': '20 days',
    'LongRental.Payment.Day.TwentyFifth': '25 days',
    'LongRental.Payment.Day.Last': 'The last day',
    'LongRental.RentalInfo.Year.Two': '24 months',
    'LongRental.RentalInfo.Year.Three': '36 months',
    'LongRental.RentalInfo.Year.Four': '48 months',
    'LongRental.RentalInfo.Year.Five': '60 months',
    'LongRental.Charge.Bank_CARD.Placeholder': 'Select the card company.',
    'LongRental.Charge.CountNumber_CARD.Placeholder': 'Enter the card no.',
    'LongRental.Charge.CountOwner_CARD.Placeholder': 'Enter the card owner.',
    ERROR_CODE_LICENSE_USE_BUSINESS_EXIST:
        'It cannot be deleted because a business/rental car is using the rate plan.',
    ERROR_CODE_MENU_CHILDREN_EXIST:
        'Cannot delete because of an existing submenu',
    ERROR_CODE_ROLE_CHILDREN_EXIST:
        'Cannot delete because of existing sub-authority data',
    'TestCar.Reservation.View': 'View',
    'Common.Input.Required.Percent': 'Enter a value from 1 to 100.',
    'Terminal.message':
        '*It may not match the retrieved physical terminal information.*',
    'Reservation.Request.BeContractCancle.Success':
        'The long-term rental of a new car is canceled.',
    'VehicleInsurance.4year': '4th year',
    'VehicleInsurance.5year': '5th year',
    'VehicleInsurance.6year': '6th year',
    'VehicleInsurance.7year': '7th year',
    'VehicleInsurance.8year': '8th year',
    'VehicleInsurance.9year': '9th year',
    'VehicleInsurance.10year': '10th year',
    'Reservation.Error.SelectPeriod':
        'Select an estimated time and date of rental.',
    'Reservation.Error.StartLocation': 'Select a delivery location.',
    'Common.Error.message': 'Action disabled.',
    'Title.PaymentManager': 'Sales/settlement status',
    'Menu.Payment.Violation.Mgnt': 'Penalty/toll management',
    ERROR_CODE_NOT_FOUND_RESERVATION_DATA:
        'A reservation related to the violation does not exist.',
    ERROR_CODE_FORCERETURN_CAN_BE_MADE_AFTER_THE_START_TIME:
        'Forced return can be conducted after the start of rental.',
    'Password.Infomation1':
        '- You are required to enter the password before viewing your rental contract to protect your personal information.',
    'Password.Infomation2':
        "- The password is your six-digit date of birth or the first six digits of the corporate's business registration no. Enter the password and click the OK button.",
    'Password.CompanyInfo1': 'Operator',
    'Vehicle.Registration.Card': 'Vehicle registration card',
    'Vehicle.Registration.Card.Btn.Create':
        'Register vehicle registration card',
    'Vehicle.Registration.Card.Create.Help':
        'Select a vehicle registration card (jpeg, jpg, png).',
    'Vehicle.Registration.Card.Create.ExtName.Help':
        'Only jpeg, jpg, and png extensions can be used.',
    'Vehicle.Registration.Card.Btn.Upload': 'Upload vehicle registration card',
    'Vehicle.Registration.Card.Upload.Fail':
        'Failed to analyze the vehicle registration card.',
    'Common.Buuton.Kakaotalk': 'KakaoTalk',
    'Common.fail.changeMonth': 'Select a rental period',
    'Send.Kakaotalk.Fail': 'Failed to send the KakaoTalk notification.',
    'Send.Kakaotalk.Success':
        'The KakaoTalk notification has been sent successfully.',
    'Send.Kakaotalk.Password.Fail': 'Incorrect password entered',
    'Send.Kakaotalk.Password.Success': 'Password entered successfully.',
    ERROR_CODE_TODAY_IS_NOT_SAME_DATE:
        "Rental can only be registered with today's date.",
    'Common.Error.Validation': 'Please fill in all required fields.',
    ERROR_CODE_NOT_MATCH_PASSWORD: 'Password does not match.',
    'Vehicle.BasicInfo.Insurance.Limit': 'Insurance limit',

    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */
    'Vehicle.BasicInfo.Equip': '장착',
    'Vehicle.BasicInfo.Not.Equip': '미장착',
    'Vehicle.Title.RealTimeInfo': '실시간차량정보',
    'Vehicle.Title.AdditionalInfo': '부가정보',
    'Vehicle.AdditionalInfo.iviID': 'IVI ID',
    'Vehicle.AdditionalInfo.iviID.Call': 'IVI ID 불러오기',
    'Vehicle.AdditionalInfo.asPeriod': 'A/S 기간',
    'Vehicle.AdditionalInfo.taxiDrivingGroup': '택시운행조',
    'Vehicle.AdditionalInfo.VIN.FieldNull': '차대번호를 입력해주세요.',
    'Vehicle.AdditionalInfo.iviID.Fail': 'IVI ID 조회 실패',
    'Vehicle.AdditionalInfo.iviID.Empty':
        '차대번호에 해당되는 IVI ID가 존재하지 않습니다.',

    'Vehicle.Tab.Sales': '차량별매출',
    'Vehicle.Sales.HistoryTime': '발생일시',
    'Vehicle.Sales.Business.Time': '영업시간(거리)',

    'Menu.Vehicle.OperationStatistics': '차량운행일지',
    'Vehicle.OperationStatistics.Btn.Download': '엑셀다운로드',
    'Terms.Search.Period': '조회기간',
    'Vehicle.OperationStatistics.Vehicle.Number': '차량번호',
    'Vehicle.OperationStatistics.TaxiDriver.Name': '기사명',
    'Vehicle.OperationStatistics.Date': '날짜',
    'Vehicle.OperationStatistics.Login.Time': '출근시간',
    'Vehicle.OperationStatistics.Logout.Time': '퇴근시간',
    'Vehicle.OperationStatistics.Driving.Time': '주행시간(거리)',
    'Vehicle.OperationStatistics.Business.Time': '영업시간(거리)',
    'Vehicle.OperationStatistics.Empty.Time': '공차시간(거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount': '총영업금액(호출/배회)',

    'Vehicle.OperationStatistics.Title.Info': '차량운행 상세 정보',
    'Vehicle.OperationStatistics.Work.Time': '출퇴근시간',
    'Vehicle.OperationStatistics.TotalDriving.Time': '총주행시간(거리)',
    'Vehicle.OperationStatistics.TotalBusiness.Time': '총영업시간(거리)',
    'Vehicle.OperationStatistics.TotalEmpty.Time': '총공차시간(거리)',
    'Vehicle.OperationStatistics.Business.Rate': '영업률(영업거리/총주행거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount2': '총매출금액',
    'Vehicle.OperationStatistics.Business.Count': '영업건수/객단가',
    'Vehicle.OperationStatistics.CallType.Count': '호출/배회건수',
    'Vehicle.OperationStatistics.CallType.SalesAmount': '호출/배회 매출금액',

    'Vehicle.OperationStatistics.Departure.Time': '승차시간',
    'Vehicle.OperationStatistics.Arrive.Time': '하차시간',
    'Vehicle.OperationStatistics.Departure.PlaceName': '승차위치',
    'Vehicle.OperationStatistics.Arrive.PlaceName': '하차위치',
    'Vehicle.OperationStatistics.Call.YN': '호출여부',
    'Vehicle.OperationStatistics.Call.App': '호출APP',
    'Vehicle.OperationStatistics.Payment.Method': '결제방식',
    'Vehicle.OperationStatistics.SalesAmount': '매출금액',
    'Vehicle.OperationStatistics.Create.Time': '운행정보입력시간',
    'Vehicle.OperationStatistics.PaymentType.Cash': '현금',
    'Vehicle.OperationStatistics.PaymentType.Card': '카드',
    'Vehicle.OperationStatistics.AppType.Macaron': '마카롱배차앱',
    'Vehicle.OperationStatistics.AppType.TMoney': '티머니미터기',
    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */

    // 차량만령관리
    'Vehicle.ExpiryHeader.Title': '차량만령관리',
    'Vehicle.ExpirySearch.Title': '차령만료일 기간',
    'Vehicle.ExpirySearch.all': '전체',
    'Vehicle.ExpirySearch.prevday': '1일전',
    'Vehicle.ExpirySearch.prevweek': '1주일전',
    'Vehicle.ExpirySearch.prevtwoweek': '2주일전',
    'Vehicle.ExpirySearch.prevmonth': '1달전',
    'Vehicle.ExpirySearch.prevyear': '1년 2주전',
    'Vehicle.ExpirySearch.today': '당일',
    'Vehicle.ExpiryHeader.vehicleAge': '차량연령',

    // 택시통합연동로그
    'Menu.ReceiveLog': '택시통합연동로그',
    'Title.ReceiveLog': '택시통합연동로그',
    'ReceiveLog.Btn.Download': '엑셀다운로드',
    'ReceiveLog.Filter.RegDate': '등록일',
    'ReceiveLog.Filter.InterfaceId': 'InterfaceID',
    'ReceiveLog.Filter.DriverName': '기사명',
    'ReceiveLog.Filter.Pincode': 'PINCODE',
    'ReceiveLog.Filter.ResultCodeKind': '응답코드종류',
    'ReceiveLog.Filter.ResultCode': '응답코드',
    'ReceiveLog.ResultCodeKind.All': '전체',
    'ReceiveLog.ResultCodeKind.Success': '성공',
    'ReceiveLog.ResultCodeKind.Failure': '실패',
    // 'ReceiveLog.Item.ID' : 'ID',
    'ReceiveLog.Item.CreatedDateTime': '생성일시',
    'ReceiveLog.Item.YdServiceName': '서비스명',
    'ReceiveLog.Item.InterfaceId': 'InterfaceID',
    'ReceiveLog.Item.InterfaceName': 'Interface명',
    'ReceiveLog.Item.AccessDateTime': '요청일시',
    // 'ReceiveLog.Item.SessionId' : '세션ID',
    'ReceiveLog.Item.UrlPath': 'API URL',
    // 'ReceiveLog.Item.HttpMethod' : '메소드명',
    // 'ReceiveLog.Item.ClientIp' : 'Client IP',
    'ReceiveLog.Item.TaxiDriverName': '기사명',
    'ReceiveLog.Item.Pincode': 'PINCODE',
    // 'ReceiveLog.Item.Vin' : 'VIN No.',
    'ReceiveLog.Item.Param': 'Parameter',
    'ReceiveLog.Item.ResultCode': '응답코드',
    'ReceiveLog.Item.ResultMsg': '응답메시지',
    'ReceiveLog.Item.InnerResultMsg': '응답메시지상세',
    'ReceiveLog.Item.ResultValue': '응답값',
    // 차량기록조회
    'Vehicle.Records.Btn.Download': '엑셀다운로드',
    'Terms.Search.Time': '조회시간*',
    'Vehicle.Records.Vehicle.Number': '차대번호*',
    'Menu.Vehicle.Records': '차량실시간정보',
    'Vehicle.Records.Minute.Placeholder': '(분)',
    'Vehicle.Records.Hour.Placeholder': '(시)',
    'Vehicle.Records.Comment':
        '* 선택한 시간부터 10분간의 데이터를 조회합니다.',
};

export default language;
