import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import { useAppRoot, useTransaction } from 'component/mux';

import {
    TRANSID_AUTHENTICATION,
    TRANSID_SYSTEM_CODE,
    TRANSID_LOCATION,
} from 'config/transaction';
import { STORAGE_KEY } from 'util/pageUtil';
import { useHistory } from 'react-router';

const excludePath = [
    '/',
    '/admin',
    '/redirect',
    '/user/redirect',
    '/loginAfter/agreementPage',
    '/jp/redirect',
    '/jp/user/redirect',
    '/jp/loginAfter/agreementPage',
    '/openWindow',
    '/reload',
];

const MEMBER_ROLE = ['BUSINESS_MANAGER', 'BUSINESS_OPERATOR'];
const ROLE = {
    systemAdmin: 'SYSTEM_ADMIN', // 시스템 관리자
    mobilityManager: 'MOBILITY_MANAGER', // MOCEAN Car Sharing FMS
    businessManager: 'BUSINESS_MANAGER', // 슈퍼 관리자
    businessOperator: 'BUSINESS_OPERATOR', // 부서 담당자
    maintenance: 'MAINTENANCE', // 위탁업체
    mipOperator: 'MIP_OPERATOR', // 장착기사
};
const USER_REDIRECT_URL = ['/user/redirect', '/jp/user/redirect'];
const ADMIN_REDIRECT_URL = ['/redirect', '/jp/redirect']
const useAuthentication = () => {
    const { fetchTransaction } = useTransaction(TRANSID_AUTHENTICATION);
    const { customTransaction } = useTransaction(TRANSID_SYSTEM_CODE);
    const { customTransaction: location_customTransaction } =
        useTransaction(TRANSID_LOCATION);

    const {
        messageBox,
        signIn,
        signOut,
        setMenu,
        setSystemCode,
        changeLocale,
    } = useAppRoot();
    const intl = useIntl();
    const history = useHistory();

    const fetchSession = useCallback(async () => {
        // 로그인정보 조회
        let pickedData;
        await fetchTransaction()
            .then((data) => {
                pickedData = _.pick(data, [
                    'ID',
                    'name',
                    'email',
                    'role',
                    'roleInfo',
                    'business',
                    'activationStatusCd',
                    'agreeYn',
                    'mobileNumber',
                    'department',
                    'businessContract',
                    'uID',
                ]);

                // 메뉴 조회
                if (data?.aclMenu) {
                    pickedData.aclMenu = data?.aclMenu.filter(
                        (item) =>
                            item?.useYn === 'Y' &&
                            (item?.inquiryYn === 'Y' ||
                                item?.saveYn === 'Y' ||
                                item?.delYn === 'Y'),
                    );

                    const { aclMenu } = data;
                    setMenu(aclMenu);
                }

                // 슈퍼관리자, 부서담당자 의 경우 위블존정보 설정
                const pActivationStatusCd = pickedData?.activationStatusCd;
                const pAgreeYn = pickedData?.agreeYn;
                const pRole = pickedData?.role;
                const pBusinessID = pickedData?.business?.ID;
                const pContractID = pickedData?.businessContract?.ID;
                if (
                    pActivationStatusCd === 'NORMAL' &&
                    pAgreeYn === 'Y' &&
                    pBusinessID &&
                    pContractID
                ) {
                    if (MEMBER_ROLE.includes(pRole)) {
                        // 위블존 정보 조회
                        location_customTransaction(
                            'ListLocationBusinessHistory',
                            {
                                businessID: pBusinessID,
                                contractID: pContractID,
                            },
                        )
                            .then(({ items }) => {
                                pickedData.businessWibleZone = items;
                            })
                            .catch((error) => {});
                    }
                }

                // 로그인 정보 설정
                signIn(pickedData);
            })
            .catch((error) => {
                const errorHandler = error?.errorHandler;
                if (errorHandler) {
                    if (errorHandler(error)) return;
                }

                const pathname = window.location.pathname;
                if (!_.includes(excludePath, pathname)) {
                    messageBox(
                        intl.formatMessage({
                            id: 'Message.Auth.Alert.Expired',
                            defaultMessage: 'Message.Auth.Alert.Expired',
                        }),
                    );
                    afterSignOut();
                }
                return Promise.reject({});
            });

        // 코드 조회
        await customTransaction('fetch', {
            all: 'Y',
            useYn: 'Y',
            reqGroupYn: 'Y',
            reqItemYn: 'Y',
            noPaging: 'Y',
            sortField: 'orderNum',
            sortOrder: 'ASC',
        })
            .then(({ items, pageInfo }) => {
                setSystemCode(items);
            })
            .catch((error) => {});
        return pickedData;
    }, []);

    const afterAuth = ({ authInfo, onIsStatusAgree, onIsFirstAgree }) => {
        // 동의 팝업 처리
        const { activationStatusCd, role, agreeYn, roleInfo } = authInfo;
        const agreeCompleteYn = getAgreeComplete();

        if (agreeCompleteYn !== 'Y') {
            if (agreeYn === 'N') {
                onIsStatusAgree(true);
            } else {
                if ('NORMAL' === activationStatusCd) {
                    onIsFirstAgree(true);
                }
            }
            return;
        } else {
            onIsStatusAgree(false);
            onIsFirstAgree(false);
        }

        // URL 없이 접근한 경우 처리
        const pathName = window.location.pathname;
        const pathRoot = pathName === '/' || pathName === '/admin';

        if (pathRoot) {
            history.push(defaultUrl(activationStatusCd, role, roleInfo));
        }
    };

    const defaultUrl = (activationStatusCd, role, roleInfo) => {
        const roleKey = roleInfo?.roleKey;
        const systemType = roleInfo?.systemType;
        const landingUrl = roleInfo?.landingUrl;

        let moveUrl = '';
        if (activationStatusCd !== 'NORMAL') {
            moveUrl = '/myInfo';
        } else {
            // 권한에 landingUrl 가 등록 되어있으면 사용
            if (_.trim(landingUrl)) {
                moveUrl = landingUrl;
            } else if (role === 'BUSINESS_MANAGER') {
                // 슈퍼관리자 > 대시보드
                moveUrl = '/dashboardBusiness';
            } else if (role === 'BUSINESS_OPERATOR') {
                // 부서담당자 > 결제내역
                moveUrl = '/reservationPayment';
            } else if (roleKey === 'VOC') {
                // 고객센터 > 고객센터 공지
                moveUrl = '/csCenterNotice';
            } else if (systemType === 'PARTNER') {
                // (고객센터 를 제외한 위탁업체류) 유지보수, 위탁업체, 필드매니저 > 유지관리/정비 이력
                moveUrl = '/vehicleOperation';
            } else if (['MOBILITY_MANAGER', 'SYSTEM_ADMIN'].includes(role)) {
                // 시스템관리자/MOCEAN Car Sharing매니저 > 차량 등록/조회
                moveUrl = '/vehicle';
            } else {
                moveUrl = '/myInfo';
            }
        }
        return moveUrl;
    };

    const getAuthInfo = () => {
        const redirectAuthObj = JSON.parse(
            getStorage(STORAGE_KEY.LOGIN_AUTH_INFO) || '{}',
        );
        return redirectAuthObj;
    };

    const setAuthInfo = ({ activationStatus, agreeYn, pathname }) => {
        setStorage(
            STORAGE_KEY.LOGIN_AUTH_INFO,
            `{
                    "activationStatus":"${activationStatus}",
                    "agreeYn":"${agreeYn}",
                    "pathname":"${pathname}"
                }`,
        );
    };

    const afterSignOut = () => {
        // 세션 제거
        signOut();

        // 리다이렉트 실행
        const redirectAuthObj = getAuthInfo();
        if (ADMIN_REDIRECT_URL.includes(redirectAuthObj?.pathname)) {
            history.push('/admin');
        } else {
            history.push('/');
        }

        // 언어 초기화
        changeLocale(process.env.REACT_APP_LANGUAGE);

        // 스토리지 제거
        clearStorage();
    };

    const getAgreeComplete = () => {
        return getStorage(STORAGE_KEY.COMPLETE_AGREE);
    };

    const setAgreeComplete = (str) => {
        setStorage(STORAGE_KEY.COMPLETE_AGREE, str);
    };

    const getStorage = (key) => {
        return localStorage.getItem(key);
    };

    const setStorage = (key, val) => {
        localStorage.setItem(key, val);
    };

    const removeStorage = (key) => {
        localStorage.removeItem(key);
    };

    const clearStorage = () => {
        localStorage.clear();
    };

    return {
        fetchSession,
        afterAuth,
        defaultUrl,
        getStorage,
        setStorage,
        removeStorage,
        getAuthInfo,
        setAuthInfo,
        getAgreeComplete,
        setAgreeComplete,
        clearStorage,
        afterSignOut,
    };
};

const containRole = (userRole, roleList) => {
    return roleList.includes(userRole);
};

export default useAuthentication;
export { ROLE, useAuthentication, containRole, excludePath, MEMBER_ROLE };
