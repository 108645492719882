import { ProseMirror } from '@progress/kendo-react-editor';

const { Plugin, PluginKey, Decoration, DecorationSet } = ProseMirror;

export function placeholderPlugin(placeholder) {
    return new Plugin({
        key: new PluginKey('placeholder'),
        props: {
            decorations: (state) => {
                const { doc } = state;
                const empty =
                    doc.textContent === '' &&
                    doc.childCount <= 1 &&
                    doc.content.size <= 2;

                if (!empty) {
                    return DecorationSet.empty;
                }

                const decorations = [];
                const decAttrs = {
                    class: 'placeholder',
                    'data-placeholder': placeholder,
                };
                doc.descendants((node, pos) => {
                    decorations.push(
                        Decoration.node(pos, pos + node.nodeSize, decAttrs),
                    );
                });
                return DecorationSet.create(doc, decorations);
            },
        },
    });
}

export const style = `
p { font-family: HyundaiSansTextJP, HyundaiSansTextKR, sans-serif !important;
    font-size: 12;
    font-weight: 300;
    color: #05141f;}
p.placeholder:first-child:before {
content: attr(data-placeholder);
float: left;
cursor: text;
height: 0;
font-size: 12px;
color: #9aa2a8;
}
`;
