import { Suspense, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Else, If, Then } from 'react-if';
import _ from 'lodash';

import { useTransaction, Text, Button, useAppRoot } from 'component/mux';
import { UnauthedRoutes } from 'config/routes';
import { TRANSID_AUTHENTICATION } from 'config/transaction';
import { DynamicRoute } from 'util/DynamicRoute';
import { TERMS, TRANSE_MODE } from 'util/pageUtil';
import { TransFailure } from 'util/transactionUtil';
import PrivacyPolicy from 'component/dialogComponent/TermsDialog/adminNoAuth/PrivacyPolicy';

const ONEID_ENV = JSON.parse(
    process.env.REACT_APP_ONEID ? process.env.REACT_APP_ONEID : '{}',
);

const OneIdLink = ({ attr, children }) => {
    const baseUrl = process.env.REACT_APP_ONEID_URL;
    const [linkUrl, setLinkUrl] = useState(baseUrl);
    const { customTransaction } = useTransaction(TRANSID_AUTHENTICATION);
    const intl = useIntl();
    useEffect(() => {
        if (_.isUndefined(attr)) {
            return;
        }

        customTransaction('fetchState', attr)
            .then((data) => {
                setLinkUrl(
                    baseUrl
                        .replace('{client_id}', attr['clientID'])
                        .replace('{redirect_uri}', attr['redirectURI'])
                        .replace('{state}', data['state'])
                        .replace('{lang}', attr['lang']),
                );
            })
            .catch((error) => {
                TransFailure({ intl, mode: TRANSE_MODE.GET, error });
            });
    }, [attr]);

    return (
        <a href={linkUrl} className="buttonStart">
            {children}
        </a>
    );
};

const MaingContent = ({ location }) => {
    const [dispTermsUse, setDispTermsUse] = useState(false);
    const [dispPrivacyPolicy, setDispPrivacyPolicy] = useState(false);
    const applyDomain =
        location?.pathname === '/admin' ? TERMS.ADMIN : TERMS.BIZ;
    const { language } = useAppRoot();

    const onTermsUse = () => {
        setDispTermsUse((prev) => {
            return !prev;
        });
    };

    const onPrivacyPolicy = () => {
        setDispPrivacyPolicy((prev) => {
            return !prev;
        });
    };

    return (
        <>
            <div id="wrap" className="wbLogin">
                <div className="wbLoginContent">
                    <div className="contentTop">
                        <img
                            src={`${process.env.PUBLIC_URL}/image/login/login_logo.png`}
                            alt="logo"
                        />
                        <If condition={'local' === process.env.REACT_APP_MODE}>
                            <Then>
                                <Text
                                    resId="Intro.Title.Admin"
                                    className="title"
                                />
                                <ul>
                                    <li>
                                        <a href="/redirect?code=_AWLJY7CNGEMV4QDXHLOQQ&state=YWRtaW46MTYzMzQyNTg1Mg==&type=SYSTEM_ADMIN">
                                            시스템 관리자
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/redirect?code=_AWLJY7CNGEMV4QDXHLOQQ&state=YWRtaW46MTYzMzQyNTg1Mg==&type=MOBILITY_MANAGER">
                                            위블 비즈관리자
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/user/redirect?code=_AWLJY7CNGEMV4QDXHLOQQ&state=YWRtaW46MTYzMzQyNTg1Mg==&type=BUSINESS_MANAGER">
                                            기업관리자
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/user/redirect?code=_AWLJY7CNGEMV4QDXHLOQQ&state=YWRtaW46MTYzMzQyNTg1Mg==&type=BUSINESS_OPERATOR">
                                            부서관리자
                                        </a>
                                    </li>
                                </ul>
                                <OneIdLink attr={ONEID_ENV['admin']}>
                                    <Text resId="Common.Main.GettingStart" />
                                </OneIdLink>
                            </Then>
                            <Else>
                                <If condition={location?.pathname === '/admin'}>
                                    <Then>
                                        <Text
                                            resId="Intro.Title.Admin"
                                            className="title"
                                        />
                                        <OneIdLink attr={ONEID_ENV['admin']}>
                                            <Text resId="Common.Main.GettingStart" />
                                        </OneIdLink>
                                    </Then>
                                    <Else>
                                        <Text
                                            resId="Intro.Title.Client.Admin"
                                            className="title"
                                        />
                                        <Text
                                            resId="Intro.Desc.Client.Admin"
                                            className="desc"
                                        />
                                        <OneIdLink
                                            attr={ONEID_ENV['personInCharge']}
                                        >
                                            <Text resId="Common.Main.GettingStart" />
                                        </OneIdLink>
                                    </Else>
                                </If>
                            </Else>
                        </If>
                    </div>
                    <div className="contentFooter">
                        <ul>
                            <If condition={applyDomain === TERMS.BIZ}>
                                <Then>
                                    <li>
                                        <Button
                                            onClick={onTermsUse}
                                            className="menuItemBold"
                                            resId="Common.Footer.Policy1"
                                        />
                                    </li>
                                </Then>
                            </If>
                            <li>
                                <Button
                                    onClick={onPrivacyPolicy}
                                    className="menuItemBold"
                                    resId="Common.Footer.Policy2"
                                />
                            </li>
                        </ul>
                        <Text
                            resId="Common.Footer.Address1"
                            className="address"
                        />
                        <Text
                            resId="Common.Footer.Address2"
                            className="address"
                        />
                        <Text
                            resId="Common.Footer.Copyright"
                            className="copy"
                        />
                    </div>
                </div>
            </div>
            {/* 이용약관 */}
            <PrivacyPolicy
                key="TermsUse"
                visible={dispTermsUse}
                handleClose={onTermsUse}
                applyDomain={applyDomain}
                titleResId="Common.StartAdmin.TermsUse.Title"
                transData = {{
                    termsGroupIDs:
                        applyDomain === TERMS.ADMIN ? TERMS.ADMIN : TERMS.BIZ,
                    termsTypes: 'Terms.Type.Join',
                    termsClassIDs: 'JOIN_SERVICE_USE',
                    useYn: 'Y',
                    activeDateYn: 'Y',
                    nationality: language === 'ko' ? 'KOR' : 'JPN',
                }}
            />
            {/* 개인정보 처리 방침 */}
            <PrivacyPolicy
                key="PrivacyPolicy"
                visible={dispPrivacyPolicy}
                handleClose={onPrivacyPolicy}
                applyDomain={applyDomain}
                titleResId="Common.StartAdmin.PrivacyPolicy.Title"
                transData={{
                    termsGroupIDs: TERMS.ADMIN,
                    termsTypes: 'Terms.Type.StartAdmin',
                    termsClassIDs: 'STARTADMIN_ADMIN_PRIVACY',
                    useYn: 'Y',
                    activeDateYn: 'Y',
                    nationality: language === 'ko' ? 'KOR' : 'JPN',
                }}
            />
        </>
    );
};

const UnAuthedApp = ({ onRanderHandle }) => {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route exact path="/" component={MaingContent}></Route>
                    <Route exact path="/admin" component={MaingContent}></Route>
                    <DynamicRoute
                        routes={UnauthedRoutes}
                        onRanderHandle={onRanderHandle}
                    ></DynamicRoute>
                </Switch>
            </Suspense>
        </>
    );
};

export { UnAuthedApp };
