import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import _ from 'lodash';
import { useIntl } from 'react-intl';

import { Text as MuxText } from 'mux';

const Text = ({ resId, text, ...rest }) => {
    const intl = useIntl();
    const chunkText = resId ? intl.formatMessage({ id: resId }) : text;
    return (
        <MuxText {...rest}>
            {_.isString(chunkText) ? parse(chunkText) : chunkText}
        </MuxText>
    );
};

Text.propTypes = {
    resId: PropTypes.string,
};

export default Text;
