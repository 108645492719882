import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Dialog, Form, TextOrSelect, useTransaction } from 'component/mux';
import { TRANSID_TERMS } from 'config/transaction';
import { TransFailure } from 'util/transactionUtil';
import { INPUT_WIDTH_CLASS } from 'util/pageUtil';
import { NoDataTag } from 'component/layer/DetailLayer';
import {
    DATETIME_FORMAT,
    parseFormatStartToEnd,
    parseFormatStartToEndLess,
} from 'util/LongTimeUtil';
import { If, Then } from 'react-if';

const TermsSelectView = ({ items, onValueChange }) => {
    const intl = useIntl();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (_.size(items) < 1) {
            return <></>;
        }

        const _options = [];
        _.forEach(items, (element) => {
            const { ID, startDate, endDate } = element;
            const label =
                endDate === 0
                    ? parseFormatStartToEndLess(
                          startDate,
                          DATETIME_FORMAT[0],
                          intl,
                          '-',
                      )
                    : parseFormatStartToEnd(
                          startDate,
                          endDate,
                          DATETIME_FORMAT[0],
                          '-',
                      );
            _options.push({
                label: label,
                value: ID,
            });
        });

        if (_.size(_options) > 0) {
            setOptions(_options);
            onValueChange(_options[0].value);
        }
    }, [items]);

    return _.size(options) > 0 ? (
        <Form>
            <TextOrSelect
                editable
                name="termsOptions"
                option={options}
                className={INPUT_WIDTH_CLASS.W250}
                value={options[0].value}
                onValueChange={({ data }) => onValueChange(data?.termsOptions)}
            />
        </Form>
    ) : (
        <></>
    );
};

const TermsContentsView = ({ items, selectedID }) => {
    if (_.size(items) < 1) {
        return <NoDataTag />;
    }
    const findItem = _.find(items, { ID: selectedID });
    return (
        <div>
            <div
                dangerouslySetInnerHTML={{
                    __html: findItem?.contents,
                }}
            />
        </div>
    );
};

const PrivacyPolicy = ({ visible, handleClose, applyDomain, titleResId, transData }) => {
    const intl = useIntl();
    const { customTransaction } = useTransaction(TRANSID_TERMS);
    const [termsItems, setTermsItems] = useState([]);
    const [selectedID, setSelectedID] = useState();

    useEffect(() => {
        if (!visible) {
            return;
        }

        customTransaction('getGroupList', transData)
            .then(({ items }) => {
                setTermsItems(items);
            })
            .catch((error) => {
                TransFailure({ intl, error });
            });
    }, [visible, applyDomain]);

    return (
        <Dialog
            type="default"
            visible={visible}
            resId={titleResId || "Common.StartAdmin.PrivacyPolicy.Title" }
            onCancel={handleClose}
            width={720}
            bodyStyle={{ height: 400, overflowY: 'scroll' }}
            footer={false}
        >
            <TermsSelectView items={termsItems} onValueChange={setSelectedID} />
            <br />
            <If condition={selectedID}>
                <Then>
                    <TermsContentsView
                        items={termsItems}
                        selectedID={selectedID}
                    />
                </Then>
            </If>
        </Dialog>
    );
};

export default PrivacyPolicy;
export { TermsSelectView, TermsContentsView };
