import React, { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { EditorToolsSettings, EditorUtils } from '@progress/kendo-react-editor';
import { Button } from '@progress/kendo-react-buttons';

import { TextOrInput, TextOrCheckBox, Section, Element } from 'component/mux';
import CustomDialog from './CustomDialog';

/**
 * /kor.mop.bo.web/node_modules/@progress/kendo-react-editor/dist/npm/tools/insertLink.js
 * 분석하여 작성
 */

const { mark, commandName, props } = EditorToolsSettings.link;

const InsertLink = ({ view }) => {
    const state = view && view.state;
    const linkMark =
        state && EditorUtils.getMark(state, state.schema.marks[mark]);

    const { href, title, target } = useMemo(() => {
        return linkMark?.attrs ? linkMark.attrs : {};
    }, [linkMark]);

    const [visible, setVisible] = useState(false);

    const handleCancel = () => setVisible(!visible);

    const handleSubmit = (data) => {
        const { target, ...other } = data;
        const attrs = {
            target: target?.length > 0 ? target[0] : undefined,
            ...other,
        };
        EditorUtils.applyLink(view, { mark, attrs }, commandName);
        handleCancel();
    };

    return (
        <>
            <Button
                onClick={handleCancel}
                disabled={!linkMark && state?.selection.empty}
                {...props}
            />
            <CustomDialog
                visible={visible}
                resId="Title.WebEditor.InsertLink"
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            >
                <Section>
                    <Element resId="WebEditor.InsertLink.Href" span={2}>
                        <TextOrInput name="href" value={href} />
                    </Element>
                    <Element resId="WebEditor.InsertLink.Title" span={2}>
                        <TextOrInput name="title" value={title} />
                    </Element>
                    <Element
                        resId="WebEditor.InsertLink.Target"
                        span={2}
                        contentStyle={{ alignSelf: 'center' }}
                    >
                        <TextOrCheckBox
                            name="target"
                            defaultValue={target?.split(',')}
                            option={[
                                {
                                    label: (
                                        <FormattedMessage id="WebEditor.InsertLink.Target.Label" />
                                    ),
                                    value: '_blank',
                                },
                            ]}
                        />
                    </Element>
                </Section>
            </CustomDialog>
        </>
    );
};

export default InsertLink;
