import moment from 'moment';
import React from 'react';
import { DATETIME_FORMAT, parseFormatStr } from './LongTimeUtil';

const RESERVATION_TYPE = {
    personal: 'PERSONAL',
    business: 'BUSINESS',
};

const CHARGE_GROUP_TYPE = {
    timebase: 'TIMEBASE',
    special: 'SPECIAL',
    pass: 'PASS',
    oneDay: 'ONEDAY',
    relation: 'RELATION',
    space: 'SPACE',
    camping: 'CAMPING',
};

const ItemsToCodeLists = (items, labelKey, valueKey) => {
    if (!items?.length) {
        return [];
    }

    return items.map((item) => ({
        ...item,
        label: item[labelKey],
        value: item[valueKey],
    }));
};

// long 형식의 시간을 넣으면 기간을 return
const CalculateDateTerm = (startTime, endTime) => {
    if (!startTime || !endTime) return { days: 0, hours: 0, minutes: 0 };
    const newStartTime = moment(startTime).startOf('minute');
    const newEndTime = moment(endTime).startOf('minute');
    const duration = moment.duration(newEndTime - newStartTime, 'milliseconds');
    let days = Math.floor(duration.asDays());
    let hours = Math.floor(duration.asHours()) - days * 24;
    let minutes = Math.floor(duration.asMinutes()) - days * 1440 - hours * 60;
    return { days, hours, minutes };
};

const CalculateDateTermHoursAndMinutes = (startTime, endTime) => {
    if (!startTime || !endTime) return { days: 0, hours: 0, minutes: 0 };
    const newStartTime = moment(startTime).startOf('minute');
    const newEndTime = moment(endTime).startOf('minute');
    const duration = moment.duration(newEndTime - newStartTime, 'milliseconds');
    let hours = Math.floor(duration.asHours());
    let minutes = Math.floor(duration.asMinutes()) - hours * 60;
    return { hours, minutes };
};

const CalculateDateTermMinutes = (startTime, endTime) => {
    if (!startTime || !endTime) return { days: 0, hours: 0, minutes: 0 };
    const newStartTime = moment(startTime).startOf('minute');
    const newEndTime = moment(endTime).startOf('minute');
    const duration = moment.duration(newEndTime - newStartTime, 'milliseconds');
    return Math.floor(duration.asMinutes());
};

const MinToHourAndMin = (minutes) => {
    if (!minutes || minutes < 60) return { hours: 0, minutes };
    const hours = parseInt(minutes / 60);
    const newMinutes = minutes % 60;
    return { hours, minutes: newMinutes };
};

// long 형식의 시간을 넣으면 분을 return
const CalculateMinuteTerm = (startTime, endTime) => {
    if (!startTime || !endTime || startTime >= endTime) return 0;
    const newStartTime = moment(startTime).startOf('minute');
    const newEndTime = moment(endTime).startOf('minute');
    return Math.floor(
        moment.duration(newEndTime - newStartTime, 'milliseconds').asMinutes(),
    );
};

const DateTermWithStyles = ({ days, hours, minutes }) => {
    return {
        days: <span className="wbSpanPointGreen">{days}</span>,
        hours: <span className="wbSpanPointGreen">{hours}</span>,
        minutes: <span className="wbSpanPointGreen">{minutes}</span>,
    };
};

const createHolidayLabel = (date, baseTime, isPost) => {
    // 2021-06-10 18:00 ~ 2021-06-11 18:00
    if (isPost) {
        return `${moment(date).format('YYYY-MM-DD')} ${baseTime} ~ ${moment(
            date,
        )
            .clone()
            .add(1, 'd')
            .format('YYYY-MM-DD')} ${baseTime}`;
    } else {
        return `${moment(date)
            .clone()
            .subtract(1, 'd')
            .format('YYYY-MM-DD')} ${baseTime} ~ ${moment(date).format(
            'YYYY-MM-DD',
        )} ${baseTime}`;
    }
};

const getContinualHoliday = (holidays, isPost, baseTime) => {
    // holidays 받아온 것들에 대해서 reservationDate 랑 붙어있는지 체크
    const continualHolidays = [];
    if (!holidays) return continualHolidays;
    let labelTime = moment(baseTime).format('HH:mm');
    if (isPost) {
        let baseDate = moment(baseTime).subtract(1, 'd').startOf('day');
        for (let idx of _.range(1, holidays.length + 1)) {
            let continualDay = holidays.find(
                (item) =>
                    item.holidayDate ===
                    baseDate.clone().add(idx, 'd').format('YYYYMMDD'),
            );
            if (continualDay) {
                let value = [];
                let idx = 1;
                while (
                    continualDay.holidayDate >=
                    baseDate.clone().add(idx, 'd').format('YYYYMMDD')
                ) {
                    value.push(
                        baseDate.clone().add(idx, 'd').format('YYYYMMDD'),
                    );
                    idx = idx + 1;
                }
                continualHolidays.push({
                    ...continualDay,
                    idx: idx - 1,
                    label: createHolidayLabel(
                        moment(continualDay.holidayDate, 'YYYYMMDD').valueOf(),
                        labelTime,
                        isPost,
                    ),
                    value: continualDay.holidayDate,
                });
            } else {
                break;
            }
        }
    } else {
        let baseDate = moment(baseTime).add(1, 'd').startOf('day');
        for (let idx of _.range(1, holidays.length + 1)) {
            let continualDay = holidays.find(
                (item) =>
                    item.holidayDate ===
                    baseDate.clone().subtract(idx, 'd').format('YYYYMMDD'),
            );
            if (continualDay) {
                let value = [];
                let idx = 1;
                while (
                    continualDay.holidayDate <=
                    baseDate.clone().subtract(idx, 'd').format('YYYYMMDD')
                ) {
                    value.push(
                        baseDate.clone().subtract(idx, 'd').format('YYYYMMDD'),
                    );
                    idx = idx + 1;
                }
                continualHolidays.push({
                    ...continualDay,
                    idx: idx - 1,
                    label: createHolidayLabel(
                        moment(continualDay.holidayDate, 'YYYYMMDD').valueOf(),
                        labelTime,
                        isPost,
                    ),
                    value: continualDay.holidayDate,
                });
            } else {
                break;
            }
        }
    }

    return _.sortBy(
        continualHolidays,
        ['holidayDate'],
        isPost ? 'asc' : 'desc',
    );
};

const getDisabledOptions = (options, fieldName, idx, nextIdx) => {
    const maxLen = options?.length || 0;
    let disabledOption = _.map(options, (opt) => {
        const optIdx = opt[fieldName];
        if (optIdx === idx) {
            opt.disabled = false;
        } else {
            opt.disabled = true;
        }
        return opt;
    });

    // 마지막 값이 아니라면 이전값 까지 체크가능 (체크 푸는용도)
    if (maxLen !== idx && nextIdx) {
        disabledOption = _.map(disabledOption, (opt) => {
            const optIdx = opt[fieldName];
            if (optIdx === nextIdx) {
                opt.disabled = false;
            }
            return opt;
        });
    }

    return disabledOption;
};

const getContinualDays = (days, firstDay, isPost) => {
    const continualDays = [];
    const baseDay = moment(firstDay.value, 'YYYYMMDD');
    if (isPost) {
        for (let idx of _.range(0, days.length)) {
            let continualDay = days.find(
                (item) =>
                    item === baseDay.clone().add(idx, 'd').format('YYYYMMDD'),
            );
            if (continualDay) {
                continualDays.push(continualDay);
            } else {
                break;
            }
        }
    } else {
        for (let idx of _.range(0, days.length)) {
            let continualDay = days.find(
                (item) =>
                    item ===
                    baseDay.clone().subtract(idx, 'd').format('YYYYMMDD'),
            );
            if (continualDay) {
                continualDays.push(continualDay);
            } else {
                break;
            }
        }
    }
    return continualDays;
};

const getRentalStarTime = (
    chargeCombination,
    defaultValue,
    fieldName = 'rentalStartTime',
) => {
    return chargeCombination?.length > 0
        ? Math.min(
              ..._.filter(
                  _.map(chargeCombination, (item) => item[fieldName]),
                  (value) => !!value,
              ),
          )
        : defaultValue;
};

const getRentalEndTime = (
    chargeCombination,
    defaultValue,
    fieldName = 'rentalEndTime',
) => {
    return chargeCombination?.length > 0
        ? Math.max(
              ..._.filter(
                  _.map(chargeCombination, (item) => item[fieldName]),
                  (value) => !!value,
              ),
          )
        : defaultValue;
};

const getRentalTime = (reservationData) => {
    const rentalStartTime = getRentalStarTime(
        reservationData.chargeCombination,
        reservationData.rentalStartTime,
    );
    const rentalEndTime = getRentalEndTime(
        reservationData.chargeCombination,
        reservationData.rentalEndTime,
    );

    return {
        startDate: parseFormatStr({
            text: rentalStartTime,
            formatStr: DATETIME_FORMAT[1],
        }),
        endDate: parseFormatStr({
            text: rentalEndTime,
            formatStr: DATETIME_FORMAT[1],
        }),
    };
};

const isRelationCharge = (reservationData) => {
    if (reservationData.chargeCombination?.length > 1) {
        return (
            _.filter(reservationData.chargeCombination, (item) => {
                return item.reservationType === 'PERSONAL';
            })?.length > 0 &&
            _.filter(reservationData.chargeCombination, (item) => {
                return item.reservationType === 'BUSINESS';
            })?.length > 0
        );
    }
    return false;
};

export {
    RESERVATION_TYPE,
    CHARGE_GROUP_TYPE,
    ItemsToCodeLists,
    CalculateDateTerm,
    CalculateDateTermHoursAndMinutes,
    CalculateDateTermMinutes,
    MinToHourAndMin,
    CalculateMinuteTerm,
    DateTermWithStyles,
    getContinualHoliday,
    getDisabledOptions,
    getContinualDays,
    isRelationCharge,
    getRentalStarTime,
    getRentalEndTime,
    getRentalTime,
};
