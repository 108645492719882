import { useAppRoot } from 'component/mux';
import {
    getMsgByCode,
    getCodeByIntValue,
    getMsgByCodeChildGroupKey,
} from 'util/codeUtil';

const CodeNumValueText = ({ groupKey, value, defaultMessage }) => {
    const { fetchCode } = useAppRoot();
    const code = getCodeByIntValue({
        codeList: fetchCode(groupKey),
        value,
        defaultMessage,
    });

    return code?.label || code?.name || defaultMessage;
};

const CodeValueText = ({ groupKey, value, defaultMessage }) => {
    const { fetchCode } = useAppRoot();
    return getMsgByCode({
        codeList: fetchCode(groupKey),
        value,
        defaultMessage,
    });
};

const CodeGroupText = ({ groupKey, value, defaultValue, defaultMessage }) => {
    const { fetchCode } = useAppRoot();
    return getMsgByCodeChildGroupKey({
        codeList: fetchCode(groupKey),
        childGroupKey: value,
        defaultValue,
        defaultMessage,
    });
};

export { CodeNumValueText, CodeValueText, CodeGroupText };
