import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Form as MuxForm } from 'mux';
import _ from 'lodash';

const Form = forwardRef((props, ref) => {
    const { formId, onSubmit, allowEmptyField, ...rest } = props;

    const handleSubmit = useCallback(
        (data, e) => {
            // 빈값 제거
            const formData = allowEmptyField
                ? _.pickBy(data, (value, key) => {
                      if (key.startsWith('$$##') && key.endsWith('##$$'))
                          return false;
                      if (_.isUndefined(value)) return false;
                      return true;
                  })
                : data;
            return onSubmit(formData, e);
        },
        [onSubmit, allowEmptyField],
    );

    return <MuxForm id={formId} onSubmit={handleSubmit} {...rest} ref={ref} />;
});

Form.propTypes = {
    formId: PropTypes.string,
    onSubmit: PropTypes.func,
    defaultValues: PropTypes.object,
    allowEmptyField: PropTypes.bool.isRequired, // form field의 값이 없더라도 submit시 onSubmit 이벤트가 발생하도록 하는 옵션,
    resetable: PropTypes.bool.isRequired, // reset이벤트 발생후 onSubmit 이벤트가 발생하도록 하는 옵션
    setFormMethod: PropTypes.func,
    onDetected: PropTypes.func,
    autoComplete: PropTypes.string,
};

Form.defaultProps = {
    allowEmptyField: true,
    resetable: false,
    autoComplete: 'off',
};

export default Form;
