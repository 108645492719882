import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Else, If, Then } from 'react-if';

import {
    useTransaction,
    Text,
    useAppRoot,
} from 'component/mux';
import { TRANSID_AUTHENTICATION } from 'config/transaction';
import { InfoMessagePage } from 'util/transactionUtil';

// 일본 ccSP + JAM
const ONEID_ENV = JSON.parse(
    process.env.REACT_APP_ONEID ? process.env.REACT_APP_ONEID : '{}',
);

const OneIdLink = ({ attr, children }) => {
    const intl = useIntl();
    const baseUrl = process.env.REACT_APP_ONEID_URL;
    const [linkUrl, setLinkUrl] = useState();
    const { language } = useAppRoot();
    const { customTransaction } = useTransaction(TRANSID_AUTHENTICATION);

    const checkState = useCallback(() => {
        if (!linkUrl) {
            InfoMessagePage({
                intl,
                titleMsgID: 'Common.Login.State.Error.Title',
                msgID: 'Message.Login.State.Error',
                onOk: () => {
                    window.location.reload();
                },
            });
            return;
        }
        window.location.href = linkUrl;
    }, [linkUrl]);

    useEffect(() => {
        if (_.isUndefined(attr)) {
            return;
        }

        customTransaction('fetchState', attr)
            .then((data) => {
                setLinkUrl(
                    baseUrl
                        .replace('{client_id}', attr['clientID'])
                        .replace('{redirect_uri}', attr['redirectURI'])
                        .replace('{state}', data['state'])
                        .replace('{lang}', attr['lang']),
                );
            })
            .catch((error) => {
                // TODO 에러메시지, 후속조치
                // TransFailure({ error });
            });
    }, [attr, language]);

    return (
        <a onClick={checkState} className="buttonStart">
            {children}
        </a>
    );
};

const UnAuthedContent = ({ language, isAdmin }) => {
    const getOneIdEnv = useCallback(
        (key) => {
            if (_.isEmpty(key) || _.isEmpty(language)) {
                return;
            }
            const oneObj = ONEID_ENV[key];
            oneObj.lang = language === 'ko' ? 'en' : language;
            return oneObj;
        },
        [language],
    );

    return (
        <>
            <If condition={isAdmin}>
                <Then>
                    <Text
                        resId="Intro.Title.Admin"
                        className="title"
                    />
                    <OneIdLink attr={getOneIdEnv('admin')}>
                        <Text resId="Common.Main.GettingStart" />
                    </OneIdLink>
                </Then>
                <Else>
                    <Text
                        resId="Intro.Title.Client.Admin"
                        className="title"
                    />
                    <Text
                        resId="Intro.Desc.Client.Admin"
                        className="desc"
                    />
                    <OneIdLink
                        attr={getOneIdEnv('personInCharge')}
                    >
                        <Text resId="Common.Main.GettingStart" />
                    </OneIdLink>
                </Else>
            </If>
        </>
    );
};

export { UnAuthedContent };
