import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Text, Button } from 'component/mux';
import { ActionButton } from 'component/ActionButton';
import { createPortal } from 'react-dom';

const CustomDialog = ({
    resId,
    onSubmit,
    visible,
    onCancel,
    buttons,
    children,
    element,
    width = 840,
    onDetected,
    style,
    headerStyle,
    header,
    bodyStyle,
    footer = true,
}) => {
    // const { getValues } = useForm();
    const [WEB_EDITOR_DIALOG_FORM_ID] = useState(
        'WEB_EDITOR_DIALOG_' + _.uniqueId(),
    );
    const [data, setData] = useState({});
    // const href = watch('href');
    const handleSubmit = () => {
        onSubmit(data);
    };
    const handleDetected = ({ form }) => {
        if (form) {
            setData(form.value);
        }
        if (typeof onDetected === 'function') {
            onDetected(form?.value);
        }
    };
    if (!buttons || buttons.length < 1) {
        buttons = (
            <>
                <ActionButton
                    type="cancel"
                    label={{ resId: 'Btn.Cancel' }}
                    onClick={onCancel}
                />
                <ActionButton
                    type="button"
                    label={{ resId: 'Btn.Confirm' }}
                    targetForm={WEB_EDITOR_DIALOG_FORM_ID}
                    onClick={handleSubmit}
                />
            </>
        );
    }
    return visible ? (
        createPortal(
            <div className="wbPopupWrap noBg">
                <Form
                    onDetected={handleDetected}
                    onSubmit={handleSubmit}
                    formId={WEB_EDITOR_DIALOG_FORM_ID}
                >
                    <div
                        className="wbPopupContainer"
                        style={{ width, ...style }}
                    >
                        <div className="wbPopupHeader" style={headerStyle}>
                            {header ? (
                                header
                            ) : (
                                <>
                                    <Text
                                        resId={resId}
                                        className="popupTitle"
                                    />
                                    <Button
                                        type="text"
                                        className="btnPopupClose"
                                        onClick={onCancel}
                                        icon={<span className="iconClose" />}
                                    />
                                </>
                            )}
                        </div>
                        <div className="wbPopupContent">
                            <div className="popupConfirmText" style={bodyStyle}>
                                {children}
                            </div>
                            {footer && (
                                <div className="wbPopupFunction">
                                    <div className="wbPopupCenterBtn">
                                        {buttons}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Form>
            </div>,
            document.body,
        )
    ) : (
        <></>
    );
};

CustomDialog.propTypes = {
    resId: PropTypes.string,
    buttons: PropTypes.element,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
    onDetected: PropTypes.func,
    element: PropTypes.object,
};
export default CustomDialog;
