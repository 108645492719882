const language = {
    // 번역의뢰 WEB 0330    - it.js
    'Common.All': 'Tutti',
    'Common.Login': 'login',
    'Common.Logout': 'Logout',
    'Common.Logout.Hello': 'Salve, {user}.',
    'Common.Input.Required': 'Valore obbligatorio.',
    'Common.Select.Required': 'Il valore deve essere selezionato',
    'Common.Input.EnterSearchKeyword': 'Inserire parole chiavi',
    'Common.Btn.Selected': 'Selezionato',
    'Common.Btn.Select': 'Seleziona',
    'Common.Btn.AllSearch': 'Ricerca completa',
    'Common.Btn.Cancel': 'Annulla',
    'Common.Btn.Save': 'Salvare',
    'Common.Btn.CancelEdit': 'Annulla',
    'Common.Btn.CancelExtend': 'Annulla',
    'Common.Btn.Filter': 'Opzione',
    'Common.Btn.Create': 'Registra',
    'Common.Btn.NewCreate': 'Nuova registrazione',
    'Common.Btn.CreateComplete': 'Registrazione completata',
    'Common.Btn.Edit': 'Modifica.',
    'Common.Btn.EditInfo': 'Modifica Informazioni',
    'Common.Btn.EditComplete': 'Modificare',
    'Common.Btn.Confirm': 'OK',
    'Common.Btn.EditBusiness': 'Modifica.',
    'Common.Btn.EditInformation': 'Modifica.',
    'Common.Btn.CompleteReservation': 'Prenotazione completata',
    'Common.Btn.CompleteEdit': 'Modifica.',
    'Common.Btn.Add': 'Aggiungi',
    'Common.Btn.Delete': 'Elimina.',
    'Common.Btn.InUse': 'Utilizzo',
    'Common.Btn.List': 'Elenco',
    'Common.Btn.Return': 'Restituire',
    'Common.Btn.ForceEdit': 'Modifica forzata',
    'Common.Btn.ExtendReturn': 'Estendere la data di restituzione',
    'Common.Btn.CompleteExtend': 'Estendere la data di restituzione',
    'Common.Btn.ForceReturn': 'Restituzione forzata',
    'Common.Btn.CancelReservation': 'Annulla prenotazione',
    'Common.Btn.Approve': 'Approva',
    'Common.Btn.Deny': 'Rifiuta',
    'Common.Btn.PrivateDeny': 'Revoca della società',
    'Common.Btn.Map': 'Seleziona dalla mappa',
    'Common.Btn.SearchWeek': '1 settimana',
    'Common.Btn.Search1Month': '1 mese',
    'Common.Btn.Search3Month': '3 mesi',
    'Common.Btn.Show.Edit': 'Modifica',
    'Common.Btn.AddVehicle': 'Aggiungi veicolo',
    'Common.Btn.AllSelect': 'Seleziona Tutto',
    'Common.Btn.Apply': 'Applica',
    'Common.Btn.MultiSelected': 'Selezione multipla possibile',
    'Common.Btn.CarLog': 'Registro di guida',
    'Common.Confirm.Create': 'Registrarlo?',
    'Common.Confirm.Update': 'Modificare?',
    'Common.Confirm.Delete': 'eliminare?',
    'Common.Confirm.Deletes': 'Eliminare {count} casi?',
    'Common.Confirm.Approve': 'Approvare?',
    'Common.Confirm.Deny': 'Rifiutarlo?',
    'Common.Confirm.Pay': 'Si desidera effettuare il pagamento?',
    'Common.Confirm.Action': 'Eseguire la funzione?',
    'Common.Confirm.UserDelete': "Cancellare l'iscrizione?",
    'Common.Confirm.PwdChange': 'Modificare la password?',
    'Common.Confirm.InUse':
        'Cambiare il veicolo portandolo allo status In Uso?',
    'Common.Alert': 'Impossibile eseguire la funzione.',
    'Common.Created.Success': 'Registrato',
    'Common.Updated.Success': 'Modificato.',
    'Common.Deleted.Success': 'Eliminato.',
    'Common.Create.Fail': 'Impossibile registrare.',
    'Common.Update.Fail': 'Impossibile modificare.',
    'Common.Delete.Fail': 'Impossibile eliminare.',
    'Common.Search.Fail': 'La ricerca non è andata a buon fine.',
    'Common.Print': 'Stampa',
    'Common.Alert.Delete.No.Checked': 'Selezionare un elemento da eliminare.',
    'Common.Alert.Approve.No.Checked': 'Seleziona un elemento da approvare',
    'Common.Alert.Deny.No.Checked': 'Selezionare un elemento da rifiutare.',
    'Common.Alert.Pay.No.Checked': 'Selezionare un elemento da pagare.',
    'Common.Msg.NoData': 'Non ci sono dati recuperati.',
    'Common.Msg.NoSearchData': 'Non ci sono risultati di ricerca.',
    'Common.Msg.NoVehicle': 'Il veicolo non esiste.',
    'Common.Unit.Distance.meter': 'm',
    'Common.Unit.Money.10000': '0000EUR',
    'Common.Unit.Money.0': 'EUR',
    'Common.Unit.Money.0.Value': '{value} EUR',
    'Common.Unit.Vehicle.Count': 'veicoli',
    'Common.Unit.Vehicle.Count.Value': '{value} veicoli',
    'Common.Unit.Short.Hour': 'Ora',
    'Common.Unit.Hour': 'ore',
    'Common.Unit.Hour.Value': '{hours} ore',
    'Common.Unit.Minute': 'minuti',
    'Common.Unit.Minute.Value': '{value} minuti',
    'Common.Unit.Age.Over': 'anni o più',
    'Common.Unit.DiffDays.DaysHours.Valule': '{days} giorni {hours} ore',
    'Common.Unit.DiffDays.Days.Valule': '{days} giorni',
    'Common.Unit.DiffDays.Hours.Valule': '{hours} ore',
    'Common.Unit.Days': 'Giorno',
    'Common.Unit.Count': 'Conteggio',
    'Common.Unit.Month': 'Mese',
    'Common.Today': 'Oggi',
    'Common.Back': 'Precedente',
    'Common.BirthDate.Year': 'Anno',
    'Common.BirthDate.Month': 'Mese',
    'Common.BirthDate.Day': 'Giorno',
    'Common.Role.Warn': 'Non hai il permesso.',
    'Common.Validator.Help.Number': 'Inserire solo numeri.',
    'Common.Validator.Help.Email':
        "Inserire l'indirizzo in un formato corretto.",
    'Common.Validator.Help.MaxNumber':
        'È possibile inserire {compareValue} o meno.',
    'Common.Validator.Help.MinNumber':
        'È possibile inserire {compareValue} o più.',
    'Common.Language': 'Lingua',
    'Main.Title':
        'Confronta le condizioni di noleggio da breve, a medio-lungo termine {br}e prenota un veicolo comodamente.',
    'Main.SubTitle': 'Le prenotazioni e i pagamenti sono resi più facili.',
    'Main.GettingStart': 'Guida Introduttiva',
    'Footer.Policy1': 'Termini di servizio',
    'Footer.Policy2': 'Dichiarazione di riservatezza',
    'Footer.Mocean': 'MOCEAN',
    'Footer.Address':
        '907-ho, A-dong, Bundang-Suji U-TOWER, 767, Sinsu-ro, Suji-gu, Yongin-si, Gyeonggi-do, Repubblica di Korea',
    'DayTimePicker.Help.From.Selected': 'Selezionare una data di inizio.',
    'DayTimePicker.Help.From.After.Now':
        'La data di inizio deve essere anteriore a quella attuale.',
    'DayTimePicker.Help.To.Selected': 'Selezionare una data di fine noleggio.',
    'DayTimePicker.Help.To.Before.From':
        'La data di fine deve essere successiva alla data di inizio.',
    'Login.Alert.SystemError': 'Guasto temporaneo del sistema',
    'Auth.Check.Alert.SystemError':
        'Accesso effettuato da un percorso sbagliato. Accedere di nuovo.',
    'Join.Alert.SystemError': 'Effettuare nuovamente il login.',
    'Auth.Alert.Expired':
        'Sei disconnesso poiché la sessione è scaduta. Effettua di nuovo il login.',
    'Auth.Alert.Forbidden': 'Non hai il permesso.',
    'Auth.Wait': 'Attendere un momento!',
    'Menu.VehicleManager': 'Gestione della flotta',
    'Menu.ReservationManager': 'Gestione prenotazioni',
    'Menu.VehicleUseManager': 'Completamento del noleggio',
    'Menu.PaymentManager': 'Gestione vendite/accordi',
    // 'Menu.SalesAndCalculation': 'Gestione vendite/regolamento',
    // 'Menu.SalesManager': 'Gestione vendite/regolamento',
    // 'Menu.LocationManager': 'Gestione della localizzazione',
    // 'Menu.UserManager': 'Gestione delle membership',
    'Menu.Location': 'Gestione base',
    // 'Menu.BusinessManager': 'Gestione della società',
    // 'Menu.BusinessCustomrList': 'Elenco clienti della società',
    // 'Menu.BusinessCustomerEditList': 'Modifica elenco clienti della società',
    'Menu.Terminal': 'Gestione terminali',
    // 'Menu.User': 'Gestione utenti',
    'Menu.MyPage': 'La mia pagina',
    'Title.Main': 'Principale',
    'Title.VehicleMonitoring': 'Controllo del veicolo',
    'Title.VehicleManager': 'Gestione della flotta',
    'Title.Vehicle': 'Visualizza/registra veicolo',
    'Title.VehicleInfo': 'Visualizza veicolo',
    'Title.VehicleCreate': 'Immatricolazione del veicolo',
    'Title.VehicleModel': 'Gestione modello del veicolo',
    'Title.Terminal': 'Gestione terminali',
    'Title.ReservationManager': 'Gestione prenotazioni',
    'Title.ReservationCreate': 'Nuova prenotazione',
    'Title.ReservationTestCarCreate': "Prenotazione di un'auto di prova",
    'Title.MakeReservation.BUSINESS_CORP': 'Nuova prenotazione (Affiliato)',
    'Title.UseList': 'Stato di utilizzo',
    'Title.EndUseList': 'Completamento del noleggio',
    'Title.PaymentList': 'Dettagli di pagamento',
    'Title.Charge': 'Piano tariffario',
    'Title.ChargeVehicle': 'Gestione del piano tariffario per veicolo',
    'Title.UseStateList': 'Status del noleggio',
    'Title.Insurance': 'Premio',
    'Title.InsuranceVehicle': 'Gestione premi per veicolo',
    'Title.PenaltyAndToll': 'Penalità/pedaggio',
    'Title.Location': 'Base',
    'Title.LocationCreate': 'Registrazione base',
    'Title.LocationEdit': 'Modifica base',
    'Title.Member': 'Manager',
    'Title.Business': 'Gestione operatore',
    'Title.BusinessCreate': 'Registrazione operatore',
    'Title.Customer': 'Gestione soci',
    'Title.TestCarConfigList': 'Limite fissato per le auto di prova',
    'Title.License': 'Gestione delle licenze',
    'Title.Code': 'Gestione del codice',
    'Title.Menu': 'Gestione menu',
    'Title.Role': 'Gestione degli accessi al ruolo',
    'Title.Program': 'Gestione dei programmi',
    'Title.Message': 'Gestione messaggi',
    'Title.AccidentCreate': "Registrazione dell'incidente/riparazione",
    // 'Title.TestCarConfigList': 'Configurazione dell'automobile di prova',
    'Menu.Holiday': 'Gestione delle auto di prova per le vacanze',
    'Monitoring.LatestUpdate': 'Ultimo aggiornamento',
    'Monitoring.ViewUnavailableVehicle': 'Visualizza problema veicolo',
    'Monitoring.ViewAllVehicle': 'Visualizza tutti i veicoli',
    'Monitoring.CurrentLocation': 'Posizione attuale',
    'Monitoring.Location': 'Posizione base',
    'Monitoring.SeeMore': 'Visualizza altro',
    'Monitoring.NoUnavailableVehicle': "Non c'è nessun veicolo problematico.",
    'Monitoring.Vehicle.DTE': 'Livello di carburante',
    'Monitoring.Vehicle.AUXbattery': 'Tensione della batteria',
    'Monitoring.Vehicle.Connectivity': 'Stato della comunicazione',
    'Monitoring.Unknown': 'Sconosciuto',
    'Monitoring.Normal': 'Normale',
    'Reservation.Btn.Create': 'Nuova prenotazione',
    'Reservation.Btn.Filter': 'Opzione',
    'Reservation.Btn.Selected': 'Prenotazione completata',
    'Reservation.Btn.Download': 'Download CSV',
    'Reservation.Code': 'N. prenotazione',
    'Reservation.Birth': 'Data di nascita',
    'Reservation.Birth.Placeholder': 'AAAA. MM. GG',
    'Reservation.BirthYear.Placeholder': '2000',
    'Reservation.BirthMonth.Placeholder': '0',
    'Reservation.BirthDate.Placeholder': '0',
    'Reservation.Address': 'Indirizzo',
    'Reservation.Address.Zip.Placeholder': 'Codice postale',
    'Reservation.Address.Placeholder': "Inserire l'indirizzo.",
    'Reservation.DetailAddress.Placeholder': "Inserire l'indirizzo.",
    'Reservation.Address.Search': 'Cerca codice postale',
    'Reservation.LicenseInfo': 'Informazioni sulla patente di guida',
    'Reservation.License': 'Patente di guida n.',
    'Reservation.License.Placeholder': 'Patente di guida n.',
    'Reservation.License.Confirm': 'Autenticazione licenza',
    'Reservation.License.ConfirmFinish': 'Autenticato',
    'Reservation.License.DueDate': 'Data di scadenza licenza',
    'Reservation.License.DueDate.DayPicker':
        'Inserire la data di scadenza della patente.',
    'Reservation.License.Check': "Completare l'autenticazione della licenza.",
    'Reservation.License.AdditionalCheck':
        "Completare l'autenticazione della patente di guida.",
    'Reservation.License.ValidCheck':
        "Inserire tutti i campi per l'autenticazione della licenza.",
    'Reservation.Edit.Warn':
        "La prenotazione non può essere modificata una volta trascorso l'orario di inizio della prenotazione.",
    'Reservation.GetProfile.NoData':
        'Nessun dato corrispondente a un membro trovato',
    'Reservation.GetEmployeeProfile.NoData':
        'Informazioni sui dipendenti non trovate',
    'Reservation.GetProfile.fail':
        'Impossibile trovare le informazioni dei membri.',
    // 'Reservation.Status.InUse': 'Il veicolo non può essere restituito perché non è in uso.',
    'Reservation.Location.NotMatch':
        'Impossibile restituirlo poiché il luogo di restituzione non corrisponde.',
    'Reservation.RemoteControl.Fail':
        'Il controllo del veicolo non è andato a buon fine. Impossibile restituire il veicolo.',
    'Reservation.PaymentDetail.IsNull':
        'Non hai un piano tariffario da modificare.',
    'Reservation.Date.IsEqual':
        'La data di fine prenotazione deve essere successiva alla data di inizio.',
    'Reservation.Date.Invalid':
        'La data di inizio della prenotazione non può essere precedente a quella odierna.',
    'Reservation.AlreadyUseCarExist.Fail':
        'Vi è una macchina già in uso. Prenotazione non possibile.',
    'Reservation.VehicleUsePay.IsZero':
        'Il tuo piano non esiste o è impostato su $ 0.',
    'Reservation.Extend.CheckEndDate':
        'La data di restituzione prolungata deve essere successiva alla data di fine prenotazione.',
    'Reservation.Deny.DefaultMessage': 'Inserire i dettagli.',
    'Reservation.TotalPay.Changed': 'Il tasso è cambiato.',
    'Reservation.Insurance.Invalid':
        "La prenotazione non è possibile in quanto la data di scadenza dell'assicurazione.",
    'Reservation.InsuranceEdit.Invalid':
        "La prenotazione non può essere modificata in quanto la data di scadenza dell'assicurazione è passata.",
    'Reservation.VehicleType': 'Tipo di veicolo',
    // 'Reservation.Insurance.Invalid':  //   'Non è possibile prenotare un'auto perché l'assicurazione dell'auto è scaduta.',
    //   'La prenotazione non può essere modificata perché l'assicurazione dell'auto è scaduta.',
    'Reservation.UserNameInfo': 'Informazioni prenotante',
    'Reservation.UserName': 'Prenotante',
    'Reservation.Name': 'Nome',
    'Reservation.CustName': 'Nome del cliente',
    'Reservation.Status': 'Status',
    'Reservation.ReservationStatus': 'Stato della prenotazione',
    'Reservation.UserName.Placeholder': 'Inserire il nome del prenotante.',
    'Reservation.UserName.Confirm': 'Trova membri esistenti',
    'Reservation.UserName.ReConfirm': 'Cambia prenotante',
    'Reservation.UserInfo.Search': 'Trova informazioni membro',
    'Reservation.UserInfo.Confirm': 'Controllare le Informazioni del membro',
    'Reservation.Employee.Confirm': 'Trova dipendente',
    'Reservation.EmployeeInfo.Search': 'Trova Informazioni sul dipendente',
    'Reservation.EmployeeInfo.Confirm':
        'Controlla le informazioni del dipendente',
    'Reservation.Share.UserName': 'Co-conducente',
    'Reservation.Share.UserName.Placeholder':
        'Inserire il nome del co-conducente.',
    'Reservation.Mobile': 'Numero di telefono',
    'Reservation.Mobile.Placeholder': 'Numero di telefono',
    'Reservation.Team': 'Squadra',
    'Reservation.Business': 'Organizzazione',
    'Reservation.Team.Placeholder': 'Squadra',
    'Reservation.CampanyNumber': 'Dipendente n.',
    'Reservation.CampanyNumber.Placeholder': 'Dipendente n.',
    'Reservation.EmailAddress': 'Indirizzo email',
    'Reservation.EmailAddress.Placeholder': "Inserire l'indirizzo email.",
    'Reservation.Insurance.Fee': 'Premio',
    'Reservation.Rent.Fee': 'Canone di noleggio',
    'Reservation.BusinessInfo': 'Seleziona operatore',
    'Reservation.Business.Rent': 'Generale',
    'Reservation.Business.Corp': 'Società',
    'Reservation.VehicleInfo': 'Informazioni sul veicolo',
    'Reservation.VehicleUseFeeInfo': 'Tariffa di utilizzo del veicolo',
    'Reservation.UseFeeInfo': 'Tariffe di utilizzo',
    'Reservation.VehicleModel': 'Tipo di veicolo',
    'Reservation.BusinessName': 'Nome della società',
    'Reservation.DateRange': 'Periodo di prenotazione',
    'Reservation.RentalPeriod': 'Periodo di noleggio',
    'Reservation.MobileReservation': 'Prenotazione mobile',
    'Reservation.Date': 'Data/ora prenotazione',
    'Reservation.DateRange.Placeholder.Start': 'Data di inizio',
    'Reservation.DateRange.Placeholder.End': 'Data di fine',
    'Reservation.StartOdometer': "Misurazione iniziale dell'odometro (km)",
    'Reservation.EndOdometer': "Misurazione finale dell'odometro (km)",
    'Reservation.TotalOdometer': 'Distanza totale del viaggio (km)',
    'Reservation.InsuranceAge': 'Età assicurativa',
    'Reservation.InsuranceAge.Placeholder':
        'Assicurazione del conducente di età pari o superiore a 21 anni',
    'Reservation.UseFee': 'Tariffa di utilizzo del veicolo',
    'Reservation.ExtendFee': 'Tassa di estensione',
    'Reservation.UseFee.Result': 'Tariffa di viaggio {fee} EUR / km',
    'Reservation.StartLocation': 'Luogo ci consegna',
    'Reservation.StartLocation.Placeholder':
        'Luogo di consegna del veicolo selezionato',
    'Reservation.StartLocation.Button': 'Seleziona dalla mappa',
    'Reservation.VehicleNumber': 'N. del veicolo',
    'Reservation.DenyReson': 'Motivo del rifiuto',
    'Reservation.LicenseType': 'Tipo di licenza',
    'Reservation.VehicleNumber.Placeholder': 'Selezionare un veicolo.',
    'Reservation.UseFeeInfo.Placeholder': '0',
    'Reservation.UseFee.Placeholder': '0',
    'Reservation.ExtendFee.Placeholder': '0',
    'Reservation.Confirm.ExtendFee': 'Inserire la tassa di estensione.',
    'Reservation.BusinessType': "Selezionare l'operatore",
    'Reservation.BusinessType.Placeholder': 'Selezionare un operatore.',
    'Reservation.Confirm.Return': 'Restituire il veicolo?',
    'Reservation.Confirm.ForceReturn': 'Restituire il veicolo forzosamente?',
    'Reservation.Confirm.Extend': 'Estendere la data di restituzione?',
    'Reservation.Confirm.Cancel': 'Annullare la prenotazione?',
    'Reservation.Confirm.Confirm': 'Approvare?',
    'Reservation.Confirm.Deny': 'Rifiutarlo?',
    'Reservation.DenyReason.Confirm': 'Inserire il motivo della restituzione.',
    'Reservation.Return.Complete': 'Restituito.',
    'Reservation.ForceReturn.Complete': 'Restituito forzosamente.',
    'Reservation.Extend.Complete': 'Data di restituzione estesa.',
    'Reservation.Confirm.Complete': 'Prenotazione approvata.',
    'Reservation.Cancel.Complete': 'Prenotazione annullata.',
    'Reservation.Deny.Complete': 'Prenotazione respinta.',
    'Reservation.Delete.Complete': 'Prenotazione eliminata.',
    'Reservation.Update.Complete': 'Prenotazione modificata.',
    'Reservation.Create.Complete': 'Prenotazione registrata.',
    'Reservation.InUse.Complete':
        'La prenotazione è stata modificata con lo status In uso.',
    'Reservation.InsruanceAge.ConfirmFail':
        "Il veicolo selezionato non può essere prenotato a causa dell'età del prenotante.",
    'Reservation.AdditionalInsruanceAge.ConfirmFail':
        "Il veicolo selezionato non può essere prenotato a causa dell'età del co-conducente.",
    'Reservation.LicenseType.Placeholder': 'Tipo di licenza',
    'Reservation.InsuranceList': 'Tipo di assicurazione',
    'Reservation.InsuranceList.Placeholder':
        'Selezionare un tipo di assicurazione.',
    'Reservation.Complete.NameMobileIsNull':
        'Inserire sia il prenotante sia il numero di telefono.',
    'Reservation.Extend.AlreadyReserved':
        "Il veicolo che prenotato è disponibile a quell'ora.",
    'Reservation.InsuranceAge.IsOver': '{insuranceAge} anni o più',
    'Reservation.List.Fail':
        'Impossibile recuperare i dettagli della prenotazione.',
    'Reservation.Approve.Success':
        '{success} casi sono stati approvati su {total} casi con approvazione globale.',
    'Reservation.Deny.Success':
        '{success} pagamenti sono stati rifiutati dalla somma forfettaria di {total} pagamenti.',
    'Reservation.Pay.Success':
        '{success} i pagamenti hanno avuto esito positivo dalla somma forfettaria di {total} pagamenti.',
    'Reservation.Approve.Fail': "L'autorizzazione non è riuscita.",
    'Reservation.Deny.Fail': 'La restituzione non è andata a buon fine.',
    'Reservation.GetRentCar.Fail':
        "Impossibile recuperare l'elenco delle aziende di noleggio.",
    'Reservation.EndList.Fail':
        'Impossibile recuperare i dettagli di completamento del noleggio.',
    'Reservation.GetAvailable.Fail':
        "Impossibile recuperare l'elenco dei veicoli disponibili.",
    'Reservation.GetLocation.Fail':
        "Impossibile recuperare l'elenco delle basi.",
    'Reservation.MustHave.Fail': 'Compilare tutti i campi richiesti.',
    'Reservation.AddReservation.Fail':
        'Impossibile registrare la prenotazione.',
    'Reservation.AlreadyReserved.Fail': 'Il veicolo è già prenotato.',
    'Reservation.GetUseList.Fail':
        "Impossibile recuperare l'elenco di utilizzo.",
    'Reservation.GetTimeList.Fail':
        "Impossibile recuperare l'elenco di utilizzo.",
    'Reservation.Return.Fail': 'Impossibile restituire il veicolo.',
    'Reservation.Return.StartFail':
        "Non è possibile restituire il veicolo prima dell'inizio della prenotazione.",
    'Reservation.ForceReturn.Fail':
        'Impossibile restituire il veicolo forzosamente.',
    'Reservation.ForceReturn.StartFail':
        "Non è possibile restituire il veicolo prima dell'inizio della prenotazione.",
    'Reservation.Cancel.Fail': 'Impossibile annullare la prenotazione.',
    'Reservation.Inuse.Fail':
        'Impossibile cambiare il veicolo portandolo allo status In uso',
    'Reservation.InUse.TimeIsInvalid':
        "È possibile cambiare il veicolo con lo status In uso 15 minuti prima dell'ora di inizio.",
    'Reservation.Delete.Fail': 'Impossibile eliminare la prenotazione.',
    'Reservation.Extend.Fail': 'Impossibile estendere la data di ritorno.',
    'Reservation.Update.Fail': 'Impossibile modificare la prenotazione.',
    'Reservation.LicenseCheck.Fail':
        'Autenticazione della licenza non riuscita.',
    'Reservation.LicenseCheck.Invalid':
        'Informazioni patente non valide. Controllare nuovamente le informazioni.',
    'Reservation.LicenseExpiryDate.InValid':
        'La data di inizio del noleggio supera la data di scadenza del rinnovo della patente.',
    'Reservation.LicenseExpiryDate.AddTimeIsInvalid':
        'La data di inizio del noleggio supera la data di scadenza del rinnovo della patente del co-conducente.',
    'Reservation.GetPaymentDetail.Fail':
        'Impossibile recuperare le informazioni sulla tariffa.',
    'Reservation.GetInsuranceList.Fail':
        'Impossibile recuperare la lista assicurazioni.',
    'Reservation.View.Fail':
        'Impossibile recuperare le informazioni sulla prenotazione.',
    'Reservation.CurrentVehicle.AlreadyReserved': 'Il veicolo è già prenotato.',
    'Reservation.Edit.NotAvailable':
        'La prenotazione non può essere modificata.',
    'Reservation.OfflinePayment.Notify':
        'Si può pagare la tassa di utilizzo solo offline.',
    'Reservation.DenyReason.Placeholder':
        'Inserire il motivo della restituzione.',
    'Reservation.Payment.NotMinTime':
        'Il periodo di prenotazione è inferiore al periodo di utilizzo minimo.',
    'Reservation.Payment.NotMinUseTime':
        'Il periodo prolungato è inferiore al periodo di utilizzo minimo.',
    'Reservation.Payment.NoCharge':
        'Nessun piano tariffario fissato per il veicolo.',
    'Reservation.Payment.NoInsurance': 'Nessun premio fissato per il veicolo.',
    'Reservation.Business.Name': 'Nome della società',
    'Reservation.User.Team': 'Squadra',
    'Reservation.User.Name': 'Nome di chi effettua la prenotazione',
    'Reservation.Use.ImgList': "Immagini prima e dopo l'uso del veicolo",
    'Reservation.Use.ImgCheck':
        "Controllare le immagini del veicolo prima e dopo l'uso.",
    'Reservation.Count.AllVehicle': 'Tutti i veicoli in funzione',
    'Reservation.Count.AvailableVehicle':
        'Veicoli disponibili per la prenotazione',
    'Reservation.Count.CompletedVehicle': 'Veicolo riservato',
    'Reservation.Count.InUseVehicle': 'Il veicolo è attualmente in uso.',
    'Reservation.Count.WaitVehicle': "In attesa dell'omologazione del veicolo",
    'Reservation.Filter.LicensePlateNumber': 'N. del veicolo',
    'Reservation.Filter.Code': 'N. prenotazione',
    'Reservation.Filter.User.Name': 'Nome prenotante',
    'Reservation.Filter.User.MobileNumber': 'N. telefono del prenotante',
    'Reservation.Filter.Location.Name': 'Nome base',
    'Reservation.Filter.Business.Name': 'Nome della società',
    'Reservation.DayTimePicker.Title': 'Selezionare una data di noleggio.',
    'Rerservation.DayPicker.Title':
        'Inserire data di noleggio e di restituzione.',
    'Rerservation.DayPicker.StartTime': 'Data di noleggio',
    'Rerservation.DayPicker.EndTime': 'Data di restituzione',
    'Rerservation.Btn.All.Confirm': 'Piena approvazione',
    'Rerservation.Btn.Confirm': 'Approva',
    'Rerservation.Btn.Deny': 'Rifiuta',
    'Rerservation.Btn.Pay': 'Pagamento',
    'Reservation.DateFormat.Placeholder': 'AAAA. MM. GG',
    'UseStateCalendar.No': 'NO',
    'UseStateCalendar.LicensePlateNumber': 'N. del veicolo',
    'ReservationCalendar.Calendar.Item.Label.requested':
        'In attesa di approvazione',
    'ReservationCalendar.Calendar.Item.Label.confirmed': 'Prenotazioni',
    'ReservationCalendar.Calendar.Item.Label.canceled': 'Annulla prenotazione',
    'ReservationCalendar.Calendar.Item.Label.returned': 'Da restituire',
    'ReservationCalendar.Admin.Title': 'Stato della prenotazione',
    'ReservationCalendar.Admin.Item': '{businessName}{br}{reservationCount}',
    'ReservationCalendar.Private.Desc':
        'Le prenotazioni non autorizzate entro il periodo di prenotazione verranno automaticamente annullate.',
    'ReservationCalendar.Private.Requested.Title': 'In attesa di approvazione',
    'ReservationCalendar.Private.Requested.Item': 'In attesa di approvazione',
    'ReservationCalendar.Private.Confirmed.Title': 'Stato della prenotazione',
    'ReservationCalendar.Private.Confirmed.Item': '{groupName} {length}',
    'ReservationCalendar.Private.Group.Confirmed.Item':
        '{licensePlateNumber}{br}Prenotante {user}',
    'ReservationCalendar.Private.Group.Requested.Item':
        '{licensePlateNumber}{br}{teamName} / {user}',
    'ReservationCalendar.Private.Confirm.Approve':
        'Approvare gli elementi controllati?',
    'ReservationCalendar.Private.Confirm.Approve.All':
        'Concedere la piena approvazione?',
    'ReservationCalendar.Public.Confirmed.Title': 'Stato della prenotazione',
    'ReservationCalendar.Public.Confirmed.Item':
        '[{code}] {reservationTime}{br}{name} / {totalPay}EUR //[{code}]{reservationTime}{br}{name}/{totalPay}EUR',
    'ReservationCalendar.Public.Retruned.Item':
        '[{code}] {reservationTime}{br}{name}',
    'ReservationCalendar.Public.Returned.Title': 'Da restituire',
    'ReservationCalendar.Public.Returned.Item':
        '{reservationTime}{br}{code} / {name}',
    'ReservationCalendar.Month.Get.Fail':
        'Impossibile recuperare lo stato di prenotazione mensile.',
    'ReservationCalendar.Business.Get.Fail':
        'Impossibile recuperare lo stato della prenotazione per società.',
    'ReservationCalendar.Public.Get.Fail':
        'Impossibile recuperare lo status di noleggio.',
    'ReservationCalendar.Private.Get.Fail':
        'Impossibile recuperare lo stato di prenotazione della società.',
    // 'Vehicle.Used': 'Stato di utilizzo',
    // 'Vehicle.Used.Yes': 'Usato',
    // 'Vehicle.Used.No': 'Inutilizzato',
    'Vehicle.Information': 'Informazioni sul veicolo',
    'Vehicle.State.Sub.Title': 'Informazioni sul veicolo',
    'Vehicle.Reservation.Sub.Title': 'Stato della prenotazione',
    'Vehicle.Model': 'Modello del veicolo',
    'Vehicle.Color': 'Colore del veicolo',
    'Vehicle.Transmission': 'Tipo di cambio',
    'Vehicle.LicensePlateNumber': 'N. di immatricolazione del veicolo',
    'Vehicle.VIN': 'VIN N.',
    'Vehicle.Status': 'Status',
    'Vehicle.ControllerID': 'N. telefono del terminale',
    'Vehicle.RegistrationDate': 'Data immatricolazione del veicolo',
    'Vehicle.ProductionDate': 'Data produzione',
    'Vehicle.Options': 'Opzione',
    'Vehicle.Business': 'Possessore società',
    'Vehicle.Location': 'Base',
    'Vehicle.Insurance.Company': 'Compagnia di assicurazione',
    'Vehicle.Insurance.Age': 'Età assicurativa',
    'Vehicle.Insurance.JoinDate': 'Data sottoscrizione assicurazione',
    'Vehicle.Insurance.ExpireDate': 'Data di scadenza',
    'Vehicle.Insurance.PD': 'Limite di responsabilità per oggetti',
    'Vehicle.Insurance.BI': 'Limite di responsabilità per persone',
    'Vehicle.Insurance.DB': 'Limite PAI',
    'Vehicle.RegistrationDate.Placeholder': 'es) 20190101',
    'Vehicle.Model.Placeholder': 'Selezionare un modello.',
    'Vehicle.Transmission.Placeholder': 'Selezionare un tipo di cambio.',
    'Vehicle.LicensePlateNumber.Placeholder': 'ad esempio) 00 Ha 0000.',
    'Vehicle.VIN.Placeholder':
        'Il VIN è composto da 17 caratteri tra cui lettere e numeri',
    'Vehicle.VIN.Help':
        'Il VIN è composto da 17 caratteri tra cui lettere e numeri',
    'Vehicle.ControllerID.Placeholder': 'es.) 01012345678',
    'Vehicle.ControllerID.Help':
        'Il numero di telefono dovrebbe essere composto da numeri.',
    'Vehicle.Business.Placeholder': 'Selezionare una società.',
    'Vehicle.Location.Placeholder': 'Selezionare una base.',
    'Vehicle.Options.Placeholder': "Selezionare un'opzione.",
    'Vehicle.Options.No.Data': "Selezionare un'opzione.",
    'Vehicle.Insurance.Company.Placeholder':
        'Inserire una compagnia assicurativa.',
    'Vehicle.Insurance.Age.Placeholder': "Selezionare un'età assicurativa.",
    'Vehicle.Insurance.JoinDate.Placeholder':
        "Inserire la data di sottoscrizione dell'assicurazione.",
    'Vehicle.Insurance.PD.Placeholder':
        'Inserire il limite di responsabilità per gli oggetti.',
    'Vehicle.Insurance.BI.Placeholder':
        'Inserire il limite di responsabilità per le persone.',
    'Vehicle.Insurance.DB.Placeholder': 'Inserire il limite PAI.',
    'Vehicle.ControllerID.Help.Already.Use':
        'Il numero di telefono del terminale inserito è già in uso.',
    'Vehicle.VIN.Help.Already.Use': 'Il VIN inserito è già in uso.',
    'Vehicle.Btn.Controller': 'Controlla terminale',
    'Vehicle.View.Fail': 'Impossibile recuperare le informazioni del veicolo.',
    'Vehicle.Delete.Fail': 'I veicoli in uso non possono essere eliminati.',
    'Vehicle.Confirm.Delete': 'Eliminare le informazioni del veicolo?',
    'Vehicle.Confirm.Deletes':
        'Eliminare gli elementi selezionati?{br}I veicoli in uso non possono essere eliminati.',
    'Vehicle.State.TpmsFl': 'Ruota anteriore/sinistra',
    'Vehicle.State.TpmsFr': 'Ruota anteriore/destra',
    'Vehicle.State.TpmsRl': 'Ruota posteriore/sinistra',
    'Vehicle.State.TpmsRr': 'Ruota posteriore/destra',
    'Vehicle.State.Tpms.Unit': 'PSI',
    'Vehicle.State.Battery': 'Tensione della batteria',
    'Vehicle.State.FuelLevel': 'Livello di carburante',
    'Vehicle.Confirm.Control.Open': 'Aprire la portiera?',
    'Vehicle.Confirm.Control.Close': 'Bloccare la portiera?',
    'Vehicle.Confirm.Control.Siren': 'Attivare la luce di emergenza/clacson?',
    'Vehicle.Control': 'Controllo del veicolo',
    'Vehicle.Control.Open': 'Portiera aperta',
    'Vehicle.Control.Close': 'Chiudere la portiera',
    'Vehicle.Control.Siren': 'Luce di emergenza/clacson',
    'Vehicle.Control.Open.Action': 'Attiva',
    'Vehicle.Control.Close.Action': 'Attiva',
    'Vehicle.Control.Siren.Action': 'Attiva',
    'Vehicle.Control.Requested.Success':
        'Un segnale è stato inviato al veicolo.',
    'Vehicle.Control.Request.Fail':
        'Impossibile inviare un segnale al veicolo.',
    'Vehicle.Control.Opened.Success': 'La portiera è aperta.',
    'Vehicle.Control.Closed.Success': 'La portiera è chiusa a chiave.',
    'Vehicle.Control.Siren.Success': 'Luce di emergenza/clacson attivato.',
    'Vehicle.Control.Fail':
        'Si è verificato un errore durante il controllo del veicolo.',
    'Vehicle.Control.Fail.Code':
        'Contattare il proprio amministratore. [{code}]',
    'Vehicle.Control.Timeout':
        'La ricezione del risultato del controllo è ritardata.',
    'Vehicle.Type.Normal': 'Veicolo normale',
    'Vehicle.Type.TestCar': 'Auto di prova',
    'Vehicle.VehicleType': 'Tipo di veicolo',
    'VehicleModel.Information': 'Informazioni sul veicolo',
    'VehicleModel.Name': 'Nome modello',
    'VehicleModel.Name.Placeholder': 'Inserire il nome del modello.',
    'VehicleModel.Image.Helper':
        "Un'immagine del veicolo deve essere registrata.",
    'VehicleModel.Brand': 'Produttore',
    'VehicleModel.Brand.Placeholder': 'Selezionare un produttore.',
    'VehicleModel.FuelType': 'Tipo di carburante',
    'VehicleModel.FuelType.Placeholder': 'Selezionare un tipo di carburante.',
    'VehicleModel.SeatingCapacity': 'Numero di passeggeri',
    'VehicleModel.SeatingCapacity.Placeholder':
        'Inserire il numero di passeggeri.',
    'VehicleModel.SeatingCapacityP': 'Numero di passeggeri',
    'VehicleModel.Displacement': 'Cilindrata (cc)',
    'VehicleModel.Displacement.Placeholder': 'es) 1800',
    'VehicleModel.FuelTankCapacity': 'Capacità serbatoio carburante',
    'VehicleModel.FuelTankCapacity.Placeholder': 'es) 70',
    'VehicleModel.FuelTankCapacityE': 'Capacità della batteria (kWh)',
    'VehicleModel.FuelEfficiency': 'Consumo di carburante (km / l)',
    'VehicleModel.FuelEfficiency.Helper':
        'È possibile inserire solo un numero intero o un numero decimale per il consumo di carburante. ad esempio) 12,5',
    'VehicleModel.FuelEfficiency.Placeholder': 'es) 12,5',
    'VehicleModel.Grade': 'Classe del veicolo',
    'VehicleModel.Grade.Placeholder': 'Classe del veicolo',
    'VehicleModel.Standard': 'Modello principale',
    'VehicleModel.StandardTag': 'Principale',
    'VehicleModel.Confirm.Deletes':
        'Eliminare i modelli {count} ?{br}Non è possibile eliminare i modelli registrati.',
    'VehicleModel.GetData.Fail':
        'Impossibile recuperare il modello di veicolo.',
    'VehicleModel.Create.Success': 'Il modello di veicolo è stato registrato.',
    'VehicleModel.Create.Fail': 'Impossibile registrare il modello di veicolo.',
    'VehicleModel.Update.Success': 'Il modello di veicolo è stato modificato.',
    'VehicleModel.Update.Fail': 'Impossibile modificare il modello di veicolo.',
    'VehicleModel.UpdateImage.Fail':
        "Impossibile registrare l'immagine del modello del veicolo.",
    'VehicleModel.Delete.Success': 'Il modello di veicolo è stato eliminato.',
    'VehicleModel.Delete.Fail':
        "Impossibile eliminare l'elenco dei modelli di veicolo.",
    'VehicleModel.Fail.DuplicatedModelName':
        '모델명 중복으로 기능을 수행할 수 없습니다.',

    'Terminal.Title.Create': 'Registra terminale',
    'Terminal.Title.Info': 'Dettagli terminale',
    'Terminal.Vehicle.LicensePlateNumber': 'N. del veicolo',
    'Terminal.VIN': 'VIN N.',
    'Terminal.NadID': 'Numero di telefono',
    'Terminal.Serial': 'Numero di serie.',
    'Terminal.Manufacturer': 'Produttore',
    'Terminal.Version': 'S/W Ver.',
    'Terminal.OrderDate': 'Data ordine',
    'Terminal.DeliveryDate': 'Data di consegna',
    'Terminal.MobileOperator': 'Vettore di comunicazione',
    'Terminal.ActivationDate': 'Data di attivazione del terminale',
    'Terminal.ICCID': 'USIM n.',
    'Terminal.State': 'Status terminale',
    'Terminal.Status': 'Gestione terminali',
    'Terminal.State.Status': 'Status terminale',
    'Terminal.VIN.Placeholder':
        'Il VIN è composto da 17 caratteri tra cui lettere e numeri',
    'Terminal.NadID.Placeholder': 'Inserire il numero di telefono.',
    'Terminal.Manufacturer.Placeholder': 'Inserire il produttore.',
    'Terminal.Serial.Placeholder': 'es) CASD12434052DS888',
    'Terminal.Version.Placeholder': 'Inserire versione software',
    'Terminal.OrderDate.Placeholder': 'es) 20191201',
    'Terminal.DeliveryDate.Placeholder': 'es) 20191201',
    'Terminal.MobileOperator.Placeholder': 'Selezionare un vettore.',
    'Terminal.ActivationDate.Placeholder': 'es) 20191201',
    'Terminal.ICCID.Placeholder': 'Inserire numero',
    'Terminal.State.Placeholder':
        'Visualizzazione automatica dopo la connessione',
    'Terminal.Status.Placeholder':
        'Selezionare lo status di gestione del terminale.',
    'Terminal.Get.Fail':
        'Impossibile recuperare le informazioni del terminale.',
    'Charge.Btn.Business.List': 'Elenco delle società',
    'Charge.Title': 'Piano tariffario',
    'Charge.Name': 'Nome del piano tariffario',
    'Charge.Reservation': 'Tempo minimo di prenotazione',
    'Charge.PriceTime': 'Unità di tempo per il pagamento',
    'Charge.Price.Helper':
        'La tariffa deve essere un numero intero maggiore di zero.',
    'Charge.Week_day_charge': 'Tariffa infrasettimanale',
    'Charge.Week_end_charge': 'Tariffa weekend',
    'Charge.Insurance': 'Premio esonero di responsabilità',
    'Charge.Peak_season_week_day_charge':
        'Tariffa infrasettimanale di alta stagione',
    'Charge.Peak_season_week_end_charge': 'Tasso weekend di alta stagione',
    'Charge.Peak_season': 'Periodo stabilito',
    'Charge.TimePrice': 'Tabella delle tariffe per tempo di noleggio',
    'Charge.Vehicle.Apply': 'Veicolo applicabile',
    'Charge.WeekDay': 'Giorno infrasettimanale',
    'Charge.WeekEnd': 'Weekend',
    'Charge.PeakWeekDay': 'Infrasettimanale alta stagione',
    'Charge.PeakWeekEnd': 'Weekend alta stagione',
    'Charge.VehicleNoData':
        'Aggiungere un veicolo al quale verrà applicato il piano tariffario.',
    'Charge.PriceTimeUnit': 'Unità',
    'Charge.MinReservation': 'Tempo minimo',
    'Charge.Vehiclemodel.Name': 'Tipo di veicolo',
    'Charge.Vehicle.licenseplatenumber': 'N. del veicolo',
    'Charge.Vehicle.Charge': 'Piano tariffario',
    'Charge.PickerInfoMsg':
        '* Se non si immette il tasso di alta stagione, verrà applicata la tariffa normale.',
    'Charge.Vehicle': 'Veicolo',
    'Charge.Vehicle.Msg': 'Veicolo',
    'Charge.Name.Placeholder': 'Inserire il piano tariffario.',
    'Charge.Insurance.Placeholder':
        'Selezionare un premio con esonero di responsabilità.',
    'Charge.Peak_season_start.Placeholder': 'Data di inizio',
    'Charge.Peak_season_end.Placeholder': 'Data di fine',
    'Charge.Week_day_charge.Placeholder': '0',
    'Charge.Week_end_charge.Placeholder': '0',
    'Charge.Peak_season_week_day_charge.Placeholder': '0',
    'Charge.Peak_season_week_end_charge.Placeholder': '0',
    'Charge.PriceTime_hour.Placeholder': '0h',
    'Charge.PriceTime_minute.Placeholder': '0m',
    'Charge.Reservation_hour.Placeholder': '0h',
    'Charge.Reservation_minute.Placeholder': '0m',
    'Charge.WarningMsg':
        'I veicoli con altri piani tariffari sono inclusi.{br} Se non si desidera modificare il piano, deselezionare la casella.',
    'Charge.NotCreate': 'Non registrato',
    'Charge.Vehicle.NoData': 'Nessun veicolo',
    'Charge.Confirm.Deletes':
        'Eliminare gli elementi selezionati?{br}I veicoli riservati ai sensi di questo piano tariffario saranno addebitati con la tariffa prima della cancellazione.',
    'Charge.Vehicle.Confirm.Deletes': 'Eliminare gli elementi selezionati?',
    'Charge.First_discount': 'Sconto noleggio di 3 giorni o più (%)',
    'Charge.Second_discount': 'Sconto noleggio di 7 giorni o più (%)',
    'Charge.Toast.Business.Fail': "Impossibile recuperare l'elenco operatori.",
    'Charge.Toast.ChargeList.Fail':
        'Impossibile recuperare la lista del piano tariffario.',
    'Charge.Toast.ChargeDetail.Fail':
        'Impossibile recuperare i dettagli del piano tariffario.',
    'Charge.Toast.ChargeCreate.Success':
        'Il piano tariffario è stato registrato.',
    'Charge.Toast.ChargeCreate.Fail':
        'Impossibile registrare il piano tariffario.',
    'Charge.Toast.ChargeUpdate.Success':
        'Il piano tariffario è stato modificato.',
    'Charge.Toast.ChargeUpdate.Fail':
        'Impossibile modificare il piano tariffario.',
    'Charge.Toast.DetailVehicleList.Fail':
        "Impossibile recuperare l'elenco dei veicoli con piano tariffario.",
    'Charge.Toast.VehicleList.Fail': 'Impossibile recuperare la lista veicolo.',
    'Charge.Toast.Delete.Success': 'Il piano tariffario è stato eliminato.',
    'Charge.Toast.Delete.Fail':
        'Si è verificato un errore dovuto al piano tariffario riservato.',
    'Charge.Toast.ChargeDelete.Fail':
        'Impossibile eliminare il piano tariffario.',
    'Charge.Toast.VehicleChargeDelete.Success':
        'Il piano tariffario è stato eliminato.',
    'Charge.Toast.VehicleChargeDelete.Fail':
        'Impossibile eliminare il piano tariffario.',
    'Charge.Toast.VehicleChargeList.Fail':
        "Impossibile recuperare l'elenco dei modelli di veicolo.",
    'Insurance.Vehicle': 'Veicolo',
    'Insurance.GroupName': 'Premio per nome del gruppo',
    'Insurance.Info': 'Informazioni premi',
    'Insurance.Name': 'Nome assicurazione',
    'Insurance.SelfCost': 'Franchigia assicurativa (EUR)',
    'Insurance.Time': 'Unità di tempo per il pagamento',
    'Insurance.Premium': 'Premium (EUR)',
    'Insurance.TimePrice': 'Premio per tempo di noleggio',
    'Insurance.Charge1': 'Premio 1',
    'Insurance.Charge2': 'Premio 2',
    'Insurance.Charge3': 'Premio 3',
    'Insurance.WarningMsg':
        'Ci sono veicoli con altri premi.{br}Se non si desidera modificare il premio, deselezionare la casella.',
    'Insurance.Confirm.Deletes':
        'Eliminare gli elementi selezionati?{br}I veicoli riservati ai sensi di questo premio saranno addebitati col premio prima della cancellazione.',
    'Insurance.Toast.InsuranceList.Fail':
        'Impossibile recuperare la lista premium.',
    'Insurance.Toast.InsuranceDetail.Fail':
        'Impossibile recuperare i dettagli premium.',
    'Insurance.Toast.InsuranceUpdate.Success': 'Il premio è stato modificato.',
    'Insurance.Toast.InsuranceUpdate.Fail': 'Impossibile modificare il premio.',
    'Insurance.Toast.InsuranceDelete.Success': 'Il premio è stato eliminato.',
    'Insurance.Toast.InsuranceDelete.Fail': 'Impossibile eliminare il premio.',
    'Insurance.Toast.InsuranceCreate.Success': 'Il premio è stato registrato.',
    'Insurance.Toast.InsuranceCreate.Fail': 'Impossibile registrare il premio.',
    'Insurance.Toast.DetailVehicleList.Fail':
        "Impossibile recuperare l'elenco dei veicoli con premio.",
    'Insurance.Toast.VehicleChargeList.Fail':
        "Impossibile recuperare l'elenco dei modelli di veicolo.",
    'Insurance.Toast.VehicleChargeDelete.Success':
        'Il premio per veicolo è stato soppresso.',
    'Insurance.Toast.VehicleChargeDelete.Fail':
        'Non è riuscito a eliminare il premio per veicolo.',
    'Insurance.Toast.Delete.Fail':
        'Si è verificato un errore dovuto al premio riservato.',
    'Insurance.Toast.Business.Fail':
        "Impossibile recuperare l'elenco operatori.",
    'SalesManager.AppReservation': 'Prenotazione APP',
    'SalesManager.FMSReservation': 'Prenotazione WEB',
    'SalesManager.Use_pay': 'Noleggio/estensione',
    'SalesManager.Premium': 'Premio',
    'SalesManager.Receivables_pay': 'Crediti',
    'SalesManager.Receivables_pay_total': '{month} crediti {br}(cumulativo)',
    'SalesManager.Sales_total': 'Totale',
    'SalesManager.Month_total': '{month} vendite totali {br}(cumulativo)',
    'SalesManager.Won': '{amount} EUR',
    'SalesManager.Toast.PaymentMonthList.Fail':
        "Impossibile recuperare l'elenco mensile.",
    'SalesManager.Toast.PaymentDayList.Fail':
        "Impossibile recuperare l'elenco giornaliero.",
    'SalesManager.Toast.PaymentBusinessList.Fail':
        "Impossibile recuperare l'elenco operatori.",
    'UseState.State.InUse': 'In uso',
    'UseState.State.Wait': 'In attesa',
    'UseState.State.Unavailable': 'Anormale',
    'UseState.State.LowVoltage': 'Bassa tensione',
    'UseState.State.NotReturned': 'Non restituito',
    'UseState.All': 'Tutti',
    'UseState.Use': 'In uso',
    'UseState.Toast.UseStatusList.Fail':
        "Impossibile recuperare l'elenco di stato di utilizzo.",
    'UseState.Toast.UseStateCount.Fail':
        "Impossibile recuperare l'elenco di stato di utilizzo.",
    'Payment.Processing': 'Pagamento processo',
    'Payment.Complete': 'Pagamento completato',
    'Payment.Status': 'Stato/Importo',
    'Payment.PaymentStatus': 'Stato del pagamento',
    'Payment.UnpaidPay': 'Tariffe non pagate',
    'Payment.ActualPay': 'Pagamento effettivo',
    'Payment.Status.Complete': 'Pagamento completato',
    'Payment.Status.Unpaid': 'Non pagato',
    'Payment.Status.Wait': 'In attesa di pagamento',
    'Payment.Status.Fail': 'Errore di pagamento',
    'Payment.Alert.NoPaymentData': 'Le informazioni di pagamento non esistono.',
    'Payment.Alert.Delete.No.Checked':
        'Selezionare una prenotazione per elaborare il pagamento.',
    'Payment.Confirm.Pay': 'Elaborare {count} pagamenti?',
    'Payment.Pay.Fail': 'Impossibile elaborare il pagamento',
    'Payment.Get.Fail': 'Impossibile recuperare le informazioni di pagamento.',
    'Payment.Pay.No.Checked':
        'Non ci sono elementi per elaborare il pagamento.',
    'Payment.Pay.MultiFail': 'Impossibile elaborare {count} pagamenti',
    'Payment.Pay.Success': 'Il pagamento è stato completato.',
    'Local.Address.Get.Fail': "Impossibile convertire l'indirizzo.",
    'Local.Geocode.Get.Fail': "Impossibile convertire l'indirizzo.",
    'Local.Search.Fail': 'La ricerca non è andata a buon fine.',
    Location: 'Base',
    'Location.Placeholder':
        "Spostare il marcatore sulla mappa per inserire automaticamente l'indirizzo.",
    'Location.Name': 'Nome base',
    'Location.Name.Placeholder': 'Inserire il nome della base.',
    'Location.Address': 'Indirizzo',
    'Location.Address.ModalTitle': 'Ricerca indirizzi',
    'Location.Address.Placeholder':
        "Questo campo viene riempito automaticamente con l'indirizzo.",
    'Location.DetailedAddress': 'Indirizzo dettagliato',
    'Location.DetailedAddress.Placeholder': "Inserire l'indirizzo.",
    'Location.VehicleCount': 'Veicolo in possesso',
    'Location.Business': 'Possessore società',
    'Location.Business.Name': 'Nome della società',
    'Location.Business.Opened': 'Base pubblica',
    'Location.Business.Name.Placeholder': 'Selezionare una società.',
    'Location.Confirm.MultiDelete': 'Eliminare {count} base/i?',
    'Location.Create.Success': 'La base è stata registrata.',
    'Location.Create.Fail': 'Impossibile registrare la base.',
    'Location.Update.Success':
        'Le informazioni della base sono state modificate.',
    'Location.Update.Fail':
        'Impossibile modificare le informazioni della base.',
    'Location.Delete.Success': 'La base è stata eliminata.',
    'Location.Delete.Fail': 'Impossibile eliminare la base.',
    'Location.Delete.Fail.VehicleExists':
        'Cancellazione fallita in quanto i veicoli esistono nella base.',
    'Location.View.Fail': 'Impossibile recuperare le informazioni della base.',
    'Location.Monitoring.Fail':
        'Impossibile recuperare le informazioni del veicolo nella base.',
    'Location.Unit.Vehicle.Count': '{value} veicoli',
    'Location.Radius': 'Luogo della restituzione (Raggio)',
    'Location.Radius.Placeholder': 'Selezionare un raggio.',
    'Location.Note': 'Dettagli',
    'Location.Note.Placeholder': 'Inserire i dettagli.',
    'Location.Note.Empty': 'Non ci sono dettagli.',
    'BusinessMember.Title': 'Informazioni sulla sottoscrizione del dipendente',
    'Member.Title.Info': 'Informazioni di base',
    'Member.Title.Business': "Informazioni sull'operatore",
    'Member.Name': 'Nome',
    'Member.Email': 'ID utente',
    'Member.MobileNumber': 'Numero di telefono',
    'Member.Role': 'Tipo',
    'Member.ActivationStatus': 'Status',
    'Member.JoinDate': 'Data di iscrizione',
    'Member.BirthDay': 'Data di nascita',
    'Member.Business.Name': 'Operatore',
    'Member.Branch.Name': 'Filiale',
    'Member.TeamName': 'Squadra',
    'Member.EmployeeRegNum': 'Dipendente n.',
    'Member.OfficePhoneNumber': 'Ufficio',
    'Member.DenyReason': 'Motivo del rifiuto',
    'Member.Filter.Email': 'ID utente',
    'Member.Filter.Name': 'Nome',
    'Member.Filter.MobileNumber': 'Numero di telefono',
    'Member.Filter.Business': 'Nome operatore',
    'Member.Modal.Title.Deny': 'Motivo del rifiuto',
    'Member.Denyreason.Placeholder': 'Inserire il motivo della restituzione.',
    'Member.View.Fail': "Impossibile recuperare l'amministratore.",
    'Member.Approved.Success': 'La richiesta è stata approvata.',
    'Member.Approve.Fail': 'Impossibile approvare la richiesta.',
    'Member.Denied.Success': 'La richiesta è stata respinta.',
    'Member.Deny.Fail': 'Impossibile respingere la richiesta.',
    'Member.Help.Approve.Ready':
        '※ La richiesta di approvazione non è stata completata.',
    'Customer.Title.Info': 'Informazioni di base',
    'Customer.Title.Business': 'Informazioni dipendente',
    'Customer.Name': 'Nome membro',
    'Customer.Email': 'Indirizzo email',
    'Customer.MobileNumber': 'Numero di telefono',
    'Customer.JoinDate': 'Data di iscrizione',
    'Customer.ActivationStatus': 'Status',
    'Customer.BirthDay': 'Data di nascita',
    'Customer.Address': 'Indirizzo',
    'Customer.DriverLicense': 'Patente',
    'Customer.CreditCard': 'Carta n.',
    'Customer.Business.Name': 'Nome della società',
    'Customer.TeamName': 'Squadra',
    'Customer.EmployeeRegNum': 'Dipendente n.',
    'Customer.DenyReason': 'Motivo del rifiuto',
    'Customer.Filter.Email': 'ID utente',
    'Customer.Filter.Name': 'Nome',
    'Customer.Filter.MobileNumber': 'Numero di telefono',
    'Customer.Filter.Business': 'Nome operatore',
    'Customer.Filter.Team': 'Squadra',
    'Customer.Modal.Title.Deny': 'Motivo del rifiuto',
    'Customer.Denyreason.Placeholder': 'Inserire il motivo della restituzione.',
    'Customer.View.Fail': 'Impossibile recuperare i membri.',
    'Customer.Approved.Success': 'La richiesta è stata approvata.',
    'Customer.Approve.Fail': 'Impossibile approvare la richiesta.',
    'Customer.Denied.Success': 'La richiesta è stata respinta.',
    'Customer.Deny.Fail': 'Impossibile respingere la richiesta.',
    'Business.Title.Type': 'Categoria operatore',
    'Business.Title.Business': "Informazioni sull'operatore",
    'Business.Title.Manager': 'Amministratore principale',
    'Business.BusinessCode': 'Codice',
    'Business.BusinessRegNum': 'N. operatore',
    'Business.Name': 'Nome della società',
    'Business.MobileNumber': 'Telefono principale',
    'Business.Address': 'Indirizzo',
    'Business.KoroadClientID': 'Patente',
    'Business.PGMemberID': 'ID membro PG',
    'Business.Manager.Name': 'Nome',
    'Business.Manager.MobileNumber': 'Numero di telefono',
    'Business.Manager.Email': 'Indirizzo email',
    'Business.Type.Private': 'Società',
    'Business.Type.Public': 'Generale',
    'Business.Modal.Address.Search': 'Trova indirizzo operatore',
    // 'Business.TestCar': 'Auto di prova',
    'Business.BusinessRegNum.Placeholder': "Inserire il numero dell'operatore.",
    'Business.Name.Placeholder': 'Inserire il nome della società.',
    'Business.MobileNumber.Placeholder': '000-0000-0000',
    'Business.Address.Placeholder': '00000',
    'Business.Address.Address.Placeholder': 'Cercare il codice postale.',
    'Business.Address.DetailedAddress.Placeholder': "Inserire l'indirizzo.",
    'Business.KoroadClientID.Placeholder': 'ID CLIENTE',
    'Business.KoroadSecret.Placeholder': 'CLIENTE SEGRETO',
    'Business.KoroadPIN.Placeholder': 'PIN',
    'Business.KoroadPWD.Placeholder': 'Password',
    'Business.PGMemberID.Placeholder': "Inserire l'ID.",
    'Business.Manager.Name.Placeholder': 'Inserire il nome.',
    'Business.Manager.MobileNumber.Placeholder': '000-0000-0000',
    'Business.Manager.Email.Placeholder': "Inserire l'indirizzo email.",
    'Business.View.Fail':
        "Impossibile recuperare le informazioni dell'operatore.",
    'Business.BusinessRegNum.Help': "Inserire il numero dell'operatore.",
    'Business.MobileNumber.Help':
        'Inserire un numero di telefono principale valido.',
    'Business.Manager.MobileNumber.Help':
        'Inserire un numero di telefono valido.',
    'Alert.Image.SizeLimitExceeded':
        "È possibile caricare un'immagine fino a 500KB.",
    'Vehicle.Status.Wait': 'In attesa',
    'Vehicle.Status.InUse': 'In uso',
    'Vehicle.Status.Incident': 'Anormale',
    'Vehicle.Status.LowVoltage': 'Bassa tensione',
    'Vehicle.Status.NotReturned': 'Non restituito',
    'Vehicle.Status.Unknown': 'Sconosciuto',
    'Brand.Type.Hyundai': 'Hyundai Motor Company',
    'Brand.Type.Kia': 'Kia Motors',
    'Fuel.Type.Gasoline': 'Benzina',
    'Fuel.Type.Diesel': 'Diesel',
    'Fuel.Type.Ev': 'Elettricità',
    'Fuel.Type.Hybrid': 'Ibrido',
    'Grade.Type.City': 'Minicompact',
    'Grade.Type.SubCompact': 'Utilitaria',
    'Grade.Type.Compact': 'Compatto',
    'Grade.Type.Mid': 'Di medie dimensioni',
    'Grade.Type.Large': 'Grande',
    'Grade.Type.SUV': 'SUV',
    'Grade.Type.VAN': 'Furgoncino',
    'Grade.Type.Electric': 'Elettricità',
    'Grade.Type.Oversea': 'Importazione',
    'Accident.Type.Washing': 'Lavaggio',
    'Accident.Type.Maintenance': 'Manutenzione',
    'Accident.Type.Repair': 'Riparazione',
    'Accident.Type.Acc1': 'Incidente causato dal cliente',
    'Accident.Type.Acc2': 'Incidente causante danni al cliente',
    'Accident.Type.Etc': 'Altro',
    'Terminal.Telecom.Type.SKT': 'SKT',
    'Terminal.Telecom.Type.KT': 'KT',
    'Terminal.Telecom.Type.LGU+': 'LGU',
    'Terminal.Telecom.Type.SKTMVNO': 'SKT MVNO ',
    'Terminal.Telecom.Type.KTMVNO': 'KT MVNO',
    'Terminal.Telecom.Type.LGUMVNO': 'LGU+ MVNO ',
    'Terminal.StatusType.Normal': 'Utilizzo (stato connessione)',
    'Terminal.StatusType.Outoforder': 'Risoluzione dei problemi',
    'Terminal.StatusType.Discard': 'Smaltimento',
    'Terminal.StatusType.Unknown': 'Sconosciuto',
    'Terminal.State.Type.Unknown': 'Sconosciuto',
    'Terminal.State.Type.Connect': 'Montato sul veicolo',
    'Terminal.State.Type.Conflict': 'Incoerenza',
    'Terminal.State.Type.Wait': 'In attesa',
    'Terms.StatusType.Valid': 'Valido',
    'Terms.StatusType.Invalid': 'Non valido',
    'Notice.StatusType.Draft': 'BOZZA',
    'Notice.StatusType.Published': 'PUBBLICATO',
    'Notice.StatusType.Notified': 'NOTIFICATO',
    'Member.ActivationStatusType.Pending': 'In attesa',
    'Member.ActivationStatusType.Normal': 'Approva',
    'Member.ActivationStatusType.Deny': 'Rifiuta',
    'Member.RoleType.System.Admin': 'Manager',
    'Member.RoleType.System.Operator': 'Amministratore',
    'Member.RoleType.Public.Admin': 'Manager',
    'Member.RoleType.Public.Operator': 'Amministratore',
    'Member.RoleType.Private.Admin': 'Manager',
    'Member.RoleType.Private.Operator': 'Amministratore',
    'Member.RoleGroupType.Admin': 'Manager',
    'Member.RoleGroupType.Operator': 'Amministratore',
    'User.StatusType.Pending': 'In attesa',
    'User.StatusType.Normal': 'Approva',
    'User.StatusType.Reject': 'Rifiuta',
    'User.StatusType.Deny': 'Rifiuta',
    'User.LevelType.Public': 'Membro generale',
    'User.LevelType.Private': 'Membro della società',
    'Customer.BizStatusType.Requested': 'In attesa',
    'Customer.BizStatusType.Inactive': 'Approva',
    'Customer.BizStatusType.Deny': 'Rifiuta',
    'Reservation.Status.Unknown': 'Sconosciuto',
    'Reservation.Status.Requested': 'In attesa di approvazione',
    'Reservation.Status.Approve': 'Prenotazione completata',
    'Reservation.Status.Confirm': 'Approvazione completata',
    'Reservation.Status.Denied': 'Prenotazione respinta',
    'Reservation.Status.Canceled': 'Annulla prenotazione',
    'Reservation.Status.InUse': 'In uso',
    'Reservation.Status.Returned': 'Restituzione completata',
    'Reservation.Status.Return': 'Restituzione',
    'Reservation.ConfirmType.Requested':
        'In attesa della spedizione del veicolo',
    'Reservation.ConfirmType.Confirmed': 'Omologazione del veicolo',
    'Reservation.ConfirmType.Denied': 'Rifiuto spedizione del veicolo',
    'Vehicle.ControlType.Open': 'Portiera aperta (Sblocca)',
    'Vehicle.ControlType.Close': 'Chiudere la portiera (Bloccare)',
    'Vehicle.ControlType.Horn': 'Clacson',
    'Vehcile.ControlType.Light': 'Luce di emergenza',
    'Vehicle.Colors.White': 'Bianco',
    'Vehicle.Colors.LightGrey': 'argento',
    'Vehicle.Colors.Grey': 'grigio',
    'Vehicle.Colors.Black': 'Colore nero',
    'Vehicle.Colors.Red': 'Rosso',
    'Vehicle.Colors.Blue': 'blu',
    'Vehicle.Colors.Green': 'Verde',
    'Vehicle.Colors.Yellow': 'Giallo',
    'Vehicle.Transmission.Auto': 'Automatico',
    'Vehicle.Transmission.Manual': 'Manuale',
    'Vehicle.Options.Nosmoking': 'Non fumatori',
    'Vehicle.Options.Dashcam': 'Scatola nera',
    'Vehicle.Options.Rearview': 'Telecamera posteriore',
    'Vehicle.Options.Navigation': 'Navigazione',
    'Vehicle.InsuranceAge.21': 'età 21 anni e più',
    'Vehicle.InsuranceAge.26': 'età 26 anni e più',
    'Vehicle.InsuranceDriverAge.21':
        'Assicurazione del conducente di età pari o superiore a 21 anni',
    'Vehicle.InsuranceDriverAge.26':
        'Assicurazione del conducente di età pari o superiore a 26 anni',
    'Vehicle.ModelYear.2015': '2015',
    'Vehicle.ModelYear.2016': '2016',
    'Vehicle.ModelYear.2017': '2017',
    'Vehicle.ModelYear.2018': '2018',
    'Vehicle.ModelYear.2019': '2019',
    'Vehicle.ModelYear.2020': '2020',
    'Vehicle.ModelYear.2021': '2021',
    'License.Location.Seoul': 'Seoul',
    'License.Location.Busan': 'Busan',
    'License.Location.Gyeonggi': 'Gyeonggi (Sud)',
    'License.Location.Kangwon': 'Kangwon',
    'License.Location.Chungbuk': 'Chungbuk',
    'License.Location.Chungnam': 'Chungnam',
    'License.Location.Jeonbuk': 'Jeonbuk',
    'License.Location.Jeonnam': 'Jeonnam',
    'License.Location.Gyeongbuk': 'Gyeongbuk',
    'License.Location.Gyeongnam': 'Gyeongnam',
    'License.Location.Jeju': 'Jeju',
    'License.Location.Daegu': 'Daegu',
    'License.Location.Incheon': 'Incheon',
    'License.Location.Kwangju': 'Kwangju',
    'License.Location.Daejeon': 'Daejeon',
    'License.Location.Ulsan': 'Ulsan',
    'License.Location.NorthGyeonggi': 'Gyeonggi (Nord)',
    'License.Type.Large': 'Prima classe (Grande)',
    'License.Type.Normal': 'Prima classe (Normale)',
    'License.Type.Small': 'Prima classe (Piccolo)',
    'License.Type.Trailer': 'Grande rimorchio',
    'License.Type.Wrecker': 'Demolitore',
    'License.Type.SmallTrailer': 'Piccolo rimorchio',
    'License.Type.SecondNormal': '2a classe (Normale)',
    'License.Type.SecondSmall': '2a classe (Piccolo)',
    'License.Type.MotorizedBicycle': '2a classe (Bicimotore)',
    'PenaltyAndTollPage.Objection': 'Dichiarazione di opposizione',
    'PenaltyAndTollPage.LicensePlateNumber': 'N. del veicolo',
    'PenaltyAndTollPage.Violation': 'Data/ora infrazione',
    'PenaltyAndTollPage.UserName': 'Nome del cliente',
    'PenaltyAndTollPage.MobileNumber': 'Numero di telefono',
    'PenaltyAndTollPage.LicenseType': 'Licenza n.',
    'PenaltyAndTollPage.LicnConCode': 'Licenza n.',
    'PenaltyAndTollPage.LicenseType.3': 'Incheon (3)',
    'PenaltyAndTollPage.LicenseType.12': 'Seoul (11)',
    'PenaltyAndTollPage.ExpirationOfLicence': 'Periodo di licenza effettivo',
    'PenaltyAndTollPage.RegistrationDate.Placeholder': '0000.00.00  00',
    'PenaltyAndTollPage.AppealTime.Placeholder': '0000.00.00  00',
    'PenaltyAndTollPage.Violation.Placeholder': '0000.00.00  00',
    'PenaltyAndTollPage.ExpirationOfLicence.Placeholder': '0000.00.00  00',
    'PenaltyAndTollPage.LicensePlateNumber.Placeholder':
        'Inserire n. del veicolo',
    'PenaltyAndTollPage.UserName.Placeholder': 'Inserire nome del cliente',
    'PenaltyAndTollPage.MobileNumber.Placeholder': 'Inserire n. telefono',
    'PenaltyAndTollPage.LicenseType.Placeholder': 'Seoul (11)',
    'PenaltyAndTollPage.LicnConCode.Placeholder': 'Seoul (11)',
    'PenaltyAndTollPage.ZipCode': 'Indirizzo',
    'PenaltyAndTollPage.DetailedAddress': 'Indirizzo dettagliato ',
    'PenaltyAndTollPage.LicenceNumberSecond.Placeholder': 'Inserire n. patente',
    'PenaltyAndTollPage.RentDivision': 'Categoria di noleggio',
    'PenaltyAndTollPage.RentDivision.Placeholder': 'Generale',
    'PenaltyAndTollPage.RentDuration': 'Periodo di noleggio',
    'PenaltyAndTollPage.RentDuration.Placeholder': '0000.00.00',
    'PenaltyAndTollPage.BusinessName': 'Nome della società',
    'PenaltyAndTollPage.BusinessName.Placeholder':
        'Inserire nome della società',
    'PenaltyAndTollPage.NumberOfBusiness': 'N. operatore',
    'PenaltyAndTollPage.NumberOfBusiness.Placeholder': 'Inserire n. operatore',
    'PenaltyAndTollPage.Category': 'Tipo di infrazione',
    'PenaltyAndTollPage.Category.Placeholder': 'Tutti',
    'PenaltyAndTollPage.PenaltyFee.Placeholder': 'Inserire importo',
    'PenaltyAndTollPage.PenaltyFee': 'Penalità',
    'PenaltyAndTollPage.Location.Placeholder': 'Inserire luogo infrazione',
    'PenaltyAndTollPage.Organization.Placeholder': 'Inserire reparto emittente',
    'PenaltyAndTollPage.Desc.Placeholder': 'Inserire contenuto',
    'PenaltyAndTollPage.Desc': 'Dettagli',
    'PenaltyAndTollPage.ProcessObjection.Placeholder': 'Sì',
    'PenaltyAndTollPage.ProcessObjection': 'Obiezione processo',
    'PenaltyAndTollPage.Appeal': 'Obiezione processo',
    'PenaltyAndTollPage.Appeal.Placeholder': 'Sì',
    'PenaltyAndTollPage.Remark.Placeholder': 'Inserire contenuto',
    'PenaltyAndTollPage.Remark': 'Contenuto',
    'PenaltyAndTollPage.ViolationPlace.Placeholder':
        'Inserire luogo infrazione',
    'PenaltyAndTollPage.ViolationPlace': 'Luogo infrazione',
    'PenaltyAndTollPage.Location': 'Luogo infrazione',
    'PenaltyAndTollPage.Organization': 'Reparto emittente',
    'PenaltyAndTollPage.ProcessingDate': 'Data/ora processata',
    'PenaltyAndTollPage.ConnectRentInformation':
        'Collegamento con informazioni di noleggio',
    'PenaltyAndTollPage.Check.RentInformation':
        "Inserire il numero del veicolo e la data dell'infrazione.",
    'PenaltyAndTollPage.Fail.ConnectRentInformation':
        'Le informazioni sul noleggio non esistono.',
    'PenaltyAndTollPage.Fail.Create':
        'Impossibile registrare penalità/pedaggio.',
    'PenaltyAndTollPage.Success.Create': 'Penalità/pedaggio registrato.',
    'PenaltyAndTollPage.Success.Delete': 'Penalità/pedaggio eliminato.',
    'PenaltyAndTollPage.Fail.Delete':
        'Impossibile eliminare penalità/pedaggio.',
    'PenaltyAndTollPage.Fail.ViolationList':
        'Impossibile recuperare la lista penalità/pedaggio.',
    'PenaltyAndTollPage.Fail.Detail':
        'Impossibile recuperare i dettagli di penalità/pedaggio.',
    'PenaltyAndTollPage.Fail.Download': 'Impossibile stampare.',
    'PenaltyAndTollPage.Success.Update': 'Penalità/pedaggio modificato.',
    'PenaltyAndTollPage.Fail.Update':
        'Impossibile modificare penalità/pedaggio.',
    'PenaltyAndToll.NumberOfPenalty': 'N. penalità',
    'PenaltyAndToll.ViolationCategory': 'Tipo di infrazione',
    'PenaltyAndToll.Fee': 'Tassa',
    'PenaltyAndTollPage.RegistrationDate': 'Data/ora infrazione',
    'PenaltyAndToll.ViolationDate': 'Data/ora infrazione',
    'PenaltyAndToll.Vehicle': 'Veicolo',
    'PenaltyAndToll.UserName': 'Nome del cliente',
    'PenaltyAndToll.MobileNumber': 'Numero di telefono',
    'PenaltyAndToll.Business': 'Operatore',
    'PenaltyAndToll.NumberOfPenalty.Placeholder': 'Inserire n. penalità',
    'PenaltyAndToll.Download.PdfName': 'Dichiarazione di opposizione.pdf',
    'PenaltyAndTollPage.Type.Park': 'Violazione parcheggio',
    'PenaltyAndTollPage.Type.Sign': 'Violazione segnaletica',
    'PenaltyAndTollPage.Type.Speed': 'Violazione velocità',
    'PenaltyAndTollPage.Yes': 'Sì',
    'PenaltyAndTollPage.No': 'No',
    'PenaltyAndTollPage.Required': 'Compilare tutti i campi.',
    'AppealDetail.DocID': 'Documento n.',
    'AppealDetail.AppealTime': 'Data di esecuzione',
    'AppealDetail.Organization': 'Destinatario',
    'AppealDetail.Temp': 'CC',
    'AppealDetail.Remark': 'Contenuto',
    'AppealDetail.LicensePlateNumber': 'Infrazione veicolo n.',
    'AppealDetail.UserName': 'Nome del cliente',
    'AppealDetail.LicnCon': 'Licenza n.',
    'AppealDetail.ViolationTime': 'Data/ora infrazione',
    'AppealDetail.ReservationTime': 'Periodo di noleggio',
    'AppealDetail.Representative': 'CEO',
    'AppealDetail.Paragraph': 'A causa di',
    'AppealDetail.Paragraph2':
        'che si è verificato durante il periodo di noleggio; presento una Dichiarazione di opposizione come sopra, pregando quindi di rivederlo e trasferirlo al conducente.',
    'MyPage.JoinDate': 'Data di iscrizione',
    'MyPage.Name': 'Nome del cliente',
    'MyPage.Email': 'ID utente',
    'MyPage.MobileNumber': 'Numero di telefono',
    'MyPage.BirthDate': 'Data di nascita',
    'MyPage.ApproveMessage': 'Motivo del rifiuto',
    'MyPage.Info': 'Informazioni di base',
    'MyPage.BusinessInfo': 'Informazioni aziendali',
    'MyPage.Business.BusinessCode': 'Codice aziendale',
    'MyPage.Business.Name': 'Nome della società',
    'MyPage.Business.Required': 'Inserire un codice aziendale valido.',
    'MyPage.EmployeeRegNum': 'Dipendente n.',
    'MyPage.TeamName': 'Squadra',
    'MyPage.CompanyEmail': 'Indirizzo email',
    'MyPage.ActivationStatus': 'Status',
    'MyPage.PasswordChange': 'Cambia password',
    'MyPage.OldPassword': 'Vecchia password',
    'MyPage.NewPassword': 'Nuova password',
    'MyPage.CheckNewPassword': 'Conferma nuova password',
    'MyPage.PasswordInfo':
        'Per la password, utilizzare da 8 a 20 caratteri, scegliendo 3 diversi tipi di caratteri, tra cui maiuscole/minuscole, numeri e caratteri speciali.',
    'MyPage.Change': 'Cambia',
    'MyPage.UserDelete': 'Revoca',
    'MyPage.UserType': 'Tipo',
    'MyPage.Update.Fail': 'Impossibile modificare le informazioni.',
    'MyPage.Update.Success': 'Le informazioni sono state modificate.',
    'MyPage.UpdatePwdCheck.Fail': 'La vecchia password è sbagliata.',
    'MyPage.UpdatePwd.Fail': 'Impossibile modificare la password.',
    'MyPage.UpdatePwd.Success': 'La password è stata modificata.',
    'TestCarConfig.Business': "Selezionare l'operatore",
    'TestCarConfig.ReservationTime':
        'Periodo di prenotazione (n. massimo di giorni)',
    'TestCarConfig.MaxMonth': "Periodo di prova dell'auto (n. massimo di mesi)",
    'TestCarConfig.ReservationCount':
        'Conteggio delle prenotazioni (per trimestre)',
    'TestCarConfig.TokURL': 'URL per le revisioni delle auto di prova',
    'TestCarConfig.Info': 'Limite fissato per le auto di prova',
    'TestCarConfig.ReservationHour': 'Orario di inizio/fine prenotazione',
    'TestCarConfig.ReservationInfo': 'Informazioni sulla prenotazione',
    'TestCarConfig.Toast.getTestCarConfig.Fail':
        "Impossibile recuperare l'elenco configurazioni.",
    'TestCarConfig.Toast.createTestCarConfig.Fail':
        'Impossibile registrare la configurazione.',
    'TestCarConfig.Toast.updateTestCarConfig.Success':
        'La configurazione è stata aggiornata.',
    'TestCarConfig.Toast.updateTestCarConfig.Fail':
        'Impossibile aggiornare la configurazione.',
    'Program.Name': 'Nome del programma',
    // 'Program.Method': 'Azione',
    'Program.Action': 'Azione',
    'Program.ApiUrl': 'URL API',
    'Program.Confirm.MultiDelete': 'Eliminare {count} programmi?',
    'Program.View.Fail':
        'Impossibile recuperare le informazioni sul programma.',
    'Program.Delete.Success': 'Il programma è stato eliminato.',
    'Program.Delete.Fail': 'Impossibile eliminare il programma.',
    'Program.Create.Success': 'Il programma è stato registrato.',
    'Program.Create.Fail': 'Impossibile registrare il programma.',
    'Program.Action.Inquiry': 'Leggere',
    'Program.Action.Save': 'Scrivere',
    'Program.Action.Delete': 'Eliminare',
    'Program.Action.Download': 'Scaricare',
    'Program.Name.Placeholder': 'Inserire il nome del programma.',
    'Program.Apiurl.Placeholder': "Inserire l'URL dell'API.",
    'Message.ResourceKey': 'Chiave',
    'Message.Message': 'Messaggio',
    'Message.Confirm.MultiDelete': 'Eliminare {count} messaggi?',
    'Message.View.Fail':
        'Impossibile recuperare le informazioni sul messaggio.',
    'Message.Delete.Success': 'Il messaggio è stato eliminato.',
    'Message.Delete.Fail': 'Impossibile eliminare il messaggio.',
    'Message.Create.Success': 'Il messaggio è stato creato.',
    'Message.Create.Fail': 'Impossibile creare il messaggio.',
    'Message.Update.Success': 'Il messaggio è stato aggiornato.',
    'Message.Update.Fail': 'Impossibile aggiornare il messaggio.',
    'Message.Key.Placeholder': 'Inserire il valore chiave.',
    'Message.Message.Placeholder': 'Inserisci un messaggio.',
    'Message.Confirm.Delete': 'Eliminare il messaggio?',
    'Code.RootName': 'Gestione del codice',
    'Code.SelectCode': 'Selezionare il codice',
    'Code.Type': 'Tipo',
    'Code.Type.Group': 'Gruppo di codici',
    'Code.Type.Value': 'Elemento di codice',
    'Code.Name': 'Nome del codice',
    'Code.GroupKey': 'Chiave di gruppo',
    'Code.CodeValue': 'Valore del codice',
    'Code.OrderNum': 'Priorità',
    'Code.Use': 'Stato di visualizzazione',
    'Code.Use.false': 'Nascondere',
    'Code.Use.true': 'Visualizzazione',
    'Code.Name.Placeholder': 'Inserire il nome del codice.',
    'Code.MsgKey.Placeholder': 'Inserire il codice chiave del messaggio.',
    'Code.GroupKey.Placeholder': 'Immettere un valore chiave di gruppo.',
    'Code.CodeValue.Placeholder': 'Inserire il valore del codice.',
    'Code.OrderNum.Placeholder': 'Inserire un valore di priorità.',
    'Code.View.Fail': 'Impossibile recuperare le informazioni sul codice.',
    'Code.Confirm.Delete': 'Eliminare il codice?',
    'Code.Create.Success': 'Il codice è stato registrato.',
    'Code.Create.Fail': 'Impossibile registrare il codice.',
    'Code.Update.Success': 'Il codice è stato aggiornato.',
    'Code.Update.Fail': 'Impossibile aggiornare il codice.',
    'Code.Delete.Success': 'Codice eliminato.',
    'Code.Delete.Fail': 'Impossibile eliminare il codice.',
    'Menu.RootName': 'HOME',
    'Menu.SelectCode': 'Selezionare un menu',
    'Menu.Name': 'Nome menu',
    'Menu.MenuUrl': 'Percorso del menu',
    'Menu.OrderNum': 'Priorità',
    'Menu.Use': 'Stato di visualizzazione',
    'Menu.Use.false': 'Nascondere',
    'Menu.Use.true': 'Visualizzazione',
    'Menu.Acl': 'Status di autorità',
    'Menu.Acl.true': 'Usato',
    'Menu.Acl.false': 'Non in uso',
    'Menu.Confirm.Delete': 'Eliminare il menu?',
    'Menu.Menu.Placeholder': 'Immettere il nome del menu.',
    'Menu.Path.Placeholder': 'Immettere il percorso del menu.',
    'Menu.Order.Placeholder': 'Impostare la priorità.',
    'Menu.View.Fail': 'Impossibile recuperare le informazioni sul menu.',
    'Menu.Delete.Success': 'Il menu è stato eliminato.',
    'Menu.Delete.Fail': 'Impossibile cancellare il menu.',
    'Menu.Create.Success': 'Il menu è stato registrato.',
    'Menu.Create.Fail': 'Impossibile registrare il menu.',
    'Menu.Update.Success': 'Il menu è stato aggiornato.',
    'Menu.Update.Fail': 'Impossibile aggiornare il menu.',
    'Menu.Program.View.Fail':
        'Impossibile recuperare le informazioni sul programma.',
    'Menu.Program.Delete.Success': 'Il programma è stato eliminato.',
    'Menu.Program.Delete.Fail': 'Impossibile eliminare il programma.',
    'Menu.Program.Create.Success': 'Il programma è stato registrato.',
    'Menu.Program.Create.Fail': 'Impossibile registrare il programma.',
    'Role.Select.Placeholder': 'Selezionare un ruolo.',
    'Role.Name': 'Nome del ruolo',
    'Role.RoleKey': 'Chiave di ruolo',
    'Role.Note': 'Nota sul ruolo',
    'Role.Name.Placeholder': 'Inserire un nome del ruolo',
    'Role.RoleKey.Placeholder': 'Inserire una chiave di ruolo',
    'Role.Note.Placeholder': 'Inserire una nota sul ruolo.',
    'Role.Acl.Title': 'Autorità di accesso',
    'Role.Admin.Title': 'Direttore',
    'Role.View.Fail': 'Impossibile recuperare le informazioni sul ruolo.',
    'Role.Delete.Success': 'Il ruolo è stato eliminato.',
    'Role.Delete.Fail': 'Impossibile eliminare il ruolo.',
    'Role.Create.Success': 'Il ruolo è stato registrato.',
    'Role.Create.Fail': 'Impossibile utilizzare una chiave di ruolo duplicata.',
    'Role.Update.Success': 'Il ruolo è stato aggiornato.',
    'Role.Update.Fail': 'Impossibile utilizzare una chiave di ruolo duplicata.',
    'Role.Acl.View.Fail':
        "Impossibile recuperare le informazioni dell'autorità di accesso.",
    'Role.Acl.Update.Success': "L'autorità di accesso è stata aggiornata.",
    'Role.Acl.Update.Fail': "Impossibile aggiornare l'autorità di accesso.",
    'Role.Admin.View.Fail':
        "Impossibile visualizzare le informazioni dell'amministratore.",
    'Role.Confirm.Delete': 'Eliminare il ruolo?',
    'Role.Menu': 'Nome menu',
    'Role.Read': 'Leggere',
    'Role.Write': 'Scrivere',
    'Role.Delete': 'Eliminare',
    'Role.Download': 'Scaricare',
    'Role.Admin.Name': 'Nome',
    'Role.Admin.UserID': 'ID utente',
    'Role.Admin.MobileNumber': 'N. di telefono',
    'License.Confirm.MultiDelete': 'Eliminare {count} gestioni di licenze?',
    'License.View.Fail':
        'Impossibile visualizzare le informazioni sulla gestione delle licenze.',
    'License.Confirm.Delete': 'Eliminare la gestione delle licenze?',
    'License.Delete.Success': 'La gestione delle licenze è stata eliminata.',
    'License.Delete.Fail': 'Impossibile eliminare la gestione delle licenze.',
    'License.Create.Success': 'La gestione delle licenze è stata registrata.',
    'License.Create.Fail': 'Impossibile registrare la gestione delle licenze.',
    'License.Update.Success': 'La gestione delle licenze è stata aggiornata.',
    'License.Update.Fail': 'Impossibile aggiornare la gestione delle licenze.',
    'License.View.Menu.Fail':
        "Impossibile visualizzare le informazioni dell'autorità di accesso per la gestione delle licenze.",
    'License.Update.Menu.Fail':
        "Impossibile aggiornare l'autorità di accesso alla gestione delle licenze.",
    'License.View.Business.Fail':
        'Impossibile visualizzare le informazioni aziendali di gestione delle licenze.',
    'License.Name': 'Nome della licenza',
    'License.Use': 'Stato di utilizzo',
    'License.Note': 'Nota',
    'License.BusienssCnt': 'Numero di abbonamento',
    'License.UsageFee': "Commissione d'uso/mese",
    'License.Use.false': 'Non in uso',
    'License.Use.true': 'Usato',
    'License.Menu.Title': 'Autorità di accesso',
    'License.Business.Title': 'Operatore',
    'License.Business.Name': "Nome dell'operatore",
    'License.Business.Date': 'Data di licenza',
    'License.Name.Placeholder': 'Immettere il nome della licenza.',
    'License.UseFee.Placeholder': 'Inserire la commissione di utilizzo.',
    'License.Note.Placeholder': 'Inserire una nota.',
    'CompanyUser.CompanyId': "Nome dell'azienda",
    'CompanyUser.ManagerName': 'Nome del direttore',
    'CompanyUser.PhoneNumber': 'N. di telefono',
    'CompanyUser.FaxNumber': 'N. di Fax',
    'CompanyUser.ManagerMobileNumber': 'N. di telefono dei direttori',
    'CompanyUser.ManagerEmail': 'Indirizzo email del direttore',
    'CompanyUser.Get.Fail':
        "Le informazioni del direttore dell'azienda non sono disponibili.",
    'Title.CompanyUser': 'Direttore aziendale',
    'CompanyUser.Title.Create': "Registrare il direttore dell'azienda",
    'CompanyUser.CompanyID.Placeholder': 'Selezionare un ID azienda.',
    'CompanyUser.ManagerName.Placeholder':
        "Inserire il nome del direttore dell'azienda.",
    'CompanyUser.PhoneNumber.Placeholder':
        "Inserire in n. di telefono del direttore dell'azienda",
    'CompanyUser.FaxNumber.Placeholder':
        "Inserire il n. di fax del direttore dell'azienda.",
    'CompanyUser.ManagerMobileNumber.Placeholder':
        "Inserire in n. di telefono del direttore dell'azienda",
    'CompanyUser.ManagerEmail.Placeholder':
        "Inserire l'indirizzo e-mail del direttore dell'azienda.",
    'CompanyUser.Filter.CompanyID': 'ID azienda',
    'CompanyUser.Filter.ManagerName': 'Nome del direttore',
    'CompanyUser.Filter.ManagerMobileNumber': 'N. di telefono dei direttori',
    'Company.Name': "Nome dell'azienda",
    'Company.CompanyBusiness': "Stato di attività dell'azienda",
    'Company.CompanyType': 'Tipo di azienda',
    'Company.PhoneNumber': 'N. di telefono',
    'Company.FaxNumber': 'N. di FAX',
    'Company.Address': 'Indirizzo',
    'Company.BusinessRegNum': 'N. operatore.',
    'Company.CeoName': 'Nome',
    'Company.Homepage': 'Sito Internet',
    'Company.RepBusinessType': 'Tipo di attività principale',
    'Company.BusinessType': 'Tipo di attività',
    // 'Company.BusinessId': 'ID azienda',
    // 'Company.BranchId': 'ID filiale',
    'Company.BusinessId': 'Nome della società',
    'Company.BranchId': 'Filiale',
    'COMPANY.Type.Mechanic': 'Officina di manutenzione',
    'COMPANY.Type.Insurance': 'Compagnia di Assicurazione',
    'Company.Title.Create': "Registrare un'azienda",
    'Title.Company': 'Azienda',
    'Company.Name.Placeholder': "Inserire il nome dell'azienda.",
    'Company.CompanyType.Placeholder': 'Selezionare il tipo di azienda.',
    'Company.PhoneNumber.Placeholder': 'es.) 0101234567',
    'Company.FaxNumber.Placeholder': 'es.) 0101234567',
    'Company.BusinessRegNum.Placeholder': 'Inserire il n. operatore.',
    'Company.CeoName.Placeholder':
        "Inserire il nome dell'AD/direttore di filiale.",
    'Company.Homepage.Placeholder': 'Inserire il sito Internet.',
    'Company.CompanyBusiness.Placeholder':
        'Inserire lo stato di attività della propria azienda.',
    'Company.RepBusinessType.Placeholder': 'Inserire il tipo di attività.',
    'Company.BusinessType.Placeholder': 'Immettere un tipo di attività.',
    // 'Company.BusinessId.Placeholder': 'Inserire l'ID dell'azienda',
    // 'Company.BranchId.Placeholder': 'Inserire l'ID filiale',
    'Company.BusinessId.Placeholder': 'Selezionare una società.',
    'Company.BranchId.Placeholder': 'Selezionare una filiale.',
    'Company.Address.Placeholder': '00000',
    'Company.Address.Address.Placeholder':
        'Si prega di cercare un codice postale.',
    'Company.Address.DetailedAddress.Placeholder': "Inserire l'indirizzo.",
    'TestCarConfig.Toast.createTestCarConfig.Success':
        'La configurazione è stata registrata.',
    'TestCarConfig.Toast.ReservationHour.Fail':
        "L'orario di inizio della prenotazione è uguale o superiore all'ora di fine.",
    'Holiday.Create.Info': 'Registrazione di una festività',
    'Holiday.HolidayName': 'Nome della festività',
    'Holiday.Date': 'Data',
    'Holiday.Toast.getHolidayList.Fail':
        "Impossibile recuperare l'elenco festività.",
    'Holiday.Toast.getHolidayDetail.Fail':
        'Impossibile recuperare i dettagli della festività.',
    'Holiday.Toast.createHoliday.Success': 'La festività è stata registrata.',
    'Holiday.Toast.createHoliday.Fail': 'Impossibile registrare una festività.',
    'Holiday.Toast.updateHoliday.Success': 'La festività è stata aggiornata.',
    'Holiday.Toast.updateHoliday.Fail': 'Impossibile aggiornare la festività.',
    'Holiday.Toast.deleteHoliday.Success': 'La festività è stata cancellata.',
    'Holiday.Toast.deleteHoliday.Fail': 'Impossibile cancellare la festività.',
    'Menu.Accident': "Informazioni sull'incidente/riparazione",
    // 'Title.AccidentCreate': 'Registrazione dell'incidente/riparazione',
    'Title.AccidentDetail': "Informazioni sull'incidente/riparazione",
    'Accident.Btn.Create': 'Registrazione incidente',
    'Accident.Date': "Data dell'incidente",
    'Accident.Location': 'Filiale',
    'Accident.Vehicle.Information': 'Informazioni sul veicolo',
    'Accident.Vehicle.LicensePlateNum': 'N. veicolo',
    'Accident.RepairType': 'Tipo di incidente',
    'Accident.Status': 'Stato',
    'Accident.Id': 'N. registrazione',
    'Accident.Company': 'Azienda',
    'Accident.Status.Occur': 'Avvenimento di un incidente',
    'Accident.Status.Requested': 'Riparazione richiesta',
    'Accident.Status.Repairing': 'Riparazione',
    'Accident.Status.Repaired': 'Riparato',
    'Accident.Status.Unknown': 'Sconosciuto',
    'Accident.Filter.LicensePlateNumber': 'N. veicolo',
    'Accident.Filter.RepairType': 'Tipo di incidente',
    'Accident.Filter.VehicleModel': 'Modello del veicolo',
    'Accident.Filter.Location': 'Filiale',
    'Accident.Filter.Id': 'N. registrazione',
    'Accident.Filter.Company': 'Azienda',
    'Accident.VehicleInfo': 'Informazioni sul veicolo',
    'Accident.ReservationInfo': 'Informazioni sul noleggio',
    'Accident.RepairInfo': 'Dettagli di riparazione',
    'Accident.CostInfo': 'Elaborazione dei costi',
    'Accident.InsuranceInfo': 'Compagnia assicurativa della controparte',
    'Accident.Get.Fail':
        "Impossibile recuperare le informazioni sull'incidente/riparazione.",
    'Accident.Create.Succes': 'Incidente registrato con successo.',
    'Accident.Create.Fail': 'Registrazione incidente non riuscita.',
    'AccidentVehicle.LicensePlateNum': 'N. veicolo',
    'AccidentVehicle.Company': 'Azienda',
    'AccidentVehicle.AccidentDate': "Data dell'incidente",
    'AccidentVehicle.Location': 'Filiale',
    'AccidentVehicle.AccidentStatus': 'Stato',
    'AccidentVehicle.AccidentType': 'Tipo di incidente',
    'AccidentVehicle.Search.Vehicle': 'Ricercare veicolo',
    'AccidentVehicle.Search.Company': "Cercare un'azienda",
    'AccidentVehicle.AccidentDate.DayPicker': "Data dell'incidente",
    'AccidentVehicle.Repair': 'Data della riparazione',
    'AccidentVehicle.Repair.Start': 'Data di inizio riparazione',
    'AccidentVehicle.Repair.End': 'Data di fine riparazione',
    'AccidentReservation.ReservationCode': 'Codice di prenotazione',
    'AccidentReservation.ReservationDate': 'Periodo di noleggio',
    'AccidentReservation.CustomerName': 'Nome del cliente',
    'AccidentReservation.LicenseType': 'Tipo di patente',
    'AccidentReservation.LicenseNumber': 'Patente di guida n.',
    'AccidentReservation.Tel': 'N. di telefono',
    'AccidentReservation.Search.ReservationInfo':
        'Collegamento delle informazioni sul noleggio',
    'AccidentRepair.RepairDetail': 'Dettagli di riparazione',
    'AccidentRepair.RepairBefore': 'Prima della riparazione',
    'AccidentRepair.RepairAfter': 'Dopo la riparazione',
    'Accident.RepairBefore.Placeholder':
        'Inserire gli elementi prima della riparazione.',
    'Accident.RepairAfter.Placeholder':
        'Inserire gli elementi dopo la riparazione.',
    'AccidentInsurance.InsuranceCompany':
        'Compagnia assicurativa della controparte',
    'AccidentInsurance.InsuranceCode':
        "N. di registrazione dell'assicurazione della controparte",
    'AccidentInsurance.InsuranceManager': 'Direttore',
    'AccidentInsurance.InsuranceManagerTel': 'N. di telefono del direttore',
    'AccidentCost.InsuranceTreatement': 'Trattamento assicurativo',
    'AccidentCost.Company': 'Compagnia di Assicurazione',
    'AccidentCost.InsuranceCode': 'N. registrazione',
    'AccidentCost.OpponentInjury': 'Per persona',
    'AccidentCost.OpponentVehicle': 'Per oggetti',
    'AccidentCost.OwnVehicle': 'Copertura di collisione',
    'AccidentCost.OwnInjury': 'Danno autoinflitto',
    'AccidentCost.Other': 'Altro',
    'AccidentCost.TotalInsuranceCost': "Costo totale dell'assicurazione",
    'AccidentCost.CompanyTreatment': 'Trattamento aziendale',
    'AccidentCost.InsuranceCompanyCharge':
        'Compagnia di assicurazione deducibile',
    'AccidentCost.TotalCompanyCost': "Costo totale dell'azienda",
    'AccidentCost.AmountOfAccident': 'Importo raccolto',
    'AccidentCost.IndemnityCost': 'Costo di indennizzo',
    'AccidentCost.CompensationFee': 'Commissione di risarcimento',
    'AccidentCost.Unrepaired': 'Non riparato',
    'AccidentCost.TotalCollectCost': 'Importo totale raccolto',
    'AccidentCost.TotalCost': 'Totale',
    'AccidentCost.Status': 'Stato del pagamento',
    'AccidentCost.PaymentMethod': 'Metodo di pagamento',
    'AccidentBusiness.BusinessType.Placeholder': "Selezionare l'operatore",
    'Accident.GetVehicle.Fail': "Impossibile recuperare l'elenco veicoli.",
    'Accident.GetBusiness.Fail':
        "Impossibile recuperare l'elenco degli operatori.",
    'Accident.ReservationCheckParam.Fail':
        "Inserire il n. del veicolo e la data dell'incidente.",
    'Accident.GetReservationCheck.Fail':
        'Impossibile collegare le informazioni sul noleggio.',
    'Accident.Fail.ConnectRentInformation':
        'Le informazioni sul noleggio non esistono.',
    'Accident.Delete.Success':
        "I dettagli dell'incidente sono stati cancellati.",
    'Accident.Edit.Success': "I dettagli dell'incidente sono stati modificati.",
    'Accident.Create.Success':
        "I dettagli dell'incidente sono stati registrati.",
    'Accident.Delete.Fail': "Impossibile eliminare i dettagli dell'incidente.",
    'Accident.Edit.Fail': "Impossibile modificare i dettagli dell'incidente.",
    'Accident.Confirm': "Elimina i dettagli dell'incidente",
    'Accident.Confirm.Delete': "Eliminare i dettagli dell'incidente?",
    'Accident.Confirm.Edit': "Modificare i dettagli dell'incidente?",
    'Accident.Confirm.Create': "Registrare i dettagli dell'incidente?",
    'Menu.Branch': 'Filiale',
    'Title.Branch': 'Filiale',
    'Branch.Name': 'Nome della filiale',
    'Branch.BusinessRegNum': 'N. operatore.',
    'Branch.PhoneNumber': 'N. di telefono',
    'Branch.FaxNumber': 'N. di Fax',
    'Branch.Address': 'Indirizzo',
    'Branch.ManagerName': 'Nome',
    'Branch.ManagerPhoneNumber': 'N. di telefono',
    'Branch.ManagerEmail': 'Indirizzo email',
    'Branch.Title.Create': 'Registrazione della filiale',
    'Branch.Company': 'Filiale',
    'Branch.BusinessID': 'Nome della società',
    'Branch.BusinessID.Placeholder': 'Inserire il nome della società',
    'Branch.Name.Placeholder': 'Inserire il nome della filiale',
    'Branch.BusinessRegNum.Placeholder': 'Inserire il n. operatore.',
    'Branch.PhoneNumber.Placeholder': 'Inserire un n. di telefono',
    'Branch.FaxNumber.Placeholder': 'Inserire un n. fax',
    'Branch.Address.Placeholder': '00000',
    'Branch.ManagerName.Placeholder': "Inserisci il nome dell'AD.",
    'Branch.ManagerPhoneNumber.Placeholder':
        "Inserire il n. di telefono dell'AD/direttore di filiale.",
    'Branch.ManagerEmail.Placeholder':
        "Inserire l'indirizzo email dell'AD/direttore di filiale.",
    'Branch.Company.Placeholder': 'Inserire una filiale.',
    'Branch.Address.Address.Placeholder':
        'Si prega di cercare un codice postale.',
    'Branch.Address.DetailedAddress.Placeholder': "Inserire l'indirizzo.",
    'Accident.PaymentStatus.Unpaid': 'Non pagato',
    'Accident.PaymentStatus.Paid': 'Pagato',
    'Accident.PaymentType.Card': 'Carta',
    'Accident.PaymentType.Cash': 'Contanti',
    'Accident.GetBranch.Fail': "Impossibile recuperare l'elenco filiali.",
    'Accident.GetCompany.Fail': "Impossibile caricare l'elenco delle aziende.",
    'Code.MsgKey': 'Chiave di codice',
    'Menu.VehicleInsurance': 'Gestione assicurativa',
    'VehicleInsurance.Get.Fail':
        'Impossibile recuperare la gestione assicurativa.',
    'VehicleInsurance.LicensePlateNumber': 'N. veicolo',
    'VehicleInsurance.InsureYear': 'N.° anno',
    'VehicleInsurance.InsureCondition': 'Condizione assicurata',
    'VehicleInsurance.VehicleGrade': 'Grado del veicolo',
    'VehicleInsurance.VehicleModelName': 'Nome del modello del veicolo',
    'VehicleInsurance.CompanyName': 'Compagnia di assicurazione',
    'VehicleInsurance.StartDate': "Data di inizio dell'assicurazione",
    'VehicleInsurance.EndDate': "Data di scadenza dell'assicurazione",
    'VehicleInsurance.Filter.LicensePlateNumber': 'N. veicolo',
    'VehicleInsurance.Filter.CompanyName': 'Compagnia di assicurazione',
    'Title.VehicleInsurance': 'Gestione delle assicurazioni dei veicoli',
    'VehicleInsurance.VehicleInformation': 'Informazioni sul veicolo',
    'VehicleInsurance.InsuranceInformation': 'Informazioni assicurative',
    'VehicleInsurance.insureSelfVehicle': 'Copertura di collisione assicurata',
    'VehicleInsurance.PaymentDueDay': 'Data di pagamento',
    'VehicleInsurance.InsurePayment': 'Assicurazione pagata',
    'VehicleInsurance.paymentMethod': 'Metodo di pagamento',
    'VehicleInsurance.insureSelfVehicle.true': 'Sì',
    'VehicleInsurance.insureSelfVehicle.false': 'No',
    'VehicleInsurance.paymentMethod.true': 'Pagato per intero',
    'VehicleInsurance.paymentMethod.false': 'Rata mensile',
    'VehicleInsurance.MonthPer': 'Numero di rate',
    'VehicleInsurance.MonthPerPayment': 'Pagamento mensile',
    'VehicleInsurance.VehiclePremium': 'Informazioni sui premi assicurativi',
    'VehicleInsurance.insureOrder': 'Sequenza',
    'VehicleInsurance.paymentDate': 'Data di pagamento',
    'VehicleInsurance.monthPerPayment': 'Pagamento mensile',
    'VehicleInsurance.delete': 'Eliminare',
    'Menu.MsgKey': 'Tasto menu',
    'Menu.MsgKey.Placeholder': 'Inserisci un tasto menu',
    'Title.ShortRental': 'Noleggio breve',
    'ShortRental.Type': 'Categoria di noleggio',
    'ShortRental.Type.Public': 'Auto a noleggio',
    'ShortRental.Type.Private': 'Società',
    'ShortRental.Type.Rent': 'Registrare un noleggio a breve termine',
    'ShortRental.Type.Reservation': 'Prenotazione di noleggio breve',
    'ShortRental.RentalInfo': 'Informazioni sul noleggio di auto e veicoli',
    'ShortRental.Duration': 'Periodo di noleggio',
    'ShortRental.Duration.Value': '{days} giorni {hours} ore',
    'ShortRental.Insurance.Type': 'Tipo di assicurazione',
    'ShortRental.Insurance.Age.Placeholder':
        "Selezionare l'età di assicurazione.",
    'ShortRental.Insurance.Type.Placeholder':
        'Selezionare un tipo di assicurazione.',
    'Reservation.EndLocation': 'Luogo di restituzione',
    'Reservation.EndLocation.Placeholder':
        'Selezionare un luogo di restituzione.',
    'Common.Deletes.Fail':
        'Alcuni elementi non possono essere eliminati. Controllare i dati.',
    'Reservation.OwnVehicle': "Stato dell'assicurazione contro le collisioni",
    'Reservation.CarInfo': 'Informazioni sul veicolo',
    'Reservation.SelectCar': 'Selezione del veicolo',
    'Reservation.CarNumber': 'N. veicolo',
    'Reservation.CarModel': 'Modello del veicolo',
    'Reservation.CarColor': 'Colore del veicolo',
    'Reservation.CarFuel': 'Tipo di carburante',
    'Reservation.CarRate': 'Classe del veicolo',
    'Reservation.Agent': 'Responsabile',
    'Reservation.RentAgent': 'Agente di noleggio',
    'Reservation.ReturnAgent': 'Agente di restituzione',
    'Reservation.Agent.Placeholder': 'Selezionare un dipendente.',
    'Reservation.Join': 'Unisciti',
    'Reservation.NotJoin': 'Non assicurato',
    'Reservation.Indemnity.title': 'Informazioni sui costi di indennizzo',
    'Reservation.Run.title': 'Carburante e distanza percorsa',
    'Reservation.Run.Fuel': 'Livello di carburante',
    'Reservation.Run.Run': 'Distanza di viaggio',
    'Reservation.Run.Rental': 'Noleggio',
    'Reservation.Run.Return': 'Restituzione',
    'ShortRental.OwnVehicle.Type.Placeholder':
        'Selezionare il tipo di abbonamento.',
    'ShortRental.User.Public': 'Società',
    'ShortRental.User.Private': 'Utente privato',
    'ShortRental.User.SelectBusiness': 'Trova una società esistente',
    'ShortRental.User.Name.Error': 'Inserire un nome.',
    'ShortRental.User.ZipCode.Error': 'Inserire il codice postale',
    'ShortRental.User.SelectUser': 'Trova un membro esistente',
    'ShortRental.User.SelectEmployee': 'Trovare dipendente',
    'ShortRental.User.Contact': "Dati di contatto dell'utente",
    'ShortRental.User.Contact.Placeholder':
        'Inserire i propri dati di contatto.',
    'ShortRental.User.Contact.Error': 'Inserire i propri dati di contatto.',
    'ShortRental.User.Address.Error': "Inserire l'indirizzo.",
    'ShortRental.User.Email': 'Indirizzo email',
    'ShortRental.User.Email.Placeholder': "Inserire l'indirizzo e-mail.",
    'ShortRental.User.Fax': 'Fax',
    'ShortRental.User.Fax.Placeholder': 'Inserire un n. fax',
    'ShortRental.User.BusinessRegNum.Error': 'Inserire il n. operatore.',
    'ShortRental.User.DetailAddress.Error': "Inserire l'indirizzo dettagliato.",
    'ShortRental.User.FirstDriver': 'Primo conducente',
    'ShortRental.User.SecondDriver': 'Secondo conducente',
    'ShortRental.Charge.Title': 'Informazioni sui tassi',
    'ShortRental.Charge.Discount.title': 'Informazioni sugli sconti',
    'ShortRental.Charge.Default': 'Commissione di inadempimento',
    'ShortRental.Charge.Discount': 'Tariffa scontata',
    'ShortRental.Charge.Rental': 'Canone di noleggio',
    'ShortRental.Charge.Real': 'Canone di noleggio',
    'ShortRental.Charge.Additional': 'Costo aggiuntivo',
    'ShortRental.Charge.Etc': 'Oltre a premi assicurativi',
    'ShortRental.Charge.Sum': 'Canone di noleggio totale',
    'ShortRental.Etc.Special': 'Questioni speciali',
    'ShortRental.Etc.Memo': 'Memo',
    'ShortRental.Car.Select': 'Non ci sono veicoli disponibili da selezionare.',
    'ShortRental.DueDate.Empty': "Inserire l'ora e la data di noleggio.",
    'Title.LongRental': 'Noleggio a lungo termine di una nuova auto',
    'LongRental.Type': 'Categoria di noleggio',
    'LongRental.Type.Estimate':
        'Stima delle tariffe di noleggio a lungo termine per una nuova auto',
    'LongRental.Type.Create':
        'Registrare il noleggio a lungo termine per una nuova auto',
    'LongRental.ChargeInfo': 'Informazioni sui tassi',
    'LongRental.RentalDeposit': 'Deposito del noleggio',
    'LongRental.ExtraCharge': 'Costo extra',
    'LongRental.ExtraItem': 'Articolo extra',
    'LongRental.MonthlyCharge.Title': 'Canone mensile',
    'LongRental.MonthlyCharge': 'Canone di noleggio',
    'LongRental.MonthlyTotal': 'Totale',
    'LongRental.ContractNumber': 'N. contratto',
    'LongRental.ContractStatus': 'Stato di quotazione',
    'LongRental.Info.Repair': 'Inclusione della manutenzione',
    'LongRental.Repair.Include': 'Includere',
    'LongRental.Repair.NonInclude': 'Non incluso',
    'LongRental.Payment.first': 'Primo pagamento',
    'LongRental.Payment.Final': 'Pagamento finale',
    'LongRental.Payment.Day': 'Data di pagamento',
    'LongRental.Payment.Charge': 'Importo del pagamento',
    'LongRental.Payment.Month': 'Data del pagamento mensile',
    'LongRental.Payment.Beginning': 'Prima data di pagamento',
    'LongRental.CommonDriverInfo': 'Informazioni sul conducente principale',
    'LongRental.User.DriverName': 'Co-conducente',
    'LongRental.User.DriverName.Placeholder':
        'Inserire il nome del conducente principale.',
    'LongRental.User.Contact': "Dati di contatto dell'utente",
    'LongRental.User.Contact.Placeholder':
        'Inserire i propri dati di contatto.',
    'LongRental.User.Birth': 'Data di nascita',
    'LongRental.User.Birth.Placeholder':
        'Le prime sei cifre del codice fiscale, ad esempio, 990126',
    'LongRental.User.DriverLicense': 'Patente del conducente',
    'LongRental.Kind.DriverLicense.Placeholder':
        'Selezionare il tipo di patente.',
    'Title.MonthRental': 'Noleggio mensile a lungo termine',
    'MonthRental.Type': 'Categoria di noleggio',
    'MonthRental.Type.Reservation':
        'Prenotare un noleggio mensile a lungo termine',
    'MonthRental.Type.Create': 'Registrare un noleggio mensile a lungo termine',
    'MonthRental.ReservationNumber': 'N. prenotazione',
    'MonthRental.ReservationStatus': 'Stato della prenotazione',
    'Rent.RentalDueDate': 'Data di noleggio stimata',
    'MonthRental.ExtraCharge': 'Costo aggiuntivo',
    'Menu.Vehicle': 'Gestione dei veicoli',
    'Menu.Maintenance': 'Gestione della manutenzione del veicolo',
    'Menu.Operation': 'Operazione di assistenza',
    'Menu.Vehicle.Insurance': 'Gestione dei pagamenti assicurativi',
    'Menu.Rent': 'Gestione del noleggio',
    'Menu.Payment': 'Gestione vendite/regolamento',
    'Menu.User': 'Gestione utenti',
    'Menu.Work': 'Gestione del lavoro MOCEAN',
    'Menu.System': 'Gestione del sistema',
    'Common.Get.Fail': 'Impossibile recuperare i dati.',
    'Menu.History': 'Storia',
    'Title.ControlHistory': 'Storia del controllo del veicolo',
    'Title.ControlHistory.Request': 'Dettagli richiesta',
    'Title.ControlHistory.Response': 'Dettagli della risposta',
    'ControlHistory.Filter.LicensePlateNumber': 'N. veicolo',
    'ControlHistory.Filter.UserName': 'Nome',
    'ControlHistory.HistoryTime': 'Ora e data di occorrenza',
    'ControlHistory.UserName': 'Nome',
    'ControlHistory.LicensePlateNumber': 'N. veicolo',
    'ControlHistory.ReqTime': 'Orario della richiesta',
    'ControlHistory.ResTime': 'Orario di risposta',
    'ControlHistory.Action': 'Azione',
    'ControlHistory.ReqResultMessage': 'Risultato della richiesta',
    'ControlHistory.ResResultCode': 'Codice di risposta',
    'ControlHistory.ReqTid': 'TID',
    'ControlHistory.ReqClientID': 'ID cliente',
    'ControlHistory.ResTid': 'TID',
    'ControlHistory.ResData': 'Dati di risposta',
    'ControlHistory.ReqData': 'Dati richiesti',
    Vehicle: 'Veicolo',
    'Vehicle.Tab.Detail': 'Dettaglio del veicolo',
    'Vehicle.Tab.Purchase': "Informazioni sull'acquisto",
    'Vehicle.Tab.Accident': 'Incidente/riparazione',
    'Vehicle.PurchaseMethod': 'Metodo di acquisto',
    'Vehicle.PurchaseCost': 'Costo di acquisto',
    'Vehicle.Purchase.BasicCost': 'Costo veicolo di base',
    'Vehicle.Purchase.BasicCost.Placeholder': 'Costo veicolo di base',
    'Vehicle.Purchase.SelectCost': 'Opzioni',
    'Vehicle.Purchase.SelectCost.Placeholder': 'Opzioni',
    'Vehicle.Purchase.Consignment': 'Commissione di spedizione',
    'Vehicle.Purchase.Consignment.Placeholder': 'Commissione di spedizione',
    'Vehicle.Purchase.PureVehiclePrice': 'Prezzo effettivo del veicolo',
    'Vehicle.Purchase.PureVehiclePrice.Placeholder':
        'Prezzo effettivo del veicolo',
    'Vehicle.Purchase.TotalCost': 'Costo di acquisto totale',
    'Vehicle.Pay.DownPayment': 'Acconto',
    'Vehicle.Pay.DownPayment.Placeholder': 'Acconto',
    'Vehicle.Pay.DeliveryPayment': 'Pagamento della consegna',
    'Vehicle.Pay.DeliveryPayment.Placeholder': 'Pagamento della consegna',
    'Vehicle.Pay.TotalLeasePayment': 'Importo totale della rata',
    'Vehicle.Pay.TotalLeasePayment.Placeholder': 'Importo totale della rata',
    'Vehicle.PayInfo': 'Condizioni di pagamento',
    'Vehicle.AdditionalFee': 'Costo aggiuntivo',
    'Vehicle.Additional.MandatoryPremium': 'Premio obbligatorio',
    'Vehicle.Additional.MandatoryPremium.Placeholder': 'Premio obbligatorio',
    'Vehicle.Additional.StampPrice': 'Prezzo di bollo',
    'Vehicle.Additional.StampPrice.Placeholder': 'Prezzo di bollo',
    'Vehicle.Additional.EtcPrice': 'Altri costi',
    'Vehicle.Additional.EtcPrice.Placeholder': 'Altri costi',
    'Vehicle.Additional.TotalCost': 'Costo aggiuntivo totale',
    'Vehicle.RegistrationFee': 'Tassa di immatricolazione del veicolo',
    'Vehicle.Registration.TotalCost': 'Commissione di iscrizione totale',
    'Vehicle.Registration.AcquisitionTax': "Tassa sull'acquisizione",
    'Vehicle.Registration.BoundDiscount': 'Sconto detratto',
    'Vehicle.Registration.RegistrationStampPrice': 'Prezzo di bollo',
    'Vehicle.Registration.LicensePlateNumberPrice': 'Prezzo targa',
    'Vehicle.Registration.RegistrationFee':
        'Commissioni di agenzia per registrazione',
    'Vehicle.Registration.AcquisitionTax.Placeholder':
        "Tassa sull'acquisizione",
    'Vehicle.Registration.BoundDiscount.Placeholder': 'Sconto detratto',
    'Vehicle.Registration.RegistrationStampPrice.Placeholder':
        'Prezzo di bollo',
    'Vehicle.Registration.LicensePlateNumberPrice.Placeholder': 'Prezzo targa',
    'Vehicle.Registration.RegistrationFee.Placeholder':
        'Commissioni di agenzia per registrazione',
    'Vehicle.TotalFee': 'Costo di acquisto totale',
    'Vehicle.Available': 'Controllare i veicoli disponibili',
    'Title.LicenseCheckHistory':
        'Cronologia di verifica della patente del conducente',
    'LicenseCheckHistory.Filter.UserID': 'ID utente',
    'LicenseCheckHistory.Filter.PhoneNumber': 'N. di telefono',
    'LicenseCheckHistory.Filter.ResidentName': 'Nome del conducente',
    'LicenseCheckHistory.Filter.ResidentDate': 'Data di nascita del conducente',
    'LicenseCheckHistory.Filter.ResponseCode': 'Codice di risposta',
    'LicenseCheckHistory.LicenseNo': 'Patente di guida n.',
    'LicenseCheckHistory.LicnConCode': 'Tipo di patente di guida',
    'LicenseCheckHistory.UserID': 'ID utente',
    'LicenseCheckHistory.PhoneNumber': 'N. di telefono',
    'LicenseCheckHistory.ResidentName': 'Nome del conducente',
    'LicenseCheckHistory.ResidentDate': 'Data di nascita del conducente',
    'LicenseCheckHistory.ResponseCode': 'Risultato della risposta',
    'LicenseCheckHistory.ResponseMessage': 'Messaggio di risposta',
    'LicenseCheckHistory.Date': 'Ore di noleggio',
    'LicenseCheckHistory.StartDate': 'Ora di inizio del noleggio',
    'LicenseCheckHistory.EndDate': 'Orario di fine noleggio',
    'LicenseCheckHistory.CreatedAt': 'Orario della richiesta',
    'Title.RentalSearch': 'Ricerca veicoli a noleggio disponibili',
    'RentalSearch.Rental.Day': 'Data di noleggio',
    'RentalSearch.Rental.UseCarYear': 'Anno del modello',
    'RentalSearch.Rental.UseCarFuel': 'Carburante in uso',
    'RentalSearch.No': 'NO',
    'RentalSearch.CarFuel': 'Carburante',
    'RentalSearch.CarLocation': "Posizione dell'automobile",
    'RentalSearch.Title.RentalDetail': 'Dettagli del veicolo',
    'RentalSearch.CarAnsic': 'Anno del modello',
    'RentalSearch.FixedPeople': 'N. dei passeggeri',
    'RentalSearch.CarInsurance': "Assicurazione di noleggio dell'auto",
    'Business.Extra.Service': 'Servizio extra',
    'Business.TestCar': 'Auto di prova',
    'Common.Btn.Update': 'Modificare',
    'Terminal.Title.State': 'Stato',
    'Common.Btn.DetailAndUpdate': 'Dettagli/aggiornamenti',
    'Customer.Title.Private': 'Informazioni personali',
    'Title.Branch.Info': 'Informazioni di base sulla filiale',
    'Vehicle.BasicInfo.CarNumber': 'N. auto',
    'Vehicle.BasicInfo.TerminalNumber': 'N. terminale',
    'Vehicle.BasicInfo.UseCarYear': 'Anno del modello',
    'Vehicle.BasicInfo.Transmission': 'Trasmissione',
    'Vehicle.BasicInfo.CreatedDate': 'Data di Immatricolazione del veicolo',
    'Vehicle.BasicInfo.InsuranceAge': 'Età assicurativa',
    'Vehicle.BasicInfo.Option': ' Opzione',
    'Vehicle.BasicInfo.BusinessName': 'Nome della società',
    'Vehicle.BasicInfo.Location': 'Base',
    'Vehicle.BasicInfo.Color': 'Colore',
    'Vehicle.BasicInfo.FuelType': 'Tipo di carburante',
    'Vehicle.BasicInfo.CarType': 'Classe del veicolo',
    'Vehicle.BasicInfo': 'Informazioni di base',
    'Vehicle.BasicInfo.AvailableDistance': 'Distanza disponibile',
    'Vehicle.BasicInfo.TotalDistance': 'Distanza totale del viaggio',
    'Vehicle.BasicInfo.AuxBattery': 'Batteria AUX',
    'Vehicle.BasicInfo.TempFuelUsage': 'Uso temporaneo di carburante',
    'Vehicle.BasicInfo.FrontLeft': 'Ruota anteriore/Sinistra',
    'Vehicle.BasicInfo.FrontRight': 'Ruota anteriore/Destra',
    'Vehicle.BasicInfo.BackLeft': 'Ruota Posteriore/Sinistra',
    'Vehicle.BasicInfo.BackRight': 'Ruota Posteriore/Destra',
    'Vehicle.BasicInfo.DoorOpen': 'Sbloccare portiera',
    'Vehicle.BasicInfo.DoorClose': 'Chiudere portiera',
    'Vehicle.BasicInfo.Siren': 'Luce/Clacson di emergenza',
    'Common.Btn.UpdateVehicle': 'Modifica delle informazioni sul veicolo',
    'Vehicle.BasicInfo.Type': 'Tipo',
    'Title.Company.Info': "Informazioni sull'azienda",
    'Common.Btn.Setup': 'Impostazioni',
    'Common.Btn.Setup.Complete': 'Configurazione completata',
    'Common.Btn.DuplicateCheck': 'Controllo duplicato',
    'Vehicle.BasicInfo.CreateDate': 'Prima data di registrazione',
    'Vehicle.BasicInfo.ExpireDate': 'Data di scadenza del veicolo',
    'MyPage.Business.Code.Placeholder': 'Inserire il codice della società',
    'MyPage.Branch': 'Filiale',
    'MyPage.Branch.Placeholder': 'Selezionare la società e la filiale.',
    'Vehicle.BasicInfo.VehicleImage': 'Immagine del veicolo',
    'Vehicle.BasicInfo.Terminal': 'Terminale',
    'Vehicle.InterpersonalCompensation1':
        'Pagamento di responsabilità per persona 1',
    'Vehicle.InterpersonalCompensation2':
        'Pagamento di responsabilità per persona 2',
    'Vehicle.Reparation1': 'Pagamento di responsabilità per oggetti 1',
    'Vehicle.UninsuredCarInjury': 'Danno auto non assicurato',
    'Vehicle.PhysicalAccident': 'Incidente con autolesione',
    'Vehicle.Interpersonal': 'Per persona',
    'Vehicle.InterVehicle': 'Per oggetti',
    'Vehicle.SelfDamage': 'Danno autoinflitto',
    'Vehicle.VehicleDamage': 'Copertura di collisione',
    'Vehicle.VehicleDamage.Registration': 'Copertura di collisione assicurata',
    'Vehicle.VehicleDamage.Join': 'Unisciti',
    'Vehicle.VehicleDamage.NotJoin': 'Non registrato',
    'Title.VehicleEdit': 'Aggiornamento del veicolo',
    'Menu.Password': 'Cambia password',
    'Menu.MyBusiness': 'Il mio operatore',
    'Menu.MyBranch': 'La mia filiale',
    UNKNOWN: 'Sconosciuto',
    'BusinessMember.RoleDetail.Business': 'Amministratore rappresentativo',
    'BusinessMember.RoleDetail.Branch': 'Amministratore della filiale',
    'BusinessMember.RoleDetail.Staff': 'Dipendente',
    'Business.Title.License': 'Licenza sottoscritta',
    'Business.License.Name': 'Nome della licenza',
    'Vehicle.Duplicate.Fail': 'Il veicolo n. esiste già.',
    'Vehicle.Duplicate.Required':
        'Controllare se il n. del veicolo è duplicato.',
    'Vehicle.Duplicate.Success':
        'Il n. del veicolo è disponibile per la registrazione.',
    'InsuranceRental.Title': "Assicurazione di noleggio dell'auto",
    'InsuranceRental.Type.Reservation':
        "Prenotazione dell'assicurazione di noleggio dell'auto",
    'InsuranceRental.Type.Creation':
        "Registrazione dell'assicurazione di noleggio dell'auto",
    'InsuranceRental.Charge.Amount': 'Importo fatturato',
    'InsuranceRental.Charge.Daily': 'Tariffa giornaliera',
    'InsuranceRental.Charge.Time': 'Tariffe orarie',
    'InsuranceRental.Charge.Rate': 'Altri tassi',
    'InsuranceRental.Charge.Rate.Charge': 'Tasso di pagamento',
    'InsuranceRental.Charge.Rate.Mistake': 'Tasso di errore',
    'InsuranceRental.Charge.TotalAmount': 'Commissioni totali',
    'InsuranceRental.Charge.TotalAmount.Example':
        '((commissioni giornaliere * n. di giorni) + (commissioni orarie * n. di ore)) * tasso di addebito * (100% - tasso di guasto)',
    'InsuranceRental.Charge.Real': 'Importo effettivo richiesto',
    'InsuranceRental.Accident.Title': "Informazioni sull'incidente",
    'InsuranceRental.Accident.Damage': 'Informazioni sui danni',
    'InsuranceRental.Accident.Assault': "Informazioni sull'assalto",
    'InsuranceRental.Accident.Name': 'Nome',
    'InsuranceRental.Accident.CarNum': 'N. veicolo',
    'InsuranceRental.Accident.CarModel': 'Modello del veicolo',
    'InsuranceRental.Accident.MaintenanceInfo':
        'Informazioni sulla manutenzione',
    'InsuranceRental.Accident.MaintenanceShop': 'Officina di manutenzione',
    'InsuranceRental.Insurance.Title': 'Informazioni assicurative',
    'InsuranceRental.Insurance.CompanyInfo':
        'Informazioni sulla compagnia di assicurazione',
    'InsuranceRental.Insurance.CompanyCode': 'Compagnia di Assicurazione',
    'InsuranceRental.Insurance.InsuranceCode':
        "Codice di registrazione dell'assicurazione",
    'InsuranceRental.Insurance.Manager': "Gestore dell'assicurazione",
    'InsuranceRental.Insurance.Charge.Title': 'Informazioni di fatturazione',
    'InsuranceRental.Insurance.Charge.Date': 'Data di fatturazione',
    'InsuranceRental.Insurance.Charge.Amount': 'Commissioni totali',
    'InsuranceRental.Insurance.Deposit.Date': 'Data di deposito',
    'InsuranceRental.Insurance.Deposit.Amount': 'Importo del deposito',
    'InsuranceRental.Insurance.Mistake.Amount':
        'Spese di responsabilità per danni del cliente',
    'InsuranceRental.Insurance.OwnVehicle.Amount':
        "Importo dell'assicurazione contro le collisioni",
    'InsuranceRental.Insurance.Payment.Type': 'Tipo di pagamento',
    'InsuranceRental.Insurance.Payment.Type.Card': 'Carta',
    'InsuranceRental.Insurance.Payment.Type.Cash': 'Contanti',
    'Common.Btn.Search': 'Cerca',
    'Reservation.Label.StartDate': 'Data di inizio noleggio',
    'Reservation.Label.SearchType': 'Elemento di ricerca',
    'Reservation.Label.Type': 'Categoria di noleggio',
    'Reservation.Label.Status': 'Stato della prenotazione',
    'Reservation.Label.DueDate': 'Orario e data stimati di restituzione',
    'Title.ReservationList': 'Visualizza i dettagli della prenotazione',
    'Title.RentList': 'Gestione dei dettagli di noleggio',
    'Vehicle.DuplicateCheck.FieldNull': 'Inserire il n. del veicolo.',
    'Common.Btn.DeleteVehicle': 'Cancellazione delle informazioni del veicolo',
    'Menu.Vehicle.Mgnt': "Gestione dell'immatricolazione dei veicoli",
    'Menu.Vehicle.Insurance.Payment.Mgnt':
        'Gestione delle informazioni assicurative',
    'Menu.Insurance.Payment.Mgnt': 'Gestione delle informazioni assicurative',
    'Menu.Vehicle.Purchase.Payment.Mgnt': 'Gestione rate mensili',
    'Menu.Vehicle.Accident.Mgnt': "Gestione della riparazione dell'incidente",
    'Menu.Vehicle.Penalty.Pee.Mgnt': 'Gestione delle sanzioni',
    'Menu.Branch.Mgnt': 'Gestione filiale',
    'Menu.Business.Member.Mgnt': 'Gestione dei membri aziendali',
    'Menu.Business.Customer.Mgnt': 'Gestione dei clienti aziendali',
    'Menu.Customer.Mgnt': 'Gestione clienti',
    'Menu.Member.Mgnt': 'Gestione dei dipendenti',
    'Menu.License': 'Gestione delle licenze',
    'Menu.Code': 'Gestione del codice',
    'Menu.Menu': 'Gestione menu',
    'Menu.Role': "Gestione dell'autorità",
    'Menu.Program': 'Gestione dei programmi',
    'Menu.Message': 'Gestione messaggi',
    'Menu.Rent.Fare': 'Gestione dei canoni di noleggio',
    'Menu.Vehicle.Rent.Fare': 'Commissione di noleggio auto',
    'Menu.Rent.Insurance.Fare': 'Gestione dei premi assicurativi di noleggio',
    'Menu.Vehicle.Rent.Insurance.Fare':
        'Premio assicurativo per il noleggio auto',
    'Menu.Rent.Schedule': 'Controllare lo stato di noleggio',
    'Menu.Rent.Vehcile.Available': 'Ricerca veicoli a noleggio disponibili',
    'Menu.Reservation.List': 'Visualizza i dettagli della prenotazione',
    'Menu.Rent.List': 'Gestione dei dettagli di noleggio',
    'Menu.CompanyUser': 'Gestione dirigenza aziendale',
    'Menu.Company': 'Dirigenza aziendale',
    'Reservation.Label.RentalRegister': 'Registrazione del noleggio',
    'Common.Confirm.Reservation.Cancle': 'Cancellare la prenotazione?',
    'Common.Logout.Success':
        'Disconnessione avvenuta in modo sicuro. Arrivederci.',
    'LongRental.Estimate.CarInfo': "Informazioni sull'auto stimata",
    'LongRental.ReservationDate.Placeholder':
        'Selezionare un periodo di noleggio',
    'LongRental.Estimate.VehicleName': 'Nome del veicolo',
    'LongRental.Purchase.Cost': 'Costo di acquisto del veicolo (EUR)',
    'LongRental.RentalDeposit.Cost': 'Deposito del noleggio (EUR)',
    'LongRental.MonthlyCharge.Cost': 'Canone mensile (EUR)',
    'LongRental.Period': 'Periodo (mesi)',
    'LongRental.Options': 'Opzioni',
    'LongRental.PenaltyAmount': 'Penale (%)',
    'LongRental.Note': 'Nota',
    'LongRental.VehiclePremium': 'Informazioni sul pagamento',
    'LongRental.Charge.Method': 'Metodo di pagamento',
    'LongRental.Charge.Bank': 'Banca per la fatturazione',
    'LongRental.Charge.CountNumber': 'N. del conto',
    'LongRental.Charge.CountOwner': 'Titolare del conto',
    'LongRental.Send.Method': 'Luogo di fatturazione',
    'LongRental.Pay.Day': 'Data di pagamento',
    'LongRental.Charge.Method.Placeholder': 'Selezionare metodo di pagamento',
    'LongRental.Charge.Bank.Placeholder':
        'Selezionare una banca per la fatturazione',
    'LongRental.Charge.CountNumber.Placeholder': 'Inserire il n. del conto',
    'LongRental.Charge.CountOwner.Placeholder':
        'Inserire il titolare del conto',
    'LongRental.Send.Method.Placeholder': 'Seleziona il luogo di fatturazione',
    'LongRental.Pay.Day.Placeholder': 'Seleziona data di pagamento',
    'Rent.UseState.Type.Day': 'Giorno',
    'Rent.UseState.Type.Month': 'Mese',
    'Code.GradeType.Placeholder': 'Classe del veicolo',
    'Code.VehicleStatus.Placeholder': 'Stato di noleggio',
    'UseState.State.Short': 'Breve',
    'UseState.State.Insurance': 'Assicurazione',
    'UseState.State.Month': 'Mensile',
    'UseState.State.Long': 'A lungo termine',
    'Code.Weekdays.0': 'Giorno',
    'Code.Weekdays.1': 'Mese',
    'Code.Weekdays.2': 'Martedì',
    'Code.Weekdays.3': 'Mercoledì',
    'Code.Weekdays.4': 'Giovedì',
    'Code.Weekdays.5': 'Venerdì',
    'Code.Weekdays.6': 'Sabato',
    'VehicleModel.Image': 'Immagine',
    'Branch.ManagerName.Detail': 'AD/direttore di filiale',
    'Branch.ManagerPhoneNumber.Detail':
        "N. di telefono dell'AD/direttore di filiale",
    'Branch.ManagerEmail.Detail':
        "Indirizzo e-mail dell'AD/direttore di filiale",
    'Rent.Filter.Label.Status': 'Stato di noleggio',
    'Rent.Filter.Label.IncludeMaintenance': 'Manutenzione inclusa',
    'Rent.Filter.Label.PaymentDate': 'Data di pagamento',
    'Rent.Filter.Code': 'Codice di noleggio',
    'Rent.Filter.User.Name': 'Nome del cliente',
    'Rent.Filter.User.MobileNumber': 'N. di telefono del cliente',
    'Rent.Filter.User.Location': 'Base di noleggio',
    'Rent.Filter.Status.Inuse': 'Attualmente noleggiata',
    'Rent.Filter.Status.Returned': 'Fine del noleggio',
    'Rent.Filter.Status.Canceled': 'Cancellazione',
    'Rent.Filter.Status.LowPower': 'Bassa tensione',
    'Rent.Filter.Status.NoReturned': 'Non restituito',
    'Rent.Filter.Maintenance.Include': 'Includere',
    'Rent.Filter.Maintenance.NotInclude': 'Non incluso',
    'Rent.Filter.Payment.5': '5 giorni',
    'Rent.Filter.Payment.7': '7 giorni',
    'Rent.Filter.Payment.10': '10 giorni',
    'Rent.Filter.Payment.15': '15 giorni',
    'Rent.Filter.Payment.20': '20 giorni',
    'Rent.Filter.Payment.25': '25 giorni',
    'Rent.Filter.Payment.EndOfMonth': "L'ultimo giorno del mese",
    'Rent.Code': 'N. contratto',
    'Rent.Date': 'Data di noleggio',
    'Rent.Status.INUSE': 'Attualmente noleggiata',
    'Rent.Status.RETURNED': 'Fine del noleggio',
    'Rent.Status.BE_CANCELED': 'Cancellazione',
    'Rent.Status.LOW_POWER': 'Bassa tensione',
    'Rent.Status.NO_RETURNED': 'Non restituito',
    'Rent.IncludeMaintenance': 'Stato di manutenzione',
    'Rent.Extend': 'Estensione del noleggio',
    'Rent.Contract': 'Contratto',
    'Rent.status.Requested': 'Richiesta di prenotazione',
    'Rent.status.Canceled': 'Annullare prenotazione',
    'Rent.status.NoShow': 'Il veicolo non è stato acquisito',
    'Rent.status.Deleted': 'Cancellazione della prenotazione',
    'Rent.status.Denied': 'Rifiuto della prenotazione',
    'Rent.status.CommError': 'Errore di comunicazione',
    'Rent.status.Estimate': 'Quotazione completata',
    'Rent.status.EstimateCanceled': 'Annullare il preventivo',
    'Rent.status.ChangeToInsurance':
        "Modifica dell'assicurazione di noleggio dell'auto",
    'Rent.status.ChangeToShort': 'Modifica a noleggio a breve termine',
    'Rent.status.Insurance.Billing': 'Polizza assicurativa',
    'Rent.status.Billing': 'Contratto',
    'Rent.modal.Return.message': "Restituire l'auto?",
    'Rent.modal.Return.Date': 'Ora e data di restituzione',
    'Rent.modal.Return.Worker': 'Lavoratore',
    'Rent.modal.Return.WorkDate': 'Giorno di lavoro',
    'Rent.modal.Return.Fail': 'Impossibile elaborare la restituzione.',
    'Rent.modal.Extend.message': 'Estendere il periodo di noleggio?',
    'Rent.modal.Extend.DueDate': 'Orario di fine precedente',
    'Rent.modal.Extend.Date': 'Data di fine della proroga',
    'Rent.modal.Extend.Fail': 'Proroga del periodo non riuscita.',
    'Rent.modal.Force.message':
        'Effettuare la restituzione forzata del veicolo?',
    'Rent.modal.Force.Date': 'Ora e data della restituzione forzata',
    'Rent.modal.Force.Fail': 'Impossibile elaborare la restituzione forzata.',
    'VehicleBasic.VIN.Error':
        'Il VIN esiste già o non è valido. Controllare di nuovo le informazioni.',
    'LongRental.Charge.DirectDebit': 'Addebito diretto',
    'LongRental.Charge.VirtualAccount': 'Account virtuale',
    'LongRental.Charge.Card': 'Pagamento con carta',
    'LongRental.Charge.Billing': 'Fattura',
    'LongRental.Charge.Home': 'Casa',
    'LongRental.Charge.Company': 'Azienda',
    'LongRental.UserNameInfo': 'Informazioni sul cliente',
    'LongRental.VehicleNumber': 'Veicolo',
    'Common.percent': '%',
    'Common.or': 'o',
    'LongRental.Payment.Month.Placeholder': 'Selezionare una data di pagamento',
    'LongRental.Title.Estimate.Create': "Registrare un'auto stimata",
    'LongRental.Estimate.VehicleName.Placeholder':
        'Inserire il nome del veicolo.',
    'LongRental.Purchase.Cost.Placeholder': 'Inserire il costo di acquisto.',
    'LongRental.RentalDeposit.Cost.Placeholder':
        'Inserire il deposito del noleggio.',
    'LongRental.MonthlyCharge.Cost.Placeholder': 'Inserire il canone mensile',
    'LongRental.Period.Placeholder': 'Inserire un periodo',
    'LongRental.Options.Placeholder': 'Inserire opzioni',
    'LongRental.PenaltyAmount.Placeholder': 'Inserire la penale',
    'LongRental.Note.Placeholder': 'Inserire una nota',
    'Title.Charge.Peak.Fare': 'Tasso di picco',
    'Title.Charge.Discount': 'Sconto sul noleggio (%)',
    'Vehicle.Tab.Log': 'Registro di guida',
    'Common.Fail.User': 'Prenotazione duplicata esistente.',
    'Common.Btn.ContractCancle': 'Cancellazione',
    'Rent.modal.ContractCancle.message': 'Cancellare il contratto di noleggio?',
    'LongRental.ContractCancle.StartDate': 'Data di inizio noleggio',
    'LongRental.ContractCancle.EndDate':
        'Orario e data stimati di restituzione',
    'LongRental.ContractCancle.ContractCancle':
        'Data e orario di cancellazione',
    'LongRental.ContractCancle.ReturnPay.Title':
        'Rimborso del canone di noleggio',
    'LongRental.ContractCancle.Refund': 'Rimborso dei canoni di noleggio',
    'LongRental.ContractCancle.Total': 'Totale',
    'LongRental.ContractCancle.PenaltyAmount': 'Penale',
    'LongRental.Charge.Cash': 'Contanti',
    'LongRental.Estimate.message':
        'Inserire le informazioni su auto e noleggio per un preventivo.',
    'Common.BeContractCancle.Fail': 'La cancellazione non è consentita.',
    'Common.Btn.Report': 'Stima',
    'Code.Create.ErrorCode.910004':
        'Il codice della chiave di gruppo è duplicato.',
    'Code.Update.ErrorCode.910004':
        'Il codice della chiave di gruppo è duplicato.',
    'Code.Delete.ErrorCode.910004':
        'Impossibile eliminare a causa di un sottocodice esistente',
    'VehicleInsurance.branchName': 'Nome della filiale',
    'VehicleInsurance.insureYear': 'N. di anni',
    'VehicleInsurance.paymentDueDate': 'Scadenza',
    'VehicleInsurance.totalInsurance': 'Premio assicurativo totale',
    'VehicleInsurance.paymentStatus': 'Stato del pagamento',
    'VehicleInsurance.ManagerName': "Gestore dell'assicurazione",
    'VehicleInsurance.ManagerMobileNumber':
        "N. di telefono del direttore dell'assicurazione",
    'VehicleInsurance.FaxNumber': 'FAX',
    'LongRental.values':
        'Totale = deposito di noleggio - importo della penale + costo di indennizzo – costi aggiuntivi + rimborso',
    'Vehicle.Tab.Insurance': 'Informazioni assicurative',
    'VehicleInsurance.yearSelect': 'Selezionare il n. di anni',
    'VehicleInsurance.1year': '1° anno',
    'VehicleInsurance.2year': '2° anno',
    'VehicleInsurance.3year': '3° anno',
    'Menu.Business': "Gestione dell'operatore",
    'VehicleInsurance.12Month': '12 mesi',
    'VehicleInsurance.24Month': '24 mesi',
    'VehicleInsurance.36Month': '36 mesi',
    'VehicleInsurance.2Month': '2 mesi',
    'VehicleInsurance.3Month': '3 mesi',
    'VehicleInsurance.4Month': '4 mesi',
    'VehicleInsurance.5Month': '5 mesi',
    'VehicleInsurance.6Month': '6 mesi',
    'VehicleInsurance.7Month': '7 mesi',
    'VehicleInsurance.8Month': '8 mesi',
    'VehicleInsurance.9Month': '9 mesi',
    'VehicleInsurance.10Month': '10 mesi',
    'VehicleInsurance.11Month': '11 mesi',
    ERROR_CODE_SUCCESS: 'Completato con successo.',
    ERROR_CODE_NO_CONTENT: 'Nessun dato disponibile',
    ERROR_CODE_CREATED: 'Creazione completata.',
    ERROR_CODE_NOT_CREATED: 'Impossibile inserire i dati.',
    ERROR_CODE_NOT_UPDATED: 'Impossibile modificare i dati.',
    ERROR_CODE_NOT_DELETED: 'Impossibile eliminare i dati.',
    ERROR_CODE_BAD_REQUEST: 'Fattore di input non valido',
    ERROR_CODE_FORBIDDEN_ROLE: 'Autorizzazione amministratore non disponibile.',
    ERROR_CODE_FORBIDDEN_ACL: 'Autorizzazione utente non disponibile.',
    ERROR_CODE_INTERNAL_SERVER_ERROR: 'Si è verificato un errore interno.',
    ERROR_CODE_NEED_LOGON: 'Login richiesto.',
    ERROR_CODE_VEHICLE_NOT_DELETE_RESERVATION:
        'Impossibile cancellare il veicolo a causa delle informazioni esistenti sulla prenotazione.',
    ERROR_CODE_VEHICLE_NOT_DELETE_ACCIDENT:
        "Impossibile eliminare l'auto a causa della storia di incidente esistente.",
    ERROR_CODE_VEHICLE_VIN_DUPLICATION: 'Impossibile utilizzare il codice VIN.',
    ERROR_CODE_VEHICLE_CONTROL_ID_DUPLICATION:
        "Impossibile utilizzare l'ID di controllo del veicolo.",
    ERROR_CODE_USER_NOT_AUTHORIZATION: 'Autorizzazione utente non riuscita.',
    ERROR_CODE_BUSINESS_NOT_DELETE_CAR:
        'Impossibile cancellare in quanto esiste un veicolo correlato.',
    ERROR_CODE_PRIVATE_UNAUTH_BUSINESS:
        "L'utente attivato non può modificare la propria società.",
    ERROR_CODE_CHARGE_NOT_DELETE_RESERVATION:
        'Si è verificato un errore dovuto al premio riservato.',
    ERROR_CODE_VEHICLEMODEL_NOT_DELETE:
        'Impossibile cancellare in quanto esiste un veicolo correlato.',
    ERROR_CODE_RESERVATION_ALEADY_VEHICLE: 'Il veicolo è già prenotato.',
    ERROR_CODE_RESERVATION_CONFLICT: 'Conflitto nella prenotazione utente',
    ERROR_CODE_RESERVATION_UNAUTH_USER:
        'La prenotazione è disabilitata per utenti non autorizzati.',
    ERROR_CODE_RESERVATION_FIRST_USER_BUSINESS: 'Registrare prima una società.',
    ERROR_CODE_RESERVATION_NOT_INSUE:
        "Lo stato della prenotazione è 'non in uso'.",
    ERROR_CODE_RESERVATION_RETURN_NOT_LOCATION:
        'La posizione corrente del veicolo non corrisponde alla posizione di restituzione.',
    ERROR_CODE_RESERVATION_BAD_REQUEST:
        'Impossibile interpretare la richiesta.',
    ERROR_CODE_RESERVATION_VEHICLE_ERROR:
        'Impossibile trovare le informazioni sul veicolo.',
    ERROR_CODE_RESERVATION_TIME_ERROR: 'Orari di inizio e fine insoliti',
    ERROR_CODE_RESERVATION_RENTAL_COMPANY:
        'Impossibile registrare o modificare la compagnia di noleggio.',
    ERROR_CODE_RESERVATION_DRIVER_LICENSE:
        'Impossibile registrare o aggiornare la patente di guida.',
    ERROR_CODE_RESERVATION_ADDITIONAL_DRIVER_LICENSE:
        'Informazioni aggiuntive sul guidatore non valide.',
    ERROR_CODE_RESERVATION_NO_CONTENT: 'Nessun dato disponibile',
    ERROR_CODE_RESERVATION_GRPC_ERROR: "C'è un errore nel modulo GRPC.",
    ERROR_CODE_RESERVATION_STATUS_ERROR: 'Errore di stato',
    ERROR_CODE_RESERVATION_KOROAD_ERROR:
        'Non è riuscito a verificare la patente.',
    ERROR_CODE_RESERVATION_INUSE:
        "È possibile impostare una prenotazione 15 minuti prima dell'ora di inizio.",
    ERROR_CODE_RESERVATION_OVERLAP:
        'Presenza di sovrapposizione di prenotazione.',
    ERROR_CODE_CHARGE: 'Informazioni sul pagamento non disponibili.',
    ERROR_CODE_TESTCAR_CONFIG_NOT_EXIST:
        "Le informazioni di configurazione per l'auto di prova non sono disponibili.",
    ERROR_CODE_TESTCAR_RESERVATION_PERIOD_EXCESS:
        "Il periodo di prenotazione per un'auto di prova è stato superato.",
    ERROR_CODE_TESTCAR_STARTTIME_WEEKEND:
        'I noleggi non sono disponibili durante i fine settimana.',
    ERROR_CODE_TESTCAR_ENDTIME_WEEKEND:
        'I noleggi non possono essere restituiti durante i fine settimana.',
    ERROR_CODE_TESTCAR_STARTTIME_HOLIDAY:
        'Non è possibile noleggia in un giorno festivo.',
    ERROR_CODE_TESTCAR_ENDTIME_HOLIDAY:
        'Non è possibile restituire in un giorno festivo.',
    ERROR_CODE_TESTCAR_COUNT_EXCESS:
        'Il limite di prenotazione per le auto di prova è stato superato.',
    ERROR_CODE_MONITORING_NOT_FIND_VIN:
        'La posizione corrente del veicolo non corrisponde alla posizione di restituzione.',
    ERROR_CODE_CONTROL_FAIL: 'Impossibile controllare a distanza il veicolo.',
    ERROR_CODE_CONTROL_VIN_DUPLICATION: 'Il codice VIN non è disponibile.',
    ERROR_CODE_CONTROL_NADID_DUPLICATION:
        'Le informazioni NADID non sono disponibili.',
    ERROR_CODE_RESOURCE_NOT_FOUND: 'Impossibile trovare la risorsa.',
    ERROR_CODE_RESOURCE_KEY_DUPLICATION:
        'Impossibile utilizzare la chiave di risorsa.',
    ERROR_CODE_RESOURCE_KEY_EMPTY: 'La chiave di risorsa è vuota.',
    ERROR_CODE_COMPANY_BUSINESS_REG_NUM_EXIST:
        "Il n. di registrazione dell'operatore esiste già.",
    ERROR_CODE_EXIST_BRANCH_USER: 'Esiste un utente di filiale.',
    'Vehicle.BasicInfo.ModelYear.Placeholder':
        "Inserire l'anno del modello del veicolo",
    'Vehicle.BasicInfo.ModelYear': 'Anno del modello',
    'Vehicle.BasicInfo.ExtendCnt':
        "Conteggio di estensione dell'età del veicolo",
    'Vehicle.BasicInfo.RegularInspectionDate': 'Ispezione regolare',
    'Vehicle.ExtendCnt.0': '0 volte',
    'Vehicle.ExtendCnt.1': 'Una volta',
    'Vehicle.ExtendCnt.2': 'Due volte',
    'Branch.Manager.Title': 'Direttore di filiale',
    ERROR_CODE_ALREADY_RESERVED: 'La data è già stata prenotata.',
    'LongRental.Charge.Bank_CARD': 'Società di emissione della carta',
    'LongRental.Charge.CountNumber_CARD': 'N. carta',
    'LongRental.Charge.CountOwner_CARD': 'Titolare della carta',
    'VehicleInsurance.Payment.1': '1°',
    'VehicleInsurance.Payment.2': '2°',
    'VehicleInsurance.Payment.3': '3°',
    'VehicleInsurance.Payment.4': '4°',
    'VehicleInsurance.Payment.5': '5°',
    'VehicleInsurance.Payment.6': '6°',
    'VehicleInsurance.Payment.7': '7°',
    'VehicleInsurance.Payment.8': '8°',
    'VehicleInsurance.Payment.9': '9°',
    'VehicleInsurance.Payment.10': '10°',
    'VehicleInsurance.Payment.11': '11°',
    'VehicleInsurance.Payment.12': '12°',
    'Vehicle.Model.Info': 'Informazioni sul modello del veicolo',
    'VehicleInsurance.Select.Paid': 'Pagato',
    'VehicleInsurance.Day.type': 'Selezionare una data',
    'VehicleInsurance.Day.Duration': 'Durata del pagamento',
    'VehicleInsurance.Modal.Year': 'N. di anni/sequenza',
    'Common.Buuton.Close': 'Chiudere',
    'Vehicle.Used': 'Stato di noleggio disponibile',
    'Vehicle.Used.Yes': 'Noleggio disponibile',
    'Vehicle.Used.No': 'Noleggio interrotto',
    'Common.Btn.Terminal': 'Trasmissione terminale',
    'Reservation.Request.Terminal.Fail':
        'Impossibile inviare le informazioni di prenotazione al terminale.',
    'Reservation.Request.Terminal.Success':
        'È stata effettuata una richiesta per inviare le informazioni sulla prenotazione al tuo terminale.',
    'Reservation.Request.Terminal.Confirm':
        'Inviare le informazioni sulla prenotazione al terminale?',
    'TestCarConfig.Reservation.StartTime':
        'Impostare data/orario di inizio prenotazione',
    'Common.Input.Required.Hour': 'Immettere un valore compreso tra 0 e 23.',
    'Common.Input.Required.Day': 'Immettere un valore compreso tra 1 e 31.',
    'Location.Radius.50m': '50 m',
    'Location.Radius.100m': '100 m',
    'Location.Radius.200m': '200 m',
    'Location.Radius.300m': '300 m',
    'Menu.Reservation.TestCar': 'Gestione del noleggio di auto di prova',
    'Charge.Minute.Zero': '0 m',
    'Charge.Minute.Ten': '10 minuti',
    'Charge.Minute.Twenty': '20 minuti',
    'Charge.Minute.Thirty': '30 minuti',
    'Charge.Minute.Forty': '40 minuti',
    'Charge.Minute.Fifty': '50 minuti',
    'LongRental.Payment.Day.One': '1 giorno',
    'LongRental.Payment.Day.Five': '5 giorni',
    'LongRental.Payment.Day.Ten': '10 giorni',
    'LongRental.Payment.Day.Fifteen': '15 giorni',
    'LongRental.Payment.Day.Twenty': '20 giorni',
    'LongRental.Payment.Day.TwentyFifth': '25 giorni',
    'LongRental.Payment.Day.Last': "L'ultimo giorno del mese",
    'LongRental.RentalInfo.Year.Two': '24 mesi',
    'LongRental.RentalInfo.Year.Three': '36 mesi',
    'LongRental.RentalInfo.Year.Four': '48 mesi',
    'LongRental.RentalInfo.Year.Five': '60 mesi',
    'LongRental.Charge.Bank_CARD.Placeholder':
        'Selezionare la società di emissione della carta.',
    'LongRental.Charge.CountNumber_CARD.Placeholder':
        'Inserire il n. della carta',
    'LongRental.Charge.CountOwner_CARD.Placeholder':
        'Inserire il proprietario della carta.',
    ERROR_CODE_LICENSE_USE_BUSINESS_EXIST:
        "Cancellazione non possibile, perché un'auto aziendale/a noleggio utilizza il piano tariffario.",
    ERROR_CODE_MENU_CHILDREN_EXIST:
        'Impossibile eliminare a causa di un sottomenu esistente',
    ERROR_CODE_ROLE_CHILDREN_EXIST:
        'Impossibile eliminare a causa dei dati della subautorità esistenti',
    'TestCar.Reservation.View': 'Visualizzare',
    'Common.Input.Required.Percent':
        'Immettere un valore compreso tra 1 e 100.',
    'Terminal.message':
        '*Potrebbe non corrispondere alle informazioni sul terminale fisico recuperate.*',
    'Reservation.Request.BeContractCancle.Success':
        'Il noleggio a lungo termine di una nuova auto è annullato.',
    'VehicleInsurance.4year': '4° anno',
    'VehicleInsurance.5year': '5° anno',
    'VehicleInsurance.6year': '6° anno',
    'VehicleInsurance.7year': '7° anno',
    'VehicleInsurance.8year': '8° anno',
    'VehicleInsurance.9year': '9° anno',
    'VehicleInsurance.10year': '10° anno',
    'Reservation.Error.SelectPeriod':
        "Selezionare l'ora e la data di noleggio stimate.",
    'Reservation.Error.StartLocation': 'Selezionare un luogo di consegna.',
    'Common.Error.message': 'Azione disabilitata.',
    'Title.PaymentManager': 'Stato vendite/regolamento',
    'Menu.Payment.Violation.Mgnt': 'Gestione sanzioni/costo',
    ERROR_CODE_NOT_FOUND_RESERVATION_DATA:
        'Non esiste una prenotazione relativa alla violazione.',
    ERROR_CODE_FORCERETURN_CAN_BE_MADE_AFTER_THE_START_TIME:
        "La restituzione forzata può essere condotta dopo l'inizio del noleggio.",
    'Password.Infomation1':
        '- È necessario inserire la password prima di visualizzare il contratto di noleggio per proteggere le informazioni personali.',
    'Password.Infomation2':
        '- La password è la propria data di nascita a sei cifre o le prime sei cifre del n. di registrazione aziendale. Inserire la password e fare clic sul pulsante OK.',
    'Password.CompanyInfo1': 'Operatore',
    'Password.CompanyInfo2': 'COPYRIGHT ⓒ MOCEAN.TUTTI I DIRITTI RISERVATI.',
    'Vehicle.Registration.Card': 'Carta di immatricolazione del veicolo',
    'Vehicle.Registration.Card.Btn.Create':
        'Carta di immatricolazione del veicolo del registro',
    'Vehicle.Registration.Card.Create.Help':
        'Selezionare una carta di registrazione del veicolo (jpeg, JPG, png).',
    'Vehicle.Registration.Card.Create.ExtName.Help':
        'Possono essere utilizzate solo estensioni jpeg, jpg e png.',
    'Vehicle.Registration.Card.Btn.Upload':
        'Caricare la carta di immatricolazione del veicolo',
    'Vehicle.Registration.Card.Upload.Fail':
        'Impossibile analizzare la carta di immatricolazione del veicolo.',
    'Common.Buuton.Kakaotalk': 'KakaoTalk',
    'Common.fail.changeMonth': 'Selezionare un periodo di noleggio',
    'Send.Kakaotalk.Fail': 'Impossibile inviare la notifica KakaoTalk.',
    'Send.Kakaotalk.Success':
        'La notifica KakaoTalk è stata inviata correttamente.',
    'Send.Kakaotalk.Password.Fail': 'Password errata inserita',
    'Send.Kakaotalk.Password.Success': 'Password inserita correttamente.',
    ERROR_CODE_TODAY_IS_NOT_SAME_DATE:
        'Il noleggio può essere registrato solo con la data odierna.',
    'Common.Error.Validation': 'Compilare tutti i campi richiesti.',
    ERROR_CODE_NOT_MATCH_PASSWORD: 'La password non corrisponde.',
    'Vehicle.BasicInfo.Insurance.Limit': 'Limite assicurativo',

    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */
    'Vehicle.BasicInfo.Equip': '장착',
    'Vehicle.BasicInfo.Not.Equip': '미장착',
    'Vehicle.Title.RealTimeInfo': '실시간차량정보',
    'Vehicle.Title.AdditionalInfo': '부가정보',
    'Vehicle.AdditionalInfo.iviID': 'IVI ID',
    'Vehicle.AdditionalInfo.iviID.Call': 'IVI ID 불러오기',
    'Vehicle.AdditionalInfo.asPeriod': 'A/S 기간',
    'Vehicle.AdditionalInfo.taxiDrivingGroup': '택시운행조',
    'Vehicle.AdditionalInfo.VIN.FieldNull': '차대번호를 입력해주세요.',
    'Vehicle.AdditionalInfo.iviID.Fail': 'IVI ID 조회 실패',
    'Vehicle.AdditionalInfo.iviID.Empty':
        '차대번호에 해당되는 IVI ID가 존재하지 않습니다.',

    'Vehicle.Tab.Sales': '차량별매출',
    'Vehicle.Sales.HistoryTime': '발생일시',
    'Vehicle.Sales.Business.Time': '영업시간(거리)',

    'Menu.Vehicle.OperationStatistics': '차량운행일지',
    'Vehicle.OperationStatistics.Btn.Download': '엑셀다운로드',
    'Terms.Search.Period': '조회기간',
    'Vehicle.OperationStatistics.Vehicle.Number': '차량번호',
    'Vehicle.OperationStatistics.TaxiDriver.Name': '기사명',
    'Vehicle.OperationStatistics.Date': '날짜',
    'Vehicle.OperationStatistics.Login.Time': '출근시간',
    'Vehicle.OperationStatistics.Logout.Time': '퇴근시간',
    'Vehicle.OperationStatistics.Driving.Time': '주행시간(거리)',
    'Vehicle.OperationStatistics.Business.Time': '영업시간(거리)',
    'Vehicle.OperationStatistics.Empty.Time': '공차시간(거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount': '총영업금액(호출/배회)',

    'Vehicle.OperationStatistics.Title.Info': '차량운행 상세 정보',
    'Vehicle.OperationStatistics.Work.Time': '출퇴근시간',
    'Vehicle.OperationStatistics.TotalDriving.Time': '총주행시간(거리)',
    'Vehicle.OperationStatistics.TotalBusiness.Time': '총영업시간(거리)',
    'Vehicle.OperationStatistics.TotalEmpty.Time': '총공차시간(거리)',
    'Vehicle.OperationStatistics.Business.Rate': '영업률(영업거리/총주행거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount2': '총매출금액',
    'Vehicle.OperationStatistics.Business.Count': '영업건수/객단가',
    'Vehicle.OperationStatistics.CallType.Count': '호출/배회건수',
    'Vehicle.OperationStatistics.CallType.SalesAmount': '호출/배회 매출금액',

    'Vehicle.OperationStatistics.Departure.Time': '승차시간',
    'Vehicle.OperationStatistics.Arrive.Time': '하차시간',
    'Vehicle.OperationStatistics.Departure.PlaceName': '승차위치',
    'Vehicle.OperationStatistics.Arrive.PlaceName': '하차위치',
    'Vehicle.OperationStatistics.Call.YN': '호출여부',
    'Vehicle.OperationStatistics.Call.App': '호출APP',
    'Vehicle.OperationStatistics.Payment.Method': '결제방식',
    'Vehicle.OperationStatistics.SalesAmount': '매출금액',
    'Vehicle.OperationStatistics.Create.Time': '운행정보입력시간',
    'Vehicle.OperationStatistics.PaymentType.Cash': '현금',
    'Vehicle.OperationStatistics.PaymentType.Card': '카드',
    'Vehicle.OperationStatistics.AppType.Macaron': '마카롱배차앱',
    'Vehicle.OperationStatistics.AppType.TMoney': '티머니미터기',
    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */

    // 차량만령관리
    'Vehicle.ExpiryHeader.Title': '차량만령관리',
    'Vehicle.ExpirySearch.Title': '차령만료일 기간',
    'Vehicle.ExpirySearch.all': '전체',
    'Vehicle.ExpirySearch.prevday': '1일전',
    'Vehicle.ExpirySearch.prevweek': '1주일전',
    'Vehicle.ExpirySearch.prevtwoweek': '2주일전',
    'Vehicle.ExpirySearch.prevmonth': '1달전',
    'Vehicle.ExpirySearch.prevyear': '1년 2주전',
    'Vehicle.ExpirySearch.today': '당일',
    'Vehicle.ExpiryHeader.vehicleAge': '차량연령',

    // 택시통합연동로그
    'Menu.ReceiveLog': '택시통합연동로그',
    'Title.ReceiveLog': '택시통합연동로그',
    'ReceiveLog.Btn.Download': '엑셀다운로드',
    'ReceiveLog.Filter.RegDate': '등록일',
    'ReceiveLog.Filter.InterfaceId': 'InterfaceID',
    'ReceiveLog.Filter.DriverName': '기사명',
    'ReceiveLog.Filter.Pincode': 'PINCODE',
    'ReceiveLog.Filter.ResultCodeKind': '응답코드종류',
    'ReceiveLog.Filter.ResultCode': '응답코드',
    'ReceiveLog.ResultCodeKind.All': '전체',
    'ReceiveLog.ResultCodeKind.Success': '성공',
    'ReceiveLog.ResultCodeKind.Failure': '실패',
    // 'ReceiveLog.Item.ID' : 'ID',
    'ReceiveLog.Item.CreatedDateTime': '생성일시',
    'ReceiveLog.Item.YdServiceName': '서비스명',
    'ReceiveLog.Item.InterfaceId': 'InterfaceID',
    'ReceiveLog.Item.InterfaceName': 'Interface명',
    'ReceiveLog.Item.AccessDateTime': '요청일시',
    // 'ReceiveLog.Item.SessionId' : '세션ID',
    'ReceiveLog.Item.UrlPath': 'API URL',
    // 'ReceiveLog.Item.HttpMethod' : '메소드명',
    // 'ReceiveLog.Item.ClientIp' : 'Client IP',
    'ReceiveLog.Item.TaxiDriverName': '기사명',
    'ReceiveLog.Item.Pincode': 'PINCODE',
    // 'ReceiveLog.Item.Vin' : 'VIN No.',
    'ReceiveLog.Item.Param': 'Parameter',
    'ReceiveLog.Item.ResultCode': '응답코드',
    'ReceiveLog.Item.ResultMsg': '응답메시지',
    'ReceiveLog.Item.InnerResultMsg': '응답메시지상세',
    'ReceiveLog.Item.ResultValue': '응답값',
    // 차량기록조회
    'Vehicle.Records.Btn.Download': '엑셀다운로드',
    'Terms.Search.Time': '조회시간*',
    'Vehicle.Records.Vehicle.Number': '차대번호*',
    'Menu.Vehicle.Records': '차량실시간정보',
    'Vehicle.Records.Minute.Placeholder': '(분)',
    'Vehicle.Records.Hour.Placeholder': '(시)',
    'Vehicle.Records.Comment':
        '* 선택한 시간부터 10분간의 데이터를 조회합니다.',
};

export default language;
