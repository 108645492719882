import { Suspense, useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import _ from 'lodash';

import { useAppRoot, useTransaction } from 'component/mux';

import { useAuthentication } from 'util/authentication';
import { BusinessSingleSelectDialog } from 'component/dialogComponent/BusinessSingleSelectDialog';
import { TRANSID_AUTHENTICATION } from 'config/transaction';

import { AuthedApp } from './AuthedApp';
import { UnAuthedApp } from './UnAuthedApp';
import LoginFirstAgree from 'component/dialogComponent/TermsDialog/adminAgree/LoginFirstAgree';
import LoginStatusAgree from 'component/dialogComponent/TermsDialog/adminAgree/LoginStatusAgree';
import { UnAuthedSimpleApp } from './UnAuthedSimpleApp';
import NoticeDays from 'component/dialogComponent/NoticeDialog/NoticeDays';
import { useHistory } from 'react-router-dom';

const GateKeeper = () => {
    // 기본 State 선언
    const { fetchSession, afterAuth, getAuthInfo, setAgreeComplete } =
        useAuthentication();
    const { customTransaction } = useTransaction(TRANSID_AUTHENTICATION);
    const [IsAuthed, setAuthed] = useState(false);
    const [randerHandle, setRanderHandle] = useState(false);
    const { session } = useAppRoot();
    const history = useHistory();

    // 로그인 후 동의 처리 관련 State
    const [isStatusAgree, setIsStatusAgree] = useState(false);
    const [isFirstAgree, setIsFirstAgree] = useState(false);

    // 로그인 감지
    useEffect(() => {
        if (_.isNull(session) || _.isUndefined(session) || _.isEmpty(session)) {
            fetchSession()
                .then((data) => {
                    // 인증 상태인경우 afeter처리
                    afterAuth({
                        authInfo: data,
                        onIsStatusAgree,
                        onIsFirstAgree,
                    });
                    setAuthed(true);
                })
                .catch((error) => {
                    // 미인증 상태일경우 아무 처리 하지 않음
                    setAuthed(false);
                });
        } else {
            afterAuth({ authInfo: session, onIsStatusAgree, onIsFirstAgree });
            setAuthed(true);
        }
    }, [randerHandle]);

    // 로그아웃 감지
    useEffect(() => {
        if (_.isNull(session) || _.isUndefined(session) || _.isEmpty(session)) {
            setAuthed(false);
        }
    }, [session]);

    const onRanderHandle = () => {
        setRanderHandle((prev) => {
            return !prev;
        });
    };

    const onFirstAgree = () => {
        setIsFirstAgree(false);
    };

    const onIsStatusAgree = (result) => {
        setIsStatusAgree(result);
    };

    const onIsFirstAgree = (result) => {
        setIsFirstAgree(result);
    };

    const onStatusAgree = (termsAndConditions) => {
        const redirectAuthObj = getAuthInfo();
        const { activationStatus } = redirectAuthObj;
        const callTransId = activationStatus === 'GUEST' ? 'join' : 'agree';
        customTransaction(callTransId, { termsAndConditions })
            .then(() => {
                fetchSession()
                    .then((data) => {
                        setAgreeComplete('Y');
                        onRanderHandle();
                    })
                    .catch((err) => {
                        const { message } = err?.data || {};
                        let errCode = message || '515102';
                        onNoAgree(errCode);
                    });
            })
            .catch((err) => {
                const { message } = err?.data || {};
                let errCode = message || '515102';
                onNoAgree(errCode);
            });
    };

    const onNoAgree = (errCode) => {
        const messageId = errCode || 'Message.Auth.Logout.Success';
        history.push(`/signOut?messageID=${messageId}`);
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <If condition={IsAuthed}>
                <Then>
                    <If condition={isFirstAgree}>
                        <Then>
                            {/* 화면 진입시 항상 보여줄 동의 */}
                            <LoginFirstAgree
                                visible={isFirstAgree}
                                onAgree={onFirstAgree}
                                onNoAgree={onNoAgree}
                            />
                            <UnAuthedSimpleApp />
                        </Then>
                    </If>
                    <If condition={!isFirstAgree && isStatusAgree}>
                        <Then>
                            {/* 회원가입 후 이용동의 */}
                            <LoginStatusAgree
                                visible={isStatusAgree}
                                onAgree={onStatusAgree}
                                onNoAgree={onNoAgree}
                            />
                            <UnAuthedSimpleApp />
                        </Then>
                    </If>
                    <If condition={!isFirstAgree && !isStatusAgree}>
                        <Then>
                            <AuthedApp onRanderHandle={onRanderHandle} />
                            <BusinessSingleSelectDialog />
                            <NoticeDays />
                        </Then>
                    </If>
                </Then>
                <Else>
                    <UnAuthedApp onRanderHandle={onRanderHandle} />
                </Else>
            </If>
        </Suspense>
    );
};

export default GateKeeper;

export { GateKeeper };
