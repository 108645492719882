import React, { forwardRef, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import { useIntl } from 'react-intl';

// mux 업데이트 이후 mux로 교체 필요.
// import { TextOrDatePicker as MuxTextOrDatePicker } from 'mux';
import { MuxBaseTextOrDatePicker as MuxTextOrDatePicker } from './base/MuxBaseTextOrDatePicker';

const TextOrDatePicker = forwardRef((props, ref) => {
    const {
        value,
        disable,
        placeholder,
        placeholderId,
        required,
        validate,
        ...rest
    } = props;

    const intl = useIntl();

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [intl, required, validate]);

    const panelDate = useRef(moment(value));

    // 현재 위치에 해당하는 modal 을 popupContainer 로 지정 (다중 popup 문제로 추가)
    const getPopupContainer = (trigger) => {
        let curBody = trigger.parentNode;
        const modalBodys = document.getElementsByClassName('ant-modal-body');
        _.map(modalBodys, (element) => {
            if (element.hasChildNodes()) {
                _.map(element.childNodes, (element) => {
                    if (element.contains(trigger)) {
                        curBody = element;
                    }
                });
            }
        });
        return curBody;
    };

    return (
        <MuxTextOrDatePicker
            {...rest}
            {...rules}
            value={value}
            disabled={disable}
            placeholder={
                placeholderId
                    ? intl.formatMessage({ id: placeholderId })
                    : placeholder
            }
            onPanelChange={(value, mode) => {
                if (mode === 'date') {
                    panelDate.current = value;
                }
            }}
            dateRender={(current) => {
                const style = {};
                if (
                    current.month() === panelDate.current.month() &&
                    current.day() === 0
                ) {
                    style.color = '#e65a55';
                }

                return (
                    <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                    </div>
                );
            }}
            getPopupContainer={getPopupContainer}
            ref={ref}
        />
    );
});

TextOrDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    disable: PropTypes.bool,
    placeholder: PropTypes.string,
    placeholderId: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    format: PropTypes.string,
    naviShow: PropTypes.bool.isRequired,
    inputReadOnly: PropTypes.bool,
    showToday: PropTypes.bool,
    showNow: PropTypes.bool,
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    picker: PropTypes.oneOf(['date', 'week', 'month', 'quarter', 'year']),
    exportFormat: PropTypes.string, // longtime | moment | format
};

TextOrDatePicker.defaultProps = {
    format: 'YYYY-MM-DD',
    required: false,
    disable: false,
    inputReadOnly: true,
    showToday: false,
    showNow: false,
    editable: true,
    naviShow: false,
    picker: 'date',
    exportFormat: 'longtime',
};

export default TextOrDatePicker;
