import { getMsgByCode } from 'util/codeUtil';
import { convertBizNumber } from 'util/NumberUtil';

const columns = [
    {
        title: '회원사 명', // deprecated
        resId: 'Business.Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '사업자등록번호', // deprecated
        resId: 'Business.RegNum',
        dataIndex: 'businessRegNum',
        key: 'businessRegNum',
        render: (text, record, index) => {
            return convertBizNumber(text);
        },
    },
];

const makeColumnsForContractY = (licenseTypeCodes) => {
    return [
        {
            title: '회원사 명', // deprecated
            resId: 'Business.Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '사업자등록번호', // deprecated
            resId: 'Business.RegNum',
            dataIndex: 'businessRegNum',
            key: 'businessRegNum',
            render: (text, record, index) => {
                return convertBizNumber(text);
            },
        },
        {
            title: '가입상품', // deprecated
            resId: 'Business.licenseType',
            dataIndex: 'licenseTypeCd',
            key: 'licenseTypeCd',
            render: (text) => {
                return getMsgByCode({
                    codeList: licenseTypeCodes,
                    value: text,
                    defaultMessage: '-',
                });
            },
        },
    ];
};

export { columns, makeColumnsForContractY };
