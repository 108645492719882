import React, { useMemo, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import {
    Editor,
    EditorTools,
    EditorUtils,
    ProseMirror,
} from '@progress/kendo-react-editor';
import { trimWhitespace } from '@progress/kendo-editor-common';

import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { insertImagePlugin } from './plugins/insert-image';
import {
    placeholderPlugin,
    style as placeholderStyle,
} from './plugins/placeholder';
import { readonlyPlugin } from './plugins/readonly';

import { InsertImage, InsertLink, ViewHtml } from './tools';

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    ForeColor,
    BackColor,
    CleanFormatting,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    Unlink,
} = EditorTools;

const { EditorView, EditorState } = ProseMirror;

function onImageInsert(args) {
    const { files } = args;
    // const { files, view, event } = args;
    // const nodeType = view.state.schema.nodes.image;
    // const position =
    //     event.type === 'drop'
    //         ? view.posAtCoords({
    //               left: event.clientX,
    //               top: event.clientY,
    //           })
    //         : null;
    return files.length > 0;
}

const WebEditor = ({
    name,
    value,
    required,
    maxLength,
    pattern,
    validate,
    placeholder,
    editable,
    showTool,
    onContentChange,
}) => {
    const editor = React.createRef();

    const onMount = (event) => {
        // const state = event.viewProps.state;

        const { viewProps } = event;
        const { plugins, doc } = viewProps.state;

        const editorDocument = event.dom.ownerDocument;
        const styleElement =
            editorDocument && editorDocument.querySelector('style');

        if (styleElement) {
            styleElement.appendChild(
                editorDocument.createTextNode(placeholderStyle),
            );
        }

        return new EditorView(
            {
                mount: event.dom,
            },
            {
                ...event.viewProps,
                state: EditorState.create({
                    doc,
                    plugins: [
                        ...plugins,
                        insertImagePlugin(onImageInsert),
                        readonlyPlugin(editable),
                        placeholderPlugin(placeholder),
                    ],
                }),
            },
        );
    };

    const intl = useIntl();
    const {
        register,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        if (pattern) {
            chunkRules.pattern = pattern;
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [required, pattern, validate]);

    // form 등록
    register(name, { ...rules });

    // editor 입력
    const handleChange = useCallback(
        (e) => {
            const html = e?.html;
            if (maxLength < html.length) {
                setError(name, {
                    type: 'maxLength',
                    message: intl.formatMessage(
                        { id: 'Message.Validator.MaxLength' },
                        {
                            compareValue: maxLength,
                        },
                    ),
                });
            } else {
                setValue(name, html === '<p></p>' ? '' : html);
                clearErrors(name);

                if (_.isFunction(onContentChange)) {
                    onContentChange(html);
                }
            }
        },
        [maxLength, onContentChange],
    );

    //
    useEffect(() => {
        if (editor.current) {
            const html = trimWhitespace(value || '');
            const view = editor.current.view;
            if (view && value) {
                EditorUtils.setHtml(view, html);
            }
        }
    }, [value]);

    const baseClassName = classNames('wbWebEditor', {
        wbForm_error: !!errors[name],
    });

    return (
        <div className={baseClassName}>
            <Editor
                tools={
                    showTool && editable
                        ? [
                              [Bold, Italic, Underline, Strikethrough],
                              [Subscript, Superscript],
                              ForeColor,
                              BackColor,
                              [CleanFormatting],
                              [
                                  AlignLeft,
                                  AlignCenter,
                                  AlignRight,
                                  AlignJustify,
                              ],
                              [Indent, Outdent],
                              [OrderedList, UnorderedList],
                              FontSize,
                              [Undo, Redo],
                              [InsertLink, Unlink, InsertImage, ViewHtml],
                          ]
                        : []
                }
                defaultContent={value}
                contentStyle={{
                    fontFamily:
                        'HyundaiSansTextJP, HyundaiSansTextKR, sans-serif',
                    height: 430,
                    flex: 1,
                }}
                onMount={onMount}
                onChange={handleChange}
                ref={editor}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                    <div className="errorMessage">{message}</div>
                )}
            />
        </div>
    );
};

WebEditor.defaultProps = {
    showTool: true,
    required: false,
    maxLength: 104857600, // 100MB
};

export default WebEditor;
