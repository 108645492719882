/** eslint-disable */
import { buildMockup } from 'component/mux';

import profile from './mockups/profile.json';
import systemCode from './mockups/systemCode.json';
import business from './mockups/business.json';
import notice from './mockups/notice.json';
import role from './mockups/role.json';
import licenseCheck from './mocks/licenseCheck.json';
import program from './mocks/program.json';
import msgTemplate from './mocks/msgTemplate.json';
import appVersion from './mocks/appVersion.json';
import team from './mocks/team.json';
import passiveMessage from './mocks/passiveMessage.json';
import menu from './mocks/menu.json';
import menuProgram from './mocks/menuProgram.json';
import vehicle from './mocks/vehicle.json';
import vehicleCheck from './mocks/vehicleCheck.json';
import hipass from './mocks/hipassCard.json';
import chargecard from './mocks/chargeCard.json';
import locationModel from './mocks/location.json';
import controlHistory from './mocks/controlHistory.json';
import faqMgmt from './mocks/faqMgmt.json';
import jobs from './mocks/jobs.json';
import jobHistory from './mocks/jobHistory.json';
import trade from './mocks/trade.json';
import faqCategory from './mocks/faqCategory.json';
import request from './mocks/request.json';
import historyMsgTemplate from './mocks/historyMsgTemplate.json';
import operator from './mocks/operator.json';
import user from './mocks/user.json';
import receivable from './mocks/receivable.json';

var myBusiness = buildMockup(business.items);
var myRole = buildMockup(role);
var mockupLicenseCheck = buildMockup(licenseCheck.items);
var mockupRequest = buildMockup(request.items);
var mockupHistoryMsgTemplate = buildMockup(historyMsgTemplate.items);
var mockupOperator = buildMockup(operator.items);
var mockupUser = buildMockup(user.items);
var mockupReceivable = buildMockup(receivable.items);

const mockupData = {
    MOCKUPID_AUTHENTICATION: {
        signUp: {
            url: '/user/auth2',
            method: 'post',
            response: {
                status: 200,
                data: {
                    activationStatus: 'NORMAL',
                },
            },
        },
        fetch: {
            url: '/user/profile',
            method: 'get',
            response: {
                status: 200,
                data: profile,
            },
        },
    },
    MOCKUPID_SYSTEM_CODE: {
        fetch: {
            url: '/code',
            method: 'get',
            response: {
                status: 200,
                data: systemCode,
            },
        },
    },
    MOCKUPID_PROVIDER: {
        fetch: {
            url: '/businessList',
            method: 'get',
            response: (param) => {
                return myBusiness.fetch(param);
            },
        },
        create: {
            url: '/businessList/',
            method: 'post',
            response: (param) => {
                return myBusiness.create(param);
            },
        },
        get: {
            url: '/businessList/{id}',
            method: 'get',
            response: (param) => {
                return myBusiness.get(param);
            },
        },
        update: {
            url: '/businessList/{id}',
            method: 'patch',
            response: (param) => {
                return myBusiness.update(param);
            },
        },
        delete: {
            url: '/businessList/{id}',
            method: 'delete',
            response: (param) => {
                return myBusiness.delete(param);
            },
        },
    },
    MOCKUPID_NOTICE: {
        fetch: {
            url: '/notice',
            method: 'get',
            response: {
                status: 200,
                data: notice,
            },
        },
    },
    MOCKUPID_ROLE: {
        fetch: {
            url: '/api/gcsp/role',
            method: 'get',
            response: (param) => {
                return myRole.fetch();
            },
        },
    },
    MOCKUPID_LICENSE_CHECK: {
        fetch: {
            url: '/licenseCheck',
            method: 'get',
            response: (param) => {
                return mockupLicenseCheck.fetch(param);
            },
        },
        get: {
            url: '/licenseCheck/',
            method: 'get',
            response: (param) => {
                return mockupLicenseCheck.get(param);
            },
        },
        create: {
            url: '/licenseCheck',
            method: 'post',
            response: (param) => {
                return mockupLicenseCheck.create(param);
            },
        },
        delete: {
            url: `/licenseCheck`,
            method: 'delete',
            response: (param) => {
                return mockupLicenseCheck.delete(param);
            },
        },
    },
    MOCKUPID_PROGRAM: {
        fetch: {
            url: '/program',
            method: 'get',
            response: {
                status: 200,
                data: program,
            },
        },
    },
    MOCKUPID_MSG_TEMPLATE: {
        fetch: {
            url: '/msgTemplate',
            method: 'get',
            response: {
                status: 200,
                data: msgTemplate,
            },
        },
        get: {
            url: `\/msgTemplate\/*`,
            method: 'get',
            idKey: 'ID', // response > data 의 ID 필드명
            idIndex: 2, // url을 '/' 으로 split 하였을 떄 순서(/notice/49 일 경우, "": 0, notice: 1, 49: 2) --> 순서를 모를 경우 콘솔에 찍힘(>> paths (3) ["", "notice", "49"])
            response: {
                status: 200,
                data: msgTemplate,
            },
        },
    },
    MOCKUPID_PASSIVE_MESSAGE: {
        fetch: {
            url: '/passiveMessage',
            method: 'get',
            response: {
                status: 200,
                data: passiveMessage,
            },
        },
        get: {
            url: `\/passiveMessage\/*`,
            method: 'get',
            idKey: 'ID',
            idIndex: 2,
            response: {
                status: 200,
                data: passiveMessage,
            },
        },
    },
    MOCKUPID_MENU: {
        fetch: {
            url: '/menu',
            method: 'get',
            response: {
                status: 200,
                data: menu,
            },
        },
        get: {
            url: `\/menu\/*`,
            method: 'get',
            idKey: 'ID',
            idIndex: 2,
            response: {
                status: 200,
                data: menu,
            },
        },
    },
    MOCKUPID_MENU_PROGRAM: {
        fetch: {
            url: '/subMenuProgram',
            method: 'get',
            response: {
                status: 200,
                data: menuProgram,
            },
        },
        get: {
            url: `\/subMenuProgram\/*`,
            method: 'get',
            idKey: 'menuId',
            idIndex: 2,
            response: {
                status: 200,
                data: menuProgram,
            },
        },
    },
    MOCKUPID_APP_VERSION: {
        fetch: {
            url: 'appVersion',
            method: 'get',
            response: {
                status: 200,
                data: appVersion,
            },
        },
        get: {
            url: '/appVersion/',
            method: 'get',
            response: {
                status: 200,
                data: appVersion,
            },
        },
    },
    MOCKUPID_TEAM: {
        fetch: {
            url: '/bizAdm/team',
            method: 'get',
            response: {
                status: 200,
                data: team,
            },
        },
        get: {
            url: `/bizAdm/team/*`,
            method: 'get',
            idKey: 'teamCode', // response > data 의 ID 필드명
            idIndex: 2, // url을 '/' 으로 split 하였을 떄 순서(/notice/49 일 경우, "": 0, notice: 1, 49: 2) --> 순서를 모를 경우 콘솔에 찍힘(>> paths (3) ["", "notice", "49"])
            response: {
                status: 200,
                data: team, // 목록 mock-up data
            },
        },
    },
    MOCKUPID_VEHICLE: {
        fetch: {
            url: '/vehicle',
            method: 'get',
            response: {
                status: 200,
                data: vehicle,
            },
        },
    },
    MOCKUPID_VEHICLE_CHECK: {
        get: {
            url: '/vehicle/check/*',
            method: 'get',
            response: {
                status: 200,
                data: vehicleCheck,
            },
        },
    },
    MOCKUPID_HIPASS: {
        fetch: {
            url: '/hipass',
            method: 'get',
            response: {
                status: 200,
                data: hipass,
            },
        },
    },
    MOCKUPID_CHARGECARD: {
        fetch: {
            url: '/chargecard',
            method: 'get',
            response: {
                status: 200,
                data: chargecard,
            },
        },
    },
    // MOCKUPID_VEHICLE_MODEL: {
    //     fetch: {
    //         url: '/vehiclemodel',
    //         method: 'get',
    //         response: {
    //             status: 200,
    //             data: vehicleModel,
    //         },
    //     },
    //     get: {
    //         url: `\/vehiclemodel\/*`,
    //         method: 'get',
    //         idKey: 'ID', // response > data 의 ID 필드명
    //         idIndex: 2, // url을 '/' 으로 split 하였을 떄 순서(/notice/49 일 경우, "": 0, notice: 1, 49: 2) --> 순서를 모를 경우 콘솔에 찍힘(>> paths (3) ["", "notice", "49"])
    //         response: {
    //             status: 200,
    //             data: vehicleModel,
    //         },
    //     },
    // },
    MOCKUPID_LOCATION: {
        fetch: {
            url: '/location',
            method: 'get',
            response: {
                status: 200,
                data: locationModel,
            },
        },
    },
    MOCKUPID_CONTROL_HISTORY: {
        fetch: {
            url: '/controlHistory',
            method: 'get',
            response: {
                status: 200,
                data: controlHistory,
            },
        },
        get: {
            url: '/controlHistory/',
            method: 'get',
            response: {
                status: 200,
                data: controlHistory,
            },
        },
    },
    MOCKUPID_TERMINAL: {
        // fetch: {
        //     url: '/terminal',
        //     method: 'get',
        //     response: {
        //         status: 200,
        //         data: terminal,
        //     },
        // },
        // get: {
        //     url: '/terminal',
        //     method: 'get',
        //     response: {
        //         status: 200,
        //         data: terminal,
        //     },
        // },
    },
    MOCKUPID_FAQ_MGMT: {
        fetch: {
            url: '/faq',
            method: 'get',
            response: {
                status: 200,
                data: faqMgmt,
            },
        },
        get: {
            url: '/faq/',
            method: 'get',
            response: {
                status: 200,
                data: faqMgmt,
            },
        },
    },
    MOCKUPID_JOB: {
        fetch: {
            url: '/batch/scheduler/jobs',
            method: 'get',
            response: {
                status: 200,
                data: jobs,
            },
        },
        get: {
            url: `/batch/scheduler/jobs/*`,
            method: 'get',
            idKey: 'jobID',
            idIndex: 4,
            response: {
                status: 200,
                data: jobs,
            },
        },
    },
    MOCKUPID_JOB_HIST: {
        fetch: {
            url: '/batch/scheduler/jobhistory',
            method: 'get',
            response: {
                status: 200,
                data: jobHistory,
            },
        },
        get: {
            url: `/batch/scheduler/jobhistory/*`,
            method: 'get',
            idKey: 'histID',
            idIndex: 4,
            response: {
                status: 200,
                data: jobHistory,
            },
        },
    },
    MOCKUPID_TRADE: {
        fetch: {
            url: '/trade',
            method: 'get',
            response: {
                status: 200,
                data: trade,
            },
        },
        get: {
            url: `\/trade\/*`,
            method: 'get',
            idKey: 'ID',
            idIndex: 2,
            response: {
                status: 200,
                data: trade,
            },
        },
    },
    MOCKUPID_FAQ_CATEGORY: {
        fetch: {
            url: '/faq-category',
            method: 'get',
            response: {
                status: 200,
                data: faqCategory,
            },
        },
        get: {
            url: '/faq-category',
            method: 'get',
            response: {
                status: 200,
                data: faqCategory,
            },
        },
    },
    MOCKUPID_CUSTOMER_SERVICE: {
        fetch: {
            url: '/request',
            method: 'get',
            response: {
                status: 200,
                data: request,
            },
        },
        get: {
            url: '/request/{id}',
            method: 'get',
            response: (param) => {
                return mockupRequest.get(param);
            },
        },
        create: {
            url: '/request/',
            method: 'post',
            response: (param) => {
                return mockupRequest.create(param);
            },
        },
        delete: {
            url: '/request/{id}',
            method: 'delete',
            response: (param) => {
                return mockupRequest.delete(param);
            },
        },
    },
    MOCKUPID_CUSTOMER_HISTORY_SERVICE: {
        fetch: {
            url: `/customerService/56/history`,
            method: 'get',
            response: {},
        },
    },
    MOCKUPID_HISTORY_MSG_TEMPLATE: {
        fetch: {
            url: '/historyMsgTemplate',
            method: 'get',
            response: {
                status: 200,
                data: historyMsgTemplate,
            },
        },
        get: {
            url: '/historyMsgTemplate/{id}',
            method: 'get',
            response: (param) => {
                return mockupHistoryMsgTemplate.get(param);
            },
        },
    },
    MOCKUPID_OPERATOR: {
        fetch: {
            url: '/operator',
            method: 'get',
            response: {
                status: 200,
                data: operator,
            },
        },
        get: {
            url: '/operator/{id}',
            method: 'get',
            response: (param) => {
                return mockupOperator.get(param);
            },
        },
        update: {
            url: '/operator/{id}',
            method: 'patch',
            response: (param) => {
                return mockupOperator.update(param);
            },
        },
    },
    MOCKUPID_USER: {
        fetch: {
            url: '/user',
            method: 'get',
            response: {
                status: 200,
                data: user,
            },
        },
        get: {
            url: '/user/{id}',
            method: 'get',
            response: (param) => {
                return mockupUser.get(param);
            },
        },
    },
    TRANSID_RECEIVBLE: {
        fetch: {
            url: '/receivable',
            method: 'get',
            response: {
                status: 200,
                data: receivable,
            },
        },
        get: {
            url: '/receivable/{id}',
            method: 'get',
            response: (param) => {
                return mockupReceivable.get(param);
            },
        },
        create: {
            url: '/receivable/',
            method: 'post',
            response: (param) => {
                return mockupReceivable.create(param);
            },
        },
    },
};
export default mockupData;