import { useState } from 'react';
import { Link } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { FormattedMessage } from 'react-intl';

import { useAppRoot, Text, Form, TextOrSelect } from 'component/mux';
import NoticeGnb from 'component/dialogComponent/NoticeDialog/NoticeGnb';
import { INPUT_WIDTH_CLASS } from 'util/pageUtil';

const GlobalNavigationBar = () => {
    const { session, changeLocale, language, fetchCode } = useAppRoot();
    const [alramVisible, setAlramVisible] = useState(false);
    const [alramCnt, setAlramCnt] = useState(0);

    const fnVisible = () => {
        setAlramVisible((prev) => {
            return !prev;
        });
    };

    const quickNotiCnt = (cnt) => {
        setAlramCnt(cnt);
    };
    return (
        <>
            <div id="header">
                <div className="headerIn">
                    <div className="noticeName">
                        <div className="quickNotice">
                            <If
                                condition={
                                    session?.activationStatusCd === 'NORMAL' &&
                                    [
                                        'BUSINESS_MANAGER',
                                        'BUSINESS_OPERATOR',
                                    ].includes(session?.role)
                                }
                            >
                                <Then>
                                    <button
                                        type="button"
                                        className="quickNoticeBtn"
                                        onClick={fnVisible}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/image/common/icon_bell.png`}
                                            alt={
                                                <Text resId="Common.Bnb.Alram" />
                                            }
                                        />
                                        <span>{alramCnt}</span>
                                    </button>
                                </Then>
                            </If>
                        </div>
                        <div className="userName">
                            <FormattedMessage
                                id="Message.Logout.Hello"
                                values={{ user: <span>{session?.name}</span> }}
                            />
                        </div>
                    </div>
                    <div className="setupLogin">
                        {/* 승인상태가 아니면 역할, 기업정보 표출 X */}
                        {session?.activationStatusCd === 'NORMAL' && (
                            <If
                                condition={
                                    session?.roleInfo?.systemType === 'B2BG'
                                }
                            >
                                {/* jp > 역할 표출, systemType이 B2BG일 경우 기업정보 표출 X,  */}
                                <Then>
                                    <div className="label">
                                        <Text
                                            text={session?.roleInfo?.name}
                                            className="cate"
                                        />
                                    </div>
                                </Then>
                                <Else>
                                    <div className="label">
                                        <Text
                                            text={session?.roleInfo?.name}
                                            className="cate"
                                        />
                                    </div>
                                    <span className="company">
                                        {session?.business?.name}
                                    </span>
                                </Else>
                            </If>
                        )}

                        <Link to="/myInfo">
                            <Text resId="Title.MyInfo" className="setup" />
                        </Link>
                        <Link to="/signOut">
                            <Text resId="Common.Logout" className="Login" />
                        </Link>
                        <Form>
                            <TextOrSelect
                                name="changeLocale"
                                option={fetchCode('Code.Comm.Language')}
                                value={language}
                                className={INPUT_WIDTH_CLASS.AUTO}
                                onValueChange={(res) => {
                                    if (changeLocale)
                                        changeLocale(res?.data?.changeLocale);
                                }}
                            />
                        </Form>
                    </div>
                </div>
            </div>
            <If condition={session?.activationStatusCd === 'NORMAL'}>
                <Then>
                    <NoticeGnb
                        visible={alramVisible}
                        onClose={fnVisible}
                        lengCallBack={quickNotiCnt}
                    />
                </Then>
            </If>
        </>
    );
};

export default GlobalNavigationBar;
