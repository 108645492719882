import { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { UnauthedRoutes } from 'config/routes';
import { DynamicRoute } from 'util/DynamicRoute';

const UnAuthedSimpleApp = () => {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <DynamicRoute routes={UnauthedRoutes}></DynamicRoute>
                </Switch>
            </Suspense>
        </>
    );
};

export { UnAuthedSimpleApp };
