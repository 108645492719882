import React, { useCallback, useEffect, useState } from 'react';
import {
    useAppRoot,
    useTransaction,
    TextOrSelectWithbaseOption,
    HiddenInput,
} from 'component/mux';
import { TRANSID_PLAIN_BUSINESS } from 'config/transaction';
import { ItemsToCodeLists } from 'util/reservationUtils';

const BusinessTextOrSelect = ({
    name = 'businessID',
    value,
    includeAll = false,
    onChangeValue,
    onValueChange,
    ...rest
}) => {
    const { session, signIn } = useAppRoot();
    const { role, business } = session || {};
    const { fetchTransaction } = useTransaction(TRANSID_PLAIN_BUSINESS);
    const [dataSource, setDataSource] = useState([]);
    const [businessID, setBusinessID] = useState();

    const OnValueChange = useCallback(
        (props) => {
            const { data } = props;
            const findItems = data
                ? _.find(dataSource, ['ID', data[name]])
                : undefined;

            if (onChangeValue) {
                /**
                 * 해당 로직으로 session.myBusiness 값이 생성되고
                 * 각 화면에 myBusiness 체크로직이 존재함으로, myBusiness 기본값을 null -> { ID: undefined } 로 변경할경우
                 * 화면별 myBusiness 체크로직을 같이 변경해야함
                 * myBusiness 생성로직을 원복함으로 화면별 체크해야 하는 영향없음
                 */
                onChangeValue(findItems);
            }
            if (onValueChange) {
                onValueChange({ data });
            }
        },
        [name, onChangeValue, onValueChange, dataSource],
    );
    useEffect(() => {
        if (['BUSINESS_MANAGER', 'BUSINESS_OPERATOR'].includes(role)) {
            setBusinessID(business?.ID);
        } else {
            setBusinessID(value);
        }
    }, [role, business, value]);

    useEffect(() => {
        setDataSource(undefined);
        fetchTransaction(null, { filters: { noPaging: 'Y' } }).then(
            ({ items }) => {
                const businessList = ItemsToCodeLists(items, 'name', 'ID');
                setDataSource(businessList);
                signIn((prev) => ({
                    ...prev,
                    businessList,
                }));
            },
        );
    }, [businessID]);

    return ['BUSINESS_MANAGER', 'BUSINESS_OPERATOR'].includes(role) ? (
        <HiddenInput name={name} value={businessID || ''} />
    ) : (
        <TextOrSelectWithbaseOption
            name={name}
            option={dataSource || []}
            baseOption={
                includeAll ? { resId: 'Business.Select', value: '' } : undefined
            }
            value={businessID || (includeAll ? '' : undefined)}
            placeholderId="Business.Select"
            editable
            onValueChange={OnValueChange}
            orderBy={true}
            {...rest}
        ></TextOrSelectWithbaseOption>
    );
};
export default BusinessTextOrSelect;
