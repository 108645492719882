import { useLocation } from 'react-router-dom';

import GlobalNavigationBar from './GlobalNavigationBar';
import { ContentsPage } from './ContentsPage';
import LeftNavigationBar from './LeftNavigationBar';

const WrapLayer = ({ children }) => {
    const location = useLocation();
    return (
        <div
            id="wrap"
            className={
                (location.pathname === '/dashboardBusiness' && 'wbDashBoard') ||
                ''
            }
        >
            {children}
        </div>
    );
};

const AuthedApp = ({ onRanderHandle }) => {
    return (
        <>
            <WrapLayer>
                <div id="lnb">
                    <LeftNavigationBar />
                </div>

                <div id="container">
                    <GlobalNavigationBar></GlobalNavigationBar>

                    <div id="content">
                        <ContentsPage
                            onRanderHandle={onRanderHandle}
                        ></ContentsPage>
                    </div>
                </div>
            </WrapLayer>
            {/* <div id="wrap">
                    <div id="lnb">
                        <LeftNavigationBar />
                    </div>

                    <div id="container">
                        <GlobalNavigationBar></GlobalNavigationBar>

                        <div id="content">
                            <ContentsPage></ContentsPage>
                        </div>
                    </div>
                </div> */}
        </>
    );
};

export { AuthedApp };
