/**
 * global.mop.user.lib/src/util/date-picker.js
 * 복제된 사유: DEVOPS 개발자 환경에서 nexus를 통한 npm install 이 안되기 때문에 mux 업데이트 대신 소스 복제를 통해 수정함.
 * MuxBaseTextOrDatePicker 를 위해 복제, 수정사항 없음.
 */
import moment from 'moment';

// B/E Date max value
export const BE_DATE_UINT_MAX = new Date('2351-01-01');
// B/E Date min value
export const BE_DATE_UINT_MIN = new Date('1970-01-01');

// moment 를 longtime 으로 변환
export function moment2longtime(value) {
    return moment(value).toDate().getTime();
}

// 캘린더 날짜 비활성화
export function isDisabledDate(current, baseDate, direction) {
    if (!baseDate) return false;
    // Can not select days before/after {baseDate} and {baseDate}
    return direction === 'before'
        ? moment(baseDate).isAfter(current, 'day')
        : moment(baseDate).isBefore(current, 'day');
}

// exportFormat 에 따라 출력값 변경
export function getExportValue(value, format) {
    if (_.isEmpty(value)) return '';
    const date = moment(value);
    switch (format) {
        case 'longtime':
            return date.toDate().getTime();
        case 'moment':
            return date;
        default:
            return date.format(format);
    }
}

// 시간 형태의 포맷으로 moment 객체 생성
export function parseTimeStringToMoment(timeString, seperator = ':') {
    if (!timeString || !_.isString(timeString)) return null;
    return moment(
        _.reduce(
            timeString.split(seperator),
            (result, time, index) => {
                if (index === 0) result.hour = time;
                if (index === 1) result.minute = time;
                if (index === 2) result.second = time;
                return result;
            },
            {},
        ),
    );
}
