import React, { forwardRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import { TextOrAdvancedInput as MuxTextOrAdvancedInput } from 'mux';

const TextOrAdvancedInput = forwardRef((props, ref) => {
    const {
        name,
        value,
        disable,
        readonly,
        placeholderId,
        placeholder,
        required,
        onChangeValue,
        resId,
        text,
        ...rest
    } = props;

    const intl = useIntl();
    const { setValue } = useFormContext();

    // 유효성 검사 - https://react-hook-form.com/api/useform/register/ 의 Rules 참고
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        return chunkRules;
    }, [required]);

    // change 이벤트
    const handleChangeValue = (e) => {
        if (_.isFunction(onChangeValue)) {
            onChangeValue(e);
        }
    };

    // 기본값
    useEffect(() => {
        setValue(name, value);
    }, [name, value]);

    return (
        <MuxTextOrAdvancedInput
            {...rest}
            {...rules}
            name={name}
            value={value}
            placeholder={
                _.isString(placeholderId)
                    ? intl.formatMessage({ id: placeholderId })
                    : placeholder
            }
            disabled={disable}
            readOnly={readonly}
            onChangeValue={handleChangeValue}
            buttonProps={{
                label: _.isString(resId)
                    ? intl.formatMessage({ id: resId })
                    : text,
            }}
            ref={ref}
        />
    );
});

TextOrAdvancedInput.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    disable: PropTypes.bool,
    readonly: PropTypes.bool,
    placeholder: PropTypes.string,
    placeholderId: PropTypes.string,
    required: PropTypes.bool,
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    dialogId: PropTypes.string.isRequired,
    resId: PropTypes.string,
    text: PropTypes.string,
    onShouldInitDialog: PropTypes.func,
    onResult: PropTypes.func,
    watch: PropTypes.arrayOf(PropTypes.string),
    onDetected: PropTypes.func,
    buttonProps: PropTypes.object,
};

TextOrAdvancedInput.defaultProps = {
    type: 'text',
    disable: false,
    readonly: true,
    required: false,
    editable: true,
    text: '찾아보기',
};

export default TextOrAdvancedInput;
