import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Form, Text, useTransaction, useAppRoot, Dialog } from 'component/mux';
import { TRANSID_TERMS } from 'config/transaction';
import useAuthentication from 'util/authentication';
import { TransFailure } from 'util/transactionUtil';
import { TERMS } from 'util/pageUtil';
import { ActionButton } from 'component/ActionButton';

const LoginFirstAgree = ({ visible, onAgree, onNoAgree }) => {
    const intl = useIntl();
    const [agreeChk, setAgreeChk] = useState(false);
    const [items, setItems] = useState();
    const { customTransaction } = useTransaction(TRANSID_TERMS);
    const { setAgreeComplete } = useAuthentication();
    const { session, language } = useAppRoot();
    const role = session?.role;

    useEffect(() => {
        if (!visible) {
            return;
        }

        let termsGroupIDs = TERMS.ADMIN;
        if (['BUSINESS_MANAGER', 'BUSINESS_OPERATOR'].includes(role)) {
            // 슈퍼관리자, 부서담당자
            termsGroupIDs = TERMS.BIZ;
        }

        // 이용약관
        const transData = {
            termsGroupIDs: termsGroupIDs,
            termsTypes: 'Terms.Type.UseNoti',
            termsClassIDs: 'USENOTI_NOTI',
            useYn: 'Y',
            activeDateYn: 'Y',
            nationality: language === 'ko' ? 'KOR' : 'JPN',
        };

        customTransaction('getGroup', transData)
            .then(({ items }) => {
                if (_.size(items) > 0) {
                    const { contents } = items[0];
                    setItems(contents);
                }
            })
            .catch((error) => {
                TransFailure({ intl, error });
            });
    }, [visible]);

    const onClickAgree = useCallback(() => {
        if (!agreeChk) {
            return;
        }

        setAgreeComplete('Y');
        return onAgree();
    }, [agreeChk]);

    const onClickCancel = () => {
        return onNoAgree();
    };

    return (
        <Form>
            <Dialog
                type="default"
                visible={visible}
                closable={false}
                resId="Common.Login.Agree.Title"
                width={540}
                actions={[
                    <ActionButton
                        type="cancel"
                        label={{ resId: 'Btn.Agree.No' }}
                        onClick={onClickCancel}
                    />,
                    <ActionButton
                        type={agreeChk ? 'primary' : 'cancel'}
                        label={{ resId: 'Btn.Agree.Yes' }}
                        onClick={onClickAgree}
                    />,
                ]}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: items,
                    }}
                />
                <div className="wbAgreeWrap">
                    <label className="wbCheckbox" htmlFor="infoAgree">
                        <input
                            id="infoAgree"
                            type="checkbox"
                            onChange={() => {
                                setAgreeChk((prev) => {
                                    return !prev;
                                });
                            }}
                            checked={agreeChk}
                        />
                        <span>
                            <Text resId="Common.Login.Agree.Content.4" />
                        </span>
                    </label>
                </div>
            </Dialog>
        </Form>
    );
};

export default LoginFirstAgree;
