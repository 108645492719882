import { matchPath } from 'react-router';
import { PAGE_MODE } from 'util/pageUtil';

const AuthedRoutes = [
    /* 유지관리/정비 이력 Mobile */
    {
        url: '/m/vehicleOperation',
        page: () => import('pageMobile/vehicleMaintenance/ListPage'),
        props: { baseURL: '/m/vehicleOperation' },
    },
    {
        url: '/m/vehicleOperation/new',
        page: () => import('pageMobile/vehicleMaintenance/DetailPage'),
        props: { mode: PAGE_MODE.CREATE, baseURL: '/m/vehicleOperation' },
    },
    {
        url: '/m/vehicleOperation/:ID/form',
        page: () => import('pageMobile/vehicleMaintenance/DetailPage'),
        props: { mode: PAGE_MODE.EDIT, baseURL: '/m/vehicleOperation' },
    },
    {
        url: '/m/vehicleOperation/:ID',
        page: () => import('pageMobile/vehicleMaintenance/DetailPage'),
        props: { mode: PAGE_MODE.VIEW, baseURL: '/m/vehicleOperation' },
    },
    /* 공통 */
    {
        url: '/openWindow',
        page: () => import('page/OpenWindow'),
    },
    {
        url: '/reload',
        page: () => import('page/ReloadPage'),
    },
    {
        url: '/signOut',
        page: () => import('page/unauthed/SignOut'),
    },
];

const UnauthedRoutes = [
    {
        url: '/signOut',
        page: () => import('page/unauthed/SignOut'),
    },
];

const findMenuUrl = (pathname) => {
    const selectedRoutes = AuthedRoutes.filter((item) => {
        const mPath = matchPath(pathname, { path: item?.url, exact: true });
        return !!mPath;
    });
    if (selectedRoutes?.length)
        return (
            selectedRoutes[0]?.props?.menuURL ||
            selectedRoutes[0]?.props?.baseURL ||
            selectedRoutes[0]?.url
        );
    return pathname;
};

export { AuthedRoutes, UnauthedRoutes, findMenuUrl };
