import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import Slider from 'react-slick';
import { useCookies } from 'react-cookie';
import _ from 'lodash';
import { useAppRoot, useTransaction, Button, Dialog } from 'component/mux';
import {
    TRANSID_CSCENTER_NOTICE,
    TRANSID_BANNER_MGMT,
} from 'config/transaction';
import { COOKIE_KEY, STORAGE_KEY } from 'util/pageUtil';
import useAuthentication, { MEMBER_ROLE } from 'util/authentication';
import { TransFailure } from 'util/transactionUtil';
import { useIntl } from 'react-intl';

const VOC_ADMIN_ROLE_KEY = ['VOC'];

const NoticeDays = () => {
    const intl = useIntl();
    const [items, setItems] = useState([]);
    const [visible, setVisible] = useState(true);
    const { session } = useAppRoot();
    const [cookies, setCookie] = useCookies(COOKIE_KEY.NOTICE_DAY_NOT_SHOW);
    const { getStorage } = useAuthentication();

    const { customTransaction: voc_customTransaction } = useTransaction(
        TRANSID_CSCENTER_NOTICE,
    );
    const { customTransaction: banner_customTransaction } =
        useTransaction(TRANSID_BANNER_MGMT);

    const onClose = () => {
        setVisible((prev) => {
            return !prev;
        });
    };

    const onCloseDay = () => {
        let curDay = new Date();
        let afterDay = new Date();
        afterDay.setDate(curDay.getDate() + 1);
        setCookie(COOKIE_KEY.NOTICE_DAY_NOT_SHOW, 'Y', {
            path: '/',
            expires: afterDay,
        });
        setVisible((prev) => {
            return !prev;
        });
    };

    /**
     * readOnly 인경우 자동으로 노출할 수 있는 목록으로 필터됨
     *.
     * MOCEAN Car Sharing FMS 메니저
     *   노출 안함
     *
     * VOC
     *   고객센터 > 고객센터 공지
     *
     * 고객사 (슈퍼관리자/부서담당자) 배너/스플래시/팝업 else if 조건
     * 공지사항 의 경우 businessID 를 넘김 ( 공지 등록시 옵션으로 선택하게 되어있음 )
     *
     *
     * 등록시 xss 예외처리 필요
     */
    useEffect(() => {
        const curCookies = cookies[COOKIE_KEY.NOTICE_DAY_NOT_SHOW];
        const notiDaysClose = getStorage(STORAGE_KEY.NOTI_DAYS_CLOSE);
        const activationStatusCd = session?.activationStatusCd;
        const role = session?.role;
        const roleKey = session?.roleInfo?.roleKey;
        // const businessID = session?.business?.ID;
        if (
            curCookies === 'Y' ||
            activationStatusCd !== 'NORMAL' ||
            notiDaysClose === 'Y'
        ) {
            onClose();
            return <></>;
        }

        // (VOC) 고객센터 > 고객센터 공지
        if (VOC_ADMIN_ROLE_KEY.includes(roleKey)) {
            voc_customTransaction('listContents', {
                readOnly: 'Y',
            })
                .then(({ items }) => {
                    const nItems = items.reduce((prev, cur) => {
                        const { contents } = cur;
                        const nObj = {
                            notiType: 'HTML',
                            contents: contents,
                        };
                        prev.push(nObj);
                        return prev;
                    }, []);
                    setItems(nItems);
                })
                .catch((error) => {
                    TransFailure({ intl, error });
                });

            // (슈퍼관리자/부서담당자) 배너/스플래시/팝업
        } else if (MEMBER_ROLE.includes(role)) {
            banner_customTransaction('listContents', {
                splashType: 'POP_ADMIN',
                readOnly: 'Y',
                langs: 'ja', // [일본] 팝업 등록시 언어설정이 추가되어 대응
            })
                .then(({ items }) => {
                    const noItems = items?.length
                        ? items
                              .filter((item) => ['Y'].includes(item.useYn))
                              .reduce((prev, cur) => {
                                  const { name, clickUrl, splashImage } = cur;
                                  const nObj = {
                                      notiType: 'IMG',
                                      title: name,
                                      clickUrl: clickUrl,
                                      contents: splashImage,
                                  };
                                  prev.push(nObj);
                                  return prev;
                              }, [])
                        : [];

                    setItems((prev) => {
                        const nPrev = [...prev, ...noItems];
                        return nPrev;
                    });
                })
                .catch((error) => {
                    TransFailure({ intl, error });
                });

            // noti_customTransaction('listContents', {
            //     readOnly: 'Y',
            //     target: 'BUSINESS',
            // })
            //     .then(({ items }) => {
            //         const noItems = items?.length
            //             ? items.reduce((prev, cur) => {
            //                   const { contents } = cur;
            //                   const nObj = {
            //                       notiType: 'HTML',
            //                       contents: contents,
            //                   };
            //                   prev.push(nObj);
            //                   return prev;
            //               }, [])
            //             : [];
            //         setItems((prev) => {
            //             const nPrev = [...prev, ...noItems];
            //             return nPrev;
            //         });
            //     })
            //     .catch((error) => {
            //         TransFailure({ intl, error });
            //     });
        }
    }, [cookies, session]);

    return (
        <>
            <If condition={!_.isEmpty(items)}>
                <Then>
                    <Dialog
                        visible={visible}
                        closable={false}
                        onCancel={onClose}
                        // style={{ '.ant-modal-content': { padding: 0 } }}
                        width={620}
                        className="paddingZero"
                        footer={false}
                    >
                        <NoticeContents
                            items={items}
                            onClose={onClose}
                            onCloseDay={onCloseDay}
                        />
                    </Dialog>
                </Then>
            </If>
        </>
    );
};

const NoticeContents = ({ items, onClose, onCloseDay }) => {
    const { setStorage } = useAuthentication();
    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    const notiClose = () => {
        setStorage(STORAGE_KEY.NOTI_DAYS_CLOSE, 'Y');
        return onClose();
    };

    const sliderItems = _.reduce(
        items,
        (prev, cur, idx) => {
            const notiType = cur?.notiType;
            const title = cur?.title;
            const clickUrl = cur?.clickUrl;
            const contents = cur?.contents;
            let nodeItem;
            if (notiType === 'IMG') {
                nodeItem = (
                    <span className="slideItem" key={idx}>
                        <If condition={clickUrl}>
                            <Then>
                                <a
                                    href={clickUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        // src={`${process.env.PUBLIC_URL}${contents}`}
                                        src={`data:image/png;base64,${contents}`}
                                        alt={`banner_${title}`}
                                    />
                                </a>
                            </Then>
                            <Else>
                                <img
                                    src={`data:image/png;base64,${contents}`}
                                    alt={`banner_${title}`}
                                />
                            </Else>
                        </If>
                    </span>
                );
            } else {
                nodeItem = (
                    <span className="slideItem" key={idx}>
                        {/* <div className="title">공지사항 타이틀이 들어갑니다.</div> */}
                        <div
                            className="txt"
                            dangerouslySetInnerHTML={{
                                __html: contents,
                            }}
                        />
                    </span>
                );
            }

            prev.push(nodeItem);
            return prev;
        },
        [],
    );

    return (
        <>
            <If condition={_.size(sliderItems) > 0}>
                <div className="noticePopup">
                    <div className="imgSlideShow">
                        <div className="slideshow">
                            <Slider {...settings}>{sliderItems}</Slider>
                        </div>
                    </div>
                    <div className="bottom">
                        <Button
                            className="txtBtn"
                            onClick={onCloseDay}
                            resId="Btn.Today.Close"
                        />
                        <Button
                            className="txtBtn"
                            onClick={notiClose}
                            resId="Btn.Close"
                        />
                    </div>
                </div>
            </If>
        </>
    );
};

export default NoticeDays;
