import { useEffect, useState } from 'react';
import _ from 'lodash';
import { If, Then } from 'react-if';
import { useAppRoot, useTransaction, Button, Text } from 'component/mux';
import { TRANSID_DASHBOARD } from 'config/transaction';
import { parseFormatStr, DATETIME_FORMAT } from 'util/LongTimeUtil';
import { TransFailure } from 'util/transactionUtil';
import { useIntl } from 'react-intl';
import { CodeValueText } from 'component/CodeText';
import CustomDialog from './CustomDialog';

const postCodeStyle = {
    display: 'block',
    position: 'fixed',
    top: 80,
    left: 367,
    zIndex: 100,
    width: 400,
    padding: 20,
};

const SLICE_SIZE = 3;

const NoticeGnb = ({ visible, onClose, lengCallBack }) => {
    const intl = useIntl();
    const { customTransaction } = useTransaction(TRANSID_DASHBOARD);
    const [items, setItems] = useState();
    const [curSize, setCurSize] = useState(SLICE_SIZE);
    const { session } = useAppRoot();

    useEffect(() => {
        if (!session?.business?.ID) return;
        customTransaction('getBusinessNoti', {
            id: session?.business?.ID,
            role: session?.role,
        })
            .then(({ items }) => {
                setItems(items);
                lengCallBack(_.size(items));
            })
            .catch((error) => {
                TransFailure({ intl, error });
            });
    }, []);

    const curSizeHandle = () => {
        setCurSize((prev) => {
            return prev + SLICE_SIZE;
        });
    };

    const MoreView = ({ items, curSize, curSizeHandle }) => {
        if (!_.isArray(items) || _.size(items) < 1) {
            return (
                <>
                    <div className="noticeMessageNon">
                        <Text resId="Message.Notice.NoData" />
                    </div>
                </>
            );
        }
        const againSlice = _.size(items) > curSize;
        const resizeViews = _.slice(items, 0, curSize);

        const _viewItems = [];
        _.forEach(resizeViews, (element) => {
            const { ID, messageTypeCd, message, createdAt } = element;
            _viewItems.push(
                <div className="noticeMessage">
                    <div className="messageBox">
                        <div ID={ID}>
                            <div className="titleWrap">
                                <div className="title">
                                    <CodeValueText
                                        groupKey="BusinessNotification"
                                        value={messageTypeCd}
                                        defaultMessage={messageTypeCd}
                                    />
                                </div>

                                <div className="date">
                                    {parseFormatStr({
                                        text: createdAt,
                                        formatStr: DATETIME_FORMAT[0],
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="message">{message}</div>
                    </div>
                </div>,
            );
        });

        return (
            <>
                {_viewItems}
                <If condition={againSlice}>
                    <Then>
                        <div className="noticeBtnMoreWrap">
                            <Button
                                onClick={curSizeHandle}
                                resId="Btn.Notice.More"
                                className="noticeBtnMore"
                            />
                        </div>
                    </Then>
                </If>
            </>
        );
    };

    return (
        <>
            <CustomDialog
                visible={visible}
                resId="Common.Bnb.Alram"
                headerStyle={{
                    height: 'unset',
                    padding: '0 20px 18px',
                    margin: '0 -20px 14px',
                    borderBottom: '1px solid #e6e6e6',
                }}
                bodyStyle={{
                    height: 390,
                    overflowY: 'auto',
                }}
                width={400}
                style={postCodeStyle}
                onCancel={onClose}
                noDim={true}
                footer={false}
                header={
                    <>
                        <Text
                            resId="Common.Bnb.Alram"
                            className="popupTitleSm"
                        />
                        <Button
                            type="text"
                            className="btnPopupCloseSm"
                            onClick={onClose}
                            icon={<span className="iconCloseSm" />}
                        />
                    </>
                }
            >
                <MoreView
                    items={items}
                    curSize={curSize}
                    curSizeHandle={curSizeHandle}
                />
            </CustomDialog>
        </>
    );
};

export default NoticeGnb;
