export const ErrorCode = [
    // common DB  9100
    { code: 910001, label: '910001', desc: 'NOT_CREATED' },
    { code: 910002, label: '910002', desc: 'NOT_UPDATED' },
    { code: 910003, label: '910003', desc: 'NOT_DELETED' },
    { code: 910004, label: '910004', desc: 'DB_INTERNAL_ERROR' },
    { code: 910005, label: '910005', desc: 'NOT_FOUND' },
    {
        code: 900403,
        label: 'Message.Auth.Alert.Forbidden',
        desc: 'ERROR_CODE_DAT_ACCESS_DENY',
    }, // 데이터 접근권한 없음.

    // common Session  9200
    { code: 920001, label: '920001', desc: 'USER_NOT_FOUND' },
    { code: 920002, label: '920001', desc: 'INVALID_TOKEN' },

    // common Struct Copy  9300
    { code: 930001, label: '930001', desc: 'MESSAGE_COPY_FAILED' },
    { code: 930002, label: '930002', desc: 'STRUCT_NOT_FOUND' },

    // common Excel 9400
    { code: 940001, label: '940001', desc: 'EXCEL_DOWNLOAD_FAILED' },
    { code: 940002, label: '940002', desc: 'EXCEL_UPLOAD_FAILED' },
    {
        code: 940005,
        label: '940005',
        desc: 'ERROR_CODE_FILE_FAILED_OVER_DEFAULT_MAX_ROW',
    },

    // common File  9500
    { code: 950001, label: '950001', desc: 'FILE_NOT_FOUND' },
    { code: 950002, label: '950002', desc: 'FILE_NOT_CREATED' },
    { code: 950003, label: '950003', desc: 'FILE_NOT_DELETED' },
    { code: 950004, label: '950004', desc: 'FILE_EMPTY_FILE_TYPE' },
    { code: 950005, label: '950005', desc: 'FILE_EMPTY_FILE_GROUP_TYPE' },
    { code: 950006, label: '950006', desc: 'FILE_EMPTY_FILE_GROUP_ID' },
    { code: 950007, label: '950007', desc: 'FILE_INVALID_MIMETYPE' },
    { code: 950008, label: '950008', desc: 'FILE_NOT_OPENED' },
    { code: 950009, label: '950009', desc: 'FILE_NOT_COPIED' },

    // 차량 관리 > 차량 등록/조회
    { code: 330002, label: '330002', desc: 'RESERVATION_EXIST' },
    { code: 330003, label: '330003', desc: 'DEVICE_EXIST' },
    {
        code: 330004,
        label: '330004',
        desc: 'ERROR_CODE_VEHICLE_DELETE_FORCE_REASON_RESERVATION',
    },
    {
        code: 330005,
        label: '330005',
        desc: 'ERROR_CODE_VEHICLE_TRANSFER_REASON_RESERVATION',
    },

    // 차량 관리 > 차종(차량모델)
    { code: 340003, label: '340003', desc: 'USED_VEHICLE' },
    { code: 340004, label: '340004', desc: 'USED_STANDARD' },
    { code: 340005, label: '340005', desc: 'USED_MODEL_INFO' },

    // 차량 관리 > 단말기
    { code: 470001, label: '470001', desc: 'VIN_NOT_EXIST' },

    // 차량 관리 > 유지관리/정비 이력
    {
        code: 550001,
        label: '550001',
        desc: 'NOT_TIME',
    },
    {
        code: 550002,
        label: '550002',
        desc: 'NOT_RETURN_VEHICLE_MAINTENANCE',
    },
    {
        code: 550003,
        label: '550003',
        desc: 'DUPLICATE_INSERT_VEHICLE_MAINTENANCE',
    },
    {
        code: 550005,
        label: '550005',
        desc: 'ERROR_CODE_DUPLICATE_TIME_MAINTENANCE',
    },

    // 위블존 관리 > 위블존 등록/조회
    { code: 310001, label: '310001', desc: 'LOCATION_NOT_USEABLE' },
    { code: 310002, label: '310002', desc: 'LOCATION_NOT_USEABLE_VEHICLE' },
    {
        code: 310003,
        label: '310003',
        desc: 'LOCATION_NOT_USEABLE_CHARGE_TYPE_CD',
    },
    { code: 310004, label: '310004', desc: 'LOCATION_CONTRACT_NOT_FOUND' },
    {
        code: 310005,
        label: '310005',
        desc: 'LOCATION_CHARGE_INSURANCE_NOT_FOUND',
    },
    { code: 310006, label: '310006', desc: 'LOCATION_SCHEDULE_NOT_FOUND' },
    { code: 310008, label: '310008', desc: 'LOCATION_RESERVATION_NOT_FOUND' },
    { code: 310009, label: '310009', desc: 'VEHICLE_EXIST_LOCATION' },
    { code: 310010, label: '310010', desc: 'CONTRACT_EXIST_LOCATION' },
    { code: 310012, label: '310012', desc: 'LOCATION_INVALID_TIME' },
    { code: 310013, label: '310013', desc: 'LOCATION_USED_VEHICLE' },
    { code: 310014, label: '310014', desc: 'LOCATION_NOT_RETURNED' },
    {
        code: 310023,
        label: '310023',
        desc: 'ERROR_CODE_LOCATION_OVER_NAX_SPACE_TIME',
    }, // 공간요금제는 최대 3시간 까지만 대여하실수 있습니다. 확인 후 다시 예약해주세요.
    { code: 313101, label: '313101', desc: 'NOT_BUSINESS_CONTRACT' },
    { code: 313102, label: '313102', desc: 'RESERVATION_NOT_FOUND' },
    { code: 313103, label: '313103', desc: 'INSURANCE_NOT_FOUND' },

    // 시스템 관리 > 메뉴
    { code: 100001, label: '100001', desc: 'MENU_CHILDREN_EXIST' },
    { code: 101001, label: '101001', desc: 'MENU_RPOGRAM_INSERT_EXIST' },

    // 시스템 관리 > 코드
    { code: 122001, label: '122001', desc: 'CODE_INSERT_EXIST' },

    // 콘텐츠 관리 > 알림 템플릿
    { code: 151501, label: '151501', desc: 'TEMPLATE_CODE_DUPLICATION' },

    // 운영 관리 > 버전
    { code: 161901, label: '161901', desc: 'VERSION_DUPLICATION' },

    // 회원가입 > 슈퍼관리자/부서담당자
    {
        code: '512305',
        label: '512305',
        desc: 'ERROR_CODE_BUSINESS_MANAGER_OPERATOR', // 고객사내 프로필이 2개 이하인 경우, 임직원 삭제 불가
    },
    {
        code: '515101',
        label: '515103',
        desc: 'AGREEMENT_NOT_FULFILLED', // 필수약관 동의 누락
    },
    {
        code: '515102',
        label: '515102',
        desc: 'CUSTOMER_NOT_FOUND', // 비즈니스 프로필 생성 전, 가입불가
    },
    {
        code: '515103',
        label: '515103',
        desc: 'CUSTOMER_CONTRACT_NOT_FOUND', // 계약 없음, 가입불가
    },
    {
        code: '515104',
        label: '515103',
        desc: 'CUSTOMER_CONTRACT_DATE_EXCEEDED', // 계약 만료, 가입불가
    },
    {
        code: '515105',
        label: '515103',
        desc: 'CUSTOMER_CONTRACT_NOT_STARTED', // 계약 시작일 미도래
    },
    {
        code: '510002',
        label: '510002',
        desc: 'INVALID_PIN', // 일치하지 않는 pin 번호
    },
    {
        code: '510003',
        label: '515103',
        desc: 'CONNECT_FAIL_WITH_CCSP ', // ccsp 통신 오류
    },
    {
        code: '510004',
        label: '515103',
        desc: 'VALIDATE_PIN_CCSP_ERROR ', // CCSP pin 인증 기타 오류. 로그 확인 필요
    },
    // 기타 > 내 정보
    // 프로필 삭제
    { code: '513101', label: '513101', desc: 'CUSTOMER_UNIQUE' },
    { code: '513102', label: '513102', desc: 'CUSTOMER_USE_RESERVATION' },
    // 권한이관
    {
        code: '515106',
        label: '515106',
        desc: 'DEPT_SHOULD_HAVE_AT_LEAST_1_OPERATOR',
    },
    // TODO 메시지 X
    // { code: '515107', label: '515107', desc: 'CANNOT_FIND_PREVIOUS_USER' },
    // { code: '515108', label: '515108', desc: 'CANNOT_FIND_TARGET_USER' },
    // { code: '515109', label: '515109', desc: 'CANNOT_GRANT_LOWER_ROLE' },
    // { code: '515110', label: '515110', desc: 'CANNOT_GRANT_SAME_ROLE' },
    // {
    //     code: '515111',
    //     label: '515111',
    //     desc: 'CANNOT_GRANT_ANOTHER_DEPARTMENT',
    // },
    // { code: '515112', label: '515112', desc: 'CANNOT_GRANT_ANOTHER_ROLE' },

    // 회원/부서 관리 > 회원
    { code: '480001', label: '480001', desc: 'CUSTOMER_CARD_ONE_OR_MORE' },
    { code: '740001', label: '740001', desc: 'DRIVERLICENSE_USE_RESERVATION' },

    // 회원/부서 관리 > 임직원 조회
    { code: '523101', label: '523101', desc: 'CUSTOMER_EMPLOYEE_UNIQUE' },
    {
        code: '523102',
        label: '523102',
        desc: 'CUSTOMER_EMPLOYEE_USE_RESERVATION',
    },
    //회원/부서 관리 > 임직원 등록
    { code: '523105', label: '523105', desc: 'CUSTOMER_EMAIL_UNIQUE' },
    { code: '523111', label: '523111', desc: 'EMPLOYEE_EMAIL_INVALID' },
    { code: '523113', label: '523113', desc: 'EMPLOYEE_REGNUM_INVALID' },

    //회원사 계약 등록
    { code: '292901', label: '292901', desc: 'BUSINESS_MANAGER_MAXIMUM' },
    { code: '224201', label: '224201', desc: 'CONTRACT_DATE_DUPLICATION' },

    //사고수리이력 등록
    { code: '490001', label: '490001', desc: 'DUPLICATION' },
    { code: '490002', label: '490002', desc: 'ERROR_CODE_NON_CLAIM_DATA' },
    { code: '490003', label: '490003', desc: 'ERROR_CODE_NON_UPDATE_STATUS' },
    { code: '490004', label: '490004', desc: 'ERROR_CODE_NON_FILE_PAYENT' },
    { code: '490005', label: '490005', desc: 'ERROR_CODE_NON_STATUS_DATA' },

    //회원/부서 관리 > 부서 관리
    { code: '382901', label: '382901', desc: 'DEPARTMENT_NAME_UNIQUE' },
    { code: '382902', label: '382902', desc: 'DEPARTMENT_MANAGER_UNIQUE' },
    {
        code: '382903',
        label: '382903',
        desc: 'DEPARTMENT_MANAGER_USE_RESERVATION',
    },

    // 회원/부서 관리 > 페널티
    { code: '233502', label: '233502', desc: 'NOT_SAVED_DUPLICATED_PENALTY' },

    // 매출/정산 관리 > 쿠폰
    { code: 350001, label: '350001', desc: 'ERROR_CODE_COUPON_MAX_OVER' },
    { code: 350002, label: '350002', desc: 'ERROR_CODE_PROCESSOR_COUPON_NOT_UPDATED_ALREADY_ISSUED' },
    {
        code: 350003,
        label: '350003',
        desc: 'COUPON_NOT_DELETED_ALREADY_ISSUED_COUPON',
    },
    {
        code: 350004,
        label: '350004',
        desc: 'ERROR_CODE_COUPON_USER_OVER',
    },
    { code: 354106, label: '354106', desc: 'DUPLICATED_COUPON_CODE' },
    {
        code: 350002,
        label: '350002',
        desc: 'COUPON_NOT_UPDATED_ALREADY_ISSUED_COUPON',
    },
    {
        code: 350021,
        label: '350021',
        desc: 'ERROR_CODE_COUPON_USER_INVALID',
    },
    {
        code: 410003,
        label: '410003',
        desc: 'ALLIANCE_COUPON_NOT_CREATED_DUPLICATED_CODE',
    },
    {
        code: 410004,
        label: '410004',
        desc: 'ALLIANCE_COUPON_ALREADY_ISSUED_COUPON',
    },

    // 대여/결제관리 > 대여관리
    { code: 403000, label: '403000', desc: 'ALREADY_PROCESSING' },
    { code: 403001, label: '403001', desc: 'VEHICLE_NOT_FOUND' },
    { code: 403002, label: '403002', desc: 'VEHICLE_MODEL_NOT_FOUND' },
    { code: 403003, label: '403003', desc: 'PICKUP_LOCATION_NOT_FOUND' },
    { code: 403004, label: '403004', desc: 'RETURN_LOCATION_NOT_FOUND' },
    { code: 403005, label: '403005', desc: 'BUSINESS_NOT_FOUND' },
    { code: 403006, label: '403006', desc: 'BUSINESS_CONTRACT_NOT_FOUND' },
    { code: 403007, label: '403007', desc: 'RENTER_NOT_FOUND' },
    { code: 403008, label: '403008', desc: 'COMPANION_NOT_FOUND' },
    { code: 403009, label: '403009', desc: 'RENTER_DRIVERLICENSE_NOT_FOUND' },
    {
        code: 403010,
        label: '403010',
        desc: 'COMPANION_DRIVERLICENSE_NOT_FOUND',
    },
    { code: 403011, label: '403011', desc: 'RENTER_DRIVERLICENSE_CHECK_FAIL' },
    {
        code: 403012,
        label: '403012',
        desc: 'COMPANION_DRIVERLICENSE_CHECK_FAIL',
    },
    { code: 403013, label: '403013', desc: 'CHARGE_MASTER_NOT_FOUND' },
    { code: 403014, label: '403014', desc: 'RESERVATION_KEY_NOT_CREATE' },
    { code: 403015, label: '403015', desc: 'RESERVATION_CORE_NOT_CREATE' },
    { code: 403016, label: '403016', desc: 'CARD_NOT_FOUND' },
    { code: 403017, label: '403017', desc: 'PAYMENT_FAIL' },
    { code: 403018, label: '403018', desc: 'NOT_POSSIBLE_CANCEL_STATE' },
    { code: 403019, label: '403019', desc: 'NOT_POSSIBLE_EXTEND_STATE' },
    { code: 403020, label: '403020', desc: 'CONFLICT_RESERVATION' },
    { code: 403021, label: '403021', desc: 'CONTROL_HISTORY' },
    {
        code: 403022,
        label: '403022',
        desc: 'VEHICLE_LOCATION_IS_NOT_RETURN_LOCATION',
    },
    { code: 403023, label: '403023', desc: 'VEHICLE_STATE_NOT_FOUND' },
    {
        code: 403024,
        label: '403024',
        desc: 'VEHICLE_STATE_CONDITION_NOT_FOUND',
    },
    {
        code: 403025,
        label: '403025',
        desc: 'VEHICLE_STATE_CONDITION_ODOMETER_ZERO',
    },
    {
        code: 403026,
        label: '403026',
        desc: 'VEHICLE_STATE_CONDITION_FUELLEVEL_ZERO',
    },
    { code: 403027, label: '403027', desc: 'VEHICLE_STATE_LOCATION_NOT_FOUND' },
    {
        code: 403028,
        label: '403028',
        desc: 'VEHICLE_STATE_LOCATION_VALUE_ZERO',
    },
    {
        code: 403029,
        label: '403029',
        desc: 'VEHICLE_STATE_REMAIN_CHARGE_AMOUNT',
    },
    { code: 403030, label: '403030', desc: 'USER_PENALTY_NOT_CREATE' },
    { code: 403031, label: '403031', desc: 'USER_REWORD_COUPON_NOT_CREATE' },
    { code: 403032, label: '403032', desc: 'VEHICLE_TERMINAL_NOT_FOUND' },
    { code: 403033, label: '403033', desc: 'RETURN_COMMAND_ERROR' },
    { code: 403034, label: '403034', desc: 'VEHICLE_MID_NOT_FOUND' },
    { code: 403035, label: '403035', desc: 'PAYMENT_REGISTRATION_FAIL' },
    { code: 403036, label: '403036', desc: 'RECEIVABLES_EXIT' },
    { code: 403038, label: '403038', desc: 'DEPARTMENT_NOT_FOUND' },
    { code: 403039, label: '403039', desc: 'RESERVATION_IS_NOT_INUSE' },
    {
        code: 403040,
        label: '403040',
        desc: 'RESERVATION_STATE_IS_NOI_CONFIRMED',
    },
    { code: 403041, label: '403041', desc: 'VEHICLE_IS_NOT_RETURNED' },
    { code: 403042, label: '403042', desc: 'CANCEL_PAYMENT_FAIL' },
    { code: 403044, label: '403044', desc: 'RESERVATION_START_TIME_IS_OVER' },
    {
        code: 403045,
        label: '403045',
        desc: 'COMBINATION_CHARGE_KEY_GENERATION_FAIL',
    },
    { code: 403046, label: '403046', desc: 'VEHICLE_HAS_ANOTHER_RESERVATION' },
    { code: 403047, label: '403047', desc: 'PASSENGER_HAS_PENALTY' },
    { code: 403048, label: '403048', desc: 'VEHICLE_CHARGER_NOT_CONNECT' },
    { code: 403049, label: '403049', desc: 'RENTER_HAS_PENALTY' },
    { code: 403050, label: '403050', desc: 'RESERVATION_TOTAL_FEE_ERROR' },
    { code: 403051, label: '403051', desc: 'INSURANCE_FEE_ERROR' },
    { code: 403052, label: '403052', desc: 'COUPON_DISCOUNT_ERROR' },
    { code: 403053, label: '403053', desc: 'MEMBERSHIP_DISCOUNT_ERROR' },
    { code: 403054, label: '403054', desc: 'CHARGE_DISCOUNT_ERROR' },
    { code: 403055, label: '403055', desc: 'PAYMENT_FEE_ERROR' },
    { code: 403056, label: '403056', desc: 'COUPON_IS_NOT_AVAILABLE' },
    { code: 403057, label: '403057', desc: 'BUSINESS_CARD_NOT_EXIST' },
    {
        code: 403078,
        label: '403078',
        desc: 'RESERVATION_NOT_EXTEND_START_TIME',
    },
    { code: 403079, label: '403079', desc: 'RESERVATION_NOT_EXTEND_END_TIME' },
    { code: 403080, label: '403080', desc: 'NO_REMAIN_ENGAGEMENT_TIME' },
    {
        code: 403081,
        label: '403081',
        desc: 'RESERVETIME_LONGER_REMAIN_ENGAGEMENT_TIME',
    },
    { code: 403082, label: '403082', desc: 'ERROR_RESERVATION_UPDATE_INVALID_STATUS' },
    { code: 403083, label: '403083', desc: 'ERROR_RESERVATION_UPDATE_INVALID_STATUS_NOSHOW' },
    { code: 403084, label: '403084', desc: 'ERROR_RESERVATION_UPDATE_EXIST_COMMAND' },

    { code: 403092, label: '403092', desc: 'RENTER_PASSPORT_CHECK_FAIL' },
    { code: 403093, label: '403093', desc: 'COMPANION_PASSPORT_NOT_FOUND' },
    { code: 403094, label: '403094', desc: 'COMPANION_PASSPORT_CHECK_FAIL', },
    { code: 403095, label: '403095', desc: 'RENTER_PASSPORT_NOT_FOUND' },

    { code: 503046, label: '503046', desc: 'RESERVATION_EXIST' },
    { code: 503050, label: '503050', desc: 'RESERVATION_TOTAL_FEE_ERROR' },
    { code: 503051, label: '503051', desc: 'INSURANCE_FEE_ERROR' },
    { code: 503052, label: '503052', desc: 'COUPON_DISCOUNT_ERROR' },
    { code: 503053, label: '503053', desc: 'MEMBERSHIP_DISCOUNT_ERROR' },
    { code: 503054, label: '503054', desc: 'CHARGE_DISCOUNT_ERROR' },
    { code: 503055, label: '503055', desc: 'PAYMENT_FEE_ERROR' },
    { code: 503056, label: '503056', desc: 'COUPON_IS_NOT_AVAILABLE' },
    { code: 503059, label: '503059', desc: 'RESERVATION_IS_INUSE_OR_CANCELED' },
    { code: 503337, label: '503337', desc: 'VEHICLE_SCHEDULE_NOT_FOUND' },

    // 대여/결제관리 > 결제카드
    {
        code: 454002,
        label: '454002',
        desc: 'CREATE_BILL_KEY_FAILED',
    },
    {
        code: 454003,
        label: '454003',
        desc: 'CARD_ALREADY_REGISTERED_BILL_KEY',
    },
    {
        code: 454004,
        label: '454004',
        desc: 'CARD_INVALID_CARD_BIN',
    },
    {
        code: 454005,
        label: '454005',
        desc: 'CARD_CAN_NOT_USE',
    },
    {
        code: 454006,
        label: '454006',
        desc: 'CARD_INVALID_DATA',
    },
    {
        code: 454007,
        label: '454007',
        desc: 'GENERATE_BID_FAIL',
    },
    {
        code: 454008,
        label: '454008',
        desc: 'DUPLICATE',
    },

    // 상품관리 > 요금제, 보험료 (삭제 시 대여, 구독 존재 오류 코드)
    {
        code: 413412,
        label: '413412',
        desc: 'RESERVATION_EXIT_CANNOT_DELETE',
    },
    {
        code: 413413,
        label: '413413',
        desc: 'SUBSCRIBE_EXIT_CANNOT_DELETE',
    },

    // 콘텐츠관리 > 약관
    {
        code: 270002,
        label: '270002',
        desc: 'TERMS_EXIT_CANNOT_DELETE',
    },
    {
        code: 270003,
        label: '270003',
        desc: 'ERROR_CODE_PROCESS_TERMS_DUPLICATE_START_DATE ',
    },

    // 콘텐츠관리 > 알림메시지 > 수동메시지
    {
        code: 690001,
        label: '690001',
        desc: '엑셀 내 데이터 없음.',
    },
    {
        code: 690002,
        label: '690002',
        desc: '글차수 초과',
    },

    //구독관리
    {
        code: 503018,
        label: '503018',
        desc: 'SUBSCRIBE_CANNOT_CANCEL',
    },
    {
        code: 503316,
        label: '503316',
        desc: 'SUBSCRIBE_CANNOT_FIND_CARDINFO',
    },
    {
        code: 503060,
        label: '503060',
        desc: 'SUBSCRIBE_ALREADY_CANCEL',
    },

    //공급사관리
    {
        code: 180001,
        label: '180001',
        desc: 'PROVIDER_EXIST_VEHICLE',
    },

    //위탁사관리
    {
        code: 610001,
        label: '610001',
        desc: 'BUSINESS_PARTNER_EXIST_MAINTENANCE',
    },

    // 정산
    {
        code: 757501,
        label: '757501',
        desc: 'MONTHCLAIM_NOT_CANCELED_ALEADY_PAYMENT',
    },
    {
        code: 729999,
        label: '729999',
        desc: 'ERROR_CODE_PROCESS_CALCULATION_PAYMENT_RESULT_FAIL',
    },
];

export const getErrorMessage = (code) => {
    const findItem = ErrorCode.find(
        (item) => parseInt(code) === parseInt(item.code),
    );
    return (findItem && parseInt(findItem.label)) || 'Message.Default.Error';
};
