import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useIntl } from 'react-intl';

import { TextOrDateRangePicker as MuxTextOrDateRangePicker } from 'mux';

const TextOrDateTimeRangePicker = (props) => {
    const {
        disable,
        required,
        validate,
        timeFormat,
        placeholders,
        placeholderIds,
        timeProps,
        ...rest
    } = props;

    const intl = useIntl();

    const periodOptions = useMemo(() => {
        return [
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Today',
                }),
                value: 'TODAY',
            },
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Week.1',
                }),
                value: '1W',
            },
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Month.1',
                }),
                value: '1M',
            },
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Month.3',
                }),
                value: '3M',
            },
            {
                label: intl.formatMessage({
                    id: 'Btn.Date.Month.6',
                }),
                value: '6M',
            },
        ];
    }, [intl]);

    // placeholder
    const placeholderMessage = useMemo(() => {
        if (!_.isEmpty(placeholderIds)) {
            return _.map(placeholderIds, (placeholderId) =>
                intl.formatMessage({ id: placeholderId }),
            );
        }
        return placeholders;
    }, [intl, placeholderIds, placeholders]);

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [intl, required, validate]);

    return (
        <MuxTextOrDateRangePicker
            {...rest}
            {...rules}
            disabled={disable}
            placeholder={placeholderMessage}
            periodOptions={periodOptions}
            showTime={{
                format: timeFormat,
                ...timeProps,
            }}
        />
    );
};

TextOrDateTimeRangePicker.propTypes = {
    format: PropTypes.string,
    disable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool),
    ]),
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    placeholders: PropTypes.arrayOf(PropTypes.string),
    placeholderIds: PropTypes.arrayOf(PropTypes.string),
    timeFormat: PropTypes.string,
    timeProps: PropTypes.object,
    exportFormat: PropTypes.string, // longtime | moment | format
};

TextOrDateTimeRangePicker.defaultProps = {
    format: 'YYYY-MM-DD HH:mm:ss',
    timeFormat: 'HH:mm:ss',
    separator: '~',
    disable: false,
    required: false,
    exportFormat: 'longtime',
    placeholderIds: [
        'Common.StartDateTime.Placeholder',
        'Common.EndDateTime.Placeholder',
    ],
};

export default TextOrDateTimeRangePicker;
