import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import { Else, If, Then } from 'react-if';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppRoot, Form } from 'component/mux';
import { findMenuUrl } from 'config/routes';
import BusinessTextOrSelect from 'component/textOrSelect/BusinessTextOrSelect';
import { INPUT_WIDTH_CLASS, STORAGE_KEY } from 'util/pageUtil';
import useAuthentication, {
    excludePath,
    ROLE,
    MEMBER_ROLE,
} from 'util/authentication';

// menuUrl 메뉴 대표 URL
// pathname 현재 page URL
const SubMenuItem = ({ menu, menuUrl, isBusinessManager }) => {
    const intl = useIntl();
    const { current, setCurrent } = useAppRoot();

    useEffect(() => {
        if (!menu?.length || !menuUrl) return;

        const fMenu = menu.find(
            (item) =>
                item.menuUrl &&
                (_.isArray(menuUrl)
                    ? menuUrl.includes(item.menuUrl)
                    : menuUrl === item.menuUrl),
        );

        if (fMenu?.ID) setCurrent(fMenu);
    }, [menuUrl, menu]);

    if (!menu?.length) return <></>;
    return (
        <ul>
            {menu.map((elem, index) => {
                if (elem?.inquiryYn !== 'Y') {
                    return <></>;
                }
                const { msgKey, name, businessMenuName, businessMsgKey } = elem;
                let menuName;

                if (isBusinessManager && (businessMsgKey || businessMenuName)) {
                    menuName = intl.formatMessage({
                        id: businessMsgKey || businessMenuName,
                        defaultMessage: businessMenuName,
                    });
                } else {
                    menuName = intl.formatMessage({
                        id: msgKey || name,
                        defaultMessage: name,
                    });
                }

                return (
                    <li
                        key={`SubMenuItem-${elem?.ID}`}
                        className={current?.ID === elem?.ID ? 'active' : ''}
                    >
                        <If condition={elem?.menuUrl}>
                            <Then>
                                <Link
                                    to={{
                                        pathname: '/reload',
                                        state: {
                                            location: {
                                                pathname: elem?.menuUrl,
                                            },
                                        },
                                    }}
                                >
                                    <span>{menuName}</span>
                                </Link>
                            </Then>
                            <Else>
                                <a href="#">
                                    <span>{menuName}</span>
                                </a>
                            </Else>
                        </If>
                    </li>
                );
            })}
        </ul>
    );
};

const MenuItem = ({ element, pathname, menuUrl, isBusinessManager }) => {
    const { setCurrent } = useAppRoot();
    const intl = useIntl();

    const [selected, setSelected] = useState();
    const [menuName, setMenuName] = useState();

    useEffect(() => {
        setSelected();

        if (_.isEmpty(element)) {
            return;
        }

        const { msgKey, name, businessMenuName, businessMsgKey } = element;
        if (isBusinessManager && (businessMsgKey || businessMenuName)) {
            setMenuName(
                intl.formatMessage({
                    id: businessMsgKey || businessMenuName,
                    defaultMessage: businessMenuName || name,
                }),
            );
        } else {
            if (msgKey) {
                setMenuName(
                    intl.formatMessage({
                        id: msgKey || name,
                        defaultMessage: name,
                    }),
                );
            }
        }

        if (element?.menuUrl && element?.menuUrl === menuUrl) {
            setSelected(element);
            setCurrent(element);
            return;
        }
        if (!element?.child?.length) {
            return;
        }
        const isSelected = element?.child
            .map((item) => item.menuUrl)
            .includes(menuUrl);

        if (isSelected) {
            setSelected(element);
        }
    }, [menuUrl]);

    return (
        <>
            <li
                key={`MenuItem-${element?.ID}`}
                className={selected?.ID ? 'open active' : ''}
            >
                <If condition={element?.menuUrl}>
                    <Then>
                        <Link
                            to={{
                                pathname: '/reload',
                                state: {
                                    location: {
                                        pathname: element?.menuUrl,
                                    },
                                },
                            }}
                        >
                            <span>{menuName}</span>
                        </Link>
                    </Then>
                    <Else>
                        <a href="#">
                            <span>{menuName}</span>
                        </a>
                    </Else>
                </If>
                <SubMenuItem
                    menu={element?.child}
                    pathname={pathname}
                    menuUrl={menuUrl}
                    isBusinessManager={isBusinessManager}
                />
            </li>
        </>
    );
};

const SideNavigationBar = ({ menu, pathname, menuUrl, isBusinessManager }) => {
    const disCardNonScreenMenu = _.reduce(
        menu,
        (nArray, current) => {
            const { menuType } = current;
            if (menuType === 'SCREEN') {
                nArray.push(current);
            }
            return nArray;
        },
        [],
    );

    const reOrderMenu = (parent, menu) => {
        const subMenu = _.filter(menu, (elem) => {
            const { parentID } = elem;
            return parent === parentID;
        });
        if (_.isEmpty(subMenu)) {
            return subMenu;
        }
        _.forEach(subMenu, (elem) => {
            const { ID } = elem;
            const r = reOrderMenu(ID, menu);
            if (!_.isEmpty(r)) {
                Object.assign(elem, { child: r });
            }
        });
        return subMenu;
    };

    const reOrderedMenu = reOrderMenu(0, disCardNonScreenMenu);

    return (
        <div className="menuList">
            <ul>
                {reOrderedMenu.map((elem, index) => {
                    return elem?.inquiryYn !== 'Y' ? (
                        <></>
                    ) : (
                        <MenuItem
                            element={elem}
                            pathname={pathname}
                            menuUrl={menuUrl}
                            key={`SideNavigationBar-${elem?.ID}`}
                            isBusinessManager={isBusinessManager}
                        />
                    );
                })}
            </ul>
        </div>
    );
};

const LeftNavigationBar = ({ history, location }) => {
    const { session, menu, signIn, current } = useAppRoot();
    const { defaultUrl, setStorage } = useAuthentication();
    const routeMenuUrl = findMenuUrl(location?.pathname);

    const { activationStatusCd, role, roleInfo } = session;
    const redirectUrl = defaultUrl(activationStatusCd, role, roleInfo);

    /**
     * 페이지 최초 로드시 onChangeValue 가 바로 동작하지 않음으로, 최초 로드시 페이지 이동 미동작함
     * 페이지 최초 로드가 아닌경우 LNB 변경할경우 항상 List페이지로 이동
     */
    const onChangeValue = (item) => {
        signIn((prev) => ({
            ...prev,
            myBusiness: item,
        }));

        // Lbn 회원사를 변경했고, 현재 URL 이 List 페이지면 페이지 이동이 없으니 setStorage 를 설정해 SearchForm.js 컴포넌트 lnbChangeYn 를 통하여 강제 리로드 시킨다.
        if (routeMenuUrl === location?.pathname) {
            setStorage(STORAGE_KEY.LNB_CHANGE, 'Y');
        }

        // Lbn 변경시 무조건 List 페이지로 이동
        history.push({ pathname: routeMenuUrl });
    };

    //pathname 접근권한 체크
    useEffect(() => {
        if (
            _.isEmpty(menu) ||
            _.isEmpty(session) ||
            _.isEmpty(history?.location)
        ) {
            return;
        }

        // 현재 url
        const pathname = history?.location?.pathname;

        // 로그인시 사용하는 defaultUrl
        const defaultMoveUrl = defaultUrl(
            session?.activationStatusCd,
            session?.role,
            session?.roleInfo,
        );

        // 현재 url 에 해당하는 menu 권한
        const permitMenu = _.find(menu, {
            menuUrl: pathname,
        });

        // 체크 예외 url 확인
        if (excludePath.includes(pathname)) {
            return;
        }

        // 현재 페이지가 defaultUrl 과 같다면 권한체크를 하여도 이동해야할 페이지가 없음으로 권한체크 하지 않음
        // 권한이 없는상태에서 이 케이스인 경우 루프가 발생함으로 루프 방지 역할도 같이 수행
        if (pathname === defaultMoveUrl) {
            return;
        }

        if (!_.isEmpty(permitMenu)) {
            // menu 에서 관리중인 권한인 경우 권한 체크 실행
            if (permitMenu?.inquiryYn !== 'Y') {
                history.push(defaultMoveUrl);
                return;
            }
        } else {
            // menu 에서 관리중인 권한이 아닌경우 (signOut, myInfo, Deatil 페이지 등은 menu 에서 관리하지 않음)
            // 슈퍼관리자, 부서담당자 체크하여 current 가 없으면 URL 로 Direct 접근으로 간주하고 튕김
            // current 가 없는경우 : Lnb 에서선택한 페이지가 없음 or OpenWindow 로 접근하지 않음
            if (
                [ROLE.businessManager, ROLE.businessOperator].includes(
                    session?.role,
                ) &&
                _.isEmpty(current)
            ) {
                const routePermitMenu = _.find(menu, {
                    menuUrl: routeMenuUrl,
                    inquiryYn: 'Y',
                });

                if (_.isEmpty(routePermitMenu)) {
                    history.push(defaultMoveUrl);
                } else {
                    history.push(routeMenuUrl);
                }

                return;
            }
        }
    }, [session, history?.location, menu, current]);

    return (
        <>
            {/* <button type="button" className="lnbHidden"></button> */}
            <h1 className="logo">
                <Link
                    to={{
                        pathname: redirectUrl,
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/image/common/logo.png`}
                        alt="wible BIZ"
                    />
                    {MEMBER_ROLE.includes(session?.role) ? (
                        <span className="txtSmall">
                            <FormattedMessage id="Intro.Title.Client.Admin.2" />
                        </span>
                    ) : (
                        <span className="txtLarge">
                            <FormattedMessage id="Intro.Title.Admin.2" />
                        </span>
                    )}
                </Link>
            </h1>
            <If
                condition={
                    session?.activationStatusCd === 'NORMAL' &&
                    ['B2BG'].includes(session?.roleInfo.systemType)
                }
            >
                <Then>
                    <div className="companySelect">
                        <div className="tabWrap wbTabWrap">
                            <div className="wbTabContent">
                                <div className="tabBox tabActive">
                                    <span
                                        className="wbSelect"
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        <Form
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <BusinessTextOrSelect
                                                showSearch
                                                baseOption={{
                                                    resId: 'Common.MobilityManager',
                                                    value: '',
                                                }}
                                                includeAll
                                                onChangeValue={onChangeValue}
                                                listItemHeight={10}
                                                listHeight={250}
                                                className={
                                                    INPUT_WIDTH_CLASS.FULL
                                                }
                                            />
                                        </Form>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Then>
            </If>
            <div className="menu">
                <If condition={session?.activationStatusCd === 'NORMAL'}>
                    <Then>
                        <SideNavigationBar
                            menu={menu}
                            pathname={location?.pathname}
                            menuUrl={routeMenuUrl}
                            filter={'ALL'}
                            isBusinessManager={[
                                'BUSINESS_MANAGER',
                                'BUSINESS_OPERATOR',
                            ].includes(session?.role)}
                        />
                    </Then>
                </If>
            </div>
        </>
    );
};

export default withRouter(LeftNavigationBar);
