const language = {
    'Common.All': 'Все',
    'Common.Login': 'вход',
    'Common.Logout': 'Выход',
    'Common.Logout.Hello': 'Привет, {user}.',
    'Common.Input.Required': 'Обязательное значение.',
    'Common.Input.EnterSearchKeyword': 'Введите ключевые слова',
    'Common.Btn.Selected': 'Выбрано',
    'Common.Btn.Select': 'Выбрать',
    'Common.Btn.AllSearch': 'Полный поиск',
    'Common.Btn.Cancel': 'Отмена',
    'Common.Btn.CancelEdit': 'Отмена',
    'Common.Btn.CancelExtend': 'Отмена',
    'Common.Btn.Filter': 'Опция',
    'Common.Btn.Create': 'Регистрация',
    'Common.Btn.Edit': 'Изменить',
    'Common.Btn.EditInfo': 'Изменить информацию',
    'Common.Btn.Confirm': 'ОК',
    'Common.Btn.EditBusiness': 'Изменить',
    'Common.Btn.EditInformation': 'Изменить',
    'Common.Btn.CompleteReservation': 'Бронирование завершено',
    'Common.Btn.CompleteEdit': 'Изменение завершено',
    'Common.Btn.Add': 'Добавить',
    'Common.Btn.Delete': 'Удалить',
    'Common.Btn.InUse': 'Использовать',
    'Common.Btn.List': 'Список',
    'Common.Btn.Return': 'Вернуться',
    'Common.Btn.ForceEdit': 'Принудительное изменение',
    'Common.Btn.ExtendReturn': 'Продлить дату возврата',
    'Common.Btn.CompleteExtend': 'Продлить дату возврата',
    'Common.Btn.ForceReturn': 'Принудительный возврат',
    'Common.Btn.CancelReservation': 'Отменить бронь',
    'Common.Btn.Approve': 'Одобрить',
    'Common.Btn.Deny': 'Отклонить',
    'Common.Btn.Map': 'Выбрать на карте',
    'Common.Btn.SearchWeek': '1 неделя',
    'Common.Btn.Search1Month': '1 месяц',
    'Common.Btn.Search3Month': '1 месяц',
    'Common.Btn.Show.Edit': 'Редактировать',
    'Common.Btn.AddVehicle': 'Добавить автомобиль',
    'Common.Btn.AllSelect': 'Выбрать все',
    'Common.Btn.Apply': 'Применить',
    'Common.Btn.MultiSelected': 'Можно выбрать несколько',
    'Common.Confirm.Create': 'Зарегистрировать?',
    'Common.Confirm.Update': 'Изменить?',
    'Common.Confirm.Delete': 'Удалить?',
    'Common.Confirm.Deletes': 'Удалить {count} элементов?',
    'Common.Confirm.Approve': 'Одобрить?',
    'Common.Confirm.Deny': 'Отклонить?',
    'Common.Confirm.Action': 'Выполнить функцию?',
    'Common.Confirm.UserDelete': 'Отменить ваш статус участника?',
    'Common.Confirm.PwdChange': 'Изменить пароль?',
    'Common.Confirm.InUse': 'Изменить статус автомобиля на "Используется"?',
    'Common.Alert': 'Невозможно выполнить функцию.',
    'Common.Created.Success': 'Зарегистрировано.',
    'Common.Updated.Success': 'Изменено.',
    'Common.Deleted.Success': 'Удалено.',
    'Common.Create.Fail': 'Зарегистрировать невозможно.',
    'Common.Update.Fail': 'Изменить невозможно.',
    'Common.Delete.Fail': 'Удалить невозможно.',
    'Common.Search.Fail': 'Ошибка поиска.',
    'Common.Print': 'Печать',
    'Common.Alert.Delete.No.Checked': 'Выберите элемент для удаления.',
    'Common.Alert.Approve.No.Checked': 'Выберите элемент для одобрения.',
    'Common.Alert.Deny.No.Checked': 'Выберите элемент для отклонения.',
    'Common.Alert.Pay.No.Checked': 'Выберите элемент для оплаты.',
    'Common.Msg.NoData': 'Данные не получены.',
    'Common.Msg.NoSearchData': 'Результаты поиска отсутствуют.',
    'Common.Msg.NoVehicle': 'Автомобиль не существует.',
    'Common.Unit.Distance.meter': 'm',

    'Common.Unit.Money.0': 'RUB',
    'Common.Unit.Money.10000': '0000RUB',
    'Common.Unit.Money.0.Value': '{value} RUB',
    'Common.Unit.Vehicle.Count': 'автомобили',
    'Common.Unit.Vehicle.Count.Value': '{value} автомобили',
    'Common.Unit.Hour': 'ч.',
    'Common.Unit.Hour.Value': '{hours} ч.',
    'Common.Unit.Minute': 'минут(ы)',
    'Common.Unit.Minute.Value': '{value} минут(ы)',
    'Common.Unit.Age.Over': 'года (лет) или старше',
    'Common.Unit.DiffDays.DaysHours.Valule': '{days} дн. {hours} ч.',
    'Common.Unit.DiffDays.Days.Valule': '{days} дн.',
    'Common.Unit.DiffDays.Hours.Valule': '{hours} ч.',
    'Common.Today': 'Сегодня',
    'Common.Back': 'Предыдущий',
    'Common.BirthDate.Year': 'Год',
    'Common.BirthDate.Month': 'Месяц',
    'Common.BirthDate.Day': '{days} дн.',
    'Common.Role.Warn': 'Не разрешено.',
    'Common.Validator.Help.Number': 'Вводите только числа.',
    'Common.Validator.Help.Email': 'Введите адрес в допустимом формате.',
    'Common.Validator.Help.MaxNumber':
        'Можно ввести {compareValue} или меньше.',
    'Common.Validator.Help.MinNumber':
        'Можно ввести {compareValue} или больше.',

    'Main.Title':
        'Сравните условия проката на короткий,{br}средний и длительный срок,{br}и бронируйте автомобиль удобным вам образом.',
    // 'Main.SubTitle': ' ',
    'Main.GettingStart': 'Начало работы',

    'Footer.Policy1': 'Условия предоставления услуг',
    'Footer.Policy2': 'Соглашением о конфиденциальности',
    'Footer.Mocean': 'MOCEAN',
    'Footer.Address':
        '907-ho, A-dong, Bundang-Suji U-TOWER, 767, Sinsu-ro, Suji-gu, Yongin-si, Gyeonggi-do, Republic of Korea',

    'DayTimePicker.Help.From.Selected': 'Выберите дату начала.',
    'DayTimePicker.Help.From.After.Now':
        'Дата начала должна быть ранее текущей.',
    'DayTimePicker.Help.To.Selected': 'Выберите дату окончания.',
    'DayTimePicker.Help.To.Before.From':
        'Дата окончания должна быть позже даты начала.',

    'Login.Alert.SystemError': 'Временный сбой системы',
    'Auth.Check.Alert.SystemError':
        'Вы выполнили вход из неправильного места. Войдите в систему снова.',
    'Join.Alert.SystemError': 'Войдите в систему позже.',

    'Auth.Alert.Expired':
        'Вы вышли из системы в результате окончания сеанса. Войдите в систему снова.',
    'Auth.Alert.Forbidden': 'Не разрешено.',
    'Auth.Wait': 'Пожалуйста, подождите!',

    'Menu.VehicleManager': 'Управление парком',
    'Menu.ReservationManager': 'Управление бронированием',
    'Menu.VehicleUseManager': 'завершении проката',
    'Menu.PaymentManager': 'Управление продажами и расчетами',
    'Menu.Location': 'Управление базой',
    'Menu.Terminal': 'Управление терминалом',
    'Menu.User': 'Управление пользователями',
    'Menu.MyPage': 'Моя страница',

    'Title.Main': 'Главная',
    'Title.VehicleMonitoring': 'Управление автомобилем',
    'Title.VehicleManager': 'Управление парком',
    'Title.Vehicle': 'Просмотреть/зарегистрировать автомобиль',
    'Title.VehicleModel': 'Управление моделью автомобиля',
    'Title.Terminal': 'Управление терминалом',
    'Title.ReservationManager': 'Управление бронированием',
    'Title.ReservationList': 'См. подробности бронирования',
    'Title.ReservationCreate': 'Новое бронирование',
    'Title.MakeReservation.BUSINESS_CORP': 'Новое бронирование (партнер)',
    'Title.UseList': 'Статус использования',
    'Title.EndUseList': 'завершении проката',
    'Title.PaymentList': 'Информация платежа',
    'Title.Charge': 'Тарифный план',
    'Title.ChargeVehicle': 'Управление тарифным планом по автомобилям',
    'Title.UseStateList': 'Статус проката',
    'Title.Insurance': 'Страховой платеж',
    'Title.InsuranceVehicle': 'Управление страховыми платежами по автомобилям',
    'Title.PenaltyAndToll': 'Штраф/плата за проезд',
    'Title.Location': 'База',
    'Title.LocationCreate': 'Регистрация базы',
    'Title.LocationEdit': 'Изменение базы',
    'Title.Member': 'Менеджер',
    'Title.Business': 'Управление операторами',
    'Title.BusinessCreate': 'Регистрация оператора',
    'Title.Customer': 'Управление статусом участника',

    'Monitoring.LatestUpdate': 'Последнее обновление',
    'Monitoring.ViewUnavailableVehicle': 'См. автомобиль с проблемами',
    'Monitoring.ViewAllVehicle': 'См. все автомобили',
    'Monitoring.CurrentLocation': 'Текущее положение',
    'Monitoring.Location': 'Положение базы',
    'Monitoring.SeeMore': 'Показать больше',
    'Monitoring.NoUnavailableVehicle': 'Автомобилей с проблемами нет.',
    'Monitoring.Vehicle.DTE': 'Уровень топлива',
    'Monitoring.Vehicle.AUXbattery': 'Напряжение аккумулятора',
    'Monitoring.Vehicle.Connectivity': 'Состояние связи',
    'Monitoring.Unknown': 'Неизвестно',
    'Monitoring.Normal': 'обычный',

    'Reservation.Btn.Create': 'Новое бронирование',
    'Reservation.Btn.Filter': 'Опция',
    'Reservation.Btn.Selected': 'Бронирование завершено',
    'Reservation.Code': '№ бронирования',
    'Reservation.Birth': 'Дата рождения',
    'Reservation.Birth.Placeholder': 'YYYY.MM.DD',
    'Reservation.BirthYear.Placeholder': '2000',
    'Reservation.BirthMonth.Placeholder': '0',
    'Reservation.BirthDate.Placeholder': '0',
    'Reservation.Address': 'Адрес',
    'Reservation.Address.Zip.Placeholder': 'Индекс',
    'Reservation.Address.Placeholder': 'Введите адрес.',
    'Reservation.DetailAddress.Placeholder': 'Введите улицу и № дома.',
    'Reservation.Address.Search': 'Поиск индекса',
    'Reservation.LicenseInfo': 'Информация о водит. удостоверении',
    'Reservation.License': '№ водит. удостоверения',
    'Reservation.License.Placeholder': '№ водит. удостоверения',
    'Reservation.License.Confirm': 'Проверка водит. удостоверения',
    'Reservation.License.ConfirmFinish': 'Проверка выполнена',
    'Reservation.License.DueDate': 'Срок действия водит. удостоверения',
    'Reservation.License.DueDate.DayPicker':
        'Введите срок действия водит. удостоверения.',
    'Reservation.License.Check': 'Пройдите проверку водит. удостоверения.',
    'Reservation.License.AdditionalCheck':
        'Пройдите проверку удостоверения второго водителя.',
    'Reservation.License.ValidCheck':
        'Для проверки водит. удостоверения заполните все поля.',
    'Reservation.Edit.Warn':
        'После времени начала бронирования изменить его нельзя.',
    'Reservation.GetProfile.NoData':
        'Данные о соответствующем участнике не найдены',
    'Reservation.GetEmployeeProfile.NoData': 'Информация сотрудника не найдена',
    'Reservation.GetProfile.fail': 'Сбой поиска информации участника.',
    'Reservation.Location.NotMatch':
        'Возврат невозможен, так как место возврата не совпадает.',
    'Reservation.RemoteControl.Fail':
        'Ошибка управления автомобилем. Вернуть автомобиль невозможно.',
    'Reservation.PaymentDetail.IsNull': 'Нет тарифного плана для изменения.',
    'Reservation.Date.IsEqual':
        'Дата окончания бронирования должна быть позже даты начала.',
    'Reservation.Date.Invalid':
        'Дата начала бронирования не может быть раньше, чем сегодня.',
    'Reservation.AlreadyUseCarExist.Fail':
        'Уже есть используемый автомобиль. Бронирование невозможно.',
    'Reservation.VehicleUsePay.IsZero':
        'Ваш план не существует или установлен на 0 RUB.',
    'Reservation.Extend.CheckEndDate':
        'Продленная дата возврата должна быть позже даты окончания бронирования.',
    'Reservation.Deny.DefaultMessage': 'Введите информацию.',
    'Reservation.TotalPay.Changed': 'Тариф изменился.',
    'Reservation.Insurance.Invalid':
        'Бронирование невозможно, так как завершилось действие страховки.',
    'Reservation.InsuranceEdit.Invalid':
        'Изменить бронирование невозможно, так как завершилось действие страховки.',
    'Reservation.UserNameInfo': 'Информация заявителя бронирования',
    'Reservation.UserName': 'Заявитель бронирования',
    'Reservation.Name': 'ФИО',
    'Reservation.CustName': 'ФИО клиента',
    'Reservation.Status': 'Статус',
    'Reservation.ReservationStatus': 'Статус бронирования',
    'Reservation.UserName.Placeholder': 'Введите ФИО заявителя бронирования.',
    'Reservation.UserName.Confirm': 'Найти существующих участников',
    'Reservation.UserName.ReConfirm': 'Изменить заявителя бронирования',
    'Reservation.UserInfo.Search': 'Найти информацию об участнике',
    'Reservation.UserInfo.Confirm': 'Проверить информацию об участнике',
    'Reservation.Employee.Confirm': 'Найти сотрудника',
    'Reservation.EmployeeInfo.Search': 'Найти информацию о сотруднике',
    'Reservation.EmployeeInfo.Confirm': 'Проверить информацию о сотруднике',
    'Reservation.Share.UserName': 'Второй водитель',
    'Reservation.Share.UserName.Placeholder': 'Введите ФИО второго водителя.',
    'Reservation.Mobile': '№ телефона',
    'Reservation.Mobile.Placeholder': '№ телефона',
    'Reservation.Team': 'Команда',
    'Reservation.Business': 'Организация',
    'Reservation.Team.Placeholder': 'Команда',
    'Reservation.CampanyNumber': '№ сотрудника',
    'Reservation.CampanyNumber.Placeholder': '№ сотрудника',
    'Reservation.EmailAddress': 'Адрес эл. почты',
    'Reservation.EmailAddress.Placeholder': 'Введите адрес эл. почты',
    'Reservation.Insurance.Fee': 'Страховой платеж',
    'Reservation.Rent.Fee': 'Плата за прокат',
    'Reservation.BusinessInfo': 'Выбрать оператора',
    'Reservation.Business.Rent': 'Общий',
    'Reservation.Business.Corp': 'Корпорация',
    'Reservation.VehicleInfo': 'Информация об автомобиле',
    'Reservation.VehicleUseFeeInfo': 'Сбор за использование автомобиля',
    'Reservation.UseFeeInfo': 'Сбор за использование',
    'Reservation.VehicleModel': 'Тип автомобиля',
    'Reservation.BusinessName': 'Название компании',
    'Reservation.DateRange': 'Период бронирования',
    'Reservation.RentalPeriod': 'Период проката',
    'Reservation.MobileReservation': 'Мобильное бронирование',
    'Reservation.Date': 'Дата/время бронирования',
    'Reservation.DateRange.Placeholder.Start': 'Дата начала',
    'Reservation.DateRange.Placeholder.End': 'Дата окончания',
    'Reservation.InsuranceAge': 'Страховой возраст',
    'Reservation.InsuranceAge.Placeholder':
        'Страховка водителя возрастом 21 и более лет',
    'Reservation.UseFee': 'Сбор за использование автомобиля',
    'Reservation.ExtendFee': 'Сбор за продление',
    'Reservation.UseFee.Result': 'Сбор за пробег {fee} RUB/км',
    'Reservation.StartLocation': 'Место доставки',
    'Reservation.StartLocation.Placeholder':
        'Выберите место доставки автомобиля',
    'Reservation.StartLocation.Button': 'Выбрать на карте',
    'Reservation.VehicleNumber': '№ автомобиля',
    'Reservation.DenyReson': 'Причина отклонения',
    'Reservation.LicenseType': 'Тип водит. удостоверения',
    'Reservation.VehicleNumber.Placeholder': 'Выберите автомобиль.',
    'Reservation.UseFeeInfo.Placeholder': '0',
    'Reservation.UseFee.Placeholder': '0',
    'Reservation.ExtendFee.Placeholder': '0',
    'Reservation.Confirm.ExtendFee': 'Введите сбор за продление.',
    'Reservation.BusinessType.Placeholder': 'Выберите оператора.',
    'Reservation.Confirm.Return': 'Желаете вернуть автомобиль?',
    'Reservation.Confirm.ForceReturn':
        'Желаете вернуть автомобиль принудительно?',
    'Reservation.Confirm.Extend': 'Желаете продлить дату возврата?',
    'Reservation.Confirm.Cancel': 'Желаете отменить бронирование?',
    'Reservation.Confirm.Confirm': 'Одобрить?',
    'Reservation.Confirm.Deny': 'Отклонить?',
    'Reservation.DenyReason.Confirm': 'Введите причину возврата.',
    'Reservation.Return.Complete': 'Возврат выполнен.',
    'Reservation.ForceReturn.Complete': 'Принудительный возврат выполнен.',
    'Reservation.Extend.Complete': 'Дата возврата продлена.',
    'Reservation.Confirm.Complete': 'Бронирование одобрено.',
    'Reservation.Cancel.Complete': 'Бронирование отменено.',
    'Reservation.Deny.Complete': 'Бронирование отклонено.',
    'Reservation.Delete.Complete': 'Бронирование удалено.',
    'Reservation.Update.Complete': 'Бронирование изменено.',
    'Reservation.Create.Complete': 'Бронирование зарегистрировано.',
    'Reservation.InUse.Complete':
        'Статус бронирования изменен на "Используется".',
    'Reservation.InsruanceAge.ConfirmFail':
        'Выбранный автомобиль нельзя забронировать из-за возраста заявителя бронирования.',
    'Reservation.AdditionalInsruanceAge.ConfirmFail':
        'Выбранный автомобиль нельзя забронировать из-за возраста второго водителя.',
    'Reservation.LicenseType.Placeholder': 'Тип водит. удостоверения',
    'Reservation.InsuranceList': 'Тип страховки',
    'Reservation.InsuranceList.Placeholder': 'Выберите тип страховки.',
    'Reservation.Complete.NameMobileIsNull':
        'Введите заявителя бронирования и № телефона.',
    'Reservation.Extend.AlreadyReserved':
        'Забронированный вами автомобиль доступен в это время.',
    'Reservation.InsuranceAge.IsOver': '{insuranceAge} года (лет) или старше',
    'Reservation.List.Fail': 'Невозможно получить информацию бронирования.',
    'Reservation.Approve.Fail': 'Ошибка авторизации.',
    'Reservation.Deny.Fail': 'Ошибка возврата.',
    'Reservation.GetRentCar.Fail':
        'Невозможно получить список прокатных компаний.',
    'Reservation.EndList.Fail':
        'Невозможно получить информацию о завершении проката.',
    'Reservation.GetAvailable.Fail':
        'Невозможно получить список доступных автомобилей.',
    'Reservation.GetLocation.Fail': 'Невозможно получить список баз.',
    'Reservation.MustHave.Fail': 'Заполните все обязательные поля.',
    'Reservation.AddReservation.Fail': 'Сбой регистрации бронирования.',
    'Reservation.AlreadyReserved.Fail': 'Автомобиль уже забронирован.',
    'Reservation.GetUseList.Fail': 'Невозможно получить список использования.',
    'Reservation.GetTimeList.Fail': 'Невозможно получить список использования.',
    'Reservation.Return.Fail': 'Вернуть автомобиль невозможно.',
    'Reservation.Return.StartFail':
        'Автомобиль нельзя вернуть раньше времени начала бронирования.',
    'Reservation.ForceReturn.Fail': 'Сбой принудительного возврата автомобиля.',
    'Reservation.ForceReturn.StartFail':
        'Автомобиль нельзя вернуть раньше времени начала бронирования.',
    'Reservation.Cancel.Fail': 'Бронирование отменить нельзя.',
    'Reservation.Inuse.Fail':
        'Невозможно изменить статус автомобиля на "Используется".',
    'Reservation.InUse.TimeIsInvalid':
        'Статус автомобиля можно изменить на "Используется" за 15 минут до времени начала.',
    'Reservation.Delete.Fail': 'Бронирование удалить нельзя.',
    'Reservation.Extend.Fail': 'Дату возврата продлить нельзя.',
    'Reservation.Update.Fail': 'Бронирование изменить нельзя.',
    'Reservation.LicenseCheck.Fail': 'Ошибка проверки водит. удостоверения.',
    'Reservation.LicenseCheck.Invalid':
        'Неверная информация водит. удостоверения. Проверьте информацию еще раз.',
    'Reservation.LicenseExpiryDate.InValid':
        'Дата начала проката позже срока действия водит. удостоверения.',
    'Reservation.LicenseExpiryDate.AddTimeIsInvalid':
        'Дата начала проката позже срока действия водит. удостоверения второго водителя.',
    'Reservation.GetPaymentDetail.Fail': 'Сбой получения информации тарифа.',
    'Reservation.GetInsuranceList.Fail': 'Сбой получения списка страховок.',
    'Reservation.View.Fail': 'Сбой получения информации бронирования.',
    'Reservation.CurrentVehicle.AlreadyReserved':
        'Автомобиль уже забронирован.',
    'Reservation.Edit.NotAvailable': 'Бронирование изменить невозможно.',
    'Reservation.OfflinePayment.Notify':
        'Сбор за использование нельзя оплатить через Интернет.',
    'Reservation.DenyReason.Placeholder': 'Введите причину возврата.',
    'Reservation.Payment.NotMinTime':
        'Период бронирования короче минимального периода использования.',
    'Reservation.Payment.NotMinUseTime':
        'Продленный период короче минимального периода использования.',
    'Reservation.Payment.NoCharge':
        'Тарифный план для автомобиля не установлен.',
    'Reservation.Payment.NoInsurance':
        'Страховой платеж для автомобиля не установлен.',

    'Reservation.Filter.LicensePlateNumber': '№ автомобиля',
    'Reservation.Filter.Code': '№ бронирования',
    'Reservation.Filter.User.Name': 'ФИО заявителя бронирования',
    'Reservation.Filter.User.MobileNumber': '№ телефона заявителя бронирования',
    'Reservation.Filter.Location.Name': 'Название базы',
    'Reservation.Filter.Business.Name': 'Название корпорации',

    'Reservation.DayTimePicker.Title': 'Выберите дату начала проката.',
    'Rerservation.DayPicker.Title': 'Введите дату начала проката и возврата.',
    'Rerservation.DayPicker.StartTime': 'Дата начала проката',
    'Rerservation.DayPicker.EndTime': 'Дата возврата',
    'Rerservation.Btn.All.Confirm': 'Полное одобрение',
    'Rerservation.Btn.Confirm': 'Одобрить',
    'Rerservation.Btn.Deny': 'Отклонить',
    'Reservation.DateFormat.Placeholder': 'YYYY.MM.DD',

    'UseStateCalendar.No': 'NO.',
    'UseStateCalendar.LicensePlateNumber': '№ автомобиля',

    'ReservationCalendar.Calendar.Item.Label.requested':
        'Ожидает одобрения:  {count}',
    'ReservationCalendar.Calendar.Item.Label.confirmed':
        'Бронирование: {count}',
    'ReservationCalendar.Calendar.Item.Label.canceled':
        'Отменить бронь: {count}',
    'ReservationCalendar.Calendar.Item.Label.returned':
        'Подлежит возврату : {count}',
    'ReservationCalendar.Admin.Title': 'Статус бронирования',
    'ReservationCalendar.Admin.Item': '{businessName}{br}{reservationCount}',
    'ReservationCalendar.Private.Desc':
        'Бронирования, не авторизованые в период бронирования, отменяются автоматически.',
    'ReservationCalendar.Private.Requested.Title': 'Ожидает одобрения',
    'ReservationCalendar.Private.Requested.Item':
        'Ожидает одобрения : {requestedCount}',
    'ReservationCalendar.Private.Confirmed.Title': 'Статус бронирования',
    'ReservationCalendar.Private.Confirmed.Item': '{groupName} {length}',
    'ReservationCalendar.Private.Group.Confirmed.Item':
        '{licensePlateNumber}{br}Заявитель бронирования {user}',
    'ReservationCalendar.Private.Group.Requested.Item':
        '{licensePlateNumber}{br}{teamName} / {user}',
    'ReservationCalendar.Private.Confirm.Approve':
        'Одобрить отмеченные позиции?',
    'ReservationCalendar.Private.Confirm.Approve.All':
        'Предоставить полное одобрение?',
    'ReservationCalendar.Public.Confirmed.Title': 'Статус бронирования',
    'ReservationCalendar.Public.Confirmed.Item':
        '[{code}] {reservationTime}{br}{name} / {totalPay}RUB //[{code}]{reservationTime}{br}{name}/{totalPay}RUB',
    'ReservationCalendar.Public.Retruned.Item':
        '[{code}] {reservationTime}{br}{name}',
    'ReservationCalendar.Public.Returned.Title': 'Подлежит возврату',
    'ReservationCalendar.Public.Returned.Item':
        '{reservationTime}{br}{code} / {name}',
    'ReservationCalendar.Month.Get.Fail':
        'Невозможно получить статус ежемесячного бронирования.',
    'ReservationCalendar.Business.Get.Fail':
        'Невозможно получить статус бронирования по корпорации.',
    'ReservationCalendar.Public.Get.Fail':
        'Невозможно получить статус проката.',
    'ReservationCalendar.Private.Get.Fail':
        'Невозможно получить статус бронирования корпорации.',

    'Vehicle.Information': 'Информация об автомобиле',
    'Vehicle.State.Sub.Title': 'Информация об автомобиле',
    'Vehicle.Reservation.Sub.Title': 'Статус бронирования',
    'Vehicle.Model': 'Модель автомобиля',
    'Vehicle.Color': 'Цвет автомобиля',
    'Vehicle.Transmission': 'Тип коробки передач',
    'Vehicle.LicensePlateNumber': 'Регистрационный № автомобиля',
    'Vehicle.VIN': 'VIN No.',
    'Vehicle.Status': 'Статус',
    'Vehicle.ControllerID': '№ телефона терминала',
    'Vehicle.RegistrationDate': 'Дата регистрации автомобиля',
    'Vehicle.ProductionDate': 'Дата выпуска',
    'Vehicle.Options': 'Опция',
    'Vehicle.Business': 'Корпорация-владелец',
    'Vehicle.Location': 'База',
    'Vehicle.Insurance.Company': 'Страховая компания',
    'Vehicle.Insurance.Age': 'Страховой возраст',
    'Vehicle.Insurance.JoinDate': 'Дата подписки на страховку',
    'Vehicle.Insurance.ExpireDate': 'Дата окончания срока действия',
    'Vehicle.Insurance.PD': 'Лимит ответственности для имущества',
    'Vehicle.Insurance.BI': 'Лимит ответственности для людей',
    'Vehicle.Insurance.DB': 'Лимит личного страхования',
    'Vehicle.RegistrationDate.Placeholder': 'ex) 20190101',
    'Vehicle.Model.Placeholder': 'Выберите модель.',
    'Vehicle.Transmission.Placeholder': 'Выберите тип коробки передач.',

    'Vehicle.VIN.Placeholder':
        'VIN состоит из 17 символов, включая английские буквы и цифры',
    'Vehicle.VIN.Help':
        'VIN состоит из 17 символов, включая английские буквы и цифры',

    'Vehicle.ControllerID.Help': 'Номер телефона может содержать только цифры.',
    'Vehicle.Business.Placeholder': 'Выберите корпорацию.',
    'Vehicle.Location.Placeholder': 'Выберите базу.',
    'Vehicle.Options.Placeholder': 'Выберите опцию.',
    'Vehicle.Options.No.Data': 'Выберите опцию.',
    'Vehicle.Insurance.Company.Placeholder': 'Выберите страховую компанию.',
    'Vehicle.Insurance.Age.Placeholder': 'Выберите страховой возраст.',
    'Vehicle.Insurance.JoinDate.Placeholder':
        'Введите дату подписки на страховку.',
    'Vehicle.Insurance.PD.Placeholder':
        'Введите лимит ответственности для имущества.',
    'Vehicle.Insurance.BI.Placeholder':
        'Введите лимит ответственности для людей.',
    'Vehicle.Insurance.DB.Placeholder': 'Введите лимит личного страхования.',
    'Vehicle.ControllerID.Help.Already.Use':
        'Введенный № телефона терминала уже используется.',
    'Vehicle.VIN.Help.Already.Use': 'Введенный VIN уже используется.',
    'Vehicle.Btn.Controller': 'Проверить терминал',
    'Vehicle.View.Fail': 'Невозможно получить информацию автомобиля.',
    'Vehicle.Delete.Fail': 'Используемые автомобили удалить невозможно.',
    'Vehicle.Confirm.Delete': 'Удалить информацию автомобиля?',
    'Vehicle.Confirm.Deletes':
        'Удалить отмеченные позиции?{br}Используемые автомобили удалить невозможно.',

    'Vehicle.State.TpmsFl': 'Переднее колесо/Левое',
    'Vehicle.State.TpmsFr': 'Переднее колесо/Правое',
    'Vehicle.State.TpmsRl': 'Заднее колесо/Левое',
    'Vehicle.State.TpmsRr': 'Заднее колесо/Правое',
    'Vehicle.State.Tpms.Unit': 'PSI',
    'Vehicle.State.Battery': 'Напряжение аккумулятора',
    'Vehicle.State.FuelLevel': 'Уровень топлива',

    'Vehicle.Confirm.Control.Open': 'Открыть дверь?',
    'Vehicle.Confirm.Control.Close': 'Замкнуть дверь?',
    'Vehicle.Confirm.Control.Siren':
        'Включить аварийную сигнализацию/звуковой сигнал?',
    'Vehicle.Control': 'Управление автомобилем',
    'Vehicle.Control.Open': 'Открыть дверь',
    'Vehicle.Control.Close': 'Закрыть дверь',
    'Vehicle.Control.Siren': 'Аварийная сигнализация/звуковой сигнал',
    'Vehicle.Control.Open.Action': 'Включить',
    'Vehicle.Control.Close.Action': 'Включить',
    'Vehicle.Control.Siren.Action': 'Включить',
    'Vehicle.Control.Requested.Success': 'Сигнал отправлен автомобилю.',
    'Vehicle.Control.Request.Fail': 'Невозможно отправить сигнал автомобилю.',
    'Vehicle.Control.Opened.Success': 'Дверь открыта.',
    'Vehicle.Control.Closed.Success': 'Дверь замкнута.',
    'Vehicle.Control.Siren.Success':
        'Аварийная сигнализация/звуковой сигнал включен.',
    'Vehicle.Control.Fail': 'Ошибка при управлении автомобилем.',
    'Vehicle.Control.Fail.Code': 'Обратитесь к администратору. [{code}]',
    'Vehicle.Control.Timeout': 'Задержка получения результатов управления.',

    'VehicleModel.Information': 'Информация об автомобиле',
    'VehicleModel.Name': 'Наименование модели',
    'VehicleModel.Name.Placeholder': 'Введите наименование модели',
    'VehicleModel.Image.Helper':
        'Изображение автомобиля нужно зарегистрировать.',
    'VehicleModel.Brand': 'Производитель',
    'VehicleModel.Brand.Placeholder': 'Выберите производителя.',
    'VehicleModel.FuelType': 'Тип топлива',
    'VehicleModel.FuelType.Placeholder': 'Выберите тип топлива.',
    'VehicleModel.SeatingCapacity': 'Количество пассажиров',
    'VehicleModel.SeatingCapacity.Placeholder':
        'Введите количество пассажиров.',
    'VehicleModel.SeatingCapacityP': 'Количество пассажиров',
    'VehicleModel.Displacement': 'Объем двигателя (см куб.)',
    'VehicleModel.Displacement.Placeholder': 'ex) 1800',
    'VehicleModel.FuelTankCapacity': 'Объем топливного бака',
    'VehicleModel.FuelTankCapacity.Placeholder': 'ex) 70',
    'VehicleModel.FuelTankCapacityE': 'Емкость аккумулятора (кВт ч)',
    'VehicleModel.FuelEfficiency': 'Расход топлива (км/л)',
    'VehicleModel.FuelEfficiency.Helper':
        'Для расхода топлива можно ввести только целое или десятичное число, например, 12,5',
    'VehicleModel.FuelEfficiency.Placeholder': 'ex) 12.5',
    'VehicleModel.Grade': 'Класс автомобиля',
    'VehicleModel.Grade.Placeholder': 'Класс автомобиля',
    'VehicleModel.Standard': 'Главная модель',
    'VehicleModel.StandardTag': 'Главная',
    'VehicleModel.Confirm.Deletes':
        'Удалить {count} шт. моделей?{br}Зарегистрированные модели удалить невозможно.',
    'VehicleModel.GetData.Fail': 'Сбой получения модели автомобиля.',
    'VehicleModel.Create.Success': 'Модель автомобиля зарегистрирована.',
    'VehicleModel.Create.Fail': 'Сбой регистрации модели автомобиля.',
    'VehicleModel.Update.Success': 'Модель автомобиля изменена.',
    'VehicleModel.Update.Fail': 'Сбой изменения модели автомобиля.',
    'VehicleModel.UpdateImage.Fail':
        'Сбой регистрации изображения модели автомобиля.',
    'VehicleModel.Delete.Success': 'Модель автомобиля удалена.',
    'VehicleModel.Delete.Fail': 'Сбой удаления модели автомобиля.',
    'VehicleModel.Fail.DuplicatedModelName':
        '모델명 중복으로 기능을 수행할 수 없습니다.',

    'Terminal.Title.Create': 'Зарегистрировать терминал',
    'Terminal.Title.Info': 'Информация терминала',
    'Terminal.Vehicle.LicensePlateNumber': '№ автомобиля',
    'Terminal.VIN': 'VIN No.',
    'Terminal.NadID': '№ телефона',
    'Terminal.Serial': 'Serial No.',
    'Terminal.Manufacturer': 'Производитель',
    'Terminal.Version': 'S/W Ver.',
    'Terminal.OrderDate': 'Дата заказа',
    'Terminal.DeliveryDate': 'Дата доставки',
    'Terminal.MobileOperator': 'Оператор связи',
    'Terminal.ActivationDate': 'Дата активации терминала',
    'Terminal.ICCID': '№ USIM',
    'Terminal.State': 'Статус терминала',
    'Terminal.Status': 'Управление терминалом',
    'Terminal.State.Status': 'Статус терминала',
    'Terminal.VIN.Placeholder':
        'VIN состоит из 17 символов, включая английские буквы и цифры',
    'Terminal.NadID.Placeholder': 'Введите номер телефона.',
    'Terminal.Manufacturer.Placeholder': 'Введите производителя.',
    'Terminal.Serial.Placeholder': 'ex) CASD12434052DS888',
    'Terminal.Version.Placeholder': 'Введите версию ПО',
    'Terminal.OrderDate.Placeholder': 'ex) 20191201',
    'Terminal.DeliveryDate.Placeholder': 'ex) 20191201',
    'Terminal.MobileOperator.Placeholder': 'Выберите оператора.',
    'Terminal.ActivationDate.Placeholder': 'ex) 20191201',
    'Terminal.ICCID.Placeholder': 'Введите номер',
    'Terminal.State.Placeholder': 'Автоматически показывать после подключения',
    'Terminal.Status.Placeholder': 'Выберите статус управления терминала.',
    'Terminal.Get.Fail': 'Невозможно получить информацию терминала.',

    'Charge.Btn.Business.List': 'Список корпораций',
    'Charge.Title': 'Тарифный план',
    'Charge.Name': 'Введите название тарифного плана',
    'Charge.Reservation': 'Минимальное время бронирования',
    'Charge.PriceTime': 'Ед. времени для оплаты',
    'Charge.Price.Helper': 'Тариф должен быть целым числом больше нуля.',
    'Charge.Week_day_charge': 'Тариф в будние дни',
    'Charge.Week_end_charge': 'Тариф в выходные дни',
    'Charge.Insurance': 'Страховой платеж страховки защиты от ответственности',
    'Charge.Peak_season_week_day_charge': 'Тариф в час пик в будние дни',
    'Charge.Peak_season_week_end_charge': 'Тариф в час пик в выходные дни',
    'Charge.Peak_season': 'Установить период',
    'Charge.TimePrice': 'Таблица тарифов по времени проката',
    'Charge.Vehicle.Apply': 'Применимый автомобиль',
    'Charge.WeekDay': 'Будний день',
    'Charge.WeekEnd': 'Выходной',
    'Charge.PeakWeekDay': 'Час пик в будние дни',
    'Charge.PeakWeekEnd': 'Час пик в выходные дни',
    'Charge.VehicleNoData':
        'Добавьте автомобиль для применения тарифного плана.',
    'Charge.PriceTimeUnit': 'Ед. изм.',
    'Charge.MinReservation': 'Минимальное время',
    'Charge.Vehiclemodel.Name': 'Тип автомобиля',
    'Charge.Vehicle.licenseplatenumber': '№ автомобиля',
    'Charge.Vehicle.Charge': 'Тарифный план',
    'Charge.PickerInfoMsg':
        '* Если тариф для часа пик не введен, применяется стандартный тариф.',
    'Charge.Vehicle': 'автомобиль',
    'Charge.Vehicle.Msg': 'автомобиль',
    'Charge.Name.Placeholder': 'Введите тарифный план.',
    'Charge.Insurance.Placeholder':
        'Выберите страховку защиты от ответственности.',
    'Charge.Peak_season_start.Placeholder': 'Дата начала',
    'Charge.Peak_season_end.Placeholder': 'Дата окончания',
    'Charge.Week_day_charge.Placeholder': '0',
    'Charge.Week_end_charge.Placeholder': '0',
    'Charge.Peak_season_week_day_charge.Placeholder': '0',
    'Charge.Peak_season_week_end_charge.Placeholder': '0',
    'Charge.PriceTime_hour.Placeholder': '0 ч ',
    'Charge.PriceTime_minute.Placeholder': '0 м',
    'Charge.Reservation_hour.Placeholder': '0 ч ',
    'Charge.Reservation_minute.Placeholder': '0 м',
    'Charge.WarningMsg':
        'Включены автомобили с другими тарифными планами.{br}Если вы не хотите менять план, снимите флажок.',
    'Charge.NotCreate': 'Не зарегистрирован',
    'Charge.Vehicle.NoData': 'Нет автомобиля',
    'Charge.Confirm.Deletes':
        'Удалить отмеченные позиции?{br}За автомобили, забронированные в этом тарифном плане, будет снята плата перед удалением.',
    'Charge.Vehicle.Confirm.Deletes': 'Удалить отмеченные позиции?',
    'Charge.First_discount': 'Скидка за прокат на 3 или более дней (%)',
    'Charge.Second_discount': 'Скидка за прокат на 7 или более дней (%)',
    'Charge.Toast.Business.Fail': 'Сбой получения списка операторов.',
    'Charge.Toast.ChargeList.Fail': 'Сбой получения списка тарифных планов.',
    'Charge.Toast.ChargeDetail.Fail':
        'Сбой получения информации тарифного плана.',
    'Charge.Toast.ChargeCreate.Success': 'Тарифный план зарегистрирован.',
    'Charge.Toast.ChargeCreate.Fail': 'Сбой регистрации тарифного плана.',
    'Charge.Toast.ChargeUpdate.Success': 'Тарифный план изменен.',
    'Charge.Toast.ChargeUpdate.Fail': 'Сбой изменения тарифного плана.',
    'Charge.Toast.DetailVehicleList.Fail':
        'Сбой получения списка автомобилей с тарифным планом.',
    'Charge.Toast.VehicleList.Fail': 'Сбой получения списка автомобилей.',
    'Charge.Toast.Delete.Success': 'Тарифный план удален.',
    'Charge.Toast.Delete.Fail':
        'Сбой в результате уже выбранного тарифного плана бронирования.',
    'Charge.Toast.ChargeDelete.Fail': 'Сбой удаления тарифного плана.',
    'Charge.Toast.VehicleChargeDelete.Success': 'Тарифный план удален.',
    'Charge.Toast.VehicleChargeDelete.Fail': 'Сбой удаления тарифного плана.',
    'Charge.Toast.VehicleChargeList.Fail':
        'Сбой получения списка моделей автомобилей.',

    'Insurance.Vehicle': 'автомобиль',
    'Insurance.GroupName': 'Название группы страховых платежей',
    'Insurance.Info': 'Информация страхового платежа',
    'Insurance.Name': 'Название страховки',
    'Insurance.SelfCost': 'Франшиза страховки (RUB)',
    'Insurance.Time': 'Ед. времени для оплаты',
    'Insurance.Premium': 'Страховой платеж (RUB)',
    'Insurance.TimePrice': 'Страховой платеж по времени проката',
    'Insurance.Charge1': 'Страховой платеж 1',
    'Insurance.Charge2': 'Страховой платеж 2',
    'Insurance.Charge3': 'Страховой платеж 3',
    'Insurance.WarningMsg':
        'Есть автомобили с другими страховыми платежами.{br}Если вы не хотите менять страховой платеж, снимите флажок.',
    'Insurance.Confirm.Deletes':
        'Удалить отмеченные позиции?{br}За автомобили, забронированные с этим страховым платежом, будет снята плата за страховой платеж перед удалением.',
    'Insurance.Toast.InsuranceList.Fail':
        'Сбой получения списка страховых платежей.',
    'Insurance.Toast.InsuranceDetail.Fail':
        'Сбой получения информации страхового платежа.',
    'Insurance.Toast.InsuranceUpdate.Success': 'Страховой платеж был изменен.',
    'Insurance.Toast.InsuranceUpdate.Fail':
        'Сбой изменения страхового платежа.',
    'Insurance.Toast.InsuranceDelete.Success': 'Страховой платеж был удален.',
    'Insurance.Toast.InsuranceDelete.Fail': 'Сбой удаления страхового платежа.',
    'Insurance.Toast.InsuranceCreate.Success':
        'Страховой платеж был зарегистрирован.',
    'Insurance.Toast.InsuranceCreate.Fail':
        'Сбой регистрации страхового платежа.',
    'Insurance.Toast.DetailVehicleList.Fail':
        'Сбой получения списка автомобилей со страховым платежом.',
    'Insurance.Toast.VehicleChargeList.Fail':
        'Сбой получения списка моделей автомобилей.',
    'Insurance.Toast.VehicleChargeDelete.Success':
        'Страховой платеж по автомобилю был удален.',
    'Insurance.Toast.VehicleChargeDelete.Fail':
        'Сбой удаления страхового платежа по автомобилю.',
    'Insurance.Toast.Delete.Fail':
        'Сбой в результате уже выбранного страхового платежа для бронирования.',
    'Insurance.Toast.Business.Fail': 'Сбой получения списка операторов.',

    'SalesManager.AppReservation': 'Бронирование в приложении',
    'SalesManager.FMSReservation': 'Бронирование на сайте',
    'SalesManager.Use_pay': 'Прокат/продление',
    'SalesManager.Premium': 'Страховой платеж',
    'SalesManager.Receivables_pay': 'Поступления',
    'SalesManager.Receivables_pay_total': 'Поступления за {month}{br}(всего)',
    'SalesManager.Sales_total': 'Итого',
    'SalesManager.Month_total': 'Общие продажи за {month}{br}(всего)',
    'SalesManager.Won': '{amount} RUB',
    'SalesManager.Toast.PaymentMonthList.Fail':
        'Сбой получения ежемесячного списка.',
    'SalesManager.Toast.PaymentDayList.Fail':
        'Сбой получения ежедневного списка.',
    'SalesManager.Toast.PaymentBusinessList.Fail':
        'Сбой получения списка операторов.',

    'UseState.State.InUse': 'Используется',
    'UseState.State.Wait': 'Ожидание',
    'UseState.State.Unavailable': 'Нестандартно',
    'UseState.State.LowVoltage': 'Низкое напряжение',
    'UseState.State.NotReturned': 'Не возвращен',
    'UseState.All': 'Все',
    'UseState.Use': 'Используется',
    'UseState.Toast.UseStatusList.Fail':
        'Сбой получения списка статуса использования.',
    'UseState.Toast.UseStateCount.Fail':
        'Сбой получения списка статуса использования.',

    'Payment.Processing': 'Обработать платеж',
    'Payment.Complete': 'Платеж совершен',
    'Payment.Status': 'Статус/Сумма',
    'Payment.PaymentStatus': 'Статус платежа',
    'Payment.UnpaidPay': 'Неоплаченные сборы',
    'Payment.ActualPay': 'Фактический платеж',
    'Payment.Status.Complete': 'Платеж совершен',
    'Payment.Status.Unpaid': 'Не оплачено',
    'Payment.Status.Wait': 'Ожидание платежа',
    'Payment.Status.Fail': 'Сбой платежа',
    'Payment.Alert.NoPaymentData': 'Информация платежа не существует.',
    'Payment.Alert.Delete.No.Checked':
        'Выберите бронирование для обработки платежа.',
    'Payment.Confirm.Pay': 'Обработать {count} шт. платежей?',
    'Payment.Pay.Fail': 'Сбой обработки платежа.',
    'Payment.Get.Fail': 'Сбой получения информации платежа.',
    'Payment.Pay.No.Checked': 'Нет элементов для обработки платежа.',
    'Payment.Pay.MultiFail': 'Сбой обработки {count} шт. платежей.',
    'Payment.Pay.Success': 'Платеж выполнен.',

    'Local.Address.Get.Fail': 'Сбой преобразования адреса.',
    'Local.Geocode.Get.Fail': 'Сбой преобразования адреса.',
    'Local.Search.Fail': 'Ошибка поиска.',

    Location: 'База',
    'Location.Placeholder':
        'Переместите маркер на карте для автоматического ввода адреса.',
    'Location.Name': 'Название базы',
    'Location.Name.Placeholder': 'Введите название базы.',
    'Location.Address': 'Адрес',
    'Location.Address.ModalTitle': 'Поиск по адресу',
    'Location.Address.Placeholder':
        'Это поле автоматически заполняется адресом.',
    'Location.DetailedAddress': 'Подробный адрес',
    'Location.DetailedAddress.Placeholder': 'Введите улицу и № дома.',
    'Location.VehicleCount': 'Автомобиль во владении',
    'Location.Business': 'Корпорация-владелец',
    'Location.Business.Name': 'Название корпорации',
    'Location.Business.Opened': 'Открытая база',
    'Location.Business.Name.Placeholder': 'Выберите корпорацию.',
    'Location.Confirm.MultiDelete': 'Удалить {count} шт. баз(ы)?',
    'Location.Create.Success': 'База была зарегистрирована.',
    'Location.Create.Fail': 'Сбой регистрации базы.',
    'Location.Update.Success': 'Информация базы была изменена.',
    'Location.Update.Fail': 'Сбой изменения информации базы.',
    'Location.Delete.Success': 'База была удалена.',
    'Location.Delete.Fail': 'Сбой удаления базы.',
    'Location.Delete.Fail.VehicleExists':
        'Сбой удаления, так как автомобиль есть в базе.',
    'Location.View.Fail': 'Невозможно получить информацию базы.',
    'Location.Monitoring.Fail':
        'Невозможно получить информацию автомобиля в базе.',
    'Location.Unit.Vehicle.Count': '{value} шт. автомобилей',
    'Location.Radius': 'Место возврата (радиус)',
    'Location.Radius.Placeholder': 'Выберите радиус.',
    'Location.Note': 'Подробности',
    'Location.Note.Placeholder': 'Введите информацию.',
    'Location.Note.Empty': 'Подробностей нет.',

    'Member.Title.Info': 'Базовая информация',
    'Member.Title.Business': 'Информация оператора',
    'Member.Name': 'ФИО',
    'Member.Email': 'ID пользователя',
    'Member.MobileNumber': '№ телефона',
    'Member.Role': 'Тип',
    'Member.ActivationStatus': 'Статус',
    'Member.JoinDate': 'Дата регистрации',
    'Member.BirthDay': 'Дата рождения',
    'Member.Business.Name': 'Оператор',
    'Member.TeamName': 'Команда',
    'Member.EmployeeRegNum': '№ сотрудника',
    'Member.OfficePhoneNumber': 'Офис',
    'Member.DenyReason': 'Причина отклонения',
    'Member.Filter.Email': 'ID пользователя',
    'Member.Filter.Name': 'ФИО',
    'Member.Filter.MobileNumber': '№ телефона',
    'Member.Filter.Business': 'ФИО оператора',
    'Member.Modal.Title.Deny': 'Причина отклонения',
    'Member.Denyreason.Placeholder': 'Введите причину возврата.',
    'Member.View.Fail': 'Невозможно получить администратора.',
    'Member.Approved.Success': 'Запрос одобрен.',
    'Member.Approve.Fail': 'Сбой одобрения запроса.',
    'Member.Denied.Success': 'Запрос отклонен.',
    'Member.Deny.Fail': 'Сбой отклонения запроса.',
    'Member.Help.Approve.Ready': '※ Ваш запрос одобрения не был завершен.',

    'Customer.Title.Info': 'Базовая информация',
    'Customer.Title.Business': 'Информация о сотруднике',
    'Customer.Name': 'ФИО участника',
    'Customer.Email': 'Адрес эл. почты',
    'Customer.MobileNumber': '№ телефона',
    'Customer.JoinDate': 'Дата регистрации',
    'Customer.ActivationStatus': 'Статус',
    'Customer.BirthDay': 'Дата рождения',
    'Customer.Address': 'Адрес',
    'Customer.DriverLicense': 'Водит. удостоверение',
    'Customer.CreditCard': '№ карты',
    'Customer.Business.Name': 'Название корпорации',
    'Customer.TeamName': 'Команда',
    'Customer.EmployeeRegNum': '№ сотрудника',
    'Customer.DenyReason': 'Причина отклонения',
    'Customer.Filter.Email': 'ID пользователя',
    'Customer.Filter.Name': 'ФИО',
    'Customer.Filter.MobileNumber': '№ телефона',
    'Customer.Filter.Business': 'ФИО оператора',
    'Customer.Filter.Team': 'Команда',
    'Customer.Modal.Title.Deny': 'Причина отклонения',
    'Customer.Denyreason.Placeholder': 'Введите причину возврата.',
    'Customer.View.Fail': 'Невозможно получить участников.',
    'Customer.Approved.Success': 'Запрос одобрен.',
    'Customer.Approve.Fail': 'Сбой одобрения запроса.',
    'Customer.Denied.Success': 'Запрос отклонен.',
    'Customer.Deny.Fail': 'Сбой отклонения запроса.',

    'Business.Title.Type': 'Категория оператора',
    'Business.Title.Business': 'Информация оператора',
    'Business.Title.Manager': 'Главный администратор',
    'Business.BusinessCode': 'Код',
    'Business.BusinessRegNum': '№ оператора',
    'Business.Name': 'Название корпорации',
    'Business.MobileNumber': 'Основной телефон',
    'Business.Address': 'Адрес',
    'Business.KoroadClientID': 'Водит. удостоверение',
    'Business.PGMemberID': 'PG Member ID',
    'Business.Manager.Name': 'ФИО',
    'Business.Manager.MobileNumber': '№ телефона',
    'Business.Manager.Email': 'Адрес эл. почты',
    'Business.Type.Private': 'Корпорация',
    'Business.Type.Public': 'Общий',
    'Business.Modal.Address.Search': 'Найти адрес оператора',
    'Business.BusinessRegNum.Placeholder': 'Введите номер оператора.',
    'Business.Name.Placeholder': 'Введите название корпорации.',

    'Business.Address.Address.Placeholder': 'Выполните поиск индекса.',
    'Business.Address.DetailedAddress.Placeholder': 'Введите улицу и № дома.',
    'Business.KoroadClientID.Placeholder': 'CLIENT ID',
    'Business.KoroadSecret.Placeholder': 'CLIENT SECRET',
    'Business.KoroadPIN.Placeholder': 'PIN',
    'Business.KoroadPWD.Placeholder': 'Пароль',
    'Business.PGMemberID.Placeholder': 'Введите ID.',
    'Business.Manager.Name.Placeholder': 'Введите ФИО.',

    'Business.Manager.Email.Placeholder': 'Введите адрес эл. почты',
    'Business.View.Fail': 'Невозможно получить информацию оператора.',
    'Business.BusinessRegNum.Help': 'Введите номер оператора.',
    'Business.MobileNumber.Help': 'Введите действительный основной № телефона.',
    'Business.Manager.MobileNumber.Help': 'Введите действительный № телефона.',

    'Alert.Image.SizeLimitExceeded':
        'Можно передать изображение не больше 500 Кб.',

    'Vehicle.Status.Wait': 'Ожидание',
    'Vehicle.Status.InUse': 'Используется',
    'Vehicle.Status.Incident': 'Нестандартно',
    'Vehicle.Status.LowVoltage': 'Низкое напряжение',
    'Vehicle.Status.NotReturned': 'Не возвращен',
    'Vehicle.Status.Unknown': 'Неизвестно',

    'Brand.Type.Hyundai': 'Hyundai Motor Company',
    'Brand.Type.Kia': 'Kia Motors',

    'Fuel.Type.Gasoline': 'Бензин',
    'Fuel.Type.Diesel': 'Дизель',
    'Fuel.Type.Ev': 'Электричество',
    'Fuel.Type.Hybrid': 'Гибрид',

    'Grade.Type.City': 'Мини-компакт',
    'Grade.Type.SubCompact': 'Малогабаритный',
    'Grade.Type.Compact': 'Компакт',
    'Grade.Type.Mid': 'Средний',
    'Grade.Type.Large': 'Большой',
    'Grade.Type.SUV': 'SUV',
    'Grade.Type.VAN': 'Минивэн',
    'Grade.Type.Electric': 'Электричество',
    'Grade.Type.Oversea': 'Импортный',

    'Accident.Type.Washing': 'Мойка',
    'Accident.Type.Maintenance': 'Обслуживание',
    'Accident.Type.Repair': 'Ремонт',
    'Accident.Type.Etc': 'Другое',

    'Terminal.StatusType.Normal': 'Использование (статус подключения)',
    'Terminal.StatusType.Outoforder': 'Устранение неполадок',
    'Terminal.StatusType.Discard': 'Утилизация',
    'Terminal.StatusType.Unknown': 'Неизвестно',

    'Terminal.State.Type.Unknown': 'Неизвестно',
    'Terminal.State.Type.Connect': 'Установлено на автомобиль',
    'Terminal.State.Type.Conflict': 'Несоответствие',
    'Terminal.State.Type.Wait': 'Ожидание',

    'Terms.StatusType.Valid': 'Действительный',
    'Terms.StatusType.Invalid': 'Недействительный',

    'Notice.StatusType.Draft': 'DRAFT',
    'Notice.StatusType.Published': 'PUBLISHED',
    'Notice.StatusType.Notified': 'NOTIFIED',

    'Member.ActivationStatusType.Pending': 'Ожидание',
    'Member.ActivationStatusType.Normal': 'Одобрить',
    'Member.ActivationStatusType.Deny': 'Отклонить',

    'Member.RoleType.System.Admin': 'Менеджер',
    'Member.RoleType.System.Operator': 'Администратор',
    'Member.RoleType.Public.Admin': 'Менеджер',
    'Member.RoleType.Public.Operator': 'Администратор',
    'Member.RoleType.Private.Admin': 'Менеджер',
    'Member.RoleType.Private.Operator': 'Администратор',

    'Member.RoleGroupType.Admin': 'Менеджер',
    'Member.RoleGroupType.Operator': 'Администратор',

    'User.StatusType.Pending': 'Ожидание',
    'User.StatusType.Normal': 'Одобрить',
    'User.StatusType.Reject': 'Отклонить',
    'User.StatusType.Deny': 'Отклонить',

    'User.LevelType.Public': 'Общий участник',
    'User.LevelType.Private': 'Корпоративный участник',

    'Customer.BizStatusType.Requested': 'Ожидание',
    'Customer.BizStatusType.Inactive': 'Одобрить',
    'Customer.BizStatusType.Deny': 'Отклонить',

    'Reservation.Status.Unknown': 'Неизвестно',
    'Reservation.Status.Requested': 'Ожидает одобрения',
    'Reservation.Status.Approve': 'Бронирование завершено',
    'Reservation.Status.Confirm': 'Одобрение получено',
    'Reservation.Status.Denied': 'Бронирование отклонено',
    'Reservation.Status.Canceled': 'Отменить бронь',

    'Reservation.Status.InUse': 'Используется',
    'Reservation.Status.Returned': 'Возврат выполнен',

    'Reservation.ConfirmType.Requested': 'Ожидание выпуска автомобиля',
    'Reservation.ConfirmType.Confirmed': 'Одобрение выпуска автомобиля',
    'Reservation.ConfirmType.Denied': 'Отклонение выпуска автомобиля',

    'Vehicle.ControlType.Open': 'Открыть дверь (разблокировать)',
    'Vehicle.ControlType.Close': 'Закрыть дверь (замкнуть)',
    'Vehicle.ControlType.Horn': 'Звуковой сигнал',
    'Vehcile.ControlType.Light': 'Аварийная сигнализация',

    'Vehicle.Colors.White': 'белый',
    'Vehicle.Colors.LightGrey': 'серебро',
    'Vehicle.Colors.Grey': 'серый',
    'Vehicle.Colors.Black': 'черный',
    'Vehicle.Colors.Red': 'красный',
    'Vehicle.Colors.Blue': 'синий',

    'Vehicle.Transmission.Auto': 'Автоматическая',
    'Vehicle.Transmission.Manual': 'Механическая',

    'Vehicle.Options.Nosmoking': 'Курить запрещено',
    'Vehicle.Options.Dashcam': 'Черный ящик',
    'Vehicle.Options.Rearview': 'Камера заднего вида',
    'Vehicle.Options.Navigation': 'Навигация',

    'Vehicle.InsuranceAge.21': 'возрастом 21 и более лет',
    'Vehicle.InsuranceAge.26': 'возрастом 26 и более лет',
    'Vehicle.InsuranceDriverAge.21':
        'Страховка водителя возрастом 21 и более лет',
    'Vehicle.InsuranceDriverAge.26':
        'Страховка водителя возрастом 26 и более лет',

    // LICENSE_LOCATION_TYPE
    'License.Location.Seoul': 'Сеул',
    'License.Location.Busan': 'Busan',
    'License.Location.Gyeonggi': 'Gyeonggi(South)',
    'License.Location.Kangwon': 'Kangwon',
    'License.Location.Chungbuk': 'Chungbuk',
    'License.Location.Chungnam': 'Chungnam',
    'License.Location.Jeonbuk': 'Jeonbuk',
    'License.Location.Jeonnam': 'Jeonnam',
    'License.Location.Gyeongbuk': 'Gyeongbuk',
    'License.Location.Gyeongnam': 'Gyeongnam',
    'License.Location.Jeju': 'Jeju',
    'License.Location.Daegu': 'Daegu',
    'License.Location.Incheon': 'Incheon',
    'License.Location.Kwangju': 'Kwangju',
    'License.Location.Daejeon': 'Daejeon',
    'License.Location.Ulsan': 'Ulsan',
    'License.Location.NorthGyeonggi': 'Gyeonggi(North)',

    // LICENSE_TYPE
    'License.Type.Large': 'первый класс (большой)',
    'License.Type.Normal': 'первый класс (обычный)',
    'License.Type.Small': 'первый класс (маленький)',
    'License.Type.Trailer': 'большой трейлер',
    'License.Type.Wrecker': 'Эвакуатор',
    'License.Type.SmallTrailer': 'маленький трейлер',
    'License.Type.SecondNormal': '2-й класс (обычный)',
    'License.Type.SecondSmall': '2-й класс (маленький)',
    'License.Type.MotorizedBicycle': '2-й класс (Главный двигатель)',

    'PenaltyAndTollPage.Objection': 'Заявление о несогласии',
    'PenaltyAndTollPage.LicensePlateNumber': '№ автомобиля',
    'PenaltyAndTollPage.Violation': 'Дата/время нарушения',
    'PenaltyAndTollPage.UserName': 'ФИО клиента',
    'PenaltyAndTollPage.MobileNumber': '№ телефона',
    'PenaltyAndTollPage.LicenseType': '№ водит. удостоверения',
    'PenaltyAndTollPage.LicnConCode': '№ водит. удостоверения',

    'PenaltyAndTollPage.ExpirationOfLicence':
        'Период действия водит. удостоверения',
    'PenaltyAndTollPage.RegistrationDate.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.AppealTime.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.Violation.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.ExpirationOfLicence.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.LicensePlateNumber.Placeholder': 'Введите № автомобиля',
    'PenaltyAndTollPage.UserName.Placeholder': 'Введите ФИО клиента',
    'PenaltyAndTollPage.MobileNumber.Placeholder': 'Введите № телефона',

    'PenaltyAndTollPage.ZipCode': 'Адрес',
    'PenaltyAndTollPage.DetailedAddress': 'Подробный адрес ',
    'PenaltyAndTollPage.LicenceNumberSecond.Placeholder':
        'Введите № водит. удостоверения',
    'PenaltyAndTollPage.RentDivision': 'Категория проката',
    'PenaltyAndTollPage.RentDivision.Placeholder': 'Общий',
    'PenaltyAndTollPage.RentDuration': 'Период проката',
    'PenaltyAndTollPage.RentDuration.Placeholder': '0000.00.00',
    'PenaltyAndTollPage.BusinessName': 'Название корпорации',
    'PenaltyAndTollPage.BusinessName.Placeholder':
        'Введите название корпорации',
    'PenaltyAndTollPage.NumberOfBusiness': '№ оператора',
    'PenaltyAndTollPage.NumberOfBusiness.Placeholder': 'Введите № оператора',
    'PenaltyAndTollPage.Category': 'Тип нарушения',
    'PenaltyAndTollPage.Category.Placeholder': 'Все',
    'PenaltyAndTollPage.PenaltyFee.Placeholder': 'Введите сумму',
    'PenaltyAndTollPage.PenaltyFee': 'Штраф',
    'PenaltyAndTollPage.Location.Placeholder': 'Введите место нарушения',
    'PenaltyAndTollPage.Organization.Placeholder': 'Введите департамент выдачи',
    'PenaltyAndTollPage.Desc.Placeholder': 'Введите содержание',
    'PenaltyAndTollPage.Desc': 'Подробности',
    'PenaltyAndTollPage.ProcessObjection.Placeholder': 'Да',
    'PenaltyAndTollPage.ProcessObjection': 'Обработать несогласие',
    'PenaltyAndTollPage.Appeal': 'Обработать несогласие',
    'PenaltyAndTollPage.Appeal.Placeholder': 'Да',
    'PenaltyAndTollPage.Remark.Placeholder': 'Введите содержание',
    'PenaltyAndTollPage.Remark': 'Содержание',
    'PenaltyAndTollPage.ViolationPlace.Placeholder': 'Введите место нарушения',
    'PenaltyAndTollPage.ViolationPlace': 'Место нарушения',
    'PenaltyAndTollPage.Location': 'Место нарушения',
    'PenaltyAndTollPage.Organization': 'Департамент выдачи',
    'PenaltyAndTollPage.ProcessingDate': 'Дата/время обработки',
    'PenaltyAndTollPage.ConnectRentInformation': 'Ссылка с информацией проката',
    'PenaltyAndTollPage.Check.RentInformation':
        'Введите номер автомобиля и дату нарушения.',
    'PenaltyAndTollPage.Fail.ConnectRentInformation':
        'Информация проката не существует.',
    'PenaltyAndTollPage.Fail.Create':
        'Сбой регистрации штрафа/платы за проезд.',
    'PenaltyAndTollPage.Success.Create':
        'Штраф/плата за проезд зарегистрирована.',
    'PenaltyAndTollPage.Success.Delete': 'Штраф/плата за проезд удалена.',
    'PenaltyAndTollPage.Fail.Delete': 'Сбой удаления штрафа/платы за проезд.',
    'PenaltyAndTollPage.Fail.ViolationList':
        'Сбой получения списка штрафов/платы за проезд.',
    'PenaltyAndTollPage.Fail.Detail':
        'Сбой получения информации штрафа/платы за проезд.',
    'PenaltyAndTollPage.Fail.Download': 'Сбой печати.',
    'PenaltyAndTollPage.Success.Update': 'Штраф/плата за проезд изменена.',
    'PenaltyAndTollPage.Fail.Update': 'Сбой изменения штрафа/платы за проезд.',
    'PenaltyAndToll.NumberOfPenalty': '№ штрафа',
    'PenaltyAndToll.ViolationCategory': 'Тип нарушения',
    'PenaltyAndToll.Fee': 'Сбор',
    'PenaltyAndTollPage.RegistrationDate': 'Дата/время нарушения',
    'PenaltyAndToll.ViolationDate': 'Дата/время нарушения',
    'PenaltyAndToll.Vehicle': 'автомобиль',
    'PenaltyAndToll.UserName': 'ФИО клиента',
    'PenaltyAndToll.MobileNumber': '№ телефона',
    'PenaltyAndToll.Business': 'Оператор',
    'PenaltyAndToll.NumberOfPenalty.Placeholder': 'Введите № штрафа',
    'PenaltyAndToll.Download.PdfName': 'Заявление о несогласии.pdf',
    'PenaltyAndTollPage.Type.Park': 'Нарушение правил парковки',
    'PenaltyAndTollPage.Type.Sign': 'Нарушение сигналов',
    'PenaltyAndTollPage.Type.Speed': 'Нарушение скоростного режима',
    'PenaltyAndTollPage.Yes': 'Да',
    'PenaltyAndTollPage.No': 'Нет',
    'PenaltyAndTollPage.Required': 'Заполните все поля.',
    'AppealDetail.DocID': '№ документа',
    'AppealDetail.AppealTime': 'Дата исполнения',
    'AppealDetail.Organization': 'Получатель',
    'AppealDetail.Temp': 'Скрытая копия',
    'AppealDetail.Remark': 'Содержание',
    'AppealDetail.LicensePlateNumber': '№ автомобиля-нарушителя',
    'AppealDetail.UserName': 'ФИО клиента',
    'AppealDetail.LicnCon': '№ водит. удостоверения',
    'AppealDetail.ViolationTime': 'Дата/время нарушения',
    'AppealDetail.ReservationTime': 'Период проката',
    'AppealDetail.Representative': 'Генеральный директор',
    'AppealDetail.Paragraph': 'В результате ',
    'AppealDetail.Paragraph2':
        'произошедшего в период проката, я направляю заявление о несогласии, приведенное выше, и прошу рассмотреть его и передать водителю.',
    'MyPage.JoinDate': 'Дата регистрации',
    'MyPage.Name': 'ФИО клиента',
    'MyPage.Email': 'ID пользователя',
    'MyPage.MobileNumber': '№ телефона',
    'MyPage.BirthDate': 'Дата рождения',
    'MyPage.ApproveMessage': 'Причина отклонения',
    'MyPage.Info': 'Базовая информация',
    'MyPage.BusinessInfo': 'Информация корпорации',
    'MyPage.Business.BusinessCode': 'Код компании',
    'MyPage.Business.Name': 'Название корпорации',
    'MyPage.Business.Required': 'Введите действительный код компании.',
    'MyPage.EmployeeRegNum': '№ сотрудника',
    'MyPage.TeamName': 'Команда',
    'MyPage.CompanyEmail': 'Адрес эл. почты',
    'MyPage.ActivationStatus': 'Статус',
    'MyPage.PasswordChange': 'Изменить пароль',
    'MyPage.OldPassword': 'Старый пароль',
    'MyPage.NewPassword': 'Новый пароль',
    'MyPage.CheckNewPassword': 'Подтвердите новый пароль',
    'MyPage.PasswordInfo':
        'Для пароля используйте от 8 до 20 символов, включающих в себя 3 типа - английские большие/маленькие буквы, цифры и специальные символы.',
    'MyPage.Change': 'Изменить',
    'MyPage.UserDelete': 'Отмена участия',
    'MyPage.UserType': 'Тип',
    'MyPage.Update.Fail': 'Сбой изменения информации.',
    'MyPage.Update.Success': 'Информация была изменена.',
    'MyPage.UpdatePwdCheck.Fail': 'Неправильный старый пароль.',
    'MyPage.UpdatePwd.Fail': 'Сбой изменения пароля.',
    'MyPage.UpdatePwd.Success': 'Пароль был изменен.',

    // 번역의뢰 WEB 0330    - ru.js
    'Common.Select.Required': 'Требуется выбрать значение',
    'Common.Btn.Save': 'Сохранить',
    'Common.Btn.NewCreate': 'Регистрация нового автомобиля',
    'Common.Btn.CreateComplete': 'Регистрация завершена',
    'Common.Btn.EditComplete': 'Изменить',
    'Common.Btn.PrivateDeny': 'Вывод средств предприятием',
    'Common.Btn.CarLog': 'Путевой журнал',
    'Common.Confirm.Pay': 'Хотите произвести платеж?',
    'Common.Unit.Short.Hour': 'Час',
    'Common.Unit.Days': 'День',
    'Common.Unit.Count': 'Расчет',
    'Common.Unit.Month': 'Месяц',
    'Main.SubTitle': 'Оформить бронирование и произвести расчеты стало легче.',
    // 'Menu.SalesAndCalculation': 'Управление продажами/расчетами ',
    // 'Menu.SalesManager': 'Управление продажами/расчетами ',
    // 'Menu.LocationManager': 'Управление местонахождением',
    // 'Menu.UserManager': 'Управление членством',
    // 'Menu.BusinessManager': 'Управление предприятиями',
    // 'Menu.BusinessCustomrList': 'Список клиентов предприятия',
    // 'Menu.BusinessCustomerEditList': 'Редактировать список клиентов предприятия',
    // 'Menu.User': 'Управление пользователями',
    'Title.VehicleInfo': 'Просмотреть транспортное средство',
    'Title.VehicleCreate': 'Регистрация транспортного средства',
    'Title.ReservationTestCarCreate': 'Бронирование тестового автомобиля',
    'Title.TestCarConfigList': 'Установить лимит для тестовых автомобилей',
    'Title.License': 'Управление лицензиями',
    'Title.Code': 'Управление кодом',
    'Title.Menu': 'Управление меню',
    'Title.Role': 'Управление доступом к ролям',
    'Title.Program': 'Управление программами',
    'Title.Message': 'Управление сообщениями',
    'Title.AccidentCreate': 'Регистрация аварии/ремонта',
    // 'Title.TestCarConfigList': 'Комплектация тестового автомобиля',
    'Menu.Holiday': 'Управление тестовыми автомобилями в праздничные дни',
    'Reservation.Btn.Download': 'Скачать CSV-файл',
    // 'Reservation.Status.InUse': 'Возврат транспортного средства невозможен, поскольку оно не используется.',
    'Reservation.VehicleType': 'Тип транспортного средства',
    // 'Reservation.Insurance.Invalid':  //   'Автомобиль не может быть забронирован, так как срок действия автострахования истек.',
    //   'Бронирование не может быть изменено, так как срок действия автострахования истек.',
    'Reservation.StartOdometer': 'Начальный показатель одометра (км)',
    'Reservation.EndOdometer': 'Окончательный показатель одометра (км) ',
    'Reservation.TotalOdometer': 'Общая дальность поездки (км)',
    'Reservation.BusinessType': 'Выберите оператора',
    'Reservation.Approve.Success':
        '{success} случаи были утверждены, и они являются одними из {total} случаев, утвержденных во всем мире.',
    'Reservation.Deny.Success':
        '{success} платежи были отклонены и не удерживались из единовременной суммы {total} платежей.',
    'Reservation.Pay.Success':
        '{success} платежи прошли успешно и были вычтены из единовременной суммы {total} платежей.',
    'Reservation.Business.Name': 'Название предприятия',
    'Reservation.User.Team': 'Команда',
    'Reservation.User.Name': 'Имя лица, оформившего бронирование',
    'Reservation.Use.ImgList':
        'Фотографии до и после использования транспортного средства',
    'Reservation.Use.ImgCheck':
        'Проверьте фотографию транспортного средства до и после использования.',
    'Reservation.Count.AllVehicle': 'Все автомобили в эксплуатации',
    'Reservation.Count.AvailableVehicle':
        'Доступные для бронирования транспортные средства',
    'Reservation.Count.CompletedVehicle':
        'Забронированное транспортное средство',
    'Reservation.Count.InUseVehicle':
        'Транспортное средство в настоящее время используется.',
    'Reservation.Count.WaitVehicle':
        'Транспортное средства в ожидании утверждения',
    'Rerservation.Btn.Pay': 'Оплата',
    // 'Vehicle.Used': 'Статус использования',
    // 'Vehicle.Used.Yes': 'Используется',
    // 'Vehicle.Used.No': 'Не использовано',
    'Vehicle.LicensePlateNumber.Placeholder': 'например) 00 Ха 0000.',
    'Vehicle.ControllerID.Placeholder': 'кроме) 01012345678',
    'Vehicle.Type.Normal': 'Стандартное транспортное средство',
    'Vehicle.Type.TestCar': 'Тестовый автомобиль',
    'Vehicle.VehicleType': 'Тип транспортного средства',
    'BusinessMember.Title': 'Информация о подписке для сотрудников',
    'Member.Branch.Name': 'Филиал',
    // 'Business.TestCar': 'Тестовый автомобиль',
    'Accident.Type.Acc1': 'Авария по вине клиента',
    'Accident.Type.Acc2': 'Авария, причинившая ущерб клиенту',
    'Terminal.Telecom.Type.SKTMVNO': 'SKT MVNO ',
    'Terminal.Telecom.Type.KTMVNO': 'KT MVNO',
    'Terminal.Telecom.Type.LGUMVNO': 'LGU+ MVNO ',
    'Reservation.Status.Return': 'Возврат',
    'Vehicle.Colors.Green': 'Зеленый',
    'Vehicle.Colors.Yellow': 'Желтый',
    'PenaltyAndTollPage.LicenseType.3': 'Инчхон(3)',
    'PenaltyAndTollPage.LicenseType.12': 'Сеул(11)',
    'PenaltyAndTollPage.LicenseType.Placeholder': 'Сеул(11)',
    'PenaltyAndTollPage.LicnConCode.Placeholder': 'Сеул(11)',
    'TestCarConfig.Business': 'Выберите оператора',
    'TestCarConfig.ReservationTime':
        'Период бронирования (максимальное количество дней)',
    'TestCarConfig.MaxMonth':
        'Период испытания автомобиля (максимальное количество месяцев)',
    'TestCarConfig.ReservationCount': 'Количество бронирований (в квартал)',
    'TestCarConfig.TokURL': 'URL для просмотра тестового автомобиля',
    'TestCarConfig.Info': 'Установить лимит для тестовых автомобилей',
    'TestCarConfig.ReservationHour': 'Время начала/окончания бронирования',
    'TestCarConfig.ReservationInfo': 'Информация о бронировании',
    'TestCarConfig.Toast.getTestCarConfig.Fail':
        'Не удалось получить список по комплектации.',
    'TestCarConfig.Toast.createTestCarConfig.Fail':
        'Не удалось зарегистрировать комплектацию.',
    'TestCarConfig.Toast.updateTestCarConfig.Success':
        'Комплектация была обновлена.',
    'TestCarConfig.Toast.updateTestCarConfig.Fail':
        'Не удалось обновить комплектацию.',
    'Program.Name': 'Название программы',
    // 'Program.Method': 'Действие',
    'Program.Action': 'Действие',
    'Program.Confirm.MultiDelete': 'Вы хотите удалить {count} программы?',
    'Program.View.Fail': 'Невозможно получить информацию о программе.',
    'Program.Delete.Success': 'Программа была удалена.',
    'Program.Delete.Fail': 'Не удалось удалить программу.',
    'Program.Create.Success': 'Программа была зарегистрирована.',
    'Program.Create.Fail': 'Не удалось зарегистрировать программу.',
    'Program.Action.Inquiry': 'Читать',
    'Program.Action.Save': 'Списать',
    'Program.Action.Delete': 'Удалить',
    'Program.Action.Download': 'Скачать',
    'Program.Name.Placeholder': 'Введите название программы.',
    'Program.Apiurl.Placeholder': 'Введите URL API.',
    'Message.ResourceKey': 'Ключ',
    'Message.Message': 'Сообщение',
    'Message.Confirm.MultiDelete': 'Вы хотите удалить {count} сообщения?',
    'Message.View.Fail': 'Не удалось получить информацию о сообщении.',
    'Message.Delete.Success': 'Сообщение было удалено.',
    'Message.Delete.Fail': 'Не удалось удалить сообщение.',
    'Message.Create.Success': 'Сообщение было создано.',
    'Message.Create.Fail': 'Не удалось создать сообщение.',
    'Message.Update.Success': 'Сообщение было обновлено.',
    'Message.Update.Fail': 'Не удалось обновить сообщение.',
    'Message.Key.Placeholder': 'Введите значение ключа.',
    'Message.Message.Placeholder': 'Введите сообщение.',
    'Message.Confirm.Delete': 'Хотите удалить сообщение?',
    'Code.RootName': 'Управление кодом',
    'Code.SelectCode': 'Выберите код',
    'Code.Type': 'Тип',
    'Code.Type.Group': 'Группа кода',
    'Code.Type.Value': 'Кодовый элемент',
    'Code.Name': 'Кодовое наименование',
    'Code.GroupKey': 'Ключ группы',
    'Code.CodeValue': 'Значение кода',
    'Code.OrderNum': 'Приоритет',
    'Code.Use': 'Показать статус',
    'Code.Use.false': 'Скрыть',
    'Code.Use.true': 'Показать',
    'Code.Name.Placeholder': 'Введите кодовое наименование.',
    'Code.MsgKey.Placeholder': 'Введите код ключа сообщения.',
    'Code.GroupKey.Placeholder': 'Введите значение ключа группы.',
    'Code.CodeValue.Placeholder': 'Введите значение кода.',
    'Code.OrderNum.Placeholder': 'Ввести значение приоритета.',
    'Code.View.Fail': 'Невозможно получить информацию о коде.',
    'Code.Confirm.Delete': 'Хотите удалить код?',
    'Code.Create.Success': 'Код зарегистрирован.',
    'Code.Create.Fail': 'Не удалось зарегистрировать код.',
    'Code.Update.Success': 'Код был обновлен.',
    'Code.Update.Fail': 'Не удалось обновить код.',
    'Code.Delete.Success': 'Код удален.',
    'Code.Delete.Fail': 'Не удалось удалить код.',
    'Menu.SelectCode': 'Выбрать меню',
    'Menu.Name': 'Название меню',
    'Menu.MenuUrl': 'Путь меню',
    'Menu.OrderNum': 'Приоритет',
    'Menu.Use': 'Показать статус',
    'Menu.Use.false': 'Скрыть',
    'Menu.Use.true': 'Показать',
    'Menu.Acl': 'Статус полномочий',
    'Menu.Acl.true': 'Используется',
    'Menu.Acl.false': 'Не используется',
    'Menu.Confirm.Delete': 'Хотите удалить меню?',
    'Menu.Menu.Placeholder': 'Введите название меню.',
    'Menu.Path.Placeholder': 'Введите путь меню.',
    'Menu.Order.Placeholder': 'Установить приоритет.',
    'Menu.View.Fail': 'Не удалось получить информацию о меню.',
    'Menu.Delete.Success': 'Меню было удалено.',
    'Menu.Delete.Fail': 'Не удалось удалить меню.',
    'Menu.Create.Success': 'Меню было зарегистрировано.',
    'Menu.Create.Fail': 'Не удалось зарегистрировать меню.',
    'Menu.Update.Success': 'Меню было обновлено.',
    'Menu.Update.Fail': 'Не удалось обновить меню.',
    'Menu.Program.View.Fail': 'Невозможно получить информацию о программе.',
    'Menu.Program.Delete.Success': 'Программа была удалена.',
    'Menu.Program.Delete.Fail': 'Не удалось удалить программу.',
    'Menu.Program.Create.Success': 'Программа была зарегистрирована.',
    'Menu.Program.Create.Fail': 'Не удалось зарегистрировать программу.',
    'Role.Select.Placeholder': 'Выбрать роль.',
    'Role.Name': 'Имя роли',
    'Role.RoleKey': 'Ключ роли',
    'Role.Note': 'Примечание о роли',
    'Role.Name.Placeholder': 'Введите имя роли',
    'Role.RoleKey.Placeholder': 'Введите ключ роли.',
    'Role.Note.Placeholder': 'Введите примечание о роли.',
    'Role.Acl.Title': 'Права доступа',
    'Role.Admin.Title': 'Менеджер',
    'Role.View.Fail': 'Невозможно получить информацию о роли.',
    'Role.Delete.Success': 'Роль удалена.',
    'Role.Delete.Fail': 'Не удалось удалить роль.',
    'Role.Create.Success': 'Роль была зарегистрирована.',
    'Role.Create.Fail': 'Невозможно использовать дублированный ключ роли.',
    'Role.Update.Success': 'Роль обновлена.',
    'Role.Update.Fail': 'Невозможно использовать дублированный ключ роли.',
    'Role.Acl.View.Fail': 'Невозможно получить информацию о правах доступа.',
    'Role.Acl.Update.Success': 'Права доступа были обновлены.',
    'Role.Acl.Update.Fail': 'Не удалось обновить права доступа.',
    'Role.Admin.View.Fail':
        'Не удалось просмотреть информацию об администраторе.',
    'Role.Confirm.Delete': 'Хотите удалить роль?',
    'Role.Menu': 'Название меню',
    'Role.Read': 'Читать',
    'Role.Write': 'Списать',
    'Role.Delete': 'Удалить',
    'Role.Download': 'Скачать',
    'Role.Admin.Name': 'Имя',
    'Role.Admin.UserID': 'Идентификатор пользователя',
    'Role.Admin.MobileNumber': 'Номер телефона',
    'License.Confirm.MultiDelete':
        'Вы хотите удалить {count} управление лицензиями?',
    'License.View.Fail':
        'Невозможно просмотреть информацию об управлении лицензиями.',
    'License.Confirm.Delete': 'Хотите удалить управление лицензиями?',
    'License.Delete.Success': 'Управление лицензиями было удалено.',
    'License.Delete.Fail': 'Не удалось удалить управление лицензиями.',
    'License.Create.Success': 'Управление лицензиями было зарегистрировано.',
    'License.Create.Fail': 'Не удалось зарегистрировать управление лицензиями.',
    'License.Update.Success': 'Управление лицензиями было обновлено.',
    'License.Update.Fail': 'Не удалось обновить управление лицензиями.',
    'License.View.Menu.Fail':
        'Невозможно просмотреть информацию о правах доступа на управление лицензиями.',
    'License.Update.Menu.Fail':
        'Не удалось обновить права доступа на управление лицензиями.',
    'License.View.Business.Fail':
        'Невозможно просмотреть информацию о лицензии управляющей компании.',
    'License.Name': 'Название лицензии',
    'License.Use': 'Статус использования',
    'License.Note': 'Примечание',
    'License.BusienssCnt': 'Учет подписок',
    'License.UsageFee': 'Плата за использование / месяц',
    'License.Use.false': 'Не используется',
    'License.Use.true': 'Используется',
    'License.Menu.Title': 'Права доступа',
    'License.Business.Title': 'Оператор',
    'License.Business.Name': 'Имя оператора',
    'License.Business.Date': 'Дата лицензии',
    'License.Name.Placeholder': 'Введите название лицензии.',
    'License.UseFee.Placeholder': 'Введите плату за использование.',
    'License.Note.Placeholder': 'Введите примечание.',
    'CompanyUser.CompanyId': 'Название компании',
    'CompanyUser.ManagerName': 'Имя менеджера',
    'CompanyUser.PhoneNumber': 'Номер телефона',
    'CompanyUser.FaxNumber': 'Номер факса.',
    'CompanyUser.ManagerMobileNumber': 'Номер мобильного телефона менеджера',
    'CompanyUser.ManagerEmail': 'Адрес электронной почты менеджера',
    'CompanyUser.Get.Fail': 'Информация о менеджере компании недоступна.',
    'Title.CompanyUser': 'Менеджер компании',
    'CompanyUser.Title.Create': 'Зарегистрировать менеджера компании',
    'CompanyUser.CompanyID.Placeholder':
        'Выбрать идентификационный номер компании.',
    'CompanyUser.ManagerName.Placeholder': 'Введите имя менеджера компании.',
    'CompanyUser.PhoneNumber.Placeholder':
        'Введите номер телефона менеджера компании.',
    'CompanyUser.FaxNumber.Placeholder':
        'Введите номер факса менеджера компании.',
    'CompanyUser.ManagerMobileNumber.Placeholder':
        'Введите номер телефона менеджера компании.',
    'CompanyUser.ManagerEmail.Placeholder':
        'Введите адрес электронной почты менеджера компании.',
    'CompanyUser.Filter.CompanyID': 'Идентификационный номер компании',
    'CompanyUser.Filter.ManagerName': 'Имя менеджера',
    'CompanyUser.Filter.ManagerMobileNumber':
        'Номер мобильного телефона менеджера',
    'Company.Name': 'Название компании',
    'Company.CompanyBusiness': 'Бизнес-статус компании',
    'Company.CompanyType': 'Тип компании',
    'Company.PhoneNumber': 'Номер телефона',
    'Company.FaxNumber': 'Номер ФАКСА.',
    'Company.Address': 'Адрес',
    'Company.BusinessRegNum': '№ оператора',
    'Company.CeoName': 'Имя',
    'Company.Homepage': 'Веб-сайт',
    'Company.RepBusinessType': 'Основной вид хозяйственной деятельности',
    'Company.BusinessType': 'Организационно-правовая форма',
    // 'Company.BusinessId': 'Идентификационный номер предприятия',
    // 'Company.BranchId': 'Идентификационный номер филиала',
    'Company.BusinessId': 'Название предприятия',
    'Company.BranchId': 'Филиал',
    'COMPANY.Type.Mechanic': 'Станция технического обслуживания',
    'COMPANY.Type.Insurance': 'Страховая компания',
    'Company.Title.Create': 'Зарегистрировать компанию',
    'Title.Company': 'Компания',
    'Company.Name.Placeholder': 'Введите название компании.',
    'Company.CompanyType.Placeholder': 'Выберите тип компании.',
    'Company.PhoneNumber.Placeholder': 'кроме) 0101234567',
    'Company.FaxNumber.Placeholder': 'кроме) 0101234567',
    'Company.BusinessRegNum.Placeholder': 'Введите номер оператора',
    'Company.CeoName.Placeholder':
        'Введите имя генерального директора/управляющего филиалом.',
    'Company.Homepage.Placeholder': 'Перейти на веб-сайт.',
    'Company.CompanyBusiness.Placeholder':
        'Введите бизнес-статус вашей компании.',
    'Company.RepBusinessType.Placeholder':
        'Введите организационно-правовую форму.',
    'Company.BusinessType.Placeholder':
        'Введите организационно-правовую форму.',
    // 'Company.BusinessId.Placeholder': 'Введите идентификационный номер предприятия.',
    // 'Company.BranchId.Placeholder': 'Введите идентификационный номер филиала.',
    'Company.BusinessId.Placeholder': 'Выберите предприятие.',
    'Company.BranchId.Placeholder': 'Выберите филиал.',
    'Company.Address.Address.Placeholder':
        'Пожалуйста, найдите почтовый индекс.',
    'Company.Address.DetailedAddress.Placeholder':
        'Пожалуйста, введите название улицы.',
    'TestCarConfig.Toast.createTestCarConfig.Success':
        'Комплектация была зарегистрирована.',
    'TestCarConfig.Toast.ReservationHour.Fail':
        'Время начала бронирования равно или превышает время окончания.',
    'Holiday.Create.Info': 'Регистрация праздничного дня',
    'Holiday.HolidayName': 'Название праздничного дня',
    'Holiday.Date': 'Дата',
    'Holiday.Toast.getHolidayList.Fail':
        'Не удалось получить список праздничных дней.',
    'Holiday.Toast.getHolidayDetail.Fail':
        'Не удалось получить сведения о праздничном дне.',
    'Holiday.Toast.createHoliday.Success':
        'Праздничный день был зарегистрирован.',
    'Holiday.Toast.createHoliday.Fail':
        'Не удалось зарегистрировать праздничный день.',
    'Holiday.Toast.updateHoliday.Success': 'Праздничный день был обновлен.',
    'Holiday.Toast.updateHoliday.Fail': 'Не удалось обновить праздничный день.',
    'Holiday.Toast.deleteHoliday.Success': 'Праздничный день был удален.',
    'Holiday.Toast.deleteHoliday.Fail': 'Не удалось удалить праздничный день.',
    'Menu.Accident': 'Информация об аварии/ремонте',
    // 'Title.AccidentCreate': 'Регистрация аварии/ремонта',
    'Title.AccidentDetail': 'Информация об аварии/ремонте',
    'Accident.Btn.Create': 'Регистрация аварии',
    'Accident.Date': 'Дата аварии',
    'Accident.Location': 'Филиал',
    'Accident.Vehicle.Information': 'Информация о транспортном средстве',
    'Accident.Vehicle.LicensePlateNum': 'Номер транспортного средства',
    'Accident.RepairType': 'Тип аварии',
    'Accident.Status': 'Статус',
    'Accident.Id': 'Номер заявки',
    'Accident.Company': 'Компания',
    'Accident.Status.Occur': 'Авария',
    'Accident.Status.Requested': 'Затребованный ремонт',
    'Accident.Status.Repairing': 'Ремонт',
    'Accident.Status.Repaired': 'Отремонтировано',
    'Accident.Status.Unknown': 'Неизвестно',
    'Accident.Filter.LicensePlateNumber': 'Номер транспортного средства',
    'Accident.Filter.RepairType': 'Тип аварии',
    'Accident.Filter.VehicleModel': 'Модель транспортного средства',
    'Accident.Filter.Location': 'Филиал',
    'Accident.Filter.Id': 'Номер заявки',
    'Accident.Filter.Company': 'Компания',
    'Accident.VehicleInfo': 'Информация о транспортном средстве',
    'Accident.ReservationInfo': 'Информация об аренде',
    'Accident.RepairInfo': 'Сведения о ремонте',
    'Accident.CostInfo': 'Обработка затрат',
    'Accident.InsuranceInfo': 'Страховая компания другой стороны',
    'Accident.Get.Fail': 'Невозможно получить информацию об аварии/ремонте.',
    'Accident.Create.Succes': 'Авария зарегистрирована успешно.',
    'Accident.Create.Fail': 'Регистрация аварии не выполнена.',
    'AccidentVehicle.LicensePlateNum': 'Номер транспортного средства',
    'AccidentVehicle.Company': 'Компания',
    'AccidentVehicle.AccidentDate': 'Дата аварии',
    'AccidentVehicle.Location': 'Филиал',
    'AccidentVehicle.AccidentStatus': 'Статус',
    'AccidentVehicle.AccidentType': 'Тип аварии',
    'AccidentVehicle.Search.Vehicle': 'Поиск транспортного средства',
    'AccidentVehicle.Search.Company': 'Поиск компании',
    'AccidentVehicle.AccidentDate.DayPicker': 'Дата аварии',
    'AccidentVehicle.Repair': 'Дата ремонта',
    'AccidentVehicle.Repair.Start': 'Дата начала ремонта',
    'AccidentVehicle.Repair.End': 'Дата окончания ремонта',
    'AccidentReservation.ReservationCode': 'Код бронирования',
    'AccidentReservation.ReservationDate': 'Срок аренды',
    'AccidentReservation.CustomerName': 'Имя клиента',
    'AccidentReservation.LicenseType': 'Тип лицензии',
    'AccidentReservation.LicenseNumber': 'Номер водительского удостоверения',
    'AccidentReservation.Tel': 'Номер телефона',
    'AccidentReservation.Search.ReservationInfo':
        'Ссылка на информацию об аренде',
    'AccidentRepair.RepairDetail': 'Сведения о ремонте',
    'AccidentRepair.RepairBefore': 'До ремонта',
    'AccidentRepair.RepairAfter': 'После ремонта',
    'Accident.RepairBefore.Placeholder': 'Введите элементы до ремонта.',
    'Accident.RepairAfter.Placeholder': 'Введите элементы после ремонта.',
    'AccidentInsurance.InsuranceCompany': 'Страховая компания другой стороны',
    'AccidentInsurance.InsuranceCode':
        'Регистрационный номер страховки другой стороны',
    'AccidentInsurance.InsuranceManager': 'Менеджер',
    'AccidentInsurance.InsuranceManagerTel': 'Номер телефона менеджера',
    'AccidentCost.InsuranceTreatement': 'Страховой режим',
    'AccidentCost.Company': 'Страховая компания',
    'AccidentCost.InsuranceCode': 'Номер заявки',
    'AccidentCost.OpponentInjury': 'Для лица',
    'AccidentCost.OpponentVehicle': 'Для объектов',
    'AccidentCost.OwnVehicle': 'Страхование от столкновений',
    'AccidentCost.OwnInjury': 'Причинение вреда самому себе',
    'AccidentCost.Other': 'Другое',
    'AccidentCost.TotalInsuranceCost': 'Общие расходы по страхованию',
    'AccidentCost.CompanyTreatment': 'Рассматриваемая компания',
    'AccidentCost.InsuranceCompanyCharge': 'Страховая франшиза компании',
    'AccidentCost.TotalCompanyCost': 'Общая стоимость компании',
    'AccidentCost.AmountOfAccident': 'Собранная сумма',
    'AccidentCost.IndemnityCost': 'Размер возмещения',
    'AccidentCost.CompensationFee': 'Компенсационный сбор',
    'AccidentCost.Unrepaired': 'Не отремонтировано',
    'AccidentCost.TotalCollectCost': 'Общая собранная сумма',
    'AccidentCost.TotalCost': 'Итого',
    'AccidentCost.Status': 'Статус платежа',
    'AccidentCost.PaymentMethod': 'Способ оплаты',
    'AccidentBusiness.BusinessType.Placeholder': 'Выберите оператора',
    'Accident.GetVehicle.Fail':
        'Не удалось получить список транспортных средств.',
    'Accident.GetBusiness.Fail': 'Не удалось получить список операторов.',
    'Accident.ReservationCheckParam.Fail':
        'Введите номер транспортного средства и дату аварии.',
    'Accident.GetReservationCheck.Fail':
        'Не удалось открыть ссылку с информацией об аренде.',
    'Accident.Fail.ConnectRentInformation':
        'Информации об аренде не существует.',
    'Accident.Delete.Success': 'Данные об аварии были удалены.',
    'Accident.Edit.Success': 'Данные об аварии были отредактированы.',
    'Accident.Create.Success': 'Данные об аварии были зарегистрированы.',
    'Accident.Delete.Fail': 'Не удалось удалить данные об аварии.',
    'Accident.Edit.Fail': 'Не удалось изменить данные об аварии.',
    'Accident.Confirm': 'Удалить данные об аварии',
    'Accident.Confirm.Delete': 'Хотите удалить данные об аварии?',
    'Accident.Confirm.Edit': 'Хотите отредактировать данные об аварии?',
    'Accident.Confirm.Create': 'Хотите зарегистрировать данные об аварии?',
    'Menu.Branch': 'Филиал',
    'Title.Branch': 'Филиал',
    'Branch.Name': 'Название филиала',
    'Branch.BusinessRegNum': '№ оператора',
    'Branch.PhoneNumber': 'Номер телефона',
    'Branch.FaxNumber': 'Номер факса.',
    'Branch.Address': 'Адрес',
    'Branch.ManagerName': 'Имя',
    'Branch.ManagerPhoneNumber': 'Номер телефона',
    'Branch.ManagerEmail': 'Адрес электронной почты',
    'Branch.Title.Create': 'Регистрация филиала',
    'Branch.Company': 'Филиал',
    'Branch.BusinessID': 'Название предприятия',
    'Branch.BusinessID.Placeholder': 'Введите название предприятия',
    'Branch.Name.Placeholder': 'Введите название филиала.',
    'Branch.BusinessRegNum.Placeholder': 'Введите номер оператора',
    'Branch.PhoneNumber.Placeholder': 'Введите номер телефона',
    'Branch.FaxNumber.Placeholder': 'Введите номер факса.',
    'Branch.ManagerName.Placeholder': 'Введите имя генерального директора.',
    'Branch.ManagerPhoneNumber.Placeholder':
        'Введите номер телефона генерального директора/управляющего филиалом.',
    'Branch.ManagerEmail.Placeholder':
        'Введите адрес электронной почты генерального директора/управляющего филиалом.',
    'Branch.Company.Placeholder': 'Введите филиал.',
    'Branch.Address.Address.Placeholder':
        'Пожалуйста, найдите почтовый индекс.',
    'Branch.Address.DetailedAddress.Placeholder':
        'Пожалуйста, введите название улицы.',
    'Accident.PaymentStatus.Unpaid': 'Не оплачено',
    'Accident.PaymentStatus.Paid': 'Оплачено',
    'Accident.PaymentType.Card': 'Карта',
    'Accident.PaymentType.Cash': 'Наличные',
    'Accident.GetBranch.Fail': 'Не удалось получить информацию о филиале.',
    'Accident.GetCompany.Fail': 'Не удалось загрузить список компаний.',
    'Code.MsgKey': 'Ключ кода',
    'Menu.VehicleInsurance': 'Управление страхованием',
    'VehicleInsurance.Get.Fail':
        'Не удалось получить данные по управлению страхованием.',
    'VehicleInsurance.LicensePlateNumber': 'Номер транспортного средства',
    'VehicleInsurance.InsureYear': 'N-й год',
    'VehicleInsurance.InsureCondition': 'Застрахованное состояние',
    'VehicleInsurance.VehicleGrade': 'Класс транспортного средства',
    'VehicleInsurance.VehicleModelName':
        'Название модели транспортного средства',
    'VehicleInsurance.CompanyName': 'Страховая компания',
    'VehicleInsurance.StartDate': 'Дата начала действия страховки',
    'VehicleInsurance.EndDate': 'Дата истечения срока действия страховки',
    'VehicleInsurance.Filter.LicensePlateNumber':
        'Номер транспортного средства',
    'VehicleInsurance.Filter.CompanyName': 'Страховая компания',
    'Title.VehicleInsurance': 'Управление страхованием транспортного средства',
    'VehicleInsurance.VehicleInformation': 'Информация о транспортном средстве',
    'VehicleInsurance.InsuranceInformation': 'Информация о страховании',
    'VehicleInsurance.insureSelfVehicle':
        'Страховое покрытие на случай столкновений',
    'VehicleInsurance.PaymentDueDay': 'Дата платежа',
    'VehicleInsurance.InsurePayment': 'Оплаченный страховой взнос',
    'VehicleInsurance.paymentMethod': 'Способ оплаты',
    'VehicleInsurance.insureSelfVehicle.true': 'Да',
    'VehicleInsurance.insureSelfVehicle.false': 'Нет',
    'VehicleInsurance.paymentMethod.true': 'Полностью оплачено',
    'VehicleInsurance.paymentMethod.false': 'Ежемесячный взнос',
    'VehicleInsurance.MonthPer': 'Количество взносов',
    'VehicleInsurance.MonthPerPayment': 'Ежемесячный платеж',
    'VehicleInsurance.VehiclePremium': 'Информация о страховых взносах',
    'VehicleInsurance.insureOrder': 'Последовательность',
    'VehicleInsurance.paymentDate': 'Дата платежа',
    'VehicleInsurance.monthPerPayment': 'Ежемесячный платеж',
    'VehicleInsurance.delete': 'Удалить',
    'Menu.MsgKey': 'Кнопка входа в меню',
    'Menu.MsgKey.Placeholder': 'Введите кнопку входа в меню',
    'Title.ShortRental': 'Автомобиль, сдаваемый краткосрочно в аренду',
    'ShortRental.Type': 'Категория арендуемого автомобиля',
    'ShortRental.Type.Public': 'Автомобили для аренды',
    'ShortRental.Type.Private': 'Предприятие',
    'ShortRental.Type.Rent':
        'Зарегистрировать автомобиль для краткосрочной аренды',
    'ShortRental.Type.Reservation': 'Бронирование краткосрочной аренды',
    'ShortRental.RentalInfo':
        'Аренда автомобилей и информация о транспортном средстве',
    'ShortRental.Duration': 'Срок аренды',
    'ShortRental.Duration.Value': '{days} дни {hours} часы',
    'ShortRental.Insurance.Type': 'Тип страхования',
    'ShortRental.Insurance.Age.Placeholder': 'Выберите страховой возраст.',
    'ShortRental.Insurance.Type.Placeholder': 'Выберите тип страхования.',
    'Reservation.EndLocation': 'Место возврата',
    'Reservation.EndLocation.Placeholder': 'Выберите место возврата.',
    'Common.Deletes.Fail':
        'Некоторые элементы не могут быть удалены. Проверьте данные.',
    'Reservation.OwnVehicle': 'Статус страхования от столкновений',
    'Reservation.CarInfo': 'Информация о транспортном средстве',
    'Reservation.SelectCar': 'Ассортимент транспортных средств',
    'Reservation.CarNumber': 'Номер транспортного средства',
    'Reservation.CarModel': 'Модель транспортного средства',
    'Reservation.CarColor': 'Цвет транспортного средства',
    'Reservation.CarFuel': 'Тип топлива',
    'Reservation.CarRate': 'Класс транспортного средства',
    'Reservation.Agent': 'Ответственное лицо',
    'Reservation.RentAgent': 'Агент по аренде',
    'Reservation.ReturnAgent': 'Агент по возврату',
    'Reservation.Agent.Placeholder': 'Выберите сотрудника.',
    'Reservation.Join': 'Присоединиться',
    'Reservation.NotJoin': 'Не застраховано',
    'Reservation.Indemnity.title': 'Информация о размере возмещения',
    'Reservation.Run.title': 'Топливо и дальность поездки',
    'Reservation.Run.Fuel': 'Уровень топлива',
    'Reservation.Run.Run': 'Дальность поездки',
    'Reservation.Run.Rental': 'Арендуемый автомобиль',
    'Reservation.Run.Return': 'Возврат',
    'ShortRental.OwnVehicle.Type.Placeholder': 'Выберите тип подписки.',
    'ShortRental.User.Public': 'Предприятие',
    'ShortRental.User.Private': 'Частный пользователь',
    'ShortRental.User.SelectBusiness': 'Найти существующее предприятие',
    'ShortRental.User.Name.Error': 'Введите имя.',
    'ShortRental.User.ZipCode.Error': 'Введите почтовый индекс.',
    'ShortRental.User.SelectUser': 'Найти действующего члена',
    'ShortRental.User.SelectEmployee': 'Найти сотрудника',
    'ShortRental.User.Contact': 'Контактные данные пользователя',
    'ShortRental.User.Contact.Placeholder': 'Введите свои контактные данные.',
    'ShortRental.User.Contact.Error': 'Введите свои контактные данные.',
    'ShortRental.User.Address.Error': 'Введите адрес.',
    'ShortRental.User.Email': 'Адрес электронной почты',
    'ShortRental.User.Email.Placeholder':
        'Пожалуйста, введите адрес электронной почты.',
    'ShortRental.User.Fax': 'Факс',
    'ShortRental.User.Fax.Placeholder': 'Введите номер факса.',
    'ShortRental.User.BusinessRegNum.Error': 'Введите номер оператора',
    'ShortRental.User.DetailAddress.Error': 'Введите подробный адрес.',
    'ShortRental.User.FirstDriver': 'Первый водитель',
    'ShortRental.User.SecondDriver': 'Второй водитель',
    'ShortRental.Charge.Title': 'Информация о тарифах',
    'ShortRental.Charge.Discount.title': 'Информация о скидке',
    'ShortRental.Charge.Default': 'Платеж по умолчанию',
    'ShortRental.Charge.Discount': 'Тариф со скидкой',
    'ShortRental.Charge.Rental': 'Арендная плата',
    'ShortRental.Charge.Real': 'Арендная плата',
    'ShortRental.Charge.Additional': 'Дополнительная плата',
    'ShortRental.Charge.Etc': 'Кроме страховых взносов',
    'ShortRental.Charge.Sum': 'Общая арендная плата',
    'ShortRental.Etc.Special': 'Проблемы особого рода',
    'ShortRental.Etc.Memo': 'Памятка',
    'ShortRental.Car.Select': 'Нет доступных для выбора транспортных средств.',
    'ShortRental.DueDate.Empty': 'Введите время и дату аренды.',
    'Title.LongRental': 'Долгосрочная аренда нового автомобиля',
    'LongRental.Type': 'Категория арендуемого автомобиля',
    'LongRental.Type.Estimate':
        'Смета на долгосрочную аренду нового автомобиля',
    'LongRental.Type.Create': 'Оформить долгосрочную аренду нового автомобиля',
    'LongRental.ChargeInfo': 'Информация о тарифах',
    'LongRental.RentalDeposit': 'Арендная плата',
    'LongRental.ExtraCharge': 'Дополнительный взнос',
    'LongRental.ExtraItem': 'Дополнительная единица',
    'LongRental.MonthlyCharge.Title': 'Ежемесячная плата',
    'LongRental.MonthlyCharge': 'Арендная плата',
    'LongRental.MonthlyTotal': 'Итого',
    'LongRental.ContractNumber': 'Номер договора',
    'LongRental.ContractStatus': 'Статус цен',
    'LongRental.Info.Repair': 'Включение технического обслуживания',
    'LongRental.Repair.Include': 'Включить',
    'LongRental.Repair.NonInclude': 'Не включено',
    'LongRental.Payment.first': 'Первый взнос',
    'LongRental.Payment.Final': 'Окончательный платеж',
    'LongRental.Payment.Day': 'Дата платежа',
    'LongRental.Payment.Charge': 'Сумма платежа',
    'LongRental.Payment.Month': 'Дата ежемесячного платежа',
    'LongRental.Payment.Beginning': 'Дата первоначального взноса',
    'LongRental.CommonDriverInfo': 'Общая информация о водителе',
    'LongRental.User.DriverName': 'Водитель-напарник',
    'LongRental.User.DriverName.Placeholder':
        'Введите стандартное имя водителя.',
    'LongRental.User.Contact': 'Контактные данные пользователя',
    'LongRental.User.Contact.Placeholder': 'Введите свои контактные данные.',
    'LongRental.User.Birth': 'Дата рождения',
    'LongRental.User.Birth.Placeholder':
        'Первые шесть цифр идентификационного номера резидента, например, 990126',
    'LongRental.User.DriverLicense': 'Водительское удостоверение',
    'LongRental.Kind.DriverLicense.Placeholder': 'Выберите тип лицензии.',
    'Title.MonthRental': 'Долгосрочная аренда автомобиля на ежемесячной основе',
    'MonthRental.Type': 'Категория арендуемого автомобиля',
    'MonthRental.Type.Reservation':
        'Забронировать долгосрочную аренду автомобиля на ежемесячной основе',
    'MonthRental.Type.Create':
        'Оформить долгосрочную аренду автомобиля на ежемесячной основе',
    'MonthRental.ReservationNumber': 'Номер бронирования',
    'MonthRental.ReservationStatus': 'Статус бронирования',
    'Rent.RentalDueDate': 'Предполагаемая дата аренды',
    'MonthRental.ExtraCharge': 'Дополнительная плата',
    'Menu.Vehicle': 'Управление транспортным средством',
    'Menu.Maintenance':
        'Управление техническим обслуживанием транспортного средства',
    'Menu.Operation': 'Процесс обслуживания',
    'Menu.Vehicle.Insurance': 'Управление страховыми взносами',
    'Menu.Rent': 'Управление арендуемым автомобилем',
    'Menu.Payment': 'Управление продажами/расчетами',
    'Menu.Work': 'Управление работой Mocean',
    'Menu.System': 'Управление системами',
    'Common.Get.Fail': 'Не удалось получить данные.',
    'Menu.History': 'История',
    'Title.ControlHistory': 'История управления транспортным средством',
    'Title.ControlHistory.Request': 'Детали запроса',
    'Title.ControlHistory.Response': 'Детали ответа',
    'ControlHistory.Filter.LicensePlateNumber': 'Номер транспортного средства',
    'ControlHistory.Filter.UserName': 'Имя',
    'ControlHistory.HistoryTime': 'Время и дата страхового события',
    'ControlHistory.UserName': 'Имя',
    'ControlHistory.LicensePlateNumber': 'Номер транспортного средства',
    'ControlHistory.ReqTime': 'Время запроса',
    'ControlHistory.ResTime': 'Время отклика',
    'ControlHistory.Action': 'Действие',
    'ControlHistory.ReqResultMessage': 'Результат запроса',
    'ControlHistory.ResResultCode': 'Код отклика',
    'ControlHistory.ResData': 'Данные ответа',
    'ControlHistory.ReqData': 'Запрошенные данные',
    Vehicle: 'Транспортное средство',
    'Vehicle.Tab.Detail': 'Данные о транспортном средстве',
    'Vehicle.Tab.Purchase': 'Информация о покупке',
    'Vehicle.Tab.Accident': 'Авария/ремонт',
    'Vehicle.PurchaseMethod': 'Метод покупки',
    'Vehicle.PurchaseCost': 'Стоимость покупки',
    'Vehicle.Purchase.BasicCost': 'Базовая стоимость автомобиля',
    'Vehicle.Purchase.BasicCost.Placeholder': 'Базовая стоимость автомобиля',
    'Vehicle.Purchase.SelectCost': 'Параметры',
    'Vehicle.Purchase.SelectCost.Placeholder': 'Параметры',
    'Vehicle.Purchase.Consignment': 'Плата за отправку',
    'Vehicle.Purchase.Consignment.Placeholder': 'Плата за отправку',
    'Vehicle.Purchase.PureVehiclePrice':
        'Фактическая стоимость транспортного средства',
    'Vehicle.Purchase.PureVehiclePrice.Placeholder':
        'Фактическая стоимость транспортного средства',
    'Vehicle.Purchase.TotalCost': 'Общая стоимость покупки',
    'Vehicle.Pay.DownPayment': 'Первоначальный взнос',
    'Vehicle.Pay.DownPayment.Placeholder': 'Первоначальный взнос',
    'Vehicle.Pay.DeliveryPayment': 'Оплата доставки',
    'Vehicle.Pay.DeliveryPayment.Placeholder': 'Оплата доставки',
    'Vehicle.Pay.TotalLeasePayment': 'Общая сумма взноса',
    'Vehicle.Pay.TotalLeasePayment.Placeholder': 'Общая сумма взноса',
    'Vehicle.PayInfo': 'Условия оплаты',
    'Vehicle.AdditionalFee': 'Дополнительная стоимость',
    'Vehicle.Additional.MandatoryPremium': 'Обязательный страховой взнос',
    'Vehicle.Additional.MandatoryPremium.Placeholder':
        'Обязательный страховой взнос',
    'Vehicle.Additional.StampPrice': 'Стоимость почтовой марки',
    'Vehicle.Additional.StampPrice.Placeholder': 'Стоимость почтовой марки',
    'Vehicle.Additional.EtcPrice': 'Другие расходы',
    'Vehicle.Additional.EtcPrice.Placeholder': 'Другие расходы',
    'Vehicle.Additional.TotalCost': 'Полная дополнительная стоимость',
    'Vehicle.RegistrationFee': 'Плата за регистрацию транспортного средства',
    'Vehicle.Registration.TotalCost': 'Итого регистрационных сборов',
    'Vehicle.Registration.AcquisitionTax': 'Налог на приобретение',
    'Vehicle.Registration.BoundDiscount': 'Скидка',
    'Vehicle.Registration.RegistrationStampPrice': 'Стоимость почтовой марки',
    'Vehicle.Registration.LicensePlateNumberPrice': 'Цена номерного знака',
    'Vehicle.Registration.RegistrationFee':
        'Регистрационные сборы регистрирующего органа',
    'Vehicle.Registration.AcquisitionTax.Placeholder': 'Налог на приобретение',
    'Vehicle.Registration.BoundDiscount.Placeholder': 'Скидка',
    'Vehicle.Registration.RegistrationStampPrice.Placeholder':
        'Стоимость почтовой марки',
    'Vehicle.Registration.LicensePlateNumberPrice.Placeholder':
        'Цена номерного знака',
    'Vehicle.Registration.RegistrationFee.Placeholder':
        'Регистрационные сборы регистрирующего органа',
    'Vehicle.TotalFee': 'Общая стоимость покупки',
    'Vehicle.Available': 'Проверить доступные транспортные средства',
    'Title.LicenseCheckHistory': 'История проверки водительского удостоверения',
    'LicenseCheckHistory.Filter.UserID': 'Идентификатор пользователя',
    'LicenseCheckHistory.Filter.PhoneNumber': 'Номер телефона',
    'LicenseCheckHistory.Filter.ResidentName': 'Имя водителя',
    'LicenseCheckHistory.Filter.ResidentDate': 'Дата рождения водителя',
    'LicenseCheckHistory.Filter.ResponseCode': 'Код отклика',
    'LicenseCheckHistory.LicenseNo': 'Номер водительского удостоверения',
    'LicenseCheckHistory.LicnConCode': 'Тип водительского удостоверения',
    'LicenseCheckHistory.UserID': 'Идентификатор пользователя',
    'LicenseCheckHistory.PhoneNumber': 'Номер телефона',
    'LicenseCheckHistory.ResidentName': 'Имя водителя',
    'LicenseCheckHistory.ResidentDate': 'Дата рождения водителя',
    'LicenseCheckHistory.ResponseCode': 'Результат ответа',
    'LicenseCheckHistory.ResponseMessage': 'Ответное сообщение',
    'LicenseCheckHistory.Date': 'Часы аренды',
    'LicenseCheckHistory.StartDate': 'Время начала аренды',
    'LicenseCheckHistory.EndDate': 'Время окончания аренды',
    'LicenseCheckHistory.CreatedAt': 'Время запроса',
    'Title.RentalSearch': 'Поиск доступных для аренды транспортных средств',
    'RentalSearch.Rental.Day': 'Дата аренды',
    'RentalSearch.Rental.UseCarYear': 'Год выпуска модели',
    'RentalSearch.Rental.UseCarFuel': 'Используемое топливо',
    'RentalSearch.CarFuel': 'Топливо',
    'RentalSearch.CarLocation': 'Местонахождение автомобиля',
    'RentalSearch.Title.RentalDetail': 'Данные о транспортном средстве',
    'RentalSearch.CarAnsic': 'Год выпуска модели',
    'RentalSearch.FixedPeople': 'Количество пассажиров',
    'RentalSearch.CarInsurance': 'Страхование арендуемого автомобиля',
    'Business.Extra.Service': 'Дополнительные услуги',
    'Business.TestCar': 'Тестовый автомобиль',
    'Common.Btn.Update': 'Изменить',
    'Terminal.Title.State': 'Статус',
    'Common.Btn.DetailAndUpdate': 'Основные/дополнительные сведения',
    'Customer.Title.Private': 'Персональные данные',
    'Title.Branch.Info': 'Основная информация о филиале',
    'Vehicle.BasicInfo.CarNumber': 'Номер автомобиля',
    'Vehicle.BasicInfo.TerminalNumber': 'Номер терминала',
    'Vehicle.BasicInfo.UseCarYear': 'Год выпуска модели',
    'Vehicle.BasicInfo.Transmission': 'Передача',
    'Vehicle.BasicInfo.CreatedDate': 'Дата регистрации транспортного средства',
    'Vehicle.BasicInfo.InsuranceAge': 'Страховой возраст',
    'Vehicle.BasicInfo.Option': ' Параметр',
    'Vehicle.BasicInfo.BusinessName': 'Название предприятия',
    'Vehicle.BasicInfo.Location': 'Штаб-квартира',
    'Vehicle.BasicInfo.Color': 'Цвет',
    'Vehicle.BasicInfo.FuelType': 'Тип топлива',
    'Vehicle.BasicInfo.CarType': 'Класс транспортного средства',
    'Vehicle.BasicInfo': 'Основная информация',
    'Vehicle.BasicInfo.AvailableDistance': 'Доступное расстояние',
    'Vehicle.BasicInfo.TotalDistance': 'Общая дальность поездки',
    'Vehicle.BasicInfo.AuxBattery': 'Вспомогательный аккумулятор',
    'Vehicle.BasicInfo.TempFuelUsage': 'Временное использование топлива',
    'Vehicle.BasicInfo.FrontLeft': 'Переднее колесо/Левое',
    'Vehicle.BasicInfo.FrontRight': 'Переднее колесо/Правое',
    'Vehicle.BasicInfo.BackLeft': 'Заднее колесо/Левое',
    'Vehicle.BasicInfo.BackRight': 'Заднее колесо/Правое',
    'Vehicle.BasicInfo.DoorOpen': 'Открыть дверь',
    'Vehicle.BasicInfo.DoorClose': 'Закрыть дверь',
    'Vehicle.BasicInfo.Siren': 'Аварийный световой/звуковой сигнал',
    'Common.Btn.UpdateVehicle': 'Изменение информации о транспортном средстве',
    'Vehicle.BasicInfo.Type': 'Тип',
    'Title.Company.Info': 'Информация о компании',
    'Common.Btn.Setup': 'Настройки',
    'Common.Btn.Setup.Complete': 'Настройка завершена',
    'Common.Btn.DuplicateCheck': 'Проверка на наличие дубликатов',
    'Vehicle.BasicInfo.CreateDate': 'Дата первой регистрации',
    'Vehicle.BasicInfo.ExpireDate': 'Срок годности транспортного средства',
    'MyPage.Business.Code.Placeholder': 'Введите код предприятия',
    'MyPage.Branch': 'Филиал',
    'MyPage.Branch.Placeholder': 'Выберите предприятие и филиал.',
    'Vehicle.BasicInfo.VehicleImage': 'Изображение транспортного средства',
    'Vehicle.BasicInfo.Terminal': 'Терминал',
    'Vehicle.InterpersonalCompensation1': 'Платежное обязательство для лица 1',
    'Vehicle.InterpersonalCompensation2': 'Платежное обязательство для лица 2',
    'Vehicle.Reparation1': 'Платежное обязательство для объектов 1',
    'Vehicle.UninsuredCarInjury': 'Незастрахованное повреждение автомобиля',
    'Vehicle.PhysicalAccident': 'Авария с причинением себе вреда',
    'Vehicle.Interpersonal': 'Для лица',
    'Vehicle.InterVehicle': 'Для объектов',
    'Vehicle.SelfDamage': 'Причинение вреда самому себе',
    'Vehicle.VehicleDamage': 'Страхование от столкновений',
    'Vehicle.VehicleDamage.Registration':
        'Страховое покрытие на случай столкновений',
    'Vehicle.VehicleDamage.Join': 'Присоединиться',
    'Vehicle.VehicleDamage.NotJoin': 'Не зарегистрировано',
    'Title.VehicleEdit': 'Новые транспортные средства',
    'Menu.Password': 'Изменить пароль',
    'Menu.MyBusiness': 'Мой оператор',
    'Menu.MyBranch': 'Мой филиал',
    UNKNOWN: 'Неизвестно',
    'BusinessMember.RoleDetail.Business': 'Администратор-представитель',
    'BusinessMember.RoleDetail.Branch': 'Администратор филиала',
    'BusinessMember.RoleDetail.Staff': 'Сотрудник',
    'Business.Title.License': 'Лицензия по подписке',
    'Business.License.Name': 'Название лицензии',
    'Vehicle.Duplicate.Fail': 'Номер транспортного средства уже существует.',
    'Vehicle.Duplicate.Required':
        'Проверьте, не был ли номер транспортного средства продублирован.',
    'Vehicle.Duplicate.Success':
        'Номер транспортного средства доступен для регистрации.',
    'InsuranceRental.Title': 'Страхование арендуемого автомобиля',
    'InsuranceRental.Type.Reservation':
        'Бронирование страховки на аренду автомобиля ',
    'InsuranceRental.Type.Creation':
        'Оформление страховки на аренду автомобиля',
    'InsuranceRental.Charge.Amount': 'Сумма счета',
    'InsuranceRental.Charge.Daily': 'Ежедневная плата',
    'InsuranceRental.Charge.Time': 'Почасовая оплата',
    'InsuranceRental.Charge.Rate': 'Другие тарифы',
    'InsuranceRental.Charge.Rate.Charge': 'Ставка оплаты',
    'InsuranceRental.Charge.Rate.Mistake': 'Частота отказов',
    'InsuranceRental.Charge.TotalAmount': 'Итого сборов',
    'InsuranceRental.Charge.TotalAmount.Example':
        '((ежедневные платежи * количество дней) + (почасовые платежи * количество часов)) * ставка * (100% - степень повреждения)',
    'InsuranceRental.Charge.Real': 'Фактическая заявленная сумма',
    'InsuranceRental.Accident.Title': 'Информация об аварии',
    'InsuranceRental.Accident.Damage': 'Информация об ущербе',
    'InsuranceRental.Accident.Assault': 'Информация о нападении',
    'InsuranceRental.Accident.Name': 'Имя',
    'InsuranceRental.Accident.CarNum': 'Номер транспортного средства',
    'InsuranceRental.Accident.CarModel': 'Модель транспортного средства',
    'InsuranceRental.Accident.MaintenanceInfo':
        'Информация о техническом обслуживании',
    'InsuranceRental.Accident.MaintenanceShop':
        'Станция технического обслуживания',
    'InsuranceRental.Insurance.Title': 'Информация о страховании',
    'InsuranceRental.Insurance.CompanyInfo': 'Информация о страховой компании',
    'InsuranceRental.Insurance.CompanyCode': 'Страховая компания',
    'InsuranceRental.Insurance.InsuranceCode': 'Код заявки на страховку',
    'InsuranceRental.Insurance.Manager': 'Менеджер по страхованию',
    'InsuranceRental.Insurance.Charge.Title': 'Платежные реквизиты',
    'InsuranceRental.Insurance.Charge.Date': 'Дата выставления счета',
    'InsuranceRental.Insurance.Charge.Amount': 'Итого сборов',
    'InsuranceRental.Insurance.Deposit.Date': 'Дата арендной платы',
    'InsuranceRental.Insurance.Deposit.Amount': 'Сумма арендной платы',
    'InsuranceRental.Insurance.Mistake.Amount':
        'Сборы за ущерб, нанесенный клиенту',
    'InsuranceRental.Insurance.OwnVehicle.Amount':
        'Сумма страхового покрытия на случай столкновений',
    'InsuranceRental.Insurance.Payment.Type': 'Тип платежа',
    'InsuranceRental.Insurance.Payment.Type.Card': 'Карта',
    'InsuranceRental.Insurance.Payment.Type.Cash': 'Наличные',
    'Common.Btn.Search': 'Поиск',
    'Reservation.Label.StartDate': 'Дата начала аренды',
    'Reservation.Label.SearchType': 'Поиск элемента',
    'Reservation.Label.Type': 'Категория арендуемого автомобиля',
    'Reservation.Label.Status': 'Статус бронирования',
    'Reservation.Label.DueDate': 'Предполагаемое время и дата возврата',
    'Title.RentList': 'Управление данными об аренде',
    'Vehicle.DuplicateCheck.FieldNull': 'Введите номер транспортного средства.',
    'Common.Btn.DeleteVehicle': 'Удаление информации о транспортном средстве',
    'Menu.Vehicle.Mgnt': 'Управление регистрацией транспортного средства',
    'Menu.Vehicle.Insurance.Payment.Mgnt':
        'Управление информацией о страховании',
    'Menu.Insurance.Payment.Mgnt': 'Управление информацией о страховании',
    'Menu.Vehicle.Purchase.Payment.Mgnt': 'Управление ежемесячными взносами',
    'Menu.Vehicle.Accident.Mgnt': 'Управление аварийным ремонтом',
    'Menu.Vehicle.Penalty.Pee.Mgnt': 'Управление штрафами',
    'Menu.Branch.Mgnt': 'Управление филиалами',
    'Menu.Business.Member.Mgnt': 'Управление бизнес-членами',
    'Menu.Business.Customer.Mgnt': 'Управление бизнес-клиентами',
    'Menu.Customer.Mgnt': 'Управление клиентами',
    'Menu.Member.Mgnt': 'Управление сотрудниками',
    'Menu.License': 'Управление лицензиями',
    'Menu.Code': 'Управление кодом',
    'Menu.Menu': 'Управление меню',
    'Menu.Role': 'Управление полномочиями',
    'Menu.Program': 'Управление программами',
    'Menu.Message': 'Управление сообщениями',
    'Menu.Rent.Fare': 'Управление арендными платежами',
    'Menu.Vehicle.Rent.Fare': 'Плата за аренду автомобиля',
    'Menu.Rent.Insurance.Fare':
        'Управление страховыми взносами за арендуемый автомобиль',
    'Menu.Vehicle.Rent.Insurance.Fare': 'Страховой взнос за аренду автомобиля',
    'Menu.Rent.Schedule': 'Проверить статус аренды',
    'Menu.Rent.Vehcile.Available':
        'Поиск доступных для аренды транспортных средств',
    'Menu.Reservation.List': 'Просмотреть детали бронирования',
    'Menu.Rent.List': 'Управление данными об аренде',
    'Menu.CompanyUser': 'Управление менеджером компании',
    'Menu.Company': 'Управление компаниями',
    'Reservation.Label.RentalRegister': 'Регистрация арендуемого автомобиля',
    'Common.Confirm.Reservation.Cancle': 'Хотите отменить свое бронирование',
    'Common.Logout.Success': 'Вы благополучно вышли из системы. До свидания',
    'LongRental.Estimate.CarInfo': 'Информация об оцененном автомобиле',
    'LongRental.ReservationDate.Placeholder': 'Выбрать срок аренды',
    'LongRental.Estimate.VehicleName': 'Название транспортного средства',
    'LongRental.Purchase.Cost':
        'Покупная стоимость транспортного средства (в рублях)',
    'LongRental.RentalDeposit.Cost': 'Арендная плата (в рублях)',
    'LongRental.MonthlyCharge.Cost': 'Ежемесячная плата (в рублях)',
    'LongRental.Period': 'Период (месяцы)',
    'LongRental.Options': 'Параметры',
    'LongRental.PenaltyAmount': 'Штраф (%)',
    'LongRental.Note': 'Примечание',
    'LongRental.VehiclePremium': 'Информация об оплате',
    'LongRental.Charge.Method': 'Способ оплаты',
    'LongRental.Charge.Bank': 'Банк для выставления счетов',
    'LongRental.Charge.CountNumber': 'Номер счета',
    'LongRental.Charge.CountOwner': 'Владелец счета',
    'LongRental.Send.Method': 'Место для выставления счетов',
    'LongRental.Pay.Day': 'Дата платежа',
    'LongRental.Charge.Method.Placeholder': 'Выбрать способ оплаты',
    'LongRental.Charge.Bank.Placeholder':
        'Выберите банк для выставления счетов',
    'LongRental.Charge.CountNumber.Placeholder': 'Введите номер счета',
    'LongRental.Charge.CountOwner.Placeholder': 'Введите владельца счета',
    'LongRental.Send.Method.Placeholder':
        'Выберите место для выставления счетов',
    'LongRental.Pay.Day.Placeholder': 'Выбрать дату платежа',
    'Rent.UseState.Type.Day': 'День',
    'Rent.UseState.Type.Month': 'Месяц',
    'Code.GradeType.Placeholder': 'Класс транспортного средства',
    'Code.VehicleStatus.Placeholder': 'Статус аренды',
    'UseState.State.Short': 'Краткосрочно',
    'UseState.State.Insurance': 'Страхование',
    'UseState.State.Month': 'Ежемесячно',
    'UseState.State.Long': 'Долгосрочный',
    'Code.Weekdays.0': 'День',
    'Code.Weekdays.1': 'Месяц',
    'Code.Weekdays.2': 'Вторник',
    'Code.Weekdays.3': 'Среда',
    'Code.Weekdays.4': 'Четверг',
    'Code.Weekdays.5': 'Пятница',
    'Code.Weekdays.6': 'Суббота',
    'VehicleModel.Image': 'Фотография',
    'Branch.ManagerName.Detail':
        'Имя генерального директора/управляющего филиалом',
    'Branch.ManagerPhoneNumber.Detail':
        'Номер телефона генерального директора/управляющего филиалом.',
    'Branch.ManagerEmail.Detail':
        'Адрес электронной почты генерального директора/управляющего филиалом',
    'Rent.Filter.Label.Status': 'Статус аренды',
    'Rent.Filter.Label.IncludeMaintenance': 'Техническое обслуживание включено',
    'Rent.Filter.Label.PaymentDate': 'Дата платежа',
    'Rent.Filter.Code': 'Код арендуемого автомобиля',
    'Rent.Filter.User.Name': 'Имя клиента',
    'Rent.Filter.User.MobileNumber': 'Номер телефона клиента',
    'Rent.Filter.User.Location': 'Место стоянки арендуемого автомобиля',
    'Rent.Filter.Status.Inuse': 'В настоящее время арендован',
    'Rent.Filter.Status.Returned': 'Окончание аренды',
    'Rent.Filter.Status.Canceled': 'Отмена',
    'Rent.Filter.Status.LowPower': 'Низкое напряжение',
    'Rent.Filter.Status.NoReturned': 'Не возвращено',
    'Rent.Filter.Maintenance.Include': 'Включить',
    'Rent.Filter.Maintenance.NotInclude': 'Не включено',
    'Rent.Filter.Payment.5': '5 дней',
    'Rent.Filter.Payment.7': '7 дней',
    'Rent.Filter.Payment.10': '10 дней',
    'Rent.Filter.Payment.15': '15 дней',
    'Rent.Filter.Payment.20': '20 дней',
    'Rent.Filter.Payment.25': '25 дней',
    'Rent.Filter.Payment.EndOfMonth': 'Последний день месяца',
    'Rent.Code': 'Номер договора',
    'Rent.Date': 'Дата аренды',
    'Rent.Status.INUSE': 'В настоящее время арендован',
    'Rent.Status.RETURNED': 'Окончание аренды',
    'Rent.Status.BE_CANCELED': 'Отмена',
    'Rent.Status.LOW_POWER': 'Низкое напряжение',
    'Rent.Status.NO_RETURNED': 'Не возвращено',
    'Rent.IncludeMaintenance': 'Статус технического обслуживания',
    'Rent.Extend': 'Продление срока аренды',
    'Rent.Contract': 'Договор',
    'Rent.status.Requested': 'Запрос на бронирование',
    'Rent.status.Canceled': 'Отменить бронирование',
    'Rent.status.NoShow': 'Транспортное средство не было приобретено',
    'Rent.status.Deleted': 'Удаление бронирования',
    'Rent.status.Denied': 'Отказ в бронировании',
    'Rent.status.CommError': 'Ошибка связи',
    'Rent.status.Estimate': 'Расчет цены выполнен',
    'Rent.status.EstimateCanceled': 'Отменить цену',
    'Rent.status.ChangeToInsurance':
        'Перейти к страхованию арендуемого автомобиля',
    'Rent.status.ChangeToShort': 'Изменить автомобиль для краткосрочной аренды',
    'Rent.status.Insurance.Billing': 'Страховой счет',
    'Rent.status.Billing': 'Договор',
    'Rent.modal.Return.message': 'Хотите вернуть автомобиль?',
    'Rent.modal.Return.Date': 'Время и дата возврата',
    'Rent.modal.Return.Worker': 'Работник',
    'Rent.modal.Return.WorkDate': 'Рабочая дата',
    'Rent.modal.Return.Fail': 'Не удалось обработать возврат.',
    'Rent.modal.Extend.message': 'Хотите продлить период аренды?',
    'Rent.modal.Extend.DueDate': 'Предыдущее время окончания',
    'Rent.modal.Extend.Date': 'Дата окончания продленного периода',
    'Rent.modal.Extend.Fail': 'Не удалось продлить период.',
    'Rent.modal.Force.message': 'Хотите принудительно вернуть автомобиль?',
    'Rent.modal.Force.Date': 'Время и дата принудительного возврата',
    'Rent.modal.Force.Fail': 'Не удалось обработать принудительный возврат.',
    'VehicleBasic.VIN.Error':
        'Идентификационный номер транспортного средства (VIN) уже существует или недействителен. Проверьте информацию еще раз.',
    'LongRental.Charge.DirectDebit': 'Прямой дебит',
    'LongRental.Charge.VirtualAccount': 'Виртуальная учетная запись',
    'LongRental.Charge.Card': 'Оплата картой',
    'LongRental.Charge.Billing': 'Счет',
    'LongRental.Charge.Home': 'Главная страница',
    'LongRental.Charge.Company': 'Компания',
    'LongRental.UserNameInfo': 'Информация о клиенте',
    'LongRental.VehicleNumber': 'Транспортное средство',
    'LongRental.Payment.Month.Placeholder': 'Выберите дату платежа',
    'LongRental.Title.Estimate.Create': 'Зарегистрировать оцененный автомобиль',
    'LongRental.Estimate.VehicleName.Placeholder':
        'Введите название транспортного средства.',
    'LongRental.Purchase.Cost.Placeholder': 'Введите покупную стоимость.',
    'LongRental.RentalDeposit.Cost.Placeholder': 'Введите арендную плату.',
    'LongRental.MonthlyCharge.Cost.Placeholder': 'Введите ежемесячную плату',
    'LongRental.Period.Placeholder': 'Введите период',
    'LongRental.Options.Placeholder': 'Введите параметры',
    'LongRental.PenaltyAmount.Placeholder': 'Введите штраф',
    'LongRental.Note.Placeholder': 'Введите примечание',
    'Title.Charge.Peak.Fare': 'Максимальная скорость',
    'Title.Charge.Discount': 'Скидка на аренду (%)',
    'Vehicle.Tab.Log': 'Путевой журнал',
    'Common.Fail.User': 'Существующее дублирующее бронирование.',
    'Common.Btn.ContractCancle': 'Отмена',
    'Rent.modal.ContractCancle.message': 'Хотите расторгнуть договор аренды?',
    'LongRental.ContractCancle.StartDate': 'Дата начала аренды',
    'LongRental.ContractCancle.EndDate': 'Предполагаемое время и дата возврата',
    'LongRental.ContractCancle.ContractCancle': 'Дата и время отмены',
    'LongRental.ContractCancle.ReturnPay.Title': 'Возврат арендной платы',
    'LongRental.ContractCancle.Refund': 'Возврат арендной платы',
    'LongRental.ContractCancle.Total': 'Итого',
    'LongRental.ContractCancle.PenaltyAmount': 'Штраф',
    'LongRental.Charge.Cash': 'Наличные',
    'LongRental.Estimate.message':
        'Введите информацию об автомобиле и аренде для рассчитанной цены.',
    'Common.BeContractCancle.Fail': 'Отмена не разрешена.',
    'Common.Btn.Report': 'Смета',
    'Code.Create.ErrorCode.910004': 'Код ключа группы продублирован.',
    'Code.Update.ErrorCode.910004': 'Код ключа группы продублирован.',
    'Code.Delete.ErrorCode.910004':
        'Невозможно удалить из-за существующего подкода.',
    'VehicleInsurance.branchName': 'Название филиала',
    'VehicleInsurance.insureYear': 'Количество лет',
    'VehicleInsurance.paymentDueDate': 'Срок погашения',
    'VehicleInsurance.totalInsurance': 'Итого страховых взносов',
    'VehicleInsurance.paymentStatus': 'Статус платежа',
    'VehicleInsurance.ManagerName': 'Менеджер по страхованию',
    'VehicleInsurance.ManagerMobileNumber':
        'Номер телефона менеджера по страхованию',
    'LongRental.values':
        'Итого = Арендная плата – сумма штрафа + стоимость возмещения – дополнительные сборы + возврат',
    'Vehicle.Tab.Insurance': 'Информация о страховании',
    'VehicleInsurance.yearSelect': 'Выберите количество лет',
    'VehicleInsurance.1year': '1-й год',
    'VehicleInsurance.2year': '2-й год',
    'VehicleInsurance.3year': '3-й год',
    'Menu.Business': 'Управление операторами',
    'VehicleInsurance.12Month': '12 месяцев',
    'VehicleInsurance.24Month': '24 месяца',
    'VehicleInsurance.36Month': '36 месяцев',
    'VehicleInsurance.2Month': '2 месяца',
    'VehicleInsurance.3Month': '3 месяца',
    'VehicleInsurance.4Month': '4 месяца',
    'VehicleInsurance.5Month': '5 месяцев',
    'VehicleInsurance.6Month': '6 месяцев',
    'VehicleInsurance.7Month': '7 месяцев',
    'VehicleInsurance.8Month': '8 месяцев',
    'VehicleInsurance.9Month': '9 месяцев',
    'VehicleInsurance.10Month': '10 месяцев',
    'VehicleInsurance.11Month': '11 месяцев',
    ERROR_CODE_SUCCESS: 'Она прошла успешно.',
    ERROR_CODE_NO_CONTENT: 'Данные недоступны',
    ERROR_CODE_CREATED: 'Это было создано.',
    ERROR_CODE_NOT_CREATED: 'Не удалось ввести данные.',
    ERROR_CODE_NOT_UPDATED: 'Не удалось изменить данные.',
    ERROR_CODE_NOT_DELETED: 'Не удалось удалить данные.',
    ERROR_CODE_BAD_REQUEST: 'Неверный коэффициент ввода',
    ERROR_CODE_FORBIDDEN_ROLE: 'Разрешение администратора недоступно.',
    ERROR_CODE_FORBIDDEN_ACL: 'Разрешение пользователя недоступно.',
    ERROR_CODE_INTERNAL_SERVER_ERROR: 'Возникла внутренная ошибка.',
    ERROR_CODE_NEED_LOGON: 'Требуется вход в систему.',
    ERROR_CODE_VEHICLE_NOT_DELETE_RESERVATION:
        'Невозможно удалить транспортное средство из-за существующей информации о бронировании.',
    ERROR_CODE_VEHICLE_NOT_DELETE_ACCIDENT:
        'Невозможно удалить машину из-за существующей истории аварии.',
    ERROR_CODE_VEHICLE_VIN_DUPLICATION:
        'Невозможно использовать идентификационный номер транспортного средства (VIN).',
    ERROR_CODE_VEHICLE_CONTROL_ID_DUPLICATION:
        'Невозможно использовать идентификатор управления транспортным средством.',
    ERROR_CODE_USER_NOT_AUTHORIZATION: 'Авторизация пользователя не выполнена.',
    ERROR_CODE_BUSINESS_NOT_DELETE_CAR:
        'Она не может быть удалена, поскольку существует родственное транспортное средство.',
    ERROR_CODE_PRIVATE_UNAUTH_BUSINESS:
        'Активированный пользователь не может изменить свое предприятие.',
    ERROR_CODE_CHARGE_NOT_DELETE_RESERVATION:
        'Произошла ошибка из-за забронированного взноса.',
    ERROR_CODE_VEHICLEMODEL_NOT_DELETE:
        'Она не может быть удалена, поскольку существует родственное транспортное средство.',
    ERROR_CODE_RESERVATION_ALEADY_VEHICLE:
        'Транспортное средство уже забронировано.',
    ERROR_CODE_RESERVATION_CONFLICT:
        'Несоответствие при бронировании пользователем',
    ERROR_CODE_RESERVATION_UNAUTH_USER:
        'Для неавторизованных пользователей функция бронирования отключена.',
    ERROR_CODE_RESERVATION_FIRST_USER_BUSINESS:
        'Сначала зарегистрируйте предприятие.',
    ERROR_CODE_RESERVATION_NOT_INSUE: "Статус бронирования 'не используется'.",
    ERROR_CODE_RESERVATION_RETURN_NOT_LOCATION:
        'Текущее местонахождение транспортного средства не сооответствует месту возврата.',
    ERROR_CODE_RESERVATION_BAD_REQUEST: 'Невозможно расшифровать запрос.',
    ERROR_CODE_RESERVATION_VEHICLE_ERROR:
        'Не удалось найти информацию о транспортном средстве.',
    ERROR_CODE_RESERVATION_TIME_ERROR: 'Нестандартное время начала и окончания',
    ERROR_CODE_RESERVATION_RENTAL_COMPANY:
        'Не удалось зарегистрировать или изменить компанию-арендатора.',
    ERROR_CODE_RESERVATION_DRIVER_LICENSE:
        'Не удалось зарегистрировать или обновить водительское удостоверение',
    ERROR_CODE_RESERVATION_ADDITIONAL_DRIVER_LICENSE:
        'Неверная дополнительная информация о водителе.',
    ERROR_CODE_RESERVATION_NO_CONTENT: 'Данные недоступны',
    ERROR_CODE_RESERVATION_GRPC_ERROR: 'В модуле GRPC произошла ошибка.',
    ERROR_CODE_RESERVATION_STATUS_ERROR: 'Ошибка статуса',
    ERROR_CODE_RESERVATION_KOROAD_ERROR: 'Не удалось проверить лицензию.',
    ERROR_CODE_RESERVATION_INUSE:
        'Вы можете оформить бронирование за 15 мин. до начала аренды.',
    ERROR_CODE_RESERVATION_OVERLAP: 'Случаи накладки бронирования.',
    ERROR_CODE_CHARGE: 'Информация об оплате недоступна.',
    ERROR_CODE_TESTCAR_CONFIG_NOT_EXIST:
        'Информация о настройках для тестового автомобиля недоступна.',
    ERROR_CODE_TESTCAR_RESERVATION_PERIOD_EXCESS:
        'Период бронирования для тестового автомобиля превышен.',
    ERROR_CODE_TESTCAR_STARTTIME_WEEKEND:
        'Автомобили недоступны для аренды в выходные дни.',
    ERROR_CODE_TESTCAR_ENDTIME_WEEKEND:
        'Арендуемые автомобили не могут быть возвращены в выходные дни.',
    ERROR_CODE_TESTCAR_STARTTIME_HOLIDAY:
        'Невозможно взять автомобиль в аренду в праздничный день.',
    ERROR_CODE_TESTCAR_ENDTIME_HOLIDAY:
        'Невозможно вернуть автомобиль в праздничный день.',
    ERROR_CODE_TESTCAR_COUNT_EXCESS:
        'Лимит бронирования для тестовых автомобилей превышен.',
    ERROR_CODE_MONITORING_NOT_FIND_VIN:
        'Текущее местонахождение транспортного средства не сооответствует месту возврата.',
    ERROR_CODE_CONTROL_FAIL:
        'Не удается дистанционно управлять транспортным средством.',
    ERROR_CODE_CONTROL_VIN_DUPLICATION:
        'Идентификационный номер транспортного средства (VIN) недоступен.',
    ERROR_CODE_CONTROL_NADID_DUPLICATION: 'Информация о NADID недоступна.',
    ERROR_CODE_RESOURCE_NOT_FOUND: 'Невозможно найти ресурс.',
    ERROR_CODE_RESOURCE_KEY_DUPLICATION: 'Невозможно использовать код ресурса.',
    ERROR_CODE_RESOURCE_KEY_EMPTY: 'Ключ ресурса пуст.',
    ERROR_CODE_COMPANY_BUSINESS_REG_NUM_EXIST:
        'Регистрационный номер оператора уже существует.',
    ERROR_CODE_EXIST_BRANCH_USER: 'Пользователь от филиала существует.',
    'Vehicle.BasicInfo.ModelYear.Placeholder':
        'Введите год выпуска модели транспортного средства',
    'Vehicle.BasicInfo.ModelYear': 'Год выпуска модели',
    'Vehicle.BasicInfo.ExtendCnt':
        'Продление срока годности транспортного средства',
    'Vehicle.BasicInfo.RegularInspectionDate': 'Регулярный осмотр',
    'Vehicle.ExtendCnt.0': '0 раз',
    'Vehicle.ExtendCnt.1': 'Один раз',
    'Vehicle.ExtendCnt.2': 'Два раза',
    'Branch.Manager.Title': 'Управляющий филиалом',
    ERROR_CODE_ALREADY_RESERVED: 'Дата уже забронирована.',
    'LongRental.Charge.Bank_CARD': 'Компания по выпуску платежных карт',
    'LongRental.Charge.CountNumber_CARD': 'Номер карты',
    'LongRental.Charge.CountOwner_CARD': 'Владелец карты',
    'VehicleInsurance.Payment.1': '1-й',
    'VehicleInsurance.Payment.2': '2-й',
    'VehicleInsurance.Payment.3': '3-й',
    'VehicleInsurance.Payment.4': '4-й',
    'VehicleInsurance.Payment.5': '5-й',
    'VehicleInsurance.Payment.6': '6-й',
    'VehicleInsurance.Payment.7': '7-й',
    'VehicleInsurance.Payment.8': '8-й',
    'VehicleInsurance.Payment.9': '9-й',
    'VehicleInsurance.Payment.10': '10-й',
    'VehicleInsurance.Payment.11': '11-й',
    'VehicleInsurance.Payment.12': '12-й',
    'Vehicle.Model.Info': 'Информация о модели транспортного средства',
    'VehicleInsurance.Select.Paid': 'Оплачено',
    'VehicleInsurance.Day.type': 'Выберите дату',
    'VehicleInsurance.Day.Duration': 'Период действия оплаты',
    'VehicleInsurance.Modal.Year': 'Количество лет/последовательность',
    'Common.Buuton.Close': 'Закрыть',
    'Vehicle.Used': 'Состояние арендуемого автомобиля',
    'Vehicle.Used.Yes': 'Доступный арендуемый автомобиль',
    'Vehicle.Used.No': 'Аренда остановлена',
    'Common.Btn.Terminal': 'Передача через терминал',
    'Reservation.Request.Terminal.Fail':
        'Невозможно отправить информацию о бронировании на ваш терминал.',
    'Reservation.Request.Terminal.Success':
        'Был сделан запрос на отправку информации о бронировании на ваш терминал.',
    'Reservation.Request.Terminal.Confirm':
        'Хотите отправить информацию о бронировании на свой терминал?',
    'TestCarConfig.Reservation.StartTime':
        'Установить дату/время начала бронирования',
    'Common.Input.Required.Hour': 'Введите значение от 0 до 23.',
    'Common.Input.Required.Day': 'Введите значение от 1 до 31.',
    'Location.Radius.50m': '50 м',
    'Location.Radius.100m': '100 м',
    'Location.Radius.200m': '200 м',
    'Location.Radius.300m': '300 м',
    'Menu.Reservation.TestCar': 'Управление арендой тестовых автомобилей',
    'Charge.Minute.Zero': '0м',
    'Charge.Minute.Ten': '10 минут',
    'Charge.Minute.Twenty': '20 минут',
    'Charge.Minute.Thirty': '30 минут',
    'Charge.Minute.Forty': '40 минут',
    'Charge.Minute.Fifty': '50 минут',
    'LongRental.Payment.Day.One': '1 день',
    'LongRental.Payment.Day.Five': '5 дней',
    'LongRental.Payment.Day.Ten': '10 дней',
    'LongRental.Payment.Day.Fifteen': '15 дней',
    'LongRental.Payment.Day.Twenty': '20 дней',
    'LongRental.Payment.Day.TwentyFifth': '25 дней',
    'LongRental.Payment.Day.Last': 'Последний день месяца',
    'LongRental.RentalInfo.Year.Two': '24 месяца',
    'LongRental.RentalInfo.Year.Three': '36 месяцев',
    'LongRental.RentalInfo.Year.Four': '48 месяцев',
    'LongRental.RentalInfo.Year.Five': '60 месяцев',
    'LongRental.Charge.Bank_CARD.Placeholder':
        'Выберите компанию по выпуску платежных карт.',
    'LongRental.Charge.CountNumber_CARD.Placeholder': 'Введите номер карты',
    'LongRental.Charge.CountOwner_CARD.Placeholder': 'Введите владельца карты.',
    ERROR_CODE_LICENSE_USE_BUSINESS_EXIST:
        'Его невозможно удалить, так как данный тарифный план используется для коммерческого/арендуемого автомобиля.',
    ERROR_CODE_MENU_CHILDREN_EXIST:
        'Невозможно удалить из-за существующего подменю',
    ERROR_CODE_ROLE_CHILDREN_EXIST:
        'Невозможно удалить из-за существующих данных подчиненного органа',
    'TestCar.Reservation.View': 'Просмотр',
    'Common.Input.Required.Percent': 'Введите значение от 1 до 100.',
    'Terminal.message':
        '*Может не совпадать с полученной информацией о реальном терминале.*',
    'Reservation.Request.BeContractCancle.Success':
        'Долгосрочная аренда нового автомобиля отменена.',
    'VehicleInsurance.4year': '4-й год',
    'VehicleInsurance.5year': '5-й год',
    'VehicleInsurance.6year': '6-й год',
    'VehicleInsurance.7year': '7-й год',
    'VehicleInsurance.8year': '8-й год',
    'VehicleInsurance.9year': '9-й год',
    'VehicleInsurance.10year': '10-й год',
    'Reservation.Error.SelectPeriod':
        'Выберите предполагаемое время и дату аренды.',
    'Reservation.Error.StartLocation': 'Выберите место доставки.',
    'Common.Error.message': 'Действие отключено.',
    'Title.PaymentManager': 'Статус продаж/расчетов',
    'Menu.Payment.Violation.Mgnt': 'Управление штрафами/пошлинами за проезд',
    ERROR_CODE_NOT_FOUND_RESERVATION_DATA:
        'Бронирования, связанного с нарушением, не существует.',
    ERROR_CODE_FORCERETURN_CAN_BE_MADE_AFTER_THE_START_TIME:
        'Принудительный возврат может быть осуществлен после сдачи в аренду.',
    'Password.Infomation1':
        '- Перед просмотром договора аренды вы должны ввести пароль для защиты своих персональных данных.',
    'Password.Infomation2':
        '- Пароль представляет собой шестизначную дату вашего рождения или первые шесть цифр регистрационного номера предприятия. Введите пароль и нажмите кнопку OK.',
    'Password.CompanyInfo1': 'Оператор',
    'Vehicle.Registration.Card': 'Регистрационная карта транспортного средства',
    'Vehicle.Registration.Card.Btn.Create':
        'Зарегистрировать регистрационную карту транспортного средства',
    'Vehicle.Registration.Card.Create.Help':
        'Выбрать регистрационную карту транспортного средства (jpeg, jpg, png).',
    'Vehicle.Registration.Card.Create.ExtName.Help':
        'Можно использовать только расширения jpeg, jpg и png.',
    'Vehicle.Registration.Card.Btn.Upload':
        'Загрузить регистрационную карту транспортного средства',
    'Vehicle.Registration.Card.Upload.Fail':
        'Не удалось проанализировать регистрационную карту транспортного средства.',
    'Common.Buuton.Kakaotalk': 'KakaoTalk',
    'Common.fail.changeMonth': 'Выбрать срок аренды',
    'Send.Kakaotalk.Fail': 'Не удалось отправить уведомление KakaoTalk.',
    'Send.Kakaotalk.Success': 'Уведомление KakaoTalk было успешно отправлено.',
    'Send.Kakaotalk.Password.Fail': 'Введен неверный пароль',
    'Send.Kakaotalk.Password.Success': 'Пароль введен успешно.',
    ERROR_CODE_TODAY_IS_NOT_SAME_DATE:
        'Арендуемый автомобиль может быть зарегистрирован только начиная с сегодняшнего дня.',
    'Common.Error.Validation': 'Пожалуйста, заполните все обязательные поля.',
    ERROR_CODE_NOT_MATCH_PASSWORD: 'Пароль не совпадает.',
    'Vehicle.BasicInfo.Insurance.Limit': 'Лимит страхового покрытия',

    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */
    'Vehicle.BasicInfo.Equip': '장착',
    'Vehicle.BasicInfo.Not.Equip': '미장착',
    'Vehicle.Title.RealTimeInfo': '실시간차량정보',
    'Vehicle.Title.AdditionalInfo': '부가정보',
    'Vehicle.AdditionalInfo.iviID': 'IVI ID',
    'Vehicle.AdditionalInfo.iviID.Call': 'IVI ID 불러오기',
    'Vehicle.AdditionalInfo.asPeriod': 'A/S 기간',
    'Vehicle.AdditionalInfo.taxiDrivingGroup': '택시운행조',
    'Vehicle.AdditionalInfo.VIN.FieldNull': '차대번호를 입력해주세요.',
    'Vehicle.AdditionalInfo.iviID.Fail': 'IVI ID 조회 실패',
    'Vehicle.AdditionalInfo.iviID.Empty':
        '차대번호에 해당되는 IVI ID가 존재하지 않습니다.',

    'Vehicle.Tab.Sales': '차량별매출',
    'Vehicle.Sales.HistoryTime': '발생일시',
    'Vehicle.Sales.Business.Time': '영업시간(거리)',

    'Menu.Vehicle.OperationStatistics': '차량운행일지',
    'Vehicle.OperationStatistics.Btn.Download': '엑셀다운로드',
    'Terms.Search.Period': '조회기간',
    'Vehicle.OperationStatistics.Vehicle.Number': '차량번호',
    'Vehicle.OperationStatistics.TaxiDriver.Name': '기사명',
    'Vehicle.OperationStatistics.Date': '날짜',
    'Vehicle.OperationStatistics.Login.Time': '출근시간',
    'Vehicle.OperationStatistics.Logout.Time': '퇴근시간',
    'Vehicle.OperationStatistics.Driving.Time': '주행시간(거리)',
    'Vehicle.OperationStatistics.Business.Time': '영업시간(거리)',
    'Vehicle.OperationStatistics.Empty.Time': '공차시간(거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount': '총영업금액(호출/배회)',

    'Vehicle.OperationStatistics.Title.Info': '차량운행 상세 정보',
    'Vehicle.OperationStatistics.Work.Time': '출퇴근시간',
    'Vehicle.OperationStatistics.TotalDriving.Time': '총주행시간(거리)',
    'Vehicle.OperationStatistics.TotalBusiness.Time': '총영업시간(거리)',
    'Vehicle.OperationStatistics.TotalEmpty.Time': '총공차시간(거리)',
    'Vehicle.OperationStatistics.Business.Rate': '영업률(영업거리/총주행거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount2': '총매출금액',
    'Vehicle.OperationStatistics.Business.Count': '영업건수/객단가',
    'Vehicle.OperationStatistics.CallType.Count': '호출/배회건수',
    'Vehicle.OperationStatistics.CallType.SalesAmount': '호출/배회 매출금액',

    'Vehicle.OperationStatistics.Departure.Time': '승차시간',
    'Vehicle.OperationStatistics.Arrive.Time': '하차시간',
    'Vehicle.OperationStatistics.Departure.PlaceName': '승차위치',
    'Vehicle.OperationStatistics.Arrive.PlaceName': '하차위치',
    'Vehicle.OperationStatistics.Call.YN': '호출여부',
    'Vehicle.OperationStatistics.Call.App': '호출APP',
    'Vehicle.OperationStatistics.Payment.Method': '결제방식',
    'Vehicle.OperationStatistics.SalesAmount': '매출금액',
    'Vehicle.OperationStatistics.Create.Time': '운행정보입력시간',
    'Vehicle.OperationStatistics.PaymentType.Cash': '현금',
    'Vehicle.OperationStatistics.PaymentType.Card': '카드',
    'Vehicle.OperationStatistics.AppType.Macaron': '마카롱배차앱',
    'Vehicle.OperationStatistics.AppType.TMoney': '티머니미터기',
    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */

    // 차량만령관리
    'Vehicle.ExpiryHeader.Title': '차량만령관리',
    'Vehicle.ExpirySearch.Title': '차령만료일 기간',
    'Vehicle.ExpirySearch.all': '전체',
    'Vehicle.ExpirySearch.prevday': '1일전',
    'Vehicle.ExpirySearch.prevweek': '1주일전',
    'Vehicle.ExpirySearch.prevtwoweek': '2주일전',
    'Vehicle.ExpirySearch.prevmonth': '1달전',
    'Vehicle.ExpirySearch.prevyear': '1년 2주전',
    'Vehicle.ExpirySearch.today': '당일',
    'Vehicle.ExpiryHeader.vehicleAge': '차량연령',

    // 택시통합연동로그
    'Menu.ReceiveLog': '택시통합연동로그',
    'Title.ReceiveLog': '택시통합연동로그',
    'ReceiveLog.Btn.Download': '엑셀다운로드',
    'ReceiveLog.Filter.RegDate': '등록일',
    'ReceiveLog.Filter.InterfaceId': 'InterfaceID',
    'ReceiveLog.Filter.DriverName': '기사명',
    'ReceiveLog.Filter.Pincode': 'PINCODE',
    'ReceiveLog.Filter.ResultCodeKind': '응답코드종류',
    'ReceiveLog.Filter.ResultCode': '응답코드',
    'ReceiveLog.ResultCodeKind.All': '전체',
    'ReceiveLog.ResultCodeKind.Success': '성공',
    'ReceiveLog.ResultCodeKind.Failure': '실패',
    // 'ReceiveLog.Item.ID' : 'ID',
    'ReceiveLog.Item.CreatedDateTime': '생성일시',
    'ReceiveLog.Item.YdServiceName': '서비스명',
    'ReceiveLog.Item.InterfaceId': 'InterfaceID',
    'ReceiveLog.Item.InterfaceName': 'Interface명',
    'ReceiveLog.Item.AccessDateTime': '요청일시',
    // 'ReceiveLog.Item.SessionId' : '세션ID',
    'ReceiveLog.Item.UrlPath': 'API URL',
    // 'ReceiveLog.Item.HttpMethod' : '메소드명',
    // 'ReceiveLog.Item.ClientIp' : 'Client IP',
    'ReceiveLog.Item.TaxiDriverName': '기사명',
    'ReceiveLog.Item.Pincode': 'PINCODE',
    // 'ReceiveLog.Item.Vin' : 'VIN No.',
    'ReceiveLog.Item.Param': 'Parameter',
    'ReceiveLog.Item.ResultCode': '응답코드',
    'ReceiveLog.Item.ResultMsg': '응답메시지',
    'ReceiveLog.Item.InnerResultMsg': '응답메시지상세',
    'ReceiveLog.Item.ResultValue': '응답값',
    // 차량기록조회
    'Vehicle.Records.Btn.Download': '엑셀다운로드',
    'Terms.Search.Time': '조회시간*',
    'Vehicle.Records.Vehicle.Number': '차대번호*',
    'Menu.Vehicle.Records': '차량실시간정보',
    'Vehicle.Records.Minute.Placeholder': '(분)',
    'Vehicle.Records.Hour.Placeholder': '(시)',
    'Vehicle.Records.Comment':
        '* 선택한 시간부터 10분간의 데이터를 조회합니다.',
};

export default language;
