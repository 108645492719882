import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { EditorToolsSettings, EditorUtils } from '@progress/kendo-react-editor';
import { Button } from '@progress/kendo-react-buttons';

import {
    TextOrInput,
    Tab,
    TabPanel,
    Section,
    Element,
    Uploader,
} from 'component/mux';
import CustomDialog from './CustomDialog';
import { MAX_FILE_SIE } from 'util/pageUtil';

/**
 * /kor.mop.bo.web/node_modules/@progress/kendo-react-editor/dist/npm/tools/insertLink.js
 * 분석하여 작성
 */

const { node, props } = EditorToolsSettings.image;

const InsertImage = ({ view }) => {
    const intl = useIntl();
    const state = view && view.state;
    const nodeType = state ? state.schema.nodes[node] : undefined;

    const { src, alt, title, width, height } =
        state &&
        state.selection.node &&
        state.selection.node.type === state.schema.nodes[node]
            ? state.selection.node.attrs
            : {};

    const [visible, setVisible] = useState(false);
    const [activateKey, setActivateKey] = useState(src ? '2' : '1');

    const handleCancel = () => setVisible(!visible);

    const handleSubmit = (data) => {
        const nodes = view.state.schema.nodes;
        const nodeType = nodes[node];
        const position = null;
        const requestObj = {
            src: data.src,
            title: data.title,
            alt: data.alt,
            width: data.width,
            height: data.height,
        };
        const attrs = Object.keys(requestObj)
            .filter(
                (key) =>
                    requestObj[key] !== null &&
                    requestObj[key] !== '' &&
                    key !== 'imageFile',
            )
            .reduce(
                (acc, curr) =>
                    Object.assign(acc, {
                        [curr]: requestObj[curr],
                    }),
                {},
            );

        const recordFile = (files, position, attrs) => {
            if (files?.length > 0 && files[0]) {
                const readFileAsync = (f) => {
                    return new Promise((resolve, reject) => {
                        let _reader = new FileReader();

                        _reader.onload = (e) => {
                            const image = nodeType.createAndFill({
                                ...attrs,
                                src: e.target.result,
                            });

                            if (position) {
                                view.dispatch(
                                    view.state.tr.insert(position.pos, image),
                                );
                                return;
                            }
                            resolve([image]);
                        };

                        _reader.onerror = reject;
                        _reader.readAsDataURL(f);
                    });
                };

                return readFileAsync(files[0]);
            } else {
                const newImage = nodeType.createAndFill(attrs);
                return Promise.resolve([newImage]);
            }
        };

        recordFile([data.imageFile], position, attrs).then((images) => {
            _.forEach(images, (image) => {
                EditorUtils.insertNode(view, image, true);
            });
        });

        view.focus();
        handleCancel();
    };

    return (
        <>
            <Button
                onClick={handleCancel}
                disabled={
                    !nodeType ||
                    !state ||
                    !EditorUtils.canInsert(state, nodeType)
                }
                {...props}
            />
            <CustomDialog
                visible={visible}
                resId="Title.WebEditor.InsertImage"
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            >
                <Tab
                    activeKey={activateKey}
                    onChange={setActivateKey}
                    destroyInactiveTabPane
                >
                    <TabPanel
                        tab={
                            <FormattedMessage id="WebEditor.InsertImage.Tab.File" />
                        }
                        key="1"
                    >
                        <Section cols={1}>
                            <Element resId="WebEditor.InsertImage.ImageFile">
                                <Uploader
                                    factory="fileType"
                                    name="imageFile"
                                    filter={['jpg', 'jpeg', 'png']}
                                    maxSize={MAX_FILE_SIE}
                                    maxSizeMessage={intl.formatMessage({ id: 'Input.File.Max.Size'}, { value: MAX_FILE_SIE } )}
                                />
                            </Element>
                            <Element resId="WebEditor.InsertImage.AltText">
                                <TextOrInput name="alt" value={alt} />
                            </Element>
                            <Element resId="WebEditor.InsertImage.Title">
                                <TextOrInput name="title" value={title} />
                            </Element>
                            <Element resId="WebEditor.InsertImage.Width">
                                <TextOrInput name="width" value={width} />
                            </Element>
                            <Element resId="WebEditor.InsertImage.Height">
                                <TextOrInput name="height" value={height} />
                            </Element>
                        </Section>
                    </TabPanel>
                    <TabPanel
                        tab={
                            <FormattedMessage id="WebEditor.InsertImage.Tab.URL" />
                        }
                        key="2"
                    >
                        <Section cols={1}>
                            <Element resId="WebEditor.InsertImage.Src">
                                <TextOrInput
                                    name="src"
                                    value={src}
                                    disable={/^data:image/.test(src || '')}
                                />
                            </Element>
                            <Element resId="WebEditor.InsertImage.AltText">
                                <TextOrInput name="alt" value={alt} />
                            </Element>
                            <Element resId="WebEditor.InsertImage.Title">
                                <TextOrInput name="title" value={title} />
                            </Element>
                            <Element resId="WebEditor.InsertImage.Width">
                                <TextOrInput name="width" value={width} />
                            </Element>
                            <Element resId="WebEditor.InsertImage.Height">
                                <TextOrInput name="height" value={height} />
                            </Element>
                        </Section>
                    </TabPanel>
                </Tab>
            </CustomDialog>
        </>
    );
};

export default InsertImage;
