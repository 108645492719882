import React, { forwardRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import { TextOrRadio as MuxTextOrRadio } from 'mux';

const TextOrRadio = forwardRef((props, ref) => {
    const {
        name,
        defaultValue,
        value,
        option,
        disable,
        required,
        validate,
        onChangeValue,
        onChanged,
        orderBy,
        ...rest
    } = props;

    const intl = useIntl();
    const { setValue } = useFormContext();

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Select',
            });
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [required]);

    // change 이벤트
    const handleChangeValue = (e) => {
        if (_.isFunction(onChangeValue)) {
            onChangeValue(e);
        }
        if (_.isFunction(onChanged)) {
            const { name, value } = e.target;
            onChanged({ data: { [name]: value } });
        }
    };

    // 기본값
    // useEffect(() => {
    //     if (!dirtyFields[name]) {
    //         setValue(name, value, { shouldDirty: true });
    //     }
    // }, [dirtyFields[name], value]);

    useEffect(() => {
        setValue(name, value || defaultValue);
    }, [value, defaultValue]);

    // option 정렬변경
    const orderByOptions = useMemo(() => {
        let resultOption = [];
        let orderKey = null;

        if (_.isBoolean(orderBy)) {
            orderKey = orderBy ? 'asc' : null;
        } else if (_.isString(orderBy)) {
            orderKey = orderBy;
        } else if (_.isFunction(orderBy)) {
            return orderBy(option);
        }

        if (!_.isEmpty(orderKey)) {
            const head = _.filter(option, ({ value }) => _.isEmpty(value));
            const body = _.filter(option, ({ value }) => !_.isEmpty(value));
            resultOption = [...head, ..._.orderBy(body, ['label'], orderBy)];
        } else {
            resultOption = option;
        }

        return resultOption;
    }, [option, orderBy]);

    return (
        <MuxTextOrRadio
            {...rest}
            {...rules}
            name={name}
            defaultValue={defaultValue}
            value={value}
            options={orderByOptions}
            disabled={disable}
            onChangeValue={handleChangeValue}
            ref={ref}
        />
    );
});

TextOrRadio.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    disable: PropTypes.bool,
    required: PropTypes.bool,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    option: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
                disabled: PropTypes.bool,
            }),
        ),
    ]),
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    watch: PropTypes.arrayOf(PropTypes.string),
    onDetected: PropTypes.func,
    onChanged: PropTypes.func,
    orderBy: PropTypes.any,
};

TextOrRadio.defaultProps = {
    disable: false,
    required: false,
    editable: true,
    watch: [],
    orderBy: false,
};

export default TextOrRadio;
