import React, { forwardRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { useIntl } from 'react-intl';

import { TextOrCheckBox as MuxTextOrCheckBox } from 'mux';

const TextOrCheckBox = forwardRef((props, ref) => {
    const {
        name,
        value,
        option,
        disable,
        required,
        validate,
        onChangeValue,
        labelOfAll,
        orderBy,
        ...rest
    } = props;

    const intl = useIntl();

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Select',
            });
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [required, validate]);

    // change 이벤트
    const handleCheckChange = useCallback((checkedValue) => {
        if (_.isFunction(onChangeValue)) {
            onChangeValue(checkedValue);
        }
    }, []);

    // option 정렬변경
    const orderByOptions = useMemo(() => {
        let resultOption = [];
        let orderKey = null;

        if (_.isBoolean(orderBy)) {
            orderKey = orderBy ? 'asc' : null;
        } else if (_.isString(orderBy)) {
            orderKey = orderBy;
        } else if (_.isFunction(orderBy)) {
            return orderBy(option);
        }

        if (!_.isEmpty(orderKey)) {
            const head = _.filter(option, ({ value }) => _.isEmpty(value));
            const body = _.filter(option, ({ value }) => !_.isEmpty(value));
            resultOption = [...head, ..._.orderBy(body, ['label'], orderBy)];
        } else {
            resultOption = option;
        }

        return resultOption;
    }, [option, orderBy]);

    return (
        <MuxTextOrCheckBox
            {...rest}
            {...rules}
            name={name}
            value={value}
            options={orderByOptions}
            disabled={disable}
            onChangeValue={handleCheckChange}
            labelOfAll={
                labelOfAll ||
                intl.formatMessage({
                    id: 'Common.All',
                })
            }
            ref={ref}
        />
    );
});

TextOrCheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.arrayOf(PropTypes.string),
    disable: PropTypes.bool,
    required: PropTypes.bool,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    option: PropTypes.array.isRequired,
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    watch: PropTypes.arrayOf(PropTypes.string),
    onDetected: PropTypes.func,
    allVisibleYn: PropTypes.bool,
    labelOfAll: PropTypes.string,
    orderBy: PropTypes.any,
};

TextOrCheckBox.defaultProps = {
    disable: false,
    required: false,
    editable: true,
    allVisibleYn: false,
    orderBy: false,
};

export default TextOrCheckBox;
