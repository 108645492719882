import { GateKeeper as GateKeeperKr } from './GateKeeper';
import { GateKeeper as GateKeeperJp } from 'country/jp/layout/GateKeeper';
import { getCountryCd } from 'util/pageUtil';

const Pages = {
    kr: GateKeeperKr,
    jp: GateKeeperJp,
};
const GateKeeperPage = (props) => {
    const Comp = Pages[getCountryCd()];
    return <Comp {...props} />;
};

export { GateKeeperPage };
