import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';

import { HiddenInput as MuxHiddenInput } from 'mux';

const HiddenInput = forwardRef((props, ref) => {
    const { name, value } = props;

    const { setValue } = useFormContext();

    useEffect(() => {
        setValue(name, value);
    }, [name, value, setValue]);

    return <MuxHiddenInput {...props} ref={ref} />;
});

HiddenInput.propTypes = {
    name: PropTypes.string.isRequired,
};

export default HiddenInput;
