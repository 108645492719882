import React, { forwardRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import { TextArea as MuxTextArea } from 'mux';

const TextArea = forwardRef((props, ref) => {
    const {
        name,
        value,
        disable,
        placeholderId,
        placeholder,
        required,
        maxLength,
        pattern,
        validate,
        onChangeValue,
        ...rest
    } = props;

    const intl = useIntl();
    const { setValue } = useFormContext();

    // placeholder: placeholderId > placeholder
    const placeholderMessage = useMemo(
        () =>
            placeholderId
                ? intl.formatMessage({ id: placeholderId })
                : placeholder,
        [placeholder, placeholderId, intl],
    );

    // 유효성 검사 - https://react-hook-form.com/api/useform/register/ 의 Rules 참고
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        if (maxLength) {
            chunkRules.maxLength = {
                value: Number(maxLength),
                message: intl.formatMessage(
                    { id: 'Message.Validator.MaxLength' },
                    {
                        compareValue: maxLength,
                    },
                ),
            };
        }

        if (pattern) {
            chunkRules.pattern = pattern;
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [required, maxLength, pattern, validate]);

    // change 이벤트
    const handleChangeValue = (e) => {
        if (_.isFunction(onChangeValue)) {
            onChangeValue(e);
        }
    };

    // 기본값
    useEffect(() => {
        setValue(name, value);
    }, [name, value]);

    return (
        <MuxTextArea
            {...rest}
            {...rules}
            name={name}
            value={value}
            placeholder={placeholderMessage}
            disabled={disable}
            onChangeValue={handleChangeValue}
            ref={ref}
        />
    );
});

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    allowClear: PropTypes.bool,
    disable: PropTypes.bool,
    required: PropTypes.bool,
    maxLength: PropTypes.number,
    showCount: PropTypes.bool,
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    watch: PropTypes.arrayOf(PropTypes.string),
    onDetected: PropTypes.func,
};

TextArea.defaultProps = {
    disable: false,
    allowClear: false,
    required: false,
    showCount: false,
    editable: true,
};

export default TextArea;
