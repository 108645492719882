import React, { forwardRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import { TextOrInput as MuxTextOrInput } from 'mux';

const TextOrInput = forwardRef((props, ref) => {
    const {
        type,
        name,
        value,
        disable,
        placeholderId,
        placeholder,
        required,
        maxLength,
        minLength,
        max,
        min,
        pattern,
        validate,
        onChangeValue,
        ...rest
    } = props;

    const intl = useIntl();
    const {
        setValue,
        getValues,
        formState: { isDrity, dirtyFields },
    } = useFormContext();

    // placeholder: placeholderId > placeholder
    const placeholderMessage = useMemo(
        () =>
            placeholderId
                ? intl.formatMessage({ id: placeholderId })
                : placeholder,
        [placeholder, placeholderId, intl],
    );

    // type 사용 1 : useFormContext 의 rule 작성
    // 유효성 검사 - https://react-hook-form.com/api/useform/register/ 의 Rules 참고
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        if (maxLength) {
            let parseMaxLength = maxLength;

            // currency type 의 경우 123,456 와 같은형태의 데이터이기 때문에 쉼표 만큼 maxLength 다시 계산
            if (type === 'currency') {
                const parseData = Number(
                    _.padStart('', maxLength, '1'),
                ).toLocaleString();
                parseMaxLength = parseData.length;
            }

            chunkRules.maxLength = {
                value: Number(parseMaxLength),
                message: intl.formatMessage(
                    { id: 'Message.Validator.MaxLength' },
                    {
                        compareValue: maxLength,
                    },
                ),
            };
        }

        if (minLength) {
            chunkRules.minLength = {
                value: Number(minLength),
                message: intl.formatMessage(
                    { id: 'Message.Validator.MinLength' },
                    {
                        compareValue: minLength,
                    },
                ),
            };
        }

        if (max || type === 'currency') {
            const compareValue = Number(max) || Number.MAX_SAFE_INTEGER;
            chunkRules.max = {
                value: compareValue,
                message: intl.formatMessage(
                    { id: 'Message.Validator.MaxNumber' },
                    {
                        compareValue,
                    },
                ),
            };
        }

        if (min || type === 'currency') {
            const compareValue = Number(min) || Number.MIN_SAFE_INTEGER;
            chunkRules.min = {
                value: compareValue,
                message: intl.formatMessage(
                    { id: 'Message.Validator.MinNumber' },
                    {
                        compareValue,
                    },
                ),
            };
        }

        switch (type) {
            case 'tel': {
                chunkRules.pattern = {
                    // value: /(^0[0-9]{1,2}[0-9]{3,4}[0-9]{4}$)|(^[0-9]{8}$)/,
                    value: /(^[0-9-\-+]{8,17})/,
                    message: intl.formatMessage({
                        id: 'Message.Validator.Telnum',
                    }),
                };
                break;
            }
            case 'email': {
                chunkRules.pattern = {
                    value: /^[A-Z0-9\w-.]+@([A-Z0-9\w-]+\.)+[\w-]{2,4}$/i,
                    message: intl.formatMessage({
                        id: 'Message.Validator.Email',
                    }),
                };
                break;
            }
            case 'number':
            case 'number-business': {
                chunkRules.pattern = {
                    value: /^[0-9]*$/,
                    message: intl.formatMessage({
                        id: 'Message.Validator.Number',
                    }),
                };
                break;
            }
            // 숫자, 대문자영문 허용
            case 'text-NumUpEn': {
                chunkRules.pattern = {
                    value: /^[a-zA-Z0-9]*$/,
                    message: intl.formatMessage({
                        id: 'Message.Validator.NumUpEn',
                    }),
                };
                break;
            }
            default:
        }

        if (pattern) {
            chunkRules.pattern = pattern;
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [required, maxLength, minLength, max, min, pattern, validate, type]);

    // type 사용 2 : input 의 keyPressRule 작성
    const keyPressRule = useMemo(() => {
        const pressRules = {};
        switch (type) {
            case 'tel':
                pressRules.pressPattern = /[^0-9-\-+]/g;
                pressRules.pressMaxLength = rules?.maxLength?.value || 17;
                break;
            case 'number':
                pressRules.pressPattern = /[^0-9]/g;
                pressRules.pressMaxLength = rules?.maxLength?.value;
                break;

            // 숫자, 대문자영문 허용
            case 'text-NumUpEn':
                pressRules.pressPattern = /[^a-zA-Z0-9]/g;
                pressRules.pressMaxLength = rules?.maxLength?.value;
                pressRules.pressCallFn = (data) => {
                    return _.replace(data, /[a-z]/g, (x) => _.toUpper(x));
                };
                break;
            default:
                pressRules.pressMaxLength = rules?.maxLength?.value;
                break;
        }
        return pressRules;
    }, [rules, type]);

    // type 사용 3 : 사용 1,2 이용 이후 원 type 으로 치환
    const htmlType = useMemo(() => {
        switch (type) {
            case _.startsWith(type, 'text-'):
                return 'text';
            default:
                return type;
        }
    }, [type]);

    // change 이벤트
    const handleChangeValue = (e) => {
        if (_.isFunction(onChangeValue)) {
            onChangeValue(e);
        }
    };

    // 기본값
    useEffect(() => {
        // reset(초기화) 및 최초값 설정
        if (!dirtyFields[name]) {
            setValue(name, value, {
                shouldDirty: !!value,
                // shouldValidate: !!value,
            });
        } else {
            // 기존 value 변경
            const prev = getValues(name);
            if (!_.isEqual(prev, value)) {
                setValue(name, value, {
                    shouldDirty: !!value,
                });
            }
        }
    }, [isDrity, value]);

    // 엔터키 동작
    const onKeyPress = ({ key }) => {
        if (key === 'Enter') {
            document.activeElement.blur();
        }
    };

    return (
        <MuxTextOrInput
            {...rest}
            {...rules}
            type={htmlType}
            name={name}
            value={value}
            placeholder={placeholderMessage}
            disabled={disable}
            onChangeValue={handleChangeValue}
            ref={ref}
            onKeyPress={onKeyPress}
            keyPressRule={keyPressRule}
        />
    );
});

TextOrInput.propTypes = {
    type: PropTypes.string.isRequired, // tel, number-business, currency
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    disable: PropTypes.bool,
    placeholder: PropTypes.string,
    placeholderId: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    pattern: PropTypes.shape({
        value: PropTypes.instanceOf(RegExp),
        message: PropTypes.string,
    }),
    onChangeValue: PropTypes.func,
    allowClear: PropTypes.bool,
    onClear: PropTypes.func,
    editable: PropTypes.bool,
    watch: PropTypes.arrayOf(PropTypes.string),
    onDetected: PropTypes.func,
};

TextOrInput.defaultProps = {
    type: 'text',
    disable: false,
    required: false,
    editable: true,
    allowClear: false,
};

export default TextOrInput;
