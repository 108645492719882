import { ProseMirror } from '@progress/kendo-react-editor';

const { Plugin, PluginKey } = ProseMirror;

export function readonlyPlugin(editable) {
    return new Plugin({
        key: new PluginKey('readonly'),
        props: {
            editable: () => editable,
        },
    });
}
