import React, {
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useIntl } from 'react-intl';

import { TextOrMultiSelect as MuxTextOrMultiSelect, withBaseOption } from 'mux';
import { useFormContext } from 'react-hook-form';
import TextOrInput from './TextOrInput';

const valueOfAll = 'VALUE_OF_ALL';
const valueOfNone = 'NONE';

const TextOrMultiSelect = forwardRef((props, ref) => {
    const multiRef = useRef();
    const {
        name,
        value,
        option,
        unSelectOption,
        disable,
        placeholderId,
        placeholder,
        required,
        validate,
        onChangeValue,
        seperator,
        orderBy, // fix 옵션 ignore 되도록 숨김.(필요하면 sorting 구현)
        dataClearToggle,
        maxTagCount = 'responsive',
        ...rest
    } = props;

    const intl = useIntl();
    const {
        setValue,
        trigger,
        formState: { isSubmitted, isDirty, dirtyFields },
    } = useFormContext();

    // placeholder: placeholderId > placeholder
    const placeholderMessage = useMemo(
        () =>
            placeholderId
                ? intl.formatMessage({ id: placeholderId })
                : placeholder,
        [placeholder, placeholderId, intl],
    );

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Select',
            });
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [required, validate]);

    // change 이벤트
    const handleChangeValue = (selectedValue) => {
        if (_.isFunction(onChangeValue)) {
            onChangeValue(selectedValue);
        }
    };

    const optionAll = {
        label: intl.formatMessage({ id: 'Common.Btn.AllSelect' }),
        value: valueOfAll,
    };

    const unSelect = useMemo(() => {
        return (
            (unSelectOption && {
                label: intl.formatMessage({
                    id: unSelectOption?.resId || 'Common.Btn.UnSelect',
                }),
                value: unSelectOption?.value || valueOfNone,
            }) ||
            null
        );
    }, [unSelectOption]);

    const getPopupContainer = (trigger) => {
        let curBody = trigger.parentNode;
        const modalBodys = document.getElementsByClassName('ant-modal-body');
        _.map(modalBodys, (element) => {
            if (element.hasChildNodes()) {
                _.map(element.childNodes, (element) => {
                    if (element.contains(trigger)) {
                        curBody = element;
                    }
                });
            }
        });
        return curBody;
    };

    const [chunkOptions, setChunkOptions] = useState([]); // 전체선택을 포함한 options
    const [selectedValues, setSelectedValues] = useState([]); // 선택한 option

    useEffect(() => {
        let chunkOptions = null;
        if (option?.length > 1) {
            chunkOptions = _.compact(_.concat([optionAll], option, unSelect));
        } else if (option?.length > 0) {
            chunkOptions = option;
        }

        if (chunkOptions) {
            setChunkOptions((prev) => {
                const chunkOptions = _.compact(
                    _.concat([optionAll], option, unSelect),
                );
                if (_.isEqual(prev, chunkOptions)) return prev;
                return chunkOptions;
            });
        }
    }, [option]); // 전체선택 선택

    const sortActiveOption = useCallback(
        (dataArray) => {
            return unSelect
                ? _.reduce(
                      dataArray,
                      (result, cur) => {
                          const curDate = _.isPlainObject(cur)
                              ? cur.value
                              : cur;
                          if (curDate !== unSelect?.value) {
                              result.push(cur);
                          }
                          return result;
                      },
                      [],
                  )
                : [...dataArray];
        },
        [unSelect],
    );

    const handleSelect = (selectedValue) => {
        // 없음 선택
        if (unSelect && selectedValue === unSelect?.value) {
            setSelectedValues([unSelect?.value]);
            return;
        }

        // 전체 선택
        if (selectedValue === optionAll.value) {
            const chunkValues = sortActiveOption(chunkOptions);
            setSelectedValues(_.map(chunkValues, ({ value }) => value));
            return;
        }

        // 일반 선택
        setSelectedValues((prev) => {
            const incAll = _.includes(selectedValues, valueOfAll);
            const sizeAll = _.size(selectedValues) === _.size(option);

            // 일반 선택 하였으나, 이전 선택값에 전체 선택이 있는경우 전체선택 해제
            if (incAll || sizeAll) {
                return [selectedValue];
            }

            // 일반 선택 정상 케이스 선택한값 적제
            const chunkValues = sortActiveOption(prev);
            chunkValues.push(selectedValue);
            return chunkValues;
        });
    }; // 전체선택 해제

    const handleDeselect = (deselectedValue) => {
        if (deselectedValue === optionAll.value) {
            setSelectedValues([]);
        } else {
            setSelectedValues((prev) => {
                const chunkValues = _.clone(prev);
                _.pull(chunkValues, optionAll.value, deselectedValue);

                return chunkValues;
            });
        }
    }; // value 전달시 options 선택

    // useEffect(() => {
    //     if (value) {
    //         const chunkValues = value.split(seperator);
    //         if (option?.length === chunkValues.length) {
    //             setSelectedValues(_.concat(optionAll.value, chunkValues));
    //         } else {
    //             setSelectedValues(chunkValues);
    //         }
    //     } else {
    //         setSelectedValues([]);
    //     }
    // }, [value]); // options 변경 시 초기화

    useEffect(() => {
        if (chunkOptions?.length > 0) {
            if (value) {
                const chunkValues = _.chain(chunkOptions)
                    .reduce((result, { value }) => {
                        result.push(value);
                        return result;
                    }, [])
                    .intersection(value.split(seperator))
                    .value();

                const hasOptionAll = _.find(chunkOptions, [
                    'value',
                    optionAll.value,
                ]);

                if (
                    hasOptionAll &&
                    chunkOptions.length - 1 === chunkValues.length
                ) {
                    setSelectedValues(_.concat(optionAll.value, chunkValues));
                } else {
                    setSelectedValues(chunkValues);
                }
            } else {
                setSelectedValues([]);
            }
        }
    }, [chunkOptions, value]); // options 선택 시 value 저장

    useEffect(() => {
        const shallow = _.clone(selectedValues);
        const values = _.pull(shallow, optionAll.value).join(seperator);
        setValue(name, values, {
            shouldDirty: true,
        });
        // trigger(name);

        if (_.isFunction(onChangeValue)) {
            handleChangeValue(values);
        }

        // 언마운트 리셋
        return () => {
            setValue(name, undefined);
        };
    }, [name, selectedValues, seperator]); // 유효성 검사

    useEffect(() => {
        if (isSubmitted) {
            trigger(name);
        }
    }, [selectedValues, isSubmitted]);

    useEffect(() => {
        if (!dirtyFields[name]) {
            setSelectedValues([]);
        }
    }, [isDirty, option]);

    useEffect(() => {
        setSelectedValues([]);
    }, [dataClearToggle]);

    // 전체선택 의 경우 전체선택 하나만 노출하도록 input title 설정
    const inputTitle = useMemo(() => {
        const incAll = _.includes(selectedValues, valueOfAll);
        const sizeAll = _.size(selectedValues) === _.size(option);
        if (_.size(option) === 0) {
            return [];
        } else {
            return incAll || sizeAll ? valueOfAll : selectedValues;
        }
    }, [option, selectedValues]);

    return (
        <>
            <div
                style={{
                    opacity: 0,
                    height: 0,
                    width: 0,
                }}
            >
                <TextOrInput
                    name={name}
                    required={required}
                    validate={validate}
                    onFocus={() => {
                        const curRef = multiRef || ref;
                        if (curRef?.current) {
                            curRef?.current?.focus();
                        }
                    }}
                />
            </div>
            <MuxTextOrMultiSelect
                {...rest}
                {...rules}
                maxTagCount={maxTagCount}
                showArrow
                mode="tags"
                name={name}
                value={inputTitle}
                options={chunkOptions}
                placeholder={placeholderMessage}
                disabled={disable}
                getPopupContainer={getPopupContainer}
                onSelect={handleSelect}
                onDeselect={handleDeselect}
                ref={ref || multiRef}
            />
        </>
    );
});

TextOrMultiSelect.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    allowClear: PropTypes.bool,
    defaultActiveFirstOption: PropTypes.bool,
    disable: PropTypes.bool,
    required: PropTypes.bool,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    option: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.any, value: PropTypes.any }),
    ),
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    watch: PropTypes.arrayOf(PropTypes.string),
    onDetected: PropTypes.func,
    seperator: PropTypes.any,
};

TextOrMultiSelect.defaultProps = {
    disable: false,
    allowClear: false,
    defaultActiveFirstOption: false,
    required: false,
    editable: true,
    seperator: ',',
};

const TextOrMultiSelectWithbaseOption = (props) => {
    const { baseOption, ...rest } = props;

    const intl = useIntl();
    const chunkBaseOption = useMemo(() => {
        const { resId, value } = baseOption;

        if (_.isString(resId)) {
            return [{ label: intl.formatMessage({ id: resId }), value }];
        } else {
            return [];
        }
    }, [baseOption]);

    return withBaseOption(TextOrMultiSelect)({
        ...rest,
        baseOption: chunkBaseOption,
    });
};

TextOrMultiSelectWithbaseOption.propTypes = {
    baseOption: PropTypes.shape({
        resId: PropTypes.string,
        value: PropTypes.any,
    }),
};

export { TextOrMultiSelectWithbaseOption };
export default TextOrMultiSelect;
