import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FormattedMessage } from 'react-intl';
import {
    DefaultDialog as MuxDefaultDialog,
    FormDialog as MuxFormDialog,
} from 'mux';
import { Fragment } from 'react';

const emptyCancelFn = () => {};

const DefaultDialog = (props) => {
    const { resId, titleText, actions, children, onCancel, ...rest } = props;

    const title = resId ? <FormattedMessage id={resId} /> : titleText;
    const footerButtonProps = useMemo(
        () =>
            _.map(actions, (action, index) => {
                if (React.isValidElement(action)) {
                    return <Fragment key={index}>{action}</Fragment>;
                } else {
                    const { text, resId, type, ...restAction } = action;
                    const buttonProps = {
                        label: text || <FormattedMessage id={resId} />,
                        ...restAction,
                    };
                    switch (type) {
                        case 'submit':
                            buttonProps.htmlType = 'submit';
                            break;
                        default:
                            buttonProps.type = type;
                    }

                    return buttonProps;
                }
            }),
        [actions],
    );

    return (
        <MuxDefaultDialog
            title={title}
            footerButtonProps={footerButtonProps}
            onCancel={onCancel || emptyCancelFn}
            {...rest}
        >
            {children}
        </MuxDefaultDialog>
    );
};

DefaultDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    // onCancel: PropTypes.func.isRequired,
    resId: PropTypes.string,
    titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    actions: PropTypes.array,
};

DefaultDialog.defaultProps = {
    visible: false,
    actions: [{ resId: 'Btn.Confirm' }],
};

const FormDialog = (props) => {
    const {
        dialogId,
        resId,
        titleText,
        filterView,
        actions,
        children,
        ...rest
    } = props;

    const title = resId ? <FormattedMessage id={resId} /> : titleText;
    const searchButtonProps = useMemo(() => {
        return {
            label: <FormattedMessage id="Btn.Search" />,
            htmlType: 'submit',
            at: 'component',
        };
    });

    const footerButtonProps = useMemo(
        () =>
            _.map(actions, (action) => {
                if (React.isValidElement(action)) {
                    return action;
                } else {
                    const { text, resId, type, ...restAction } = action;
                    const buttonProps = {
                        label: text || <FormattedMessage id={resId} />,
                        ...restAction,
                    };
                    switch (type) {
                        case 'submit':
                            buttonProps.htmlType = 'submit';
                            break;
                        default:
                            buttonProps.type = type;
                            break;
                    }

                    return buttonProps;
                }
            }),
        [actions],
    );

    return (
        <div id={dialogId}>
            <MuxFormDialog
                dialogId={dialogId}
                title={title}
                filterView={filterView}
                searchButtonProps={searchButtonProps}
                footerButtonProps={footerButtonProps}
                {...rest}
            >
                {children}
            </MuxFormDialog>
        </div>
    );
};

FormDialog.propTypes = {
    resId: PropTypes.string,
    titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    actions: PropTypes.array,
};

FormDialog.defaultProps = {
    actions: [
        { resId: 'Btn.Cancel', type: 'cancel' },
        { resId: 'Btn.Confirm', htmlType: 'submit' },
    ],
};

const Dialog = ({ type, ...rest }) => {
    return (
        <>
            {type === 'default' ? (
                <DefaultDialog {...rest} />
            ) : (
                <FormDialog {...rest} />
            )}
        </>
    );
};

Dialog.propTypes = {
    type: PropTypes.oneOf(['form', 'default']),
    resId: PropTypes.string,
    titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    actions: PropTypes.array,
};

Dialog.defaultProps = {
    type: 'form',
    destroyOnClose: true,
    maskClosable: false,
};

export default Dialog;
