const language = {
    'Common.All': '全部',
    'Common.Login': '登录',
    'Common.Logout': '退出',
    'Common.Logout.Hello': '您好，{user}',
    'Common.Input.Required': '必填',
    'Common.Input.EnterSearchKeyword': '输入搜索词',
    'Common.Btn.Selected': '选择完成',
    'Common.Btn.Select': '选择',
    'Common.Btn.AllSearch': '全部搜索',
    'Common.Btn.Cancel': '取消',
    'Common.Btn.CancelEdit': '取消',
    'Common.Btn.CancelExtend': '取消',
    'Common.Btn.Filter': '选项',
    'Common.Btn.Create': '登记',
    'Common.Btn.Edit': '更改',
    'Common.Btn.EditInfo': '更改信息',
    'Common.Btn.Confirm': '确认',
    'Common.Btn.EditBusiness': '更改',
    'Common.Btn.EditInformation': '更改',
    'Common.Btn.CompleteReservation': '预约完成',
    'Common.Btn.CompleteEdit': '更改完成',
    'Common.Btn.Add': '添加',
    'Common.Btn.Delete': '删除',
    'Common.Btn.InUse': '使用',
    'Common.Btn.List': '目录',
    'Common.Btn.Return': '归还',
    'Common.Btn.ForceEdit': '强制更改',
    'Common.Btn.ExtendReturn': '延期归还',
    'Common.Btn.CompleteExtend': '延期归还',
    'Common.Btn.ForceReturn': '强制归还',
    'Common.Btn.CancelReservation': '取消预约',
    'Common.Btn.Approve': '同意',
    'Common.Btn.Deny': '拒绝',
    'Common.Btn.Map': '在地图上选择',
    'Common.Btn.SearchWeek': '一周',
    'Common.Btn.Search1Month': '1 个月',
    'Common.Btn.Search3Month': '1 个月',
    'Common.Btn.Show.Edit': '编辑',
    'Common.Btn.AddVehicle': '添加车辆',
    'Common.Btn.AllSelect': '全选',
    'Common.Btn.Apply': '适用',
    'Common.Btn.MultiSelected': '可重复选择',
    'Common.Confirm.Create': '确定要登记吗？',
    'Common.Confirm.Update': '确定要更改吗？',
    'Common.Confirm.Delete': '确定要删除吗？',
    'Common.Confirm.Deletes': '确定要删除{count}吗？',
    'Common.Confirm.Approve': '确定要同意吗？',
    'Common.Confirm.Deny': '确定要拒绝吗？',
    'Common.Confirm.Action': '确定要执行功能吗？',
    'Common.Confirm.UserDelete': '确定要注销吗？',
    'Common.Confirm.PwdChange': '确定要更改密码吗？',
    'Common.Confirm.InUse': '确定要更改为正在使用车辆吗？',
    'Common.Alert': '无法执行功能。',
    'Common.Created.Success': '已登记。',
    'Common.Updated.Success': '已更改。',
    'Common.Deleted.Success': '已删除。',
    'Common.Create.Fail': '无法登记。',
    'Common.Update.Fail': '无法更改。',
    'Common.Delete.Fail': '无法删除。',
    'Common.Search.Fail': '搜索失败。',
    'Common.Print': '打印',
    'Common.Alert.Delete.No.Checked': '请选择要删除的项目。',
    'Common.Alert.Approve.No.Checked': '请选择要同意的项目。',
    'Common.Alert.Deny.No.Checked': '请选择要拒绝的项目。',
    'Common.Alert.Pay.No.Checked': '请选择要支付的项目。',
    'Common.Msg.NoData': '无查询数据。',
    'Common.Msg.NoSearchData': '无搜索结果。',
    'Common.Msg.NoVehicle': '无此车辆。',
    'Common.Unit.Distance.meter': 'm',

    'Common.Unit.Money.0': '元',
    'Common.Unit.Money.10000': '0000元',
    'Common.Unit.Money.0.Value': '{value}元',
    'Common.Unit.Vehicle.Count': '台',
    'Common.Unit.Vehicle.Count.Value': '{value}台',
    'Common.Unit.Hour': '小时',
    'Common.Unit.Hour.Value': '{hours}小时',
    'Common.Unit.Minute': '分',
    'Common.Unit.Minute.Value': '{value}分',
    'Common.Unit.Age.Over': '岁以上',
    'Common.Unit.DiffDays.DaysHours.Valule': '{days}天 {hours}小时',
    'Common.Unit.DiffDays.Days.Valule': '{days}天',
    'Common.Unit.DiffDays.Hours.Valule': '{hours}小时',
    'Common.Today': '今天',
    'Common.Back': '返回',
    'Common.BirthDate.Year': '年',
    'Common.BirthDate.Month': '月',
    'Common.BirthDate.Day': '天',
    'Common.Role.Warn': '没有权限。',
    'Common.Validator.Help.Number': '请只输入数字',
    'Common.Validator.Help.Email': '请输入邮箱',
    'Common.Validator.Help.MaxNumber': '最多请输入 {compareValue}',
    'Common.Validator.Help.MinNumber': '最少请输入 {compareValue}以上',

    'Main.Title': '实时比较短期和长期，{br}预约更简单',
    // 'Main.SubTitle': ' ',
    'Main.GettingStart': '開始',

    'Footer.Policy1': '服务使用条款',
    'Footer.Policy2': '隐私政策',
    'Footer.Mocean': 'MOCEAN',
    'Footer.Address':
        '907-ho, A-dong, Bundang-Suji U-TOWER, 767, Sinsu-ro, Suji-gu, Yongin-si, Gyeonggi-do, Republic of Korea',

    'DayTimePicker.Help.From.Selected': '请选择起始日',
    'DayTimePicker.Help.From.After.Now': '起始日请选择早于今天的日期',
    'DayTimePicker.Help.To.Selected': '请选择结束日',
    'DayTimePicker.Help.To.Before.From': '结束日请选择晚于起始日期',

    'Login.Alert.SystemError': '临时系统错误',
    'Auth.Check.Alert.SystemError': '登录路径错误。请重新登录。',
    'Join.Alert.SystemError': '请稍后重新登录。',

    'Auth.Alert.Expired': 'Session 过期，退出登录。请重新登录',
    'Auth.Alert.Forbidden': '没有权限。',
    'Auth.Wait': '请稍候！',

    'Menu.VehicleManager': '车辆管理',
    'Menu.ReservationManager': '预约管理',
    'Menu.VehicleUseManager': '租赁结束',
    'Menu.PaymentManager': '销额/结算管理',
    'Menu.Location': '网点管理',
    'Menu.Terminal': '终端管理',
    'Menu.User': '用户管理',
    'Menu.MyPage': '我的页面',

    'Title.Main': '主页',
    'Title.VehicleMonitoring': '车辆管制',
    'Title.VehicleManager': '车辆管理',
    'Title.Vehicle': '车辆查询/登记',
    'Title.VehicleModel': '车辆型号管理',
    'Title.Terminal': '终端管理',
    'Title.ReservationManager': '预约管理',
    'Title.ReservationList': '预约信息查询',
    'Title.ReservationCreate': '新的预约',
    'Title.MakeReservation.BUSINESS_CORP': '新的预约（连锁法人）',
    'Title.UseList': '使用情况',
    'Title.EndUseList': '租赁结束',
    'Title.PaymentList': '支付内容',
    'Title.Charge': '费用',
    'Title.ChargeVehicle': '各车辆费用管理',
    'Title.UseStateList': '租赁情况',
    'Title.Insurance': '保险费',
    'Title.InsuranceVehicle': '各车辆保险费管理',
    'Title.PenaltyAndToll': '滞纳金/通行费',
    'Title.Location': '网点',
    'Title.LocationCreate': '登记网点',
    'Title.LocationEdit': '更改网点',
    'Title.Member': '管理员',
    'Title.Business': '公司管理',
    'Title.BusinessCreate': '登记公司',
    'Title.Customer': '会员管理',

    'Monitoring.LatestUpdate': '最新更新',
    'Monitoring.ViewUnavailableVehicle': '查看问题车辆',
    'Monitoring.ViewAllVehicle': '查看全部车辆',
    'Monitoring.CurrentLocation': '当前位置',
    'Monitoring.Location': '网点位置',
    'Monitoring.SeeMore': '更多',
    'Monitoring.NoUnavailableVehicle': '无问题车辆。',
    'Monitoring.Vehicle.DTE': '燃油剩余量',
    'Monitoring.Vehicle.AUXbattery': '电池电压',
    'Monitoring.Vehicle.Connectivity': '通信状态',
    'Monitoring.Unknown': '未知',
    'Monitoring.Normal': '正常',

    'Reservation.Btn.Create': '新的预约',
    'Reservation.Btn.Filter': '选项',
    'Reservation.Btn.Selected': '预约完成',
    'Reservation.Code': '预约号',
    'Reservation.Birth': '出生年月日',
    'Reservation.Birth.Placeholder': 'YYYY.MM.DD',
    'Reservation.BirthYear.Placeholder': '2000',
    'Reservation.BirthMonth.Placeholder': '0',
    'Reservation.BirthDate.Placeholder': '0',
    'Reservation.Address': '地址',
    'Reservation.Address.Zip.Placeholder': '邮编',
    'Reservation.Address.Placeholder': '请输入地址',
    'Reservation.DetailAddress.Placeholder': '请输入详细地址',
    'Reservation.Address.Search': '搜索邮编',
    'Reservation.LicenseInfo': '驾驶证信息',
    'Reservation.License': '驾驶证号码',
    'Reservation.License.Placeholder': '驾驶证号码',
    'Reservation.License.Confirm': '驾驶证认证',
    'Reservation.License.ConfirmFinish': '认证完成',
    'Reservation.License.DueDate': '驾驶证有效期',
    'Reservation.License.DueDate.DayPicker': '请输入驾驶证有效期',
    'Reservation.License.Check': '请完成驾驶证认证',
    'Reservation.License.AdditionalCheck': '请完成共用驾驶员的驾驶证认证',
    'Reservation.License.ValidCheck': '请输入所有驾驶证认证项目。',
    'Reservation.Edit.Warn': '预约的起始时间已过，无法更改预约。',
    'Reservation.GetProfile.NoData': '无一致的会员信息。',
    'Reservation.GetEmployeeProfile.NoData': '无法找到职员信息。',
    'Reservation.GetProfile.fail': '查找会员信息失败。',
    'Reservation.Location.NotMatch': '与归还位置不一致，无法归还。',
    'Reservation.RemoteControl.Fail': '车辆操作失败，无法归还。',
    'Reservation.PaymentDetail.IsNull': '没有费用套餐，无法更改。',
    'Reservation.Date.IsEqual': '预约结束日不能早于或等同于起始日。',
    'Reservation.Date.Invalid': '预约起始日不能早于今天。',
    'Reservation.AlreadyUseCarExist.Fail': '已有正在使用的车辆，无法预约。',
    'Reservation.VehicleUsePay.IsZero': '无费用套餐，或设置了 0 元。',
    'Reservation.Extend.CheckEndDate': '归还延期日不得早于或等同于预约结束日。',
    'Reservation.Deny.DefaultMessage': '请输入内容。',
    'Reservation.TotalPay.Changed': '费用套餐已变更。',
    'Reservation.Insurance.Invalid': '车辆保险过期，无法预约。',
    'Reservation.InsuranceEdit.Invalid': '车辆保险过期，无法更改预约。',
    'Reservation.UserNameInfo': '预约人信息',
    'Reservation.UserName': '预约人',
    'Reservation.Name': '姓名',
    'Reservation.CustName': '客户名',
    'Reservation.Status': '状态',
    'Reservation.ReservationStatus': '预约状态',
    'Reservation.UserName.Placeholder': '请输入预约人姓名',
    'Reservation.UserName.Confirm': '查找当前会员',
    'Reservation.UserName.ReConfirm': '变更预约人',
    'Reservation.UserInfo.Search': '查找会员信息',
    'Reservation.UserInfo.Confirm': '确认会员信息',
    'Reservation.Employee.Confirm': '查找员工',
    'Reservation.EmployeeInfo.Search': '查找员工信息',
    'Reservation.EmployeeInfo.Confirm': '确认员工信息',
    'Reservation.Share.UserName': '共用驾驶员',
    'Reservation.Share.UserName.Placeholder': '请输入共用驾驶员',
    'Reservation.Mobile': '联系方式',
    'Reservation.Mobile.Placeholder': '联系方式',
    'Reservation.Team': '所在小组',
    'Reservation.Business': '所在法人',
    'Reservation.Team.Placeholder': '所在小组',
    'Reservation.CampanyNumber': '工号',
    'Reservation.CampanyNumber.Placeholder': '工号',
    'Reservation.EmailAddress': '邮箱',
    'Reservation.EmailAddress.Placeholder': '请输入邮箱地址',
    'Reservation.Insurance.Fee': '保险费',
    'Reservation.Rent.Fee': '租金',
    'Reservation.BusinessInfo': '选择公司',
    'Reservation.Business.Rent': '普通',
    'Reservation.Business.Corp': '法人',
    'Reservation.VehicleInfo': '车辆信息',
    'Reservation.VehicleUseFeeInfo': '车辆使用费用',
    'Reservation.UseFeeInfo': '使用费用',
    'Reservation.VehicleModel': '车种',
    'Reservation.BusinessName': '公司名称',
    'Reservation.DateRange': '预约时间',
    'Reservation.RentalPeriod': '租赁时间',
    'Reservation.MobileReservation': '客户端预约',
    'Reservation.Date': '预约日期',
    'Reservation.DateRange.Placeholder.Start': '起始日期',
    'Reservation.DateRange.Placeholder.End': '结束日期',
    'Reservation.InsuranceAge': '保险年龄',
    'Reservation.InsuranceAge.Placeholder': '21 岁以上驾驶员保险',
    'Reservation.UseFee': '车辆使用费用',
    'Reservation.ExtendFee': '延期金额',
    'Reservation.UseFee.Result': '行驶金额 {fee}元/km',
    'Reservation.StartLocation': '取车地点',
    'Reservation.StartLocation.Placeholder': '选择取车地点',
    'Reservation.StartLocation.Button': '在地图上选择',
    'Reservation.VehicleNumber': '车牌号',
    'Reservation.DenyReson': '拒绝理由',
    'Reservation.LicenseType': '驾驶证种类',
    'Reservation.VehicleNumber.Placeholder': '请选择车辆',
    'Reservation.UseFeeInfo.Placeholder': '0',
    'Reservation.UseFee.Placeholder': '0',
    'Reservation.ExtendFee.Placeholder': '0',
    'Reservation.Confirm.ExtendFee': '请输入延期金额。',
    'Reservation.BusinessType.Placeholder': '请选择公司',
    'Reservation.Confirm.Return': '确定要归还此车辆吗？',
    'Reservation.Confirm.ForceReturn': '确定要强制归还吗？',
    'Reservation.Confirm.Extend': '确定要延期归还吗？',
    'Reservation.Confirm.Cancel': '确定要取消预约吗？',
    'Reservation.Confirm.Confirm': '确定要同意吗？',
    'Reservation.Confirm.Deny': '确定要拒绝吗？',
    'Reservation.DenyReason.Confirm': '请输入拒绝理由',
    'Reservation.Return.Complete': '已归还。',
    'Reservation.ForceReturn.Complete': '已强制归还。',
    'Reservation.Extend.Complete': '已延期归还。',
    'Reservation.Confirm.Complete': '已成功预约。',
    'Reservation.Cancel.Complete': '已取消预约。',
    'Reservation.Deny.Complete': '已拒绝预约。',
    'Reservation.Delete.Complete': '已删除预约。',
    'Reservation.Update.Complete': '已更改预约。',
    'Reservation.Create.Complete': '已登记预约。',
    'Reservation.InUse.Complete': '此预约状态已更改为正在使用中。',
    'Reservation.InsruanceAge.ConfirmFail': '预约人年龄无法预约所选车辆。',
    'Reservation.AdditionalInsruanceAge.ConfirmFail':
        '共用驾驶员年龄无法预约所选车辆。',
    'Reservation.LicenseType.Placeholder': '驾驶证种类',
    'Reservation.InsuranceList': '保险种类',
    'Reservation.InsuranceList.Placeholder': '请选择保险种类',
    'Reservation.Complete.NameMobileIsNull': '请输入预约人和联系方式。',
    'Reservation.Extend.AlreadyReserved': '此时间段已有预约车辆。',
    'Reservation.InsuranceAge.IsOver': '{insuranceAge}岁以上',
    'Reservation.List.Fail': '无法打开预约内容。',
    'Reservation.Approve.Fail': '同意失败。',
    'Reservation.Deny.Fail': '拒绝失败。',
    'Reservation.GetRentCar.Fail': '无法打开租车公司目录。',
    'Reservation.EndList.Fail': '无法打开租赁结束内容。',
    'Reservation.GetAvailable.Fail': '无法打开可预约车辆目录。',
    'Reservation.GetLocation.Fail': '无法打开网点目录。',
    'Reservation.MustHave.Fail': '请输入所有必填项。',
    'Reservation.AddReservation.Fail': '预约登记失败。',
    'Reservation.AlreadyReserved.Fail': '车辆已预约。',
    'Reservation.GetUseList.Fail': '无法打开使用情况。',
    'Reservation.GetTimeList.Fail': '无法打开使用情况。',
    'Reservation.Return.Fail': '无法归还车辆。',
    'Reservation.Return.StartFail': '预约起始时间前，无法归还车辆。',
    'Reservation.ForceReturn.Fail': '强制归还失败。',
    'Reservation.ForceReturn.StartFail': '预约起始时间前，无法归还车辆。',
    'Reservation.Cancel.Fail': '无法取消预约。',
    'Reservation.Inuse.Fail': '状态无法变更为正在使用车辆。',
    'Reservation.InUse.TimeIsInvalid': '起始15分钟前状态可变更为正在使用车辆。',
    'Reservation.Delete.Fail': '无法删除预约。',
    'Reservation.Extend.Fail': '无法延期归还。',
    'Reservation.Update.Fail': '无法更改预约。',
    'Reservation.LicenseCheck.Fail': '驾驶证认证失败。',
    'Reservation.LicenseCheck.Invalid': '驾驶证信息无效。请重新确认信息。',
    'Reservation.LicenseExpiryDate.InValid':
        '租赁起始日超过了驾驶证更新有效期。',
    'Reservation.LicenseExpiryDate.AddTimeIsInvalid':
        '租赁起始日超过了共用驾驶员驾驶证更新有效期。',
    'Reservation.GetPaymentDetail.Fail': '费用信息打开失败。',
    'Reservation.GetInsuranceList.Fail': '保险目录打开失败。',
    'Reservation.View.Fail': '无法查询预约信息。',
    'Reservation.CurrentVehicle.AlreadyReserved': '车辆已预约。',
    'Reservation.Edit.NotAvailable': '预约无法更改。',
    'Reservation.OfflinePayment.Notify': '仅可在线下支付费用。',
    'Reservation.DenyReason.Placeholder': '请输入拒绝理由',
    'Reservation.Payment.NotMinTime': '相应预约时间要少于最短使用时间。',
    'Reservation.Payment.NotMinUseTime': '相应延期时间要少于最短使用时间。',
    'Reservation.Payment.NoCharge': '车辆未设定费用套餐。',
    'Reservation.Payment.NoInsurance': '车辆未设定保险金额。',

    'Reservation.Filter.LicensePlateNumber': '车牌号',
    'Reservation.Filter.Code': '预约号',
    'Reservation.Filter.User.Name': '预约人姓名',
    'Reservation.Filter.User.MobileNumber': '预约人联系方式',
    'Reservation.Filter.Location.Name': '网点名',
    'Reservation.Filter.Business.Name': '法人名称',

    'Reservation.DayTimePicker.Title': '请选择租赁日',
    'Rerservation.DayPicker.Title': '请输入租赁日和归还日',
    'Rerservation.DayPicker.StartTime': '租赁日',
    'Rerservation.DayPicker.EndTime': '归还日',
    'Rerservation.Btn.All.Confirm': '全部同意',
    'Rerservation.Btn.Confirm': '同意',
    'Rerservation.Btn.Deny': '拒绝',
    'Reservation.DateFormat.Placeholder': 'YYYY.MM.DD',

    'UseStateCalendar.No': 'NO.',
    'UseStateCalendar.LicensePlateNumber': '车牌号',

    'ReservationCalendar.Calendar.Item.Label.requested': '等待同意: {count}',
    'ReservationCalendar.Calendar.Item.Label.confirmed': '预订：{count}',
    'ReservationCalendar.Calendar.Item.Label.canceled': '取消预约 : {count}',
    'ReservationCalendar.Calendar.Item.Label.returned': '计划归还 : {count}',
    'ReservationCalendar.Admin.Title': '预约情况',
    'ReservationCalendar.Admin.Item': '{businessName}{br}{reservationCount}',
    'ReservationCalendar.Private.Desc': '自动取消预约时间内未同意的预约。',
    'ReservationCalendar.Private.Requested.Title': '等待同意',
    'ReservationCalendar.Private.Requested.Item': '等待同意 : {requestedCount}',
    'ReservationCalendar.Private.Confirmed.Title': '预约状态',
    'ReservationCalendar.Private.Confirmed.Item': '{groupName} {length}',
    'ReservationCalendar.Private.Group.Confirmed.Item':
        '{licensePlateNumber}{br}预约人 {user}',
    'ReservationCalendar.Private.Group.Requested.Item':
        '{licensePlateNumber}{br}{teamName} / {user}',
    'ReservationCalendar.Private.Confirm.Approve': '确定要同意勾选项吗？',
    'ReservationCalendar.Private.Confirm.Approve.All': '确定要全部同意吗？',
    'ReservationCalendar.Public.Confirmed.Title': '预约状态',
    'ReservationCalendar.Public.Confirmed.Item':
        '[{code}] {reservationTime}{br}{name} / {totalPay}元 //[{code}]{reservationTime}{br}{name}/{totalPay}元',
    'ReservationCalendar.Public.Retruned.Item':
        '[{code}] {reservationTime}{br}{name}',
    'ReservationCalendar.Public.Returned.Title': '计划归还',
    'ReservationCalendar.Public.Returned.Item':
        '{reservationTime}{br}{code} / {name}',
    'ReservationCalendar.Month.Get.Fail': '无法打开月预约情况。',
    'ReservationCalendar.Business.Get.Fail': '无法打开各法人预约情况。',
    'ReservationCalendar.Public.Get.Fail': '无法打开租赁请况。',
    'ReservationCalendar.Private.Get.Fail': '无法打开法人预约情况。',

    'Vehicle.Information': '车辆信息',
    'Vehicle.State.Sub.Title': '车辆信息',
    'Vehicle.Reservation.Sub.Title': '预约状态',
    'Vehicle.Model': '车型',
    'Vehicle.Color': '车辆颜色',
    'Vehicle.Transmission': '变速方式',
    'Vehicle.LicensePlateNumber': '车辆登记号码',
    'Vehicle.VIN': 'VIN No.',
    'Vehicle.Status': '状态',
    'Vehicle.ControllerID': '终端机号',
    'Vehicle.RegistrationDate': '车辆登记日',
    'Vehicle.ProductionDate': '生产日期',
    'Vehicle.Options': '选项',
    'Vehicle.Business': '所有法人',
    'Vehicle.Location': '网点',
    'Vehicle.Insurance.Company': '保险公司',
    'Vehicle.Insurance.Age': '保险年龄',
    'Vehicle.Insurance.JoinDate': '投保日',
    'Vehicle.Insurance.ExpireDate': '到期日',
    'Vehicle.Insurance.PD': '对物限度',
    'Vehicle.Insurance.BI': '对人限度',
    'Vehicle.Insurance.DB': '车损限度',
    'Vehicle.RegistrationDate.Placeholder': 'ex) 20190101',
    'Vehicle.Model.Placeholder': '请选择型号。',
    'Vehicle.Transmission.Placeholder': '请选择变速方式。',

    'Vehicle.VIN.Placeholder': 'VIN 为包含英文、数字的 17 位字符。',
    'Vehicle.VIN.Help': 'VIN 为包含英文、数字的 17 位字符。',

    'Vehicle.ControllerID.Help': '联系方式请输入数字。',
    'Vehicle.Business.Placeholder': '请选择法人。',
    'Vehicle.Location.Placeholder': '请选择网点。',
    'Vehicle.Options.Placeholder': '请选择选项。',
    'Vehicle.Options.No.Data': '请选择选项。',
    'Vehicle.Insurance.Company.Placeholder': '请输入保险公司。',
    'Vehicle.Insurance.Age.Placeholder': '请选择投保年龄。',
    'Vehicle.Insurance.JoinDate.Placeholder': '请输入投保日。',
    'Vehicle.Insurance.PD.Placeholder': '请输入对物限度。',
    'Vehicle.Insurance.BI.Placeholder': '请输入对人限度。',
    'Vehicle.Insurance.DB.Placeholder': '请输入车损限度。',
    'Vehicle.ControllerID.Help.Already.Use': '已存在输入的终端机号。',
    'Vehicle.VIN.Help.Already.Use': '已存在输入的 VIN。',
    'Vehicle.Btn.Controller': '确认终端机',
    'Vehicle.View.Fail': '无法查询车辆信息。',
    'Vehicle.Delete.Fail': '无法删除正在使用的车辆。',
    'Vehicle.Confirm.Delete': '确定删除车辆信息？',
    'Vehicle.Confirm.Deletes':
        '确认删除勾选项吗？{br}无法删除部分正在使用的车辆。',

    'Vehicle.State.TpmsFl': '前轮/左',
    'Vehicle.State.TpmsFr': '前轮/右',
    'Vehicle.State.TpmsRl': '后轮/左',
    'Vehicle.State.TpmsRr': '后轮/右',
    'Vehicle.State.Tpms.Unit': 'PSI',
    'Vehicle.State.Battery': '电池电压',
    'Vehicle.State.FuelLevel': '燃油剩余量',

    'Vehicle.Confirm.Control.Open': '确定要开门吗？',
    'Vehicle.Confirm.Control.Close': '确定要关门吗？',
    'Vehicle.Confirm.Control.Siren': '确定要打开危险指示灯/喇叭吗？',
    'Vehicle.Control': '车辆操作',
    'Vehicle.Control.Open': '开门',
    'Vehicle.Control.Close': '关门',
    'Vehicle.Control.Siren': '危险指示灯/喇叭',
    'Vehicle.Control.Open.Action': '开启',
    'Vehicle.Control.Close.Action': '开启',
    'Vehicle.Control.Siren.Action': '开启',
    'Vehicle.Control.Requested.Success': '向车辆发出信号。',
    'Vehicle.Control.Request.Fail': '无法向车辆发出信号。',
    'Vehicle.Control.Opened.Success': '门开了。',
    'Vehicle.Control.Closed.Success': '门关了。',
    'Vehicle.Control.Siren.Success': '危险指示灯/喇叭开启。',
    'Vehicle.Control.Fail': '操作中发生错误。',
    'Vehicle.Control.Fail.Code': '请咨询管理员。 [{code}]',
    'Vehicle.Control.Timeout': '操作结果收信延迟。',

    'VehicleModel.Information': '车辆信息',
    'VehicleModel.Name': '车型',
    'VehicleModel.Name.Placeholder': '请输入车型。',
    'VehicleModel.Image.Helper': '必须登记车辆照片。',
    'VehicleModel.Brand': '制造商',
    'VehicleModel.Brand.Placeholder': '请选择制造商。',
    'VehicleModel.FuelType': '燃油类型',
    'VehicleModel.FuelType.Placeholder': '请选择燃油类型。',
    'VehicleModel.SeatingCapacity': '乘坐人数（人乘）',
    'VehicleModel.SeatingCapacity.Placeholder': '请选择乘坐人数。',
    'VehicleModel.SeatingCapacityP': '乘坐人数（人乘）',
    'VehicleModel.Displacement': '排量(cc)',
    'VehicleModel.Displacement.Placeholder': 'ex) 1800',
    'VehicleModel.FuelTankCapacity': '燃油箱容量',
    'VehicleModel.FuelTankCapacity.Placeholder': 'ex) 70',
    'VehicleModel.FuelTankCapacityE': '电池容量(kWh)',
    'VehicleModel.FuelEfficiency': '油耗(km/l)',
    'VehicleModel.FuelEfficiency.Helper': '油耗要输入整数或小数。ex)12.5',
    'VehicleModel.FuelEfficiency.Placeholder': 'ex) 12.5',
    'VehicleModel.Grade': '车辆等级',
    'VehicleModel.Grade.Placeholder': '车辆等级',
    'VehicleModel.Standard': '代表车型',
    'VehicleModel.StandardTag': '代表',
    'VehicleModel.Confirm.Deletes':
        '确定要删除{count}的车型吗？{br}无法删除车辆登记的型号。',
    'VehicleModel.GetData.Fail': '车型查询失败。',
    'VehicleModel.Create.Success': '车型已登记。',
    'VehicleModel.Create.Fail': '车型登记失败。',
    'VehicleModel.Update.Success': '车型已更改。',
    'VehicleModel.Update.Fail': '车型更改失败。',
    'VehicleModel.UpdateImage.Fail': '车型照片登记失败。',
    'VehicleModel.Delete.Success': '车型已删除。',
    'VehicleModel.Delete.Fail': '车型删除失败。',
    'VehicleModel.Fail.DuplicatedModelName':
        '모델명 중복으로 기능을 수행할 수 없습니다.',

    'Terminal.Title.Create': '登记终端机',
    'Terminal.Title.Info': '终端机详细信息',
    'Terminal.Vehicle.LicensePlateNumber': '车牌号',
    'Terminal.VIN': 'VIN No.',
    'Terminal.NadID': '联系方式',
    'Terminal.Serial': 'Serial No.',
    'Terminal.Manufacturer': '制造商',
    'Terminal.Version': 'S/W Ver.',
    'Terminal.OrderDate': '下单日',
    'Terminal.DeliveryDate': '交货期',
    'Terminal.MobileOperator': '通讯公司',
    'Terminal.ActivationDate': '通讯开通日',
    'Terminal.ICCID': 'USIM 号',
    'Terminal.State': '终端机状态',
    'Terminal.Status': '终端管理',
    'Terminal.State.Status': '终端机状态',
    'Terminal.VIN.Placeholder': 'VIN 为包含英文、数字的 17 位字符。',
    'Terminal.NadID.Placeholder': '请输入联系方式。',
    'Terminal.Manufacturer.Placeholder': '请输入制造商。',
    'Terminal.Serial.Placeholder': 'ex) CASD12434052DS888',
    'Terminal.Version.Placeholder': '输入软件版本',
    'Terminal.OrderDate.Placeholder': 'ex) 20191201',
    'Terminal.DeliveryDate.Placeholder': 'ex) 20191201',
    'Terminal.MobileOperator.Placeholder': '请选择通讯公司。',
    'Terminal.ActivationDate.Placeholder': 'ex) 20191201',
    'Terminal.ICCID.Placeholder': '输入号码',
    'Terminal.State.Placeholder': '连接后自动显示',
    'Terminal.Status.Placeholder': '请选择终端机管理状态。',
    'Terminal.Get.Fail': '无法查询终端机信息。',

    'Charge.Btn.Business.List': '法人目录',
    'Charge.Title': '费用',
    'Charge.Name': '费用套餐名称',
    'Charge.Reservation': '可预约的最短时间',
    'Charge.PriceTime': '费用的时间单位',
    'Charge.Price.Helper': '费用必须是 0 元以上的整数。',
    'Charge.Week_day_charge': '工作日费用',
    'Charge.Week_end_charge': '周末费用',
    'Charge.Insurance': '免责保险费',
    'Charge.Peak_season_week_day_charge': '旺季工作日费用',
    'Charge.Peak_season_week_end_charge': '旺季周末费用',
    'Charge.Peak_season': '时间设定',
    'Charge.TimePrice': '各租赁时间费用一览表',
    'Charge.Vehicle.Apply': '适用车辆',
    'Charge.WeekDay': '工作日',
    'Charge.WeekEnd': '周末',
    'Charge.PeakWeekDay': '旺季工作日',
    'Charge.PeakWeekEnd': '旺季周末',
    'Charge.VehicleNoData': '请添加适用费用套餐的车辆',
    'Charge.PriceTimeUnit': '单位',
    'Charge.MinReservation': '最短时间',
    'Charge.Vehiclemodel.Name': '车种',
    'Charge.Vehicle.licenseplatenumber': '车牌号',
    'Charge.Vehicle.Charge': '费用',
    'Charge.PickerInfoMsg': '*不输入旺季费用的，适用普通费用。',
    'Charge.Vehicle': '车辆',
    'Charge.Vehicle.Msg': '车辆',
    'Charge.Name.Placeholder': '请输入费用套餐',
    'Charge.Insurance.Placeholder': '请选择免责保险费',
    'Charge.Peak_season_start.Placeholder': '起始日期',
    'Charge.Peak_season_end.Placeholder': '结束日期',
    'Charge.Week_day_charge.Placeholder': '0',
    'Charge.Week_end_charge.Placeholder': '0',
    'Charge.Peak_season_week_day_charge.Placeholder': '0',
    'Charge.Peak_season_week_end_charge.Placeholder': '0',
    'Charge.PriceTime_hour.Placeholder': '0小时',
    'Charge.PriceTime_minute.Placeholder': '0分钟',
    'Charge.Reservation_hour.Placeholder': '0小时',
    'Charge.Reservation_minute.Placeholder': '0分钟',
    'Charge.WarningMsg':
        '已含有其他费用套餐的车辆。{br}不想更改费用套餐可取消勾选。',
    'Charge.NotCreate': '未登记',
    'Charge.Vehicle.NoData': '无车辆',
    'Charge.Confirm.Deletes':
        '确定删除勾选项吗？{br}以此费用套餐预定的车辆适用删除前的费用。',
    'Charge.Vehicle.Confirm.Deletes': '确定要删除勾选项吗？',
    'Charge.First_discount': '3 天以上租赁优惠率(%)',
    'Charge.Second_discount': '7 天以上租赁优惠率(%)',
    'Charge.Toast.Business.Fail': '公司目录查询失败。',
    'Charge.Toast.ChargeList.Fail': '套餐费用目录查询失败。',
    'Charge.Toast.ChargeDetail.Fail': '套餐费用详细内容查询失败。',
    'Charge.Toast.ChargeCreate.Success': '已登记套餐费用。',
    'Charge.Toast.ChargeCreate.Fail': '套餐费用登记失败。',
    'Charge.Toast.ChargeUpdate.Success': '已更改套餐费用。',
    'Charge.Toast.ChargeUpdate.Fail': '套餐费用更改失败。',
    'Charge.Toast.DetailVehicleList.Fail': '套餐费用车辆目录查询失败。',
    'Charge.Toast.VehicleList.Fail': '车辆目录查询失败。',
    'Charge.Toast.Delete.Success': '已删除套餐费用。',
    'Charge.Toast.Delete.Fail': '已有预约套餐费用。',
    'Charge.Toast.ChargeDelete.Fail': '套餐费用删除失败。',
    'Charge.Toast.VehicleChargeDelete.Success': '已删除套餐费用。',
    'Charge.Toast.VehicleChargeDelete.Fail': '套餐费用删除失败。',
    'Charge.Toast.VehicleChargeList.Fail': '无法打开车型目录。',

    'Insurance.Vehicle': '车辆',
    'Insurance.GroupName': '保险费类别名称',
    'Insurance.Info': '保险费信息',
    'Insurance.Name': '保险名称',
    'Insurance.SelfCost': '个人承担费用（元）',
    'Insurance.Time': '费用的时间单位',
    'Insurance.Premium': '保险费（元）',
    'Insurance.TimePrice': '各租赁时间保险费',
    'Insurance.Charge1': '保险费1',
    'Insurance.Charge2': '保险费2',
    'Insurance.Charge3': '保险费3',
    'Insurance.WarningMsg':
        '已存在其他套餐费用的车辆。{br}不想更改套餐费用可取消勾选。',
    'Insurance.Confirm.Deletes':
        '确定删除勾选项吗？{br}以此保险费预定的车辆适用删除前的费用。',
    'Insurance.Toast.InsuranceList.Fail': '保险费目录查询失败。',
    'Insurance.Toast.InsuranceDetail.Fail': '保险费详细内容查询失败。',
    'Insurance.Toast.InsuranceUpdate.Success': '保险费已更改。',
    'Insurance.Toast.InsuranceUpdate.Fail': '保险费更改失败。',
    'Insurance.Toast.InsuranceDelete.Success': '保险费已删除。',
    'Insurance.Toast.InsuranceDelete.Fail': '保险费删除失败。',
    'Insurance.Toast.InsuranceCreate.Success': '已登记保险费。',
    'Insurance.Toast.InsuranceCreate.Fail': '保险费登记失败。',
    'Insurance.Toast.DetailVehicleList.Fail': '保险费车辆目录查询失败。',
    'Insurance.Toast.VehicleChargeList.Fail': '无法打开车型目录。',
    'Insurance.Toast.VehicleChargeDelete.Success': '已删除各车辆保险费。',
    'Insurance.Toast.VehicleChargeDelete.Fail': '各车辆保险费删除失败。',
    'Insurance.Toast.Delete.Fail': '已有预约的保险费。',
    'Insurance.Toast.Business.Fail': '公司目录查询失败。',

    'SalesManager.AppReservation': 'APP 预约',
    'SalesManager.FMSReservation': 'WEB 预约',
    'SalesManager.Use_pay': '租赁/延期',
    'SalesManager.Premium': '保险费',
    'SalesManager.Receivables_pay': '应收金额',
    'SalesManager.Receivables_pay_total': '{month}月应收金额{br}（累积）',
    'SalesManager.Sales_total': '总计',
    'SalesManager.Month_total': '{month}月销售额合计{br}（累积）',
    'SalesManager.Won': '{amount}元',
    'SalesManager.Toast.PaymentMonthList.Fail': '每月目录查询失败。',
    'SalesManager.Toast.PaymentDayList.Fail': '每日目录查询失败。',
    'SalesManager.Toast.PaymentBusinessList.Fail': '公司目录查询失败。',

    'UseState.State.InUse': '正在使用',
    'UseState.State.Wait': '等待',
    'UseState.State.Unavailable': '异常',
    'UseState.State.LowVoltage': '低电压',
    'UseState.State.NotReturned': '未归还',
    'UseState.All': '全部',
    'UseState.Use': '正在使用',
    'UseState.Toast.UseStatusList.Fail': '使用情况目录查询失败。',
    'UseState.Toast.UseStateCount.Fail': '使用情况目录查询失败。',

    'Payment.Processing': '支付处理',
    'Payment.Complete': '支付完成',
    'Payment.Status': '状态/金额',
    'Payment.PaymentStatus': '支付状态',
    'Payment.UnpaidPay': '未缴金额',
    'Payment.ActualPay': '实际支付金额',
    'Payment.Status.Complete': '支付完成',
    'Payment.Status.Unpaid': '未缴纳',
    'Payment.Status.Wait': '等待支付',
    'Payment.Status.Fail': '支付失败',
    'Payment.Alert.NoPaymentData': '无此支付信息。',
    'Payment.Alert.Delete.No.Checked': '请选择要进行支付的预约。',
    'Payment.Confirm.Pay': '确定要进行{count}个支付吗？',
    'Payment.Pay.Fail': '支付处理失败。',
    'Payment.Get.Fail': '支付记录查询失败。',
    'Payment.Pay.No.Checked': '无要支付的项目。',
    'Payment.Pay.MultiFail': '{count}的支付失败',
    'Payment.Pay.Success': '支付完成。',

    'Local.Address.Get.Fail': '地址变更失败。',
    'Local.Geocode.Get.Fail': '地址变更失败。',
    'Local.Search.Fail': '搜索失败。',

    Location: '网点',
    'Location.Placeholder': '在地图上移动标记，自动输入地址',
    'Location.Name': '网点名',
    'Location.Name.Placeholder': '请输入网点名',
    'Location.Address': '地址',
    'Location.Address.ModalTitle': '搜索地址',
    'Location.Address.Placeholder': '自动地址完成区域',
    'Location.DetailedAddress': '详细地址',
    'Location.DetailedAddress.Placeholder': '请输入详细地址',
    'Location.VehicleCount': '拥有车辆',
    'Location.Business': '所有法人',
    'Location.Business.Name': '法人名称',
    'Location.Business.Opened': '共同网点',
    'Location.Business.Name.Placeholder': '请选择法人',
    'Location.Confirm.MultiDelete': '确定要删除{count}个网点吗？',
    'Location.Create.Success': '已登记网点。',
    'Location.Create.Fail': '网点登记失败。',
    'Location.Update.Success': '已更改网点信息。',
    'Location.Update.Fail': '网点信息更改失败。',
    'Location.Delete.Success': '已删除网点。',
    'Location.Delete.Fail': '网点删除失败。',
    'Location.Delete.Fail.VehicleExists': '网点存在车辆，删除失败。',
    'Location.View.Fail': '无法查询网点信息。',
    'Location.Monitoring.Fail': '无法查询网点车辆信息。',
    'Location.Unit.Vehicle.Count': '{value}台',
    'Location.Radius': '归还网点（半径）',
    'Location.Radius.Placeholder': '请选择半径',
    'Location.Note': '详细信息',
    'Location.Note.Placeholder': '请输入详细信息',
    'Location.Note.Empty': '不存在详细信息',

    'Member.Title.Info': '基本信息',
    'Member.Title.Business': '公司信息',
    'Member.Name': '姓名',
    'Member.Email': '用户 ID',
    'Member.MobileNumber': '联系方式',
    'Member.Role': '类别',
    'Member.ActivationStatus': '状态',
    'Member.JoinDate': '注册时间',
    'Member.BirthDay': '出生年月日',
    'Member.Business.Name': '公司',
    'Member.TeamName': '所在小组',
    'Member.EmployeeRegNum': '工号',
    'Member.OfficePhoneNumber': '办公室',
    'Member.DenyReason': '拒绝理由',
    'Member.Filter.Email': '用户 ID',
    'Member.Filter.Name': '姓名',
    'Member.Filter.MobileNumber': '联系方式',
    'Member.Filter.Business': '公司名',
    'Member.Modal.Title.Deny': '拒绝理由',
    'Member.Denyreason.Placeholder': '请输入拒绝理由',
    'Member.View.Fail': '无法查询管理员。',
    'Member.Approved.Success': '已同意。',
    'Member.Approve.Fail': '同意失败。',
    'Member.Denied.Success': '拒绝请求。',
    'Member.Deny.Fail': '拒绝失败。',
    'Member.Help.Approve.Ready': '※同意请求未完成。',

    'Customer.Title.Info': '基本信息',
    'Customer.Title.Business': '员工信息',
    'Customer.Name': '会员名',
    'Customer.Email': '邮箱',
    'Customer.MobileNumber': '联系方式',
    'Customer.JoinDate': '注册时间',
    'Customer.ActivationStatus': '状态',
    'Customer.BirthDay': '出生年月日',
    'Customer.Address': '地址',
    'Customer.DriverLicense': '驾驶证',
    'Customer.CreditCard': '卡号',
    'Customer.Business.Name': '法人名称',
    'Customer.TeamName': '所在小组',
    'Customer.EmployeeRegNum': '工号',
    'Customer.DenyReason': '拒绝理由',
    'Customer.Filter.Email': '用户 ID',
    'Customer.Filter.Name': '姓名',
    'Customer.Filter.MobileNumber': '联系方式',
    'Customer.Filter.Business': '公司名',
    'Customer.Filter.Team': '所在小组',
    'Customer.Modal.Title.Deny': '拒绝理由',
    'Customer.Denyreason.Placeholder': '请输入拒绝理由',
    'Customer.View.Fail': '无法查询会员。',
    'Customer.Approved.Success': '已同意。',
    'Customer.Approve.Fail': '同意失败。',
    'Customer.Denied.Success': '拒绝请求。',
    'Customer.Deny.Fail': '拒绝失败。',

    'Business.Title.Type': '公司分类',
    'Business.Title.Business': '公司信息',
    'Business.Title.Manager': '主管理员',
    'Business.BusinessCode': '代码',
    'Business.BusinessRegNum': '营业证号码',
    'Business.Name': '法人名称',
    'Business.MobileNumber': '热线电话',
    'Business.Address': '地址',
    'Business.KoroadClientID': '驾驶证',
    'Business.PGMemberID': 'PG Member ID',
    'Business.Manager.Name': '姓名',
    'Business.Manager.MobileNumber': '联系方式',
    'Business.Manager.Email': '邮箱',
    'Business.Type.Private': '法人',
    'Business.Type.Public': '普通',
    'Business.Modal.Address.Search': '查找公司地址',
    'Business.BusinessRegNum.Placeholder': '请输入营业证号',
    'Business.Name.Placeholder': '请输入法人名',

    'Business.Address.Address.Placeholder': '请搜索邮编',
    'Business.Address.DetailedAddress.Placeholder': '请输入详细地址',
    'Business.KoroadClientID.Placeholder': 'CLIENT ID',
    'Business.KoroadSecret.Placeholder': 'CLIENT SECRET',
    'Business.KoroadPIN.Placeholder': 'PIN',
    'Business.KoroadPWD.Placeholder': '密码',
    'Business.PGMemberID.Placeholder': '请输入 ID',
    'Business.Manager.Name.Placeholder': '请输入姓名',

    'Business.Manager.Email.Placeholder': '请输入邮箱地址',
    'Business.View.Fail': '无法查询公司。',
    'Business.BusinessRegNum.Help': '请输入营业证号',
    'Business.MobileNumber.Help': '请准确输入热线电话',
    'Business.Manager.MobileNumber.Help': '请准确输入联系电话',

    'Alert.Image.SizeLimitExceeded': '图片上传大小为 500kb',

    'Vehicle.Status.Wait': '等待',
    'Vehicle.Status.InUse': '正在使用',
    'Vehicle.Status.Incident': '异常',
    'Vehicle.Status.LowVoltage': '低电压',
    'Vehicle.Status.NotReturned': '未归还',
    'Vehicle.Status.Unknown': '未知',

    'Brand.Type.Hyundai': '现代汽车',
    'Brand.Type.Kia': '起亚汽车',

    'Fuel.Type.Gasoline': '汽油',
    'Fuel.Type.Diesel': '柴油',
    'Fuel.Type.Ev': '电动',
    'Fuel.Type.Hybrid': '混合动力',

    'Grade.Type.City': '轻型',
    'Grade.Type.SubCompact': '小型',
    'Grade.Type.Compact': '准重型',
    'Grade.Type.Mid': '重型',
    'Grade.Type.Large': '大型',
    'Grade.Type.SUV': 'SUV',
    'Grade.Type.VAN': '客车',
    'Grade.Type.Electric': '电动',
    'Grade.Type.Oversea': '进口',

    'Accident.Type.Washing': '洗车',
    'Accident.Type.Maintenance': '维修',
    'Accident.Type.Repair': '维护',
    'Accident.Type.Etc': '其他',

    'Terminal.StatusType.Normal': '使用（显示连接状态）',
    'Terminal.StatusType.Outoforder': '故障维修',
    'Terminal.StatusType.Discard': '报废',
    'Terminal.StatusType.Unknown': '未知',

    'Terminal.State.Type.Unknown': '未知',
    'Terminal.State.Type.Connect': '车辆安装',
    'Terminal.State.Type.Conflict': '不一致',
    'Terminal.State.Type.Wait': '等待',

    'Terms.StatusType.Valid': '有效',
    'Terms.StatusType.Invalid': '无效',

    'Notice.StatusType.Draft': 'DRAFT',
    'Notice.StatusType.Published': 'PUBLISHED',
    'Notice.StatusType.Notified': 'NOTIFIED',

    'Member.ActivationStatusType.Pending': '等待',
    'Member.ActivationStatusType.Normal': '同意',
    'Member.ActivationStatusType.Deny': '拒绝',

    'Member.RoleType.System.Admin': '管理员',
    'Member.RoleType.System.Operator': '运营人',
    'Member.RoleType.Public.Admin': '管理员',
    'Member.RoleType.Public.Operator': '运营人',
    'Member.RoleType.Private.Admin': '管理员',
    'Member.RoleType.Private.Operator': '运营人',

    'Member.RoleGroupType.Admin': '管理员',
    'Member.RoleGroupType.Operator': '运营人',

    'User.StatusType.Pending': '等待',
    'User.StatusType.Normal': '同意',
    'User.StatusType.Reject': '拒绝',
    'User.StatusType.Deny': '拒绝',

    'User.LevelType.Public': '普通会员',
    'User.LevelType.Private': '法人会员',

    'Customer.BizStatusType.Requested': '等待',
    'Customer.BizStatusType.Inactive': '同意',
    'Customer.BizStatusType.Deny': '拒绝',

    'Reservation.Status.Unknown': '未知',
    'Reservation.Status.Requested': '等待同意',
    'Reservation.Status.Approve': '预约完成',
    'Reservation.Status.Confirm': '同意完成',
    'Reservation.Status.Denied': '拒绝预约',
    'Reservation.Status.Canceled': '取消预约',

    'Reservation.Status.InUse': '正在使用',
    'Reservation.Status.Returned': '归还完成',

    'Reservation.ConfirmType.Requested': '等待取车',
    'Reservation.ConfirmType.Confirmed': '同意取车',
    'Reservation.ConfirmType.Denied': '拒绝取车',

    'Vehicle.ControlType.Open': '开门（解锁）',
    'Vehicle.ControlType.Close': '关门（上锁）',
    'Vehicle.ControlType.Horn': '喇叭',
    'Vehcile.ControlType.Light': '危险指示灯',

    'Vehicle.Colors.White': '白',
    'Vehicle.Colors.LightGrey': '银子',
    'Vehicle.Colors.Grey': '灰色的',
    'Vehicle.Colors.Black': '黑色的',
    'Vehicle.Colors.Red': '红色的',
    'Vehicle.Colors.Blue': '蓝色的',

    'Vehicle.Transmission.Auto': '自动',
    'Vehicle.Transmission.Manual': '手动',

    'Vehicle.Options.Nosmoking': '禁止吸烟',
    'Vehicle.Options.Dashcam': '行车记录仪',
    'Vehicle.Options.Rearview': '后方摄像头',
    'Vehicle.Options.Navigation': '导航',

    'Vehicle.InsuranceAge.21': '21 岁以上',
    'Vehicle.InsuranceAge.26': '26 岁以上',
    'Vehicle.InsuranceDriverAge.21': '21 岁以上驾驶员保险',
    'Vehicle.InsuranceDriverAge.26': '26 岁以上驾驶员保险',

    // LICENSE_LOCATION_TYPE
    'License.Location.Seoul': '汉城',
    'License.Location.Busan': '釜山',
    'License.Location.Gyeonggi': '京畿道（北）',
    'License.Location.Kangwon': '江原道',
    'License.Location.Chungbuk': '忠清北道',
    'License.Location.Chungnam': '忠清南道',
    'License.Location.Jeonbuk': '全羅北道',
    'License.Location.Jeonnam': '全羅南道',
    'License.Location.Gyeongbuk': '慶尚北道',
    'License.Location.Gyeongnam': '慶尚南道',
    'License.Location.Jeju': '済州島',
    'License.Location.Daegu': '大邱',
    'License.Location.Incheon': '仁川',
    'License.Location.Kwangju': '光州',
    'License.Location.Daejeon': '大田',
    'License.Location.Ulsan': '蔚山',
    'License.Location.NorthGyeonggi': '京畿道（南）',

    // LICENSE_TYPE
    'License.Type.Large': '1種大型',
    'License.Type.Normal': '1種普通',
    'License.Type.Small': '1種小型',
    'License.Type.Trailer': '拖车',
    'License.Type.Wrecker': '救援车',
    'License.Type.SmallTrailer': '小型牵引车',
    'License.Type.SecondNormal': '2種普通',
    'License.Type.SecondSmall': '2種小型',
    'License.Type.MotorizedBicycle': '2種原动机',

    'PenaltyAndTollPage.Objection': '异议申请',
    'PenaltyAndTollPage.LicensePlateNumber': '车牌号',
    'PenaltyAndTollPage.Violation': '违章日期',
    'PenaltyAndTollPage.UserName': '客户名',
    'PenaltyAndTollPage.MobileNumber': '联系方式',
    'PenaltyAndTollPage.LicenseType': '驾照号',
    'PenaltyAndTollPage.LicnConCode': '驾照号',

    'PenaltyAndTollPage.ExpirationOfLicence': '驾驶证有效期',
    'PenaltyAndTollPage.RegistrationDate.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.AppealTime.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.Violation.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.ExpirationOfLicence.Placeholder': '0000.00.00  00:00',
    'PenaltyAndTollPage.LicensePlateNumber.Placeholder': '输入车牌号',
    'PenaltyAndTollPage.UserName.Placeholder': '输入客户名',
    'PenaltyAndTollPage.MobileNumber.Placeholder': '输入联系号码',

    'PenaltyAndTollPage.ZipCode': '地址',
    'PenaltyAndTollPage.DetailedAddress': '详细地址 ',
    'PenaltyAndTollPage.LicenceNumberSecond.Placeholder': '输入驾驶证号',
    'PenaltyAndTollPage.RentDivision': '租赁分类',
    'PenaltyAndTollPage.RentDivision.Placeholder': '普通',
    'PenaltyAndTollPage.RentDuration': '租赁时间',
    'PenaltyAndTollPage.RentDuration.Placeholder': '0000.00.00',
    'PenaltyAndTollPage.BusinessName': '法人名称',
    'PenaltyAndTollPage.BusinessName.Placeholder': '输入法人名',
    'PenaltyAndTollPage.NumberOfBusiness': '营业证号码',
    'PenaltyAndTollPage.NumberOfBusiness.Placeholder': '输入营业证号',
    'PenaltyAndTollPage.Category': '违章类型',
    'PenaltyAndTollPage.Category.Placeholder': '全部',
    'PenaltyAndTollPage.PenaltyFee.Placeholder': '输入金额',
    'PenaltyAndTollPage.PenaltyFee': '罚金',
    'PenaltyAndTollPage.Location.Placeholder': '输入违章地点',
    'PenaltyAndTollPage.Organization.Placeholder': '输入发放处',
    'PenaltyAndTollPage.Desc.Placeholder': '输入内容',
    'PenaltyAndTollPage.Desc': '详细内容',
    'PenaltyAndTollPage.ProcessObjection.Placeholder': '是',
    'PenaltyAndTollPage.ProcessObjection': '处理异议',
    'PenaltyAndTollPage.Appeal': '处理异议',
    'PenaltyAndTollPage.Appeal.Placeholder': '是',
    'PenaltyAndTollPage.Remark.Placeholder': '输入内容',
    'PenaltyAndTollPage.Remark': '内容',
    'PenaltyAndTollPage.ViolationPlace.Placeholder': '输入违章地点',
    'PenaltyAndTollPage.ViolationPlace': '违章地点',
    'PenaltyAndTollPage.Location': '违章地点',
    'PenaltyAndTollPage.Organization': '发放处',
    'PenaltyAndTollPage.ProcessingDate': '处理日期',
    'PenaltyAndTollPage.ConnectRentInformation': '关联租赁信息',
    'PenaltyAndTollPage.Check.RentInformation': '请输入车牌号和违章日期。',
    'PenaltyAndTollPage.Fail.ConnectRentInformation': '不存在租赁信息。',
    'PenaltyAndTollPage.Fail.Create': '滞纳金/通行费登记失败。',
    'PenaltyAndTollPage.Success.Create': '已登记滞纳金/通行费。',
    'PenaltyAndTollPage.Success.Delete': '已删除滞纳金/通行费。',
    'PenaltyAndTollPage.Fail.Delete': '滞纳金/通行费删除失败。',
    'PenaltyAndTollPage.Fail.ViolationList': '滞纳金/通行费目录查询失败。',
    'PenaltyAndTollPage.Fail.Detail': '滞纳金/通行费详细查询失败。',
    'PenaltyAndTollPage.Fail.Download': '打印失败。',
    'PenaltyAndTollPage.Success.Update': '已更改滞纳金/通行费。',
    'PenaltyAndTollPage.Fail.Update': '滞纳金/通行费更改失败。',
    'PenaltyAndToll.NumberOfPenalty': '滞纳金编号',
    'PenaltyAndToll.ViolationCategory': '违章类型',
    'PenaltyAndToll.Fee': '费用',
    'PenaltyAndTollPage.RegistrationDate': '违章日期',
    'PenaltyAndToll.ViolationDate': '违章日期',
    'PenaltyAndToll.Vehicle': '车辆',
    'PenaltyAndToll.UserName': '客户名',
    'PenaltyAndToll.MobileNumber': '联系方式',
    'PenaltyAndToll.Business': '公司',
    'PenaltyAndToll.NumberOfPenalty.Placeholder': '输入滞纳编号',
    'PenaltyAndToll.Download.PdfName': '异议申请.pdf',
    'PenaltyAndTollPage.Type.Park': '停车编号',
    'PenaltyAndTollPage.Type.Sign': '闯红灯',
    'PenaltyAndTollPage.Type.Speed': '超速',
    'PenaltyAndTollPage.Yes': '是',
    'PenaltyAndTollPage.No': '不是',
    'PenaltyAndTollPage.Required': '输入所有项。',
    'AppealDetail.DocID': '文件号',
    'AppealDetail.AppealTime': '实行日期',
    'AppealDetail.Organization': '接收',
    'AppealDetail.Temp': '参阅',
    'AppealDetail.Remark': '内容',
    'AppealDetail.LicensePlateNumber': '违章车牌号',
    'AppealDetail.UserName': '客户名',
    'AppealDetail.LicnCon': '驾照号',
    'AppealDetail.ViolationTime': '违章日期',
    'AppealDetail.ReservationTime': '租赁时间',
    'AppealDetail.Representative': '代表理事',
    'AppealDetail.Paragraph': '对汽车租赁期内发生的 ',
    'AppealDetail.Paragraph2': '提出上述异议申请，研究后移交驾驶员。',
    'MyPage.JoinDate': '注册时间',
    'MyPage.Name': '客户名',
    'MyPage.Email': '用户 ID',
    'MyPage.MobileNumber': '联系方式',
    'MyPage.BirthDate': '出生年月日',
    'MyPage.ApproveMessage': '拒绝理由',
    'MyPage.Info': '基本信息',
    'MyPage.BusinessInfo': '法人信息',
    'MyPage.Business.BusinessCode': '公司代码',
    'MyPage.Business.Name': '法人名称',
    'MyPage.Business.Required': '请输入正确的公司代码。',
    'MyPage.EmployeeRegNum': '工号',
    'MyPage.TeamName': '所在小组',
    'MyPage.CompanyEmail': '邮箱',
    'MyPage.ActivationStatus': '状态',
    'MyPage.PasswordChange': '修改密码',
    'MyPage.OldPassword': '现有密码',
    'MyPage.NewPassword': '新密码',
    'MyPage.CheckNewPassword': '确认新密码',
    'MyPage.PasswordInfo':
        '密码包含8~20个字符的英文大/小写、数字、特殊文字中的三种',
    'MyPage.Change': '修改',
    'MyPage.UserDelete': '会员注销',
    'MyPage.UserType': '类别',
    'MyPage.Update.Fail': '信息更改失败。',
    'MyPage.Update.Success': '更改信息。',
    'MyPage.UpdatePwdCheck.Fail': '现有密码错误。',
    'MyPage.UpdatePwd.Fail': '密码修改失败。',
    'MyPage.UpdatePwd.Success': '密码已修改。',

    // 번역의뢰 WEB 0330    - zh.js
    'Common.Select.Required': '必选值',
    'Common.Btn.Save': '保存',
    'Common.Btn.NewCreate': '新的登记',
    'Common.Btn.CreateComplete': '登记完成',
    'Common.Btn.EditComplete': '更改完成',
    'Common.Btn.PrivateDeny': '注销法人',
    'Common.Btn.CarLog': '运行日志',
    'Common.Confirm.Pay': '确定要支付吗？',
    'Common.Unit.Short.Hour': '小时',
    'Common.Unit.Days': '天',
    'Common.Unit.Count': '次',
    'Common.Unit.Month': '个月',
    'Main.SubTitle': '从预约到销售额结算，更轻松便捷',
    // 'Menu.SalesAndCalculation': '销额/结算管理',
    // 'Menu.SalesManager': '销额/结算管理',
    // 'Menu.LocationManager': '网点管理',
    // 'Menu.UserManager': '会员管理',
    // 'Menu.BusinessManager': '法人公司管理',
    // 'Menu.BusinessCustomrList': '法人用户目录',
    // 'Menu.BusinessCustomerEditList': '编辑法人用户目录',
    // 'Menu.User': '用户管理',
    'Title.VehicleInfo': '查询车辆',
    'Title.VehicleCreate': '车辆登记',
    'Title.ReservationTestCarCreate': '预约试驾车',
    'Title.TestCarConfigList': '设置试驾车限制',
    'Title.License': '商品管理',
    'Title.Code': '代码管理',
    'Title.Menu': '菜单管理',
    'Title.Role': '职责操作管理',
    'Title.Program': '程序管理',
    'Title.Message': '信息管理',
    'Title.AccidentCreate': '事故/维修登记',
    // 'Title.TestCarConfigList': '限制设置',
    'Menu.Holiday': '试驾车停运日管理',
    'Reservation.Btn.Download': 'CSV下载',
    // 'Reservation.Status.InUse': '不是正在使用的车辆，无法归还。',
    'Reservation.VehicleType': '车辆分类',
    // 'Reservation.Insurance.Invalid':  //   '车辆保险已过期，无法进行预约。',
    //   '车辆保险已过期，无法更改预约。',
    'Reservation.StartOdometer': '起始里程(km)',
    'Reservation.EndOdometer': '结束里程(km)',
    'Reservation.TotalOdometer': '总里程(km)',
    'Reservation.BusinessType': '选择公司',
    // 'Reservation.AdditionalInsruanceAge.ConfirmFail':    '无法用共用驾驶员的年龄预约所选车辆。',
    'Reservation.Approve.Success': '合并同意 {total}中 /正常同意{success}。',
    'Reservation.Deny.Success': '合并拒绝 {total}中 /正常拒绝{success}。',
    'Reservation.Pay.Success': '合并支付 {total}中 /正常支付{success}。',
    // 'Reservation.LicenseExpiryDate.AddTimeIsInvalid':    '租赁起始日超过了共用驾驶员的驾照更新有效期',
    'Reservation.Business.Name': '法人名称',
    'Reservation.User.Team': '所在小组',
    'Reservation.User.Name': '预约人姓名',
    'Reservation.Use.ImgList': '使用前/后车辆照片',
    'Reservation.Use.ImgCheck': '确认使用前/后照片',
    'Reservation.Count.AllVehicle': '全部运营车辆',
    'Reservation.Count.AvailableVehicle': '可预约车辆',
    'Reservation.Count.CompletedVehicle': '已预约车辆',
    'Reservation.Count.InUseVehicle': '当前使用车辆',
    'Reservation.Count.WaitVehicle': '待同意车辆',
    'Rerservation.Btn.Pay': '支付',
    // 'ReservationCalendar.Private.Group.Confirmed.Item':    '{licensePlateNumber}{br}预约人 {user}',
    // 'Vehicle.Used': '使用状态',
    // 'Vehicle.Used.Yes': '使用',
    // 'Vehicle.Used.No': '未使用',
    'Vehicle.LicensePlateNumber.Placeholder': 'ex) 00下 0000。',
    'Vehicle.ControllerID.Placeholder': 'ex) 01012345678',
    // 'Vehicle.Confirm.Deletes':    '确认要删除勾选项吗？{br}无法删除部分正在使用的车辆。',
    'Vehicle.Type.Normal': '普通车辆',
    'Vehicle.Type.TestCar': '试驾车辆',
    'Vehicle.VehicleType': '车辆分类',
    // 'VehicleModel.Confirm.Deletes':    '确定要删除{count}型号吗？{br}无法删除已登记车辆的型号。',
    // 'Charge.WarningMsg':    '已含有其他费用套餐的车辆。{br}不想更改费用套餐可取消勾选。',
    // 'Charge.Confirm.Deletes':    '确认要删除勾选项吗？{br}以此费用套餐预定的车辆适用删除前的费用。',
    // 'Insurance.WarningMsg':    '已含有其他保险费的车辆。{br}不想更改保险费可取消勾选。',
    // 'Insurance.Confirm.Deletes':    '确认要删除勾选项吗？{br}以此保险费预定的车辆适用删除前的费用。',
    'BusinessMember.Title': '员工注册信息',
    'Member.Branch.Name': '门店',
    // 'Business.TestCar': '试驾车辆',
    'Accident.Type.Acc1': '客户肇事事故',
    'Accident.Type.Acc2': '客户受害事故',
    'Terminal.Telecom.Type.SKTMVNO': 'SKT虚拟运营商手机',
    'Terminal.Telecom.Type.KTMVNO': 'KT虚拟运营商手机',
    'Terminal.Telecom.Type.LGUMVNO': 'LGU+虚拟运营商手机',
    'Reservation.Status.Return': '归还',
    'Vehicle.Colors.Green': '绿色',
    'Vehicle.Colors.Yellow': '黄色',
    'PenaltyAndTollPage.LicenseType.3': '仁川',
    'PenaltyAndTollPage.LicenseType.12': '汉城',
    'PenaltyAndTollPage.LicenseType.Placeholder': '汉城',
    'PenaltyAndTollPage.LicnConCode.Placeholder': '汉城',
    // 'MyPage.PasswordInfo':    '密码包含8~20个字符的英文大/小写、数字、特殊文字中的三种以上',
    'TestCarConfig.Business': '选择公司',
    'TestCarConfig.ReservationTime': '预约期限（最长预约天数）',
    'TestCarConfig.MaxMonth': '试驾时间（最大月数）',
    'TestCarConfig.ReservationCount': '预约次数（季度）',
    'TestCarConfig.TokURL': '试驾体验URL',
    'TestCarConfig.Info': '设置试驾车限制',
    'TestCarConfig.ReservationHour': '预约起始/结束时间',
    'TestCarConfig.ReservationInfo': '预约说明',
    'TestCarConfig.Toast.getTestCarConfig.Fail': '限制设置目录查询失败。',
    'TestCarConfig.Toast.createTestCarConfig.Fail': '限制设置登记失败。',
    'TestCarConfig.Toast.updateTestCarConfig.Success': '限制设置已更改。',
    'TestCarConfig.Toast.updateTestCarConfig.Fail': '限制设置更改失败。',
    'Program.Name': '程序名称',
    // 'Program.Method': '功能',
    'Program.Action': '功能',
    'Program.Confirm.MultiDelete': '确定要删除{count}个程序吗？',
    'Program.View.Fail': '无法查询程序信息。',
    'Program.Delete.Success': '程序已删除。',
    'Program.Delete.Fail': '程序删除失败。',
    'Program.Create.Success': '程序已登记。',
    'Program.Create.Fail': '程序登记失败。',
    'Program.Action.Inquiry': '读取',
    'Program.Action.Save': '填写',
    'Program.Action.Delete': '删除',
    'Program.Action.Download': '下载',
    'Program.Name.Placeholder': '请输入程序名称。',
    'Program.Apiurl.Placeholder': '请输入API URL。',
    'Message.ResourceKey': '密钥',
    'Message.Message': '信息',
    'Message.Confirm.MultiDelete': '确定要删除{count}个信息吗？',
    'Message.View.Fail': '无法查询信息。',
    'Message.Delete.Success': '信息已删除。',
    'Message.Delete.Fail': '信息删除失败。',
    'Message.Create.Success': '信息已登记。',
    'Message.Create.Fail': '信息登记失败。',
    'Message.Update.Success': '信息已更改。',
    'Message.Update.Fail': '信息更改失败。',
    'Message.Key.Placeholder': '请输入密钥。',
    'Message.Message.Placeholder': '请输入信息。',
    'Message.Confirm.Delete': '确定要删除信息吗？',
    'Code.RootName': '代码管理',
    'Code.SelectCode': '请选择代码',
    'Code.Type': '类别',
    'Code.Type.Group': '代码组',
    'Code.Type.Value': '代码项',
    'Code.Name': '代码名称',
    'Code.GroupKey': '组密钥',
    'Code.CodeValue': '代码值',
    'Code.OrderNum': '优先级',
    'Code.Use': '显示与否',
    'Code.Use.false': '隐藏',
    'Code.Use.true': '显示',
    'Code.Name.Placeholder': '请输入代码名称。',
    'Code.MsgKey.Placeholder': '请输入代码Message key。',
    'Code.GroupKey.Placeholder': '请输入组密钥值。',
    'Code.CodeValue.Placeholder': '请输入代码值。',
    'Code.OrderNum.Placeholder': '请输入优先级值。',
    'Code.View.Fail': '无法查询代码信息。',
    'Code.Confirm.Delete': '确定要删除代码吗？',
    'Code.Create.Success': '代码已登记。',
    'Code.Create.Fail': '代码登记失败。',
    'Code.Update.Success': '代码已更改。',
    'Code.Update.Fail': '代码更改失败。',
    'Code.Delete.Success': '代码已删除。',
    'Code.Delete.Fail': '代码删除失败。',
    'Menu.SelectCode': '请选择菜单',
    'Menu.Name': '菜单名',
    'Menu.MenuUrl': '菜单路径',
    'Menu.OrderNum': '优先级',
    'Menu.Use': '显示与否',
    'Menu.Use.false': '隐藏',
    'Menu.Use.true': '显示',
    'Menu.Acl': '有权与否',
    'Menu.Acl.true': '使用',
    'Menu.Acl.false': '非使用',
    'Menu.Confirm.Delete': '确定要删除菜单吗？',
    'Menu.Menu.Placeholder': '请输入菜单名。',
    'Menu.Path.Placeholder': '请输入菜单路径。',
    'Menu.Order.Placeholder': '请输入优先级。',
    'Menu.View.Fail': '无法查询菜单信息。',
    'Menu.Delete.Success': '菜单已删除。',
    'Menu.Delete.Fail': '菜单删除失败。',
    'Menu.Create.Success': '菜单已登记。',
    'Menu.Create.Fail': '菜单登记失败。',
    'Menu.Update.Success': '菜单已更改。',
    'Menu.Update.Fail': '菜单更改失败。',
    'Menu.Program.View.Fail': '无法查询程序信息。',
    'Menu.Program.Delete.Success': '程序已删除。',
    'Menu.Program.Delete.Fail': '程序删除失败。',
    'Menu.Program.Create.Success': '程序已登记。',
    'Menu.Program.Create.Fail': '程序登记失败。',
    'Role.Select.Placeholder': '请选择职责。',
    'Role.Name': '职责名称',
    'Role.RoleKey': '职责密钥',
    'Role.Note': '职责说明',
    'Role.Name.Placeholder': '请输入职责名称',
    'Role.RoleKey.Placeholder': '请输入职责钥匙',
    'Role.Note.Placeholder': '请输入职责说明',
    'Role.Acl.Title': '操作权限',
    'Role.Admin.Title': '管理员',
    'Role.View.Fail': '无法查询职责信息。',
    'Role.Delete.Success': '职责已删除。',
    'Role.Delete.Fail': '职责删除失败。',
    'Role.Create.Success': '职责已登记。',
    'Role.Create.Fail': '职责密钥重复，无法使用。',
    'Role.Update.Success': '职责已更改。',
    'Role.Update.Fail': '职责密钥重复，无法使用。',
    'Role.Acl.View.Fail': '无法查询操作权限信息。',
    'Role.Acl.Update.Success': '操作权限已更改。',
    'Role.Acl.Update.Fail': '操作权限更改失败。',
    'Role.Admin.View.Fail': '无法查询管理员信息。',
    'Role.Confirm.Delete': '确定要删除职责吗？',
    'Role.Menu': '菜单名',
    'Role.Read': '读取',
    'Role.Write': '填写',
    'Role.Delete': '删除',
    'Role.Download': '下载',
    'Role.Admin.Name': '姓名',
    'Role.Admin.UserID': '用户 ID',
    'Role.Admin.MobileNumber': '联系方式',
    'License.Confirm.MultiDelete': '确定要删除{count}个商品管理吗？',
    'License.View.Fail': '无法查询商品管理信息。',
    'License.Confirm.Delete': '确定要删除商品管理吗？',
    'License.Delete.Success': '商品管理已删除。',
    'License.Delete.Fail': '商品管理删除失败。',
    'License.Create.Success': '商品管理已登记。',
    'License.Create.Fail': '商品管理登记失败。',
    'License.Update.Success': '商品管理已更改。',
    'License.Update.Fail': '商品管理更改失败。',
    'License.View.Menu.Fail': '无法查询商品管理操作权限信息。',
    'License.Update.Menu.Fail': '商品管理操作权限更改失败。',
    'License.View.Business.Fail': '无法查询商品管理法人信息。',
    'License.Name': '商品名称',
    'License.Use': '使用与否',
    'License.Note': '说明',
    'License.BusienssCnt': '注册数',
    'License.UsageFee': '使用费/月',
    'License.Use.false': '非使用',
    'License.Use.true': '使用',
    'License.Menu.Title': '操作权限',
    'License.Business.Title': '公司',
    'License.Business.Name': '公司名',
    'License.Business.Date': '商品注册日期',
    'License.Name.Placeholder': '请输入商品名称。',
    'License.UseFee.Placeholder': '请输入试用费。',
    'License.Note.Placeholder': '请输入说明。',
    'CompanyUser.CompanyId': '客户名',
    'CompanyUser.ManagerName': '管理员姓名',
    'CompanyUser.PhoneNumber': '联系方式',
    'CompanyUser.FaxNumber': '传真号',
    'CompanyUser.ManagerMobileNumber': '管理员联系方式',
    'CompanyUser.ManagerEmail': '管理员邮箱',
    'CompanyUser.Get.Fail': '无法查询客户管理员信息。',
    'Title.CompanyUser': '客户管理员',
    'CompanyUser.Title.Create': '登记客户管理员',
    'CompanyUser.CompanyID.Placeholder': '请输入客户ID。',
    'CompanyUser.ManagerName.Placeholder': '请输入客户管理员姓名。',
    'CompanyUser.PhoneNumber.Placeholder': '请输入客户管理员联系方式。',
    'CompanyUser.FaxNumber.Placeholder': '请输入客户管理员传真号。',
    'CompanyUser.ManagerMobileNumber.Placeholder': '请输入客户管理员联系方式。',
    'CompanyUser.ManagerEmail.Placeholder': '请输入客户管理员邮箱。',
    'CompanyUser.Filter.CompanyID': '客户ID',
    'CompanyUser.Filter.ManagerName': '管理员姓名',
    'CompanyUser.Filter.ManagerMobileNumber': '管理员联系方式',
    'Company.Name': '客户名',
    'Company.CompanyBusiness': '经营范围',
    'Company.CompanyType': '客户分类',
    'Company.PhoneNumber': '联系方式',
    'Company.FaxNumber': 'FAX号',
    'Company.Address': '地址',
    'Company.BusinessRegNum': '营业证号',
    'Company.CeoName': '姓名',
    'Company.Homepage': '网页',
    'Company.RepBusinessType': '主经营项目',
    'Company.BusinessType': '经营项目',
    // 'Company.BusinessId': '法人ID',
    // 'Company.BranchId': '门店ID',
    'Company.BusinessId': '法人名称',
    'Company.BranchId': '门店',
    'COMPANY.Type.Mechanic': '维修厂',
    'COMPANY.Type.Insurance': '保险公司',
    'Company.Title.Create': '登记客户',
    'Title.Company': '客户',
    'Company.Name.Placeholder': '请输入客户名。',
    'Company.CompanyType.Placeholder': '请选择客户分类。',
    'Company.PhoneNumber.Placeholder': 'ex) 0101234567',
    'Company.FaxNumber.Placeholder': 'ex) 0101234567',
    'Company.BusinessRegNum.Placeholder': '请输入营业证号。',
    'Company.CeoName.Placeholder': '请输入主管理员姓名。',
    'Company.Homepage.Placeholder': '请输入网页。',
    'Company.CompanyBusiness.Placeholder': '请输入经营范围。',
    'Company.RepBusinessType.Placeholder': '请输入主经营项目。',
    'Company.BusinessType.Placeholder': '请输入经营项目。',
    // 'Company.BusinessId.Placeholder': '请输入法人ID。',
    // 'Company.BranchId.Placeholder': '请输入门店ID。',
    'Company.BusinessId.Placeholder': '请选择法人。',
    'Company.BranchId.Placeholder': '请选择门店。',
    'Company.Address.Address.Placeholder': '请搜索邮编',
    'Company.Address.DetailedAddress.Placeholder': '请输入详细地址',
    'TestCarConfig.Toast.createTestCarConfig.Success': '限制设置已登记。',
    'TestCarConfig.Toast.ReservationHour.Fail':
        '预约起始时间等于或大于结束时间。',
    'Holiday.Create.Info': '登记休息日',
    'Holiday.HolidayName': '休息日名称',
    'Holiday.Date': '日期',
    'Holiday.Toast.getHolidayList.Fail': '休息日目录查询失败。',
    'Holiday.Toast.getHolidayDetail.Fail': '休息日详情查询失败。',
    'Holiday.Toast.createHoliday.Success': '休息日已登记。',
    'Holiday.Toast.createHoliday.Fail': '休息日登记失败。',
    'Holiday.Toast.updateHoliday.Success': '休息日已更改。',
    'Holiday.Toast.updateHoliday.Fail': '休息日更改失败。',
    'Holiday.Toast.deleteHoliday.Success': '休息日已删除。',
    'Holiday.Toast.deleteHoliday.Fail': '休息日删除失败。',
    'Menu.Accident': '事故/维修信息',
    // 'Title.AccidentCreate': '事故/维修登记',
    'Title.AccidentDetail': '事故/维修信息',
    'Accident.Btn.Create': '事故登记',
    'Accident.Date': '事故日期',
    'Accident.Location': '门店',
    'Accident.Vehicle.Information': '车辆信息',
    'Accident.Vehicle.LicensePlateNum': '车牌号',
    'Accident.RepairType': '事故分类',
    'Accident.Status': '进行情况',
    'Accident.Id': '受理编号',
    'Accident.Company': '客户',
    'Accident.Status.Occur': '发生事故',
    'Accident.Status.Requested': '维修申请',
    'Accident.Status.Repairing': '维修中',
    'Accident.Status.Repaired': '维修结束',
    'Accident.Status.Unknown': '未知',
    'Accident.Filter.LicensePlateNumber': '车牌号',
    'Accident.Filter.RepairType': '事故分类',
    'Accident.Filter.VehicleModel': '车型',
    'Accident.Filter.Location': '网点',
    'Accident.Filter.Id': '受理编号',
    'Accident.Filter.Company': '客户',
    'Accident.VehicleInfo': '车辆信息',
    'Accident.ReservationInfo': '租赁信息',
    'Accident.RepairInfo': '维修记录',
    'Accident.CostInfo': '费用处理',
    'Accident.InsuranceInfo': '对方保险公司',
    'Accident.Get.Fail': '无法查询事故/维修信息。',
    'Accident.Create.Succes': '事故登记成功。',
    'Accident.Create.Fail': '事故登记失败。',
    'AccidentVehicle.LicensePlateNum': '车牌号',
    'AccidentVehicle.Company': '客户',
    'AccidentVehicle.AccidentDate': '事故日期',
    'AccidentVehicle.Location': '网点',
    'AccidentVehicle.AccidentStatus': '进行情况',
    'AccidentVehicle.AccidentType': '事故分类',
    'AccidentVehicle.Search.Vehicle': '搜索车辆',
    'AccidentVehicle.Search.Company': '搜索客户',
    'AccidentVehicle.AccidentDate.DayPicker': '事故日期',
    'AccidentVehicle.Repair': '维修日期',
    'AccidentVehicle.Repair.Start': '维修起始日期',
    'AccidentVehicle.Repair.End': '维修结束日期',
    'AccidentReservation.ReservationCode': '租赁号',
    'AccidentReservation.ReservationDate': '租赁期限',
    'AccidentReservation.CustomerName': '客户名',
    'AccidentReservation.LicenseType': '驾驶证种类',
    'AccidentReservation.LicenseNumber': '驾照号',
    'AccidentReservation.Tel': '联系方式',
    'AccidentReservation.Search.ReservationInfo': '租赁信息联动',
    'AccidentRepair.RepairDetail': '维修记录',
    'AccidentRepair.RepairBefore': '更改前',
    'AccidentRepair.RepairAfter': '更改后',
    'Accident.RepairBefore.Placeholder': '请输入更改前事项。',
    'Accident.RepairAfter.Placeholder': '请输入更改后事项。',
    'AccidentInsurance.InsuranceCompany': '对方保险公司',
    'AccidentInsurance.InsuranceCode': '对方保险受理编号',
    'AccidentInsurance.InsuranceManager': '管理员',
    'AccidentInsurance.InsuranceManagerTel': '管理员联系电话',
    'AccidentCost.InsuranceTreatement': '保险处理',
    'AccidentCost.Company': '保险公司',
    'AccidentCost.InsuranceCode': '受理编号',
    'AccidentCost.OpponentInjury': '对人',
    'AccidentCost.OpponentVehicle': '对物',
    'AccidentCost.OwnVehicle': '个人车辆',
    'AccidentCost.OwnInjury': '车损',
    'AccidentCost.Other': '其他',
    'AccidentCost.TotalInsuranceCost': '保险总处理费',
    'AccidentCost.CompanyTreatment': '本公司处理',
    'AccidentCost.InsuranceCompanyCharge': '保险公司免责金',
    'AccidentCost.TotalCompanyCost': '本公司总处理费',
    'AccidentCost.AmountOfAccident': '收款金额',
    'AccidentCost.IndemnityCost': '免责金',
    'AccidentCost.CompensationFee': '停运补偿',
    'AccidentCost.Unrepaired': '未维修处理',
    'AccidentCost.TotalCollectCost': '总收款额',
    'AccidentCost.TotalCost': '总计',
    'AccidentCost.Status': '缴纳状态',
    'AccidentCost.PaymentMethod': '支付方式',
    'AccidentBusiness.BusinessType.Placeholder': '选择公司',
    'Accident.GetVehicle.Fail': '无法打开车辆目录。',
    'Accident.GetBusiness.Fail': '无法打开公司目录',
    'Accident.ReservationCheckParam.Fail': '请设置车牌号和事故时间。',
    'Accident.GetReservationCheck.Fail': '租赁信息联动失败。',
    'Accident.Fail.ConnectRentInformation': '不存在租赁信息。',
    'Accident.Delete.Success': '此事故记录已删除。',
    'Accident.Edit.Success': '此事故记录已更改。',
    'Accident.Create.Success': '此事故记录已登记。',
    'Accident.Delete.Fail': '此事故记录删除失败。',
    'Accident.Edit.Fail': '此事故记录更改失败。',
    'Accident.Confirm': '删除事故记录',
    'Accident.Confirm.Delete': '确定要删除此事故记录吗？',
    'Accident.Confirm.Edit': '确定要更改此事故记录吗？',
    'Accident.Confirm.Create': '确定要登记此事故记录吗？',
    'Menu.Branch': '门店',
    'Title.Branch': '门店',
    'Branch.Name': '门店名',
    'Branch.BusinessRegNum': '营业证号',
    'Branch.PhoneNumber': '联系方式',
    'Branch.FaxNumber': 'Fax号',
    'Branch.Address': '地址',
    'Branch.ManagerName': '姓名',
    'Branch.ManagerPhoneNumber': '联系方式',
    'Branch.ManagerEmail': '邮箱',
    'Branch.Title.Create': '登记门店',
    'Branch.Company': '门店',
    'Branch.BusinessID': '法人名称',
    'Branch.BusinessID.Placeholder': '请输入法人名称',
    'Branch.Name.Placeholder': '请输入门店名。',
    'Branch.BusinessRegNum.Placeholder': '请输入营业证号。',
    'Branch.PhoneNumber.Placeholder': '请输入联系方式。',
    'Branch.FaxNumber.Placeholder': '请输入Fax号。',
    'Branch.ManagerName.Placeholder': '请输入主管理员姓名。',
    'Branch.ManagerPhoneNumber.Placeholder': '请输入主管理员联系方式。',
    'Branch.ManagerEmail.Placeholder': '请输入主管理员邮箱。',
    'Branch.Company.Placeholder': '请输入门店。',
    'Branch.Address.Address.Placeholder': '请搜索邮编',
    'Branch.Address.DetailedAddress.Placeholder': '请输入详细地址',
    'Accident.PaymentStatus.Unpaid': '未支付',
    'Accident.PaymentStatus.Paid': '支付成功',
    'Accident.PaymentType.Card': '卡',
    'Accident.PaymentType.Cash': '现金',
    'Accident.GetBranch.Fail': '门店目录打开失败。',
    'Accident.GetCompany.Fail': '客户目录打开失败。',
    'Code.MsgKey': '代码密钥',
    'Menu.VehicleInsurance': '保险金管理',
    'VehicleInsurance.Get.Fail': '保险金管理项目查询失败。',
    'VehicleInsurance.LicensePlateNumber': '车牌号',
    'VehicleInsurance.InsureYear': '第  年',
    'VehicleInsurance.InsureCondition': '保险状态',
    'VehicleInsurance.VehicleGrade': '车辆分类',
    'VehicleInsurance.VehicleModelName': '车辆名称',
    'VehicleInsurance.CompanyName': '保险公司',
    'VehicleInsurance.StartDate': '保险起始日期',
    'VehicleInsurance.EndDate': '保险到期日',
    'VehicleInsurance.Filter.LicensePlateNumber': '车牌号',
    'VehicleInsurance.Filter.CompanyName': '保险公司',
    'Title.VehicleInsurance': '车辆保险金管理',
    'VehicleInsurance.VehicleInformation': '车辆信息',
    'VehicleInsurance.InsuranceInformation': '保险信息',
    'VehicleInsurance.insureSelfVehicle': '个人车辆投保',
    'VehicleInsurance.PaymentDueDay': '缴纳日',
    'VehicleInsurance.InsurePayment': '缴纳保险费',
    'VehicleInsurance.paymentMethod': '缴纳方式',
    'VehicleInsurance.insureSelfVehicle.true': '是',
    'VehicleInsurance.insureSelfVehicle.false': '不是',
    'VehicleInsurance.paymentMethod.true': '全部支付',
    'VehicleInsurance.paymentMethod.false': '分期',
    'VehicleInsurance.MonthPer': '分期期数',
    'VehicleInsurance.MonthPerPayment': '月付金额',
    'VehicleInsurance.VehiclePremium': '保险费缴纳信息',
    'VehicleInsurance.insureOrder': '次',
    'VehicleInsurance.paymentDate': '缴纳日期',
    'VehicleInsurance.monthPerPayment': '缴纳金额（月）',
    'VehicleInsurance.delete': '删除',
    'Menu.MsgKey': '菜单密钥',
    'Menu.MsgKey.Placeholder': '请输入菜单密钥',
    'Title.ShortRental': '短期租赁',
    'ShortRental.Type': '租赁分类',
    'ShortRental.Type.Public': '租车',
    'ShortRental.Type.Private': '法人',
    'ShortRental.Type.Rent': '登记短期租赁',
    'ShortRental.Type.Reservation': '预约短期租赁',
    'ShortRental.RentalInfo': '租赁及车辆信息',
    'ShortRental.Duration': '租赁时间',
    'ShortRental.Duration.Value': '{days}天 {hours}小时',
    'ShortRental.Insurance.Type': '保险分类',
    'ShortRental.Insurance.Age.Placeholder': '请选择投保年龄。',
    'ShortRental.Insurance.Type.Placeholder': '请选择保险种类。',
    'Reservation.EndLocation': '归还地点',
    'Reservation.EndLocation.Placeholder': '选择车辆归还地点',
    'Common.Deletes.Fail': '无法删除部分，请确认数据。',
    'Reservation.OwnVehicle': '个人车辆投保与否',
    'Reservation.CarInfo': '车辆信息',
    'Reservation.SelectCar': '选择车辆',
    'Reservation.CarNumber': '车牌号',
    'Reservation.CarModel': '车型',
    'Reservation.CarColor': '车辆颜色',
    'Reservation.CarFuel': '燃油类型',
    'Reservation.CarRate': '车辆等级',
    'Reservation.Agent': '管理员工',
    'Reservation.RentAgent': '租赁员工',
    'Reservation.ReturnAgent': '归还员工',
    'Reservation.Agent.Placeholder': '请选择员工。',
    'Reservation.Join': '注册',
    'Reservation.NotJoin': '未注册',
    'Reservation.Indemnity.title': '免责金信息',
    'Reservation.Run.title': '燃油及行驶里程',
    'Reservation.Run.Fuel': '燃油量',
    'Reservation.Run.Run': '行驶里程',
    'Reservation.Run.Rental': '租赁',
    'Reservation.Run.Return': '归还',
    'ShortRental.OwnVehicle.Type.Placeholder': '请选择注册种类。',
    'ShortRental.User.Public': '法人',
    'ShortRental.User.Private': '个人',
    'ShortRental.User.SelectBusiness': '查找当前法人',
    'ShortRental.User.Name.Error': '需输入姓名。',
    'ShortRental.User.ZipCode.Error': '需输入邮编。',
    'ShortRental.User.SelectUser': '查找当前会员',
    'ShortRental.User.SelectEmployee': '查找员工',
    'ShortRental.User.Contact': '联系电话',
    'ShortRental.User.Contact.Placeholder': '请输入联系电话。',
    'ShortRental.User.Contact.Error': '需输入联系电话。',
    'ShortRental.User.Address.Error': '需输入地址。',
    'ShortRental.User.Email': '邮箱地址',
    'ShortRental.User.Email.Placeholder': '请输入邮箱地址。',
    'ShortRental.User.Fax': '传真',
    'ShortRental.User.Fax.Placeholder': '请输入传真号。',
    'ShortRental.User.BusinessRegNum.Error': '需输入营业证号。',
    'ShortRental.User.DetailAddress.Error': '需输入详细地址。',
    'ShortRental.User.FirstDriver': '第一驾驶员',
    'ShortRental.User.SecondDriver': '第二驾驶员',
    'ShortRental.Charge.Title': '费用信息',
    'ShortRental.Charge.Discount.title': '优惠信息',
    'ShortRental.Charge.Default': '起步费',
    'ShortRental.Charge.Discount': '优惠费用',
    'ShortRental.Charge.Rental': '租金',
    'ShortRental.Charge.Real': '租赁金额',
    'ShortRental.Charge.Additional': '附加金额',
    'ShortRental.Charge.Etc': '保险费外',
    'ShortRental.Charge.Sum': '租赁费合计',
    'ShortRental.Etc.Special': '特殊事项',
    'ShortRental.Etc.Memo': '备忘录',
    'ShortRental.Car.Select': '无可选车辆。',
    'ShortRental.DueDate.Empty': '需输入租赁日期。',
    'Title.LongRental': '新车长期租赁',
    'LongRental.Type': '租赁分类',
    'LongRental.Type.Estimate': '新车长期租赁报价',
    'LongRental.Type.Create': '新车长期租赁登记',
    'LongRental.ChargeInfo': '费用信息',
    'LongRental.RentalDeposit': '押金',
    'LongRental.ExtraCharge': '附加费用',
    'LongRental.ExtraItem': '附加项目',
    'LongRental.MonthlyCharge.Title': '月租赁费',
    'LongRental.MonthlyCharge': '租金',
    'LongRental.MonthlyTotal': '合计',
    'LongRental.ContractNumber': '合同号',
    'LongRental.ContractStatus': '报价状态',
    'LongRental.Info.Repair': '是否包括维修',
    'LongRental.Repair.Include': '包括',
    'LongRental.Repair.NonInclude': '未包括',
    'LongRental.Payment.first': '第一次',
    'LongRental.Payment.Final': '最后一次',
    'LongRental.Payment.Day': '缴纳日',
    'LongRental.Payment.Charge': '缴纳金额',
    'LongRental.Payment.Month': '月付款日',
    'LongRental.Payment.Beginning': '最初缴纳日',
    'LongRental.CommonDriverInfo': '共用驾驶员信息',
    'LongRental.User.DriverName': '共用驾驶员',
    'LongRental.User.DriverName.Placeholder': '请输入共用驾驶员姓名',
    'LongRental.User.Contact': '联系电话',
    'LongRental.User.Contact.Placeholder': '请输入联系电话。',
    'LongRental.User.Birth': '出生年月日',
    'LongRental.User.Birth.Placeholder': '身份证号前6位数  ex) 990126',
    'LongRental.User.DriverLicense': '驾驶证',
    'LongRental.Kind.DriverLicense.Placeholder': '请选择驾驶证种类。',
    'Title.MonthRental': '按月长租',
    'MonthRental.Type': '租赁分类',
    'MonthRental.Type.Reservation': '预约按月长租',
    'MonthRental.Type.Create': '登记按月长租',
    'MonthRental.ReservationNumber': '预约号',
    'MonthRental.ReservationStatus': '预约状态',
    'Rent.RentalDueDate': '计划租赁日期',
    'MonthRental.ExtraCharge': '附加金额',
    'Menu.Vehicle': '车辆管理',
    'Menu.Maintenance': '车辆维护',
    'Menu.Operation': '服务运营管理',
    'Menu.Vehicle.Insurance': '保险缴纳管理',
    'Menu.Rent': '租赁管理',
    'Menu.Payment': '销售额/结算管理',
    'Menu.Work': 'MOCEAN业务管理',
    'Menu.System': '系统管理',
    'Common.Get.Fail': '无法查询。',
    'Menu.History': '记录',
    'Title.ControlHistory': '车辆控制记录',
    'Title.ControlHistory.Request': '请求记录',
    'Title.ControlHistory.Response': '应答记录',
    'ControlHistory.Filter.LicensePlateNumber': '车牌号',
    'ControlHistory.Filter.UserName': '姓名',
    'ControlHistory.HistoryTime': '发生时间',
    'ControlHistory.UserName': '姓名',
    'ControlHistory.LicensePlateNumber': '车牌号',
    'ControlHistory.ReqTime': '请求时间',
    'ControlHistory.ResTime': '应答时间',
    'ControlHistory.Action': 'Action',
    'ControlHistory.ReqResultMessage': 'Req结果',
    'ControlHistory.ResResultCode': '应答代码',
    'ControlHistory.ResData': '应答数据',
    'ControlHistory.ReqData': '请求数据',
    Vehicle: '车辆',
    'Vehicle.Tab.Detail': '车辆详情',
    'Vehicle.Tab.Purchase': '购买信息',
    'Vehicle.Tab.Accident': '事故/维修',
    'Vehicle.PurchaseMethod': '购买方法',
    'Vehicle.PurchaseCost': '价格事项',
    'Vehicle.Purchase.BasicCost': '车辆基础价',
    'Vehicle.Purchase.BasicCost.Placeholder': '车辆基础价',
    'Vehicle.Purchase.SelectCost': '选项',
    'Vehicle.Purchase.SelectCost.Placeholder': '选项',
    'Vehicle.Purchase.Consignment': '托运费',
    'Vehicle.Purchase.Consignment.Placeholder': '托运费',
    'Vehicle.Purchase.PureVehiclePrice': '裸车价',
    'Vehicle.Purchase.PureVehiclePrice.Placeholder': '裸车价',
    'Vehicle.Purchase.TotalCost': '价格合计',
    'Vehicle.Pay.DownPayment': '定金',
    'Vehicle.Pay.DownPayment.Placeholder': '定金',
    'Vehicle.Pay.DeliveryPayment': '取车费',
    'Vehicle.Pay.DeliveryPayment.Placeholder': '取车费',
    'Vehicle.Pay.TotalLeasePayment': '分期本金',
    'Vehicle.Pay.TotalLeasePayment.Placeholder': '分期本金',
    'Vehicle.PayInfo': '支付条件',
    'Vehicle.AdditionalFee': '附带费用',
    'Vehicle.Additional.MandatoryPremium': '强制保险费',
    'Vehicle.Additional.MandatoryPremium.Placeholder': '强制保险费',
    'Vehicle.Additional.StampPrice': '印花税',
    'Vehicle.Additional.StampPrice.Placeholder': '印花税',
    'Vehicle.Additional.EtcPrice': '其他费用',
    'Vehicle.Additional.EtcPrice.Placeholder': '其他费用',
    'Vehicle.Additional.TotalCost': '附带费用合计',
    'Vehicle.RegistrationFee': '车辆登记费',
    'Vehicle.Registration.TotalCost': '登记费合计',
    'Vehicle.Registration.AcquisitionTax': '购置税',
    'Vehicle.Registration.BoundDiscount': '扣款优惠',
    'Vehicle.Registration.RegistrationStampPrice': '印花税',
    'Vehicle.Registration.LicensePlateNumberPrice': '牌照费',
    'Vehicle.Registration.RegistrationFee': '登记代理费',
    'Vehicle.Registration.AcquisitionTax.Placeholder': '购置税',
    'Vehicle.Registration.BoundDiscount.Placeholder': '扣款优惠',
    'Vehicle.Registration.RegistrationStampPrice.Placeholder': '印花税',
    'Vehicle.Registration.LicensePlateNumberPrice.Placeholder': '牌照费',
    'Vehicle.Registration.RegistrationFee.Placeholder': '登记代理费',
    'Vehicle.TotalFee': '总购置费用',
    'Vehicle.Available': '确认可用车辆',
    'Title.LicenseCheckHistory': '驾驶证审证记录',
    'LicenseCheckHistory.Filter.UserID': '用户 ID',
    'LicenseCheckHistory.Filter.PhoneNumber': '联系方式',
    'LicenseCheckHistory.Filter.ResidentName': '驾驶员姓名',
    'LicenseCheckHistory.Filter.ResidentDate': '驾驶员出生年月日',
    'LicenseCheckHistory.Filter.ResponseCode': '结果代码',
    'LicenseCheckHistory.LicenseNo': '驾照号',
    'LicenseCheckHistory.LicnConCode': '驾驶证种类',
    'LicenseCheckHistory.UserID': '用户 ID',
    'LicenseCheckHistory.PhoneNumber': '联系方式',
    'LicenseCheckHistory.ResidentName': '驾驶员姓名',
    'LicenseCheckHistory.ResidentDate': '驾驶员出生年月日',
    'LicenseCheckHistory.ResponseCode': '应答结果',
    'LicenseCheckHistory.ResponseMessage': '应答信息',
    'LicenseCheckHistory.Date': '租赁时间',
    'LicenseCheckHistory.StartDate': '租赁起始时间',
    'LicenseCheckHistory.EndDate': '租赁结束时间',
    'LicenseCheckHistory.CreatedAt': '请求时间',
    'Title.RentalSearch': '查询可租赁车辆',
    'RentalSearch.Rental.Day': '租赁日期',
    'RentalSearch.Rental.UseCarYear': '车辆生产年份',
    'RentalSearch.Rental.UseCarFuel': '使用燃油',
    'RentalSearch.CarFuel': '燃油',
    'RentalSearch.CarLocation': '车辆位置',
    'RentalSearch.Title.RentalDetail': '车辆详情',
    'RentalSearch.CarAnsic': '车辆生产年份',
    'RentalSearch.FixedPeople': '核载定员',
    'RentalSearch.CarInsurance': '保险借车',
    'Business.Extra.Service': '附加服务',
    'Business.TestCar': '试驾车',
    'Common.Btn.Update': '更改完成',
    'Terminal.Title.State': '状态',
    'Common.Btn.DetailAndUpdate': '详细信息/更改',
    'Customer.Title.Private': '个人信息',
    'Title.Branch.Info': '门店基本信息',
    'Vehicle.BasicInfo.CarNumber': '车辆识别码',
    'Vehicle.BasicInfo.TerminalNumber': '终端机序列号',
    'Vehicle.BasicInfo.UseCarYear': '车辆生产年份',
    'Vehicle.BasicInfo.Transmission': '变速器',
    'Vehicle.BasicInfo.CreatedDate': '车辆登记日',
    'Vehicle.BasicInfo.InsuranceAge': '保险年龄',
    'Vehicle.BasicInfo.Option': ' 选项',
    'Vehicle.BasicInfo.BusinessName': '法人名称',
    'Vehicle.BasicInfo.Location': '网点',
    'Vehicle.BasicInfo.Color': '颜色',
    'Vehicle.BasicInfo.FuelType': '燃油类型',
    'Vehicle.BasicInfo.CarType': '车辆等级',
    'Vehicle.BasicInfo': '基本信息',
    'Vehicle.BasicInfo.AvailableDistance': '可行驶里程',
    'Vehicle.BasicInfo.TotalDistance': '总里程',
    'Vehicle.BasicInfo.AuxBattery': 'AUX电池',
    'Vehicle.BasicInfo.TempFuelUsage': '瞬时油耗量',
    'Vehicle.BasicInfo.FrontLeft': '前轮/左',
    'Vehicle.BasicInfo.FrontRight': '前轮/右',
    'Vehicle.BasicInfo.BackLeft': '后轮/左',
    'Vehicle.BasicInfo.BackRight': '后轮/右',
    'Vehicle.BasicInfo.DoorOpen': '开门',
    'Vehicle.BasicInfo.DoorClose': '关门',
    'Vehicle.BasicInfo.Siren': '喇叭/危险指示灯',
    'Common.Btn.UpdateVehicle': '更改车辆信息',
    'Vehicle.BasicInfo.Type': '类别',
    'Title.Company.Info': '客户详细信息',
    'Common.Btn.Setup': '设置',
    'Common.Btn.Setup.Complete': '设置完成。',
    'Common.Btn.DuplicateCheck': '重复确认',
    'Vehicle.BasicInfo.CreateDate': '最初登记日',
    'Vehicle.BasicInfo.ExpireDate': '车龄到期日',
    'MyPage.Business.Code.Placeholder': '请输入法人代码',
    'MyPage.Branch': '门店',
    'MyPage.Branch.Placeholder': '请选择法人和门店。',
    'Vehicle.BasicInfo.VehicleImage': '车辆照片',
    'Vehicle.BasicInfo.Terminal': '终端机',
    'Vehicle.InterpersonalCompensation1': '对人赔付1',
    'Vehicle.InterpersonalCompensation2': '对人赔付2',
    'Vehicle.Reparation1': '对物赔付1',
    'Vehicle.UninsuredCarInjury': '无保险车辆事故',
    'Vehicle.PhysicalAccident': '本人身体事故',
    'Vehicle.Interpersonal': '对人',
    'Vehicle.InterVehicle': '对物',
    'Vehicle.SelfDamage': '车损',
    'Vehicle.VehicleDamage': '个人车辆',
    'Vehicle.VehicleDamage.Registration': '个人车辆投保',
    'Vehicle.VehicleDamage.Join': '注册',
    'Vehicle.VehicleDamage.NotJoin': '未注册',
    'Title.VehicleEdit': '更改车辆',
    'Menu.Password': '修改密码',
    'Menu.MyBusiness': '我的公司',
    'Menu.MyBranch': '我的门店',
    UNKNOWN: '未知',
    'BusinessMember.RoleDetail.Business': '主管理员',
    'BusinessMember.RoleDetail.Branch': '门店管理员',
    'BusinessMember.RoleDetail.Staff': '员工',
    'Business.Title.License': '注册商品',
    'Business.License.Name': '商品名称',
    'Vehicle.Duplicate.Fail': '此车牌号已存在。',
    'Vehicle.Duplicate.Required': '请再次确认车牌号。',
    'Vehicle.Duplicate.Success': '此车牌号可进行登记。',
    'InsuranceRental.Title': '保险借车',
    'InsuranceRental.Type.Reservation': '保险借车预约',
    'InsuranceRental.Type.Creation': '保险借车登记',
    'InsuranceRental.Charge.Amount': '申请金额',
    'InsuranceRental.Charge.Daily': '日请求额',
    'InsuranceRental.Charge.Time': '时申请额',
    'InsuranceRental.Charge.Rate': '其他比例',
    'InsuranceRental.Charge.Rate.Charge': '申请比例',
    'InsuranceRental.Charge.Rate.Mistake': '担责比例',
    'InsuranceRental.Charge.TotalAmount': '申请金额',
    'InsuranceRental.Charge.TotalAmount.Example':
        '（（日申请金额 * 天数）+（小时申请金额 * 小时）） * 申请比例 * （100% - 过失比例）',
    'InsuranceRental.Charge.Real': '实际申请金额',
    'InsuranceRental.Accident.Title': '事故信息',
    'InsuranceRental.Accident.Damage': '事故信息',
    'InsuranceRental.Accident.Assault': '肇事信息',
    'InsuranceRental.Accident.Name': '姓名',
    'InsuranceRental.Accident.CarNum': '车牌号',
    'InsuranceRental.Accident.CarModel': '车型',
    'InsuranceRental.Accident.MaintenanceInfo': '维修信息',
    'InsuranceRental.Accident.MaintenanceShop': '维修厂',
    'InsuranceRental.Insurance.Title': '保险信息',
    'InsuranceRental.Insurance.CompanyInfo': '保险公司信息',
    'InsuranceRental.Insurance.CompanyCode': '保险公司',
    'InsuranceRental.Insurance.InsuranceCode': '保险受理编号',
    'InsuranceRental.Insurance.Manager': '保险管理员',
    'InsuranceRental.Insurance.Charge.Title': '申请信息',
    'InsuranceRental.Insurance.Charge.Date': '申请日期',
    'InsuranceRental.Insurance.Charge.Amount': '申请金额',
    'InsuranceRental.Insurance.Deposit.Date': '到账日期',
    'InsuranceRental.Insurance.Deposit.Amount': '到账金额',
    'InsuranceRental.Insurance.Mistake.Amount': '客户过失赔偿金',
    'InsuranceRental.Insurance.OwnVehicle.Amount': '个人车辆投保费',
    'InsuranceRental.Insurance.Payment.Type': '支付类型',
    'InsuranceRental.Insurance.Payment.Type.Card': '卡',
    'InsuranceRental.Insurance.Payment.Type.Cash': '现金',
    'Common.Btn.Search': '搜索',
    'Reservation.Label.StartDate': '租赁起始日',
    'Reservation.Label.SearchType': '搜索项',
    'Reservation.Label.Type': '租赁分类',
    'Reservation.Label.Status': '预约状态',
    'Reservation.Label.DueDate': '计划归还时间',
    'Title.RentList': '租赁记录管理',
    'Vehicle.DuplicateCheck.FieldNull': '请输入车牌号。',
    'Common.Btn.DeleteVehicle': '删除车辆信息',
    'Menu.Vehicle.Mgnt': '车辆登记管理',
    'Menu.Vehicle.Insurance.Payment.Mgnt': '保险信息管理',
    'Menu.Insurance.Payment.Mgnt': '保险信息管理',
    'Menu.Vehicle.Purchase.Payment.Mgnt': '分期金额缴纳管理',
    'Menu.Vehicle.Accident.Mgnt': '事故维修管理',
    'Menu.Vehicle.Penalty.Pee.Mgnt': '罚金管理',
    'Menu.Branch.Mgnt': '门店管理',
    'Menu.Business.Member.Mgnt': '公司员工管理',
    'Menu.Business.Customer.Mgnt': '公司客户管理',
    'Menu.Customer.Mgnt': '客户管理',
    'Menu.Member.Mgnt': '员工管理',
    'Menu.License': '商品管理',
    'Menu.Code': '代码管理',
    'Menu.Menu': '菜单管理',
    'Menu.Role': '权限管理',
    'Menu.Program': '程序管理',
    'Menu.Message': '信息管理',
    'Menu.Rent.Fare': '租赁费管理',
    'Menu.Vehicle.Rent.Fare': '各车辆租赁费',
    'Menu.Rent.Insurance.Fare': '租赁保险费管理',
    'Menu.Vehicle.Rent.Insurance.Fare': '各车辆租赁保险费',
    'Menu.Rent.Schedule': '租赁情况查询',
    'Menu.Rent.Vehcile.Available': '查询可租赁车辆',
    'Menu.Reservation.List': '预约信息查询',
    'Menu.Rent.List': '租赁记录管理',
    'Menu.CompanyUser': '客户管理员管理',
    'Menu.Company': '客户管理',
    'Reservation.Label.RentalRegister': '租赁登记',
    'Common.Confirm.Reservation.Cancle': '确定要取消预约吗',
    'Common.Logout.Success': '您已安全退出，再见',
    'LongRental.Estimate.CarInfo': '报价车辆信息',
    'LongRental.ReservationDate.Placeholder': '请选择租赁期限。',
    'LongRental.Estimate.VehicleName': '车辆名称',
    'LongRental.Purchase.Cost': '车辆价格（元）',
    'LongRental.RentalDeposit.Cost': '押金（元）',
    'LongRental.MonthlyCharge.Cost': '月租赁费（元）',
    'LongRental.Period': '期限（个月）',
    'LongRental.Options': '选项',
    'LongRental.PenaltyAmount': '违约金(%)',
    'LongRental.Note': '备注',
    'LongRental.VehiclePremium': '缴纳信息',
    'LongRental.Charge.Method': '申请方式',
    'LongRental.Charge.Bank': '申请银行',
    'LongRental.Charge.CountNumber': '帐号',
    'LongRental.Charge.CountOwner': '收款人',
    'LongRental.Send.Method': '申请地',
    'LongRental.Pay.Day': '支付日期',
    'LongRental.Charge.Method.Placeholder': '请选择申请方式',
    'LongRental.Charge.Bank.Placeholder': '请选择申请银行',
    'LongRental.Charge.CountNumber.Placeholder': '请输入帐号',
    'LongRental.Charge.CountOwner.Placeholder': '请输入收款人',
    'LongRental.Send.Method.Placeholder': '请选择申请地',
    'LongRental.Pay.Day.Placeholder': '请选择支付日期',
    'Rent.UseState.Type.Day': '日',
    'Rent.UseState.Type.Month': '每月',
    'Code.GradeType.Placeholder': '车辆等级',
    'Code.VehicleStatus.Placeholder': '租赁状态',
    'UseState.State.Short': '短期',
    'UseState.State.Insurance': '保险',
    'UseState.State.Month': '月',
    'UseState.State.Long': '长期',
    'Code.Weekdays.0': '天',
    'Code.Weekdays.1': '月',
    'Code.Weekdays.2': '星期二',
    'Code.Weekdays.3': '星期三',
    'Code.Weekdays.4': '星期四',
    'Code.Weekdays.5': '星期五',
    'Code.Weekdays.6': '星期六',
    'VehicleModel.Image': '照片',
    'Branch.ManagerName.Detail': '主管理员姓名',
    'Branch.ManagerPhoneNumber.Detail': '主管理员联系方式',
    'Branch.ManagerEmail.Detail': '主管理员邮箱',
    'Rent.Filter.Label.Status': '租赁状态',
    'Rent.Filter.Label.IncludeMaintenance': '包括维修',
    'Rent.Filter.Label.PaymentDate': '缴纳日',
    'Rent.Filter.Code': '租赁号',
    'Rent.Filter.User.Name': '租赁人姓名',
    'Rent.Filter.User.MobileNumber': '租赁人联系方式',
    'Rent.Filter.User.Location': '租赁网点',
    'Rent.Filter.Status.Inuse': '租赁中',
    'Rent.Filter.Status.Returned': '租赁结束',
    'Rent.Filter.Status.Canceled': '中途解除',
    'Rent.Filter.Status.LowPower': '低电压',
    'Rent.Filter.Status.NoReturned': '未归还',
    'Rent.Filter.Maintenance.Include': '包括',
    'Rent.Filter.Maintenance.NotInclude': '未包括',
    'Rent.Filter.Payment.5': '5天',
    'Rent.Filter.Payment.7': '7天',
    'Rent.Filter.Payment.10': '10天',
    'Rent.Filter.Payment.15': '15天',
    'Rent.Filter.Payment.20': '20天',
    'Rent.Filter.Payment.25': '25天',
    'Rent.Filter.Payment.EndOfMonth': '月末',
    'Rent.Code': '合同号',
    'Rent.Date': '租赁时间',
    'Rent.Status.INUSE': '租赁中',
    'Rent.Status.RETURNED': '租赁结束',
    'Rent.Status.BE_CANCELED': '中途解除',
    'Rent.Status.LOW_POWER': '低电压',
    'Rent.Status.NO_RETURNED': '未归还',
    'Rent.IncludeMaintenance': '维修与否',
    'Rent.Extend': '租赁延期',
    'Rent.Contract': '合同书',
    'Rent.status.Requested': '预约请求',
    'Rent.status.Canceled': '取消预约',
    'Rent.status.NoShow': '未交车',
    'Rent.status.Deleted': '删除预约',
    'Rent.status.Denied': '拒绝预约',
    'Rent.status.CommError': '通信错误',
    'Rent.status.Estimate': '报价完成',
    'Rent.status.EstimateCanceled': '取消报价',
    'Rent.status.ChangeToInsurance': '保险借车转换',
    'Rent.status.ChangeToShort': '转换短期租赁',
    'Rent.status.Insurance.Billing': '保险金申请单',
    'Rent.status.Billing': '合同书',
    'Rent.modal.Return.message': '确定要归还车辆吗？',
    'Rent.modal.Return.Date': '归还时间',
    'Rent.modal.Return.Worker': '作业人员',
    'Rent.modal.Return.WorkDate': '作业日期',
    'Rent.modal.Return.Fail': '归还处理失败。',
    'Rent.modal.Extend.message': '确定要延长车辆租赁时间吗？',
    'Rent.modal.Extend.DueDate': '上一结束日期',
    'Rent.modal.Extend.Date': '延期结束日期',
    'Rent.modal.Extend.Fail': '延期失败。',
    'Rent.modal.Force.message': '确定要强制归还车辆吗？',
    'Rent.modal.Force.Date': '强制归还时间',
    'Rent.modal.Force.Fail': '强制归还失败。',
    'VehicleBasic.VIN.Error': '已有或无效的VIN。请再次确认信息。',
    'LongRental.Charge.DirectDebit': '自动转账',
    'LongRental.Charge.VirtualAccount': '虚拟帐户',
    'LongRental.Charge.Card': '用卡支付',
    'LongRental.Charge.Billing': '申请单',
    'LongRental.Charge.Home': '家',
    'LongRental.Charge.Company': '公司',
    'LongRental.UserNameInfo': '租赁人信息',
    'LongRental.VehicleNumber': '车辆',
    'LongRental.Payment.Month.Placeholder': '请选择缴纳日',
    'LongRental.Title.Estimate.Create': '登记报价车辆',
    'LongRental.Estimate.VehicleName.Placeholder': '请输入车辆名称',
    'LongRental.Purchase.Cost.Placeholder': '请输入车辆价格',
    'LongRental.RentalDeposit.Cost.Placeholder': '请输入押金',
    'LongRental.MonthlyCharge.Cost.Placeholder': '请输入月租赁费',
    'LongRental.Period.Placeholder': '请输入期限。',
    'LongRental.Options.Placeholder': '请输入选项',
    'LongRental.PenaltyAmount.Placeholder': '请输入违约金',
    'LongRental.Note.Placeholder': '请输入备注内容',
    'Title.Charge.Peak.Fare': '旺季费用',
    'Title.Charge.Discount': '租赁优惠率',
    'Vehicle.Tab.Log': '运行日志',
    'Common.Fail.User': '存在重复的预约人。',
    'Common.Btn.ContractCancle': '中途解除',
    'Rent.modal.ContractCancle.message': '确定要中途解除车辆租赁吗？',
    'LongRental.ContractCancle.StartDate': '租赁起始日期',
    'LongRental.ContractCancle.EndDate': '计划归还时间',
    'LongRental.ContractCancle.ContractCancle': '中途解除日期',
    'LongRental.ContractCancle.ReturnPay.Title': '租赁退费',
    'LongRental.ContractCancle.Refund': '退还租赁费',
    'LongRental.ContractCancle.Total': '合计',
    'LongRental.ContractCancle.PenaltyAmount': '违约金',
    'LongRental.Charge.Cash': '现金',
    'LongRental.Estimate.message': '请输入报价车辆及租赁信息。',
    'Common.BeContractCancle.Fail': '无法中途解除',
    'Common.Btn.Report': '报价单',
    'Code.Create.ErrorCode.910004': '代码组密钥重复。',
    'Code.Update.ErrorCode.910004': '代码组密钥重复。',
    'Code.Delete.ErrorCode.910004': '存在下级代码，无法删除。',
    'VehicleInsurance.branchName': '旗下门店',
    'VehicleInsurance.insureYear': '年次',
    'VehicleInsurance.paymentDueDate': '计划缴纳日',
    'VehicleInsurance.totalInsurance': '总保险费',
    'VehicleInsurance.paymentStatus': '缴纳与否',
    'VehicleInsurance.ManagerName': '保险管理员',
    'VehicleInsurance.ManagerMobileNumber': '保险联系电话',
    'LongRental.values': '合计 = 押金-违约金+免责金-附加金额+退费',
    'Vehicle.Tab.Insurance': '保险信息',
    'VehicleInsurance.yearSelect': '选择年次',
    'VehicleInsurance.1year': '第1年',
    'VehicleInsurance.2year': '第2年',
    'VehicleInsurance.3year': '第3年',
    'Menu.Business': '公司管理',
    'VehicleInsurance.12Month': '12个月',
    'VehicleInsurance.24Month': '24个月',
    'VehicleInsurance.36Month': '36个月',
    'VehicleInsurance.2Month': '2个月',
    'VehicleInsurance.3Month': '1 个月',
    'VehicleInsurance.4Month': '4个月',
    'VehicleInsurance.5Month': '5个月',
    'VehicleInsurance.6Month': '6个月',
    'VehicleInsurance.7Month': '7个月',
    'VehicleInsurance.8Month': '8个月',
    'VehicleInsurance.9Month': '9个月',
    'VehicleInsurance.10Month': '10个月',
    'VehicleInsurance.11Month': '11个月',
    ERROR_CODE_SUCCESS: '成功。',
    ERROR_CODE_NO_CONTENT: '无数据。',
    ERROR_CODE_CREATED: '创建成功。',
    ERROR_CODE_NOT_CREATED: '数据输入失败。',
    ERROR_CODE_NOT_UPDATED: '数据更改失败。',
    ERROR_CODE_NOT_DELETED: '数据删除失败。',
    ERROR_CODE_BAD_REQUEST: '输入参数不正确。',
    ERROR_CODE_FORBIDDEN_ROLE: '没有管理员权限。',
    ERROR_CODE_FORBIDDEN_ACL: '没有用户权限。',
    ERROR_CODE_INTERNAL_SERVER_ERROR: '发生内部错误。',
    ERROR_CODE_NEED_LOGON: '需登录。',
    ERROR_CODE_VEHICLE_NOT_DELETE_RESERVATION: '当前有预约信息，无法删除车辆。',
    ERROR_CODE_VEHICLE_NOT_DELETE_ACCIDENT: '存在事故记录，无法删除车辆。',
    ERROR_CODE_VEHICLE_VIN_DUPLICATION: '无法使用车辆VIN码。',
    ERROR_CODE_VEHICLE_CONTROL_ID_DUPLICATION: '无法使用车辆控制-ID。',
    ERROR_CODE_USER_NOT_AUTHORIZATION: '用户认证失败。',
    ERROR_CODE_BUSINESS_NOT_DELETE_CAR: '存在已关联车辆，无法删除。',
    ERROR_CODE_PRIVATE_UNAUTH_BUSINESS: '用户激活后无法更换法人。',
    ERROR_CODE_CHARGE_NOT_DELETE_RESERVATION: '已有预约的保险费。',
    ERROR_CODE_VEHICLEMODEL_NOT_DELETE: '存在已关联车辆，无法删除。',
    ERROR_CODE_RESERVATION_ALEADY_VEHICLE: '此车辆已被预约。',
    ERROR_CODE_RESERVATION_CONFLICT: '用户预约发生冲突。',
    ERROR_CODE_RESERVATION_UNAUTH_USER: '未认证用户无法预约。',
    ERROR_CODE_RESERVATION_FIRST_USER_BUSINESS: '请先登记法人。',
    ERROR_CODE_RESERVATION_NOT_INSUE: "预约状态不是 '正在使用'。",
    ERROR_CODE_RESERVATION_RETURN_NOT_LOCATION:
        '当前车辆位置与归还位置不一致。',
    ERROR_CODE_RESERVATION_BAD_REQUEST: '无法解释请求。',
    ERROR_CODE_RESERVATION_VEHICLE_ERROR: '无法找到车辆信息。',
    ERROR_CODE_RESERVATION_TIME_ERROR: '起始时间和结束时间不正常。',
    ERROR_CODE_RESERVATION_RENTAL_COMPANY: '租赁客户登记或更改失败。',
    ERROR_CODE_RESERVATION_DRIVER_LICENSE: '驾驶员驾驶证登记或更改失败。',
    ERROR_CODE_RESERVATION_ADDITIONAL_DRIVER_LICENSE: '驾驶员添加信息无效。',
    ERROR_CODE_RESERVATION_NO_CONTENT: '无数据',
    ERROR_CODE_RESERVATION_GRPC_ERROR: 'GRPC模块发生错误',
    ERROR_CODE_RESERVATION_STATUS_ERROR: '状态错误',
    ERROR_CODE_RESERVATION_KOROAD_ERROR: '驾驶证认证失败。',
    ERROR_CODE_RESERVATION_INUSE: '在执行15分钟之前可进行租赁登记。',
    ERROR_CODE_RESERVATION_OVERLAP: '同时间段有预约。',
    ERROR_CODE_CHARGE: '无法找到缴纳信息。',
    ERROR_CODE_TESTCAR_CONFIG_NOT_EXIST: '无试驾车设置信息。',
    ERROR_CODE_TESTCAR_RESERVATION_PERIOD_EXCESS: '超出试驾车预约时间。',
    ERROR_CODE_TESTCAR_STARTTIME_WEEKEND: '周末无法租赁。',
    ERROR_CODE_TESTCAR_ENDTIME_WEEKEND: '周末无法归还。',
    ERROR_CODE_TESTCAR_STARTTIME_HOLIDAY: '休息日无法租车。',
    ERROR_CODE_TESTCAR_ENDTIME_HOLIDAY: '休息日无法还车。',
    ERROR_CODE_TESTCAR_COUNT_EXCESS: '超出试驾车预约限制。',
    ERROR_CODE_MONITORING_NOT_FIND_VIN: '当前车辆位置与归还位置不一致。',
    ERROR_CODE_CONTROL_FAIL: '车辆远程控制失败。',
    ERROR_CODE_CONTROL_VIN_DUPLICATION: '无法使用VIN码。',
    ERROR_CODE_CONTROL_NADID_DUPLICATION: '无法使用NADID信息。',
    ERROR_CODE_RESOURCE_NOT_FOUND: '无法找到资源。',
    ERROR_CODE_RESOURCE_KEY_DUPLICATION: '资源密钥不可用。',
    ERROR_CODE_RESOURCE_KEY_EMPTY: '空的资源密钥。',
    ERROR_CODE_COMPANY_BUSINESS_REG_NUM_EXIST: '此营业证号已登记。',
    ERROR_CODE_EXIST_BRANCH_USER: '网点用户已存在。',
    'Vehicle.BasicInfo.ModelYear.Placeholder': '请输入车辆生产年份',
    'Vehicle.BasicInfo.ModelYear': '车辆生产年份',
    'Vehicle.BasicInfo.ExtendCnt': '车龄延长次数',
    'Vehicle.BasicInfo.RegularInspectionDate': '定期检查',
    'Vehicle.ExtendCnt.0': '0次',
    'Vehicle.ExtendCnt.1': '1次',
    'Vehicle.ExtendCnt.2': '2次',
    'Branch.Manager.Title': '门店代表理事',
    ERROR_CODE_ALREADY_RESERVED: '此日期已有预约。',
    'LongRental.Charge.Bank_CARD': '发卡行',
    'LongRental.Charge.CountNumber_CARD': '卡号',
    'LongRental.Charge.CountOwner_CARD': '持卡人',
    'VehicleInsurance.Payment.1': '第1次',
    'VehicleInsurance.Payment.2': '第2次',
    'VehicleInsurance.Payment.3': '第3次',
    'VehicleInsurance.Payment.4': '第4次',
    'VehicleInsurance.Payment.5': '第5次',
    'VehicleInsurance.Payment.6': '第6次',
    'VehicleInsurance.Payment.7': '第7次',
    'VehicleInsurance.Payment.8': '第8次',
    'VehicleInsurance.Payment.9': '第9次',
    'VehicleInsurance.Payment.10': '第10次',
    'VehicleInsurance.Payment.11': '第11次',
    'VehicleInsurance.Payment.12': '第12次',
    'Vehicle.Model.Info': '车型信息',
    'VehicleInsurance.Select.Paid': '缴纳',
    'VehicleInsurance.Day.type': '选择日期',
    'VehicleInsurance.Day.Duration': '缴纳期限',
    'VehicleInsurance.Modal.Year': '年次/次数',
    'Common.Buuton.Close': '关闭',
    'Vehicle.Used': '可租赁状态',
    'Vehicle.Used.Yes': '可租赁',
    'Vehicle.Used.No': '停止租赁',
    'Common.Btn.Terminal': '终端发送',
    'Reservation.Request.Terminal.Fail': '无法将预约信息发送到终端机。',
    'Reservation.Request.Terminal.Success': '请求将预约信息发送到终端机。',
    'Reservation.Request.Terminal.Confirm': '确定要将预约信息发送到终端机吗？',
    'TestCarConfig.Reservation.StartTime': '设置的预约起始日期/时间',
    'Common.Input.Required.Hour': '可输入0到23。',
    'Common.Input.Required.Day': '可输入1到31。',
    'Location.Radius.50m': '50m',
    'Location.Radius.100m': '100m',
    'Location.Radius.200m': '200m',
    'Location.Radius.300m': '300m',
    'Menu.Reservation.TestCar': '试驾车租赁管理',
    'Charge.Minute.Zero': '0分钟',
    'Charge.Minute.Ten': '10分钟',
    'Charge.Minute.Twenty': '20分钟',
    'Charge.Minute.Thirty': '30分钟',
    'Charge.Minute.Forty': '40分钟',
    'Charge.Minute.Fifty': '50分钟',
    'LongRental.Payment.Day.One': '1天',
    'LongRental.Payment.Day.Five': '5天',
    'LongRental.Payment.Day.Ten': '10天',
    'LongRental.Payment.Day.Fifteen': '15天',
    'LongRental.Payment.Day.Twenty': '20天',
    'LongRental.Payment.Day.TwentyFifth': '25天',
    'LongRental.Payment.Day.Last': '月末',
    'LongRental.RentalInfo.Year.Two': '24个月',
    'LongRental.RentalInfo.Year.Three': '36个月',
    'LongRental.RentalInfo.Year.Four': '48个月',
    'LongRental.RentalInfo.Year.Five': '60个月',
    'LongRental.Charge.Bank_CARD.Placeholder': '请选择发卡行。',
    'LongRental.Charge.CountNumber_CARD.Placeholder': '请输入卡号。',
    'LongRental.Charge.CountOwner_CARD.Placeholder': '请输入持卡人。',
    ERROR_CODE_LICENSE_USE_BUSINESS_EXIST:
        '已存在正在使用费用套餐的法人/租用车辆，无法删除。',
    ERROR_CODE_MENU_CHILDREN_EXIST: '存在下级菜单，无法删除',
    ERROR_CODE_ROLE_CHILDREN_EXIST: '存在下级权限数据，无法删除',
    'TestCar.Reservation.View': '查看',
    'Common.Input.Required.Percent': '可输入1到100。',
    'Terminal.message': '*搜索实际终端信息，有可能出现不一致的情况*',
    'Reservation.Request.BeContractCancle.Success': '新车长期租赁已中途解除。',
    'VehicleInsurance.4year': '第4年',
    'VehicleInsurance.5year': '第5年',
    'VehicleInsurance.6year': '第6年',
    'VehicleInsurance.7year': '第7年',
    'VehicleInsurance.8year': '第8年',
    'VehicleInsurance.9year': '第9年',
    'VehicleInsurance.10year': '第10年',
    'Reservation.Error.SelectPeriod': '请选择计划租赁日期。',
    'Reservation.Error.StartLocation': '请选择取车地点。',
    'Common.Error.message': '无法执行功能。',
    'Title.PaymentManager': '销售额/结算情况',
    'Menu.Payment.Violation.Mgnt': '滞纳金/通行费管理',
    ERROR_CODE_NOT_FOUND_RESERVATION_DATA: '无违章相关预约记录。',
    ERROR_CODE_FORCERETURN_CAN_BE_MADE_AFTER_THE_START_TIME:
        '租赁起始时间开始后才能强制归还。',
    'Password.Infomation1':
        '- 为了保护个人信息，输入密码后才能查询客户的租赁合同。',
    'Password.Infomation2':
        '- 个人客户请输入6位数出生年月日，法人客户请输入营业证号前6位数后点击确认键。',
    'Password.CompanyInfo1': '公司',
    'Vehicle.Registration.Card': '机动车登记证书',
    'Vehicle.Registration.Card.Btn.Create': '登记机动车登记证书',
    'Vehicle.Registration.Card.Create.Help':
        '请选择机动车登记证书(jpeg、jpg、png)',
    'Vehicle.Registration.Card.Create.ExtName.Help':
        '仅限使用扩展名jpeg、jpg、png。',
    'Vehicle.Registration.Card.Btn.Upload': '上传机动车登记证书',
    'Vehicle.Registration.Card.Upload.Fail': '无法分析机动车登记证书。',
    'Common.Buuton.Kakaotalk': '通知',
    'Common.fail.changeMonth': '请选择租赁期限',
    'Send.Kakaotalk.Fail': '通知发送失败。',
    'Send.Kakaotalk.Success': '通知发送成功。',
    'Send.Kakaotalk.Password.Fail': '密码输入错误。',
    'Send.Kakaotalk.Password.Success': '密码输入成功。',
    ERROR_CODE_TODAY_IS_NOT_SAME_DATE: '租赁登记只能以当天日期进行登记。',
    'Common.Error.Validation': '请输入所有必填项。',
    ERROR_CODE_NOT_MATCH_PASSWORD: '密码不一致。',
    'Vehicle.BasicInfo.Insurance.Limit': '保险限额',

    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */
    'Vehicle.BasicInfo.Equip': '장착',
    'Vehicle.BasicInfo.Not.Equip': '미장착',
    'Vehicle.Title.RealTimeInfo': '실시간차량정보',
    'Vehicle.Title.AdditionalInfo': '부가정보',
    'Vehicle.AdditionalInfo.iviID': 'IVI ID',
    'Vehicle.AdditionalInfo.iviID.Call': 'IVI ID 불러오기',
    'Vehicle.AdditionalInfo.asPeriod': 'A/S 기간',
    'Vehicle.AdditionalInfo.taxiDrivingGroup': '택시운행조',
    'Vehicle.AdditionalInfo.VIN.FieldNull': '차대번호를 입력해주세요.',
    'Vehicle.AdditionalInfo.iviID.Fail': 'IVI ID 조회 실패',
    'Vehicle.AdditionalInfo.iviID.Empty':
        '차대번호에 해당되는 IVI ID가 존재하지 않습니다.',

    'Vehicle.Tab.Sales': '차량별매출',
    'Vehicle.Sales.HistoryTime': '발생일시',
    'Vehicle.Sales.Business.Time': '영업시간(거리)',

    'Menu.Vehicle.OperationStatistics': '차량운행일지',
    'Vehicle.OperationStatistics.Btn.Download': '엑셀다운로드',
    'Terms.Search.Period': '조회기간',
    'Vehicle.OperationStatistics.Vehicle.Number': '차량번호',
    'Vehicle.OperationStatistics.TaxiDriver.Name': '기사명',
    'Vehicle.OperationStatistics.Date': '날짜',
    'Vehicle.OperationStatistics.Login.Time': '출근시간',
    'Vehicle.OperationStatistics.Logout.Time': '퇴근시간',
    'Vehicle.OperationStatistics.Driving.Time': '주행시간(거리)',
    'Vehicle.OperationStatistics.Business.Time': '영업시간(거리)',
    'Vehicle.OperationStatistics.Empty.Time': '공차시간(거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount': '총영업금액(호출/배회)',

    'Vehicle.OperationStatistics.Title.Info': '차량운행 상세 정보',
    'Vehicle.OperationStatistics.Work.Time': '출퇴근시간',
    'Vehicle.OperationStatistics.TotalDriving.Time': '총주행시간(거리)',
    'Vehicle.OperationStatistics.TotalBusiness.Time': '총영업시간(거리)',
    'Vehicle.OperationStatistics.TotalEmpty.Time': '총공차시간(거리)',
    'Vehicle.OperationStatistics.Business.Rate': '영업률(영업거리/총주행거리)',
    'Vehicle.OperationStatistics.TotalSalesAmount2': '총매출금액',
    'Vehicle.OperationStatistics.Business.Count': '영업건수/객단가',
    'Vehicle.OperationStatistics.CallType.Count': '호출/배회건수',
    'Vehicle.OperationStatistics.CallType.SalesAmount': '호출/배회 매출금액',

    'Vehicle.OperationStatistics.Departure.Time': '승차시간',
    'Vehicle.OperationStatistics.Arrive.Time': '하차시간',
    'Vehicle.OperationStatistics.Departure.PlaceName': '승차위치',
    'Vehicle.OperationStatistics.Arrive.PlaceName': '하차위치',
    'Vehicle.OperationStatistics.Call.YN': '호출여부',
    'Vehicle.OperationStatistics.Call.App': '호출APP',
    'Vehicle.OperationStatistics.Payment.Method': '결제방식',
    'Vehicle.OperationStatistics.SalesAmount': '매출금액',
    'Vehicle.OperationStatistics.Create.Time': '운행정보입력시간',
    'Vehicle.OperationStatistics.PaymentType.Cash': '현금',
    'Vehicle.OperationStatistics.PaymentType.Card': '카드',
    'Vehicle.OperationStatistics.AppType.Macaron': '마카롱배차앱',
    'Vehicle.OperationStatistics.AppType.TMoney': '티머니미터기',
    /* 차량등록관리 - 부가정보 2020-08-24 hkchoi */

    // 차량만령관리
    'Vehicle.ExpiryHeader.Title': '차량만령관리',
    'Vehicle.ExpirySearch.Title': '차령만료일 기간',
    'Vehicle.ExpirySearch.all': '전체',
    'Vehicle.ExpirySearch.prevday': '1일전',
    'Vehicle.ExpirySearch.prevweek': '1주일전',
    'Vehicle.ExpirySearch.prevtwoweek': '2주일전',
    'Vehicle.ExpirySearch.prevmonth': '1달전',
    'Vehicle.ExpirySearch.prevyear': '1년 2주전',
    'Vehicle.ExpirySearch.today': '당일',
    'Vehicle.ExpiryHeader.vehicleAge': '차량연령',

    // 택시통합연동로그
    'Menu.ReceiveLog': '택시통합연동로그',
    'Title.ReceiveLog': '택시통합연동로그',
    'ReceiveLog.Btn.Download': '엑셀다운로드',
    'ReceiveLog.Filter.RegDate': '등록일',
    'ReceiveLog.Filter.InterfaceId': 'InterfaceID',
    'ReceiveLog.Filter.DriverName': '기사명',
    'ReceiveLog.Filter.Pincode': 'PINCODE',
    'ReceiveLog.Filter.ResultCodeKind': '응답코드종류',
    'ReceiveLog.Filter.ResultCode': '응답코드',
    'ReceiveLog.ResultCodeKind.All': '전체',
    'ReceiveLog.ResultCodeKind.Success': '성공',
    'ReceiveLog.ResultCodeKind.Failure': '실패',
    // 'ReceiveLog.Item.ID' : 'ID',
    'ReceiveLog.Item.CreatedDateTime': '생성일시',
    'ReceiveLog.Item.YdServiceName': '서비스명',
    'ReceiveLog.Item.InterfaceId': 'InterfaceID',
    'ReceiveLog.Item.InterfaceName': 'Interface명',
    'ReceiveLog.Item.AccessDateTime': '요청일시',
    // 'ReceiveLog.Item.SessionId' : '세션ID',
    'ReceiveLog.Item.UrlPath': 'API URL',
    // 'ReceiveLog.Item.HttpMethod' : '메소드명',
    // 'ReceiveLog.Item.ClientIp' : 'Client IP',
    'ReceiveLog.Item.TaxiDriverName': '기사명',
    'ReceiveLog.Item.Pincode': 'PINCODE',
    // 'ReceiveLog.Item.Vin' : 'VIN No.',
    'ReceiveLog.Item.Param': 'Parameter',
    'ReceiveLog.Item.ResultCode': '응답코드',
    'ReceiveLog.Item.ResultMsg': '응답메시지',
    'ReceiveLog.Item.InnerResultMsg': '응답메시지상세',
    'ReceiveLog.Item.ResultValue': '응답값',
    // 차량기록조회
    'Vehicle.Records.Btn.Download': '엑셀다운로드',
    'Terms.Search.Time': '조회시간*',
    'Vehicle.Records.Vehicle.Number': '차대번호*',
    'Menu.Vehicle.Records': '차량실시간정보',
    'Vehicle.Records.Minute.Placeholder': '(분)',
    'Vehicle.Records.Hour.Placeholder': '(시)',
    'Vehicle.Records.Comment':
        '* 선택한 시간부터 10분간의 데이터를 조회합니다.',
};

export default language;
