import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import { Fragment } from 'react';

const WEEK_RESIDS = [
    'Common.Week,Monday',
    'Common.Week,Tuesday',
    'Common.Week,Wednesday',
    'Common.Week,Thursday',
    'Common.Week,Friday',
    'Common.Week,Saturday',
    'Common.Week,Sunday',
];

const COUNTRY_CD = process?.env?.REACT_APP_COUNTRY;

const MAX_FILE_SIE = 10 * 1024 * 1024; // 10 * 1024 * 1024 10M

const DEFAULT_COUNTRY = 'kr';

const COOKIE_KEY = {
    NOTICE_DAY_NOT_SHOW: 'NOTICE_DAY_NOT_SHOW',
};
const TERMS = {
    BIZ: 'GROUP_BIZ_CLIENT', // 슈퍼관리자, 부서담당자
    ADMIN: 'GROUP_ADMIN', // MOCEAN Car Sharing관리자,위탁사
};
const STORAGE_KEY = {
    LOGIN_AUTH_INFO: 'LOGIN_AUTH_INFO',
    COMPLETE_AGREE: 'COMPLETE_AGREE',
    NOTI_DAYS_CLOSE: 'NOTI_DAYS_CLOSE',
    LNB_CHANGE: 'LNB_CHANGE',
    LANGUAGE: 'LANGUAGE',
};
const TABLE_CLASS = {
    SEARCH: 'noDivider',
    SEARCH_OUT: 'wbSearchTableOut',
    SEARCH_LABEL: 'wbSearchLable',
};
const INPUT_WIDTH_CLASS = {
    AUTO: 'wbBtnWidth_auto',
    W60: 'wbBtnWidth_60',
    W80: 'wbBtnWidth_80',
    W90: 'wbBtnWidth_90',
    W100: 'wbBtnWidth_100',
    W120: 'wbBtnWidth_120',
    W160: 'wbBtnWidth_160',
    W190: 'wbBtnWidth_190',
    W200: 'wbBtnWidth_200',
    W250: 'wbBtnWidth_250',
    W300: 'wbBtnWidth_300',
    W340: 'wbBtnWidth_340',
    FULL: 'wbBtnWidth_full',
    LARGE_GREEN: 'wbBtnLarge_green',
    WITH_BUTTON: 'wbWithButton',
    WITH_BUTTON_AUTO: 'wbWithButtonAuto',
};
const DECK_CLASS = {
    DIRECTION_COLUMN: 'wbDeckDirectionColumn',
    COLUMN_START: 'wbDeckColumnStart',
    COLUMN_END: 'wbDeckColumnEnd',
    ROW_SB: 'wbDeckRowSpaceBetween',
    ROW_HALF: 'wbDeckHalf',
    ALIGN_CENTER: 'wbDeckAlignCenter',
    DEFAULT: 'wbDeckDefault',
    GROW_UNSET: 'wbDeckGrowUnset',
    UPLOADER_FULL: 'wbDeckUploaderFull',
};
const SPAN_CLASS = {
    POINT_GREEN: 'wbSpanPointGreen',
    POINT_RED: 'wbSpanPointRed',
};
const DIV_CLASS = {
    GUIDE_BOX: 'wbGuideBox',
    TABLE_WITH_BUTTON: 'wbTableWithButton',
    TABLE_WITH_BOTTOM_BUTTON: 'wbTableWithBottomButton',
    LEFT_BUTTON: 'wbLeftButton',
    RIGHT_BUTTON: 'wbRightButton',
};
const ACL_MODE = { INQUIRY: 'inquiry', SAVE: 'save', DELETE: 'del' };
const PAGE_MODE = { CREATE: 'create', EDIT: 'edit', VIEW: 'view' };
const isNewReg = (pgMode) => pgMode === PAGE_MODE.CREATE;
const isEdit = (pgMode) => [PAGE_MODE.EDIT, PAGE_MODE.CREATE].includes(pgMode);
const isUpdate = (pgMode) => pgMode === PAGE_MODE.EDIT;

const TRANSE_MODE = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    GET: 'get',
};

const parseDate = (data, nvlData) => {
    if (_.isNumber(data)) {
        return data;
    }
    const nanToNvl = nvlData || 0;
    const parsedData = _.parseInt(data);
    return _.isNaN(parsedData) ? nanToNvl : parsedData;
};

const getCountryCd = () => {
    return !COUNTRY_CD ? DEFAULT_COUNTRY : COUNTRY_CD;
};

const parseNum = (data, nvlData) => {
    if (_.isNumber(data)) {
        return data;
    }
    const nanToNvl = nvlData || 0;
    const parsedData = _.parseInt(data);
    return _.isNaN(parsedData) ? nanToNvl : parsedData;
};

const getRangePickerFilterValues = (filters, startKey, endKey) => {
    return !_.isEmpty(filters) && filters[startKey] && filters[endKey]
        ? [
              moment(filters[startKey] ? Number(filters[startKey]) : undefined),
              moment(filters[endKey] ? Number(filters[endKey]) : undefined),
          ]
        : [];
};

const fileSave = ({ blob, fileName, extension }) => {
    if (!blob || _.isEmpty(fileName) || _.isEmpty(extension)) {
        return;
    }
    FileSaver.saveAs(
        blob,
        `${fileName}-${moment(new Date()).format(
            'YYYYMMDDHHmmss',
        )}.${extension}`,
    );
};

const printFileName = (fileInfos, uploaderName) => {
    const temp = fileInfos?.find((a) => a?.fileType === uploaderName);
    return temp?.OriginFileName || '';
};

const getFileID = (fileInfos, uploaderName) => {
    const temp = fileInfos?.find ? fileInfos?.find((a) => a?.fileType === uploaderName) : undefined;
    return temp?.ID || '';
};

const DEFAULT_PAGE_OPTION = { current: 1, scale: 15 };
const DIALOG_PAGE_OPTION = { current: 1, scale: 10 };
const NO_PAGE_OPTION = { noPaging: 'Y' };
const calcPagination = (props) => {
    const { current: currentInput, scale: scaleInput } = props || {};

    const current = currentInput || 1;
    const scale = scaleInput || DEFAULT_PAGE_OPTION?.scale;
    const offset = (current - 1) * scale;
    return {
        current: current,
        scale: scale,
        offset: offset,
        limit: scale,
    };
};

const isDev = () => {
    const appMode = process.env.REACT_APP_MODE;
    const devHost = ['local', 'dev', 'stg'];
    return devHost.includes(appMode);
};
const numberToLocaleString = (value, intl, suffixResId) => {
    const suffix =
        suffixResId && intl
            ? ` ${intl.formatMessage({ id: suffixResId })}`
            : '';
    return `${Number(value || 0).toLocaleString()}${suffix}`;
};
const getPlaceholderText = (
    resId,
    intl,
    suffixResId = 'Common.Placeholder.Suffix',
) => {
    if (!intl) {
        return '';
    }
    const suffix =
        typeof suffixResId !== 'undefined'
            ? ` ${intl.formatMessage({ id: suffixResId })}`
            : '';
    const text = intl.formatMessage({ id: resId });
    return `${text} ${suffix}`;
};

const RenderItems = ({ items }) => {
    if (!_.isArray(items)) {
        return <>{items}</>;
    }
    const elems = items.map((element, index) => {
        return <Fragment key={index}>{element}</Fragment>;
    });

    return <>{elems}</>;
};

/**
optoin: business
  - 사전조건
    - addBusiness : true
    - 전달된 파라미터에 businessID, businessName 가 없음
  - 권한별 데이터 설정
    - 슈퍼관리자, 부서담당자 : session > business 데이터
    - 슈퍼관리자, 부서담당자 외 권한 : session > myBusiness 데이터

optoin: department
  - 사전조건
    - addBusiness : true
    - addDepartment : true (자동 파라미터 설정에서는 department 단독사용 불가)
    - 전달된 파라미터에 departmentID, departmentName 가 없음
  - 권한별 데이터 설정
    - 부서담당자 : session > department 데이터
    - 부서담당자 외 권한 : 설정불가
*/

const pageStateInfo = ({
    location,
    session,
    stateKey,
    addBusiness,
    addDepartment,
    addProvider,
}) => {
    // session 정보 추출
    const { myBusiness, myProvider, role, business, department } =
        session || {};

    // baseState, baseUrl
    const { state: baseState, pathname: baseURL } = location || {};

    // filters, basePaing
    const curState = baseState && baseState[stateKey];
    const filters = curState?.filters;
    const basePaging = curState?.pagination;

    // baseFilters - 기존 파라미터 유지
    let baseFilters = Object.assign({}, filters);

    // baseFilters - addBusiness 설정 조건 체크
    if (addBusiness && !baseFilters?.businessName && !baseFilters?.businessID) {
        // 슈퍼관리자, 부서담당자 인 경우 본인의 session 정보만 설정
        if (
            ['BUSINESS_MANAGER', 'BUSINESS_OPERATOR'].includes(role) &&
            business
        ) {
            Object.assign(baseFilters, {
                businessName: business?.name,
                businessID: business?.ID,
            });

            // 슈퍼관리자, 부서담당자 외 경우 (회원사 선택 셀렉트박스가 있을경우) 선택한 세션(myBusiness)이 있는지 확인
        } else if (myBusiness) {
            Object.assign(baseFilters, {
                businessName: myBusiness?.name,
                businessID: myBusiness?.ID,
            });
        } else {
            /**
                business, department 초기화
                초기화한 데이터는 SearchForm 에서 해당필드 존재여부 확인후 존재할 경우만 설정함 (department 의 경우 모든 api 에서 받지 못하기때문에 해당처리)
            */
            Object.assign(baseFilters, {
                businessName: '',
                businessID: '',
                departmentName: '',
                departmentID: '',
            });
        }

        // baseFilters - addDepartment 설정 조건 체크 (addBusiness 가 있는경우만 취급)
        if (
            addDepartment &&
            !baseFilters?.departmentName &&
            !baseFilters?.departmentID
        ) {
            // 부서담당자 인 경우 본인의 session 정보만 설정
            if (['BUSINESS_OPERATOR'].includes(role) && department) {
                Object.assign(baseFilters, {
                    departmentName: department?.departmentName,
                    departmentID: department?.ID,
                });
            }
        }
    }

    if (addProvider && !baseFilters?.providerID) {
        // 공급사 인 경우 본인의 session 정보만 설정
        if (['PROVIDER_MANAGER'].includes(role) && business) {
            Object.assign(baseFilters, {
                providerID: business?.ID,
            });

            // 모션관리자 경우 (회원사 선택 셀렉트박스가 있을경우) 선택한 세션(myProvider)이 있는지 확인
        } else if (myProvider?.ID) {
            Object.assign(baseFilters, {
                providerID: myProvider?.ID,
            });
        } else {
            /**
                공급사 초기화
                초기화한 데이터는 SearchForm 에서 해당필드 존재여부 확인후 존재할 경우만 설정함
            */
            Object.assign(baseFilters, {
                providerID: '',
            });
        }
    }

    return { baseURL, baseFilters, basePaging };
};

const findSessionProviderID = ({ session }) => {
    const { myProvider, role, business } = session || {};

    let providerID = '';
    if (['PROVIDER_MANAGER'].includes(role) && business?.ID) {
        providerID = business?.ID;
    } else if (myProvider?.ID) {
        providerID = myProvider?.ID;
    }

    return providerID;
};

const eachElement = ({ datas, format }) => {
    if (!_.isArray(datas) || !_.isFunction(format)) {
        return <></>;
    }

    const elems = datas.map((data, index) => {
        return <Fragment key={`eachElement-${index}`}>{format(data)}</Fragment>;
    });

    return elems;
};

const maskEmail = ({ email }) => {
    const splitEmail = _.split(email, '@');
    if (splitEmail.length === 2) {
        if (splitEmail[0].length > 2) {
            return splitEmail[0].substring(0, 2) + '*****@' + splitEmail[1];
        } else {
            return splitEmail[0] + '*****@' + splitEmail[1];
        }
    } else {
        if (email.length > 2) {
            return email.substring(0, 2) + '*****@';
        } else {
            return email;
        }
    }
};

export {
    COOKIE_KEY,
    ACL_MODE,
    WEEK_RESIDS,
    TERMS,
    STORAGE_KEY,
    TRANSE_MODE,
    PAGE_MODE,
    DEFAULT_PAGE_OPTION,
    TABLE_CLASS,
    INPUT_WIDTH_CLASS,
    DECK_CLASS,
    SPAN_CLASS,
    DIV_CLASS,
    MAX_FILE_SIE,
    isNewReg,
    getCountryCd,
    isEdit,
    isUpdate,
    parseNum,
    parseDate,
    fileSave,
    calcPagination,
    getRangePickerFilterValues,
    DIALOG_PAGE_OPTION,
    printFileName,
    getFileID,
    NO_PAGE_OPTION,
    isDev,
    numberToLocaleString,
    getPlaceholderText,
    RenderItems,
    pageStateInfo,
    eachElement,
    maskEmail,
    findSessionProviderID,
};
