import { GateKeeperMobile as GateKeeperKr } from './GateKeeperMobile';
import { GateKeeperMobile as GateKeeperJp } from './GateKeeperMobile';
import { getCountryCd } from 'util/pageUtil';

const Pages = {
    kr: GateKeeperKr,
    jp: GateKeeperJp,
};
const GateKeeperPageMobile = (props) => {
    const Comp = Pages[getCountryCd()];
    return <Comp {...props} />;
};

export { GateKeeperPageMobile };
