import React, { useEffect, useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
    useAppRoot,
    TextOrSelectWithbaseOption,
    useTransaction,
} from 'component/mux';
import { TRANSID_PROVIDER } from 'config/transaction';
import { ItemsToCodeLists } from 'util/reservationUtils';
import { TransFailure } from 'util/transactionUtil';

const ProviderTextOrSelect = ({
    ignoreRoleDisabled, // 공급사 역할이라도, 강제로 활성화 시킬 때 사용. 기본은 사용안함(false).
    disable,
    name = 'providerID',
    value,
    placeholderId = 'Vehicle.Provider.Placeholder',
    baseOption,
    filters,
    onChangeValue,
    onValueChange,
    includeAll,
    showSearch = true,
    orderBy = true,
    onLoadComplete,
    ...rest
}) => {
    const { setValue } = useFormContext();
    const intl = useIntl();
    const { session } = useAppRoot();
    const { business, roleInfo, myProvider, activationStatusCd } =
        session || {};
    const [providers, setProviders] = useState([]);
    const [providerID, setProviderID] = useState('');
    const [disabled, setDisabled] = useState(!!disable);
    const { fetchTransaction: getProviders } = useTransaction(TRANSID_PROVIDER);

    const OnValueChange = useCallback(
        (props) => {
            const { data } = props;
            const findItems = data
                ? _.find(providers, ['ID', data[name]])
                : undefined;

            if (onChangeValue) {
                /**
                 * 해당 로직으로 session.myBusiness 값이 생성되고
                 * 각 화면에 myBusiness 체크로직이 존재함으로, myBusiness 기본값을 null -> { ID: undefined } 로 변경할경우
                 * 화면별 myBusiness 체크로직을 같이 변경해야함
                 * myBusiness 생성로직을 원복함으로 화면별 체크해야 하는 영향없음
                 */
                onChangeValue(findItems);
            }
            if (onValueChange) {
                onValueChange({ data });
            }
        },
        [onChangeValue, onValueChange, providers],
    );

    useEffect(() => {
        getProviders(null, {
            filters: { useYn: 'Y', noPaging: 'Y', ...filters },
        })
            .then((data) => {
                setProviders(ItemsToCodeLists(data?.items, 'name', 'ID'));
            })
            .catch((error) => {
                setProviders([]);
                TransFailure({ intl, error });
            }).finally(() => {
                if (onLoadComplete) onLoadComplete(true);
            });
    }, []);

    useEffect(() => {
        let changeDisabled = !!disable;

        if (!ignoreRoleDisabled && activationStatusCd === 'NORMAL') {
            // 승인된 공급사 관리자
            if (roleInfo?.systemType === 'PROVIDER') changeDisabled = true;
            else if (myProvider?.ID && roleInfo?.systemType === 'B2BG')
                // LNB 공급사 선택
                changeDisabled = true;
        }

        setDisabled((prev) =>
            !!prev !== !!changeDisabled ? changeDisabled : !!prev,
        );
    }, [
        roleInfo?.systemType,
        myProvider?.ID,
        activationStatusCd,
        disable,
        ignoreRoleDisabled,
    ]);

    useEffect(() => {
        if (!providers?.length) return;
        if (ignoreRoleDisabled) return; // 값 동기화 하지 않음.

        let changeProviderID = value || '';

        if (activationStatusCd === 'NORMAL') {
            if (roleInfo.systemType === 'PROVIDER') {
                changeProviderID = business?.ID;
            } else if (roleInfo.systemType === 'B2BG') {
                if (!!myProvider?.ID) {
                    changeProviderID = myProvider?.ID;
                }
            }
        }
        setProviderID(changeProviderID || (includeAll ? '' : undefined));
        if (name && setValue)
            setValue(name, changeProviderID || (includeAll ? '' : undefined));
    }, [
        myProvider?.ID,
        providers?.length,
        ignoreRoleDisabled,
        value,
        setValue,
    ]);

    const props = {
        name,
        value: providerID || (includeAll ? '' : undefined),
        option: providers,
        baseOption: includeAll
            ? { resId: 'Vehicle.Provider.AllSelect', value: '' }
            : undefined,
        onValueChange: OnValueChange,
        placeholderId,
        disable: disabled,
        showSearch,
        orderBy,
        ...rest,
    };

    if (!!baseOption) {
        props.baseOption = baseOption;
    }

    return <TextOrSelectWithbaseOption {...props} />;
};

export default ProviderTextOrSelect;
