import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Form, useTransaction, Text, Dialog, useAppRoot } from 'component/mux';
import { TRANSID_TERMS } from 'config/transaction';
import { AlertMessagePage, TransFailure } from 'util/transactionUtil';
import useAuthentication from 'util/authentication';
import { TERMS, parseNum } from 'util/pageUtil';
import { NoDataTag } from 'component/layer/DetailLayer';
import { ActionButton } from 'component/ActionButton';

const BIZ_REDIRECT_URL = ['/user/redirect', '/jp/user/redirect'];

const LoginStatusAgree = ({ visible, onAgree, onNoAgree }) => {
    const { customTransaction } = useTransaction(TRANSID_TERMS);
    const [checkeds, setCheckeds] = useState([]);
    const intl = useIntl();
    const { getAuthInfo } = useAuthentication();
    const { language } = useAppRoot();

    const setCheckedArray = (curKeys, curValue) => {
        const dynamicKeys = _.isArray(curKeys) ? curKeys : [curKeys];
        setCheckeds((prev) => {
            const newPrev = [...prev];
            _.forEach(dynamicKeys, (cur) => {
                const parseCur = parseNum(cur);
                const sameIdx = _.findIndex(newPrev, { ID: parseCur });
                if (sameIdx > -1) {
                    newPrev[sameIdx] = Object.assign(newPrev[sameIdx], {
                        ID: parseCur,
                        agreement: curValue ? 'Y' : 'N',
                    });
                }
            });
            return newPrev;
        });
    };

    const [agreeButton, setAgreeButton] = useState();
    useEffect(() => {
        const agreePassed = _.isEmpty(
            _.find(checkeds, { termsAgreeYn: 'Y', agreement: 'N' }),
        );
        if (_.size(checkeds) < 1 || !agreePassed) {
            setAgreeButton('cancel');
        } else {
            setAgreeButton('primary');
        }
    }, [checkeds]);

    useEffect(() => {
        if (!visible) {
            return;
        }

        const redirectAuthObj = getAuthInfo();
        const { pathname } = redirectAuthObj;

        // 이용약관
        const transData = {
            termsGroupIDs: BIZ_REDIRECT_URL.includes(pathname)
                ? TERMS.BIZ
                : TERMS.ADMIN,
            termsTypes: 'Terms.Type.Join',
            useYn: 'Y',
            activeDateYn: 'Y',
            sortField: 'ID',
            sortOrder: 'ASC',
            nationality: language === 'ko' ? 'KOR' : 'JPN',
        };

        customTransaction('getGroup', transData)
            .then(({ items }) => {
                if (items?.length < 1) {
                    onAgree();
                } else {
                    const defaultChecked = _.reduce(
                        items,
                        (prev, cur) => {
                            const { ID, termsAgreeYn, termsName, contents } =
                                cur;
                            const curObj = {
                                ID: ID,
                                agreement: 'N',
                                termsAgreeYn: termsAgreeYn,
                                termsName: termsName,
                                contents: contents,
                            };
                            prev.push(curObj);
                            return prev;
                        },
                        [],
                    );
                    setCheckeds(defaultChecked);
                }
            })
            .catch((error) => {
                TransFailure({ intl, error });
            });
    }, [visible]);

    const GenerateAgree = ({ items, setCheckedArray }) => {
        if (!_.isArray(items) || _.size(items) < 1) {
            return NoDataTag();
        }

        const allAgree = _.isEmpty(_.find(items, { agreement: 'N' }));
        const contentsArray = [];
        _.forEach(items, (element, idx) => {
            const { ID, agreement, termsName, contents } = element;
            contentsArray.push(
                <>
                    <div
                        className="wbTextareaAgreeWrap"
                        style={{
                            marginBottom: '20px',
                        }}
                    >
                        <label className="wbCheckbox" htmlFor={`${ID}_${idx}`}>
                            <input
                                id={`${ID}_${idx}`}
                                type="checkbox"
                                name={ID}
                                defaultChecked={agreement === 'Y'}
                                onChange={(data) => {
                                    setCheckedArray(
                                        data?.target?.name,
                                        data?.target?.checked,
                                    );
                                }}
                            />
                            <span>{termsName}</span>
                        </label>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: contents,
                            }}
                            style={{
                                fontSize: '12px',
                                border: '1px solid #e8e8e8',
                                marginTop: '5px',
                                padding: '10px',
                                maxHeight: '180px',
                                overflowY: 'auto',
                            }}
                        />
                    </div>
                </>,
            );
        });
        if (items?.length > 1) {
            contentsArray.push(
                <label className="wbCheckbox" htmlFor="allCheck">
                    <input
                        id="allCheck"
                        type="checkbox"
                        defaultChecked={allAgree}
                        onChange={(data) => {
                            setCheckedArray(
                                _.reduce(
                                    items,
                                    (prev, cur) => {
                                        prev.push(cur.ID);
                                        return prev;
                                    },
                                    [],
                                ),
                                data?.target?.checked,
                            );
                        }}
                    />
                    <span>
                        <Text resId="Btn.Agree.All" />
                    </span>
                </label>,
            );
        }

        return <>{contentsArray}</>;
    };

    const onClickAgree = useCallback(() => {
        if (agreeButton === 'cancel') {
            return;
        }
        const agreePassed = _.isEmpty(
            _.find(checkeds, { termsAgreeYn: 'Y', agreement: 'N' }),
        );
        if (_.size(checkeds) < 1 || !agreePassed) {
            AlertMessagePage({
                intl: intl,
                msgID: 'Message.Alert.Agree.Checked',
            });
            return;
        }

        const saveArray = _.reduce(
            checkeds,
            (prev, cur) => {
                const { ID, agreement } = cur;
                const nObj = {
                    ID: parseNum(ID),
                    agreement: agreement,
                };
                prev.push(nObj);
                return prev;
            },
            [],
        );

        return onAgree(saveArray);
    }, [checkeds, agreeButton]);

    const onClickCancel = () => {
        return onNoAgree();
    };

    return (
        <Form>
            <Dialog
                type="default"
                closable={false}
                visible={visible}
                resId="Common.Login.AdminCommon.Title"
                actions={[
                    <ActionButton
                        label={{ resId: 'Btn.Cancel' }}
                        onClick={onClickCancel}
                    />,
                    <ActionButton
                        type={agreeButton}
                        label={{ resId: 'Btn.Agree' }}
                        onClick={onClickAgree}
                    />,
                ]}
            >
                <GenerateAgree
                    items={checkeds}
                    setCheckedArray={setCheckedArray}
                />
            </Dialog>
        </Form>
    );
};

export default LoginStatusAgree;
