import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import * as _ from 'lodash';
import {
    DefaultTable,
    useTransaction,
    FilterSection,
    TextOrInput,
    HiddenInput,
    Dialog,
    useAppRoot,
} from 'component/mux';
import { TRANSID_BUSINESS, TRANSID_PLAIN_BUSINESS } from 'config/transaction';
import { TABLE_CLASS, INPUT_WIDTH_CLASS } from 'util/pageUtil';
import { AlertMessagePage, TransFailure } from 'util/transactionUtil';
import { columns, makeColumnsForContractY } from './columns';
import { If, Then } from 'react-if';
import { useIntl } from 'react-intl';

const ListInDialog = ({ dataSource, queryInfo, handleQuery, isContractY }) => {
    const { setValue } = useFormContext();
    const { fetchCode } = useAppRoot();
    const { pagination, filters } = queryInfo?.current || {};

    return (
        <>
            <DefaultTable
                columns={
                    isContractY
                        ? makeColumnsForContractY(
                              fetchCode('BusinessContract.LicenseTypeCd'),
                          )
                        : columns
                }
                dataSource={dataSource}
                // loading={isLoading}
                pagination={{
                    total: pagination?.totalRecord || dataSource?.length || 0,
                    current: pagination?.current || 1,
                    pageSize: pagination?.scale || 10,
                    position: ['bottomCenter'],
                }}
                showCount={false} // totalCount  표시 여부 옵션 default :false
                showIndex={false}
                rowKey={
                    isContractY
                        ? (record) =>
                              `${record?.contractID}#${record?.licenseTypeCd}`
                        : 'ID'
                }
                checkable={true}
                onSelect={(param, rowObj) => {
                    setValue('selectedIdx', rowObj);
                }}
                onChange={(page) => {
                    const { current } = page;

                    handleQuery({
                        pagination: { ...pagination, current: current },
                        filters,
                    });
                }}
                selType="radio"
                scroll={{ y: 511 }}
            />
        </>
    );
};

const DEFAULT_PAGINATION = { current: 1, offset: 0, scale: 10 };

const BusinessSingleSelectDialog = ({
    dialogId = 'BusinessSingleSelectDialog',
    resId,
    baseFilters,
    isContractY = false, //대여에서 사용여부
    isOnlyBusiness = true, //계약이랑 무관하게 회원사만 출력
    actions,
}) => {
    const intl = useIntl();
    // const { resId, baseFilters, basePagination } = props || {};
    const { fetchTransaction } = useTransaction(
        isOnlyBusiness ? TRANSID_PLAIN_BUSINESS : TRANSID_BUSINESS,
    );
    const [dataSource, setDataSource] = useState([]);

    // render 없이 값 보존.
    const queryInfo = useRef({
        pagination: { ...DEFAULT_PAGINATION },
        filters: {},
    });

    const handleQuery = useCallback((props) => {
        const { filters, pagination } = props || {};

        let currPagination = queryInfo?.current?.pagination || {};
        if (props?.pagination) {
            if (pagination?.current) {
                currPagination.offset =
                    (pagination.current - 1) * DEFAULT_PAGINATION.scale;
                currPagination.current = pagination.current;
            }
            if (!currPagination.offset) currPagination.offset = 0;
            if (!currPagination?.scale)
                currPagination.scale = DEFAULT_PAGINATION.scale;
        } else {
            currPagination = { ...DEFAULT_PAGINATION };
        }
        const currFilters = { ...baseFilters, ...filters };

        // render 없이 값 보존.
        queryInfo.current = {
            pagination: currPagination,
            filters: currFilters,
        };

        setDataSource(undefined);
        fetchTransaction(currPagination, {
            filters: currFilters,
        })
            .then(({ items, pageInfo }) => {
                queryInfo.current.pagination.totalRecord =
                    pageInfo?.totalRecord;
                setDataSource(items || []);
            })
            .catch((error) => {
                TransFailure({ intl, error });
            });
    });

    const setActions = useMemo(() => {
        return (
            actions || [
                { resId: 'Btn.Cancel', type: 'cancel' },
                { resId: 'Btn.Confirm', type: 'submit' },
            ]
        );
    }, [actions]);

    return (
        <Dialog
            dialogId={dialogId}
            resId={resId || 'Title.Popup.Business.Search'}
            actions={setActions}
            onQuery={(data) => handleQuery({ filters: data })}
            onInitDialog={() => handleQuery({ pagination: DEFAULT_PAGINATION })}
            validate={(formData) => {
                if (_.isEmpty(formData.form?.value)) {
                    AlertMessagePage({
                        intl,
                        msgID: 'Message.Alert.Dialog.Business.Selected',
                    });
                    return false;
                }
                return true;
            }}
            filterView={
                <>
                    <FilterSection className={TABLE_CLASS.SEARCH}>
                        <TextOrInput
                            editable
                            placeholderId="Business.Name.Placeholder"
                            name="name"
                            value={baseFilters?.name || ''}
                            className={INPUT_WIDTH_CLASS.FULL}
                            wrapperClassName={
                                INPUT_WIDTH_CLASS.WITH_BUTTON_AUTO
                            }
                        />
                        <TextOrInput
                            editable
                            placeholderId="Business.RegNum.Placeholder"
                            name="businessRegNum"
                            value={baseFilters?.licenseType || ''}
                            className={INPUT_WIDTH_CLASS.FULL}
                            wrapperClassName={
                                INPUT_WIDTH_CLASS.WITH_BUTTON_AUTO
                            }
                        />
                    </FilterSection>

                    <HiddenInput
                        name="contractYn"
                        value={isContractY ? 'Y' : ''}
                    />
                    <HiddenInput
                        name="startDate"
                        value={isOnlyBusiness ? '' : moment().unix() * 1000}
                    />
                    <HiddenInput
                        name="endDate"
                        value={
                            isOnlyBusiness
                                ? ''
                                : moment()
                                      .set({
                                          hour: 23,
                                          minute: 59,
                                          second: 59,
                                          millisecond: 0,
                                      })
                                      .unix() * 1000
                        }
                    />
                </>
            }
        >
            <If condition={_.size(dataSource) > 0}>
                <Then>
                    <ListInDialog
                        dataSource={dataSource}
                        queryInfo={queryInfo}
                        handleQuery={handleQuery}
                        isContractY={isContractY}
                    />
                </Then>
            </If>
        </Dialog>
    );
};

export { BusinessSingleSelectDialog };
export default BusinessSingleSelectDialog;
