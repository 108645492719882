import React from 'react';
import ReactDOM from 'react-dom';
import { Else, If, Then } from 'react-if';
import App from './App';
import AppMobile from './AppMobile';
import reportWebVitals from './reportWebVitals';
import { GA4React } from 'ga-4-react';
import { initTagManager } from 'util/tagmanagerUtil';
// Mobile Domain 분리
const pathname = window.location.pathname;
const mobileDomain = pathname?.includes('/m/');

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);

const render = () => {
    ReactDOM.render(
        <React.StrictMode>
            <If condition={mobileDomain}>
                <Then>
                    <AppMobile />
                </Then>
                <Else>
                    <App />
                </Else>
            </If>
        </React.StrictMode>,
        document.getElementById('root'),
    );
};

// google analytics
(async () => {
    await ga4react.initialize();
    /* initialize for google tag manager */
    initTagManager();
    render();
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
