import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useIntl } from 'react-intl';

import { TextOrTimePicker as MuxTextOrTimePicker } from 'mux';

const TextOrTimePicker = forwardRef((props, ref) => {
    const { disable, placeholder, placeholderId, required, validate, ...rest } =
        props;

    const intl = useIntl();

    // 유효성 검사
    const rules = useMemo(() => {
        const chunkRules = {};
        if (required) {
            chunkRules.required = intl.formatMessage({
                id: 'Message.Validator.Required',
            });
        }

        if (_.isFunction(validate) || _.isObject(validate)) {
            chunkRules.validate = validate;
        }

        return chunkRules;
    }, [intl, required, validate]);

    // 현재 위치에 해당하는 modal 을 popupContainer 로 지정 (다중 popup 문제로 추가)
    const getPopupContainer = (trigger) => {
        let curBody = trigger.parentNode;
        const modalBodys = document.getElementsByClassName('ant-modal-body');
        _.map(modalBodys, (element) => {
            if (element.hasChildNodes()) {
                _.map(element.childNodes, (element) => {
                    if (element.contains(trigger)) {
                        curBody = element;
                    }
                });
            }
        });
        return curBody;
    };

    return (
        <MuxTextOrTimePicker
            {...rest}
            {...rules}
            disabled={disable}
            placeholder={
                placeholderId
                    ? intl.formatMessage({ id: placeholderId })
                    : placeholder
            }
            getPopupContainer={getPopupContainer}
            ref={ref}
        />
    );
});

TextOrTimePicker.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    disable: PropTypes.bool,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    placeholder: PropTypes.string,
    placeholderId: PropTypes.string,
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
    exportFormat: PropTypes.oneOf(['longtime', 'moment', 'HH:mm', 'HH:mm:ss']),
};

TextOrTimePicker.defaultProps = {
    disable: false,
    required: false,
    editable: true,
    exportFormat: 'HH:mm',
};

export default TextOrTimePicker;
